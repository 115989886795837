import {
  createFooterNoteFail,
  createFooterNoteRequest,
  createFooterNoteSuccess,
  getFooterNoteSuccess,
  getFooterNoteFail,
  getFooterNoteTestRequest,
  getFooterNoteTestSuccess,
  getFooterNoteTestFail,
  updateFooterNoteFail,
  updateFooterNoteSuccess,
  getFooterNoteRequest,
  updateFooterNoteRequest,
  getFooterNoteNextRequest,
  getFooterNotePreviousRequest,
  clearFooterNoteData,
  getFooterNoteByIdRequest,
  footerNoteEditSuccess,
  footerNoteEditFail,
} from "./footerNoteSlice";
import { mergeMap } from "rxjs";
import {
  createFooterNote,
  getFooterNote,
  updateFooterNote,
  getFooterNoteTest,
  getFooterNoteById,
} from "./api";
import { map, filter, tap } from "rxjs/operators";
import { Observable } from "rxjs";
import { Action } from "@reduxjs/toolkit";
import { combineEpics } from "redux-observable";
import {
  alertErrorAction,
  alertSuccessAction,
  closeModal,
} from "../../../CommonAppRedux/CommonAppSlice";
import { getNext, getPrevious } from "../../../CommonAppRedux/api";
import {
  dispatchAction,
  stateAction,
} from "../../../../AppUtils/Utils/globalTypes";
import messages from "../../../../AppUtils/Utils/validationConstants";

export const controller = new AbortController();

// get footernote epic
const getFooterNoteEpic = (
  action$: Observable<Action>,
  _: stateAction,
  { dispatch }: dispatchAction
) =>
  action$.pipe(
    filter(getFooterNoteRequest.match),
    mergeMap(async (action) => {
      try {
        const response = await getFooterNote(action.payload);
        return { payload: response.data };
      } catch (e) {
        return { error: e };
      }
    }),
    map((action) =>
      action?.payload
        ? getFooterNoteSuccess(action?.payload)
        : getFooterNoteFail()
    )
  );

// get footer note by id epic
const getFooterNoteByIdEpic = (
  action$: Observable<Action>,
  _: stateAction,
  { dispatch }: dispatchAction
) =>
  action$.pipe(
    filter(getFooterNoteByIdRequest.match),
    mergeMap(async (action) => {
      try {
        const response = await getFooterNoteById(action.payload);
        return { payload: response.data };
      } catch (e) {
        return { error: e };
      }
    }),
    map((action) =>
      action?.payload
        ? footerNoteEditSuccess(action?.payload)
        : footerNoteEditFail()
    )
  );

//get next
const getFooterNoteNext = (
  action$: Observable<Action>,
  _: stateAction,
  { dispatch }: dispatchAction
) =>
  action$.pipe(
    filter(getFooterNoteNextRequest.match),
    mergeMap(async (action) => {
      try {
        const response = await getNext(action.payload);
        return { payload: response.data };
      } catch (e) {
        return { error: e };
      }
    }),
    map((action) =>
      action?.payload
        ? getFooterNoteSuccess(action?.payload)
        : getFooterNoteFail()
    )
  );

//get previous
const getFooterNotePrevious = (
  action$: Observable<Action>,
  _: stateAction,
  { dispatch }: dispatchAction
) =>
  action$.pipe(
    filter(getFooterNotePreviousRequest.match),
    mergeMap(async (action) => {
      try {
        const response = await getPrevious(action.payload);
        return { payload: response.data };
      } catch (e) {
        return { error: e };
      }
    }),
    map((action) =>
      action?.payload
        ? getFooterNoteSuccess(action?.payload)
        : getFooterNoteFail()
    )
  );
const getAllFooterNoteTests = (
  action$: Observable<Action>,
  _: stateAction,
  { dispatch }: dispatchAction
) =>
  action$.pipe(
    filter(getFooterNoteTestRequest.match),
    mergeMap(async (action) => {
      try {
        const response = await getFooterNoteTest(action.payload);
        return { payload: response.data };
      } catch (e) {
        return { error: e };
      }
    }),
    map((action) =>
      action?.payload
        ? getFooterNoteTestSuccess(action?.payload)
        : getFooterNoteTestFail()
    )
  );
//create FooterNote epic
const createFooterNoteEpic = (
  action$: Observable<Action>,
  _: stateAction,
  { dispatch }: dispatchAction
) =>
  action$.pipe(
    filter(createFooterNoteRequest.match),
    mergeMap(async ({ payload: { values, rowsPerPage, page } }) => {
      const { test, footerText, active } = values;
      try {
        const body = JSON.stringify({
          test,
          footerText,
          active,
        });
        const response = await createFooterNote(body);
        if (response) {
          dispatch(getFooterNoteRequest({ rowsPerPage, page }));
          dispatch(alertSuccessAction(messages.createMessage));
          dispatch(closeModal());
        }
        return { payload: { response } };
      } catch (e) {
        dispatch(alertErrorAction(messages.createFailMessage));
        return { error: e };
      }
    }),
    map((action) => {
      return action?.payload
        ? createFooterNoteSuccess()
        : createFooterNoteFail();
    })
  );

//update FooterNote epic
const updateFooterNoteEpic = (
  action$: Observable<Action>,
  _: stateAction,
  { dispatch }: dispatchAction
) =>
  action$.pipe(
    filter(updateFooterNoteRequest.match),
    mergeMap(async ({ payload: { values, id, rowsPerPage, page } }) => {
      const { test, footerText, active, remarks } = values;
      try {
        const body = JSON.stringify({
          test,
          footerText,
          active,
          remarks,
        });
        const response = await updateFooterNote(body, id);
        if (response) {
          dispatch(getFooterNoteRequest({ rowsPerPage, page }));
          dispatch(alertSuccessAction(messages.updateMessage));
          dispatch(clearFooterNoteData());
          dispatch(closeModal());
        }
        return { payload: { response, rowsPerPage } };
      } catch (e) {
        dispatch(alertErrorAction(messages.updateFailMessage));
        return { error: e };
      }
    }),
    map((action) =>
      action?.payload ? updateFooterNoteSuccess() : updateFooterNoteFail()
    )
  );

export const footerNoteEpics = combineEpics(
  getFooterNoteEpic,
  createFooterNoteEpic,
  updateFooterNoteEpic,
  getFooterNoteNext,
  getFooterNotePrevious,
  getAllFooterNoteTests,
  getFooterNoteByIdEpic
);
