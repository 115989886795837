import { filterApiData } from "../../../../../AppUtils/Utils/appFunctions";
import { axiosInstance } from "../../../../../AppUtils/Utils/axios";
import { getApiProps, searchProps } from "../../../../../AppUtils/Utils/globalTypes";



const IRDSyncsModURL = '/api/v1/clinic-ird-sync-app/ird-sync';

export const getIRDSync = (data: getApiProps) => {
  const { rowsPerPage, page } = data;
  return axiosInstance.get(
    `${IRDSyncsModURL}?offset=${rowsPerPage * page}&limit=${rowsPerPage}${
      data?.filterData ? filterApiData(data?.filterData) : ""
    }`
  );
};

export const searchIRDSync = ({ rowsPerPage, search }: searchProps) => {
  return `${IRDSyncsModURL}?offset=0&limit=${rowsPerPage}&search=${search}`;
};

export const postIRDSyncApi = () =>{
  const values="";
  const body=JSON.stringify(values);
  return axiosInstance.post(`${IRDSyncsModURL}`,body);
}
