import { axiosInstance } from "../../../../AppUtils/Utils/axios";
import {
  searchProps,
  getApiProps,
} from "../../../../AppUtils/Utils/globalTypes";
import { filterApiData } from "../../../../AppUtils/Utils/appFunctions";

const appointmentTypeModURL = `api/v1/appointment-app/appointment-type`;

//get appointmentType
export const getAppointmentType = (data: getApiProps) => {
  const { rowsPerPage, page, search } = data;
  return axiosInstance.get(
    `${appointmentTypeModURL}?offset=${
      rowsPerPage * page
    }&limit=${rowsPerPage}&search=${search ?? ""}${
      data?.filterData ? filterApiData(data?.filterData) : ""
    }`
  );
};
//get appointmentType by id
export const getAppointmentTypeById = (id: number) => {
  return axiosInstance.get(`${appointmentTypeModURL}/${id}`);
};
//search appointmentType
export const searchAppointmentType = ({ rowsPerPage, search }: searchProps) => {
  return `${appointmentTypeModURL}?offset=0&limit=${rowsPerPage}&search=${
    search ?? ""
  }`;
};
//update appointmentType
export const updateAppointmentType = (body: string | FormData, id: number) =>
  axiosInstance.patch(`${appointmentTypeModURL}/${id}`, body);
//create appointmentType
export const createAppointmentType = (body: string | FormData) =>
  axiosInstance.post(`${appointmentTypeModURL}`, body);
