import { filterApiData } from "../../../../AppUtils/Utils/appFunctions";
import { axiosInstance } from "../../../../AppUtils/Utils/axios";

export const moldOrderModURL = "/api/v1/appointment-app/mold-order";
//get MoldOrder data api
export const getMoldOrder = (data: any) => {
  const { rowsPerPage, page, isDispatched } = data;
  return axiosInstance.get(
    `${moldOrderModURL}?offset=${rowsPerPage * page}&limit=${rowsPerPage}${
      data?.filterData ? filterApiData(data?.filterData) : ""
    }${isDispatched !== undefined ? `&is_dispatched=${isDispatched}` : ""}`
  );
};
export const getMoldOrderCustomerType = ({ rowsPerPage }: any) => {
  return axiosInstance.get(
    `${moldOrderModURL}/customer-type?offset=0&limit=${rowsPerPage}`
  );
};
export const getMoldOrderDepartment = (rowsPerPage: number) => {
  return axiosInstance.get(
    `${moldOrderModURL}/department?offset=0&limit=${rowsPerPage}`
  );
};
export const getMoldOrderCategory = ({ rowsPerPage }: any) => {
  return axiosInstance.get(
    `${moldOrderModURL}/clinicorder-category?offset=0&limit=${rowsPerPage}`
  );
};
//handle search
export const searchMoldOrder = ({ rowsPerPage, search }: any) => {
  return `${moldOrderModURL}?offset=0&limit=${rowsPerPage}&search=${search}`;
};
//create MoldOrder
export const createMoldOrder = (body: string | FormData) =>
  axiosInstance.post(`${moldOrderModURL}`, body);
//update MoldOrder
export const updateMoldOrder = (body: string | FormData, id: number) =>
  axiosInstance.patch(`${moldOrderModURL}/${id}`, body);
//
export const getMoldOrderById = (id: number) => {
  return axiosInstance.get(`${moldOrderModURL}/${id}`);
};

//
export const getMoldOrderItem = (rowsPerPage: number) =>
  axiosInstance.get(`${moldOrderModURL}/item?offset=0&limit=${rowsPerPage}`);

export const getMoldOrderCustomer = (rowsPerPage: number) =>
  axiosInstance.get(
    `api/v1/appointment-app/customer?offset=0&limit=${rowsPerPage}`
  );

export const getOuterMoldType = (defaultRowsPerPage: number) => {
  return axiosInstance.get(
    `${moldOrderModURL}/mold-type?offset=0&limit=${defaultRowsPerPage}`
  );
};

//update received status
export const updateReceivedStatus = (body: string, id: number) => {
  return axiosInstance.patch(`${moldOrderModURL}/${id}/receive`, body);
};

//update dispatched status
export const updateDispatchedStatus = (body: string, id: number) => {
  return axiosInstance.patch(`${moldOrderModURL}/${id}/dispatch`, body);
};
// cancel mold order
export const cancelMoldOrder = (id: number) => {
  return axiosInstance.patch(`${moldOrderModURL}/${id}/`);
};
