import { filterApiData } from "../../../../../AppUtils/Utils/appFunctions";
import { axiosInstance } from "../../../../../AppUtils/Utils/axios";
import { getApiProps, searchProps } from "../../../../../AppUtils/Utils/globalTypes";


const IRDSyncInventorysModURL = 'api/v1/inventory-ird-sync-app/ird-sync';
export const getIRDSyncInventory = (data: getApiProps) => {
  const { rowsPerPage, page } = data;
  return axiosInstance.get(
    `${IRDSyncInventorysModURL}?offset=${rowsPerPage * page}&limit=${rowsPerPage}${
      data?.filterData ? filterApiData(data?.filterData) : ""
    }`
  );
};

export const searchIRDSyncInventory = ({ rowsPerPage, search }: searchProps) => {
  return `${IRDSyncInventorysModURL}?offset=0&limit=${rowsPerPage}&search=${search}`;
};

export const postIRDSyncInventoryApi = () =>{
  const values="";
  const body=JSON.stringify(values);
  axiosInstance.post(`${IRDSyncInventorysModURL}`,body);
}