import axios from "axios";
import { filterApiData } from "../../../../AppUtils/Utils/appFunctions";
import ArrayUtils from "../../../../AppUtils/Utils/arrayUtils";
import { axiosInstance } from "../../../../AppUtils/Utils/axios";
import {
  getApiProps,
  globalApiData,
  searchProps,
} from "../../../../AppUtils/Utils/globalTypes";
import { reportComment, resultEntry, sensitiveMedicineApiData } from "../types";
export const resultEntryURL = "api/v1/lab-result-entry-app/result-entry";
// create  result Entry
export const createResultEntry = (body: any, id?: number) =>
  axiosInstance.post<resultEntry>(`${resultEntryURL}`, body);
//update result Entry
export const updateResultEntry = (body: string | FormData) =>
  axiosInstance.patch<resultEntry>(`${resultEntryURL}`, body);
//   get  result Entry data
export const getResultEntry = (data: getApiProps) => {
  const { rowsPerPage, page, search } = data;
  return axiosInstance.get(
    `${resultEntryURL}?offset=${
      rowsPerPage * page
    }&limit=${rowsPerPage}&search=${search ?? ""}${
      data?.filterData ? filterApiData(data?.filterData) : ""
    }`
  );
};
// get sample collection specific data
export const getResultEntryCustomerTest = (id: number) =>
  axiosInstance.get(`${resultEntryURL}/customer-test/${id}?offset=0&limit=0`);
//obtaining the sample type
export const getSampleType = () =>
  axiosInstance.get(`${resultEntryURL}/sample-type?offset=0&limit=0`);
//obtaining all data
export const getAllResultEntry = () =>
  axiosInstance.get<resultEntry[]>(`api/v1/lab-app/test-category`);
//handle search
export const searchResultEntry = ({ rowsPerPage, search }: searchProps) => {
  return `${resultEntryURL}?offset=0&limit=${rowsPerPage}&search=${
    search ?? ""
  }`;
};
// get collected test
export const getResultEntryCollectedTest = (id: number) =>
  axiosInstance.get<resultEntry>(
    `${resultEntryURL}/collected-test?sample_main=${id}&offset=0&limit=0`
  );
//get result entry report footer
export const getResultEntryReportFooter = (rowsPerPage: number) =>
  axiosInstance.get<resultEntry>(
    `${resultEntryURL}/report-footer?offset=0&limit=${rowsPerPage}
    `
  );
//get result entry collected test details
export const getResultEntryCollectedTestDetails = (
  selectedTableRow: number[]
) =>
  axiosInstance.get<globalApiData>(
    `${resultEntryURL}/collected-test-detail?offset=0&limit=0&id__in=${ArrayUtils.splitArrayOfNumberToStringById(
      selectedTableRow
    )}`
  );
//get entered test details
export const getResultEntryTestDetails = (selectedTableRow: number[]) =>
  axiosInstance.get<globalApiData>(
    `${resultEntryURL}/result-entry-test-detail?offset=0&limit=0&sample_detail__in=${ArrayUtils.splitArrayOfNumberToStringById(
      selectedTableRow
    )}`
  );
// get sensitive medicines
export const getResultEntryTestSensitiveMedicine = (id?: number[]) =>
  axiosInstance.get<sensitiveMedicineApiData>(
    `${resultEntryURL}/customer-test-sensitive-medicine?id__in=${
      id ? id.join(",") : ""
    }&offset=0&limit=0`
  );
//get sensitive medicine of a particular test
export const getSpecificSensitiveMedicine = (id: number) =>
  axiosInstance.get(
    `${resultEntryURL}/test-sensitive-medicine?test=${id}&offset=0&limit=0`
  );
//get report comment
export const getResultEntryReportComment = () =>
  axiosInstance.get<reportComment[]>(`${resultEntryURL}/report-comment`);
//get report comment after result is entered
export const getEnteredResultEntryReportComment = (id: number[]) =>
  axiosInstance.get<reportComment[]>(
    `${resultEntryURL}/customer-test-report-comment?customer_test__in=${ArrayUtils.splitArrayOfNumberToStringById(
      id
    )}`
  );
//get report footer after result is entered
export const getEnteredResultEntryReportFooter = (id: number) =>
  axiosInstance.get(
    `${resultEntryURL}/customer-test-report-footer?sample_main=${id}`
  );
