import * as React from "react";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import MoreVertIcon from "@mui/icons-material/MoreVert";
import AppButton from "../AppElements/Button/AppButton";
import { verticalMenuProps } from "./types";

export default function VerticalMenusForContact({
  handleShowDetail,
  handleStatusUpdate,
  handleViewContacts,
  row,
}: verticalMenuProps) {

  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);

  const handleClick = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  return (
    <>
      <AppButton
        tooltipTitle="More Options"
        submit={false}
        onClick={handleClick}
      >
        <MoreVertIcon fontSize="large" />
      </AppButton>
      <Menu anchorEl={anchorEl} open={open} onClose={handleClose}>
        <MenuItem
          onClick={() => handleShowDetail?.(row, handleClose)}
          disableRipple
        >
          Show Detail
        </MenuItem>
        <MenuItem
          onClick={() => handleStatusUpdate?.(row, handleClose)}
          disableRipple
        >
          Update Status
        </MenuItem>
        <MenuItem
          onClick={() => handleViewContacts?.(row, handleClose)}
          disableRipple
        >
          View Contacts
        </MenuItem>
      </Menu>
    </>
  );
}
