import { makeStyles } from "@mui/styles";
export const useModalStyles = makeStyles({
  modalContainer: {
    "& .MuiDialog-container": {
      "& .MuiDialog-paper": {
        borderRadius: "8px",
      },
    },
  },
  modalTitle: {
    fontSize: "1.2rem !important",
    padding: "6px 24px !important",
    textAlign: "center",
    color: "whitesmoke",
    background: "linear-gradient(to left, #1d6294, #4064a6)",
    "& .modal-close-button": {
      position: "absolute !important",
      right: "10px",
      top: "6px",
      background: "#fff !important",
      color: "#1d6294 !important",
      padding: "2px !important",
      fontSize: "1rem !important",
      transition: "0.2s ease-in-out 0s !important",
      "&:hover": {
        transform: "scale(1.1)",
      },
    },
  },
});
