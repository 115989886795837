import { createAction, createSlice } from "@reduxjs/toolkit";

const initialState: any = {
  referralEdits: [],
  edit: false,
  referralEdit: null,
  loading:false,
  loadingReferralEdit: false,
  loadingCreateReferralEdit:false,
  count: 0,
  next: "",
  previous: "",
  customers:[],
  sales:[],
  referrals:[],
  searchData:[],
  loadingReferralEditSearch:false,
};

export const getSaleNoRequest = createAction(
  "getSaleNoRequest",
  function prepare(id) {
    return {
      payload: id,
    };
  }
);
export const getAllCustomerRequest = createAction(
  "getAllCustomerRequest",
  function prepare(rowsPerPage) {
    return {
      payload: rowsPerPage,
    };
  }
);
export const getAllReferralRequest = createAction(
  "getAllReferralRequest",
  function prepare(rowsPerPage) {
    return {
      payload: rowsPerPage,
    };
  }
);
export const updateReferralEditRequest = createAction(
  "updateReferralEditRequest",
  function prepare({ values, id, rowsPerPage, page }) {
    return {
      payload: {
        values,
        id,
        rowsPerPage,
        page,
      },
    };
  }
);
export const getReferralEditRequest = createAction(
  "getReferralEditRequest",
  function prepare({ rowsPerPage, page, filterData }) {
    return {
      payload: {
        rowsPerPage,
        page,
        filterData,
      },
    };
  }
);
// get next request
export const getReferralEditNextRequest = createAction(
  "getReferralEditNextRequest",
  function prepare(next) {
    return {
      payload: next,
    };
  }
);
//get previous request
export const getReferralEditPreviousRequest = createAction(
  "getReferralEditPreviousRequest",
  function prepare(previous) {
    return {
      payload: previous,
    };
  }
);
export const ReferralEdit = createSlice({
  name: "ReferralEditReducer",
  initialState,
  reducers: {
    loadingReferralEdit: (state) => {
      state.loadingReferralEdit = true;
    },
    getReferralEditSuccess: (
      state,
      { payload: { results, count, next, previous } }
    ) => {
      state.loadingReferralEdit = false;
      state.referralEdits = results;
      state.count = count;
      state.next = next;
      state.previous = previous;
    },
    getReferralEditFail: (state) => {
      state.loadingReferralEdit = false;
    },
    getReferralEditSearchDataSuccess: (
      state,
      { payload: { results} }
    ) => {
      state.loadingReferralEditSearch = true;
      state.searchData = results;
    },
    getReferralEditSearchDataFail: (state) => {
      state.loading = false;
    },
    getCustomerSuccess: (
      state,
      { payload: { results} }
    ) => {
      state.loading = true;
      state.customers = results;
    },
    getCustomerFail: (state) => {
      state.loading = false;
    },
    getSaleNoSuccess: (
      state,
      { payload: { results} }
    ) => {
      state.loading = true;
      state.sales = results;

    },
    getSaleNoFail: (state) => {
      state.loading = false;
      state.sales = [];
    },
    getReferralSuccess: (
      state,
      { payload: { results} }
    ) => {
      state.loading = true;
      state.referrals = results;

    },
    getReferralFail: (state) => {
      state.loading = false;
    },
    createReferralEditRequest: (state, payload) => {
      state.loadingCreateReferralEdit = true;
      state.closeModal = true;
    },
    createReferralEditSuccess: (state) => {
      state.loadingCreateReferralEdit = false;
    },
    createReferralEditFail: (state) => {
      state.loadingCreateReferralEdit = false;
    },
    referralEditEditSuccess: (state, { payload }) => {
      state.edit = true;
      state.referralEdit = payload;
    },
    updateReferralEditSuccess: (state) => {
      state.loadingCreateReferralEdit = false;
    },
    updateReferralEditFail: (state) => {
      state.loadingCreateReferralEdit = false;
    },
    clearReferralEditData: (state) => {
      state.edit = false;
    },
  },
});
// Action creators are generated for each case reducer function
export const {
  loadingReferralEdit,
  getReferralEditSuccess,
  getReferralEditFail,
  getSaleNoSuccess,
  getSaleNoFail,
  getCustomerSuccess,
  getCustomerFail,
  getReferralSuccess,
  getReferralFail,
  createReferralEditRequest,
  createReferralEditSuccess,
  createReferralEditFail,
  referralEditEditSuccess,
  updateReferralEditSuccess,
  updateReferralEditFail,
  clearReferralEditData,
  getReferralEditSearchDataSuccess,
  getReferralEditSearchDataFail,
} = ReferralEdit.actions;
export default ReferralEdit.reducer;
