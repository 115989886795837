import { axiosInstance } from "../../../../AppUtils/Utils/axios";
import { filterApiData } from "../../../../AppUtils/Utils/appFunctions";
import {
  getApiProps,
  searchProps,
} from "../../../../AppUtils/Utils/globalTypes";

const testMethodModURL = "/api/v1/lab-app/test-method";

export const getTestMethod = (data: getApiProps) => {
  const { rowsPerPage, page } = data;
  return axiosInstance.get(
    `${testMethodModURL}?offset=${rowsPerPage * page}&limit=${rowsPerPage}${
      data?.filterData ? filterApiData(data?.filterData) : ""
    }`
  );
};

//get test method by id
export const getTestMethodById = (id: number) => {
  return axiosInstance.get(`${testMethodModURL}/${id}`);
};

export const searchTestMethod = ({ rowsPerPage, search }: searchProps) => {
  return `${testMethodModURL}?offset=0&limit=${rowsPerPage}&search=${search}`;
};
export const createTestMethod = (body: string | FormData) =>
  axiosInstance.post(`${testMethodModURL}`, body);
//update TestMethod
export const updateTestMethod = (body: string | FormData, id: number) =>
  axiosInstance.patch(`${testMethodModURL}/${id}`, body);

export const getAllTestMethodForDefault = () =>
  axiosInstance.get<any>(`${testMethodModURL}`);
