import { createSlice } from "@reduxjs/toolkit";
import { depositInitialState } from "./types";

const initialState: depositInitialState = {
  deposits: [],
  edit: false,
  deposit: null,
  loadingDepositReturn: false,
  loadingCreateDepositReturn: false,
  loadingDepositReturnCustomer: false,
  customers: [],
  loadingDepositReturnDeposit: false,
  refDeposits: [],
  count: 0,
  previous: "",
  next: "",
};

export const DepositReturn = createSlice({
  name: "depositReturnReducer",
  initialState,
  reducers: {
    getDepositReturnRequest: (state, payload) => {
      state.loadingDepositReturn = true;
    },
    getDepositReturnSuccess: (
      state,
      { payload: { results, count, next, previous } }
    ) => {
      state.loadingDepositReturn = false;
      state.deposits = results;
      state.count = count;
      state.next = next;
      state.previous = previous;
    },
    getDepositReturnFail: (state) => {
      state.loadingDepositReturn = false;
    },

    getDepositReturnNextRequest: (state, payload) => {
      state.loadingDepositReturn = true;
    },
    getDepositReturnPreviousRequest: (state, payload) => {
      state.loadingDepositReturn = true;
    },
    createDepositReturnRequest: (state, payload) => {
      state.loadingCreateDepositReturn = true;
    },
    updateDepositReturnRequest: (state, payload) => {
      state.loadingCreateDepositReturn = true;
    },
    createDepositReturnSuccess: (state) => {
      state.loadingCreateDepositReturn = false;
    },
    createDepositReturnFail: (state) => {
      state.loadingCreateDepositReturn = false;
    },
    getDepositReturnByIdRequest: (state, payload) => {
      state.edit = true;
    },
    depositEditSuccess: (state, { payload }) => {
      state.deposit = payload;
    },
    depositEditFail: (state) => {
      state.edit = false;
    },
    updateDepositReturnSuccess: (state) => {
      state.loadingCreateDepositReturn = false;
    },
    updateDepositReturnFail: (state) => {
      state.loadingCreateDepositReturn = false;
    },
    loadingDepositReturnCustomerRequest: (state) => {
      state.loadingDepositReturnCustomer = true;
    },
    getDepositReturnCustomerRequest: (state, { payload }) => {
      state.loadingDepositReturnCustomer = true;
    },
    getDepositReturnCustomerSuccess: (state, { payload: { results } }) => {
      state.loadingDepositReturnCustomer = false;
      state.customers = results;
    },
    getDepositReturnCustomerFail: (state) => {
      state.loadingDepositReturnCustomer = false;
    },
    loadingDepositReturnDepositRequest: (state) => {
      state.loadingDepositReturnDeposit = true;
    },
    getDepositReturnDepositRequest: (state, { payload }) => {
      state.loadingDepositReturnDeposit = true;
    },
    getDepositReturnDepositSuccess: (state, { payload: { results } }) => {
      state.loadingDepositReturnDeposit = false;
      state.refDeposits = results;
    },
    getDepositReturnDepositFail: (state) => {
      state.loadingDepositReturnDeposit = false;
    },
    clearAllData: (state) => {
      state.edit = false;
      state.deposit = null;
      state.refDeposits = [];
      state.customers = [];
    },
  },
});

//action creaters generated for each case reducer
export const {
  getDepositReturnRequest,
  getDepositReturnFail,
  getDepositReturnSuccess,
  //
  getDepositReturnNextRequest,
  getDepositReturnPreviousRequest,
  //
  createDepositReturnFail,
  createDepositReturnRequest,
  createDepositReturnSuccess,
  //
  depositEditSuccess,
  updateDepositReturnFail,
  updateDepositReturnSuccess,
  updateDepositReturnRequest,
  //
  clearAllData,
  //
  getDepositReturnByIdRequest,
  depositEditFail,
  //
  loadingDepositReturnCustomerRequest,
  getDepositReturnCustomerRequest,
  getDepositReturnCustomerSuccess,
  getDepositReturnCustomerFail,
  //
  loadingDepositReturnDepositRequest,
  getDepositReturnDepositRequest,
  getDepositReturnDepositSuccess,
  getDepositReturnDepositFail,
} = DepositReturn.actions;

export default DepositReturn.reducer;
