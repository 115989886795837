import { createAction, createSlice } from "@reduxjs/toolkit";

const initialState: any = {
  testMethods: [],
  edit: false,
  testMethod: null,
  loadingTestMethod: false,
  loadingCreateTestMethod: false,
  count: 0,
  next: "",
  previous: "",
};

export const updateTestMethodRequest = createAction(
  "updateTestMethodRequest",
  function prepare({ values, id, rowsPerPage, page }) {
    return {
      payload: {
        values,
        id,
        rowsPerPage,
        page,
      },
    };
  }
);

export const getTestMethodRequest = createAction(
  "getTestMethodRequest",
  function prepare({ rowsPerPage, page, filterData }) {
    return {
      payload: {
        rowsPerPage,
        page,
        filterData,
      },
    };
  }
);
// get next request
export const getTestMethodNextRequest = createAction(
  "getTestMethodNextRequest",
  function prepare(next) {
    return {
      payload: next,
    };
  }
);
//get previous request
export const getTestMethodPreviousRequest = createAction(
  "getTestMethodPreviousRequest",
  function prepare(previous) {
    return {
      payload: previous,
    };
  }
);
export const TestMethod = createSlice({
  name: "TestMethodReducer",
  initialState,
  reducers: {
    loadingTestMethod: (state) => {
      state.loadingTestMethod = true;
    },
    getTestMethodSuccess: (
      state,
      { payload: { results, count, next, previous } }
    ) => {
      state.loadingTestMethod = false;
      state.testMethods = results;
      state.count = count;
      state.next = next;
      state.previous = previous;
    },
    getTestMethodFail: (state) => {
      state.loadingTestMethod = false;
    },

    createTestMethodRequest: (state, payload) => {
      state.loadingCreateTestMethod = true;
      state.closeModal = true;
    },
    createTestMethodSuccess: (state) => {
      state.loadingCreateTestMethod = false;
    },
    createTestMethodFail: (state) => {
      state.loadingCreateTestMethod = false;
    },
    getTestMethodByIdRequest: (state, payload) => {
      state.edit = true;
    },
    testMethodEditSuccess: (state, { payload }) => {
      state.testMethod = payload;
    },
    testMethodEditFail: (state) => {
      state.edit = false;
    },
    updateTestMethodSuccess: (state) => {
      state.loadingCreateTestMethod = false;
    },
    updateTestMethodFail: (state) => {
      state.loadingCreateTestMethod = false;
    },

    clearTestMethodData: (state) => {
      state.edit = false;
      state.testMethod = null;
    },
  },
});
// Action creators are generated for each case reducer function
export const {
  loadingTestMethod,
  getTestMethodSuccess,
  getTestMethodFail,
  createTestMethodRequest,
  createTestMethodSuccess,
  createTestMethodFail,
  testMethodEditSuccess,
  updateTestMethodSuccess,
  updateTestMethodFail,
  clearTestMethodData,
  getTestMethodByIdRequest,
  testMethodEditFail,
} = TestMethod.actions;
export default TestMethod.reducer;
