import { axiosInstance } from "../../../../../AppUtils/Utils/axios";
import { filterApiData } from "../../../../../AppUtils/Utils/appFunctions";
import {
  getApiProps,
  searchProps,
} from "../../../../../AppUtils/Utils/globalTypes";
import { UserActivityLogReport } from "./types";

const UserActivityLogReportModURL =
  "/api/v1/clinic-ird-report-app/user-activity-log-report";

export const getUserActivityLogReportSupplier = () =>
  axiosInstance.get(
    `${UserActivityLogReportModURL}/supplier?offset=0&limit=${0}`
  );
export const getUserActivityLogReportUser = () =>
  axiosInstance.get(`${UserActivityLogReportModURL}/user?offset=0&limit=${0}`);
export const getUserActivityLogReportItem = () =>
  axiosInstance.get(`${UserActivityLogReportModURL}/item?offset=0&limit=${0}`);
export const getUserActivityLogReportAllReport = (data: any) => {
  const { updatedValue } = data;

  const {
    item,
    summary,
    detail,
    supplier,
    user,
    purchaseType,
    startDateAd,
    endDateAd,
  } = updatedValue;
  return axiosInstance.get(
    `${UserActivityLogReportModURL}?offset=0&limit=${0}${
      data?.filterData ? filterApiData(data?.filterData) : ""
    }&created_by=${user}&purchase_type=${purchaseType}&supplier=${supplier}&item=${item}&date_after=${startDateAd}&date_before=${endDateAd}`
  );
};
export const getUserActivityLogReportQuickReport = (data: any) => {
  const { rowsPerPage, page, updatedValue } = data;
  const { startDateAd, endDateAd } = updatedValue;
  return axiosInstance.get(
    `${UserActivityLogReportModURL}?offset=${
      rowsPerPage * page
    }&limit=${rowsPerPage}${
      data?.filterData ? filterApiData(data?.filterData) : ""
    }&date_after=${startDateAd}&date_before=${endDateAd}`
  );
};

export const searchUserActivityLogReport = ({
  rowsPerPage,
  search,
  updatedValue,
}: any) => {
  const { startDateAd, endDateAd } = updatedValue;

  return `${UserActivityLogReportModURL}?offset=0&limit=${rowsPerPage}&search=${search}&date_after=${startDateAd}&date_before=${endDateAd}`;
};
