import {
  createServiceTypeFail,
  createServiceTypeRequest,
  createServiceTypeSuccess,
  getServiceTypeFail,
  getServiceTypeSuccess,
  loadingServiceType,
  updateServiceTypeFail,
  updateServiceTypeSuccess,
  getServiceTypeRequest,
  getServiceTypeNextRequest,
  getServiceTypePreviousRequest,
  updateServiceTypeRequest,
  clearServiceTypeData,
  getServiceTypeByIdRequest,
  ServiceTypeEditSuccess,
  ServiceTypeEditFail,
  getMainServiceTypeSuccess,
  getMainServiceTypeFail,
  getMainServiceTypeRequest,
} from "./serviceTypeSlice";
import { mergeMap } from "rxjs";
import {
  createServiceType,
  getServiceType,
  updateServiceType,
  getAllServiceTypeForDefault,
  getServiceTypeById,
} from "./api";
import { map, filter } from "rxjs/operators";
import { Observable } from "rxjs";
import { Action } from "@reduxjs/toolkit";
import { combineEpics } from "redux-observable";
import {
  alertErrorAction,
  alertSuccessAction,
  closeModal,
} from "../../../CommonAppRedux/CommonAppSlice";
import { getNext, getPrevious } from "../../../CommonAppRedux/api";
import {
  dispatchAction,
  stateAction,
} from "../../../../AppUtils/Utils/globalTypes";
import messages from "../../../../AppUtils/Utils/validationConstants";
export const controller = new AbortController();
// import { searchData } from "../../../CommonAppRedux/commonAppFunctions";
//get ServiceTypeType epic
const getServiceTypeEpic = (
  action$: Observable<Action>,
  _: stateAction,
  { dispatch }: dispatchAction
) =>
  action$.pipe(
    filter(getServiceTypeRequest.match),
    mergeMap(async (action) => {
      dispatch(loadingServiceType());
      try {
        const response = await getServiceType(action.payload);
        return { payload: response.data };
      } catch (e) {
        return { error: e };
      }
    }),
    map((action) =>
      action?.payload ? getServiceTypeSuccess(action?.payload) : getServiceTypeFail()
    )
  );

//get stor by id
const getServiceTypeByIdEpic = (
  action$: Observable<Action>,
  _: stateAction,
  { dispatch }: dispatchAction
) =>
  action$.pipe(
    filter(getServiceTypeByIdRequest.match),
    mergeMap(async (action) => {
      try {
        const response = await getServiceTypeById(action.payload);
        return { payload: response.data };
      } catch (e) {
        return { error: e };
      }
    }),
    map((action) =>
      action?.payload ? ServiceTypeEditSuccess(action.payload) : ServiceTypeEditFail()
    )
  );

//get next
const getServiceTypeNext = (
  action$: Observable<Action>,
  _: stateAction,
  { dispatch }: dispatchAction
) =>
  action$.pipe(
    filter(getServiceTypeNextRequest.match),
    mergeMap(async (action) => {
      dispatch(loadingServiceType());
      try {
        const response = await getNext(action.payload);
        return { payload: response.data };
      } catch (e) {
        return { error: e };
      }
    }),
    map((action) =>
      action?.payload ? getServiceTypeSuccess(action?.payload) : getServiceTypeFail()
    )
  );
//get previous
const getServiceTypePrevious = (
  action$: Observable<Action>,
  _: stateAction,
  { dispatch }: dispatchAction
) =>
  action$.pipe(
    filter(getServiceTypePreviousRequest.match),
    mergeMap(async (action) => {
      dispatch(loadingServiceType());
      try {
        const response = await getPrevious(action.payload);
        return { payload: response.data };
      } catch (e) {
        return { error: e };
      }
    }),
    map((action) =>
      action?.payload ? getServiceTypeSuccess(action?.payload) : getServiceTypeFail()
    )
  );
//create ServiceTypeType epic
const createServiceTypeEpic = (
  action$: Observable<Action>,
  _: stateAction,
  { dispatch }: dispatchAction
) =>
  action$.pipe(
    filter(createServiceTypeRequest.match),
    mergeMap(async ({ payload: { values, rowsPerPage, page } }) => {
      try {
        const body = new FormData();
        for (let [key, value] of Object.entries(values)) {
          // @ts-ignore
          body.append(`${key}`, value);
        }
        const response = await createServiceType(body);
        if (response) {
          dispatch(getServiceTypeRequest({ rowsPerPage, page }));
          dispatch(alertSuccessAction(messages.createMessage));
          dispatch(closeModal());
        }
        return { payload: { response } };
      } catch (e) {
        dispatch(alertErrorAction(messages.createFailMessage));
        return { error: e };
      }
    }),
    map((action) => {
      return action?.payload ? createServiceTypeSuccess() : createServiceTypeFail();
    })
  );
//update ServiceTypeType epic
const updateServiceTypeEpic = (
  action$: Observable<Action>,
  _: stateAction,
  { dispatch }: dispatchAction
) =>
  action$.pipe(
    filter(updateServiceTypeRequest.match),
    mergeMap(async ({ payload: { values, id, rowsPerPage, page } }) => {
      try {
        const body = new FormData();
        for (let [key, value] of Object.entries(values)) {
          // @ts-ignore
          body.append(`${key}`, value);
        }

        const response = await updateServiceType(body, id);
        if (response) {
          dispatch(getServiceTypeRequest({ rowsPerPage, page }));
          dispatch(alertSuccessAction(messages.updateMessage));
          dispatch(closeModal());
          dispatch(clearServiceTypeData());
        }
        return { payload: { response, rowsPerPage } };
      } catch (e) {
        dispatch(alertErrorAction(messages.updateFailMessage));
        return { error: e };
      }
    }),
    map((action) =>
      action?.payload ? updateServiceTypeSuccess() : updateServiceTypeFail()
    )
  );

const getAllServiceTypeDefaultEpic = (
  action$: Observable<Action>,
  _: stateAction,
  { dispatch }: dispatchAction
) =>
  action$.pipe(
    filter(getMainServiceTypeRequest.match),
    mergeMap(async (action) => {
      // dispatch(loadingServiceType());
      try {
        const response = await getAllServiceTypeForDefault();
        return { payload: response.data };
      } catch (e) {
        return { error: e };
      }
    }),
    map((action) =>
      action?.payload
        ? getMainServiceTypeSuccess(action?.payload)
        : getMainServiceTypeFail()
    )
  );

export const serviceTypeEpics = combineEpics(
  getServiceTypeEpic,
  createServiceTypeEpic,
  updateServiceTypeEpic,
  getServiceTypeNext,
  getServiceTypePrevious,
  getAllServiceTypeDefaultEpic,
  getServiceTypeByIdEpic
);
