import * as React from "react";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import CallReceivedIcon from "@mui/icons-material/CallReceived";
import ScheduleSendIcon from "@mui/icons-material/ScheduleSend";
import MoreVertIcon from "@mui/icons-material/MoreVert";
import AppButton from "../AppElements/Button/AppButton";
import { verticalMenuProps } from "./types";

export default function VerticalMenus({
  handleReceive,
  handleDispatch,
  handleMakeBill,
  handleRepaired,
  handleCancel,
  row,
}: verticalMenuProps) {
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);
  const handleClick = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  return (
    <>
      <AppButton
        tooltipTitle="More Options"
        submit={false}
        onClick={handleClick}
      >
        <MoreVertIcon fontSize="large" />
      </AppButton>
      <Menu anchorEl={anchorEl} open={open} onClose={handleClose}>
        <MenuItem
          onClick={() => handleReceive?.(row, handleClose)}
          disableRipple
        >
          {/* <CallReceivedIcon /> */}
          Received
        </MenuItem>
        <MenuItem
          onClick={() => handleDispatch?.(row, handleClose)}
          disableRipple
        >
          {/* <ScheduleSendIcon /> */}
          Dispatched
        </MenuItem>
        <MenuItem
          onClick={() => handleMakeBill?.(row, handleClose)}
          disableRipple
        >
          {/* <ScheduleSendIcon /> */}
          Make Bill
        </MenuItem>
        <MenuItem
          onClick={() => handleCancel?.(row, handleClose)}
          disableRipple
        >
          Cancel
        </MenuItem>
        {handleRepaired && (
          <MenuItem
            onClick={() => handleRepaired?.(row, handleClose)}
            disableRipple
          >
            Repaired
          </MenuItem>
        )}
      </Menu>
    </>
  );
}
