import { axiosInstance } from "../../../../../AppUtils/Utils/axios";
import { filterApiData } from "../../../../../AppUtils/Utils/appFunctions";
import {
  getApiProps,
  searchProps,
} from "../../../../../AppUtils/Utils/globalTypes";
import { DepartmentWiseSaleReport } from "./types";

const DepartmentWiseSaleReportModURL = '/api/v1/clinic-financial-report-app/department-wise-sales-report';


export const getDepartmentWiseSaleReportSupplier = () =>
axiosInstance.get(`${DepartmentWiseSaleReportModURL}/supplier?offset=0&limit=${0}`);
export const getDepartmentWiseSaleReportUser = () =>
axiosInstance.get(`${DepartmentWiseSaleReportModURL}/user?offset=0&limit=${0}`);
export const getDepartmentWiseSaleReportItem = () =>
axiosInstance.get(`${DepartmentWiseSaleReportModURL}/item?offset=0&limit=${0}`);
export const getDepartmentWiseSaleReportAllReport = (data:any) =>{
  const {updatedValue} = data;
  const {
    summary,
    detail,
    user,
    startDateAd,
    endDateAd,
  } = updatedValue;
  return axiosInstance.get(
    `${DepartmentWiseSaleReportModURL}/${summary?"summary":"detail"}?offset=0&limit=${0}${
      data?.filterData?filterApiData(data?.filterData) : ""
    }&date_after=${startDateAd}&date_before=${endDateAd}`
  )};
export const getDepartmentWiseSaleReportQuickReport = (data: any) => {
  const { rowsPerPage, page ,updatedValue} = data;
  const {
    summary,
    detail,
    user,
    startDateAd,
    endDateAd,
  } = updatedValue;
  return axiosInstance.get(
    `${DepartmentWiseSaleReportModURL}/${summary?"summary":"detail"}?offset=${
      rowsPerPage * page
    }&limit=${rowsPerPage}${
      data?.filterData ? filterApiData(data?.filterData) : ""
    }&date_after=${startDateAd}&date_before=${endDateAd}`
  );
};

export const searchDepartmentWiseSaleReport = ({ rowsPerPage, search ,updatedValue}: any) => {
  const {
    summary,
    detail,
    user,
    startDateAd,
    endDateAd,
  } = updatedValue;

  return `${DepartmentWiseSaleReportModURL}/${summary?"summary":"detail"}?offset=0&limit=${rowsPerPage}&search=${search}&date_after=${startDateAd}&date_before=${endDateAd}`;
};