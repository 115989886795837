import {
  createAppointmentFail,
  createAppointmentRequest,
  createAppointmentSuccess,
  getAppointmentSuccess,
  getAppointmentFail,
  getAppointmentSiteRequest,
  getAppointmentSiteSuccess,
  getAppointmentSiteFail,
  getAppointmentClinicRequest,
  getAppointmentClinicSuccess,
  getAppointmentClinicFail,
  getClinicForRoutineAndPendingListRequest,
  getClinicForRoutineAndPendingListSuccess,
  getClinicForRoutineAndPendingListFail,
  getClinicRoutineRequest,
  getClinicRoutineSuccess,
  getClinicRoutineFail,
  getAppointmentDoctorRequest,
  getAppointmentDoctorSuccess,
  getAppointmentDoctorFail,
  getAppointmentTitleRequest,
  getAppointmentTitleSuccess,
  getAppointmentTitleFail,
  getAppointmentAppointmentTypeRequest,
  getAppointmentAppointmentTypeSuccess,
  getAppointmentAppointmentTypeFail,
  getAppointmentReferralTypeRequest,
  getAppointmentReferralTypeSuccess,
  getAppointmentReferralTypeFail,
  getAppointmentCustomerRequest,
  getAppointmentCustomerSuccess,
  getAppointmentCustomerFail,
  getAppointmentFromSiteRequest,
  getAppointmentFromSiteSuccess,
  getAppointmentFromSiteFail,
  getAppointmentFromDoctorRequest,
  getAppointmentFromDoctorSuccess,
  getAppointmentFromDoctorFail,
  getAppointmentFromClinicRequest,
  getAppointmentFromClinicSuccess,
  getAppointmentFromClinicFail,
  getAppointmentFromStatusRequest,
  getAppointmentFromStatusSuccess,
  getAppointmentFromStatusFail,
  getAppointmentFromCustomerNoRequest,
  getAppointmentFromCustomerNoSuccess,
  getAppointmentFromCustomerNoFail,
  getClinicFromSiteRequest,
  getClinicFromSiteSuccess,
  getClinicFromSiteFail,
  getFilterClinicFromSiteRequest,
  getFilterClinicFromSiteSuccess,
  getFilterClinicFromSiteFail,
  getDoctorFromClinicRequest,
  getDoctorFromClinicSuccess,
  getDoctorFromClinicFail,
  getFilterDoctorFromClinicRequest,
  getFilterDoctorFromClinicSuccess,
  getFilterDoctorFromClinicFail,
  getPendingReferralListRequest,
  getPendingReferralListSuccess,
  getPendingReferralListFail,
  updateAppointmentFail,
  updateAppointmentSuccess,
  getAppointmentRequest,
  updateAppointmentRequest,
  showConfirmationModalAfterAppointmentCompleted,
  getFilteredAppointmentRequest,
  getFilteredAppointmentSuccess,
  getFilteredAppointmentFail,
  getAppointmentProvinceRequest,
  getAppointmentProvinceSuccess,
  getAppointmentProvinceFail,
  getAppointmentDistrictRequest,
  getAppointmentDistrictSuccess,
  getAppointmentDistrictFail,
  getAllAppointmentDistrictRequest,
  getAppointmentFilterRequest,
  getAppointmentFilterSuccess,
  getAppointmentFilterFail,
  startAppointmentRequest,
  startAppointmentSuccess,
  startAppointmentFail,
  stopAppointmentRequest,
  stopAppointmentSuccess,
  stopAppointmentFail,
  getAppointmentReferrerRequest,
  getAppointmentReferrerSuccess,
  getAppointmentReferrerFail,
} from "./appointmentSlice";
import { setCustomerForMedicalReferral } from "../ConfirmModal/Redux/medicalReferralSlice";
import { mergeMap } from "rxjs";
import {
  createAppointment,
  getAppointment,
  updateAppointment,
  getAppointmentSite,
  getAppointmentDoctor,
  getAppointmentClinic,
  getAppointmentTitle,
  getAppointmentAppointmentType,
  getAppointmentReferralType,
  getAppointmentCustomer,
  getClinicFromSite,
  getDoctorFromClinic,
  getFilterClinicFromSite,
  getFilterDoctorFromClinic,
  getAppointmentFromSite,
  getAppointmentFromDoctor,
  getAppointmentFromClinic,
  getAppointmentFromStatus,
  getAppointmentFromCustomerNo,
  getClinicAppointmentRoutine,
  getPendingReferralList,
  getFilteredAppointmentById,
  getAppointmentProvince,
  getAppointmentDistrict,
  getAllAppointmentDistrict,
  getAppointmentFilter,
  startAppointment,
  stopAppointment,
  getAppointmentReferrer,
} from "./api";
import { map, filter, tap } from "rxjs/operators";
import { Observable } from "rxjs";
import { Action } from "@reduxjs/toolkit";
import { combineEpics } from "redux-observable";
import {
  alertErrorAction,
  alertSuccessAction,
  closeModal,
} from "../../../CommonAppRedux/CommonAppSlice";
import {
  dispatchAction,
  stateAction,
} from "../../../../AppUtils/Utils/globalTypes";
import messages from "../../../../AppUtils/Utils/validationConstants";

export const controller = new AbortController();
// get appointment epic
const getAppointmentEpic = (
  action$: Observable<Action>,
  _: stateAction,
  { dispatch }: dispatchAction
) =>
  action$.pipe(
    filter(getAppointmentRequest.match),
    mergeMap(async (action) => {
      try {
        const response = await getAppointment(action.payload);
        return { payload: response.data };
      } catch (e) {
        return { error: e };
      }
    }),
    map((action) =>
      action?.payload
        ? getAppointmentSuccess(action?.payload)
        : getAppointmentFail()
    )
  );
const getFilteredAppointmentEpic = (
  action$: Observable<Action>,
  _: stateAction,
  { dispatch }: dispatchAction
) =>
  action$.pipe(
    filter(getFilteredAppointmentRequest.match),
    mergeMap(async (action) => {
      try {
        // @ts-ignore
        const response = await getFilteredAppointmentById(action.payload);
        return { payload: response.data };
      } catch (e) {
        return { error: e };
      }
    }),
    map((action) =>
      action?.payload
        ? getFilteredAppointmentSuccess(action?.payload)
        : getFilteredAppointmentFail()
    )
  );

// get appointment from site
const getAppointmentFromSiteEpic = (
  action$: Observable<Action>,
  _: stateAction,
  { dispatch }: dispatchAction
) =>
  action$.pipe(
    filter(getAppointmentFromSiteRequest.match),
    mergeMap(async (action) => {
      try {
        const response = await getAppointmentFromSite(action.payload);
        return { payload: response.data };
      } catch (e) {
        return { error: e };
      }
    }),
    map((action) =>
      action?.payload
        ? getAppointmentFromSiteSuccess(action?.payload)
        : getAppointmentFromSiteFail()
    )
  );

  // get referrer
const getAppointmentReferrerEpic = (
  action$: Observable<Action>,
  _: stateAction,
  { dispatch }: dispatchAction
) =>
  action$.pipe(
    filter(getAppointmentReferrerRequest.match),
    mergeMap(async (action) => {
      try {
        const response = await getAppointmentReferrer();
        return { payload: response.data };
      } catch (e) {
        return { error: e };
      }
    }),
    map((action) =>
      action?.payload
        ? getAppointmentReferrerSuccess(action?.payload)
        : getAppointmentReferrerFail()
    )
  );
// get appointment from doctor
const getAppointmentFromDoctorEpic = (
  action$: Observable<Action>,
  _: stateAction,
  { dispatch }: dispatchAction
) =>
  action$.pipe(
    filter(getAppointmentFromDoctorRequest.match),
    mergeMap(async (action) => {
      try {
        const response = await getAppointmentFromDoctor(action.payload);
        return { payload: response.data };
      } catch (e) {
        return { error: e };
      }
    }),
    map((action) =>
      action?.payload
        ? getAppointmentFromDoctorSuccess(action?.payload)
        : getAppointmentFromDoctorFail()
    )
  );
// get appointment from clinic
const getAppointmentFromClinicEpic = (
  action$: Observable<Action>,
  _: stateAction,
  { dispatch }: dispatchAction
) =>
  action$.pipe(
    filter(getAppointmentFromClinicRequest.match),
    mergeMap(async (action) => {
      try {
        const response = await getAppointmentFromClinic(action.payload);
        return { payload: response.data };
      } catch (e) {
        return { error: e };
      }
    }),
    map((action) =>
      action?.payload
        ? getAppointmentFromClinicSuccess(action?.payload)
        : getAppointmentFromClinicFail()
    )
  );
// get appointment from status
const getAppointmentFromStatusEpic = (
  action$: Observable<Action>,
  _: stateAction,
  { dispatch }: dispatchAction
) =>
  action$.pipe(
    filter(getAppointmentFromStatusRequest.match),
    mergeMap(async (action) => {
      try {
        const response = await getAppointmentFromStatus(action.payload);
        return { payload: response.data };
      } catch (e) {
        return { error: e };
      }
    }),
    map((action) =>
      action?.payload
        ? getAppointmentFromStatusSuccess(action?.payload)
        : getAppointmentFromStatusFail()
    )
  );
// get appointment from customerNo
const getAppointmentFromCustomerNoEpic = (
  action$: Observable<Action>,
  _: stateAction,
  { dispatch }: dispatchAction
) =>
  action$.pipe(
    filter(getAppointmentFromCustomerNoRequest.match),
    mergeMap(async (action) => {
      try {
        const response = await getAppointmentFromCustomerNo(action.payload);
        return { payload: response.data };
      } catch (e) {
        return { error: e };
      }
    }),
    map((action) =>
      action?.payload
        ? getAppointmentFromCustomerNoSuccess(action?.payload)
        : getAppointmentFromCustomerNoFail()
    )
  );

// get site
const getAllAppointmentSite = (
  action$: Observable<Action>,
  _: stateAction,
  { dispatch }: dispatchAction
) =>
  action$.pipe(
    filter(getAppointmentSiteRequest.match),
    mergeMap(async (action) => {
      try {
        const response = await getAppointmentSite();
        return { payload: response.data };
      } catch (e) {
        return { error: e };
      }
    }),
    map((action) =>
      action?.payload
        ? getAppointmentSiteSuccess(action?.payload)
        : getAppointmentSiteFail()
    )
  );

// get clinic
const getAllAppointmentClinic = (
  action$: Observable<Action>,
  _: stateAction,
  { dispatch }: dispatchAction
) =>
  action$.pipe(
    filter(getAppointmentClinicRequest.match),
    mergeMap(async (action) => {
      try {
        const response = await getAppointmentClinic();
        return { payload: response.data };
      } catch (e) {
        return { error: e };
      }
    }),
    map((action) =>
      action?.payload
        ? getAppointmentClinicSuccess(action?.payload)
        : getAppointmentClinicFail()
    )
  );
// get clinic for routine and pending list
const getAllClinicForRoutineAndPendingList = (
  action$: Observable<Action>,
  _: stateAction,
  { dispatch }: dispatchAction
) =>
  action$.pipe(
    filter(getClinicForRoutineAndPendingListRequest.match),
    mergeMap(async (action) => {
      try {
        const response = await getAppointmentClinic();
        return { payload: response.data };
      } catch (e) {
        return { error: e };
      }
    }),
    map((action) =>
      action?.payload
        ? getClinicForRoutineAndPendingListSuccess(action?.payload)
        : getClinicForRoutineAndPendingListFail()
    )
  );

// get doctor
const getAllAppointmentDoctor = (
  action$: Observable<Action>,
  _: stateAction,
  { dispatch }: dispatchAction
) =>
  action$.pipe(
    filter(getAppointmentDoctorRequest.match),
    mergeMap(async (action) => {
      try {
        const response = await getAppointmentDoctor(action.payload);
        return { payload: response.data };
      } catch (e) {
        return { error: e };
      }
    }),
    map((action) =>
      action?.payload
        ? getAppointmentDoctorSuccess(action?.payload)
        : getAppointmentDoctorFail()
    )
  );
// get title
const getAllAppointmentTitle = (
  action$: Observable<Action>,
  _: stateAction,
  { dispatch }: dispatchAction
) =>
  action$.pipe(
    filter(getAppointmentTitleRequest.match),
    mergeMap(async (action) => {
      try {
        const response = await getAppointmentTitle();
        return { payload: response.data };
      } catch (e) {
        return { error: e };
      }
    }),
    map((action) =>
      action?.payload
        ? getAppointmentTitleSuccess(action?.payload)
        : getAppointmentTitleFail()
    )
  );
// get appointment type
const getAllAppointmentAppointmentType = (
  action$: Observable<Action>,
  _: stateAction,
  { dispatch }: dispatchAction
) =>
  action$.pipe(
    filter(getAppointmentAppointmentTypeRequest.match),
    mergeMap(async (action) => {
      try {
        const response = await getAppointmentAppointmentType();
        return { payload: response.data };
      } catch (e) {
        return { error: e };
      }
    }),
    map((action) =>
      action?.payload
        ? getAppointmentAppointmentTypeSuccess(action?.payload)
        : getAppointmentAppointmentTypeFail()
    )
  );
// get referral type
const getAllAppointmentReferralType = (
  action$: Observable<Action>,
  _: stateAction,
  { dispatch }: dispatchAction
) =>
  action$.pipe(
    filter(getAppointmentReferralTypeRequest.match),
    mergeMap(async (action) => {
      try {
        const response = await getAppointmentReferralType();
        return { payload: response.data };
      } catch (e) {
        return { error: e };
      }
    }),
    map((action) =>
      action?.payload
        ? getAppointmentReferralTypeSuccess(action?.payload)
        : getAppointmentReferralTypeFail()
    )
  );

// get appointment filter
const getAllAppointmentFilterEpic = (
  action$: Observable<Action>,
  _: stateAction,
  { dispatch }: dispatchAction
) =>
  action$.pipe(
    filter(getAppointmentFilterRequest.match),
    mergeMap(async (action) => {
      try {
        const response = await getAppointmentFilter();
        return { payload: response.data };
      } catch (e) {
        return { error: e };
      }
    }),
    map((action) =>
      action?.payload
        ? getAppointmentFilterSuccess(action?.payload)
        : getAppointmentFilterFail()
    )
  );
// get customer
const getAllAppointmentCustomer = (
  action$: Observable<Action>,
  _: stateAction,
  { dispatch }: dispatchAction
) =>
  action$.pipe(
    filter(getAppointmentCustomerRequest.match),
    mergeMap(async (action) => {
      try {
        const response = await getAppointmentCustomer(action.payload);
        return { payload: response.data };
      } catch (e) {
        return { error: e };
      }
    }),
    map((action) =>
      action?.payload
        ? getAppointmentCustomerSuccess(action?.payload)
        : getAppointmentCustomerFail()
    )
  );
// get clinic wise appointment routine
const getAllClinicAppointmentRoutine = (
  action$: Observable<Action>,
  _: stateAction,
  { dispatch }: dispatchAction
) =>
  action$.pipe(
    filter(getClinicRoutineRequest.match),
    mergeMap(async (action) => {
      try {
        const response = await getClinicAppointmentRoutine(action.payload);
        return { payload: response.data };
      } catch (e) {
        return { error: e };
      }
    }),
    map((action) =>
      action?.payload
        ? getClinicRoutineSuccess(action?.payload)
        : getClinicRoutineFail()
    )
  );

// get clinic from  site
const getAllClinicFromSite = (
  action$: Observable<Action>,
  _: stateAction,
  { dispatch }: dispatchAction
) =>
  action$.pipe(
    filter(getClinicFromSiteRequest.match),
    mergeMap(async (action) => {
      try {
        const response = await getClinicFromSite(action.payload);
        return { payload: response.data };
      } catch (e) {
        return { error: e };
      }
    }),
    map((action) =>
      action?.payload
        ? getClinicFromSiteSuccess(action?.payload)
        : getClinicFromSiteFail()
    )
  );
// get clinic from  site for filter fields
const getAllFilterClinicFromSite = (
  action$: Observable<Action>,
  _: stateAction,
  { dispatch }: dispatchAction
) =>
  action$.pipe(
    filter(getFilterClinicFromSiteRequest.match),
    mergeMap(async (action) => {
      try {
        const response = await getFilterClinicFromSite(action.payload);
        return { payload: response.data };
      } catch (e) {
        return { error: e };
      }
    }),
    map((action) =>
      action?.payload
        ? getFilterClinicFromSiteSuccess(action?.payload)
        : getFilterClinicFromSiteFail()
    )
  );
// get doctor from clinic
const getAllDoctorFromClinic = (
  action$: Observable<Action>,
  _: stateAction,
  { dispatch }: dispatchAction
) =>
  action$.pipe(
    filter(getDoctorFromClinicRequest.match),
    mergeMap(async (action) => {
      try {
        const response = await getDoctorFromClinic(action.payload);
        return { payload: response.data };
      } catch (e) {
        return { error: e };
      }
    }),
    map((action) =>
      action?.payload
        ? getDoctorFromClinicSuccess(action?.payload)
        : getDoctorFromClinicFail()
    )
  );
// get doctor from clinic for filter fields
const getAllFilterDoctorFromClinic = (
  action$: Observable<Action>,
  _: stateAction,
  { dispatch }: dispatchAction
) =>
  action$.pipe(
    filter(getFilterDoctorFromClinicRequest.match),
    mergeMap(async (action) => {
      try {
        const response = await getFilterDoctorFromClinic(action.payload);
        return { payload: response.data };
      } catch (e) {
        return { error: e };
      }
    }),
    map((action) =>
      action?.payload
        ? getFilterDoctorFromClinicSuccess(action?.payload)
        : getFilterDoctorFromClinicFail()
    )
  );
// get pending referral list
const getAllPendingReferralListEpic = (
  action$: Observable<Action>,
  _: stateAction,
  { dispatch }: dispatchAction
) =>
  action$.pipe(
    filter(getPendingReferralListRequest.match),
    mergeMap(async (action) => {
      try {
        const response = await getPendingReferralList(action.payload);
        return { payload: response.data };
      } catch (e) {
        return { error: e };
      }
    }),
    map((action) =>
      action?.payload
        ? getPendingReferralListSuccess(action?.payload)
        : getPendingReferralListFail()
    )
  );
//create Appointment epic
const createAppointmentEpic = (
  action$: Observable<Action>,
  _: stateAction,
  { dispatch }: dispatchAction
) =>
  action$.pipe(
    filter(createAppointmentRequest.match),
    mergeMap(async ({ payload: { values, scheduler, dateRange } }) => {
      try {
        const body = new FormData();
        for (let [key, value] of Object.entries(values)) {
          // @ts-ignore
          body.append(`${key}`, value);
        }
        const response = await createAppointment(body);
        if (response) {
          dispatch(getAppointmentRequest({ dateRange }));
          dispatch(alertSuccessAction(messages.createMessage));
          scheduler.close();
        }

        return { payload: { response } };
      } catch (e) {
        dispatch(alertErrorAction(messages.createFailMessage));
        return { error: e };
      }
    }),
    map((action) => {
      return action?.payload
        ? createAppointmentSuccess()
        : createAppointmentFail();
    })
  );

//update Appointment epic
const updateAppointmentEpic = (
  action$: Observable<Action>,
  _: stateAction,
  { dispatch }: dispatchAction
) =>
  action$.pipe(
    filter(updateAppointmentRequest.match),
    mergeMap(async ({ payload: { values, id, scheduler, dateRange } }) => {
      const { status } = values;
      try {
        const body = new FormData();
        for (let [key, value] of Object.entries(values)) {
          // @ts-ignore
          body.append(`${key}`, value);
        }
        const response = await updateAppointment(body, id);
        if (response) {
          dispatch(getAppointmentRequest({ dateRange }));
          dispatch(alertSuccessAction(messages.updateMessage));
          scheduler.close();
          if (status === "COMPLETED") {
            dispatch(showConfirmationModalAfterAppointmentCompleted(true));
            dispatch(setCustomerForMedicalReferral(response?.data));
          }
        }
        return { payload: { response } };
      } catch (e) {
        dispatch(alertErrorAction(messages.updateFailMessage));
        return { error: e };
      }
    }),
    map((action) =>
      action?.payload ? updateAppointmentSuccess() : updateAppointmentFail()
    )
  );

// get appointment from province
const getAppointmentProvinceEpic = (
  action$: Observable<Action>,
  _: stateAction,
  { dispatch }: dispatchAction
) =>
  action$.pipe(
    filter(getAppointmentProvinceRequest.match),
    mergeMap(async (action) => {
      try {
        const response = await getAppointmentProvince();
        return { payload: response.data };
      } catch (e) {
        return { error: e };
      }
    }),
    map((action) =>
      action?.payload
        ? getAppointmentProvinceSuccess(action?.payload)
        : getAppointmentProvinceFail()
    )
  );

// get appointment from district
const getAppointmentDistrictEpic = (
  action$: Observable<Action>,
  _: stateAction,
  { dispatch }: dispatchAction
) =>
  action$.pipe(
    filter(getAppointmentDistrictRequest.match),
    mergeMap(async (action) => {
      try {
        const response = await getAppointmentDistrict(action?.payload);
        return { payload: response.data };
      } catch (e) {
        return { error: e };
      }
    }),
    map((action) =>
      action?.payload
        ? getAppointmentDistrictSuccess(action?.payload)
        : getAppointmentDistrictFail()
    )
  );
// get appointment from district
const getAllAppointmentDistrictEpic = (
  action$: Observable<Action>,
  _: stateAction,
  { dispatch }: dispatchAction
) =>
  action$.pipe(
    filter(getAllAppointmentDistrictRequest.match),
    mergeMap(async (action) => {
      try {
        const response = await getAllAppointmentDistrict();
        return { payload: response.data };
      } catch (e) {
        return { error: e };
      }
    }),
    map((action) =>
      action?.payload
        ? getAppointmentDistrictSuccess(action?.payload)
        : getAppointmentDistrictFail()
    )
  );

//start appointment epic
const startAppointmentEpic = (
  action$: Observable<Action>,
  _: stateAction,
  { dispatch }: dispatchAction
) =>
  action$.pipe(
    filter(startAppointmentRequest.match),
    mergeMap(async ({ payload: { values, id, dateRange } }) => {
      try {
        const body = JSON.stringify(values);
        const response = await startAppointment(id, body);
        if (response) {
          dispatch(alertSuccessAction("Appointment has started."));

        }
        return { payload: response };
      } catch (e: any) {
        if (e?.response?.status === 400) {
          dispatch(alertErrorAction("Appointment has already started."));
        } else {
          dispatch(alertErrorAction("Couldn't start appointment."));
        }
        return { error: e };
      }
    }),
    map((action) =>
      action?.payload ? startAppointmentSuccess() : startAppointmentFail()
    )
  );

const stopAppointmentEpic = (
  action$: Observable<Action>,
  _: stateAction,
  { dispatch }: dispatchAction
) =>
  action$.pipe(
    filter(stopAppointmentRequest.match),
    mergeMap(async ({ payload: { values, id, dateRange } }) => {
      try {
        const body = JSON.stringify(values);
        const response = await stopAppointment(id, body);
        if (response) {
          dispatch(alertSuccessAction("Appointment has stoped."));

        }
        return { payload: response };
      } catch (e: any) {
        if (e?.response?.status === 400) {
          dispatch(alertErrorAction("Appointment has already stoped."));
        } else {
          dispatch(alertErrorAction("Couldn't stop appointment."));
        }
        return { error: e };
      }
    }),
    map((action) =>
      action?.payload ? stopAppointmentSuccess() : stopAppointmentFail()
    )
  );

export const appointmentEpics = combineEpics(
  getAppointmentEpic,
  getFilteredAppointmentEpic,
  createAppointmentEpic,
  updateAppointmentEpic,
  getAllAppointmentSite,
  getAllAppointmentClinic,
  getAllClinicForRoutineAndPendingList,
  getAllClinicAppointmentRoutine,
  getAllAppointmentDoctor,
  getAllAppointmentTitle,
  getAllAppointmentAppointmentType,
  getAllAppointmentReferralType,
  getAllAppointmentCustomer,
  getAllClinicFromSite,
  getAllDoctorFromClinic,
  getAllFilterClinicFromSite,
  getAllFilterDoctorFromClinic,
  getAppointmentFromSiteEpic,
  getAppointmentFromClinicEpic,
  getAppointmentFromDoctorEpic,
  getAppointmentFromStatusEpic,
  getAppointmentFromCustomerNoEpic,
  getAllPendingReferralListEpic,
  //
  getAppointmentDistrictEpic,
  getAppointmentProvinceEpic,
  getAllAppointmentDistrictEpic,
  //
  getAllAppointmentFilterEpic,
  //
  startAppointmentEpic,
  stopAppointmentEpic,
  getAppointmentReferrerEpic
);
