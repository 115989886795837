import { createSlice, createAction } from "@reduxjs/toolkit";
const initialState: any = {
  departments: [],
  loadingDepartment: false,
  loadingCreateDepartment: false,
  edit: false,
  department: null,
  count: 0,
  previous: "",
  next: "",
  loadingCustomerType:false,
  customerTypes:[],
};

export const Department = createSlice({
  name: "Department Reducer",
  initialState,
  reducers: {
    loadingDepartment: (state) => {
      state.loadingDepartment = true;
    },
    getDepartmentRequest: (state, payload) => {
      state.loadingDepartment = true;
    },
    getDepartmentSuccess: (
      state,
      { payload: { results, count, next, previous } }
    ) => {
      state.loadingDepartment = false;
      state.departments = results;
      state.count = count;
      state.next = next;
      state.previous = previous;
    },
    getDepartmentFail: (state) => {
      state.loadingDepartment = false;
    },
    getDepartmentNextRequest: (state, payload) => {
      state.loadingDepartment = true;
    },
    getDepartmentPreviousRequest: (state, payload) => {
      state.loadingDepartment = true;
    },
    createDepartmentRequest: (state, payload) => {
      state.loadingCreateDepartment = true;
      state.closeModal = true;
    },
    createDepartmentSuccess: (state) => {
      state.loadingCreateDepartment = false;
    },
    createDepartmentFail: (state) => {
      state.loadingCreateDepartment = false;
    },
    getDepartmentByIdRequest: (state, payload) => {
      state.edit = true;
    },
    departmentEditSuccess: (state, { payload }) => {
      // state.edit = true;
      state.department = payload;
    },
    departmentEditFail: (state) => {
      state.edit = false;
    },
    updateDepartmentRequest: (state, payload) => {
      state.loadingCreateDepartment = true;
    },
    updateDepartmentSuccess: (state) => {
      state.loadingCreateDepartment = false;
    },
    updateDepartmentFail: (state) => {
      state.loadingCreateDepartment = false;
    },
    clearDepartmentData: (state) => {
      state.edit = false;
      state.department = null;
    },
    getDepartmentCustomerTypeRequest: (state) => {
      state.loadingCustomerType = true;
    },
    getDepartmentCustomerTypeSuccess: (
      state,
      { payload: { results} }
    ) => {
      state.loadingCustomerType = false;
      state.customerTypes = results;
    },
    getDepartmentCustomerTypeFail: (state) => {
      state.loadingCustomerType= false;
    },
  },
});
// Action creators are generated for each case reducer function
export const {
  loadingDepartment,
  getDepartmentRequest,
  getDepartmentSuccess,
  getDepartmentFail,
  getDepartmentNextRequest,
  getDepartmentPreviousRequest,
  createDepartmentRequest,
  createDepartmentSuccess,
  createDepartmentFail,
  departmentEditSuccess,
  updateDepartmentRequest,
  updateDepartmentSuccess,
  updateDepartmentFail,
  clearDepartmentData,
  getDepartmentCustomerTypeFail,
  getDepartmentCustomerTypeSuccess,
  getDepartmentCustomerTypeRequest,
  departmentEditFail,
  getDepartmentByIdRequest,
} = Department.actions;
export default Department.reducer;
