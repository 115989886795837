import {
    createCreditClearanceFail,
    createCreditClearanceRequest,
    createCreditClearanceSuccess,
    //
    getCreditClearanceFail,
    getCreditClearanceSuccess,
    loadingCreditClearance,
    getCreditClearanceRequest,
    //
    updateCreditClearanceFail,
    updateCreditClearanceSuccess,
    updateCreditClearanceRequest,
    //
    getCreditClearanceNextRequest,
    getCreditClearancePreviousRequest,
    //
    clearCreditClearanceData,
    //
    getCreditClearanceByIdRequest,
    creditClearanceEditSuccess,
    creditClearanceEditFail,
    //
    getCreditClearanceCustomerRequest,
    getCreditClearanceCustomerSuccess,
    getCreditClearanceCustomerFail,
    //payment mode
    getCreditClearanceModeRequest,
    getCreditClearanceModeSuccess,
    getCreditClearanceModeFail,
    getCreditClearanceSalesWiseSuccess,
    getCreditClearanceSalesWiseFail,
    getCreditClearanceSalesWiseRequest,
    getCreditClearanceCustomerWiseSuccess,
    getCreditClearanceCustomerWiseFail,
    getCreditClearanceCustomerWiseRequest,
    getCreditClearanceSalesWisePreviousRequest,
    getCreditClearanceSalesWiseNextRequest,
    getCreditClearancePrintDetailsRequest,
    getCreditClearancePrintDetailsSuccess,
    getCreditClearancePrintDetailsFail,
    getCreditClearancePrintCountRequest,
    getCreditClearancePrintCountSuccess,
    getCreditClearancePrintCountFail,
} from "./creditClearanceSlice";
import { mergeMap } from "rxjs";
import {
    createCreditClearance,
    getCreditClearance,
    getCreditClearanceById,
    getCreditClearanceMode,
    getCreditClearanceCustomer,
    updateCreditClearance,
    getCreditClearanceSalesWise,
    getCreditClearanceCustomerWise,
    getcreditClearancePrintCount,
    printCreditClearanceCount,
    getPrintCreditClearanceDetails,
} from "./api";
import { map, filter } from "rxjs/operators";
import { Observable } from "rxjs";
import { Action } from "@reduxjs/toolkit";
import { combineEpics } from "redux-observable";
import {
    alertErrorAction,
    alertSuccessAction,
    closeModal,
} from "../../../CommonAppRedux/CommonAppSlice";
import { getNext, getPrevious } from "../../../CommonAppRedux/api";
import {
    dispatchAction,
    stateAction,
} from "../../../../AppUtils/Utils/globalTypes";
import messages from "../../../../AppUtils/Utils/validationConstants";
export const controller = new AbortController();

//get CreditClearance epic
const getCreditClearanceEpic = (
    action$: Observable<Action>,
    _: stateAction,
    { dispatch }: dispatchAction
) =>
    action$.pipe(
        filter(getCreditClearanceRequest.match),
        mergeMap(async (action) => {
            dispatch(loadingCreditClearance());
            try {
                const response = await getCreditClearance(action.payload);
                return { payload: response.data };
            } catch (e) {
                return { error: e };
            }
        }),
        map((action) =>
            action?.payload
                ? getCreditClearanceSuccess(action?.payload)
                : getCreditClearanceFail()
        )
    );

//get CreditClearance by Id Epic
const getCreditClearanceByIdEpic = (
    action$: Observable<Action>,
    _: stateAction,
    { dispatch }: dispatchAction
) =>
    action$.pipe(
        filter(getCreditClearanceByIdRequest.match),
        mergeMap(async (action) => {
            try {
                const response = await getCreditClearanceById(action.payload);
                return { payload: response.data };
            } catch (e) {
                return { error: e };
            }
        }),
        map((action) =>
            action?.payload
                ? creditClearanceEditSuccess(action?.payload)
                : creditClearanceEditFail()
        )
    );

//get creditClearance supplier
const getCreditClearanceCustomerEpic = (
    action$: Observable<Action>,
    _: stateAction,
    { dispatch }: dispatchAction
) =>
    action$.pipe(
        filter(getCreditClearanceCustomerRequest.match),
        mergeMap(async (action) => {
            try {
                const response = await getCreditClearanceCustomer();
                return { payload: response.data };
            } catch (e) {
                return { error: e };
            }
        }),
        map((action) =>
            action?.payload
                ? getCreditClearanceCustomerSuccess(action?.payload)
                : getCreditClearanceCustomerFail()
        )
    );

//get next
const getCreditClearanceNext = (
    action$: Observable<Action>,
    _: stateAction,
    { dispatch }: dispatchAction
) =>
    action$.pipe(
        filter(getCreditClearanceNextRequest.match),
        mergeMap(async (action) => {
            //   dispatch(loadingCreditClearance());
            try {
                const response = await getNext(action.payload);
                return { payload: response.data };
            } catch (e) {
                return { error: e };
            }
        }),
        map((action) =>
            action?.payload
                ? getCreditClearanceSuccess(action?.payload)
                : getCreditClearanceFail()
        )
    );
//get previous
const getCreditClearancePrevious = (
    action$: Observable<Action>,
    _: stateAction,
    { dispatch }: dispatchAction
) =>
    action$.pipe(
        filter(getCreditClearancePreviousRequest.match),
        mergeMap(async (action) => {
            //   dispatch(loadingCreditClearance());
            try {
                const response = await getPrevious(action.payload);
                return { payload: response.data };
            } catch (e) {
                return { error: e };
            }
        }),
        map((action) =>
            action?.payload
                ? getCreditClearanceSuccess(action?.payload)
                : getCreditClearanceFail()
        )
    );
//create CreditClearance epic
const createCreditClearanceEpic = (
    action$: Observable<Action>,
    _: stateAction,
    { dispatch }: dispatchAction
) =>
    action$.pipe(
        filter(createCreditClearanceRequest.match),
        mergeMap(async ({ payload: { values, rowsPerPage, page } }) => {
            //   const { name, province, active } = values;
            try {
                const body = JSON.stringify(values);
                const response = await createCreditClearance(body);
                if (response) {
                    dispatch(getCreditClearanceRequest({ rowsPerPage, page }));
                    dispatch(alertSuccessAction(messages.createMessage));
                    dispatch(closeModal());
                    dispatch(clearCreditClearanceData());
                }
                return { payload: { response } };
            } catch (e) {
                dispatch(alertErrorAction(messages.createFailMessage));
                return { error: e };
            }
        }),
        map((action) => {
            return action?.payload
                ? createCreditClearanceSuccess()
                : createCreditClearanceFail();
        })
    );
//update CreditClearance epic
const updateCreditClearanceEpic = (
    action$: Observable<Action>,
    _: stateAction,
    { dispatch }: dispatchAction
) =>
    action$.pipe(
        filter(updateCreditClearanceRequest.match),
        mergeMap(async ({ payload: { values, id, rowsPerPage, page } }) => {
            //   const { name, province, active, remarks } = values;
            try {
                const body = JSON.stringify(values);
                const response = await updateCreditClearance(body, id);
                if (response) {
                    dispatch(getCreditClearanceRequest({ rowsPerPage, page }));
                    dispatch(alertSuccessAction(messages.updateMessage));
                    dispatch(clearCreditClearanceData());
                    dispatch(closeModal());
                }
                return { payload: { response, rowsPerPage } };
            } catch (e) {
                dispatch(alertErrorAction(messages.updateFailMessage));
                return { error: e };
            }
        }),
        map((action) =>
            action?.payload
                ? updateCreditClearanceSuccess()
                : updateCreditClearanceFail()
        )
    );

// payment mode for party payment
const getCreditClearanceModeEpic = (
    action$: Observable<Action>,
    _: stateAction,
    { dispatch }: dispatchAction
) =>
    action$.pipe(
        filter(getCreditClearanceModeRequest.match),
        mergeMap(async (action) => {
            try {
                const response = await getCreditClearanceMode();
                return { payload: response.data };
            } catch (e) {
                return { error: e };
            }
        }),
        map((action) =>
            action?.payload
                ? getCreditClearanceModeSuccess(action?.payload)
                : getCreditClearanceModeFail()
        )
    );
//
const getCreditClearanceCustomerWiseEpic = (
    action$: Observable<Action>,
    _: stateAction,
    { dispatch }: dispatchAction
) =>
    action$.pipe(
        filter(getCreditClearanceCustomerWiseRequest.match),
        mergeMap(async (action) => {
            try {
                const response = await getCreditClearanceCustomerWise(
                    action?.payload
                );
                return { payload: response.data };
            } catch (e) {
                return { error: e };
            }
        }),
        map((action) =>
            action?.payload
                ? getCreditClearanceCustomerWiseSuccess(action?.payload)
                : getCreditClearanceCustomerWiseFail()
        )
    );

const getCreditClearanceSalesWiseEpic = (
    action$: Observable<Action>,
    _: stateAction,
    { dispatch }: dispatchAction
) =>
    action$.pipe(
        filter(getCreditClearanceSalesWiseRequest.match),
        mergeMap(async (action) => {
            try {
                const response = await getCreditClearanceSalesWise(
                    action?.payload
                );
                return { payload: response.data };
            } catch (e) {
                return { error: e };
            }
        }),
        map((action) =>
            action?.payload
                ? getCreditClearanceSalesWiseSuccess(action?.payload)
                : getCreditClearanceSalesWiseFail()
        )
    );
//
const getCreditClearanceSalesWiseNext = (
    action$: Observable<Action>,
    _: stateAction,
    { dispatch }: dispatchAction
) =>
    action$.pipe(
        filter(getCreditClearanceSalesWiseNextRequest.match),
        mergeMap(async (action) => {
            //   dispatch(loadingCreditClearanceSalesWise());
            try {
                const response = await getNext(action.payload);
                return { payload: response.data };
            } catch (e) {
                return { error: e };
            }
        }),
        map((action) =>
            action?.payload
                ? getCreditClearanceSalesWiseSuccess(action?.payload)
                : getCreditClearanceSalesWiseFail()
        )
    );
//get previous
const getCreditClearanceSalesWisePrevious = (
    action$: Observable<Action>,
    _: stateAction,
    { dispatch }: dispatchAction
) =>
    action$.pipe(
        filter(getCreditClearanceSalesWisePreviousRequest.match),
        mergeMap(async (action) => {
            //   dispatch(loadingCreditClearanceSalesWise());
            try {
                const response = await getPrevious(action.payload);
                return { payload: response.data };
            } catch (e) {
                return { error: e };
            }
        }),
        map((action) =>
            action?.payload
                ? getCreditClearanceSalesWiseSuccess(action?.payload)
                : getCreditClearanceSalesWiseFail()
        )
    );

    // get print credit clearance print details
const getCreditClearancePrintDetailsEpic = (
    action$: Observable<Action>,
    _: stateAction,
    { dispatch }: dispatchAction
) =>
    action$.pipe(
        filter(getCreditClearancePrintDetailsRequest.match),
        mergeMap(async (action) => {
            try {
                const response = await getPrintCreditClearanceDetails(
                    action?.payload
                );
                return { payload: response.data };
            } catch (e) {
                return { error: e };
            }
        }),
        map((action) => {
            return action?.payload
                ? getCreditClearancePrintDetailsSuccess(action?.payload)
                : getCreditClearancePrintDetailsFail();
        })
    );

const getCreditClearancePrintCountEpic = (
    action$: Observable<Action>,
    _: stateAction,
    { dispatch }: dispatchAction
) =>
    action$.pipe(
        filter(getCreditClearancePrintCountRequest.match),
        mergeMap(async (action) => {
            try {
                const response = await getcreditClearancePrintCount(action?.payload);
                return { payload: response.data };
            } catch (e) {
                return { error: e };
            }
        }),
        map((action) => {
            return action?.payload
                ? getCreditClearancePrintCountSuccess(action?.payload)
                : getCreditClearancePrintCountFail();
        })
    );
// const creditClearanceInvoicePrintCountEpic = (
//     action$: Observable<Action>,
//     _: stateAction,
//     { dispatch }: dispatchAction
// ) =>
//     action$.pipe(
//         filter(getCreditClearancePrintCountRequest.match),
//         mergeMap(async (action) => {
//             try {
//                 const response = await printCreditClearanceCount(action?.payload);
//                 return { payload: response.data };
//             } catch (e) {
//                 return { error: e };
//             }
//         }),
//         map((action) => {
//             return action?.payload
//                 ? getCreditClearancePrintCountSuccess(action?.payload)
//                 : getCreditClearancePrintCountFail();
//         })
//     );
export const creditClearanceEpics = combineEpics(
    getCreditClearanceEpic,
    createCreditClearanceEpic,
    updateCreditClearanceEpic,
    getCreditClearanceNext,
    getCreditClearancePrevious,
    getCreditClearanceByIdEpic,
    getCreditClearanceCustomerEpic,
    getCreditClearanceModeEpic,
    getCreditClearanceCustomerWiseEpic,
    getCreditClearanceSalesWiseEpic,
    getCreditClearanceSalesWisePrevious,
    getCreditClearanceSalesWiseNext,
    getCreditClearancePrintDetailsEpic,
    getCreditClearancePrintCountEpic,
    // creditClearanceInvoicePrintCountEpic,
);
