import { createAction, createSlice } from "@reduxjs/toolkit";

const initialState: any = {
  IRDSyncs: [],
  edit: false,
  IRDSync: null,
  loadingIRDSyncs: false,
  count: 0,
  next: "",
  previous: "",
};


export const getIRDSyncRequest = createAction(
  "getIRDSyncRequest",
  function prepare({ rowsPerPage, page, filterData }) {
    return {
      payload: {
        rowsPerPage,
        page,
        filterData,
      },
    };
  }
);


// get next request
export const getIRDSyncNextRequest = createAction(
  "getIRDSyncNextRequest",
  function prepare(next) {
    return {
      payload: next,
    };
  }
);
//get previous request
export const getIRDSyncPreviousRequest = createAction(
  "getIRDSyncPreviousRequest",
  function prepare(previous) {
    return {
      payload: previous,
    };
  }
);
export const IRDSync = createSlice({
  name: "IRDSyncReducer",
  initialState,
  reducers: {
    loadingIRDSync: (state) => {
      state.loadingIRDSync = true;
    },
    getIRDSyncSuccess: (
      state,
      { payload: { results, count, next, previous } }
    ) => {
      state.loadingIRDSync = false;
      state.IRDSyncs = results;
      state.count = count;
      state.next = next;
      state.previous = previous;
    },
    getIRDSyncFail: (state) => {
      state.loadingIRDSync = false;
    },
    postIRDSyncRequest:(state)=>{
      state.loadingUploadIRDSync=true;
    },
    postIRDSyncUploadSuccess:(state)=>{
      state.loadingUploadIRDSync=false;
    },
    
    clearIRDSyncData: (state) => {
      state.edit = false;
      state.IRDSync = null;
    },
  },
});
// Action creators are generated for each case reducer function
export const {
  loadingIRDSync,
  getIRDSyncSuccess,
  getIRDSyncFail,
  clearIRDSyncData,
  postIRDSyncUploadSuccess,
  postIRDSyncRequest,
} = IRDSync.actions;
export default IRDSync.reducer;
