import {
  getPurchaseReturnFail,
  getPurchaseReturnSuccess,
  loadingPurchaseReturn,
  getPurchaseReturnRequest,
  getPurchaseReturnSupplierRequest,
  getPurchaseReturnSuppliersSuccess,
  getPurchaseReturnSuppliersFail,
  createPurchaseReturnRequest,
  createPurchaseReturnSuccess,
  createPurchaseReturnFail,
  getSpecificPurchaseRequest,
  getSpecificPurchaseSuccess,
  getSpecificPurchaseFail,
  getSpecificPurchaseDetailsRequest,
  getSpecificPurchaseDetailsSuccess,
  getSpecificPurchaseDetailsFail,
  getSelectedPackingTypeRequest,
  getSelectedPackingTypesSuccess,
  getSelectedPackingTypesFail,
  getPurchaseReturnPaymentModeSuccess,
  getPurchaseReturnPaymentModesFail,
  getViewPurchaseReturnDetailsRequest,
  getViewPurchaseReturnDetailsSuccess,
  getViewPurchaseReturnDetailsFail,
  getViewPurchaseReturnDetailsNextRequest,
  getViewPurchaseReturnDetailsPreviousRequest,
  getPurchaseReturnAdditionalChargeRequest,
  getPurchaseReturnAdditionalChargeSuccess,
  getPurchaseReturnAdditionalChargeFail,
  clearPurchaseReturnData,
} from "./purchaseReturnSlice";
import { mergeMap } from "rxjs";
import { map, filter } from "rxjs/operators";
import { Observable } from "rxjs";
import { Action } from "@reduxjs/toolkit";
import { combineEpics } from "redux-observable";
import {
  dispatchAction,
  stateAction,
} from "../../../../AppUtils/Utils/globalTypes";
import * as API from "./api";
import {
  alertErrorAction,
  alertSuccessAction,
  closeModal,
} from "../../../CommonAppRedux/CommonAppSlice";
import messages from "../../../../AppUtils/Utils/validationConstants";
import { getNext, getPrevious } from "../../../CommonAppRedux/api";
export const controller = new AbortController();
//get purchase return epic
const getPurchaseReturnEpic = (
  action$: Observable<Action>,
  _: stateAction,
  { dispatch }: dispatchAction
) =>
  action$.pipe(
    filter(getPurchaseReturnRequest.match),
    mergeMap(async (action) => {
      dispatch(loadingPurchaseReturn());
      try {
        const response = await API.getPurchaseReturn(action?.payload);
        return { payload: response.data };
      } catch (e) {
        return { error: e };
      }
    }),
    map((action) =>
      action?.payload
        ? getPurchaseReturnSuccess(action?.payload)
        : getPurchaseReturnFail()
    )
  );
//create purchase epic
const createPurchaseReturnEpic = (
  action$: Observable<Action>,
  _: stateAction,
  { dispatch }: dispatchAction
) =>
  action$.pipe(
    filter(createPurchaseReturnRequest.match),
    mergeMap(async ({ payload: { values, rowsPerPage, page } }) => {
      try {
        const body = JSON.stringify(values);

        const response = await API.createPurchaseReturn(body);
        if (response) {
          dispatch(getPurchaseReturnRequest({ rowsPerPage, page }));
          dispatch(alertSuccessAction(messages.createMessage));
          dispatch(clearPurchaseReturnData());
          dispatch(closeModal());
        }
        return { payload: { response } };
      } catch (e) {
        dispatch(alertErrorAction(messages.createFailMessage));
        return { error: e };
      }
    }),
    map((action) => {
      return action?.payload
        ? createPurchaseReturnSuccess()
        : createPurchaseReturnFail();
    })
  );
//get purchase supplier  epic
const getPurchaseReturnSupplierEpic = (
  action$: Observable<Action>,
  _: stateAction,
  { dispatch }: dispatchAction
) =>
  action$.pipe(
    filter(getPurchaseReturnSupplierRequest.match),
    mergeMap(async (action) => {
      try {
        const response = await API.getPurchaseReturnSupplier(action.payload);
        return { payload: response.data };
      } catch (e) {
        return { error: e };
      }
    }),
    map((action) =>
      action?.payload
        ? getPurchaseReturnSuppliersSuccess(action?.payload)
        : getPurchaseReturnSuppliersFail()
    )
  );
// //get specific purchase according to the supplier iid
const getSpecificPurchaseEpic = (
  action$: Observable<Action>,
  _: stateAction,
  { dispatch }: dispatchAction
) =>
  action$.pipe(
    filter(getSpecificPurchaseRequest.match),
    mergeMap(async (action) => {
      try {
        const response = await API.getSpecificPurchases(action.payload);
        return { payload: response.data };
      } catch (e) {
        return { error: e };
      }
    }),
    map((action) =>
      action?.payload
        ? getSpecificPurchaseSuccess(action?.payload)
        : getSpecificPurchaseFail()
    )
  );
//get specific packing type
const getSelectedPackingTypeEpic = (
  action$: Observable<Action>,
  _: stateAction,
  { dispatch }: dispatchAction
) =>
  action$.pipe(
    filter(getSelectedPackingTypeRequest.match),
    mergeMap(async (action) => {
      try {
        const response = await API.getSelectedPackingTypeDetail(action.payload);
        return { payload: response.data };
      } catch (e) {
        return { error: e };
      }
    }),
    map((action) =>
      action?.payload
        ? getSelectedPackingTypesSuccess(action?.payload)
        : getSelectedPackingTypesFail()
    )
  );

//get purchase details
const getPurchaseDetailsEpic = (
  action$: Observable<Action>,
  _: stateAction,
  { dispatch }: dispatchAction
) =>
  action$.pipe(
    filter(getSpecificPurchaseDetailsRequest.match),
    mergeMap(async (action) => {
      try {
        const response = await API.getSpecificPurchaseDetails(action.payload);
        return { payload: response.data };
      } catch (e) {
        return { error: e };
      }
    }),
    map((action) =>
      action?.payload
        ? getSpecificPurchaseDetailsSuccess(action?.payload)
        : getSpecificPurchaseDetailsFail()
    )
  );
//get purchase details
const getPurchaseReturnPaymentModeEpic = (
  action$: Observable<Action>,
  _: stateAction
) =>
  action$.pipe(
    filter(getSpecificPurchaseDetailsRequest.match),
    mergeMap(async () => {
      try {
        const response = await API.getPurchaseReturnPaymentModes();
        return { payload: response.data };
      } catch (e) {
        return { error: e };
      }
    }),
    map((action) =>
      action?.payload
        ? getPurchaseReturnPaymentModeSuccess(action?.payload)
        : getPurchaseReturnPaymentModesFail()
    )
  );

//view details
const getViewPurchaseReturnDetailsEpic = (
  action$: Observable<Action>,
  _: stateAction,
  { dispatch }: dispatchAction
) =>
  action$.pipe(
    filter(getViewPurchaseReturnDetailsRequest.match),
    mergeMap(async (action) => {
      try {
        const response = await API.getPurchaseReturnViewDetails(action.payload);
        return { payload: response.data };
      } catch (e) {
        return { error: e };
      }
    }),
    map((action) =>
      action?.payload
        ? getViewPurchaseReturnDetailsSuccess(action?.payload)
        : getViewPurchaseReturnDetailsFail()
    )
  );
//get next
const getPurchaseReturnDetailNext = (
  action$: Observable<Action>,
  _: stateAction,
  { dispatch }: dispatchAction
) =>
  action$.pipe(
    filter(getViewPurchaseReturnDetailsNextRequest.match),
    mergeMap(async (action) => {
      try {
        const response = await getNext(action.payload);
        return { payload: response.data };
      } catch (e) {
        return { error: e };
      }
    }),
    map((action) =>
      action?.payload
        ? getViewPurchaseReturnDetailsSuccess(action?.payload)
        : getViewPurchaseReturnDetailsFail()
    )
  );
//get previous
const getPurchaseReturnDetailPrevious = (
  action$: Observable<Action>,
  _: stateAction,
  { dispatch }: dispatchAction
) =>
  action$.pipe(
    filter(getViewPurchaseReturnDetailsPreviousRequest.match),
    mergeMap(async (action) => {
      try {
        const response = await getPrevious(action.payload);
        return { payload: response.data };
      } catch (e) {
        return { error: e };
      }
    }),
    map((action) =>
      action?.payload
        ? getViewPurchaseReturnDetailsSuccess(action?.payload)
        : getViewPurchaseReturnDetailsFail()
    )
  );
//get purchase additional charge
const getPurchaseReturnAdditionalChargeEpic = (
  action$: Observable<Action>,
  _: stateAction,
  { dispatch }: dispatchAction
) =>
  action$.pipe(
    filter(getPurchaseReturnAdditionalChargeRequest.match),
    mergeMap(async (action) => {
      try {
        const response = await API.getPurchaseReturnAdditionalCharge();
        return { payload: response.data };
      } catch (e) {
        return { error: e };
      }
    }),
    map((action) =>
      action?.payload
        ? getPurchaseReturnAdditionalChargeSuccess(action?.payload)
        : getPurchaseReturnAdditionalChargeFail()
    )
  );

export const purchaseReturnEpics = combineEpics(
  getPurchaseReturnEpic,
  getPurchaseReturnSupplierEpic,
  createPurchaseReturnEpic,
  getSpecificPurchaseEpic,
  getPurchaseDetailsEpic,
  getSelectedPackingTypeEpic,
  getPurchaseReturnPaymentModeEpic,
  //view details
  getViewPurchaseReturnDetailsEpic,
  getPurchaseReturnDetailNext,
  getPurchaseReturnDetailPrevious,
  //additional charge
  getPurchaseReturnAdditionalChargeEpic
);
