import { createAction, createSlice } from "@reduxjs/toolkit";
import { branch } from "./types";
import setCookie from "../../../AppUtils/Utils/Cookies/setCookie";
import deleteCookie from "../../../AppUtils/Utils/Cookies/deleteCookie";
export interface AuthState {
  isAuthenticated: boolean;
  loadingAuth: boolean;
  branches: branch[];
  userName: string;
  userId: number | null;
  authError: boolean;
  isSuperuser: boolean;
  img: string | null;
  open: boolean;
  permissions: any;
  // for reset
  message: string;
  loadingReset: boolean;
  resetError: boolean;
  // for confirm
  loadingConfirm: boolean;
  isEmailVerified: boolean;
  verifyMessage: string;
  loadingVerifyEmail: boolean;
  verifyEmailError: boolean;


}
const initialState: AuthState = {
  isAuthenticated: false,
  loadingAuth: false,
  branches: [],
  permissions: [],
  userName: "",
  userId: null,
  authError: false,
  isSuperuser: false,
  img: null,
  open: false,
  message: "",
  loadingReset: false,
  resetError: false,
  loadingConfirm: false,
  isEmailVerified: false,
  verifyMessage: "",
  loadingVerifyEmail: false,
  verifyEmailError: false,

};

export const loginRequest = createAction(
  "loginRequest",
  function prepare(body) {
    return {
      payload: body,
    };
  }
);
export const getBranchRequest = createAction(
  "getBranchRequest",
  function prepare() {
    return {
      payload: {},
    };
  }
);

// export const getLoginRequestFail = createAction("loginRequestFail");
const Auth = createSlice({
  name: "authSlice",
  initialState,
  reducers: {
    loadingLogin: (state) => {
      state.loadingAuth = true;
    },
    loginSuccess: (state, action) => {
      setCookie("accessToken", action.payload.tokens.access, {
        //if https secure url header then set secure true
        // secure: true,
        // httpOnly: true,
        "max-age": 360000,
        sameSite: "Lax",
      });
      setCookie("refreshToken", action.payload.tokens.refresh, {
        // secure: true,
        // httpOnly: true,
        "max-age": 3600000,
        sameSite: "Lax",
      });
      setCookie("isAuthenticated", true, {
        // secure: true,
        //  httpOnly: false,
        "max-age": 3600000,
        sameSite: "Lax",
      });
      state.loadingAuth = false;
      state.userId = action?.payload?.id;
      state.isAuthenticated = true;
      state.userName = action.payload.userName;
      state.isSuperuser = action?.payload?.isSuperuser;
      state.authError = false;
      state.permissions = action?.payload?.groups?.flatMap(
        (permission: any) => permission.permissions
      );
      state.img = action.payload.photo;
      state.isEmailVerified = action?.payload?.isEmailVerified;
    },
    loginFail: (state) => {
      deleteCookie("refreshToken");
      deleteCookie("accessToken");
      deleteCookie("isAuthenticated");
      state.loadingAuth = false;
      state.userId = null;
      state.isAuthenticated = false;
      state.userName = "";
      state.isSuperuser = false;
      state.authError = true;
      state.permissions = [];
      state.img = null;
      state.isEmailVerified = false;
    },
    getBranchesSuccess: (state, { payload }) => {
      state.branches = payload;
    },
    getBranchesFail: (state) => { },
    resetPasswordReq: (state, payload) => {
      state.loadingReset = true;
      state.message = "";
      state.resetError = false;
    },
    resetPasswordSuccess: (state, { payload }) => {
      state.message = payload;
      state.loadingReset = false;
      state.resetError = false;
    },
    resetPasswordFail: (state) => {
      state.loadingReset = false;
      state.resetError = true;
    },
    // confirm password
    confirmPasswordReq: (state, payload) => {
      state.loadingConfirm = true;
    },
    confirmPasswordSuccess: (state, { payload }) => {
      state.loadingConfirm = false;
    },
    confirmPasswordFail: (state) => {
      state.loadingConfirm = false;
    },
    clearResetData: (state) => {
      state.message = "";
      state.verifyMessage = "";
    },
    // verify email
    verifyEmailLinkReq: (state, payload) => {
      state.loadingVerifyEmail = true;
      state.verifyMessage = "";
      state.verifyEmailError = false;
    },
    verifyEmailReq: (state, payload) => {
      state.loadingVerifyEmail = true;
      state.verifyMessage = "";
      state.verifyEmailError = false;
    },
    verifyEmailSuccess: (state, { payload }) => {
      state.verifyMessage = payload?.detail;
      state.loadingReset = false;
      state.verifyEmailError = false;
    },
    verifyEmailFail: (state) => {
      state.loadingVerifyEmail = false;
      state.verifyEmailError = true;
    },
  },
});
export const {
  loginSuccess,
  loadingLogin,
  loginFail,
  getBranchesSuccess,
  getBranchesFail,
  resetPasswordReq,
  resetPasswordSuccess,
  resetPasswordFail,
  confirmPasswordReq,
  confirmPasswordSuccess,
  confirmPasswordFail,
  clearResetData,
  verifyEmailReq,
  verifyEmailSuccess,
  verifyEmailFail,
  verifyEmailLinkReq,
} = Auth.actions;
export default Auth.reducer;
