import { lazy, Suspense, useRef } from "react";
import { Switch, FormControlLabel, FormGroup, Grid } from "@mui/material";
import { useImmer } from "use-immer";
import AppButton from "../AppElements/Button/AppButton";
import EventRoundedIcon from "@mui/icons-material/EventRounded";
import RefreshIcon from "@mui/icons-material/Refresh";
import { memo, useState } from "react";
import FilterAltIcon from "@mui/icons-material/FilterAlt";
import { NepaliDatePicker } from "nepali-datepicker-reactjs";
import MenuIcon from "@mui/icons-material/Menu";
// @ts-ignore
import { filterStyles } from "./filterStyles";
import {
  convertDateAdToBs,
  convertDateBsToAd,
  dateFormater,
  getTodayDateBs,
} from "../../AppUtils/Utils/dateFunctions";
import "./filterData.css";
import "nepali-datepicker-reactjs/dist/index.css";
import FilterColumns from "./FilterColumns";
import { columnProps } from "../../Pages/ViewDetails/CommonTable/types";
import { filterData, filterDataProps } from "./types";
import AppDatePicker from "../AppElements/AppDatePicker/AppDatePicker";
import { ModalProps } from "../Modal/types";
import AppAutoComplete from "../AppElements/AppAutoComplete/AppAutoComplete";
import {
  checkSelectionType,
  handleEnumValuesLabel,
} from "../../AppUtils/EnumValues/EnumValues";
import { enumTypes } from "../../AppUtils/EnumValues/types";
import { useAppSelector } from "../../AppUtils/Utils/appHooks";
import { commonAppSelector } from "../../Pages/CommonAppRedux/selector";
const Modal = lazy(() => import("../Modal"));

const FilterData = ({
  columns,
  handleFilterData,
  setSelectionType,
  selectionType,
}: filterDataProps) => {
  const [filterField, setFilterField] = useImmer<columnProps[]>([]);
  const [filterData, setFilterData] = useImmer<filterData>({
    fromDateAd: new Date().toDateString(),
    toDateAd: new Date().toDateString(),
    fromDateBs: getTodayDateBs(),
    toDateBs: getTodayDateBs(),
  });
  const focusToDateAd = useRef<HTMLInputElement>(null);
  const focusFromDateAd = useRef<HTMLInputElement>(null);
  const focusButton = useRef<HTMLButtonElement>(null);
  const {globalOrganizationRules:rule,hideSelectionType}=useAppSelector(commonAppSelector);

  //open menu for adding filter field
  const [menu, setMenu] = useImmer(false);
  // function when menu is clicked
  const handleFilterMenu = () => {
    setMenu(true);
  };
  const classes = filterStyles();
  // const checkADBS=rule?.fiscalSessionType === "AD"?true:false;
  const [dateTypeAd, setDateTypeAd] = useState(rule?.fiscalSessionType === "AD");
  let ModalProps: ModalProps = {
    title: "Set Filters",
    types: "filterData",
    maxWidth: "sm",
    showModal: menu,
    displayTitleOnly: true,
    setShowModal: setMenu,
  };
  // refresh
  const handleRefresh = () => {
    window.location.reload();
  };
  // filter data according to the field values
  const handleFilterApiData = () => {
    // optional chaining return undefined or null instead throwing error
    handleFilterData?.({ ...filterData, filterField });
    // close modal
    setMenu(false);
  };
  // handle selection type
  const handleSelectionType = (_: object, value: enumTypes) => {
    setSelectionType(value?.id);
  };
  return (
    <>
      <Grid
        container
        spacing={2}
        className={classes.filterContainer}
        id="filter-container"
      >
        <Grid
          item
          xs={1.5}
          lg={1}
          md={1.5}
          className={classes.checkboxContainer}
          style={{marginTop:15}}
        >
          <FormGroup>
            <FormControlLabel
              control={<Switch defaultChecked />}
              label={`${dateTypeAd ? "AD" : "BS"}`}
              onChange={() => setDateTypeAd(!dateTypeAd)}
            />
          </FormGroup>
        </Grid>
        {dateTypeAd ? (
          <>
            <AppDatePicker
              value={filterData.fromDateAd}
              xs={3}
              md={3}
              lg={2}
              applyMarginTop
              isNotFormik
              onChange={(value: Date | null) => {
                let dateAd = dateFormater(value!);
                if (dateAd?.length > 8) {
                  setFilterData((draft: filterData) => {
                    draft.fromDateAd = dateAd;
                    draft.fromDateBs = convertDateAdToBs(dateAd);
                  });
                }
              }}
              inputRef={focusFromDateAd}
              focusElement={focusToDateAd}
              label="From Date AD"
              name="fromDateAd"
            />
            <AppDatePicker
              value={filterData.toDateAd}
              lg={2}
              md={3}
              xs={3}
              applyMarginTop
              isNotFormik
              onChange={(value: Date | null) => {
                let dateAd = dateFormater(value!);
                if (dateAd?.length > 8) {
                  setFilterData((draft: filterData) => {
                    draft.toDateAd = dateAd;
                    draft.toDateBs = convertDateAdToBs(dateAd);
                  });
                }
              }}
              inputRef={focusToDateAd}
              onKeyPress={(e: React.KeyboardEvent<HTMLDivElement>) => {
                if (e.key === "Enter") {
                  if (null !== focusButton.current) {
                    focusButton.current.click();
                  }
                }
              }}
              label="To Date AD"
              name="toDateAd"
            />
          </>
        ) : (
          <>
            <Grid item xs={2}>
              <fieldset className="filter-fieldset">
                <legend>From Date BS</legend>
                <div className="nepaliDate-icon-wrapper">
                  <NepaliDatePicker
                    className="bs-date-picker"
                    value={filterData.fromDateBs}
                    onChange={(value: string) => {
                      setFilterData((draft: filterData) => {
                        draft.fromDateBs = value;
                        draft.fromDateAd = convertDateBsToAd(value);
                      });
                    }}
                    options={{
                      calenderLocale: "en",
                      valueLocale: "en",
                    }}
                  />
                  <EventRoundedIcon />
                </div>
              </fieldset>
            </Grid>
            <Grid item xs={2}>
              <fieldset className="filter-fieldset">
                <legend>To Date BS</legend>
                <div className="nepaliDate-icon-wrapper">
                  <NepaliDatePicker
                    className="bs-date-picker"
                    value={filterData.toDateBs}
                    onChange={(value: string) => {
                      setFilterData((draft: filterData) => {
                        draft.toDateBs = value;
                        draft.toDateAd = convertDateBsToAd(value);
                      });
                    }}
                    options={{
                      calenderLocale: "en",
                      valueLocale: "en",
                    }}
                  />
                  <EventRoundedIcon />
                </div>
              </fieldset>
            </Grid>
          </>
        )}
        <Grid item className={classes.filterButton}>
          <AppButton
            tooltipTitle="Filter"
            reference={focusButton}
            onClick={handleFilterApiData}
          >
            <FilterAltIcon />
          </AppButton>
        </Grid>
        <Grid item className={classes.filterButton}>
          <AppButton
            name="filter-menu"
            tooltipTitle="Filters Menu"
            onClick={handleFilterMenu}
          >
            <MenuIcon />
          </AppButton>
        </Grid>
        <Grid item className={classes.filterButton}>
          <AppButton tooltipTitle="Refresh" onClick={handleRefresh}>
            <RefreshIcon />
          </AppButton>
        </Grid>
        {(setSelectionType && !hideSelectionType)&& (
          <AppAutoComplete
            xs={1.55}
            mt={0.9}
            options={checkSelectionType}
            // set value according to the selection type
            value={checkSelectionType[selectionType! - 1]}
            name="gender"
            getOptionLabel={handleEnumValuesLabel}
            label="Selection Type"
            onChange={handleSelectionType}
            isNotFormik
            // inputRef={focusGender}
            // focusElement={focusGroup}
            // onBlur={handleBlur}
          />
        )}
      </Grid>
      {menu && (
        <Suspense fallback={<></>}>
          <Modal {...ModalProps}>
            <FilterColumns
              columns={columns}
              filterField={filterField}
              setFilterField={setFilterField}
              handleFilterApiData={handleFilterApiData}
            />
          </Modal>
        </Suspense>
      )}
    </>
  );
};
export default memo(FilterData);
