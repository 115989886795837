import {
  getPatientListRequest,
  getPatientListSuccess,
  getPatientListFail,
  getFilteredPatientRequest,
  getFilteredPatientSuccess,
  getFilteredPatientFail,
  getPatientListByIdSuccess,
  getPatientListByIdFail,
  getPatientListByIdRequest,
} from "./patientDetailSlice";
import { mergeMap } from "rxjs";
import { getPatientLists, getFilteredPatient, getPatientListsById } from "./api";

import { map, filter} from "rxjs/operators";
import { Observable } from "rxjs";
import { Action } from "@reduxjs/toolkit";
import { combineEpics } from "redux-observable";
import {
  dispatchAction,
  stateAction,
} from "../../../../AppUtils/Utils/globalTypes";

export const controller = new AbortController();

// get patient list epic
const getPatientListEpic = (
  action$: Observable<Action>,
  _: stateAction,
  { dispatch }: dispatchAction
) =>
  action$.pipe(
    filter(getPatientListRequest.match),
    mergeMap(async (action) => {
      try {
        const response = await getPatientLists(action.payload);
        return { payload: response.data };
      } catch (e) {
        return { error: e };
      }
    }),
    map((action) =>
      action?.payload
        ? getPatientListSuccess(action?.payload)
        : getPatientListFail()
    )
  );

// get filtered patient list
const getFilteredPatientListEpic = (
  action$: Observable<Action>,
  _: stateAction,
  { dispatch }: dispatchAction
) =>
  action$.pipe(
    filter(getFilteredPatientRequest.match),
    mergeMap(async (action) => {
      try {
        const response = await getFilteredPatient(action.payload);
        return { payload: response.data };
      } catch (e) {
        return { error: e };
      }
    }),
    map((action) =>
      action?.payload
        ? getFilteredPatientSuccess(action?.payload)
        : getFilteredPatientFail()
    )
  );


  const getPatientListByIdEpic = (
    action$: Observable<Action>,
    _: stateAction,
    { dispatch }: dispatchAction
  ) =>
    action$.pipe(
      filter(getPatientListByIdRequest.match),
      mergeMap(async (action) => {
        try {
          const response = await getPatientListsById(action.payload);
          return { payload: response.data };
        } catch (e) {
          return { error: e };
        }
      }),
      map((action) =>
        action?.payload
          ? getPatientListByIdSuccess(action?.payload)
          : getPatientListByIdFail()
      )
    );


export const patientListEpics = combineEpics(
  getPatientListEpic,
  getFilteredPatientListEpic,
  getPatientListByIdEpic
);
