import {
  Checkbox,
  TableCell,
  TableHead,
  TableRow,
  TableSortLabel,
} from "@mui/material";
import React, { memo, useState } from "react";
import IconButton from "@mui/material/IconButton";
import DragIndicatorIcon from "@mui/icons-material/DragIndicator";
import { tableHeaderProps } from "../types";
import ColumnPreferences from "../ColumnPreferences";
import TooltipContent from "../../Tooltip/TooltipContent";

const AppTableHeader = (props: tableHeaderProps) => {
  const [open, setOpen] = useState(false);
  const {
    order,
    columns,
    orderBy,
    onRequestSort,
    hideAction,
    hideSort,
    hidePreferences,
    setSelected,
    showSelectAllCheckBox,
    // rowCount,
    checkBoxData,
    data,
    // multipleSelect,
  } = props;

  const createSortHandler =
    (property: any) => (event: React.MouseEvent<unknown>) => {
      onRequestSort(event, property);
    };
  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleSelectAllClick = (event: React.ChangeEvent<HTMLInputElement>) => {
    if (event.target.checked) {
      const selectedHead = data?.map((n: any) => n.id);
      setSelected(selectedHead);
      return;
    }
    setSelected([]);
  };
  return (
    <>
      <TableHead>
        <TableRow>
          <TableCell style={{ minWidth: 5, fontSize: 17 }}>#</TableCell>
          {columns?.map((headCell) => (
            <TableCell
              key={headCell.id}
              align={headCell.numeric ? "right" : "left"}
              padding={headCell.disablePadding ? "none" : "normal"}
              sortDirection={orderBy === headCell.id ? order : false}
              style={{
                minWidth: headCell.minWidth as number,
                fontSize: 13.5,
                fontWeight: "bold",
                // padding: 0,
              }}
            >
              {!hideSort ? (
                <TableSortLabel
                  active={orderBy === headCell.id}
                  direction={orderBy === headCell.id ? order : "asc"}
                  onClick={createSortHandler(headCell.id)}
                >
                  {headCell.label}
                </TableSortLabel>
              ) : (
                <>{headCell.label}</>
              )}
            </TableCell>
          ))}

          {!hideAction && (
            <TableCell
              // className={classes.headCell}
              style={{
                minWidth: 10,
                fontSize: 14,
                fontWeight: "bold",
                padding: 0,
              }}
            >
              Actions
            </TableCell>
          )}
          {showSelectAllCheckBox === true && (
            <TableCell
              style={{
                minWidth: 10,
                fontSize: 14,
                fontWeight: "bold",
                padding: 0,
              }}
            >
              <Checkbox
                color="primary"
                indeterminate={
                  checkBoxData?.length > 0 &&
                  checkBoxData?.length < data?.length
                }
                checked={
                  checkBoxData?.length > 0 &&
                  checkBoxData?.length === data?.length
                }
                onChange={handleSelectAllClick}
                inputProps={{
                  "aria-label": "select all",
                }}
              />
            </TableCell>
          )}
          {!hidePreferences && (
            <TableCell>
              <TooltipContent title="Column Preference">
                <IconButton onClick={handleClickOpen}>
                  <DragIndicatorIcon />
                </IconButton>
              </TooltipContent>
            </TableCell>
          )}
        </TableRow>
      </TableHead>
      <ColumnPreferences open={open} setOpen={setOpen} columns={columns} />
    </>
  );
};
export default memo(AppTableHeader);
