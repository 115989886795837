import { createSlice } from "@reduxjs/toolkit";
import { verificationAndPrintIState } from "./types";
const initialState: verificationAndPrintIState = {
  verificationAndPrints: [],
  resultEntryTests: [],
  updatedResultEntryTests: [],
  filteredResultEntryTests: [],
  loadingResultEntryTests: false,
  edit: false,
  verificationAndPrint: null,
  loadingVerificationAndPrint: false,
  loadingResultEnteredTestsDetails: false,
  resultEnteredTestsDetails: [],
  loadingResultEnteredReportFooter: false,
  resultEnteredReportFooter: [],
  loadingCreateVerificationAndPrint: false,
  loadingPrintCount: false,
  includeSignature: false,
  closeVerificationAndPrintModal: false,
  count: 0,
  next: "",
  previous: "",
};
export const VerificationAndPrint = createSlice({
  name: "verificationAndPrintReducer",
  initialState,
  reducers: {
    getVerificationAndPrintRequest: (state, payload) => {
      state.loadingVerificationAndPrint = true;
    },
    getVerificationAndPrintSuccess: (
      state,
      { payload: { results, count, next, previous } }
    ) => {
      state.loadingVerificationAndPrint = false;
      state.verificationAndPrints = results;
      state.closeVerificationAndPrintModal = false;
      state.count = count;
      state.next = next;
      state.previous = previous;
    },
    getVerificationAndPrintFail: (state) => {
      state.loadingVerificationAndPrint = false;
    },
    getVerificationAndPrintNextRequest: (state, payload) => {
      state.loadingVerificationAndPrint = true;
    },
    getVerificationAndPrintPreviousRequest: (state, payload) => {
      state.loadingVerificationAndPrint = true;
    },
    updateVerificationAndPrintRequest: (state, payload) => {
      state.loadingCreateVerificationAndPrint = true;
    },
    getVerificationAndPrintByIdRequest: (state, payload) => {
      state.edit = true;
    },
    verificationAndPrintEditSuccess: (state, { payload }) => {
      state.verificationAndPrint = payload;
    },
    verificationAndPrintEditFail: (state) => {
      state.edit = false;
    },
    updateVerificationAndPrintSuccess: (state) => {
      state.loadingCreateVerificationAndPrint = false;
    },
    updateVerificationAndPrintFail: (state) => {
      state.loadingCreateVerificationAndPrint = false;
    },
    getVerificationAndPrintCreatedResultEntryTestRequest: (state, payload) => {
      state.loadingResultEntryTests = true;
    },
    verificationAndPrintCreatedResultEntryTestSuccess: (
      state,
      { payload: { results } }
    ) => {
      state.resultEntryTests = results;
      state.loadingResultEntryTests = false;
    },
    verificationAndPrintCreatedResultEntryTestFail: (state) => {
      state.loadingResultEntryTests = false;
    },
    getResultEnteredTestDetailsRequest: (state, payload) => {
      state.loadingResultEnteredTestsDetails = true;
    },
    getResultEnteredTestDetailsSuccess: (state, { payload }) => {
      state.resultEnteredTestsDetails = payload;
      state.loadingResultEnteredTestsDetails = false;
    },
    getResultEnteredTestDetailsFail: (state) => {
      state.loadingResultEnteredTestsDetails = false;
    },
    getResultEnteredReportFooterRequest: (state, payload) => {
      state.loadingResultEnteredReportFooter = true;
    },
    getResultEnteredReportFooterSuccess: (state, { payload: { results } }) => {
      state.resultEnteredReportFooter = results;
      state.loadingResultEnteredReportFooter = false;
    },
    getResultEnteredReportFooterFail: (state) => {
      state.loadingResultEnteredReportFooter = false;
    },
    verifyPrintReportRequest: (state, payload) => {
      state.loadingCreateVerificationAndPrint = true;
    },
    verifyPrintReportSuccess: (state) => {
      state.loadingCreateVerificationAndPrint = false;
      state.closeVerificationAndPrintModal = true;
    },
    verifyPrintReportFail: (state) => {
      state.loadingCreateVerificationAndPrint = false;
    },
    unApproveReportRequest: (state, payload) => {
      state.loadingCreateVerificationAndPrint = true;
    },
    unApproveReportSuccess: (state) => {
      state.loadingCreateVerificationAndPrint = false;
      state.closeVerificationAndPrintModal = true;
    },
    unApproveReportFail: (state) => {
      state.loadingCreateVerificationAndPrint = false;
    },
    printCountRequest: (state, payload) => {
      state.loadingPrintCount = true;
    },
    printCountSuccess: (state) => {
      state.loadingPrintCount = false;
    },
    printCountFail: (state) => {
      state.loadingPrintCount = false;
    },
    sendLabReportEmailRequest: (state, payload) => {
      state.loadingCreateVerificationAndPrint = true;
    },
    sendLabReportEmailSuccess: (state) => {
      state.loadingCreateVerificationAndPrint = false;
      state.closeVerificationAndPrintModal = true;
    },
    sendLabReportEmailFail: (state) => {
      state.loadingCreateVerificationAndPrint = false;
    },
    clearVerificationAndPrintData: (state) => {
      state.edit = false;
      state.verificationAndPrint = null;
      state.resultEnteredTestsDetails = [];
    },
    includeSignatureRequest: (state, { payload }) => {
      state.includeSignature = payload;
    },
    updatedResultEntryTestsData: (state, { payload }) => {
      state.updatedResultEntryTests = payload;
    },
    getFilteredDepartmentWiseCustomerTests: (state, { payload: { id } }) => {
      state.filteredResultEntryTests = state?.updatedResultEntryTests?.filter(
        (resultEntry) => resultEntry?.department === id
      );
    },
    clearResultEntryTestsData: (state) => {
      state.resultEntryTests = [];
    },
  },
});
//action creators generated for each case reducer
export const {
  getVerificationAndPrintRequest,
  getVerificationAndPrintSuccess,
  getVerificationAndPrintFail,
  getVerificationAndPrintPreviousRequest,
  getVerificationAndPrintNextRequest,
  verificationAndPrintEditSuccess,
  updateVerificationAndPrintSuccess,
  updateVerificationAndPrintFail,
  clearVerificationAndPrintData,
  updateVerificationAndPrintRequest,
  getVerificationAndPrintByIdRequest,
  verificationAndPrintEditFail,
  getVerificationAndPrintCreatedResultEntryTestRequest,
  verificationAndPrintCreatedResultEntryTestFail,
  verificationAndPrintCreatedResultEntryTestSuccess,
  getResultEnteredTestDetailsRequest,
  getResultEnteredTestDetailsFail,
  getResultEnteredTestDetailsSuccess,
  getResultEnteredReportFooterRequest,
  getResultEnteredReportFooterSuccess,
  getResultEnteredReportFooterFail,
  verifyPrintReportRequest,
  verifyPrintReportFail,
  verifyPrintReportSuccess,
  unApproveReportRequest,
  unApproveReportSuccess,
  unApproveReportFail,
  printCountRequest,
  printCountSuccess,
  printCountFail,
  getFilteredDepartmentWiseCustomerTests,
  includeSignatureRequest,
  // send email
  sendLabReportEmailRequest,
  sendLabReportEmailFail,
  sendLabReportEmailSuccess,
  clearResultEntryTestsData,
  updatedResultEntryTestsData,
} = VerificationAndPrint.actions;

export default VerificationAndPrint.reducer;
