import { createSlice } from "@reduxjs/toolkit";
import { creditClearanceInitialState } from "./types";

const initialState: creditClearanceInitialState = {
  creditClearance: null,
  creditClearances: [],
  loadingCreditClearance: false,
  loadingCreateCreditClearance: false,
  loadingCustomer: false,
  loadingCreditClearanceDetails: false,
  edit: false,
  count: 0,
  next: "",
  previous: "",
  customers: [],
  loadingCreditClearanceMode: false,
  creditClearanceModes: [],
  customerWises:null,
  loadingCustomerWise:false,
  loadingSalesWise:false,
  saleWise:[],
  saleSelectedData:[],
  loadingSaleSelectedData:false,
  creditClearancePrintDetailsData: null,
  loadingCreditClearancePrintDetails: false,
  creditClearancePrintCount: [],
  creditClearanceRowData: null,
  creditClearanceViewDetails: [],
  loadingCreditClearanceViewDetails: false,
};

export const CreditClearance = createSlice({
  name: "CreditClearanceReducer",
  initialState,
  reducers: {
    loadingCreditClearance: (state) => {
      state.loadingCreditClearance = true;
    },
    getCreditClearanceRequest: (state, payload) => {
      state.loadingCreditClearance = true;
    },
    getCreditClearanceSuccess: (
      state,
      { payload: { results, count, next, previous } }
    ) => {
      state.loadingCreditClearance = false;
      state.creditClearances = results;
      state.count = count;
      state.next = next;
      state.previous = previous;
    },
    getCreditClearanceFail: (state) => {
      state.loadingCreditClearance = false;
    },
    //
    loadingCreditClearanceCustomerRequest: (state) => {
      state.loadingCustomer = true;
    },
    getCreditClearanceCustomerRequest: (state) => {
      state.loadingCustomer = true;
    },
    getCreditClearanceCustomerSuccess: (state, { payload: { results } }) => {
      state.loadingCustomer = false;
      state.customers = results;
    },
    getCreditClearanceCustomerFail: (state) => {
      state.loadingCustomer = false;
    },
    //
    loadingCreditClearanceCustomerWiseRequest: (state) => {
      state.loadingCustomerWise = true;
    },
    getCreditClearanceCustomerWiseRequest: (state,{payload}) => {
      state.loadingCustomerWise = true;
    },
    getCreditClearanceCustomerWiseSuccess: (state, { payload}) => {
      state.loadingCustomerWise = false;
      state.customerWises = payload;
    },
    getCreditClearanceCustomerWiseFail: (state) => {
      state.loadingCustomerWise = false;
    },
    //
    loadingCreditClearanceSalesWiseRequest: (state) => {
      state.loadingSalesWise = true;
    },
    getCreditClearanceSalesWiseRequest: (state,{payload}) => {
      state.loadingSalesWise = true;
    },
    getCreditClearanceSalesWiseSuccess: (state, { payload: { results,count,next,previous } }) => {
      state.loadingSalesWise = false;
      state.saleWise = results;
      state.count = count;
      state.next = next;
      state.previous = previous;
    },
    getCreditClearanceSalesWiseFail: (state) => {
      state.loadingSalesWise = false;
    },
    getCreditClearanceSalesWiseNextRequest: (state, payload) => {
      state.loadingSalesWise = true;
    },
    getCreditClearanceSalesWisePreviousRequest: (state, payload) => {
      state.loadingSalesWise = true;
    },
    //
    getCreditClearanceNextRequest: (state, payload) => {
      state.loadingCreditClearance = true;
    },
    getCreditClearancePreviousRequest: (state, payload) => {
      state.loadingCreditClearance = true;
    },
    //
    createCreditClearanceRequest: (state, payload) => {
      state.loadingCreateCreditClearance = true;
    },
    createCreditClearanceSuccess: (state) => {
      state.loadingCreateCreditClearance = false;
    },
    createCreditClearanceFail: (state) => {
      state.loadingCreateCreditClearance = false;
    },
    //
    getCreditClearanceByIdRequest: (state, payload) => {
      state.edit = true;
    },
    creditClearanceEditSuccess: (state, { payload }) => {
      state.creditClearance = payload;
    },
    creditClearanceEditFail: (state) => {
      state.edit = false;
    },
    //
    updateCreditClearanceRequest: (state, payload) => {
      state.loadingCreateCreditClearance = true;
    },
    updateCreditClearanceSuccess: (state) => {
      state.loadingCreateCreditClearance = false;
    },
    updateCreditClearanceFail: (state) => {
      state.loadingCreateCreditClearance = false;
    },
    //payment mode
    getCreditClearanceModeRequest: (state) => {
      state.loadingCreditClearanceMode = true;
    },
    getCreditClearanceModeSuccess: (state, { payload }) => {
      state.loadingCreditClearanceMode = false;
      state.creditClearanceModes = payload.results;
    },
    getCreditClearanceModeFail: (state) => {
      state.loadingCreditClearanceMode = false;
    },
    //
    clearCreditClearanceData: (state) => {
      state.edit = false;
      state.creditClearance = null;
      state.saleSelectedData=[];
      state.customerWises=null;
    },
    ///
    saleSelectedAddedSuccess: (state, { payload }) => {
      state.saleSelectedData = [...state.saleSelectedData, payload];
      state.loadingSaleSelectedData = false;
    },
    saleSelectedDeleteSuccess: (state, { payload }) => {
      const updatedTestDetails = state.saleSelectedData.filter(
        (test: any) => test.id !== payload.id
      );
      state.saleSelectedData = updatedTestDetails;
      state.loadingSaleSelectedData = false;
    },
    saleSelectedDataClear:(state)=>{
      state.saleSelectedData=[];
    },
    customerWiseDataClear:(state)=>{
      state.customerWises=null;
    },
  // print details
    getCreditClearancePrintDetailsRequest: (state, payload)=>{
      state.loadingCreditClearancePrintDetails = true
    },
    getCreditClearancePrintDetailsSuccess: (state, {payload})=>{
      state.loadingCreditClearancePrintDetails = false
      state.creditClearancePrintDetailsData = payload
    },
    getCreditClearancePrintDetailsFail: (state)=>{
      state.loadingCreditClearancePrintDetails = false
    },

    //
    getCreditClearancePrintCountRequest: (state, payload)=>{
      state.loadingCreditClearancePrintDetails = true
    },
    getCreditClearancePrintCountSuccess: (state, {payload: {results}})=>{
      state.loadingCreditClearancePrintDetails = false
      state.creditClearancePrintCount = results
    },
    getCreditClearancePrintCountFail: (state)=>{
      state.loadingCreditClearancePrintDetails = false
    },
    //
    creditClearancePrintCountRequest: (state, payload)=>{
      state.loadingCreditClearancePrintDetails = true
    },
    creditClearancePrintCountSuccess: (state, payload)=>{
      state.loadingCreditClearancePrintDetails = true
    },
    creditClearancePrintCountFail: (state, payload)=>{
      state.loadingCreditClearancePrintDetails = true
    },
  },
});
// Action creators are generated for each case reducer function
export const {
  loadingCreditClearance,
  getCreditClearanceRequest,
  getCreditClearanceSuccess,
  getCreditClearanceFail,
  //
  getCreditClearanceNextRequest,
  getCreditClearancePreviousRequest,
  //
  createCreditClearanceRequest,
  createCreditClearanceSuccess,
  createCreditClearanceFail,
  //
  getCreditClearanceByIdRequest,
  creditClearanceEditSuccess,
  creditClearanceEditFail,
  //
  updateCreditClearanceRequest,
  updateCreditClearanceSuccess,
  updateCreditClearanceFail,
  //
  clearCreditClearanceData,
  //
  loadingCreditClearanceCustomerRequest,
  getCreditClearanceCustomerRequest,
  getCreditClearanceCustomerSuccess,
  getCreditClearanceCustomerFail,
  //
  loadingCreditClearanceCustomerWiseRequest,
  getCreditClearanceCustomerWiseRequest,
  getCreditClearanceCustomerWiseSuccess,
  getCreditClearanceCustomerWiseFail,
  //
  loadingCreditClearanceSalesWiseRequest,
  getCreditClearanceSalesWiseRequest,
  getCreditClearanceSalesWiseSuccess,
  getCreditClearanceSalesWiseFail,
  getCreditClearanceSalesWiseNextRequest,
  getCreditClearanceSalesWisePreviousRequest,
  //payment mode
  getCreditClearanceModeRequest,
  getCreditClearanceModeSuccess,
  getCreditClearanceModeFail,
  //
  saleSelectedDeleteSuccess,
  saleSelectedAddedSuccess,
  //
  saleSelectedDataClear,
  customerWiseDataClear,

  //
  getCreditClearancePrintDetailsRequest,
  getCreditClearancePrintDetailsSuccess,
  getCreditClearancePrintDetailsFail,

  //
  getCreditClearancePrintCountRequest,
  getCreditClearancePrintCountSuccess,
  getCreditClearancePrintCountFail,

  //
  creditClearancePrintCountRequest,
  creditClearancePrintCountSuccess,
  creditClearancePrintCountFail,

} = CreditClearance.actions;
export default CreditClearance.reducer;
