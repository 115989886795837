import { axiosInstance } from "../../../../AppUtils/Utils/axios";
import { filterApiData } from "../../../../AppUtils/Utils/appFunctions";
import {
  getApiProps,
  searchProps,
} from "../../../../AppUtils/Utils/globalTypes";
import { defaultRowsPerPage } from "../../../../AppUtils/Utils/globalConstant";
export const purchaseURL = "api/v1/inventory-purchase-app/direct-purchase";
//get purchase  data api
export const getPurchase = (data: getApiProps) => {
  const { rowsPerPage, page } = data;
  return axiosInstance.get(
    `${purchaseURL}?offset=${rowsPerPage * page}&limit=${rowsPerPage}${data?.filterData ? filterApiData(data?.filterData) : ""
    }`
  );
};
//handle search
export const searchPurchase = ({ rowsPerPage, search }: searchProps) => {
  return `${purchaseURL}?offset=0&limit=${rowsPerPage}&search=${search}`;
};
//create purchase
export const createPurchase = (body: string | FormData) =>
  axiosInstance.post(`${purchaseURL}`, body);
//update purchase
export const updatePurchase = (body: string | FormData, id: number) =>
  axiosInstance.patch(`${purchaseURL}/${id}`, body);

// get purchase edit details
export const getPurchaseEditDetailsById = (id: number) =>
  axiosInstance.get(`${purchaseURL}/${id}`);

export const getPurchaseVerifyById = (id: number) =>{
  return  axiosInstance.patch(`${purchaseURL}/${id}/verify`)
}

export const deletePurchaseSerialNoById = (id: number) =>{
  return  axiosInstance.delete(`${purchaseURL}/purchase-detail/serial-no/${id}`)
}
export const deletePurchaseTableDetailNoById = (id: number) =>{
  return  axiosInstance.delete(`${purchaseURL}/purchase-detail/${id}`)
}
  // get purchase edit details
  export const getPurchaseEditDetails = (id: number) =>
    axiosInstance.get(`${purchaseURL}/purchase-detail?purchase_main=${id}`);
  //get purchase supplier
  export const getPurchaseSupplier = ({ rowsPerPage, id }: any) =>
    axiosInstance.get(
      `${purchaseURL}/supplier?offset=0&limit=${rowsPerPage}&id=${id}`
    );
  //get purchase item
  export const getPurchaseItem = (rowsPerPage: number) =>
    axiosInstance.get(`${purchaseURL}/item?offset=0&limit=${rowsPerPage}`);
  //get purchase packing type detail
  export const getPurchasePackingTypeDetail = (id: number) =>
    axiosInstance.get(`${purchaseURL}/packing-type-detail?item=${id}`);
  //get purchase payment mode
  export const getPurchasePaymentMode = () =>
    axiosInstance.get(`${purchaseURL}/payment-mode?offset=0&limit=0`);
  // get purchase organization rule
  export const getPurchaseOrganizationRule = () =>
    axiosInstance.get(`${purchaseURL}/organization-rule`);
  // get purchase details
  export const getPurchaseViewDetails = (data: getApiProps) => {
    const { rowsPerPage, page, id } = data;
    return axiosInstance.get(
      `${purchaseURL}/purchase-detail?purchase_main=${id}&offset=${rowsPerPage * page
      }&limit=${rowsPerPage}`
    );
  };
  //additional charge
  export const getPurchaseAdditionalCharge = () =>
    axiosInstance.get(`${purchaseURL}/additional-charge-type?offset=0&limit=0`);
  //discount scheme
  export const getPurchaseDiscountScheme = () =>
    axiosInstance.get(
      `${purchaseURL}/discount-scheme?offset=0&limit=${defaultRowsPerPage}`
    );
