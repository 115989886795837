import {
  getIRDSyncFail,
  getIRDSyncSuccess,
  loadingIRDSync,
  getIRDSyncRequest,
  getIRDSyncNextRequest,
  getIRDSyncPreviousRequest,
  postIRDSyncRequest,
  postIRDSyncUploadSuccess,
} from "./irdSyncSlice";
import { mergeMap } from "rxjs";
import {
  getIRDSync, postIRDSyncApi,
} from "./api";
import { map, filter } from "rxjs/operators";
import { Observable } from "rxjs";
import { Action } from "@reduxjs/toolkit";
import { combineEpics } from "redux-observable";
import { dispatchAction, stateAction } from "../../../../../AppUtils/Utils/globalTypes";
import { getNext, getPrevious } from "../../../../CommonAppRedux/api";
import { alertErrorAction, alertSuccessAction } from "../../../../CommonAppRedux/CommonAppSlice";
import { defaultRowsPerPage,defaultPage } from "../../../../../AppUtils/Utils/globalConstant";

export const controller = new AbortController();
//get IRDSyncType epic
const getIRDSyncEpic = (
  action$: Observable<Action>,
  _: stateAction,
  { dispatch }: dispatchAction
) =>
  action$.pipe(
    filter(getIRDSyncRequest.match),
    mergeMap(async (action) => {
      dispatch(loadingIRDSync());
      try {
        const response = await getIRDSync(action.payload);
        return { payload: response.data };
      } catch (e) {
        return { error: e };
      }
    }),
    map((action) =>
      action?.payload
        ? getIRDSyncSuccess(action?.payload)
        : getIRDSyncFail()
    )
);

//get next
const getIRDSyncNext = (
  action$: Observable<Action>,
  _: stateAction,
  { dispatch }: dispatchAction
) =>
  action$.pipe(
    filter(getIRDSyncNextRequest.match),
    mergeMap(async (action) => {
      dispatch(loadingIRDSync());
      try {
        const response = await getNext(action.payload);
        return { payload: response.data };
      } catch (e) {
        return { error: e };
      }
    }),
    map((action) =>
      action?.payload
        ? getIRDSyncSuccess(action?.payload)
        : getIRDSyncFail()
    )
  );
//get previous
const getIRDSyncPrevious = (
  action$: Observable<Action>,
  _: stateAction,
  { dispatch }: dispatchAction
) =>
  action$.pipe(
    filter(getIRDSyncPreviousRequest.match),
    mergeMap(async (action) => {
      dispatch(loadingIRDSync());
      try {
        const response = await getPrevious(action.payload);
        return { payload: response.data };
      } catch (e) {
        return { error: e };
      }
    }),
    map((action) =>
      action?.payload
        ? getIRDSyncSuccess(action?.payload)
        : getIRDSyncFail()
    )
  );
  const postIRDSync = (
    action$: Observable<Action>,
    _: stateAction,
    { dispatch }: dispatchAction
  ) =>
    action$.pipe(
      filter(postIRDSyncRequest.match),
      mergeMap(async (action) => {
        dispatch(loadingIRDSync());
        try {
 
          const response = await postIRDSyncApi();
          // dispatch(getIRDSyncRequest({defaultRowsPerPage, 0}));
          dispatch(alertSuccessAction("IRD Sync Post"))
          dispatch(getIRDSyncRequest({ defaultRowsPerPage, defaultPage }))
          return { payload: response };
        } catch (e) {
          dispatch(alertErrorAction("IRD Sync Not Post"))
          return { error: e };
        }
      }),
      map((action) =>
        action?.payload
          ? postIRDSyncUploadSuccess()
          : dispatch(alertErrorAction("IRD Sync Not Post"))
      )
    );

export const IRDSyncEpics = combineEpics(
  getIRDSyncEpic,
  getIRDSyncNext,
  getIRDSyncPrevious,
  postIRDSync,
);
