import {
  createTestMethodFail,
  createTestMethodRequest,
  createTestMethodSuccess,
  getTestMethodFail,
  getTestMethodSuccess,
  loadingTestMethod,
  updateTestMethodFail,
  updateTestMethodSuccess,
  getTestMethodRequest,
  getTestMethodNextRequest,
  getTestMethodPreviousRequest,
  updateTestMethodRequest,
  clearTestMethodData,
  getTestMethodByIdRequest,
  testMethodEditSuccess,
  testMethodEditFail,
} from "./testMethodSlice";
import { mergeMap } from "rxjs";
import {
  createTestMethod,
  getTestMethod,
  getTestMethodById,
  updateTestMethod,
} from "./api";
import { map, filter } from "rxjs/operators";
import { Observable } from "rxjs";
import { Action } from "@reduxjs/toolkit";
import { combineEpics } from "redux-observable";
import {
  alertErrorAction,
  alertSuccessAction,
  closeModal,
} from "../../../CommonAppRedux/CommonAppSlice";
import { getNext, getPrevious } from "../../../CommonAppRedux/api";
import {
  dispatchAction,
  stateAction,
} from "../../../../AppUtils/Utils/globalTypes";
import messages from "../../../../AppUtils/Utils/validationConstants";
export const controller = new AbortController();
// import { searchData } from "../../../CommonAppRedux/commonAppFunctions";
//get TestMethodType epic
const getTestMethodEpic = (
  action$: Observable<Action>,
  _: stateAction,
  { dispatch }: dispatchAction
) =>
  action$.pipe(
    filter(getTestMethodRequest.match),
    mergeMap(async (action) => {
      dispatch(loadingTestMethod());
      try {
        const response = await getTestMethod(action.payload);
        return { payload: response.data };
      } catch (e) {
        return { error: e };
      }
    }),
    map((action) =>
      action?.payload
        ? getTestMethodSuccess(action?.payload)
        : getTestMethodFail()
    )
  );

//get test method by id epic
const getTestMethodByIdEpic = (
  action$: Observable<Action>,
  _: stateAction,
  { dispatch }: dispatchAction
) =>
  action$.pipe(
    filter(getTestMethodByIdRequest.match),
    mergeMap(async (action) => {
      try {
        const response = await getTestMethodById(action?.payload);
        return { payload: response.data };
      } catch (e) {
        return { error: e };
      }
    }),
    map((action) =>
      action?.payload
        ? testMethodEditSuccess(action.payload)
        : testMethodEditFail()
    )
  );

//get next
const getTestMethodNext = (
  action$: Observable<Action>,
  _: stateAction,
  { dispatch }: dispatchAction
) =>
  action$.pipe(
    filter(getTestMethodNextRequest.match),
    mergeMap(async (action) => {
      dispatch(loadingTestMethod());
      try {
        const response = await getNext(action.payload);
        return { payload: response.data };
      } catch (e) {
        return { error: e };
      }
    }),
    map((action) =>
      action?.payload
        ? getTestMethodSuccess(action?.payload)
        : getTestMethodFail()
    )
  );
//get previous
const getTestMethodPrevious = (
  action$: Observable<Action>,
  _: stateAction,
  { dispatch }: dispatchAction
) =>
  action$.pipe(
    filter(getTestMethodPreviousRequest.match),
    mergeMap(async (action) => {
      dispatch(loadingTestMethod());
      try {
        const response = await getPrevious(action.payload);
        return { payload: response.data };
      } catch (e) {
        return { error: e };
      }
    }),
    map((action) =>
      action?.payload
        ? getTestMethodSuccess(action?.payload)
        : getTestMethodFail()
    )
  );
//create TestMethodType epic
const createTestMethodEpic = (
  action$: Observable<Action>,
  _: stateAction,
  { dispatch }: dispatchAction
) =>
  action$.pipe(
    filter(createTestMethodRequest.match),
    mergeMap(async ({ payload: { values, rowsPerPage, page, setShowTestMethod } }) => {
      try {
        const body = new FormData();
        for (let [key, value] of Object.entries(values)) {
          // @ts-ignore
          body.append(`${key}`, value);
        }
        const response = await createTestMethod(body);
        if (response) {
          dispatch(getTestMethodRequest({ rowsPerPage, page }));
          dispatch(alertSuccessAction(messages.createMessage));
          setShowTestMethod ? setShowTestMethod(false) : dispatch(closeModal());
        }
        return { payload: { response } };
      } catch (e) {
        dispatch(alertErrorAction(messages.createFailMessage));
        return { error: e };
      }
    }),
    map((action) => {
      return action?.payload
        ? createTestMethodSuccess()
        : createTestMethodFail();
    })
  );
//update TestMethodType epic
const updateTestMethodEpic = (
  action$: Observable<Action>,
  _: stateAction,
  { dispatch }: dispatchAction
) =>
  action$.pipe(
    filter(updateTestMethodRequest.match),
    mergeMap(async ({ payload: { values, id, rowsPerPage, page } }) => {
      try {
        const body = new FormData();
        for (let [key, value] of Object.entries(values)) {
          // @ts-ignore
          body.append(`${key}`, value);
        }

        const response = await updateTestMethod(body, id);
        if (response) {
          dispatch(getTestMethodRequest({ rowsPerPage, page }));
          dispatch(alertSuccessAction(messages.updateMessage));
          dispatch(closeModal());
          dispatch(clearTestMethodData());
        }
        return { payload: { response, rowsPerPage } };
      } catch (e) {
        dispatch(alertErrorAction(messages.updateFailMessage));
        return { error: e };
      }
    }),
    map((action) =>
      action?.payload ? updateTestMethodSuccess() : updateTestMethodFail()
    )
  );

export const testMethodEpics = combineEpics(
  getTestMethodEpic,
  createTestMethodEpic,
  updateTestMethodEpic,
  getTestMethodNext,
  getTestMethodPrevious,
  getTestMethodByIdEpic
);
