import { createSlice, createAction } from "@reduxjs/toolkit";
import { packingType } from "./types";

const initialState: any = {
  packingTypes: [],
  loading: false,
  loadingCreatePackingType: false,
  loadingUnitPackingType: false,
  unitPackingType: [],
  edit: false,
  packingType: null,
  count: 0,
  previous: "",
  next: "",
};

export const PackingType = createSlice({
  name: "commonAppReducer",
  initialState,
  reducers: {
    getPackingTypeRequest: (state, payload) => {
      state.loadingPackingType = true;
    },
    getPackingTypeSuccess: (
      state,
      { payload: { results, count, next, previous } }
    ) => {
      state.loadingPackingType = false;
      state.packingTypes = results;
      state.count = count;
      state.next = next;
      state.previous = previous;
    },
    getPackingTypeFail: (state) => {
      state.loadingPackingType = false;
    },
    getPackingTypeNextRequest: (state, payload) => {
      state.loadingPackingType = true;
    },
    getPackingTypePreviousRequest: (state, payload) => {
      state.loadingPackingType = true;
    },
    getUnitPackingTypeRequest: (state) => {
      state.loadingUnitPackingType = true;
    },
    getUnitPackingTypeSuccess: (state, { payload: { results } }) => {
      state.loadingUnitPackingType = false;
      state.unitPackingType = results;
    },
    getUnitPackingTypeFail: (state) => {
      state.loadingUnitPackingType = false;
    },
    createPackingTypeRequest: (state, payload) => {
      state.loadingCreatePackingType = true;
      state.closeModal = true;
    },
    createPackingTypeSuccess: (state) => {
      state.loadingCreatePackingType = false;
    },
    createPackingTypeFail: (state) => {
      state.loadingCreatePackingType = false;
    },
    packingTypeEditSuccess: (state, { payload }) => {
      const selectedPackingType = state.packingTypes.find(
        (selected: packingType) => selected.id === payload.id
      );
      state.edit = true;
      state.packingType = selectedPackingType;
    },
    updatePackingTypeRequest: (state, payload) => {
      state.loadingCreatePackingType = true;
    },
    updatePackingTypeSuccess: (state) => {
      state.loadingCreatePackingType = false;
    },
    updatePackingTypeFail: (state) => {
      state.loadingCreatePackingType = false;
    },
    clearPackingTypeData: (state) => {
      state.edit = false;
      state.packingType = null;
    },
  },
});
// Action creators are generated for each case reducer function
export const {
  getPackingTypeRequest,
  getPackingTypeSuccess,
  getPackingTypeFail,
  getPackingTypeNextRequest,
  getPackingTypePreviousRequest,
  getUnitPackingTypeRequest,
  getUnitPackingTypeSuccess,
  getUnitPackingTypeFail,
  createPackingTypeRequest,
  createPackingTypeSuccess,
  createPackingTypeFail,
  packingTypeEditSuccess,
  updatePackingTypeRequest,
  updatePackingTypeSuccess,
  updatePackingTypeFail,
  clearPackingTypeData,
} = PackingType.actions;
export default PackingType.reducer;
