import { createSlice, createAction } from "@reduxjs/toolkit";
import { DepartmentWiseSaleReportInitialState } from "./types";
const initialState: DepartmentWiseSaleReportInitialState = {
  users: [],
  departmentWiseSaleDetails: [],
  //loading 
  loadingGenerateReport: false,
  loadingDepartmentWiseSaleReportUser: false,
  loadingDepartmentWiseSaleReportDetail: false,
  count: 0,
  previous: "",
  next: "",
};

// export const getDepartmentWiseSaleReportDetailRequest = createAction(
//   "getDepartmentWiseSaleReportDetailRequest",
//   function prepare({ detailsRowsPerPage, page }) {
//     return {
//       payload: {
//         detailsRowsPerPage,
//         page,
//       },
//     };
//   }
// );
export const DepartmentWiseSaleReport = createSlice({
  name: "DepartmentWiseSaleReportReducer",
  initialState,
  reducers: {
    //loading
    loadingDepartmentWiseSaleReportDetail: (state) => {
      state.loadingDepartmentWiseSaleReportDetail = true;
    },
    //external reducer request
    getDepartmentWiseSaleReportDetailRequest: (state, { payload }) => {
      state.loadingDepartmentWiseSaleReportDetail = true;
    },
    getDepartmentWiseSaleReportUserRequest: (state) => {
      state.loadingDepartmentWiseSaleReportUser = true;
    },
    getDepartmentWiseSaleReportAllSummaryRequest: (state, { payload }) => {
      state.loadingGenerateReport = true;
    },
    getDepartmentWiseSaleReportAllDetailRequest: (state, { payload }) => {
      state.loadingGenerateReport = true;
    },
    getDepartmentWiseSaleReportQuickSummaryRequest: (state, { payload }) => {
      state.loadingGenerateReport = true;
    },
    getDepartmentWiseSaleReportQuickDetailRequest: (state, { payload }) => {
      state.loadingGenerateReport = true;
    },
    getDepartmentWiseSaleReportNextRequest: (state, payload) => {
      state.loadingDepartmentWiseSaleReportDetail = true;
    },
    getDepartmentWiseSaleReportPreviousRequest: (state, payload) => {
      state.loadingDepartmentWiseSaleReportDetail = true;
    },
    //external get reducer  
    getDepartmentWiseSaleReportDetailSuccess: (
      state,
      { payload: { results, count, next, previous } }
    ) => {
      state.departmentWiseSaleDetails = results;
      state.loadingDepartmentWiseSaleReportDetail = false;
      state.count = count;
      state.next = next;
      state.previous = previous;
    },
    getDepartmentWiseSaleReportDetailFail: (state) => {
      state.loadingDepartmentWiseSaleReportDetail = false;
    },
    getDepartmentWiseSaleReportUserSuccess: (state, { payload: { results } }) => {
      state.loadingDepartmentWiseSaleReportUser = false;
      state.users = results;
    },
    getDepartmentWiseSaleReportUserFail: (state) => {
      state.loadingDepartmentWiseSaleReportUser = false;
    },
    getDepartmentWiseSaleReportAllSummarySuccess: (state, { payload: { results } }) => {
      state.loadingGenerateReport = false;
      state.departmentWiseSaleDetails = results;
    },
    getDepartmentWiseSaleReportAllSummaryFail: (state) => {
      state.loadingGenerateReport = false;
    },
    getDepartmentWiseSaleReportQuickSummarySuccess: (
      state,
      { payload: { results, count, next, previous } }
    ) => {
      state.loadingGenerateReport = false;
      state.departmentWiseSaleDetails = results;
      state.count = count;
      state.next = next;
      state.previous = previous;
    },
    getDepartmentWiseSaleReportQuickSummaryFail: (state) => {
      state.loadingGenerateReport = false;
    },
    getDepartmentWiseSaleReportAllDetailSuccess: (state, { payload: { results } }) => {
      state.loadingGenerateReport = false;
      state.departmentWiseSaleDetails = results;
    },
    getDepartmentWiseSaleReportAllDetailFail: (state) => {
      state.loadingGenerateReport = false;
    },
    getDepartmentWiseSaleReportQuickDetailSuccess: (
      state,
      { payload: { results, count, next, previous } }
    ) => {
      state.loadingGenerateReport = false;
      state.departmentWiseSaleDetails = results;
      state.count = count;
      state.next = next;
      state.previous = previous;
    },
    getDepartmentWiseSaleReportQuickDetailFail: (state) => {
      state.loadingGenerateReport = false;
    },

    clearDepartmentWiseSaleReportData: (state) => {
      state.users = [];
    },
    clearPrintUserActivityLogData: (state) => {
      state.departmentWiseSaleDetails = [];
    },
  },
});
// Action creators are generated for each case reducer function
export const {
  //reducerReport
  getDepartmentWiseSaleReportDetailRequest,
  getDepartmentWiseSaleReportUserRequest,
  getDepartmentWiseSaleReportAllSummaryRequest,
  getDepartmentWiseSaleReportAllDetailRequest,
  getDepartmentWiseSaleReportQuickSummaryRequest,
  getDepartmentWiseSaleReportQuickDetailRequest,
  getDepartmentWiseSaleReportPreviousRequest,
  getDepartmentWiseSaleReportNextRequest,
  //reducer get data
  getDepartmentWiseSaleReportDetailSuccess,
  getDepartmentWiseSaleReportDetailFail,
  getDepartmentWiseSaleReportUserSuccess,
  getDepartmentWiseSaleReportUserFail,
  getDepartmentWiseSaleReportQuickDetailSuccess,
  getDepartmentWiseSaleReportQuickDetailFail,
  getDepartmentWiseSaleReportAllDetailSuccess,
  getDepartmentWiseSaleReportAllDetailFail,
  getDepartmentWiseSaleReportQuickSummarySuccess,
  getDepartmentWiseSaleReportQuickSummaryFail,
  getDepartmentWiseSaleReportAllSummarySuccess,
  getDepartmentWiseSaleReportAllSummaryFail,
  //loading
  loadingDepartmentWiseSaleReportDetail,
  //clear
  clearDepartmentWiseSaleReportData,
  clearPrintUserActivityLogData,
} = DepartmentWiseSaleReport.actions;
export default DepartmentWiseSaleReport.reducer;
