import React, { Suspense, useEffect } from "react";
import { Chip, Dialog, DialogContent } from "@mui/material";
import { useDispatch } from "react-redux";
import { useModalStyles } from "../../AppUtils/ModalStyles/ModalStyles";
import { closeModal } from "../../Pages/CommonAppRedux/CommonAppSlice";
import { useImmer } from "use-immer";
import AlertDialog from "../AlertDialog/AlertDialog";
import ModalTitle from "./ModalTitle";
import { ModalProps } from "./types.d";
import { useAppSelector } from "../../AppUtils/Utils/appHooks";
import { commonAppSelector } from "../../Pages/CommonAppRedux/selector";
import ItemListDetail from "../../Pages/ViewDetails/ItemList/ItemListDetailPages/ItemListDetail";
const Modal = ({
  children,
  maxWidth,
  edit,
  title,
  types,
  clearData,
  showModal,
  setShowModal,
  displayTitleOnly,
  fullScreen,
  showItemListButton,
  print,
  data,
  disableEnforceFocus=false
}: ModalProps) => {
  const { formikData } = useAppSelector(commonAppSelector);
  const [showItemModalList, setShowItemModalList] = useImmer(false);
  const dispatch = useDispatch();
  // const checkListItemShow = ["purchase"];
  const closeOtherModal = () => {
    setShowModal?.(false);
  };
  const handleClose = async () => {
    // setshowModal(false);
    //clear the title of current modal
    setShowModal
      ? closeOtherModal()
      : //use to clear data
        dispatch(closeModal());
    clearData && dispatch(clearData);
  };
  // useEffect(() => {
  //   !print && !edit && clearData && dispatch(clearData);
  // }, []);
  const classes = useModalStyles();
  const [openAlert, setAlertOpen] = useImmer(false);
  const handleButton = () => {
    setShowItemModalList(true);
  };

  return (
    <>
      <Dialog
        onClose={(event, reason) => {
          if (formikData) {
            handleClose();
          } else {
            // if (reason === "backdropClick") {
            // Set 'open' to false, however you would do that with your particular code.
            setAlertOpen(true);
            // }
          }
        }}
        disableEnforceFocus={disableEnforceFocus}
        aria-labelledby="customized-dialog-title"
        open={showModal}
        fullScreen={fullScreen}
        fullWidth
        maxWidth={maxWidth}
        className={classes.modalContainer}
      >
        <ModalTitle
          id="customized-dialog-title"
          onClose={handleClose}
          className={classes.modalTitle}
        >
          {displayTitleOnly
            ? title
            : edit
            ? `Edit ${title}`
            : `Create ${title}`}
          {showItemListButton && types !== "itemList" && (
            <Chip
              style={{ top: 11 }}
              className="centerItemButtonInModal"
              label="Item List"
              size="small"
              color="primary"
              onClick={handleButton}
            />
          )}
        </ModalTitle>
        {/* {showPurchaseModuleNote && types !== "itemList" && (
          <Typography
            align="right"
            className="fw-bold"
            sx={{ fontWeight: "bold", marginRight: "1.5rem" }}
          >
            Note:Press Shift+G to get the previous data
          </Typography>
        )} */}
        <DialogContent dividers>{children}</DialogContent>
      </Dialog>
      {/* {showModalList && (
        <Suspense fallback={<></>}>
          <Modal
            showModal={showModalList}
            setShowModal={setShowModalList}
            types="itemList"
            title="Item List Details"
            maxWidth="lg"
            edit={false}
          >
            <ItemListDetail
              setShowModal={setShowModalList}
              showModal={showModalList}
              types="itemList"
              active
            />
          </Modal>
        </Suspense>
      )} */}
      {openAlert && (
        <AlertDialog
          handleSubmit={handleClose}
          types="unsavedDataPrompt"
          setShowAlertModal={setAlertOpen}
          showAlertModal={openAlert}
        />
      )}

      {showItemModalList && (
        <Suspense fallback={<></>}>
          <Modal
            showModal={showItemModalList}
            setShowModal={setShowItemModalList}
            title="Item List Details"
            types="itemList"
            maxWidth="lg"
            edit={false}
            displayTitleOnly
          >
            <ItemListDetail
              // setShowModal={setShowItemModalList}
              showModal={showItemModalList}
              types="itemList"
            />
          </Modal>
        </Suspense>
      )}
    </>
  );
};
export default React.memo(Modal);

// {
//   data?.clinicOrderDetails?.map((item: any, i: number) => {
//     // console.log("can i run here", item);
//     return (
//       <Chip
//         // style={{ top: 11 }}
//         size="medium"
//         color="secondary"
//         label={item?.itemName}
//         key={i}
//       />
//     );
//   });
// }
