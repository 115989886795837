import { createSlice } from "@reduxjs/toolkit";
import { deviceRepairProps } from "./types";

const initialState: deviceRepairProps = {
  deviceRepair: [],
  loadingDeviceRepairList: false,
  edit: false,
  editDeviceRepair: null,
  loadingCreateDeviceRepair: false,
  deviceRepairItem: [],
  loadingDeviceRepairItem: false,
  count: 0,
  next: "",
  previous: "",
};

export const deviceRepairSlice = createSlice({
  name: "deviceRepairReducer",
  initialState,
  reducers: {
    getDeviceRepairRequest: (state, payload) => {
      state.loadingDeviceRepairList = true;
    },
    getDeviceRepairSuccess: (
      state,
      { payload: { results, count, next, previous } }
    ) => {
      state.loadingDeviceRepairList = false;
      state.deviceRepair = results;
      state.count = count;
      state.next = next;
      state.previous = previous;
    },
    getDeviceRepairFail: (state) => {
      state.loadingDeviceRepairList = false;
    },
    editDeviceRepairRequest: (state, payload) => {
      state.edit = true;
    },
    editDeviceRepairSuccess: (state, { payload }) => {
      state.edit = true;
      state.editDeviceRepair = payload;
    },
    editDeviceRepairFail: (state) => {
      state.edit = false;
      state.editDeviceRepair = null;
    },
    createDeviceRepairRequest: (state, payload) => {
      state.loadingCreateDeviceRepair = true;
    },
    createDeviceRepairSuccess: (state) => {
      state.loadingCreateDeviceRepair = false;
    },
    createDeviceRepairFail: (state) => {
      state.loadingCreateDeviceRepair = false;
    },
    updateDeviceRepairRequest: (state, payload) => {
      state.loadingCreateDeviceRepair = true;
    },
    updateDeviceRepairSuccess: (state) => {
      state.loadingCreateDeviceRepair = false;
    },
    updateDeviceRepairFail: (state) => {
      state.loadingCreateDeviceRepair = false;
    },
    getDeviceRepairItemRequest: (state) => {
      state.loadingDeviceRepairItem = true;
    },
    getDeviceRepairItemSuccess: (state, { payload: { results } }) => {
      state.loadingDeviceRepairItem = false;
      state.deviceRepairItem = results;
    },
    getDeviceRepairItemFail: (state) => {
      state.loadingDeviceRepairItem = false;
    },
    getDeviceRepairNextRequest: (state, payload) => {
      state.loadingDeviceRepairList = true;
    },
    getDeviceRepairPreviousRequest: (state, payload) => {
      state.loadingDeviceRepairList = true;
    },
    clearAllDeviceRepairData: (state) => {
      state.edit = false;
      state.editDeviceRepair = null;
    },
  },
});

export const {
  getDeviceRepairRequest,
  getDeviceRepairSuccess,
  getDeviceRepairFail,
  editDeviceRepairRequest,
  editDeviceRepairSuccess,
  editDeviceRepairFail,
  createDeviceRepairRequest,
  createDeviceRepairSuccess,
  createDeviceRepairFail,
  updateDeviceRepairRequest,
  updateDeviceRepairSuccess,
  updateDeviceRepairFail,
  getDeviceRepairItemRequest,
  getDeviceRepairItemSuccess,
  getDeviceRepairItemFail,
  clearAllDeviceRepairData,
  //
  getDeviceRepairNextRequest,
  getDeviceRepairPreviousRequest,
} = deviceRepairSlice.actions;

export default deviceRepairSlice.reducer;
