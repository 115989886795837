import {
    makeStyles
}
from "@mui/styles";

export const useStatusStyles = makeStyles({
    checkCircleIconCustom: {
    color: "green",
  },
  cancelIconCustom: {
    color: "#eb3333",
  },
})