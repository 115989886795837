import {
  createTestSensitiveMedicineFail,
  createTestSensitiveMedicineRequest,
  createTestSensitiveMedicineSuccess,
  getTestSensitiveMedicineFail,
  getTestSensitiveMedicineSuccess,
  loadingTestSensitiveMedicine,
  updateTestSensitiveMedicineFail,
  updateTestSensitiveMedicineSuccess,
  getTestSensitiveMedicineRequest,
  getTestSensitiveMedicineNextRequest,
  getTestSensitiveMedicinePreviousRequest,
  updateTestSensitiveMedicineRequest,
  clearTestSensitiveMedicineData,
  getAllTestRequest,
  getTestSensitiveTestSuccess,
  getTestSensitiveTestFail,
  getSensitiveMedicineSuccess,
  getSensitiveMedicineFail,
  getSensitiveMedicineTestRequest,
  getTestSensitiveMedicineByIdRequest,
  testSensitiveMedicineEditSuccess,
  testSensitiveMedicineEditFail,
} from "./testSensitiveMedicineSlice";
import { mergeMap } from "rxjs";
import {
  createTestSensitiveMedicine,
  getTestSensitiveMedicine,
  updateTestSensitiveMedicine,
  getSensitiveMedicineTest,
  getAllTest,
  getTestSensitiveMedicineById,
} from "./api";
import { map, filter } from "rxjs/operators";
import { Observable } from "rxjs";
import { Action } from "@reduxjs/toolkit";
import { combineEpics } from "redux-observable";
import {
  alertErrorAction,
  alertSuccessAction,
  closeModal,
} from "../../../CommonAppRedux/CommonAppSlice";
import { getNext, getPrevious } from "../../../CommonAppRedux/api";
import {
  dispatchAction,
  stateAction,
} from "../../../../AppUtils/Utils/globalTypes";
import messages from "../../../../AppUtils/Utils/validationConstants";
export const controller = new AbortController();
// import { searchData } from "../../../CommonAppRedux/commonAppFunctions";
//get Type epic
const getTestSensitiveMedicineEpic = (
  action$: Observable<Action>,
  _: stateAction,
  { dispatch }: dispatchAction
) =>
  action$.pipe(
    filter(getTestSensitiveMedicineRequest.match),
    mergeMap(async (action) => {
      dispatch(loadingTestSensitiveMedicine());
      try {
        const response = await getTestSensitiveMedicine(action.payload);
        return { payload: response.data };
      } catch (e) {
        return { error: e };
      }
    }),
    map((action) =>
      action?.payload
        ? getTestSensitiveMedicineSuccess(action?.payload)
        : getTestSensitiveMedicineFail()
    )
  );

//get test sensitive medicine by id
const getTestSensitiveMedicineByIdEpic = (
  action$: Observable<Action>,
  _: stateAction,
  { dispatch }: dispatchAction
) =>
  action$.pipe(
    filter(getTestSensitiveMedicineByIdRequest.match),
    mergeMap(async (action) => {
      try {
        const response = await getTestSensitiveMedicineById(action.payload);
        return { payload: response.data };
      } catch (e) {
        return { error: e };
      }
    }),
    map((action) =>
      action?.payload
        ? testSensitiveMedicineEditSuccess(action?.payload)
        : testSensitiveMedicineEditFail()
    )
  );

//get next
const getTestSensitiveMedicineNext = (
  action$: Observable<Action>,
  _: stateAction,
  { dispatch }: dispatchAction
) =>
  action$.pipe(
    filter(getTestSensitiveMedicineNextRequest.match),
    mergeMap(async (action) => {
      dispatch(loadingTestSensitiveMedicine());
      try {
        const response = await getNext(action.payload);
        return { payload: response.data };
      } catch (e) {
        return { error: e };
      }
    }),
    map((action) =>
      action?.payload
        ? getTestSensitiveMedicineSuccess(action?.payload)
        : getTestSensitiveMedicineFail()
    )
  );
//get previous
const getTestSensitiveMedicinePrevious = (
  action$: Observable<Action>,
  _: stateAction,
  { dispatch }: dispatchAction
) =>
  action$.pipe(
    filter(getTestSensitiveMedicinePreviousRequest.match),
    mergeMap(async (action) => {
      dispatch(loadingTestSensitiveMedicine());
      try {
        const response = await getPrevious(action.payload);
        return { payload: response.data };
      } catch (e) {
        return { error: e };
      }
    }),
    map((action) =>
      action?.payload
        ? getTestSensitiveMedicineSuccess(action?.payload)
        : getTestSensitiveMedicineFail()
    )
  );
const createTestSensitiveMedicineEpic = (
  action$: Observable<Action>,
  _: stateAction,
  { dispatch }: dispatchAction
) =>
  action$.pipe(
    filter(createTestSensitiveMedicineRequest.match),
    mergeMap(async ({ payload: { values, rowsPerPage, page } }) => {
      try {
        const body = JSON.stringify(values);
        const response = await createTestSensitiveMedicine(body);
        if (response) {
          dispatch(getTestSensitiveMedicineRequest({ rowsPerPage, page }));
          dispatch(alertSuccessAction(messages.createMessage));
          dispatch(closeModal());
        }
        return { payload: { response } };
      } catch (e) {
        dispatch(alertErrorAction(messages.createFailMessage));
        return { error: e };
      }
    }),
    map((action) => {
      return action?.payload
        ? createTestSensitiveMedicineSuccess()
        : createTestSensitiveMedicineFail();
    })
  );
//update Type epic
const updateTestSensitiveMedicineEpic = (
  action$: Observable<Action>,
  _: stateAction,
  { dispatch }: dispatchAction
) =>
  action$.pipe(
    filter(updateTestSensitiveMedicineRequest.match),
    mergeMap(async ({ payload: { values, id, rowsPerPage, page } }) => {
      try {
        const body = JSON.stringify(values);
        const response = await updateTestSensitiveMedicine(body, id);
        if (response) {
          dispatch(getTestSensitiveMedicineRequest({ rowsPerPage, page }));
          dispatch(alertSuccessAction(messages.updateMessage));
          dispatch(clearTestSensitiveMedicineData());
          dispatch(closeModal());
        }
        return { payload: { response, rowsPerPage } };
      } catch (e) {
        dispatch(alertErrorAction(messages.updateFailMessage));
        return { error: e };
      }
    }),
    map((action) =>
      action?.payload
        ? updateTestSensitiveMedicineSuccess()
        : updateTestSensitiveMedicineFail()
    )
  );

const getAllTestEpic = (
  action$: Observable<Action>,
  _: stateAction,
  { dispatch }: dispatchAction
) =>
  action$.pipe(
    filter(getAllTestRequest.match),
    mergeMap(async (action) => {
      try {
        const response = await getAllTest(action?.payload);
        return { payload: response.data };
      } catch (e) {
        return { error: e };
      }
    }),
    map((action) =>
      action?.payload
        ? getTestSensitiveTestSuccess(action?.payload)
        : getTestSensitiveTestFail()
    )
  );

const getSensitiveMedicineEpic = (
  action$: Observable<Action>,
  _: stateAction,
  { dispatch }: dispatchAction
) =>
  action$.pipe(
    filter(getSensitiveMedicineTestRequest.match),
    mergeMap(async (action) => {
      try {
        const response = await getSensitiveMedicineTest(action?.payload);
        return { payload: response.data };
      } catch (e) {
        return { error: e };
      }
    }),
    map((action) =>
      action?.payload
        ? getSensitiveMedicineSuccess(action?.payload)
        : getSensitiveMedicineFail()
    )
  );

export const testSensitiveMedicineEpics = combineEpics(
  getTestSensitiveMedicineEpic,
  createTestSensitiveMedicineEpic,
  updateTestSensitiveMedicineEpic,
  getTestSensitiveMedicineNext,
  getTestSensitiveMedicinePrevious,
  getAllTestEpic,
  getSensitiveMedicineEpic,
  getTestSensitiveMedicineByIdEpic
);
