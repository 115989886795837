import { mergeMap } from "rxjs";
import { map, filter } from "rxjs/operators";
import { Observable } from "rxjs";
import { Action } from "@reduxjs/toolkit";
import { combineEpics } from "redux-observable";
import { dispatchAction, stateAction } from "../../AppUtils/Utils/globalTypes";
import {
  getGlobalOrganizationFail,
  getGlobalOrganizationRequest,
  getGlobalOrganizationRuleFail,
  getGlobalOrganizationRuleSuccess,
  getGlobalOrganizationSuccess,
  getInventoryCreditSalesInfoRequest,
  getInventoryCreditSalesInfoFail,
  getInventoryCreditSalesInfoSuccess,
  getClinicCreditSalesInfoRequest,
  getClinicCreditSalesInfoFail,
  getClinicCreditSalesInfoSuccess,
} from "./CommonAppSlice";
import {
  getClinicCreditSalesInfoAPI,
  getGlobalOrganizationRule,
  getGlobalOrganizationSetup,
  getInventoryCreditSalesInfoAPI,
} from "./api";

export const controller = new AbortController();

const getGlobalOrganizationEpic = (
  action$: Observable<Action>,
  _: stateAction,
  { dispatch }: dispatchAction
) =>
  action$.pipe(
    filter(getGlobalOrganizationRequest.match),
    mergeMap(async (action) => {
      // dispatch(loadingOrganization());
      try {
        const response = await getGlobalOrganizationSetup();
        return { payload: response.data };
      } catch (e) {
        return { error: e };
      }
    }),
    map((action) =>
      action?.payload
        ? getGlobalOrganizationSuccess(action?.payload)
        : getGlobalOrganizationFail()
    )
  );

const getGlobalOrganizationRuleEpic = (
  action$: Observable<Action>,
  _: stateAction,
  { dispatch }: dispatchAction
) =>
  action$.pipe(
    filter(getGlobalOrganizationRequest.match),
    mergeMap(async (action) => {
      // dispatch(loadingOrganization());
      try {
        const response = await getGlobalOrganizationRule();
        return { payload: response.data };
      } catch (e) {
        return { error: e };
      }
    }),
    map((action) =>
      action?.payload
        ? getGlobalOrganizationRuleSuccess(action?.payload)
        : getGlobalOrganizationRuleFail()
    )
  );

const getInventoryCreditSalesInfoEpic = (
  action$: Observable<Action>,
  _: stateAction,
  { dispatch }: dispatchAction
) =>
  action$.pipe(
    filter(getInventoryCreditSalesInfoRequest.match),
    mergeMap(async (action) => {
      try {
        const response = await getInventoryCreditSalesInfoAPI(action?.payload);
        return { payload: response.data };
      } catch (e) {
        return { error: e };
      }
    }),
    map((action) =>
      action?.payload
        ? getInventoryCreditSalesInfoSuccess(action?.payload)
        : getInventoryCreditSalesInfoFail()
    )
  );

const getClinicCreditSalesInfoEpic = (
  action$: Observable<Action>,
  _: stateAction,
  { dispatch }: dispatchAction
) =>
  action$.pipe(
    filter(getClinicCreditSalesInfoRequest.match),
    mergeMap(async (action) => {
      try {
        const response = await getClinicCreditSalesInfoAPI(action?.payload);
        return { payload: response.data };
      } catch (e) {
        return { error: e };
      }
    }),
    map((action) =>
      action?.payload
        ? getClinicCreditSalesInfoSuccess(action?.payload)
        : getClinicCreditSalesInfoFail()
    )
  );

export const commonAppEpics = combineEpics(
  getGlobalOrganizationEpic,
  getGlobalOrganizationRuleEpic,
  getInventoryCreditSalesInfoEpic,
  getClinicCreditSalesInfoEpic
);
