import { axiosInstance } from "../../../../AppUtils/Utils/axios";
import {
  searchProps,
  getApiProps,
} from "../../../../AppUtils/Utils/globalTypes";
import { filterApiData } from "../../../../AppUtils/Utils/appFunctions";

export const deviceRepairSpecialistModURL = `api/v1/appointment-app/device-repair-specialist`;

//get deviceRepairSpecialist
export const getDeviceRepairSpecialist = (data: getApiProps) => {
  const { rowsPerPage, page, search } = data;
  return axiosInstance.get(
    `${deviceRepairSpecialistModURL}?offset=${
      rowsPerPage * page
    }&limit=${rowsPerPage}&search=${search ?? ""}${
      data?.filterData ? filterApiData(data?.filterData) : ""
    }`
  );
};
//get deviceRepairSpecialist by id
export const getDeviceRepairSpecialistById = (id: number) => {
  return axiosInstance.get(`${deviceRepairSpecialistModURL}/${id}`);
};
//search deviceRepairSpecialist
export const searchDeviceRepairSpecialist = ({
  rowsPerPage,
  search,
}: searchProps) => {
  return `${deviceRepairSpecialistModURL}?offset=0&limit=${rowsPerPage}&search=${
    search ?? ""
  }`;
};
//update deviceRepairSpecialist
export const updateDeviceRepairSpecialist = (
  body: string | FormData,
  id: number
) => axiosInstance.patch(`${deviceRepairSpecialistModURL}/${id}`, body);
//create deviceRepairSpecialist
export const createDeviceRepairSpecialist = (body: string | FormData) =>
  axiosInstance.post(`${deviceRepairSpecialistModURL}`, body);
