import {
  createClinicOrderFail,
  createClinicOrderRequest,
  createClinicOrderSuccess,
  getClinicOrderSuccess,
  getClinicOrderFail,
  getClinicOrderCategoryRequest,
  getClinicOrderCategorySuccess,
  getClinicOrderCategoryFail,
  getClinicOrderDepartmentRequest,
  getClinicOrderDepartmentSuccess,
  getClinicOrderDepartmentFail,
  getClinicOrderCustomerTypeRequest,
  getClinicOrderCustomerTypeSuccess,
  getClinicOrderCustomerTypeFail,
  updateClinicOrderFail,
  updateClinicOrderSuccess,
  getClinicOrderRequest,
  updateClinicOrderRequest,
  getClinicOrderNextRequest,
  getClinicOrderPreviousRequest,
  clearClinicOrderData,
  getClinicOrderByIdRequest,
  clinicOrderEditSuccess,
  clinicOrderEditFail,
  // getClinicOrderCustomerRequest,
  // getClinicOrderCustomerSuccess,
  // getClinicOrderCustomerFail,
  getClinicOrderItemRequest,
  getClinicOrderItemSuccess,
  getClinicOrderItemFail,
  getDeviceOrderItemCategorySuccess,
  getDeviceOrderItemCategoryRequest,
  getDeviceOrderItemCategoryFail,
} from "./clinicOrderSlice";
import { mergeMap } from "rxjs";
import {
  createClinicOrder,
  getClinicOrder,
  updateClinicOrder,
  getClinicOrderCustomerType,
  getClinicOrderCategory,
  getClinicOrderDepartment,
  getClinicOrderById,
  getClinicOrderItem,
  getDeviceOrderItemCategory,
} from "./api";
import { map, filter, tap } from "rxjs/operators";
import { Observable } from "rxjs";
import { Action } from "@reduxjs/toolkit";
import { combineEpics } from "redux-observable";
import { alertErrorAction, alertSuccessAction, closeModal } from "../../../../../CommonAppRedux/CommonAppSlice";
import { dispatchAction, stateAction } from "../../../../../../AppUtils/Utils/globalTypes";
import { getNext, getPrevious } from "../../../../../CommonAppRedux/api";
import messages from "../../../../../../AppUtils/Utils/validationConstants";


export const controller = new AbortController();

// get clinicorder epic
const getClinicOrderEpic = (
  action$: Observable<Action>,
  _: stateAction,
  { dispatch }: dispatchAction
) =>
  action$.pipe(
    filter(getClinicOrderRequest.match),
    mergeMap(async (action) => {
      try {
        const response = await getClinicOrder(action.payload);
        return { payload: response.data };
      } catch (e) {
        return { error: e };
      }
    }),
    map((action) =>
      action?.payload ? getClinicOrderSuccess(action?.payload) : getClinicOrderFail()
    )
  );

//get next
const getClinicOrderNext = (
  action$: Observable<Action>,
  _: stateAction,
  { dispatch }: dispatchAction
) =>
  action$.pipe(
    filter(getClinicOrderNextRequest.match),
    mergeMap(async (action) => {
      try {
        const response = await getNext(action.payload);
        return { payload: response.data };
      } catch (e) {
        return { error: e };
      }
    }),
    map((action) =>
      action?.payload ? getClinicOrderSuccess(action?.payload) : getClinicOrderFail()
    )
  );

//get previous
const getClinicOrderPrevious = (
  action$: Observable<Action>,
  _: stateAction,
  { dispatch }: dispatchAction
) =>
  action$.pipe(
    filter(getClinicOrderPreviousRequest.match),
    mergeMap(async (action) => {
      try {
        const response = await getPrevious(action.payload);
        return { payload: response.data };
      } catch (e) {
        return { error: e };
      }
    }),
    map((action) =>
      action?.payload ? getClinicOrderSuccess(action?.payload) : getClinicOrderFail()
    )
  );
// get clinicorder customer type
const getAllClinicOrderCustomerType = (
  action$: Observable<Action>,
  _: stateAction,
  { dispatch }: dispatchAction
) =>
  action$.pipe(
    filter(getClinicOrderCustomerTypeRequest.match),
    mergeMap(async (action) => {
      try {
        const response = await getClinicOrderCustomerType(action.payload);
        return { payload: response.data };
      } catch (e) {
        return { error: e };
      }
    }),
    map((action) =>
      action?.payload
        ? getClinicOrderCustomerTypeSuccess(action?.payload)
        : getClinicOrderCustomerTypeFail()
    )
  );
// get clinicorder department
const getAllClinicOrderDepartment = (
  action$: Observable<Action>,
  _: stateAction,
  { dispatch }: dispatchAction
) =>
  action$.pipe(
    filter(getClinicOrderDepartmentRequest.match),
    mergeMap(async (action) => {
      try {
        const response = await getClinicOrderDepartment(action.payload);
        return { payload: response.data };
      } catch (e) {
        return { error: e };
      }
    }),
    map((action) =>
      action?.payload
        ? getClinicOrderDepartmentSuccess(action?.payload)
        : getClinicOrderDepartmentFail()
    )
  );
// get clinicorder category
const getAllClinicOrderCategory = (
  action$: Observable<Action>,
  _: stateAction,
  { dispatch }: dispatchAction
) =>
  action$.pipe(
    filter(getClinicOrderCategoryRequest.match),
    mergeMap(async (action) => {
      try {
        const response = await getClinicOrderCategory(action.payload);
        return { payload: response.data };
      } catch (e) {
        return { error: e };
      }
    }),
    map((action) =>
      action?.payload
        ? getClinicOrderCategorySuccess(action?.payload)
        : getClinicOrderCategoryFail()
    )
  );
//create ClinicOrder epic
const createClinicOrderEpic = (
  action$: Observable<Action>,
  _: stateAction,
  { dispatch }: dispatchAction
) =>
  action$.pipe(
    filter(createClinicOrderRequest.match),
    mergeMap(async ({ payload: { values, rowsPerPage, page } }) => {
      
      try {
        const body = JSON.stringify(values);

        const response = await createClinicOrder(body);
        if (response) {
          dispatch(getClinicOrderRequest({ id:values?.customer, rowsPerPage, page }));
          dispatch(alertSuccessAction(messages.createMessage));
          dispatch(closeModal());
          dispatch(clearClinicOrderData());
        }
        return { payload: { response } };
      } catch (e) {
        dispatch(alertErrorAction(messages.createFailMessage));
        return { error: e };
      }
    }),
    map((action) => {
      return action?.payload ? createClinicOrderSuccess() : createClinicOrderFail();
    })
  );

//update ClinicOrder epic
const updateClinicOrderEpic = (
  action$: Observable<Action>,
  _: stateAction,
  { dispatch }: dispatchAction
) =>
  action$.pipe(
    filter(updateClinicOrderRequest.match),
    mergeMap(async ({ payload: { values, id, rowsPerPage, page } }) => {
      try {
        const body = JSON.stringify(values);
        const response = await updateClinicOrder(body, id);
        if (response) {
          dispatch(getClinicOrderRequest({ rowsPerPage, page }));
          dispatch(alertSuccessAction(messages.updateMessage));
          dispatch(clearClinicOrderData());
          dispatch(closeModal());
        }
        return { payload: { response, rowsPerPage } };
      } catch (e) {
        dispatch(alertErrorAction(messages.updateFailMessage));
        return { error: e };
      }
    }),
    map((action) => (action?.payload ? updateClinicOrderSuccess() : updateClinicOrderFail()))
  );

const getClinicOrderByIdEpic = (
  action$: Observable<Action>,
  _: stateAction,
  { dispatch }: dispatchAction
) =>
  action$.pipe(
    filter(getClinicOrderByIdRequest.match),
    mergeMap(async (action) => {
      try {
        const response = await getClinicOrderById(action.payload);
        return { payload: response.data };
      } catch (e) {
        return { error: e };
      }
    }),
    map((action) =>
      action?.payload ? clinicOrderEditSuccess(action.payload) : clinicOrderEditFail()
    )
  );


  //customer
  // const getClinicOrderCustomerEpic = (
  //   action$: Observable<Action>,
  //   _: stateAction,
  //   { dispatch }: dispatchAction
  // ) =>
  //   action$.pipe(
  //     filter(getClinicOrderCustomerRequest.match),
  //     mergeMap(async (action: any) => {
  //       try {
  //         const response = await getClinicOrderCustomer(action.payload);
  //         return { payload: response.data };
  //       } catch (e) {
  //         return { error: e };
  //       }
  //     }),
  //     map((action) =>
  //       action?.payload
  //         ? getClinicOrderCustomerSuccess(action?.payload)
  //         : getClinicOrderCustomerFail()
  //     )
  //   );
    //item
    const getClinicOrderItemEpic = (
      action$: Observable<Action>,
      _: stateAction,
      { dispatch }: dispatchAction
    ) =>
      action$.pipe(
        filter(getClinicOrderItemRequest.match),
        mergeMap(async (action: any) => {
          try {
            const response = await getClinicOrderItem(action.payload);
            return { payload: response.data };
          } catch (e) {
            return { error: e };
          }
        }),
        map((action) =>
          action?.payload
            ? getClinicOrderItemSuccess(action?.payload)
            : getClinicOrderItemFail()
        )
      );
      const getDeviceOrderItemCategoryEpic = (
        action$: Observable<Action>,
        _: stateAction,
        { dispatch }: dispatchAction
      ) =>
        action$.pipe(
          filter(getDeviceOrderItemCategoryRequest.match),
          mergeMap(async (action: any) => {
            try {
              const response = await getDeviceOrderItemCategory(action.payload);
              return { payload: response.data };
            } catch (e) {
              return { error: e };
            }
          }),
          map((action) =>
            action?.payload
              ? getDeviceOrderItemCategorySuccess(action?.payload)
              : getDeviceOrderItemCategoryFail()
          )
        );
export const clinicOrderEpics = combineEpics(
  getClinicOrderEpic,
  createClinicOrderEpic,
  updateClinicOrderEpic,
  getClinicOrderNext,
  getClinicOrderPrevious,
  getAllClinicOrderCustomerType,
  getAllClinicOrderDepartment,
  getAllClinicOrderCategory,
  getClinicOrderByIdEpic,
  getClinicOrderItemEpic,
  // getClinicOrderCustomerEpic,
  getDeviceOrderItemCategoryEpic
  
);
