// @ts-ignore
import { filterData } from "../../Component/FilterData/types";
import { rowProps } from "../../Pages/ViewDetails/CommonTable/types";
import { globalSearchPostPerPage, prefixes } from "./globalConstant";
import { dateFormater } from "./dateFunctions";
import {
  alertErrorAction,
  commonAppFormikData,
} from "../../Pages/CommonAppRedux/CommonAppSlice";
import { Dispatch } from "react";
import { AnyAction } from "@reduxjs/toolkit";
import { axiosInstance } from "./axios";

//function to filter api data
const filterApiData = (filterData: filterData) => {
  const { fromDateAd, toDateAd, fromDateBs, toDateBs, filterField } =
    filterData;
  // return array of string
  let filterFieldValue = filterField?.map(
    (x) =>
      `&${x.filterField ? x.filterField : x.id}=${x.filterFieldValue ?? ""}`
  );
  return `&date_after=${
    fromDateAd
      ? dateFormater(new Date(fromDateAd))
      : fromDateBs
      ? fromDateBs
      : ""
  }&date_before=${
    toDateAd ? dateFormater(new Date(toDateAd)) : toDateBs ? toDateBs : ""
  }${filterFieldValue?.join("")}`;
};
//function to check duplicate value
const checkDuplicateValue = (duplicateDataList: any, filedName: string) => {
  
  return duplicateDataList[0][`${filedName}`]?.length > 0
    ? duplicateDataList[0][`${filedName}`]?.length === 0
    : duplicateDataList[0][`${filedName}`] !== "undefined";
};
//custom round function
const customRound = (num: number | string) => {
  // The rounding problem can be avoided by using numbers represented in exponential notation:
  // @ts-ignore
  return +(Math.round(num + "e+2") + "e-2");
};

//data to convert file
const dataURLtoFile = (dataurl: any, filename: any) => {
  var arr = dataurl.split(","),
    mime = arr[0].match(/:(.*?);/)[1],
    bstr = atob(arr[1]),
    n = bstr.length,
    u8arr = new Uint8Array(n);
  while (n--) {
    u8arr[n] = bstr.charCodeAt(n);
  }
  return new File([u8arr], filename, { type: mime });
};
// date formater for start and end date in appointment module
const formatStartOrEndDate = (date: any): any => {
  // @ts-ignore
  const dateToFormat = new Date(date);
  const dateOnly = dateToFormat?.toISOString().substring(0, 10);
  const timeOnly = dateToFormat?.toTimeString().substring(0, 5);
  const finalDateTime = dateOnly + "T" + timeOnly;
  return finalDateTime;
};
// handle url when user search from autocomplete
const getSearchURL = (value: string) =>
  `offset=0&limit=${globalSearchPostPerPage}&search=${value.trimStart()}`;

// update details
const updateDetails = (data: any, key: string) => {
  return data?.map((data: any) => {
    const {
      [key]: { id },
    } = data;
    return { ...data, [key]: id };
  });
};
// check equality between two objects
const checkEqualityData = (
  values: any,
  initialValues: any,
  checkEquality: React.MutableRefObject<boolean>,
  dispatch: Dispatch<AnyAction>
) => {
  checkEquality.current =
    JSON.stringify(values) === JSON.stringify(initialValues) ? true : false;
  dispatch(commonAppFormikData(checkEquality.current));
};
const onKeyUpFunction = (e: any) => {
  if (e.key === "Enter") {
    // @ts-ignore
    const next = inputRef.current[i + 1];
    if (next) {
      //@ts-ignore
      return next.focus();
    }
  }
};

//
const handleFollowUpCheck = (
  values: any,
  setFieldValue: any,
  dispatch: any
) => {
  axiosInstance
    .get(`/api/v1/global-api/customer/check-follow-up-status/${values?.id}`)
    .then((response: any) => {
      if (response.status === 200) {
        // dispatch(alertSuccessAction("Successfully"));
      }
    })
    .catch((error: any) => {
      dispatch(
        alertErrorAction(
          `${values?.fullName} Patient Not Follow Up Today Please Follow Up First For claim Code`
        )
      );
      setFieldValue("customer", null);
    });
};
//isDefault Check api unique
const handleIsDefaultCheck = (
  setFieldValue: any,
  dispatch: any,
  apiUrl: any,
  keyName: any
) => {
  axiosInstance
    .get(`${apiUrl}?is_default=true`)
    .then((response: any) => {
      let data = response?.data?.results[0];
      if (response.status === 200) {
        setFieldValue(`${keyName}`, data??null);
      }
    })
    .catch((error: any) => {
      dispatch(alertErrorAction(`No Default Data`));
    });
};

const getDynamicPermissionData=(permissionData: string[]):{ [key: string]: string }=>{
  const passPermission: { [key: string]: string } = {};
  
    prefixes.forEach((prefix) => {
      const permission = permissionData.find((p) => p.includes(`${prefix}_`));
      if (permission) {
        passPermission[`${prefix}_permission`] = permission;
      }
    });
  
    return passPermission;
}

export {
  dateFormater,
  formatStartOrEndDate,
  dataURLtoFile,
  checkDuplicateValue,
  filterApiData,
  customRound,
  getSearchURL,
  checkEqualityData,
  onKeyUpFunction,
  updateDetails,
  handleFollowUpCheck,
  handleIsDefaultCheck,
  getDynamicPermissionData
};
