import { makeStyles } from "@mui/styles";
export const tableStyles = makeStyles(() => ({
  filterDataPaper: {
    padding: "4px 4px",
    boxShadow: "0px 0px 8px 1px #ddd !important",
    margin: "4px 0px",
    display: "flex",
    alignItems: "center",
    width: "100%",
  },
  tableDataPaper: {
    padding: "4px",
    boxShadow: "0px 0px 4px 1px #ddd !important",
    margin: "4px 0px",
  },
  tableContainer: {
    display: "block",
    overflow: "scroll",
    "@media (min-height: 1025px)": {
      minHeight: "75vh",
      maxHeight: "75vh",
    },
    "@media (max-height: 1024px)": {
      minHeight: "70vh",
      maxHeight: "70vh",
    },
    "@media (max-height: 950px)": {
      minHeight: "65vh",
      maxHeight: "65vh",
    },
    "@media (max-height: 820px)": {
      minHeight: "60vh",
      maxHeight: "65vh",
    },
    "@media (max-height: 700px)": {
      minHeight: "55vh",
      maxHeight: "55vh",
    },
    "@media (max-height: 650px)": {
      minHeight: "50vh",
      maxHeight: "50vh",
    },
    "@media (max-height: 550px)": {
      minHeight: "45vh",
      maxHeight: "45vh",
    },
    "@media (max-height: 500px)": {
      minHeight: "40vh",
      maxHeight: "40vh",
    },
    "@media (max-height: 470px)": {
      minHeight: "35vh",
      maxHeight: "35vh",
    },
    "@media (max-height: 400px)": {
      minHeight: "30vh",
      maxHeight: "30vh",
    },
  },
  tableContainerForDetail: {
    display: "block",
    overflow: "scroll",
    minHeight: "5vh",
    maxHeight: "35vh",
  },
  noDataDisplay: {
    display: "block",
    overflow: "scroll",
    minHeight: "15vh",
    maxHeight: "15vh",
  },
  headCell: {
    // minWidth: "10 !important",
    fontSize: "8 !important",
    fontWeight: "bold !important",
    padding: "0 !important",
  },
  tableData: {
    padding: "1.5px !important",
  },
}));
