import {
  createSensitiveMedicineFail,
  createSensitiveMedicineRequest,
  createSensitiveMedicineSuccess,
  getSensitiveMedicineFail,
  getSensitiveMedicineSuccess,
  loadingSensitiveMedicine,
  updateSensitiveMedicineFail,
  updateSensitiveMedicineSuccess,
  getSensitiveMedicineRequest,
  getSensitiveMedicineNextRequest,
  getSensitiveMedicinePreviousRequest,
  updateSensitiveMedicineRequest,
  clearSensitiveMedicineData,
} from "./sensitiveMedicineSlice";
import { mergeMap } from "rxjs";
import {
  createSensitiveMedicine,
  getSensitiveMedicine,
  updateSensitiveMedicine,
} from "./api";
import { map, filter } from "rxjs/operators";
import { Observable } from "rxjs";
import { Action } from "@reduxjs/toolkit";
import { combineEpics } from "redux-observable";
import {
  alertErrorAction,
  alertSuccessAction,
  closeModal,
} from "../../../CommonAppRedux/CommonAppSlice";
import { getNext, getPrevious } from "../../../CommonAppRedux/api";
import {
  dispatchAction,
  stateAction,
} from "../../../../AppUtils/Utils/globalTypes";
import messages from "../../../../AppUtils/Utils/validationConstants";
export const controller = new AbortController();
// import { searchData } from "../../../CommonAppRedux/commonAppFunctions";
//get Type epic
const getSensitiveMedicineEpic = (
  action$: Observable<Action>,
  _: stateAction,
  { dispatch }: dispatchAction
) =>
  action$.pipe(
    filter(getSensitiveMedicineRequest.match),
    mergeMap(async (action) => {
      dispatch(loadingSensitiveMedicine());
      try {
        const response = await getSensitiveMedicine(action.payload);
        return { payload: response.data };
      } catch (e) {
        return { error: e };
      }
    }),
    map((action) =>
      action?.payload
        ? getSensitiveMedicineSuccess(action?.payload)
        : getSensitiveMedicineFail()
    )
  );

//get next
const getSensitiveMedicineNext = (
  action$: Observable<Action>,
  _: stateAction,
  { dispatch }: dispatchAction
) =>
  action$.pipe(
    filter(getSensitiveMedicineNextRequest.match),
    mergeMap(async (action) => {
      dispatch(loadingSensitiveMedicine());
      try {
        const response = await getNext(action.payload);
        return { payload: response.data };
      } catch (e) {
        return { error: e };
      }
    }),
    map((action) =>
      action?.payload
        ? getSensitiveMedicineSuccess(action?.payload)
        : getSensitiveMedicineFail()
    )
  );
//get previous
const getSensitiveMedicinePrevious = (
  action$: Observable<Action>,
  _: stateAction,
  { dispatch }: dispatchAction
) =>
  action$.pipe(
    filter(getSensitiveMedicinePreviousRequest.match),
    mergeMap(async (action) => {
      dispatch(loadingSensitiveMedicine());
      try {
        const response = await getPrevious(action.payload);
        return { payload: response.data };
      } catch (e) {
        return { error: e };
      }
    }),
    map((action) =>
      action?.payload
        ? getSensitiveMedicineSuccess(action?.payload)
        : getSensitiveMedicineFail()
    )
  );
const createSensitiveMedicineEpic = (
  action$: Observable<Action>,
  _: stateAction,
  { dispatch }: dispatchAction
) =>
  action$.pipe(
    filter(createSensitiveMedicineRequest.match),
    mergeMap(async ({ payload: { values, rowsPerPage, page } }) => {
      try {
        const body = new FormData();
        for (let [key, value] of Object.entries(values)) {
          // @ts-ignore
          body.append(`${key}`, value);
        }
        const response = await createSensitiveMedicine(body);
        if (response) {
          dispatch(getSensitiveMedicineRequest({ rowsPerPage, page }));
          dispatch(alertSuccessAction(messages.createMessage));
          dispatch(closeModal());
        }
        return { payload: { response } };
      } catch (e) {
        dispatch(alertErrorAction(messages.createFailMessage));
        return { error: e };
      }
    }),
    map((action) => {
      return action?.payload
        ? createSensitiveMedicineSuccess()
        : createSensitiveMedicineFail();
    })
  );
//update Type epic
const updateSensitiveMedicineEpic = (
  action$: Observable<Action>,
  _: stateAction,
  { dispatch }: dispatchAction
) =>
  action$.pipe(
    filter(updateSensitiveMedicineRequest.match),
    mergeMap(async ({ payload: { values, id, rowsPerPage, page } }) => {
      try {
        const body = new FormData();
        for (let [key, value] of Object.entries(values)) {
          // @ts-ignore
          body.append(`${key}`, value);
        }

        const response = await updateSensitiveMedicine(body, id);
        if (response) {
          dispatch(getSensitiveMedicineRequest({ rowsPerPage, page }));
          dispatch(alertSuccessAction(messages.updateMessage));
          dispatch(closeModal());
          dispatch(clearSensitiveMedicineData());
        }
        return { payload: { response, rowsPerPage } };
      }
       catch (e) {
        dispatch(alertErrorAction(messages.updateFailMessage));
        return { error: e };
      }
    }),
    map((action) =>
      action?.payload ? updateSensitiveMedicineSuccess() : updateSensitiveMedicineFail()
    )
  );
export const sensitiveMedicineEpics = combineEpics(
  getSensitiveMedicineEpic,
  createSensitiveMedicineEpic,
  updateSensitiveMedicineEpic,
  getSensitiveMedicineNext,
  getSensitiveMedicinePrevious,
);
