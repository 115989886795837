import { filterApiData } from "../../../../AppUtils/Utils/appFunctions";
import { axiosInstance } from "../../../../AppUtils/Utils/axios";

export const deviceOrderModURL = "/api/v1/appointment-app/clinic-order";
//get DeviceOrder data api
export const getDeviceOrder = (data: any) => {
  const { rowsPerPage, page, isDispatched } = data;
  console.log(data, "data--->");
  return axiosInstance.get(
    `${deviceOrderModURL}?offset=${rowsPerPage * page}&limit=${rowsPerPage}${
      data?.filterData ? filterApiData(data?.filterData) : ""
    }${isDispatched !== undefined ? `&is_dispatched=${isDispatched}` : ""}`
  );
};
export const getDeviceOrderCustomerType = ({ rowsPerPage }: any) => {
  return axiosInstance.get(
    `${deviceOrderModURL}/customer-type?offset=0&limit=${rowsPerPage}`
  );
};
export const getDeviceOrderDepartment = (rowsPerPage: number) => {
  return axiosInstance.get(
    `${deviceOrderModURL}/department?offset=0&limit=${rowsPerPage}`
  );
};
export const getDeviceOrderCategory = ({ rowsPerPage }: any) => {
  return axiosInstance.get(
    `${deviceOrderModURL}/clinicorder-category?offset=0&limit=${rowsPerPage}`
  );
};
//handle search
export const searchDeviceOrder = ({ id, rowsPerPage, search }: any) => {
  return `${deviceOrderModURL}?customer=${id}&offset=0&limit=${rowsPerPage}&search=${search}`;
};
//create DeviceOrder
export const createDeviceOrder = (body: string | FormData) =>
  axiosInstance.post(`${deviceOrderModURL}`, body);
//update DeviceOrder
export const updateDeviceOrder = (body: string | FormData, id: number) =>
  axiosInstance.patch(`${deviceOrderModURL}/${id}`, body);
//
export const getDeviceOrderById = (id: number) => {
  return axiosInstance.get(`${deviceOrderModURL}/${id}`);
};

//
export const getDeviceOrderItem = ({ id, defaultRowsPerPage }: any) =>
  axiosInstance.get(
    `${deviceOrderModURL}/item?item_category=${id}&offset=0&limit=${defaultRowsPerPage}`
  );

export const getDeviceOrderCustomer = (defaultRowsPerPage: number) =>
  axiosInstance.get(
    `/api/v1/appointment-app/customer?offset=0&limit=${defaultRowsPerPage}`
  );

export const getDeviceOrderItemCategory = (rowsPerPage: number) =>
  axiosInstance.get(
    `${deviceOrderModURL}/item-category?offset=0&limit=${rowsPerPage}`
  );

//update received status
export const updateDeviceReceivedStatus = (body: string, id: number) => {
  return axiosInstance.patch(`${deviceOrderModURL}/${id}/receive`, body);
};

//update dispatched status
export const updateDeviceDispatchedStatus = (body: string, id: number) => {
  return axiosInstance.patch(`${deviceOrderModURL}/${id}/dispatch`, body);
};

//get device order referrer
export const getDeviceOrderReferrer = (defaultRowsPerPage: number) => {
  return axiosInstance.get(
    `${deviceOrderModURL}/referral?offset=0&limit=${defaultRowsPerPage}`
  );
};
export const cancelDeviceOrder = (id: number) => {
  return axiosInstance.patch(`${deviceOrderModURL}/${id}/`);
};
