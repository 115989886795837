import { createAction, createSlice } from "@reduxjs/toolkit";

const initialState: any = {
  testSensitiveMedicines: [],
  edit: false,
  testSensitiveMedicine: null,
  loadingTestSensitiveMedicine: false,
  loadingCreateTestSensitiveMedicine: false,
  tests: [],
  searchData: [],
  sensitiveMedicines: [],
  count: 0,
  next: "",
  previous: "",
};
export const getSensitiveMedicineTestRequest = createAction(
  "getSensitiveMedicineTestRequest",
  function prepare(rowsPerPage) {
    return {
      payload: rowsPerPage,
    };
  }
);
export const getAllTestRequest = createAction(
  "getAllTestRequest",
  function prepare(rowsPerPage) {
    return {
      payload: rowsPerPage,
    };
  }
);

export const updateTestSensitiveMedicineRequest = createAction(
  "updateTestSensitiveMedicineRequest",
  function prepare({ values, id, rowsPerPage, page }) {
    return {
      payload: {
        values,
        id,
        rowsPerPage,
        page,
      },
    };
  }
);
export const getTestSensitiveMedicineRequest = createAction(
  "getTestSensitiveMedicineRequest",
  function prepare({ rowsPerPage, page, filterData }) {
    return {
      payload: {
        rowsPerPage,
        page,
        filterData,
      },
    };
  }
);
// get next request
export const getTestSensitiveMedicineNextRequest = createAction(
  "getTestSensitiveMedicineNextRequest",
  function prepare(next) {
    return {
      payload: next,
    };
  }
);
//get previous request
export const getTestSensitiveMedicinePreviousRequest = createAction(
  "getTestSensitiveMedicinePreviousRequest",
  function prepare(previous) {
    return {
      payload: previous,
    };
  }
);
export const TestSensitiveMedicine = createSlice({
  name: "TestSensitiveMedicineReducer",
  initialState,
  reducers: {
    loadingTestSensitiveMedicine: (state) => {
      state.loadingTestSensitiveMedicine = true;
    },
    getTestSensitiveMedicineSuccess: (
      state,
      { payload: { results, count, next, previous } }
    ) => {
      state.loadingTestSensitiveMedicine = false;
      state.testSensitiveMedicines = results;
      state.count = count;
      state.next = next;
      state.previous = previous;
    },
    getTestSensitiveTestSuccess: (state, { payload: { results } }) => {
      state.loading = true;
      state.tests = results;
    },
    getTestSensitiveTestFail: (state) => {
      state.loading = false;
    },
    getTestSensitiveSearchDataSuccess: (state, { payload: { results } }) => {
      state.loading = true;
      state.searchData = results;
    },
    getTestSensitiveSearchDataFail: (state) => {
      state.loading = false;
    },
    getSensitiveMedicineSuccess: (state, { payload: { results } }) => {
      state.loading = true;
      state.sensitiveMedicines = results;
    },
    getSensitiveMedicineFail: (state) => {
      state.loading = false;
    },
    getTestSensitiveMedicineFail: (state) => {
      state.loadingTestSensitiveMedicine = false;
    },
    createTestSensitiveMedicineRequest: (state, payload) => {
      state.loadingCreateTestSensitiveMedicine = true;
      state.closeModal = true;
    },
    createTestSensitiveMedicineSuccess: (state) => {
      state.loadingCreateTestSensitiveMedicine = false;
    },
    createTestSensitiveMedicineFail: (state) => {
      state.loadingCreateTestSensitiveMedicine = false;
    },
    getTestSensitiveMedicineByIdRequest: (state, paylaod) => {
      state.edit = true;
    },
    testSensitiveMedicineEditSuccess: (state, { payload }) => {
      state.testSensitiveMedicine = payload;
    },
    testSensitiveMedicineEditFail: (state) => {
      state.edit = false;
    },
    updateTestSensitiveMedicineSuccess: (state) => {
      state.loadingCreateTestSensitiveMedicine = false;
    },
    updateTestSensitiveMedicineFail: (state) => {
      state.loadingCreateTestSensitiveMedicine = false;
    },
    clearTestSensitiveMedicineData: (state) => {
      state.edit = false;
      state.testSensitiveMedicine = null;
    },
  },
});
// Action creators are generated for each case reducer function
export const {
  loadingTestSensitiveMedicine,
  getTestSensitiveMedicineSuccess,
  getTestSensitiveMedicineFail,
  createTestSensitiveMedicineRequest,
  createTestSensitiveMedicineSuccess,
  createTestSensitiveMedicineFail,
  //
  getTestSensitiveMedicineByIdRequest,
  testSensitiveMedicineEditSuccess,
  testSensitiveMedicineEditFail,
  //
  updateTestSensitiveMedicineSuccess,
  updateTestSensitiveMedicineFail,
  clearTestSensitiveMedicineData,
  getTestSensitiveTestSuccess,
  getTestSensitiveTestFail,
  getSensitiveMedicineSuccess,
  getSensitiveMedicineFail,
  getTestSensitiveSearchDataSuccess,
  getTestSensitiveSearchDataFail,
} = TestSensitiveMedicine.actions;
export default TestSensitiveMedicine.reducer;
