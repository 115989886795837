import { createSlice } from "@reduxjs/toolkit";
import { deviceRepairSpecialistInitialState } from "./types";

const initialState: deviceRepairSpecialistInitialState = {
  deviceRepairSpecialists: [],
  edit: false,
  deviceRepairSpecialist: null,
  loadingDeviceRepairSpecialist: false,
  loadingCreateDeviceRepairSpecialist: false,
  count: 0,
  previous: "",
  next: "",
};

export const DeviceRepairSpecialist = createSlice({
  name: "deviceRepairSpecialistReducer",
  initialState,
  reducers: {
    getDeviceRepairSpecialistRequest: (state, payload) => {
      state.loadingDeviceRepairSpecialist = true;
    },
    getDeviceRepairSpecialistSuccess: (
      state,
      { payload: { results, count, next, previous } }
    ) => {
      state.loadingDeviceRepairSpecialist = false;
      state.deviceRepairSpecialists = results;
      state.count = count;
      state.next = next;
      state.previous = previous;
    },
    getDeviceRepairSpecialistFail: (state) => {
      state.loadingDeviceRepairSpecialist = false;
    },

    getDeviceRepairSpecialistNextRequest: (state, payload) => {
      state.loadingDeviceRepairSpecialist = true;
    },
    getDeviceRepairSpecialistPreviousRequest: (state, payload) => {
      state.loadingDeviceRepairSpecialist = true;
    },
    createDeviceRepairSpecialistRequest: (state, payload) => {
      state.loadingCreateDeviceRepairSpecialist = true;
    },
    updateDeviceRepairSpecialistRequest: (state, payload) => {
      state.loadingCreateDeviceRepairSpecialist = true;
    },
    createDeviceRepairSpecialistSuccess: (state) => {
      state.loadingCreateDeviceRepairSpecialist = false;
    },
    createDeviceRepairSpecialistFail: (state) => {
      state.loadingCreateDeviceRepairSpecialist = false;
    },
    getDeviceRepairSpecialistByIdRequest: (state, payload) => {
      state.edit = true;
    },
    deviceRepairSpecialistEditSuccess: (state, { payload }) => {
      state.deviceRepairSpecialist = payload;
    },
    deviceRepairSpecialistEditFail: (state) => {
      state.edit = false;
    },
    updateDeviceRepairSpecialistSuccess: (state) => {
      state.loadingCreateDeviceRepairSpecialist = false;
    },
    updateDeviceRepairSpecialistFail: (state) => {
      state.loadingCreateDeviceRepairSpecialist = false;
    },
    clearAllData: (state) => {
      state.edit = false;
      state.deviceRepairSpecialist = null;
    },
  },
});

//action creaters generated for each case reducer
export const {
  getDeviceRepairSpecialistRequest,
  getDeviceRepairSpecialistFail,
  getDeviceRepairSpecialistSuccess,
  getDeviceRepairSpecialistNextRequest,
  getDeviceRepairSpecialistPreviousRequest,
  createDeviceRepairSpecialistFail,
  createDeviceRepairSpecialistRequest,
  createDeviceRepairSpecialistSuccess,
  deviceRepairSpecialistEditSuccess,
  updateDeviceRepairSpecialistFail,
  updateDeviceRepairSpecialistSuccess,
  updateDeviceRepairSpecialistRequest,
  clearAllData,
  getDeviceRepairSpecialistByIdRequest,
  deviceRepairSpecialistEditFail,
} = DeviceRepairSpecialist.actions;

export default DeviceRepairSpecialist.reducer;
