import React, { Suspense, lazy } from "react";
import { Switch, Route } from "react-router-dom";
import ResetPasswordConfirm from "../Pages/Auth/ResetPassword/ResetPasswordConfirm";

const ResetPassword = lazy(() => import("../Pages/Auth/ResetPassword"));
const Login = lazy(() => import("../Pages/Auth/LoginPages/Login"));
const PublicRoutes = () => {

  return (
    <>
      <Suspense fallback="">
        <Switch>
          <Route exact path="/">
            <Login />
          </Route>
          <Route exact path="/reset-password">
            <ResetPassword />
          </Route>
          <Route exact path="/confirm-password">
            <ResetPasswordConfirm />
          </Route>
          <Route exact path="/reset-password/confirm/:token">
            <ResetPasswordConfirm />
          </Route>
          <Route path="/">
            <Login />
          </Route>
        </Switch>
      </Suspense>
    </>
  );
};

export default PublicRoutes;
