import { memo } from "react";
import { Autocomplete, Box, Grid, TextField } from "@mui/material";
import { handleFocus } from "../../../AppUtils/FocusElement/focusElement";
import { ErrorMessage } from "formik";
import TextError from "../../TextError/TextError";
import { appAutoCompleteProps } from "./types";
const AppAutoComplete = ({
  options,
  value,
  name,
  getOptionLabel,
  onChange,
  inputRef,
  label,
  xs,
  md,
  lg,
  sm,
  required,
  focusElement,
  onBlur,
  multiple,
  onFocus,
  isNotFormik,
  autoFocus,
  inputOnchange,
  loading,
  disabled,
  filterSelectedOptions,
  renderTags,
  renderOption,
  mt,
  classes,
  handleDisabledOption,
  groupBy,
  onKeyUp,
  InputProps,
}: appAutoCompleteProps) => {
  const handleFocusElement = (e: React.KeyboardEvent<HTMLDivElement>) =>
    handleFocus(focusElement, e, required && value);
  const defaultRenderOption = (props: any, option: any) => (
    <Box component="li" {...props} key={option.id}>
      {getOptionLabel(option)}
    </Box>
  );

  return (
    <Grid
      item
      xs={xs}
      md={md}
      lg={lg}
      sm={sm}
      mt={mt}
      className="textfield-wrapper"
    >
      <Autocomplete
        id={label}
        options={options}
        multiple={multiple}
        classes={classes}
        value={value}
        getOptionLabel={getOptionLabel}
        isOptionEqualToValue={(option, value) => {
          return option?.id
            ? option?.id === value?.id
            : option?.label === value?.label;
          // return option?.id  === value?.id;
        }}
        onFocus={onFocus}
        onChange={onChange}
        onBlur={onBlur}
        loading={loading}
        disabled={disabled}
        getOptionDisabled={handleDisabledOption}
        renderTags={renderTags}
        filterSelectedOptions={filterSelectedOptions}
        renderOption={renderOption ?? defaultRenderOption}
        groupBy={groupBy}
        renderInput={(params) => (
          <TextField
            {...params}
            onChange={inputOnchange}
            name={name}
            variant="outlined"
            label={label && label}
            autoFocus={autoFocus ?? false}
            inputRef={inputRef}
            required={required}
            disabled={disabled}
            onKeyDown={handleFocusElement}
            onKeyUp={onKeyUp}
          />
        )}
      />
      {!isNotFormik && <ErrorMessage name={name!} component={TextError} />}
    </Grid>
  );
};

export default memo(AppAutoComplete);
