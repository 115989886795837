import { axiosInstance } from "../../../../AppUtils/Utils/axios";
import { filterApiData } from "../../../../AppUtils/Utils/appFunctions";
import {
  getApiProps,
  searchProps,
} from "../../../../AppUtils/Utils/globalTypes";

const sensitiveMedicineModURL = "/api/v1/lab-app/sensitive-medicine";

export const getSensitiveMedicine = (data: getApiProps) => {
  const { rowsPerPage, page } = data;
  return axiosInstance.get(
    `${sensitiveMedicineModURL}?offset=${
      rowsPerPage * page
    }&limit=${rowsPerPage}${
      data?.filterData ? filterApiData(data?.filterData) : ""
    }`
  );
};
export const searchSensitiveMedicine = ({
  rowsPerPage,
  search,
}: searchProps) => {
  return `${sensitiveMedicineModURL}?offset=0&limit=${rowsPerPage}&search=${search}`;
};
export const createSensitiveMedicine = (body: string | FormData) =>
  axiosInstance.post(`${sensitiveMedicineModURL}`, body);
//update SensitiveMedicine
export const updateSensitiveMedicine = (body: string | FormData, id: number) =>
  axiosInstance.patch(`${sensitiveMedicineModURL}/${id}`, body);

export const getAllSensitiveMedicineForDefault = () =>
  axiosInstance.get<any>(`${sensitiveMedicineModURL}`);
