import {
  createRepairOrderFail,
  createRepairOrderRequest,
  createRepairOrderSuccess,
  getRepairOrderSuccess,
  getRepairOrderFail,
  updateRepairOrderFail,
  updateRepairOrderSuccess,
  getRepairOrderRequest,
  updateRepairOrderRequest,
  getRepairOrderNextRequest,
  getRepairOrderPreviousRequest,
  clearRepairOrderData,
  getRepairOrderByIdRequest,
  repairOrderEditSuccess,
  repairOrderEditFail,
  getRepairOrderItemRequest,
  getRepairOrderItemSuccess,
  getRepairOrderItemFail,
  updateRepairReceivedStatusRequest,
  updateRepairReceivedStatusSuccess,
  updateRepairReceivedStatusFail,
  updateRepairDispatchedStatusRequest,
  updateRepairDispatchedStatusSuccess,
  updateRepairDispatchedStatusFail,
  getRepairOrderCustomerRequest,
  getRepairOrderCustomerSuccess,
  getRepairOrderCustomerFail,
  getRepairOrderSpecialistRequest,
  getRepairOrderSpecialistSuccess,
  getRepairOrderSpecialistFail,
  updateDeviceOrderSpecialistRequest,
  updateDeviceOrderSpecialistSuccess,
  updateDeviceOrderSpecialistFail,
} from "./repairOrderSlice";
import { mergeMap } from "rxjs";
import {
  createRepairOrder,
  getRepairOrder,
  updateRepairOrder,
  getRepairOrderById,
  getRepairOrderItem,
  updateRepairReceivedStatus,
  updateRepairDispatchedStatus,
  getRepairOrderCustomer,
  getRepairOrderSpecialist,
  updateDeviceOrderSpecialist,
} from "./api";
import { map, filter, tap } from "rxjs/operators";
import { Observable } from "rxjs";
import { Action } from "@reduxjs/toolkit";
import { combineEpics } from "redux-observable";
import {
  alertErrorAction,
  alertSuccessAction,
  closeModal,
} from "../../../CommonAppRedux/CommonAppSlice";
import messages from "../../../../AppUtils/Utils/validationConstants";
import {
  dispatchAction,
  stateAction,
} from "../../../../AppUtils/Utils/globalTypes";
import { getNext, getPrevious } from "../../../CommonAppRedux/api";

// get repairorder epic
const getRepairOrderEpic = (
  action$: Observable<Action>,
  _: stateAction,
  { dispatch }: dispatchAction
) =>
  action$.pipe(
    filter(getRepairOrderRequest.match),
    mergeMap(async (action) => {
      try {
        const response = await getRepairOrder(action.payload);
        return { payload: response.data };
      } catch (e) {
        return { error: e };
      }
    }),
    map((action) =>
      action?.payload
        ? getRepairOrderSuccess(action?.payload)
        : getRepairOrderFail()
    )
  );

//get next
const getRepairOrderNext = (
  action$: Observable<Action>,
  _: stateAction,
  { dispatch }: dispatchAction
) =>
  action$.pipe(
    filter(getRepairOrderNextRequest.match),
    mergeMap(async (action) => {
      try {
        const response = await getNext(action.payload);
        return { payload: response.data };
      } catch (e) {
        return { error: e };
      }
    }),
    map((action) =>
      action?.payload
        ? getRepairOrderSuccess(action?.payload)
        : getRepairOrderFail()
    )
  );

//get previous
const getRepairOrderPrevious = (
  action$: Observable<Action>,
  _: stateAction,
  { dispatch }: dispatchAction
) =>
  action$.pipe(
    filter(getRepairOrderPreviousRequest.match),
    mergeMap(async (action) => {
      try {
        const response = await getPrevious(action.payload);
        return { payload: response.data };
      } catch (e) {
        return { error: e };
      }
    }),
    map((action) =>
      action?.payload
        ? getRepairOrderSuccess(action?.payload)
        : getRepairOrderFail()
    )
  );
//create RepairOrder epic
const createRepairOrderEpic = (
  action$: Observable<Action>,
  _: stateAction,
  { dispatch }: dispatchAction
) =>
  action$.pipe(
    filter(createRepairOrderRequest.match),
    mergeMap(async ({ payload: { values, rowsPerPage, page } }) => {
      try {
        const body = JSON.stringify(values);

        const response = await createRepairOrder(body);
        if (response) {
          dispatch(getRepairOrderRequest({ rowsPerPage, page }));
          dispatch(alertSuccessAction(messages.createMessage));
          dispatch(closeModal());
          dispatch(clearRepairOrderData());
        }
        return { payload: { response } };
      } catch (e) {
        dispatch(alertErrorAction(messages.createFailMessage));
        return { error: e };
      }
    }),
    map((action) => {
      return action?.payload
        ? createRepairOrderSuccess()
        : createRepairOrderFail();
    })
  );

//update RepairOrder epic
const updateRepairOrderEpic = (
  action$: Observable<Action>,
  _: stateAction,
  { dispatch }: dispatchAction
) =>
  action$.pipe(
    filter(updateRepairOrderRequest.match),
    mergeMap(async ({ payload: { values, id, rowsPerPage, page } }) => {
      try {
        const body = JSON.stringify(values);
        const response = await updateRepairOrder(body, id);
        if (response) {
          dispatch(getRepairOrderRequest({ rowsPerPage, page }));
          dispatch(alertSuccessAction(messages.updateMessage));
          dispatch(clearRepairOrderData());
          dispatch(closeModal());
        }
        return { payload: { response, rowsPerPage } };
      } catch (e) {
        dispatch(alertErrorAction(messages.updateFailMessage));
        return { error: e };
      }
    }),
    map((action) =>
      action?.payload ? updateRepairOrderSuccess() : updateRepairOrderFail()
    )
  );

const getRepairOrderByIdEpic = (
  action$: Observable<Action>,
  _: stateAction,
  { dispatch }: dispatchAction
) =>
  action$.pipe(
    filter(getRepairOrderByIdRequest.match),
    mergeMap(async (action) => {
      try {
        const response = await getRepairOrderById(action.payload);
        return { payload: response.data };
      } catch (e) {
        return { error: e };
      }
    }),
    map((action) =>
      action?.payload
        ? repairOrderEditSuccess(action.payload)
        : repairOrderEditFail()
    )
  );

//item
const getRepairOrderItemEpic = (
  action$: Observable<Action>,
  _: stateAction,
  { dispatch }: dispatchAction
) =>
  action$.pipe(
    filter(getRepairOrderItemRequest.match),
    mergeMap(async (action: any) => {
      try {
        const response = await getRepairOrderItem(action.payload);
        return { payload: response.data };
      } catch (e) {
        return { error: e };
      }
    }),
    map((action) =>
      action?.payload
        ? getRepairOrderItemSuccess(action?.payload)
        : getRepairOrderItemFail()
    )
  );

//update Repair order received status
const updateRepairReceivedStatusEpic = (
  action$: Observable<Action>,
  _: stateAction,
  { dispatch }: dispatchAction
) =>
  action$.pipe(
    filter(updateRepairReceivedStatusRequest.match),
    mergeMap(
      async ({
        payload: { values, id, rowsPerPage, page, setShowReceived },
      }) => {
        try {
          const body = JSON.stringify(values);
          const response = await updateRepairReceivedStatus(body, id);
          if (response) {
            dispatch(getRepairOrderRequest({ rowsPerPage, page }));
            dispatch(alertSuccessAction(messages.updateMessage));
            dispatch(clearRepairOrderData());
            dispatch(closeModal());
            setShowReceived && setShowReceived(false);
          }
          return { payload: { response, rowsPerPage } };
        } catch (e) {
          dispatch(alertErrorAction(messages.updateFailMessage));
          return { error: e };
        }
      }
    ),
    map((action) =>
      action?.payload
        ? updateRepairReceivedStatusSuccess()
        : updateRepairReceivedStatusFail()
    )
  );

//update reapair Order dispatched status
const updateRepairDispatchedStatusEpic = (
  action$: Observable<Action>,
  _: stateAction,
  { dispatch }: dispatchAction
) =>
  action$.pipe(
    filter(updateRepairDispatchedStatusRequest.match),
    mergeMap(
      async ({
        payload: { values, id, rowsPerPage, page, setShowDispatch },
      }) => {
        try {
          const body = JSON.stringify(values);
          const response = await updateRepairDispatchedStatus(body, id);
          if (response) {
            dispatch(getRepairOrderRequest({ rowsPerPage, page }));
            dispatch(alertSuccessAction(messages.updateMessage));
            dispatch(clearRepairOrderData());
            dispatch(closeModal());
            setShowDispatch && setShowDispatch(false);
          }
          return { payload: { response, rowsPerPage } };
        } catch (e) {
          dispatch(alertErrorAction(messages.updateFailMessage));
          return { error: e };
        }
      }
    ),
    map((action) =>
      action?.payload
        ? updateRepairDispatchedStatusSuccess()
        : updateRepairDispatchedStatusFail()
    )
  );

//customer
const getRepairOrderCustomerEpic = (
  action$: Observable<Action>,
  _: stateAction,
  { dispatch }: dispatchAction
) =>
  action$.pipe(
    filter(getRepairOrderCustomerRequest.match),
    mergeMap(async (action: any) => {
      try {
        const response = await getRepairOrderCustomer(action.payload);
        return { payload: response.data };
      } catch (e) {
        return { error: e };
      }
    }),
    map((action) =>
      action?.payload
        ? getRepairOrderCustomerSuccess(action?.payload)
        : getRepairOrderCustomerFail()
    )
  );

// get repairorder specialist epic
const getRepairOrderSpecialistEpic = (
  action$: Observable<Action>,
  _: stateAction,
  { dispatch }: dispatchAction
) =>
  action$.pipe(
    filter(getRepairOrderSpecialistRequest.match),
    mergeMap(async (action) => {
      try {
        const response = await getRepairOrderSpecialist(action.payload);
        return { payload: response.data };
      } catch (e) {
        return { error: e };
      }
    }),
    map((action) =>
      action?.payload
        ? getRepairOrderSpecialistSuccess(action?.payload)
        : getRepairOrderSpecialistFail()
    )
  );

//update RepairOrder specialist epic
const updateDeviceOrderSpecialistEpic = (
  action$: Observable<Action>,
  _: stateAction,
  { dispatch }: dispatchAction
) =>
  action$.pipe(
    filter(updateDeviceOrderSpecialistRequest.match),
    mergeMap(
      async ({
        payload: { values, id, rowsPerPage, page, setShowIsRepaired },
      }) => {
        try {
          const body = JSON.stringify(values);
          const response = await updateDeviceOrderSpecialist(body, id);
          if (response) {
            dispatch(getRepairOrderRequest({ rowsPerPage, page }));
            dispatch(alertSuccessAction(messages.updateMessage));
            dispatch(clearRepairOrderData());
            setShowIsRepaired && setShowIsRepaired(false);
          }
          return { payload: { response, rowsPerPage } };
        } catch (e) {
          dispatch(alertErrorAction(messages.updateFailMessage));
          return { error: e };
        }
      }
    ),
    map((action) =>
      action?.payload
        ? updateDeviceOrderSpecialistSuccess()
        : updateDeviceOrderSpecialistFail()
    )
  );
export const repairOrderEpics = combineEpics(
  getRepairOrderEpic,
  createRepairOrderEpic,
  updateRepairOrderEpic,
  getRepairOrderNext,
  getRepairOrderPrevious,
  getRepairOrderByIdEpic,
  getRepairOrderItemEpic,
  updateRepairDispatchedStatusEpic,
  updateRepairReceivedStatusEpic,
  getRepairOrderCustomerEpic,
  getRepairOrderSpecialistEpic,
  updateDeviceOrderSpecialistEpic
);
