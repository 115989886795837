import {
  getDepartmentWiseSaleReportUserRequest,
  getDepartmentWiseSaleReportAllSummaryRequest,
  getDepartmentWiseSaleReportAllDetailRequest,
  getDepartmentWiseSaleReportQuickSummaryRequest,
  getDepartmentWiseSaleReportQuickDetailRequest,
  //reducer get data
  getDepartmentWiseSaleReportUserSuccess,
  getDepartmentWiseSaleReportUserFail,
  getDepartmentWiseSaleReportQuickDetailSuccess,
  getDepartmentWiseSaleReportQuickDetailFail,
  getDepartmentWiseSaleReportAllDetailSuccess,
  getDepartmentWiseSaleReportAllDetailFail,
  getDepartmentWiseSaleReportQuickSummarySuccess,
  getDepartmentWiseSaleReportQuickSummaryFail,
  getDepartmentWiseSaleReportAllSummarySuccess,
  getDepartmentWiseSaleReportAllSummaryFail,
  getDepartmentWiseSaleReportDetailSuccess,
  getDepartmentWiseSaleReportDetailFail,
  loadingDepartmentWiseSaleReportDetail,
  getDepartmentWiseSaleReportPreviousRequest,
  getDepartmentWiseSaleReportNextRequest,
  getDepartmentWiseSaleReportDetailRequest,
} from "./departmentWiseSaleReportSlice";
import { mergeMap } from "rxjs";
import *  as API from "./api";
import { map, filter, tap } from "rxjs/operators";
import { Observable } from "rxjs";
import { Action } from "@reduxjs/toolkit";
import { combineEpics } from "redux-observable";
import {
  alertErrorAction,
  alertSuccessAction,
  closeModal,
} from "../../../../CommonAppRedux/CommonAppSlice";
import { getNext, getPrevious } from "../../../../CommonAppRedux/api";
import {
  dispatchAction,
  stateAction,
} from "../../../../../AppUtils/Utils/globalTypes";

export const controller = new AbortController();

//user
const getDepartmentWiseSaleReportUserEpic = (
  action$: Observable<Action>,
  _: stateAction,
  { dispatch }: dispatchAction
) =>
  action$.pipe(
    filter(getDepartmentWiseSaleReportUserRequest.match),
    mergeMap(async (action) => {
      try {
        const response = await API.getDepartmentWiseSaleReportUser();
        return { payload: response.data };
      } catch (e) {
        return { error: e };
      }
    }),
    map((action) =>
      action?.payload
        ? getDepartmentWiseSaleReportUserSuccess(action?.payload)
        : getDepartmentWiseSaleReportUserFail()
    )
  );
//item

//get all purchase summary detail
const getDepartmentWiseSaleReportAllSummaryEpic = (
  action$: Observable<Action>,
  _: stateAction,
  { dispatch }: dispatchAction
) =>
  action$.pipe(
    filter(getDepartmentWiseSaleReportAllSummaryRequest.match),
    mergeMap(async (action) => {
      try {
        const response = await API.getDepartmentWiseSaleReportAllReport(action?.payload);
        if (response?.data?.count === 0) {
          dispatch(
            alertErrorAction(`No Department Wise Data Found`)
          )
        }
        return { payload: response.data };
      } catch (e) {
        return { error: e };
      }
    }),
    map((action) =>
      action?.payload
        ? getDepartmentWiseSaleReportAllSummarySuccess(action?.payload)
        : getDepartmentWiseSaleReportAllSummaryFail()
    )
  );
//quick summary
const getDepartmentWiseSaleReportQuickSummaryEpic = (
  action$: Observable<Action>,
  _: stateAction,
  { dispatch }: dispatchAction
) =>
  action$.pipe(
    filter(getDepartmentWiseSaleReportQuickSummaryRequest.match),
    mergeMap(async (action) => {
      try {
        const response = await API.getDepartmentWiseSaleReportQuickReport(action.payload);
        if (response?.data?.count === 0) {
          dispatch(
            alertErrorAction(`No Department Wise Data Found`)
          )
        }
        return { payload: response.data };
      } catch (e) {
        return { error: e };
      }
    }),
    map((action) =>
      action?.payload
        ? getDepartmentWiseSaleReportQuickSummarySuccess(action?.payload)
        : getDepartmentWiseSaleReportQuickSummaryFail()
    )
  );

//get all purchase summary detail
const getDepartmentWiseSaleReportAllDetailEpic = (
  action$: Observable<Action>,
  _: stateAction,
  { dispatch }: dispatchAction
) =>
  action$.pipe(
    filter(getDepartmentWiseSaleReportAllDetailRequest.match),
    mergeMap(async (action) => {
      try {
        const response = await API.getDepartmentWiseSaleReportAllReport(action?.payload);
        return { payload: response.data };
      } catch (e) {
        return { error: e };
      }
    }),
    map((action) =>
      action?.payload
        ? getDepartmentWiseSaleReportAllDetailSuccess(action?.payload)
        : getDepartmentWiseSaleReportAllDetailFail()
    )
  );
//quick Detail
const getDepartmentWiseSaleReportQuickDetailEpic = (
  action$: Observable<Action>,
  _: stateAction,
  { dispatch }: dispatchAction
) =>
  action$.pipe(
    filter(getDepartmentWiseSaleReportQuickDetailRequest.match),
    mergeMap(async (action) => {
      try {
        const response = await API.getDepartmentWiseSaleReportQuickReport(action.payload);
        return { payload: response.data };
      } catch (e) {
        return { error: e };
      }
    }),
    map((action) =>
      action?.payload
        ? getDepartmentWiseSaleReportQuickDetailSuccess(action?.payload)
        : getDepartmentWiseSaleReportQuickDetailFail()
    )
  );

//next and previous
//get next
const getDepartmentWiseSaleReportNext = (
  action$: Observable<Action>,
  _: stateAction,
  { dispatch }: dispatchAction
) =>
  action$.pipe(
    filter(getDepartmentWiseSaleReportNextRequest.match),
    mergeMap(async (action) => {
      dispatch(loadingDepartmentWiseSaleReportDetail());
      try {
        const response = await getNext(action.payload);
        return { payload: response.data };
      } catch (e) {
        return { error: e };
      }
    }),
    map((action) =>
      action?.payload
        ? getDepartmentWiseSaleReportDetailSuccess(action?.payload)
        : getDepartmentWiseSaleReportDetailFail()
    )
  );
//get previous
const getDepartmentWiseSaleReportPrevious = (
  action$: Observable<Action>,
  _: stateAction,
  { dispatch }: dispatchAction
) =>
  action$.pipe(
    filter(getDepartmentWiseSaleReportPreviousRequest.match),
    mergeMap(async (action) => {
      dispatch(loadingDepartmentWiseSaleReportDetail());
      try {
        const response = await getPrevious(action.payload);
        return { payload: response.data };
      } catch (e) {
        return { error: e };
      }
    }),
    map((action) =>
      action?.payload
        ? getDepartmentWiseSaleReportDetailSuccess(action?.payload)
        : getDepartmentWiseSaleReportDetailFail()
    )
  );
//het purchase report details
const getDepartmentWiseSaleReportEpic = (
  action$: Observable<Action>,
  _: stateAction,
  { dispatch }: dispatchAction
) =>
  action$.pipe(
    filter(getDepartmentWiseSaleReportDetailRequest.match),
    mergeMap(async (action) => {
      try {
        const response = await API.getDepartmentWiseSaleReportQuickReport(action.payload);

        return { payload: response.data };
      } catch (e) {
        return { error: e };
      }
    }),
    map((action) =>
      action?.payload
        ? getDepartmentWiseSaleReportDetailSuccess(action?.payload)
        : getDepartmentWiseSaleReportDetailFail()
    )
  );
export const DepartmentWiseSaleReportEpics = combineEpics(
  getDepartmentWiseSaleReportUserEpic,
  getDepartmentWiseSaleReportAllSummaryEpic,
  getDepartmentWiseSaleReportQuickSummaryEpic,
  getDepartmentWiseSaleReportQuickDetailEpic,
  getDepartmentWiseSaleReportAllDetailEpic,
  getDepartmentWiseSaleReportPrevious,
  getDepartmentWiseSaleReportNext,
  getDepartmentWiseSaleReportEpic,
);
