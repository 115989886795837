import { createSlice } from "@reduxjs/toolkit";
import { userContactProps } from "./type";

const initialState: userContactProps = {
  userContact: [],
  loadingUserContact: false,
  edit: false,
  editContact: null,
  loadingContactSubmitButton: false,
  count: 0,
  next: 0,
  previous: 0,
  patientName: "",
};

const contactSlice = createSlice({
  name: "ContactReducer",
  initialState,
  reducers: {
    getUserContactRequest: (state, payload) => {
      state.loadingUserContact = true;
    },
    getUserContactSuccess: (
      state,
      { payload: { results, count, next, previous } }
    ) => {
      state.loadingUserContact = false;
      state.userContact = results[0]?.userContacts;
      state.patientName = results[0]?.fullName;
      state.count = count;
      state.next = next;
      state.previous = previous;
    },
    getUserContactFail: (state) => {
      state.loadingUserContact = false;
    },
    editUserContactRequest: (state, payload) => {
      state.edit = true;
    },
    editUserContactSuccess: (state, { payload }) => {
      state.edit = true;
      state.editContact = payload;
    },
    editUserContactFail: (state) => {
      state.edit = false;
      state.editContact = null;
    },
    createUserContactRequest: (state, payload) => {
      state.loadingContactSubmitButton = true;
    },
    createUserContactSuccess: (state) => {
      state.loadingContactSubmitButton = false;
    },
    createUserContactFail: (state) => {
      state.loadingContactSubmitButton = false;
    },
    updateUserContactRequest: (state, payload) => {
      state.loadingContactSubmitButton = true;
    },
    updateUserContactSuccess: (state) => {
      state.loadingContactSubmitButton = false;
    },
    updateUserContactFail: (state) => {
      state.loadingContactSubmitButton = false;
    },
    clearAllContactData: (state) => {
      state.edit = false;
      state.editContact = null;
    },
  },
});

export const {
  getUserContactRequest,
  getUserContactSuccess,
  getUserContactFail,
  editUserContactRequest,
  editUserContactSuccess,
  editUserContactFail,
  createUserContactRequest,
  createUserContactSuccess,
  createUserContactFail,
  updateUserContactRequest,
  updateUserContactSuccess,
  updateUserContactFail,
  clearAllContactData,
} = contactSlice.actions;

export default contactSlice.reducer;
