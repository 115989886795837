import {
  createReagentFail,
  createReagentRequest,
  createReagentSuccess,
  getReagentSuccess,
  getReagentFail,
  updateReagentFail,
  updateReagentSuccess,
  getReagentRequest,
  updateReagentRequest,
  getReagentNextRequest,
  getReagentPreviousRequest,
  clearReagentData,
  getReagentByIdRequest,
  reagentEditSuccess,
  reagentEditFail,
} from "./reagentSlice";
import { mergeMap } from "rxjs";
import {
  createReagent,
  getReagent,
  getReagentById,
  updateReagent,
} from "./api";
import { map, filter } from "rxjs/operators";
import { Observable } from "rxjs";
import { Action } from "@reduxjs/toolkit";
import { combineEpics } from "redux-observable";
import {
  alertErrorAction,
  alertSuccessAction,
  closeModal,
} from "../../../CommonAppRedux/CommonAppSlice";
import { getNext, getPrevious } from "../../../CommonAppRedux/api";
import {
  dispatchAction,
  stateAction,
} from "../../../../AppUtils/Utils/globalTypes";
import messages from "../../../../AppUtils/Utils/validationConstants";

export const controller = new AbortController();

// get manufacture epic
const getReagentEpic = (
  action$: Observable<Action>,
  _: stateAction,
  { dispatch }: dispatchAction
) =>
  action$.pipe(
    filter(getReagentRequest.match),
    mergeMap(async (action) => {
      try {
        const response = await getReagent(action.payload);
        return { payload: response.data };
      } catch (e) {
        return { error: e };
      }
    }),
    map((action) =>
      action?.payload ? getReagentSuccess(action?.payload) : getReagentFail()
    )
  );

//get test category by id epic
const getReagentByIdEpic = (
  action$: Observable<Action>,
  _: stateAction,
  { dispatch }: dispatchAction
) =>
  action$.pipe(
    filter(getReagentByIdRequest.match),
    mergeMap(async (action) => {
      try {
        const response = await getReagentById(action.payload);
        return { payload: response.data };
      } catch (e) {
        return { error: e };
      }
    }),
    map((action) =>
      action?.payload ? reagentEditSuccess(action.payload) : reagentEditFail()
    )
  );

//get next
const getReagentNext = (
  action$: Observable<Action>,
  _: stateAction,
  { dispatch }: dispatchAction
) =>
  action$.pipe(
    filter(getReagentNextRequest.match),
    mergeMap(async (action) => {
      try {
        const response = await getNext(action.payload);
        return { payload: response.data };
      } catch (e) {
        return { error: e };
      }
    }),
    map((action) =>
      action?.payload ? getReagentSuccess(action?.payload) : getReagentFail()
    )
  );

//get previous
const getReagentPrevious = (
  action$: Observable<Action>,
  _: stateAction,
  { dispatch }: dispatchAction
) =>
  action$.pipe(
    filter(getReagentPreviousRequest.match),
    mergeMap(async (action) => {
      try {
        const response = await getPrevious(action.payload);
        return { payload: response.data };
      } catch (e) {
        return { error: e };
      }
    }),
    map((action) =>
      action?.payload ? getReagentSuccess(action?.payload) : getReagentFail()
    )
  );
//create Reagent epic
const createReagentEpic = (
  action$: Observable<Action>,
  _: stateAction,
  { dispatch }: dispatchAction
) =>
  action$.pipe(
    filter(createReagentRequest.match),
    mergeMap(async ({ payload: { values, rowsPerPage, page } }) => {
      const { name, active } = values;
      try {
        const body = JSON.stringify({
          name,
          active,
        });
        const response = await createReagent(body);
        if (response) {
          dispatch(getReagentRequest({ rowsPerPage, page }));
          dispatch(alertSuccessAction(messages.createMessage));
          dispatch(closeModal());
        }
        return { payload: { response } };
      } catch (e) {
        dispatch(alertErrorAction(messages.createFailMessage));
        return { error: e };
      }
    }),
    map((action) => {
      return action?.payload ? createReagentSuccess() : createReagentFail();
    })
  );

//update Reagent epic
const updateReagentEpic = (
  action$: Observable<Action>,
  _: stateAction,
  { dispatch }: dispatchAction
) =>
  action$.pipe(
    filter(updateReagentRequest.match),
    mergeMap(async ({ payload: { values, id, rowsPerPage, page } }) => {
      const { name, active, remarks } = values;
      try {
        const body = JSON.stringify({
          name,
          active,
          remarks,
        });
        const response = await updateReagent(body, id);
        if (response) {
          dispatch(getReagentRequest({ rowsPerPage, page }));
          dispatch(alertSuccessAction(messages.updateMessage));
          dispatch(clearReagentData());
          dispatch(closeModal());
        }
        return { payload: { response, rowsPerPage } };
      } catch (e) {
        dispatch(alertErrorAction(messages.updateFailMessage));
        return { error: e };
      }
    }),
    map((action) =>
      action?.payload ? updateReagentSuccess() : updateReagentFail()
    )
  );

export const reagentEpics = combineEpics(
  getReagentEpic,
  createReagentEpic,
  updateReagentEpic,
  getReagentNext,
  getReagentPrevious,
  getReagentByIdEpic
);
