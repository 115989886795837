import { axiosInstance } from "../../../../AppUtils/Utils/axios";
import { filterApiData } from "../../../../AppUtils/Utils/appFunctions";
import {
  getApiProps,
  searchProps,
} from "../../../../AppUtils/Utils/globalTypes";

const customerModURL = "/api/v1/core-app/customer-type";

export const getCustomerType = (data: getApiProps) => {
  const { rowsPerPage, page } = data;
  return axiosInstance.get(
    `${customerModURL}?offset=${rowsPerPage * page}&limit=${rowsPerPage}${
      data?.filterData ? filterApiData(data?.filterData) : ""
    }`
  );
};

//get customer type by id
export const getCustomerTypeById = (id: number) => {
  return axiosInstance.get(`${customerModURL}/${id}`);
};

export const searchCustomerType = ({ rowsPerPage, search }: searchProps) => {
  return `${customerModURL}?offset=0&limit=${rowsPerPage}&search=${search}`;
};
export const createCustomerType = (body: string | FormData) =>
  axiosInstance.post(`${customerModURL}`, body);
//update CustomerType
export const updateCustomerType = (body: string | FormData, id: number) =>
  axiosInstance.patch(`${customerModURL}/${id}`, body);

export const getDefaultCustomerType = () =>
  axiosInstance.get<any>(`${customerModURL}?is_default=true`);
