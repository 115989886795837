import { createSlice, createAction } from "@reduxjs/toolkit";
const initialState: any = {
  appointments: [],
  loadingAppointment: false,
  loadingCreateAppointment: false,
  loadingSite: false,
  sites: [],
  loadingClinic: false,
  clinics: [],
  loadingClinicForRoutineAndPendingListReq: false,
  clinicsForRoutineAndPendingList: [],
  filterClinics: [],
  loadingClinicRoutine: false,
  clinicRoutines: [],
  loadingDoctor: false,
  doctors: [],
  filterDoctors: [],
  loadingTitle: false,
  titles: [],
  loadingAppointmentType: false,
  appointmentTypes: [],
  loadingReferralType: false,
  referralTypes: [],
  loadingCustomer: false,
  customers: [],
  loadingPendingReferralList: false,
  pendingReferralLists: [],
  makeAppointmentData: null,
  showMakeAppointmentModal: false,
  dateRange: "",
  appointment: null,
  showSuccessModal: false,
  showDischargeModal: false,
  showReviewModal: false,
  showReferralModal: false,
  loadingAppointmentById: false,
  filterAppointmentById: null,
  districts: [],
  loadingAppointmentDistrict: false,
  provinces: [],
  loadingAppointmentProvince: false,
  filterAppointmentTypes: [],
  loadingFilterAppointmentTypes: false,
  // loading state for start appointment
  loadingStartAppointment: false,
  appointmentStarted: false,
  loadingReferrer:false,
  referrers:[]
};

export const Appointment = createSlice({
  name: "AppointmentReducer",
  initialState,
  reducers: {
    getAppointmentRequest: (state, payload) => {
      state.loadingAppointment = true;
    },
    getAppointmentSuccess: (state, { payload: { results } }) => {
      const updatedAppointment = results.map((result: any) => ({
        ...result,
        event_id: result.eventId,
        start: new Date(result?.start),
        end: new Date(result?.end),
        doctor_id: result.doctor.id,
        draggable: false,
        deletable: false,
        color: result.doctor.colorCode ? result.doctor.colorCode : "#2d4d7b",
        // editable:
        //   result.status === "SCHEDULED" || result.status === "RESCHEDULED"
        //     ? true
        //     : false,
        editable:true,
      }));
      state.loadingAppointment = false;
      state.appointments = updatedAppointment;
    },
    getAppointmentFail: (state) => {
      state.loadingAppointment = false;
    },
    getFilteredAppointmentRequest: (state) => {
      state.loadingAppointmentById = true;
    },
    getFilteredAppointmentSuccess: (state, { payload: { results } }) => {
      state.loadingAppointmentById = false;
      state.filterAppointmentById = results;
    },
    getFilteredAppointmentFail: (state) => {
      state.loadingAppointmentById = false;
    },
    getCheckAppointmentFail: (state) => {
      state.loadingAppointment = false;
    },
    loadingAppointmentClinicReq: (state) => {
      state.loadingClinic = true;
    },
    getAppointmentClinicRequest: (state) => {
      state.loadingClinic = true;
    },
    getAppointmentClinicSuccess: (state, { payload: { results } }) => {
      state.loadingClinic = false;
      state.clinics = results;
      state.filterClinics = results;
    },
    getAppointmentClinicFail: (state) => {
      state.loadingClinic = false;
    },
    //
    loadingAppointmentProvinceRequest: (state) => {
      state.loadingAppointmentProvince = true;
    },
    getAppointmentProvinceRequest: (state) => {
      state.loadingAppointmentProvince = true;
    },
    getAppointmentProvinceSuccess: (state, { payload: { results } }) => {
      state.loadingAppointmentProvince = false;
      state.provinces = results;
    },
    getAppointmentProvinceFail: (state) => {
      state.loadingAppointmentProvince = false;
    },
    //
    loadingAppointmentDistrictRequest: (state) => {
      state.loadingAppointmentDistrict = true;
    },
    getAppointmentDistrictRequest: (state, payload) => {
      state.loadingAppointmentDistrict = true;
    },
    getAllAppointmentDistrictRequest: (state) => {
      state.loadingAppointmentDistrict = true;
    },
    getAppointmentDistrictSuccess: (state, { payload: { results } }) => {
      state.loadingAppointmentDistrict = false;
      state.districts = results;
    },
    getAppointmentDistrictFail: (state) => {
      state.loadingAppointmentDistrict = false;
    },
    //
    loadingClinicForRoutineAndPendingListReq: (state) => {
      state.loadingClinicForRoutineAndPendingList = true;
    },
    getClinicForRoutineAndPendingListRequest: (state) => {
      state.loadingClinicForRoutineAndPendingList = true;
    },
    getClinicForRoutineAndPendingListSuccess: (
      state,
      { payload: { results } }
    ) => {
      state.loadingClinicForRoutineAndPendingList = false;
      state.clinicsForRoutineAndPendingList = results;
    },
    getClinicForRoutineAndPendingListFail: (state) => {
      state.loadingClinicForRoutineAndPendingList = false;
    },
    getClinicRoutineRequest: (state, payload) => {
      state.loadingClinicRoutine = true;
    },
    getClinicRoutineSuccess: (state, { payload: { results } }) => {
      state.loadingClinicRoutine = false;
      state.clinicRoutines = results;
    },
    getClinicRoutineFail: (state) => {
      state.loadingClinicRoutine = false;
    },
    loadingAppointmentSiteReq: (state) => {
      state.loadingSite = true;
    },
    getAppointmentSiteRequest: (state) => {
      state.loadingSite = true;
    },
    getAppointmentSiteSuccess: (state, { payload: { results } }) => {
      state.loadingSite = false;
      state.sites = results;
    },
    getAppointmentSiteFail: (state) => {
      state.loadingSite = false;
    },
    loadingAppointmentDoctorReq: (state) => {
      state.loadingDoctor = true;
    },
    getAppointmentDoctorRequest: (state, payload) => {
      state.loadingDoctor = true;
    },
    getAppointmentDoctorSuccess: (state, { payload: { results } }) => {
      state.loadingDoctor = false;
      state.doctors = results;
      state.filterDoctors = results;
    },
    getAppointmentDoctorFail: (state) => {
      state.loadingDoctor = false;
    },
    loadingAppointmentTitleReq: (state) => {
      state.loadingTitle = true;
    },
    getAppointmentTitleRequest: (state) => {
      state.loadingTitle = true;
    },
    getAppointmentTitleSuccess: (state, { payload: { results } }) => {
      state.loadingTitle = false;
      state.titles = results;
    },
    getAppointmentTitleFail: (state) => {
      state.loadingTitle = false;
    },
    loadingAppointmentAppointmentTypeReq: (state) => {
      state.loadingAppointmentType = true;
    },
    getAppointmentAppointmentTypeRequest: (state) => {
      state.loadingAppointmentType = true;
    },
    getAppointmentAppointmentTypeSuccess: (state, { payload: { results } }) => {
      state.loadingAppointmentType = false;
      state.appointmentTypes = results;
    },
    getAppointmentAppointmentTypeFail: (state) => {
      state.loadingAppointmentType = false;
    },
    loadingAppointmentReferralTypeReq: (state) => {
      state.loadingReferralType = true;
    },
    getAppointmentReferralTypeRequest: (state) => {
      state.loadingReferralType = true;
    },
    getAppointmentReferralTypeSuccess: (state, { payload: { results } }) => {
      state.loadingReferralType = false;
      state.referralTypes = results;
    },
    getAppointmentReferralTypeFail: (state) => {
      state.loadingReferralType = false;
    },
    //
    loadingAppointmentFilterReq: (state) => {
      state.loadingFilterAppointmentTypes = true;
    },
    getAppointmentFilterRequest: (state) => {
      state.loadingFilterAppointmentTypes = true;
    },
    getAppointmentFilterSuccess: (state, { payload: { results } }) => {
      state.loadingFilterAppointmentTypes = false;
      state.filterAppointmentTypes = results;
    },
    getAppointmentFilterFail: (state) => {
      state.loadingFilterAppointmentTypes = false;
    },
    //
    loadingAppointmentCustomerReq: (state) => {
      state.loadingCustomer = true;
    },
    getAppointmentCustomerRequest: (state, payload) => {
      state.loadingCustomer = true;
    },
    getAppointmentCustomerSuccess: (state, { payload: { results } }) => {
      state.loadingCustomer = false;
      state.customers = results;
    },
    getAppointmentCustomerFail: (state) => {
      state.loadingCustomer = false;
    },

    getAppointmentFromDoctorRequest: (state, { payload: { doctor } }) => {
      state.loadingAppointment = true;
    },
    getAppointmentFromDoctorSuccess: (state, { payload: { results } }) => {
      const updatedAppointment = results.map((result: any) => ({
        ...result,
        event_id: result.eventId,
        doctor_id: result.doctor.id,
        start: new Date(result.start),
        end: new Date(result.end),
        draggable: false,
        deletable: false,
        color: result.doctor.colorCode ? result.doctor.colorCode : "#2d4d7b",
        editable:
          result.status === "SCHEDULED" || result.status === "RESCHEDULED"
            ? true
            : false,
      }));
      state.loadingAppointment = false;
      state.appointments = updatedAppointment;
    },
    getAppointmentFromDoctorFail: (state) => {
      state.loadingAppointment = false;
    },
    getAppointmentFromClinicRequest: (state, payload) => {
      state.loadingAppointment = true;
    },
    getAppointmentFromClinicSuccess: (state, { payload: { results } }) => {
      const updatedAppointment = results.map((result: any) => ({
        ...result,
        event_id: result.eventId,
        start: new Date(result.start),
        end: new Date(result.end),
        doctor_id: result.doctor.id,
        draggable: false,
        deletable: false,
        color: result.doctor.colorCode ? result.doctor.colorCode : "#2d4d7b",
        editable:
          result.status === "SCHEDULED" || result.status === "RESCHEDULED"
            ? true
            : false,
      }));
      state.loadingAppointment = false;
      state.appointments = updatedAppointment;
    },
    getAppointmentFromClinicFail: (state) => {
      state.loadingAppointment = false;
    },
    getAppointmentFromSiteRequest: (state, payload) => {
      state.loadingAppointment = true;
    },
    getAppointmentFromSiteSuccess: (state, { payload: { results } }) => {
      const updatedAppointment = results.map((result: any) => ({
        ...result,
        event_id: result.eventId,
        start: new Date(result.start),
        end: new Date(result.end),
        doctor_id: result.doctor.id,
        draggable: false,
        deletable: false,
        color: result.doctor.colorCode ? result.doctor.colorCode : "#2d4d7b",
        editable:
          result.status === "SCHEDULED" || result.status === "RESCHEDULED"
            ? true
            : false,
      }));
      state.loadingAppointment = false;
      state.appointments = updatedAppointment;
    },
    getAppointmentFromSiteFail: (state) => {
      state.loadingAppointment = false;
    },
    getAppointmentFromCustomerNoRequest: (state, payload) => {
      state.loadingAppointment = true;
    },
    getAppointmentFromCustomerNoSuccess: (state, { payload: { results } }) => {
      const updatedAppointment = results.map((result: any) => ({
        ...result,
        event_id: result.eventId,
        start: new Date(result.start),
        end: new Date(result.end),
        doctor_id: result.doctor.id,
        draggable: false,
        deletable: false,
        color: result.doctor.colorCode ? result.doctor.colorCode : "#2d4d7b",
        editable:
          result.status === "SCHEDULED" || result.status === "RESCHEDULED"
            ? true
            : false,
      }));
      state.loadingAppointment = false;
      state.appointments = updatedAppointment;
    },
    getAppointmentFromCustomerNoFail: (state) => {
      state.loadingAppointment = false;
    },
    getAppointmentFromStatusRequest: (state, payload) => {
      state.loadingAppointment = true;
    },
    getAppointmentFromStatusSuccess: (state, { payload: { results } }) => {
      const updatedAppointment = results.map((result: any) => ({
        ...result,
        event_id: result.eventId,
        doctor_id: result.doctor.id,
        start: new Date(result.start),
        end: new Date(result.end),
        draggable: false,
        deletable: false,
        color: result.doctor.colorCode ? result.doctor.colorCode : "#2d4d7b",
        editable:
          result.status === "SCHEDULED" || result.status === "RESCHEDULED"
            ? true
            : false,
      }));
      state.loadingAppointment = false;
      state.appointments = updatedAppointment;
    },
    getAppointmentFromStatusFail: (state) => {
      state.loadingAppointment = false;
    },

    getDoctorFromClinicRequest: (state, payload) => {
      state.loadingDoctor = true;
    },
    getDoctorFromClinicSuccess: (state, { payload: { results } }) => {
      state.loadingDoctor = false;
      state.doctors = results;
    },
    getDoctorFromClinicFail: (state) => {
      state.loadingDoctor = false;
    },
    getFilterDoctorFromClinicRequest: (state, payload) => {
      state.loadingDoctor = true;
    },
    getFilterDoctorFromClinicSuccess: (state, { payload: { results } }) => {
      state.loadingDoctor = false;
      state.filterDoctors = results;
    },
    getFilterDoctorFromClinicFail: (state) => {
      state.loadingDoctor = false;
    },
    getClinicFromSiteRequest: (state, payload) => {
      state.loadingClinic = true;
    },
    getClinicFromSiteSuccess: (state, { payload: { results } }) => {
      state.loadingClinic = false;
      state.clinics = results;
    },
    getClinicFromSiteFail: (state) => {
      state.loadingClinic = false;
    },
    getFilterClinicFromSiteRequest: (state, payload) => {
      state.loadingClinic = true;
    },
    getFilterClinicFromSiteSuccess: (state, { payload: { results } }) => {
      state.loadingClinic = false;
      state.filterClinics = results;
    },
    getFilterClinicFromSiteFail: (state) => {
      state.loadingClinic = false;
    },
    saveDateRange: (state, { payload: { query } }) => {
      state.dateRange = query;
    },
    getPendingReferralListRequest: (state, payload) => {
      state.loadingPendingReferralList = true;
    },
    getPendingReferralListSuccess: (state, { payload: { results } }) => {
      state.loadingPendingReferralList = false;
      state.pendingReferralLists = results;
    },
    getPendingReferralListFail: (state) => {
      state.loadingPendingReferralList = false;
    },
    setShowMakeAppointmentModal: (state, { payload }) => {
      state.makeAppointmentData = payload;
      state.showMakeAppointmentModal = true;
    },
    setHideMakeAppointmentModal: (state) => {
      state.makeAppointmentData = null;
      state.showMakeAppointmentModal = false;
    },
    showConfirmationModalAfterAppointmentCompleted: (state, { payload }) => {
      state.showSuccessModal = payload;
    },
    showAppointmentDischargeModal: (state, { payload }) => {
      state.showDischargeModal = payload;
    },
    showAppointmentReviewModal: (state, { payload }) => {
      state.showReviewModal = payload;
    },
    showAppointmentReferralModal: (state, { payload }) => {
      state.showReferralModal = payload;
    },
    createAppointmentRequest: (state, payload) => {
      state.loadingCreateAppointment = true;
    },
    createAppointmentSuccess: (state) => {
      state.loadingCreateAppointment = false;
    },
    createAppointmentFail: (state) => {
      state.loadingCreateAppointment = false;
    },
    updateAppointmentRequest: (state, payload) => {
      state.loadingCreateAppointment = true;
    },
    updateAppointmentSuccess: (state) => {
      state.loadingCreateAppointment = false;
    },
    updateAppointmentFail: (state) => {
      state.loadingCreateAppointment = false;
    },
    //start appointment

    startAppointmentRequest: (state, payload) => {
      state.loadingStartAppointment = true;
    },
    startAppointmentSuccess: (state) => {
      state.loadingStartAppointment = false;
      state.appointmentStarted = true;
    },
    startAppointmentFail: (state) => {
      state.loadingStartAppointment = false;
      // state.appointmentStarted = false;
    },
    stopAppointmentRequest: (state, payload) => {
      state.loadingStopAppointment = true;
    },
    stopAppointmentSuccess: (state) => {
      state.loadingStopAppointment = false;
      state.appointmentStoped = true;
    },
    stopAppointmentFail: (state) => {
      state.loadingStopAppointment = false;
      // state.appointmentStarted = false;
    },
    // referrer
    loadingAppointmentReferrerReq: (state) => {
      state.loadingReferrer = true;
    },
    getAppointmentReferrerRequest: (state) => {
      state.loadingReferrer = true;
    },
    getAppointmentReferrerSuccess: (state, { payload: { results } }) => {
      state.loadingReferrer = false;
      state.referrers = results;
    },
    getAppointmentReferrerFail: (state) => {
      state.loadingReferrer = false;
    },
    //
    clearDoctorResourcesData: (state) => {
      state.loadingDoctor = false;
    },
    clearClinicRoutineData: (state) => {
      state.clinicRoutines = [];
    },
    clearPendingReferralData: (state) => {
      state.pendingReferralLists = [];
    },
  },
});
// Action creators are generated for each case reducer function
export const {
  getAppointmentRequest,
  getAppointmentSuccess,
  getAppointmentFail,
  getFilteredAppointmentRequest,
  getFilteredAppointmentSuccess,
  getFilteredAppointmentFail,
  loadingAppointmentClinicReq,
  getAppointmentClinicRequest,
  getAppointmentClinicSuccess,
  getAppointmentClinicFail,
  loadingClinicForRoutineAndPendingListReq,
  getClinicForRoutineAndPendingListRequest,
  getClinicForRoutineAndPendingListSuccess,
  getClinicForRoutineAndPendingListFail,
  getClinicRoutineRequest,
  getClinicRoutineSuccess,
  getClinicRoutineFail,
  loadingAppointmentSiteReq,
  getAppointmentSiteRequest,
  getAppointmentSiteSuccess,
  getAppointmentSiteFail,
  loadingAppointmentDoctorReq,
  getAppointmentDoctorRequest,
  getAppointmentDoctorSuccess,
  getAppointmentDoctorFail,
  loadingAppointmentTitleReq,
  getAppointmentTitleRequest,
  getAppointmentTitleSuccess,
  getAppointmentTitleFail,
  loadingAppointmentAppointmentTypeReq,
  getAppointmentAppointmentTypeRequest,
  getAppointmentAppointmentTypeSuccess,
  getAppointmentAppointmentTypeFail,
  loadingAppointmentReferralTypeReq,
  getAppointmentReferralTypeRequest,
  getAppointmentReferralTypeSuccess,
  getAppointmentReferralTypeFail,
  loadingAppointmentCustomerReq,
  getAppointmentCustomerRequest,
  getAppointmentCustomerSuccess,
  getAppointmentCustomerFail,
  getClinicFromSiteRequest,
  getClinicFromSiteSuccess,
  getClinicFromSiteFail,
  getFilterClinicFromSiteRequest,
  getFilterClinicFromSiteSuccess,
  getFilterClinicFromSiteFail,
  getDoctorFromClinicRequest,
  getDoctorFromClinicSuccess,
  getDoctorFromClinicFail,
  getFilterDoctorFromClinicRequest,
  getFilterDoctorFromClinicSuccess,
  getFilterDoctorFromClinicFail,
  getAppointmentFromSiteRequest,
  getAppointmentFromSiteSuccess,
  getAppointmentFromSiteFail,
  getAppointmentFromDoctorRequest,
  getAppointmentFromDoctorSuccess,
  getAppointmentFromDoctorFail,
  getAppointmentFromClinicRequest,
  getAppointmentFromClinicSuccess,
  getAppointmentFromClinicFail,
  getAppointmentFromCustomerNoRequest,
  getAppointmentFromCustomerNoSuccess,
  getAppointmentFromCustomerNoFail,
  getAppointmentFromStatusRequest,
  getAppointmentFromStatusSuccess,
  getAppointmentFromStatusFail,
  saveDateRange,
  getPendingReferralListRequest,
  getPendingReferralListSuccess,
  getPendingReferralListFail,
  setShowMakeAppointmentModal,
  setHideMakeAppointmentModal,
  showConfirmationModalAfterAppointmentCompleted,
  showAppointmentDischargeModal,
  showAppointmentReviewModal,
  showAppointmentReferralModal,
  createAppointmentRequest,
  createAppointmentSuccess,
  createAppointmentFail,
  updateAppointmentRequest,
  updateAppointmentSuccess,
  updateAppointmentFail,
  //referrer
  loadingAppointmentReferrerReq,
  getAppointmentReferrerRequest,
  getAppointmentReferrerSuccess,
  getAppointmentReferrerFail,
  //
  loadingAppointmentDistrictRequest,
  getAppointmentDistrictRequest,
  getAllAppointmentDistrictRequest,
  getAppointmentDistrictSuccess,
  getAppointmentDistrictFail,
  //
  loadingAppointmentProvinceRequest,
  getAppointmentProvinceRequest,
  getAppointmentProvinceSuccess,
  getAppointmentProvinceFail,
  //
  getAppointmentFilterRequest,
  loadingAppointmentFilterReq,
  getAppointmentFilterSuccess,
  getAppointmentFilterFail,
  //
  startAppointmentRequest,
  startAppointmentSuccess,
  startAppointmentFail,
  //
  stopAppointmentRequest,
  stopAppointmentSuccess,
  stopAppointmentFail,
  //
  clearDoctorResourcesData,
  clearClinicRoutineData,
  clearPendingReferralData,
} = Appointment.actions;
export default Appointment.reducer;
