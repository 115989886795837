import { createSlice, createAction } from "@reduxjs/toolkit";
import { manufacturer } from "./types";
const initialState: any = {
  manufacturers: [],
  loadingManufacturer: false,
  loadingCreateManufacturer: false,
  edit: false,
  manufacturer: null,
  count: 0,
  previous: "",
  next: "",
};

export const Manufacturer = createSlice({
  name: "manufacturerReducer",
  initialState,
  reducers: {
    loadingManufacturer: (state) => {
      state.loadingManufacturer = true;
    },
    getManufacturerRequest: (state, payload) => {
      state.loadingManufacturer = true;
    },
    getManufacturerSuccess: (
      state,
      { payload: { results, count, next, previous } }
    ) => {
      state.loadingManufacturer = false;
      state.manufacturers = results;
      state.count = count;
      state.next = next;
      state.previous = previous;
    },
    getManufacturerFail: (state) => {
      state.loadingManufacturer = false;
    },
    getManufacturerNextRequest: (state, payload) => {
      state.loadingManufacturer = true;
    },
    getManufacturerPreviousRequest: (state, payload) => {
      state.loadingManufacturer = true;
    },
    createManufacturerRequest: (state, payload) => {
      state.loadingCreateManufacturer = true;
      state.closeModal = true;
    },
    createManufacturerSuccess: (state) => {
      state.loadingCreateManufacturer = false;
    },
    createManufacturerFail: (state) => {
      state.loadingCreateManufacturer = false;
    },
    manufacturerEditSuccess: (state, { payload }) => {
      const selectedManufacturer = state.manufacturers.find(
        (selected: manufacturer) => selected.id === payload.id
      );
      state.edit = true;
      state.manufacturer = selectedManufacturer;
    },
    updateManufacturerRequest: (state, payload) => {
      state.loadingCreateManufacturer = true;
    },
    updateManufacturerSuccess: (state) => {
      state.loadingCreateManufacturer = false;
    },
    updateManufacturerFail: (state) => {
      state.loadingCreateManufacturer = false;
    },
    clearManufacturerData: (state) => {
      state.edit = false;
      state.manufacturer = null;
    },
  },
});
// Action creators are generated for each case reducer function
export const {
  loadingManufacturer,
  getManufacturerRequest,
  getManufacturerSuccess,
  getManufacturerFail,
  getManufacturerNextRequest,
  getManufacturerPreviousRequest,
  createManufacturerRequest,
  createManufacturerSuccess,
  createManufacturerFail,
  manufacturerEditSuccess,
  updateManufacturerRequest,
  updateManufacturerSuccess,
  updateManufacturerFail,
  clearManufacturerData,
} = Manufacturer.actions;
export default Manufacturer.reducer;
