import { createSlice } from "@reduxjs/toolkit";
import { doctorInitialState } from "./types";

const initialState: doctorInitialState = {
  doctors: [],
  loadingDoctor: false,
  loadingCreateDoctor: false,
  edit: false,
  doctor: null,
  titles: [],
  loadingDoctorTitle: false,
  clinics: [],
  loadingDoctorClinic: false,
  count: 0,
  previous: "",
  next: "",
};

export const Doctor = createSlice({
  name: "Doctor Reducer",
  initialState,
  reducers: {
    getDoctorRequest: (state, payload) => {
      state.loadingDoctor = true;
    },
    getDoctorSuccess: (
      state,
      { payload: { results, count, next, previous } }
    ) => {
      state.loadingDoctor = false;
      state.doctors = results;
      state.count = count;
      state.next = next;
      state.previous = previous;
    },
    getDoctorFail: (state) => {
      state.loadingDoctor = false;
    },
    loadingDoctorTitleRequest: (state) => {
      state.loadingDoctorTitle = true;
    },
    getDoctorTitleRequest: (state, payload) => {
      state.loadingDoctorTitle = true;
    },
    getDoctorTitleSuccess: (state, { payload: { results } }) => {
      state.loadingDoctorTitle = false;
      state.titles = results;
    },
    getDoctorTitleFail: (state) => {
      state.loadingDoctorTitle = false;
    },
    loadingDoctorClinicRequest: (state, payload) => {
      state.loadingDoctorClinic = true;
    },
    getDoctorClinicRequest: (state, payload) => {
      state.loadingDoctorClinic = true;
    },
    getDoctorClinicSuccess: (state, { payload: { results } }) => {
      state.loadingDoctorClinic = false;
      state.clinics = results;
    },
    getDoctorClinicFail: (state) => {
      state.loadingDoctorClinic = false;
    },
    getDoctorNextRequest: (state, payload) => {
      state.loadingDoctor = true;
    },
    getDoctorPreviousRequest: (state, payload) => {
      state.loadingDoctor = true;
    },
    getDoctorByIdRequest: (state, payload) => {
      state.edit = true;
    },
    createDoctorRequest: (state, payload) => {
      state.loadingCreateDoctor = true;
    },
    updateDoctorRequest: (state, payload) => {
      state.loadingCreateDoctor = true;
    },
    createDoctorSuccess: (state) => {
      state.loadingCreateDoctor = false;
    },
    createDoctorFail: (state) => {
      state.loadingCreateDoctor = false;
    },
    doctorEditSuccess: (state, { payload }) => {
      state.doctor = payload;
    },
    doctorEditFail: (state) => {
      state.edit = false;
    },
    updateDoctorSuccess: (state) => {
      state.loadingCreateDoctor = false;
    },
    updateDoctorFail: (state) => {
      state.loadingCreateDoctor = false;
    },
    clearDoctorData: (state) => {
      state.edit = false;
      state.doctor = null;
    },
  },
});

// Action creators are generated for each case reducer function
export const {
  getDoctorRequest,
  getDoctorSuccess,
  getDoctorFail,
  getDoctorPreviousRequest,
  getDoctorNextRequest,
  createDoctorRequest,
  createDoctorSuccess,
  createDoctorFail,
  doctorEditSuccess,
  updateDoctorSuccess,
  updateDoctorFail,
  clearDoctorData,
  updateDoctorRequest,
  getDoctorByIdRequest,
  doctorEditFail,
  //
  loadingDoctorTitleRequest,
  getDoctorTitleRequest,
  getDoctorTitleSuccess,
  getDoctorTitleFail,
  //
  loadingDoctorClinicRequest,
  getDoctorClinicRequest,
  getDoctorClinicSuccess,
  getDoctorClinicFail,
} = Doctor.actions;
export default Doctor.reducer;
