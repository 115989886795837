import {
  createSampleTypeFail,
  createSampleTypeRequest,
  createSampleTypeSuccess,
  getSampleTypeSuccess,
  getSampleTypeFail,
  updateSampleTypeFail,
  updateSampleTypeSuccess,
  getSampleTypeRequest,
  updateSampleTypeRequest,
  getSampleTypeNextRequest,
  getSampleTypePreviousRequest,
  clearSampleTypeData,
  getSampleTypeByIdRequest,
  sampleTypeEditSuccess,
  sampleTypeEditFail,
} from "./sampleTypeSlice";
import { mergeMap } from "rxjs";
import {
  createSampleType,
  getSampleType,
  getSampleTypeById,
  updateSampleType,
} from "./api";
import { map, filter } from "rxjs/operators";
import { Observable } from "rxjs";
import { Action } from "@reduxjs/toolkit";
import { combineEpics } from "redux-observable";
import {
  alertErrorAction,
  alertSuccessAction,
  closeModal,
} from "../../../CommonAppRedux/CommonAppSlice";
import { getNext, getPrevious } from "../../../CommonAppRedux/api";
import {
  dispatchAction,
  stateAction,
} from "../../../../AppUtils/Utils/globalTypes";
import messages from "../../../../AppUtils/Utils/validationConstants";

export const controller = new AbortController();

// get manufacture epic
const getSampleTypeEpic = (
  action$: Observable<Action>,
  _: stateAction,
  { dispatch }: dispatchAction
) =>
  action$.pipe(
    filter(getSampleTypeRequest.match),
    mergeMap(async (action) => {
      try {
        const response = await getSampleType(action.payload);
        return { payload: response.data };
      } catch (e) {
        return { error: e };
      }
    }),
    map((action) =>
      action?.payload
        ? getSampleTypeSuccess(action?.payload)
        : getSampleTypeFail()
    )
  );

//get test category by id epic
const getSampleTypeByIdEpic = (
  action$: Observable<Action>,
  _: stateAction,
  { dispatch }: dispatchAction
) =>
  action$.pipe(
    filter(getSampleTypeByIdRequest.match),
    mergeMap(async (action) => {
      try {
        const response = await getSampleTypeById(action.payload);
        return { payload: response.data };
      } catch (e) {
        return { error: e };
      }
    }),
    map((action) =>
      action?.payload
        ? sampleTypeEditSuccess(action.payload)
        : sampleTypeEditFail()
    )
  );

//get next
const getSampleTypeNext = (
  action$: Observable<Action>,
  _: stateAction,
  { dispatch }: dispatchAction
) =>
  action$.pipe(
    filter(getSampleTypeNextRequest.match),
    mergeMap(async (action) => {
      try {
        const response = await getNext(action.payload);
        return { payload: response.data };
      } catch (e) {
        return { error: e };
      }
    }),
    map((action) =>
      action?.payload
        ? getSampleTypeSuccess(action?.payload)
        : getSampleTypeFail()
    )
  );

//get previous
const getSampleTypePrevious = (
  action$: Observable<Action>,
  _: stateAction,
  { dispatch }: dispatchAction
) =>
  action$.pipe(
    filter(getSampleTypePreviousRequest.match),
    mergeMap(async (action) => {
      try {
        const response = await getPrevious(action.payload);
        return { payload: response.data };
      } catch (e) {
        return { error: e };
      }
    }),
    map((action) =>
      action?.payload
        ? getSampleTypeSuccess(action?.payload)
        : getSampleTypeFail()
    )
  );
//create SampleType epic
const createSampleTypeEpic = (
  action$: Observable<Action>,
  _: stateAction,
  { dispatch }: dispatchAction
) =>
  action$.pipe(
    filter(createSampleTypeRequest.match),
    mergeMap(async ({ payload: { values, rowsPerPage, page } }) => {
      const { name, code, active } = values;
      try {
        const body = JSON.stringify({
          name,
          code,
          active,
        });
        const response = await createSampleType(body);
        if (response) {
          dispatch(getSampleTypeRequest({ rowsPerPage, page }));
          dispatch(alertSuccessAction(messages.createMessage));
          dispatch(closeModal());
        }
        return { payload: { response } };
      } catch (e) {
        dispatch(alertErrorAction(messages.createFailMessage));
        return { error: e };
      }
    }),
    map((action) => {
      return action?.payload
        ? createSampleTypeSuccess()
        : createSampleTypeFail();
    })
  );

//update SampleType epic
const updateSampleTypeEpic = (
  action$: Observable<Action>,
  _: stateAction,
  { dispatch }: dispatchAction
) =>
  action$.pipe(
    filter(updateSampleTypeRequest.match),
    mergeMap(async ({ payload: { values, id, rowsPerPage, page } }) => {
      const { name, code, active, remarks } = values;
      try {
        const body = JSON.stringify({
          name,
          code,
          active,
          remarks,
        });
        const response = await updateSampleType(body, id);
        if (response) {
          dispatch(getSampleTypeRequest({ rowsPerPage, page }));
          dispatch(alertSuccessAction(messages.updateMessage));
          dispatch(clearSampleTypeData());
          dispatch(closeModal());
        }
        return { payload: { response, rowsPerPage } };
      } catch (e) {
        dispatch(alertErrorAction(messages.updateFailMessage));
        return { error: e };
      }
    }),
    map((action) =>
      action?.payload ? updateSampleTypeSuccess() : updateSampleTypeFail()
    )
  );

export const sampleTypeEpics = combineEpics(
  getSampleTypeEpic,
  createSampleTypeEpic,
  updateSampleTypeEpic,
  getSampleTypeNext,
  getSampleTypePrevious,
  getSampleTypeByIdEpic
);
