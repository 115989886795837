import { createSlice } from "@reduxjs/toolkit";
import { clinicSetupInitialState } from "./types";

const initialState: clinicSetupInitialState = {
  clinicSetups: [],
  loadingClinicSetup: false,
  loadingCreateClinicSetup: false,
  edit: false,
  clinicSetup: null,
  sites: [],
  loadingClinicSite: false,
  count: 0,
  previous: "",
  next: "",
};

export const ClinicSetup = createSlice({
  name: "ClinicSetup Reducer",
  initialState,
  reducers: {
    getClinicSetupRequest: (state, payload) => {
      state.loadingClinicSetup = true;
    },
    getClinicSetupSuccess: (
      state,
      { payload: { results, count, next, previous } }
    ) => {
      state.loadingClinicSetup = false;
      state.clinicSetups = results;
      state.count = count;
      state.next = next;
      state.previous = previous;
    },
    getClinicSetupFail: (state) => {
      state.loadingClinicSetup = false;
    },
    loadingClinicSiteRequest: (state) => {
      state.loadingClinicSite = true;
    },
    getClinicSiteRequest: (state, payload) => {
      state.loadingClinicSite = true;
    },
    getClinicSiteSuccess: (state, { payload: { results } }) => {
      state.loadingClinicSite = false;
      state.sites = results;
    },
    getClinicSiteFail: (state) => {
      state.loadingClinicSite = false;
    },
    getClinicSetupNextRequest: (state, payload) => {
      state.loadingClinicSetup = true;
    },
    getClinicSetupPreviousRequest: (state, payload) => {
      state.loadingClinicSetup = true;
    },
    createClinicSetupRequest: (state, payload) => {
      state.loadingCreateClinicSetup = true;
    },
    createClinicSetupSuccess: (state) => {
      state.loadingCreateClinicSetup = false;
    },
    createClinicSetupFail: (state) => {
      state.loadingCreateClinicSetup = false;
    },
    getClinicSetupByIdRequest: (state, payload) => {
      state.edit = true;
    },
    clinicSetupEditSuccess: (state, { payload }) => {
      state.clinicSetup = payload;
    },
    clinicSetupEditFail: (state) => {
      state.edit = false;
    },
    updateClinicSetupRequest: (state, payload) => {
      state.loadingCreateClinicSetup = true;
    },
    updateClinicSetupSuccess: (state) => {
      state.loadingCreateClinicSetup = false;
    },
    updateClinicSetupFail: (state) => {
      state.loadingCreateClinicSetup = false;
    },
    clearClinicSetupData: (state) => {
      state.edit = false;
      state.clinicSetup = null;
    },
  },
});
// Action creators are generated for each case reducer function
export const {
  getClinicSetupRequest,
  getClinicSetupSuccess,
  getClinicSetupFail,
  //
  getClinicSetupNextRequest,
  getClinicSetupPreviousRequest,
  //
  createClinicSetupRequest,
  createClinicSetupSuccess,
  createClinicSetupFail,
  //
  clinicSetupEditSuccess,
  //
  updateClinicSetupRequest,
  updateClinicSetupSuccess,
  updateClinicSetupFail,
  //
  clearClinicSetupData,
  getClinicSetupByIdRequest,
  clinicSetupEditFail,
  //
  loadingClinicSiteRequest,
  getClinicSiteRequest,
  getClinicSiteSuccess,
  getClinicSiteFail,

} = ClinicSetup.actions;
export default ClinicSetup.reducer;
