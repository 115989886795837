import { axiosInstance } from "../../../../AppUtils/Utils/axios";
import { filterApiData } from "../../../../AppUtils/Utils/appFunctions";
import {
  getApiProps,
  searchProps,
} from "../../../../AppUtils/Utils/globalTypes";

export const doctorModURL = "api/v1/doctor-app/doctor";

//get doctor by id
export const getDoctorById = (id: number) => {
  return axiosInstance.get(`${doctorModURL}/${id}`);
};
//get doctor title
export const getDoctorTitle = (rowsPerPage: number) => {
  return axiosInstance.get(
    `${doctorModURL}/title?offset=0&limit=${rowsPerPage}`
  );
};

//get doctor clinic
export const getDoctorClinic = (rowsPerPage: number) => {
  return axiosInstance.get(
    `${doctorModURL}/clinic?offset=0&limit=${rowsPerPage}`
  );
};

//get doctor data api
export const getDoctor = (data: getApiProps) => {
  const { rowsPerPage, page, search } = data;
  return axiosInstance.get(
    `${doctorModURL}?offset=${rowsPerPage * page}&limit=${rowsPerPage}&search=${
      search ?? ""
    }${data?.filterData ? filterApiData(data?.filterData) : ""}`
  );
};

//handle search
export const searchDoctor = ({ rowsPerPage, search }: searchProps) => {
  return `${doctorModURL}?offset=0&limit=${rowsPerPage}&search=${search ?? ""}`;
};

//create doctor
export const createDoctor = (body: string | FormData) =>
  axiosInstance.post(`${doctorModURL}`, body);

//update doctor
export const updateDoctor = (body: string | FormData, id: number) =>
  axiosInstance.patch(`${doctorModURL}/${id}`, body);
