import {
  getSaleClinicReportCustomerRequest,
  getSaleClinicReportTestRequest,
  getSaleClinicReportUserRequest,
  getSaleClinicReportAllSummaryRequest,
  getSaleClinicReportAllDetailRequest,
  getSaleClinicReportQuickSummaryRequest,
  getSaleClinicReportQuickDetailRequest,
  //reducer get data
  getClinicSaleReportTestSuccess,
  getClinicSaleReportTestFail,
  getSaleClinicReportCustomerSuccess,
  getSaleClinicReportCustomerFail,
  getSaleClinicReportUserSuccess,
  getSaleClinicReportUserFail,
  getSaleClinicReportQuickDetailSuccess,
  getSaleClinicReportQuickDetailFail,
  getSaleClinicReportAllDetailSuccess,
  getSaleClinicReportAllDetailFail,
  getSaleClinicReportQuickSummarySuccess,
  getSaleClinicReportQuickSummaryFail,
  getSaleClinicReportAllSummarySuccess,
  getSaleClinicReportAllSummaryFail,
  getSaleClinicReportDetailSuccess,
  getSaleClinicReportDetailFail,
  loadingSaleClinicReportDetail,
  getSaleClinicReportPreviousRequest,
  getSaleClinicReportNextRequest,
  getSaleClinicReportDetailRequest,
} from "./clinicSaleReportSlice";
import { mergeMap } from "rxjs";
import * as API from "./api";
import { map, filter } from "rxjs/operators";
import { Observable } from "rxjs";
import { Action } from "@reduxjs/toolkit";
import { combineEpics } from "redux-observable";
import { getNext, getPrevious } from "../../../../CommonAppRedux/api";
import {
  dispatchAction,
  stateAction,
} from "../../../../../AppUtils/Utils/globalTypes";
import { alertErrorAction } from "../../../../CommonAppRedux/CommonAppSlice";
import messages from "../../../../../AppUtils/Utils/validationConstants";
export const controller = new AbortController();
const getSaleClinicReportCustomerEpic = (
  action$: Observable<Action>,
  _: stateAction,
  { dispatch }: dispatchAction
) =>
  action$.pipe(
    filter(getSaleClinicReportCustomerRequest.match),
    mergeMap(async (action) => {
      try {
        const response = await API.getSaleClinicReportCustomer(action.payload);
        return { payload: response.data };
      } catch (e) {
        return { error: e };
      }
    }),
    map((action) =>
      action?.payload
        ? getSaleClinicReportCustomerSuccess(action?.payload)
        : getSaleClinicReportCustomerFail()
    )
  );
//user
const getSaleClinicReportUserEpic = (
  action$: Observable<Action>,
  _: stateAction,
  { dispatch }: dispatchAction
) =>
  action$.pipe(
    filter(getSaleClinicReportUserRequest.match),
    mergeMap(async (action) => {
      try {
        const response = await API.getSaleClinicReportUser();
        return { payload: response.data };
      } catch (e) {
        return { error: e };
      }
    }),
    map((action) =>
      action?.payload
        ? getSaleClinicReportUserSuccess(action?.payload)
        : getSaleClinicReportUserFail()
    )
  );
//item
const getClinicSaleReportTestEpic = (
  action$: Observable<Action>,
  _: stateAction,
  { dispatch }: dispatchAction
) =>
  action$.pipe(
    filter(getSaleClinicReportTestRequest.match),
    mergeMap(async (action) => {
      try {
        const response = await API.getClinicSaleReportTest();
        return { payload: response.data };
      } catch (e) {
        return { error: e };
      }
    }),
    map((action) =>
      action?.payload
        ? getClinicSaleReportTestSuccess(action?.payload)
        : getClinicSaleReportTestFail()
    )
  );

//get all Sale summary detail
const getSaleClinicReportAllSummaryEpic = (
  action$: Observable<Action>,
  _: stateAction,
  { dispatch }: dispatchAction
) =>
  action$.pipe(
    filter(getSaleClinicReportAllSummaryRequest.match),
    mergeMap(async (action) => {
      try {
        const response = await API.getSaleClinicReportAllReport(
          action?.payload
        );
        response?.data?.count === 0 &&
          dispatch(alertErrorAction(messages?.reportData));
        return { payload: response.data };
      } catch (e) {
        return { error: e };
      }
    }),
    map((action) =>
      action?.payload
        ? getSaleClinicReportAllSummarySuccess(action?.payload)
        : getSaleClinicReportAllSummaryFail()
    )
  );
//quick summary
const getSaleClinicReportQuickSummaryEpic = (
  action$: Observable<Action>,
  _: stateAction,
  { dispatch }: dispatchAction
) =>
  action$.pipe(
    filter(getSaleClinicReportQuickSummaryRequest.match),
    mergeMap(async (action) => {
      try {
        const response = await API.getSaleClinicReportQuickReport(
          action.payload
        );
        response?.data?.count === 0 &&
          dispatch(alertErrorAction(messages?.reportData));
        return { payload: response.data };
      } catch (e) {
        return { error: e };
      }
    }),
    map((action) =>
      action?.payload
        ? getSaleClinicReportQuickSummarySuccess(action?.payload)
        : getSaleClinicReportQuickSummaryFail()
    )
  );

//get all Sale summary detail
const getSaleClinicReportAllDetailEpic = (
  action$: Observable<Action>,
  _: stateAction,
  { dispatch }: dispatchAction
) =>
  action$.pipe(
    filter(getSaleClinicReportAllDetailRequest.match),
    mergeMap(async (action) => {
      try {
        const response = await API.getSaleClinicReportAllReport(
          action?.payload
        );
        response?.data?.count === 0 &&
          dispatch(alertErrorAction(messages?.reportData));
        return { payload: response.data };
      } catch (e) {
        return { error: e };
      }
    }),
    map((action) =>
      action?.payload
        ? getSaleClinicReportAllDetailSuccess(action?.payload)
        : getSaleClinicReportAllDetailFail()
    )
  );
//quick Detail
const getSaleClinicReportQuickDetailEpic = (
  action$: Observable<Action>,
  _: stateAction,
  { dispatch }: dispatchAction
) =>
  action$.pipe(
    filter(getSaleClinicReportQuickDetailRequest.match),
    mergeMap(async (action) => {
      try {
        const response = await API.getSaleClinicReportQuickReport(
          action.payload
        );
        response?.data?.count === 0 &&
          dispatch(alertErrorAction(messages?.reportData));
        return { payload: response.data };
      } catch (e) {
        return { error: e };
      }
    }),
    map((action) =>
      action?.payload
        ? getSaleClinicReportQuickDetailSuccess(action?.payload)
        : getSaleClinicReportQuickDetailFail()
    )
  );

//next and previous
//get next
const getSaleClinicReportNext = (
  action$: Observable<Action>,
  _: stateAction,
  { dispatch }: dispatchAction
) =>
  action$.pipe(
    filter(getSaleClinicReportNextRequest.match),
    mergeMap(async (action) => {
      dispatch(loadingSaleClinicReportDetail());
      try {
        const response = await getNext(action.payload);
        return { payload: response.data };
      } catch (e) {
        return { error: e };
      }
    }),
    map((action) =>
      action?.payload
        ? getSaleClinicReportDetailSuccess(action?.payload)
        : getSaleClinicReportDetailFail()
    )
  );
//get previous
const getSaleClinicReportPrevious = (
  action$: Observable<Action>,
  _: stateAction,
  { dispatch }: dispatchAction
) =>
  action$.pipe(
    filter(getSaleClinicReportPreviousRequest.match),
    mergeMap(async (action) => {
      dispatch(loadingSaleClinicReportDetail());
      try {
        const response = await getPrevious(action.payload);
        return { payload: response.data };
      } catch (e) {
        return { error: e };
      }
    }),
    map((action) =>
      action?.payload
        ? getSaleClinicReportDetailSuccess(action?.payload)
        : getSaleClinicReportDetailFail()
    )
  );
//het Sale report details
const getSaleClinicReportEpic = (
  action$: Observable<Action>,
  _: stateAction,
  { dispatch }: dispatchAction
) =>
  action$.pipe(
    filter(getSaleClinicReportDetailRequest.match),
    mergeMap(async (action) => {
      try {
        const response = await API.getSaleClinicReportQuickReport(
          action.payload
        );
        return { payload: response.data };
      } catch (e) {
        return { error: e };
      }
    }),
    map((action) =>
      action?.payload
        ? getSaleClinicReportDetailSuccess(action?.payload)
        : getSaleClinicReportDetailFail()
    )
  );
export const saleClinicReportEpics = combineEpics(
  getSaleClinicReportCustomerEpic,
  getClinicSaleReportTestEpic,
  getSaleClinicReportUserEpic,
  getSaleClinicReportAllSummaryEpic,
  getSaleClinicReportQuickSummaryEpic,
  getSaleClinicReportQuickDetailEpic,
  getSaleClinicReportAllDetailEpic,
  getSaleClinicReportPrevious,
  getSaleClinicReportNext
  // getSaleClinicReportEpic
);
