import { Avatar, Grid } from "@mui/material";
import { cyan } from "@mui/material/colors";
import React from "react";

const AppAvatar = ({ value }: any) => {
  return (
    <>
      <Grid container spacing={1}>
        <Grid item>
          <Avatar
            sx={{
              bgcolor: cyan[400],
              width: "25px",
              height: "25px",
              fontSize: "0.85rem",
            }}
          >
            {value?.charAt(0)?.toUpperCase()}
          </Avatar>
        </Grid>
        <Grid item mt={0.5}>
          {value}
        </Grid>
      </Grid>
    </>
  );
};

export default AppAvatar;
