import { enumTypes } from "./types";

export const genders: enumTypes[] = [
  { id: "MALE", name: "Male" },
  { id: "FEMALE", name: "Female" },
  { id: "OTHER", name: "Others" },
  { id: "N/A", name: "N/A" },
];

export const opdGenders: enumTypes[] = [
  { id: "MALE", name: "Male" },
  { id: "FEMALE", name: "Female" },
  { id: "OTHER", name: "Others" },
  // { id: "N/A", name: "N/A" },
];
export const appointmentGenders: enumTypes[] = [
  { id: "MALE", name: "Male" },
  { id: "FEMALE", name: "Female" },
];
export const rangeSelectors: enumTypes[] = [
  { id: "LESS-THAN", name: "<" },
  { id: "GREATER-THAN", name: ">" },
  { id: "EQUALS", name: "=" },
  { id: "RANGE", name: "Range" },
  { id: "FREE-TEXT", name: "Free-text" },
  { id: "HEADER", name: "Header" },
  { id: "DESCRIPTIVE", name: "Descriptive" },
];
export const ageTypes: enumTypes[] = [
  { id: 1, name: "Year" },
  { id: 2, name: "Months" },
  { id: 3, name: "Days" },
];
export const payTypes: enumTypes[] = [
  { id: "CASH", name: "Cash" },
  { id: "CREDIT", name: "Credit" },
];
export const maritalStatusList: enumTypes[] = [
  { id: "SINGLE", name: "Single" },
  { id: "MARRIED", name: "Married" },
  { id: "DIVORCED", name: "Divorced" },
  { id: "WIDOWED", name: "Widowed" },
  { id: "NA", name: "Not Available" },
];
//alignment
export const alignments: enumTypes[] = [
  { id: 1, name: "LEFT" },
  { id: 2, name: "RIGHT" },
  { id: 3, name: "CENTER" },
];
// display direction
export const directions: enumTypes[] = [
  { id: "LTR", name: "LTR" },
  { id: "RTL", name: "RTL" },
];
// select amount status list
export const amountStatusList: enumTypes[] = [
  {
    id: 1,
    name: "Paid",
  },
  {
    id: 2,
    name: "Unpaid",
  },
];

export const saleTypes: enumTypes[] = [
  {
    id: "SALE",
    name: "Sale",
  },
  {
    id: "SALE-RETURN",
    name: "Sale Return",
  },
];

export const currencyType: enumTypes[] = [
  {
    id: 1,
    name: "N",
  },
  {
    id: 2,
    name: "I",
  },
];

export const taxTypes: enumTypes[] = [
  {
    id: 1,
    name: "Exc.Tax",
  },
  { id: 2, name: "Inc.Tax" },
  { id: 3, name: "Tax-Exempt" },
];
export const purchaseTypes: enumTypes[] = [
  { id: "PURCHASE", name: "Purchase" },
  { id: "RETURN", name: "Return" },
  { id: "OPENING-STOCK", name: "Opening Stock" },
  { id: "STOCK-ADDITION", name: "Stock Addition" },
  { id: "STOCK-SUBTRACTION", name: "Stock Subtraction" },
];
export const systemTypes: enumTypes[] = [
  { id: 1, name: "Lab" },
  { id: 2, name: "Clinic" },
  { id: 3, name: "Pharmacy" },
  { id: 4, name: "Inventory" },
  { id: 5, name: "Account" },
  { id: 6, name: "Core Setup" },
];
//payment types
export const paymentTypes: enumTypes[] = [
  { id: "PAYMENT", name: "Payment" },
  { id: "RETURN", name: "Return" },
];
//defining the array structure of result entry
export const resultEntryTestDetailsPrototype: any[] = [
  {
    customerTest: {
      test: {
        testReferenceRangeTest: [{}],
      },
    },
  },
];
export const customerTestReportFooters: any = [
  {
    reportFooter: "",
  },
];
//doctor type
export const doctorTypes: enumTypes[] = [
  { id: "LAB", name: "LAB" },
  { id: "OPD", name: "OPD" },
];
//sensitive medicine
export const sensitiveType: enumTypes[] = [
  { id: "SENSITIVE", name: "Sensitive" },
  { id: "PARTIALLY-SENSITIVE", name: "Partially Sensitive" },
  { id: "RESISTIVE", name: "Resistive" },
];
//report format type
export const reportFormatTypes: enumTypes[] = [
  { id: "INTABLE", name: "In-Table" },
  { id: "BORDERLESS", name: "Borderless" },
];
//department type
export const departmentTypes = [
  { id: "GENERAL", name: "General" },
  { id: "MICROBIOLOGY", name: "Microbiology" },
  { id: "CLINICAL", name: "Clinical" },
  { id: "RADIOLOGY", name: "Radiology" },
  { id: "VACCINATION", name: "Vaccination" },
  { id: "PHYSICIAN", name: "Physician" },
  { id: "AUDIOLOGY", name: "Audiology" },
  { id: "PATHOLOGY", name: "Pathology" },

  // { id: "OPD-FOLLOW-UP", name: "OPD Followup" },
  // { id: "OPD-BOOK", name: "OPD Book" },
  // { id: "OPD-REG", name: "OPD Reg" },
];
// handle enum values label
export const handleEnumValuesLabel = (option: enumTypes) => option.name;

export const calendarSystems: enumTypes[] = [
  { id: "AD", name: "AD" },
  { id: "BS", name: " BS" },
];
export const dateFormats: enumTypes[] = [
  { id: "YYYY-MM-DD", name: "yyyy-mm-dd" },
  { id: "DD-MM-YYYY", name: "dd-mm-yyyy" },
];
export const days: enumTypes[] = [
  { id: "SUNDAY", name: "Sunday" },
  { id: "MONDAY", name: " Monday" },
  { id: "TUESDAY", name: "  Tuesday" },
  { id: "WEDNESDAY", name: "  Wednesday" },
  { id: "THURSDAY", name: "  Thursday" },
  { id: "FRIDAY", name: "  Friday" },
  { id: "SATURDAY", name: "  Saturday" },
];

export const packingTypes: enumTypes[] = [
  { id: "SINGLE", name: "Single" },
  { id: "MULTIPLE", name: "Multiple" },
];

export const itemTypes: enumTypes[] = [
  { id: "SALEANDINVENTORY", name: "Both (Sale & Inventory)" },
  { id: "INVENTORY", name: "Inventory" },
  { id: "SALE", name: "Sale" },
  { id: "ASSET", name: "Asset" },
  { id: "CONSUMABLE", name: "Consumable" },
];

export const clinicTypes: enumTypes[] = [
  { id: "HOSPITAL", name: "Hospital" },
  { id: "PATHOLOGY", name: "Pathology" },
  { id: "HEARING-CARE-CENTER", name: "Hearing-Care-Center" },
  { id: "DENTAL", name: "Dental" },
  { id: "EYE-CARE-CENTER", name: "Eye-Care-Center" },
];

export const appointmentStatus: enumTypes[] = [
  { id: "SCHEDULED", name: "Scheduled" },
  { id: "COMPLETED", name: "Completed" },
  { id: "CANCELLED", name: "Cancelled" },
  { id: "DISCHARGED", name: "Discharged" },
  { id: "NOSHOW", name: "No-Show" },
  { id: "RESCHEDULED", name: "Rescheduled" },
  { id: "LWBS", name: "LWBS (Left Without Being Seen)" },
];
//enum value for verification and print
export const checkSelectionType: enumTypes[] = [
  { id: 1, name: "Normal" },
  { id: 2, name: "Bill No" },
];
//recruitment agency status
export const recrutmentAgencyStatus = [
  { id: "ACTIVE", name: "ACTIVE" },
  { id: "INACTIVE", name: "INACTIVE" },
  { id: "SUSPENDED", name: "SUSPENDED" },
  { id: "BLOCKED", name: "BLOCKED" },
];

export const investigationChargeTypes = [
  { id: "OPD", name: "OPD" },
  { id: "FOLLOW-UP", name: "FOLLOW UP" },
  { id: "OPD-BOOK", name: "OPD Book" },
];

export const testCategoryTypes = [
  { id: "CLINICAL", name: "Clinical" },
  // { id: "OPD-FOLLOW-UP", name: "OPD Followup" },
  // { id: "OPD-BOOK", name: "OPD Book" },
  // { id: "OPD-REG", name: "OPD Reg" },
  { id: "HOSPITAL-REFERRAL", name: "HOSPITAL-REFERRAL" },
  { id: "PATHOLOGY", name: "Pathology" },
];
export const invoicePaperTypes = [
  { id: "A5", name: "A5" },
  { id: "A4", name: "A4" },
  { id: "CUSTOM", name: "CUSTOM" },
];
export const invSalePriceTypes = [
  { id: "BATCH-WISE", name: "BATCH-WISE" },
  { id: "MRP-WISE", name: "MRP-WISE" },
];
export const aidcTechnologyTypes = [
  { id: "QR-CODE", name: "QR-CODE" },
  { id: "BARCODE", name: "BARCODE" },
];
export const insClaimCodeGenerationMethodTypes = [
  { id: "AUTOMATIC", name: "AUTOMATIC" },
  { id: "MANUAL", name: "MANUAL" },
];

export const organizationSectors = [
  { id: "HEALTH-CARE", name: "HEALTH-CARE" },
  { id: "TRADING", name: "TRADING" },
  { id: "MANUFACTURING", name: "MANUFACTURING" },
  { id: "SERVICE", name: "SERVICE" },
];
export const showDepartmentType = [
  "CLINICAL",
  "OPD-FOLLOW-UP",
  "OPD-BOOK",
  "OPD-REG",
];
// sample
export const booleanOption = [
  { id: true, name: "YES" },
  { id: false, name: "NO" },
];

export const clinicOrderTypes = [
  { id: "LEFT", name: "LEFT" },
  { id: "RIGHT", name: "RIGHT" },
  { id: "BINAURAL", name: "BINAURAL" },
];

export const moldOrderEarTypes = [
  { id: "LEFT", name: "LEFT" },
  { id: "RIGHT", name: "RIGHT" },
  { id: "BINAURAL", name: "BINAURAL" },
];
export const moldOrderMaterialTypes = [
  { id: "SILICONE", name: "SILICONE" },
  { id: "ACRYLIC", name: "ACRYLIC" },
  { id: "SOFT-VINYL", name: "SOFT-VINYL" },
];
export const moldOrderMoldTypes = [
  { id: "IN-THE-EAR", name: "IN-THE-EAR" },
  { id: "BEHIND-THE-EAR", name: "BEHIND-THE-EAR" },
  { id: "COMPLETELY-IN-CANAL", name: "COMPLETELY-IN-CANAL" },
  { id: "RECEIVER-IN-CANAL", name: "RECEIVER-IN-CANAL" },
];

export const orderTypes: enumTypes[] = [
  { id: "MOLD", name: "Mold" },
  { id: "REPAIR", name: "Repair" },
  { id: "CLINIC", name: "Clinic" },
];

export const contactMediums: enumTypes[] = [
  { id: "TEXT", name: "Text" },
  { id: "WHATSAPP", name: "WhatsApp" },
  { id: "VIBER", name: "Viber" },
  { id: "EMAIL", name: "Email" },
  { id: "PHONE", name: "Phone" },
];

// Customer Contact Status
export const contactRequestStatus: enumTypes[] = [
  { id: "PENDING", name: "Pending" },
  { id: "CONTACTED", name: "Contacted" },
  { id: "MESSAGE-LEFT", name: "Message Left" },
  { id: "BUSY", name: "Busy" },
];