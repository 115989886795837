import { createAction, createSlice } from "@reduxjs/toolkit";

const initialState: any = {
  customerTypes: [],
  edit: false,
  customerType: null,
  loadingCustomerType: false,
  loadingCreateCustomerType: false,
  defaultCustomerType: [],
  loadingDefaultCustomerType: false,
  count: 0,
  next: "",
  previous: "",
};

export const updateCustomerTypeRequest = createAction(
  "updateCustomerTypeRequest",
  function prepare({ values, id, rowsPerPage, page }) {
    return {
      payload: {
        values,
        id,
        rowsPerPage,
        page,
      },
    };
  }
);

export const getCustomerTypeRequest = createAction(
  "getCustomerTypeRequest",
  function prepare({ rowsPerPage, page, filterData }) {
    return {
      payload: {
        rowsPerPage,
        page,
        filterData,
      },
    };
  }
);
// get next request
export const getCustomerTypeNextRequest = createAction(
  "getCustomerTypeNextRequest",
  function prepare(next) {
    return {
      payload: next,
    };
  }
);
//get previous request
export const getCustomerTypePreviousRequest = createAction(
  "getCustomerTypePreviousRequest",
  function prepare(previous) {
    return {
      payload: previous,
    };
  }
);
export const CustomerType = createSlice({
  name: "CustomerTypeReducer",
  initialState,
  reducers: {
    loadingCustomerType: (state) => {
      state.loadingCustomerType = true;
    },
    getCustomerTypeSuccess: (
      state,
      { payload: { results, count, next, previous } }
    ) => {
      state.loadingCustomerType = false;
      state.customerTypes = results;
      state.count = count;
      state.next = next;
      state.previous = previous;
    },
    getCustomerTypeFail: (state) => {
      state.loadingCustomerType = false;
    },
    getDefaultCustomerTypeRequest: (state) => {
      state.loadingDefaultCustomerType = true;
    },
    getDefaultCustomerTypeSuccess: (state, { payload: { results } }) => {
      state.loadingDefaultCustomerType = false;
      state.defaultCustomerType = results;
    },
    getDefaultCustomerTypeFail: (state) => {
      state.loadingDefaultCustomerType = false;
    },
    createCustomerTypeRequest: (state, payload) => {
      state.loadingCreateCustomerType = true;
      state.closeModal = true;
    },
    createCustomerTypeSuccess: (state) => {
      state.loadingCreateCustomerType = false;
    },
    createCustomerTypeFail: (state) => {
      state.loadingCreateCustomerType = false;
    },
    getCustomerTypeByIdRequest: (state, payload) => {
      state.edit = true;
    },
    customerTypeEditSuccess: (state, { payload }) => {
      state.customerType = payload;
    },
    customerTypeEditFail: (state) => {
      state.edit = false;
    },
    updateCustomerTypeSuccess: (state) => {
      state.loadingCreateCustomerType = false;
    },
    updateCustomerTypeFail: (state) => {
      state.loadingCreateCustomerType = false;
    },

    clearCustomerTypeData: (state) => {
      state.edit = false;
      state.customerType = null;
    },
  },
});
// Action creators are generated for each case reducer function
export const {
  loadingCustomerType,
  getCustomerTypeSuccess,
  getCustomerTypeFail,
  createCustomerTypeRequest,
  createCustomerTypeSuccess,
  createCustomerTypeFail,
  customerTypeEditSuccess,
  updateCustomerTypeSuccess,
  updateCustomerTypeFail,
  clearCustomerTypeData,
  getCustomerTypeByIdRequest,
  customerTypeEditFail,
  getDefaultCustomerTypeRequest,
  getDefaultCustomerTypeSuccess,
  getDefaultCustomerTypeFail,
} = CustomerType.actions;
export default CustomerType.reducer;
