import { Action } from "@reduxjs/toolkit";
import { Observable } from "rxjs";
import {
  dispatchAction,
  stateAction,
} from "../../../../../../AppUtils/Utils/globalTypes";
import {
  clearAllCustomerNote,
  createCustomerNoteFail,
  createCustomerNoteRequest,
  createCustomerNoteSuccess,
  editCustomerNoteFail,
  editCustomerNoteSuccess,
  getCustomerNoteFail,
  getCustomerNoteRequest,
  getCustomerNoteSuccess,
  getCustomerNoteTemplateFail,
  getCustomerNoteTemplateRequest,
  getCustomerNoteTemplateSuccess,
  getEditCustomerNoteByIdRequest,
  getfilterCustomerNoteTemplateFail,
  getfilterCustomerNoteTemplateRequest,
  getfilterCustomerNoteTemplateSuccess,
  getSelectedCustomerNoteFail,
  getSelectedCustomerNoteRequest,
  getSelectedCustomerNoteSuccess,
  updateCustomerNoteFail,
  updateCustomerNoteRequest,
  updateCustomerNoteSuccess,
} from "./noteSlice";
import { filter, map, mergeMap } from "rxjs/operators";
import {
  createCustomerNote,
  filterCustomerNoteTemplates,
  getCustomerNoteById,
  getCustomerNoteTemplates,
  getEditedCustomerNote,
  selectedCustomerNote,
  updateCustomerNote,
} from "./api";
import { combineEpics } from "redux-observable";
import {
  alertErrorAction,
  alertSuccessAction,
  closeModal,
} from "../../../../../CommonAppRedux/CommonAppSlice";
import messages from "../../../../../../AppUtils/Utils/validationConstants";

const getCustomerNoteEpic = (
  action$: Observable<Action>,
  _: stateAction,
  { dispatch }: dispatchAction
) =>
  action$.pipe(
    filter(getCustomerNoteRequest.match),
    mergeMap(async (action) => {
      try {
        const response = await getCustomerNoteById(action?.payload);
        return { payload: response.data };
      } catch (e) {
        return { error: e };
      }
    }),
    map((action) => {
      return action?.payload
        ? getCustomerNoteSuccess(action?.payload)
        : getCustomerNoteFail();
    })
  );
const createCustomerNoteEpic = (
  action$: Observable<Action>,
  _: stateAction,
  { dispatch }: dispatchAction
) =>
  action$.pipe(
    filter(createCustomerNoteRequest.match),
    mergeMap(
      async ({
        payload: { values, rowsPerPage, page, customerId, setShowModal },
      }) => {
        const id = localStorage.getItem("customerId");
        const {
          title,
          notes,
          noteAttachments,
          isImportant,
          isDraft,
          remarks,
          customer,
        } = values;
        const body = new FormData();
        body.append("title", title);
        body.append("notes", notes);
        body.append("isImportant", isImportant);
        body.append("isDraft", isDraft);
        body.append("customer", customer);
        noteAttachments.forEach((attachment: any, index: number) => {
          body.append(`noteAttachments[${index}][file]`, attachment?.file);
        });
        try {
          const response = await createCustomerNote(body, customerId);
          dispatch(getCustomerNoteRequest(id));
          dispatch(alertSuccessAction(messages.createMessage));
          dispatch(closeModal());
          dispatch(clearAllCustomerNote());
          setShowModal(false);
          return { payload: { response } };
        } catch (e) {
          dispatch(alertErrorAction(messages.createFailMessage));
          return { error: e };
        }
      }
    ),
    map((action) => {
      return action?.payload
        ? createCustomerNoteSuccess()
        : createCustomerNoteFail();
    })
  );

const updateCustomerNoteEpic = (
  action$: Observable<Action>,
  _: stateAction,
  { dispatch }: dispatchAction
) =>
  action$.pipe(
    filter(updateCustomerNoteRequest.match),
    mergeMap(async ({ payload: { values, customerId, setShowModal } }) => {
      const {
        title,
        notes,
        noteAttachments,
        isImportant,
        isDraft,
        remarks,
        customer,
      } = values;
      const body = new FormData();
      body.append("title", title);
      body.append("notes", notes);
      body.append("isImportant", isImportant);
      body.append("isDraft", isDraft);
      body.append("customer", customer);
      body.append("remarks", remarks);
      noteAttachments.forEach((attachment: any, index: number) => {
        body.append(`noteAttachments[${index}][file]`, attachment?.file);
      });
      try {
        // const body = JSON.stringify(values);
        const response = await updateCustomerNote(body, customerId);
        const id = localStorage.getItem("patientId");
        if (response) {
          dispatch(getCustomerNoteRequest(id));
          dispatch(alertSuccessAction(messages?.updateMessage));
          dispatch(closeModal());
          dispatch(clearAllCustomerNote());
          setShowModal(false);
        }
        return { payload: { response } };
      } catch (e) {
        dispatch(alertErrorAction(messages.updateFailMessage));
        return { error: e };
      }
    }),
    map((action) => {
      return action?.payload
        ? updateCustomerNoteSuccess()
        : updateCustomerNoteFail();
    })
  );

const getEditCustomerNoteByIdEpic = (
  action$: Observable<Action>,
  _: stateAction,
  { dispatch }: dispatchAction
) =>
  action$.pipe(
    filter(getEditCustomerNoteByIdRequest.match),
    mergeMap(async (action) => {
      try {
        const response = await getEditedCustomerNote(action?.payload);
        return { payload: response.data };
      } catch (e) {
        return { error: e };
      }
    }),
    map((action) => {
      return action?.payload
        ? editCustomerNoteSuccess(action?.payload)
        : editCustomerNoteFail();
    })
  );

const getCustomerNoteTemplateEpic = (
  action$: Observable<Action>,
  _: stateAction,
  { dispatch }: dispatchAction
) =>
  action$.pipe(
    filter(getCustomerNoteTemplateRequest.match),
    mergeMap(async (action) => {
      try {
        const response = await getCustomerNoteTemplates();
        return { payload: response.data };
      } catch (e) {
        return { error: e };
      }
    }),
    map((action) => {
      return action?.payload
        ? getCustomerNoteTemplateSuccess(action?.payload)
        : getCustomerNoteTemplateFail();
    })
  );
const getFilterCustomerNoteTemplateEpic = (
  action$: Observable<Action>,
  _: stateAction,
  { dispatch }: dispatchAction
) =>
  action$.pipe(
    filter(getfilterCustomerNoteTemplateRequest.match),
    mergeMap(async (action) => {
      try {
        const response = await filterCustomerNoteTemplates(action?.payload);
        return { payload: response.data };
      } catch (e) {
        return { error: e };
      }
    }),
    map((action) => {
      return action?.payload
        ? getfilterCustomerNoteTemplateSuccess(action?.payload)
        : getfilterCustomerNoteTemplateFail();
    })
  );

const getSelectedCustomerNoteEpic = (
  action$: Observable<Action>,
  _: stateAction,
  { dispatch }: dispatchAction
) =>
  action$.pipe(
    filter(getSelectedCustomerNoteRequest.match),
    mergeMap(async (action) => {
      try {
        const response = await selectedCustomerNote(action?.payload);
        return { payload: response.data };
      } catch (e) {
        return { error: e };
      }
    }),
    map((action) => {
      return action?.payload
        ? getSelectedCustomerNoteSuccess(action?.payload)
        : getSelectedCustomerNoteFail();
    })
  );
export const customerNoteEpic = combineEpics(
  getCustomerNoteEpic,
  createCustomerNoteEpic,
  updateCustomerNoteEpic,
  getEditCustomerNoteByIdEpic,
  getCustomerNoteTemplateEpic,
  getFilterCustomerNoteTemplateEpic,
  getSelectedCustomerNoteEpic
);
