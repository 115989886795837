import {
  createAppointmentRoutineFail,
  createAppointmentRoutineRequest,
  createAppointmentRoutineSuccess,
  getAppointmentRoutineFail,
  getAppointmentRoutineSuccess,
  updateAppointmentRoutineFail,
  updateAppointmentRoutineSuccess,
  getAppointmentRoutineRequest,
  getAppointmentRoutineNextRequest,
  getAppointmentRoutinePreviousRequest,
  updateAppointmentRoutineRequest,
  clearAppointmentRoutineData,
  getAppointmentRoutineByIdRequest,
  appointmentRoutineEditSuccess,
  appointmentRoutineEditFail,
  //
  getAppointmentSiteRequest,
  getAppointmentSiteSuccess,
  getAppointmentSiteFail,
  //
  getAppointmentClinicRequest,
  getAppointmentClinicSuccess,
  getAppointmentClinicFail,
  //
  getAppointmentDoctorRequest,
  getAppointmentDoctorSuccess,
  getAppointmentDoctorFail,
  getClinicFromSiteRequest,
  getClinicFromSiteSuccess,
  getClinicFromSiteFail,
  getDoctorFromClinicRequest,
  getDoctorFromClinicSuccess,
  getDoctorFromClinicFail,
  getAppointmentRoutineOnClickRequest,
  getAppointmentRoutineOnClickSuccess,
  getAppointmentRoutineOnClickFail,
} from "./appointmentRoutineSlice";
import { mergeMap } from "rxjs";
import {
  createAppointmentRoutine,
  getAppointmentRoutine,
  updateAppointmentRoutine,
  getAppointmentRoutineById,
  getAppointmentRoutineSite,
  getAppointmentRoutineClinic,
  getAppointmentRoutineDoctor,
  getClinicFromSite,
  getDoctorFromClinic,
  getAppointmentRoutineOnGenerate,
} from "./api";
import { map, filter } from "rxjs/operators";
import { Observable } from "rxjs";
import { Action } from "@reduxjs/toolkit";
import { combineEpics } from "redux-observable";
import {
  alertErrorAction,
  alertSuccessAction,
  closeModal,
} from "../../../CommonAppRedux/CommonAppSlice";
import { getNext, getPrevious } from "../../../CommonAppRedux/api";
import {
  dispatchAction,
  stateAction,
} from "../../../../AppUtils/Utils/globalTypes";
import messages from "../../../../AppUtils/Utils/validationConstants";
export const controller = new AbortController();

//get AppointmentRoutine epic
const getAppointmentRoutineEpic = (
  action$: Observable<Action>,
  _: stateAction,
  { dispatch }: dispatchAction
) =>
  action$.pipe(
    filter(getAppointmentRoutineRequest.match),
    mergeMap(async (action) => {
      try {
        const response = await getAppointmentRoutine(action.payload);
        return { payload: response.data };
      } catch (e) {
        return { error: e };
      }
    }),
    map((action) =>
      action?.payload
        ? getAppointmentRoutineSuccess(action?.payload)
        : getAppointmentRoutineFail()
    )
  );

//get appointmentRoutine by id
const getAppointmentRoutineByIdEpic = (
  action$: Observable<Action>,
  _: stateAction,
  { dispatch }: dispatchAction
) =>
  action$.pipe(
    filter(getAppointmentRoutineByIdRequest.match),
    mergeMap(async (action) => {
      try {
        const response = await getAppointmentRoutineById(action.payload);
        return { payload: response.data };
      } catch (e) {
        return { error: e };
      }
    }),
    map((action) =>
      action?.payload
        ? appointmentRoutineEditSuccess(action.payload)
        : appointmentRoutineEditFail()
    )
  );

//get appointmentRoutine on click generate
const getAppointmentRoutineOnClickEpic = (
  action$: Observable<Action>,
  _: stateAction,
  { dispatch }: dispatchAction
) =>
  action$.pipe(
    filter(getAppointmentRoutineOnClickRequest.match),
    mergeMap(async (action) => {
      try {
        const response = await getAppointmentRoutineOnGenerate(action.payload);
        return { payload: response.data };
      } catch (e) {
        return { error: e };
      }
    }),
    map((action) =>
      action?.payload
        ? getAppointmentRoutineOnClickSuccess(action.payload)
        : getAppointmentRoutineOnClickFail()
    )
  );

//get appointment site
const getAppointmentSiteEpic = (
  action$: Observable<Action>,
  _: stateAction,
  { dispatch }: dispatchAction
) =>
  action$.pipe(
    filter(getAppointmentSiteRequest.match),
    mergeMap(async (action: any) => {
      try {
        const response = await getAppointmentRoutineSite(action.payload);
        return { payload: response.data };
      } catch (e) {
        return { error: e };
      }
    }),
    map((action) =>
      action?.payload
        ? getAppointmentSiteSuccess(action?.payload)
        : getAppointmentSiteFail()
    )
  );

//get appointment clinic
const getAppointmentClinicEpic = (
  action$: Observable<Action>,
  _: stateAction,
  { dispatch }: dispatchAction
) =>
  action$.pipe(
    filter(getAppointmentClinicRequest.match),
    mergeMap(async (action: any) => {
      try {
        const response = await getAppointmentRoutineClinic(action.payload);
        return { payload: response.data };
      } catch (e) {
        return { error: e };
      }
    }),
    map((action) =>
      action?.payload
        ? getAppointmentClinicSuccess(action?.payload)
        : getAppointmentClinicFail()
    )
  );

//get appointment doctor
const getAppointmentDoctorEpic = (
  action$: Observable<Action>,
  _: stateAction,
  { dispatch }: dispatchAction
) =>
  action$.pipe(
    filter(getAppointmentDoctorRequest.match),
    mergeMap(async (action: any) => {
      try {
        const response = await getAppointmentRoutineDoctor(action.payload);
        return { payload: response.data };
      } catch (e) {
        return { error: e };
      }
    }),
    map((action) =>
      action?.payload
        ? getAppointmentDoctorSuccess(action?.payload)
        : getAppointmentDoctorFail()
    )
  );

//get clinic from site
const getAllClinicFromSite = (
  action$: Observable<Action>,
  _: stateAction,
  { dispatch }: dispatchAction
) =>
  action$.pipe(
    filter(getClinicFromSiteRequest.match),
    mergeMap(async (action) => {
      try {
        const response = await getClinicFromSite(action.payload);
        return { payload: response.data };
      } catch (e) {
        return { error: e };
      }
    }),
    map((action) =>
      action?.payload
        ? getClinicFromSiteSuccess(action?.payload)
        : getClinicFromSiteFail()
    )
  );
// get doctor from clinic
const getAllDoctorFromClinic = (
  action$: Observable<Action>,
  _: stateAction,
  { dispatch }: dispatchAction
) =>
  action$.pipe(
    filter(getDoctorFromClinicRequest.match),
    mergeMap(async (action) => {
      try {
        const response = await getDoctorFromClinic(action.payload);
        return { payload: response.data };
      } catch (e) {
        return { error: e };
      }
    }),
    map((action) =>
      action?.payload
        ? getDoctorFromClinicSuccess(action?.payload)
        : getDoctorFromClinicFail()
    )
  );
//get next
const getAppointmentRoutineNext = (
  action$: Observable<Action>,
  _: stateAction,
  { dispatch }: dispatchAction
) =>
  action$.pipe(
    filter(getAppointmentRoutineNextRequest.match),
    mergeMap(async (action) => {
      try {
        const response = await getNext(action.payload);
        return { payload: response.data };
      } catch (e) {
        return { error: e };
      }
    }),
    map((action) =>
      action?.payload
        ? getAppointmentRoutineSuccess(action?.payload)
        : getAppointmentRoutineFail()
    )
  );
//get previous
const getAppointmentRoutinePrevious = (
  action$: Observable<Action>,
  _: stateAction,
  { dispatch }: dispatchAction
) =>
  action$.pipe(
    filter(getAppointmentRoutinePreviousRequest.match),
    mergeMap(async (action) => {
      try {
        const response = await getPrevious(action.payload);
        return { payload: response.data };
      } catch (e) {
        return { error: e };
      }
    }),
    map((action) =>
      action?.payload
        ? getAppointmentRoutineSuccess(action?.payload)
        : getAppointmentRoutineFail()
    )
  );
//create AppointmentRoutine epic
const createAppointmentRoutineEpic = (
  action$: Observable<Action>,
  _: stateAction,
  { dispatch }: dispatchAction
) =>
  action$.pipe(
    filter(createAppointmentRoutineRequest.match),
    mergeMap(
      async ({
        payload: { values, rowsPerPage, page, setShowModalFromAnotherModule },
      }) => {
        const { site, doctor, clinic, startDate, endDate, routines, active } =
          values;
        try {
          const body = new FormData();
          body.append("site", site);
          body.append("doctor", doctor);
          body.append("clinic", clinic);
          body.append("startDate", startDate);
          body.append("endDate", endDate);
          body.append("active", active);
          for (let x in routines) {
            let date = routines[x].date;
            let formatDate = new Date(date);
            let formattedDate = formatDate.toISOString().substring(0,10);
            if(routines[x].id !== undefined) {
              body.append(`routines[${x}][id]`,routines[x].id);
            }
            body.append(`routines[${x}][date]`, formattedDate);
            body.append(`routines[${x}][startTime]`, routines[x].startTime);
            body.append(`routines[${x}][endTime]`, routines[x].endTime);
          }
          const response = await createAppointmentRoutine(body);
          if (response) {
            dispatch(getAppointmentRoutineRequest({ rowsPerPage, page }));
            dispatch(alertSuccessAction(messages.createMessage));
            setShowModalFromAnotherModule
              ? setShowModalFromAnotherModule(false)
              : dispatch(closeModal());
          }
          return { payload: { response } };
        } catch (e) {
          dispatch(alertErrorAction(messages.createFailMessage));
          return { error: e };
        }
      }
    ),
    map((action) => {
      return action?.payload
        ? createAppointmentRoutineSuccess()
        : createAppointmentRoutineFail();
    })
  );
//update AppointmentRoutine epic
const updateAppointmentRoutineEpic = (
  action$: Observable<Action>,
  _: stateAction,
  { dispatch }: dispatchAction
) =>
  action$.pipe(
    filter(updateAppointmentRoutineRequest.match),
    mergeMap(async ({ payload: { values, id, rowsPerPage, page } }) => {
      try {
        const body = new FormData();
        for (let [key, value] of Object.entries(values)) {
          // @ts-ignore
          body.append(`${key}`, value);
        }
        const response = await updateAppointmentRoutine(body, id);
        if (response) {
          dispatch(getAppointmentRoutineRequest({ rowsPerPage, page }));
          dispatch(alertSuccessAction(messages.updateMessage));
          dispatch(closeModal());
          dispatch(clearAppointmentRoutineData());
        }
        return { payload: { response, rowsPerPage } };
      } catch (e) {
        dispatch(alertErrorAction(messages.updateFailMessage));
        return { error: e };
      }
    }),
    map((action) =>
      action?.payload
        ? updateAppointmentRoutineSuccess()
        : updateAppointmentRoutineFail()
    )
  );

export const appointmentRoutineEpics = combineEpics(
  getAppointmentRoutineEpic,
  createAppointmentRoutineEpic,
  updateAppointmentRoutineEpic,
  getAppointmentRoutineNext,
  getAppointmentRoutinePrevious,
  getAppointmentRoutineByIdEpic,
  getAppointmentSiteEpic,
  getAppointmentClinicEpic,
  getAppointmentDoctorEpic,
  getAllClinicFromSite,
  getAllDoctorFromClinic,
  getAppointmentRoutineOnClickEpic,
);
