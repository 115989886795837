import { filterApiData } from "../../../../AppUtils/Utils/appFunctions";
import { axiosInstance } from "../../../../AppUtils/Utils/axios";
import {
  getApiProps,
  searchProps,
} from "../../../../AppUtils/Utils/globalTypes";

const unitModURL = "api/v1/lab-app/test-unit";

//get Unit data 
export const getUnit = (data: getApiProps) => {

  const { rowsPerPage, page, search} = data;
  return axiosInstance.get(
    `${unitModURL}?offset=${rowsPerPage * page}&limit=${rowsPerPage}&search=${search ?? ""}${
      data?.filterData ? filterApiData(data?.filterData) : ""
    }`
  );
}

//get unit by id
export const getUnitById = (id: number) => {
  return axiosInstance.get(`${unitModURL}/${id}`)
}

//handle search
export const searchUnit = ({ rowsPerPage, search }: searchProps) => {
  return `${unitModURL}?offset=0&limit=${rowsPerPage}&search=${search ?? ""}`;
};

//create unit
export const createUnit = (body: string | FormData) =>
  axiosInstance.post(`${unitModURL}`, body);

//update unit
export const updateUnit = (body: string | FormData, id: number) =>
  axiosInstance.patch(`${unitModURL}/${id}`, body);
