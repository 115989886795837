import { memo, useEffect } from "react";
import { TableRow, TableCell } from "@mui/material";
import Status from "../../../../Component/Status/Status";
import { tableBodyProps } from "../types";
import { commonAppSelector } from "../../../CommonAppRedux/selector";
import { useAppDispatch, useAppSelector } from "../../../../AppUtils/Utils/appHooks";
import { tableStyles } from "../styles";
import { useDispatch } from "react-redux";
import { clearDetailPage } from "../../../CommonAppRedux/CommonAppSlice";

const CommonTableBody = ({ columns, data }: tableBodyProps) => {
  const { rowsPerPage, page } = useAppSelector(commonAppSelector);
  const classes = tableStyles();
  const dispatch=useAppDispatch();
  useEffect(() => {
    return(()=>{
      dispatch(clearDetailPage());
    })
  }, []);
  return (
    <>
      {data?.map((row: any, i: number) => {
        return (
          <TableRow hover role="checkbox" tabIndex={-1} key={(row?.id || i+1)}>
            <TableCell>{rowsPerPage * page + (i + 1)}</TableCell>
            {columns?.map((column) => {
              const { id, boolean } = column;
              let value = row[id];
              if (boolean) {
                value = <Status active={row[id]} />;
              }
              return (
                <TableCell
                  key={column.id}
                  sx={{ fontSize: 14 }}
                  className={classes.tableData}
                >
                  {value
                    ? value
                    : value === 0
                    ? value
                    : typeof value === "boolean"
                    ? value
                    : "-"}
                </TableCell>
              );
            })}
          </TableRow>
        );
      })}
    </>
  );
};

export default memo(CommonTableBody);
