import React, { Fragment, memo } from "react";
import { TableCell, TableRow } from "@mui/material";
import { commonAppSelector } from "../../../CommonAppRedux/selector";
import { useAppSelector } from "../../../../AppUtils/Utils/appHooks";
import { commonReportDetailProps } from "../types";
const CommonReportDetailBody = ({
  header,
  subHeader,
  data,
  getDetailsByKey,
  extraCell,
}: commonReportDetailProps) => {
  const { rowsPerPage, page } = useAppSelector(commonAppSelector);
  return (
    <>
      {data?.map((row: any, i: number) => {
        return (
          <Fragment key={row?.id}>
            <TableRow hover role="checkbox" tabIndex={-1}>
              <TableCell>{rowsPerPage * page + (i + 1)}</TableCell>
              {header?.map((head) => {
                let value;
                value = row[head.id] !== null ? row[head.id] : "-";
                return (
                  <TableCell
                    key={head.id}
                    style={{ padding: "16px 2px !important" }}
                  >
                    {value}
                  </TableCell>
                );
              })}
              {[...Array(extraCell?.current)]?.map((_: object, i: number) => {
                return (
                  <TableCell
                    style={{ minWidth: 5, fontSize: 17 }}
                    key={i}
                  ></TableCell>
                );
              })}
            </TableRow>
            <TableRow>
              <TableCell style={{ minWidth: 10 }}></TableCell>
              <TableCell style={{ minWidth: 10, fontWeight: 700 }}>
                S.N
              </TableCell>
              {subHeader?.map((head: any) => (
                <TableCell
                  key={head.id}
                  style={{ minWidth: head.minWidth, fontWeight: 700 }}
                >
                  {head.label}
                </TableCell>
              ))}
            </TableRow>
            {row?.[getDetailsByKey!] &&
              row?.[getDetailsByKey!]?.map((row: any, index: number) => {
                return (
                  <TableRow hover role="checkbox" tabIndex={-1} key={row?.id}>
                    <TableCell></TableCell>
                    <TableCell style={{ minWidth: 10 }}>{index + 1}</TableCell>
                    {subHeader?.map((head: any) => {
                      let value;
                      value = row[head.id] !== null ? row[head.id] : "-";
                      return <TableCell key={head.id}>{value}</TableCell>;
                    })}
                  </TableRow>
                );
              })}
          </Fragment>
        );
      })}
    </>
  );
};

export default memo(CommonReportDetailBody);
