import { createSlice } from "@reduxjs/toolkit";
import { userAddressProps } from "./types";

const initialState: userAddressProps = {
  addressById: [],
  loadingAddressById: false,
  count: 0,
  next: 0,
  previous: 0,
  editAddress: null,
  edit: false,
  patientName: "",
  provinces: [],
  loadingProvince: false,
  districts: [],
  loadingDistrict: false,
  loadingAddressSubmitButton: false,
};

export const addressSlice = createSlice({
  name: "AddressReducer",
  initialState,
  reducers: {
    getUserAddressRequest: (state, payload) => {
      state.loadingAddressById = true;
    },
    getUserAddressByIdSuccess: (
      state,
      { payload: { results, count, next, previous } }
    ) => {
      state.loadingAddressById = false;
      state.count = count;
      state.next = next;
      state.previous = previous;
      state.patientName = results[0]?.fullName;
      state.addressById = results[0]?.userAddresses;
    },
    getUserAddressByIdFail: (state) => {
      state.loadingAddressById = false;
    },
    //
    createUserAddressRequest: (state, { payload }) => {
      state.loadingAddressSubmitButton = true;
    },
    createUserAddressSuccess: (state) => {
      state.loadingAddressSubmitButton = false;
    },
    createUserAddressFail: (state) => {
      state.loadingAddressSubmitButton = false;
    },
    //
    updateUserAddressRequest: (state, { payload }) => {
      state.loadingAddressSubmitButton = true;
    },
    updateUserAddressSuccess: (state) => {
      state.loadingAddressSubmitButton = false;
    },
    updateUserAddressFail: (state) => {
      state.loadingAddressSubmitButton = false;
    },
    //
    editPatientDetailsAddressRequest: (state, payload) => {
      state.edit = true;
    },
    editPatientDetailsAddressSuccess: (state, { payload }) => {
      state.edit = true;
      state.editAddress = payload;
    },
    editPatientDetailsAddressFail: (state) => {
      state.edit = false;
      state.editAddress = null;
    },
    //
    getUserAddressProvinceRequest: (state) => {
      state.loadingProvince = true;
    },
    getUserAddressProvinceSuccess: (state, { payload: { results } }) => {
      state.loadingProvince = false;
      state.provinces = results;
    },
    getUserAddressProvinceFail: (state) => {
      state.loadingProvince = false;
    },
    // for searching the district
    loadingUserAddressDistrictRequest: (state) => {
      state.loadingDistrict = true;
    },
    getUserAddressDistrictRequest: (state, payload) => {
      state.loadingDistrict = true;
    },
    getUserAddressDistrictSuccess: (state, { payload: { results } }) => {
      state.loadingDistrict = false;
      state.districts = results;
    },
    getUserAddressDistrictFail: (state) => {
      state.loadingDistrict = false;
    },
    //
    clearAllAddressData: (state) => {
      state.edit = false;
      state.editAddress = null;
      state.loadingAddressSubmitButton = false;
    },
  },
});

export const {
  getUserAddressRequest,
  getUserAddressByIdSuccess,
  getUserAddressByIdFail,
  //
  createUserAddressRequest,
  createUserAddressSuccess,
  createUserAddressFail,
  //
  updateUserAddressRequest,
  updateUserAddressSuccess,
  updateUserAddressFail,
  //
  editPatientDetailsAddressRequest,
  editPatientDetailsAddressSuccess,
  editPatientDetailsAddressFail,
  //
  getUserAddressProvinceRequest,
  getUserAddressProvinceSuccess,
  getUserAddressProvinceFail,
  //
  loadingUserAddressDistrictRequest,
  getUserAddressDistrictRequest,
  getUserAddressDistrictSuccess,
  getUserAddressDistrictFail,
  //
  clearAllAddressData,
} = addressSlice.actions;

export default addressSlice.reducer;
