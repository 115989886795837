import { createSlice } from "@reduxjs/toolkit";
import { customerEditInitialState } from "./types";

const initialState: customerEditInitialState = {
  customerEdits: [],
  loadingCustomerEdit: false,
  loadingCreateCustomerEdit: false,
  edit: false,
  customerEdit: null,
  titles: [],
  count: 0,
  previous: "",
  next: "",
  departments: [],
  provinces: [],
  districts: [],
  doctors: [],
  customerTypes: [],
  //loading Request reducer
  loadingCustomerEditDepartment: false,
  loadingCustomerEditDoctor: false,
  loadingCustomerEditTitle: false,
  loadingCustomerEditCustomerType: false,
  loadingCustomerEditProvince: false,
  loadingCustomerEditDistrict: false,
  loadingCustomerEditWard: false,
  loadingCustomerEditDiscountScheme: false,
  loadingCustomerEditJaatiType: false,
  loadingCustomerEditServiceType: false,
  loadingCustomerEditDepartmentInvestigationDetail: false,
  palikas: [],
  loadingCustomerEditPalika: false,
  //
  jaatiTypes: [],
  wards: [],
  discountSchemes: [],
  serviceTypes: [],
  departmentInvestigationDetail: [],
  //print data start
  viewCustomerEditPrintDetailsData: [],
  customerEditRowData: null,
  customerEditPrintCount: [],
  customerEditId: "",
  //print loading
  loadingPrintCustomerEdit: false,
  //
  createdOpdId: 0,
  viewCustomerEditSmallPrintDetailsData: [],
  loadingCustomerEditOccupation: false,
  loadingCustomerEditCountry: false,
  occupations: [],
  countries: [],
};
export const CustomerEdit = createSlice({
  name: "customerEditReducer",
  initialState,
  reducers: {
    getCustomerEditRequest: (state, payload) => {
      state.loadingCustomerEdit = true;
    },
    getCustomerEditSuccess: (
      state,
      { payload: { results, count, next, previous } }
    ) => {
      state.loadingCustomerEdit = false;
      state.customerEdits = results;
      state.count = count;
      state.next = next;
      state.previous = previous;
    },
    getCustomerEditFail: (state) => {
      state.loadingCustomerEdit = false;
    },
    getCustomerEditNextRequest: (state, payload) => {
      state.loadingCustomerEdit = true;
    },
    getCustomerEditPreviousRequest: (state, payload) => {
      state.loadingCustomerEdit = true;
    },
    getCustomerEditByIdRequest: (state, payload) => {
      state.edit = true;
    },
    createCustomerEditRequest: (state, payload) => {
      state.loadingCreateCustomerEdit = true;
    },
    updateCustomerEditRequest: (state, payload) => {
      state.loadingCreateCustomerEdit = true;
    },
    createCustomerEditSuccess: (state, { payload }) => {
      state.loadingCreateCustomerEdit = false;
      state.createdOpdId = payload?.response?.data?.id;
      // state.createdOpdId = results[0]?.id;
    },
    createCustomerEditFail: (state) => {
      state.loadingCreateCustomerEdit = false;
    },
    customerEditEditSuccess: (state, { payload }) => {
      state.customerEdit = payload;
      state.edit = true;
    },
    customerEditEditFail: (state) => {
      state.edit = false;
    },
    updateCustomerEditSuccess: (state) => {
      state.loadingCreateCustomerEdit = false;
    },
    updateCustomerEditFail: (state) => {
      state.loadingCreateCustomerEdit = false;
    },
    clearCustomerEditData: (state) => {
      state.edit = false;
      state.customerEdit = null;
    },
    //external reducer request
    getCustomerEditDepartmentRequest: (state) => {
      state.loadingCustomerEditDepartment = true;
    },
    getCustomerEditDoctorRequest: (state) => {
      state.loadingCustomerEditDoctor = true;
    },
    getCustomerEditProvinceRequest: (state) => {
      state.loadingCustomerEditProvince = true;
    },
    getCustomerEditDistrictRequest: (state) => {
      state.loadingCustomerEditDistrict = true;
    },
    getCustomerEditCustomerTypeRequest: (state) => {
      state.loadingCustomerEditCustomerType = true;
    },
    getCustomerEditTitleRequest: (state) => {
      state.loadingCustomerEditTitle = true;
    },
    getCustomerEditPalikaRequest: (state, payload) => {
      state.loadingCustomerEditPalika = true;
    },
    getCustomerEditWardRequest: (state, payload) => {
      state.loadingCustomerEditWard = true;
    },
    getCustomerEditDiscountSchemeRequest: (state, payload) => {
      state.loadingCustomerEditDiscountScheme = true;
    },
    getCustomerEditJaatiTypeRequest: (state, payload) => {
      state.loadingCustomerEditJaatiType = true;
    },
    getCustomerEditServiceTypeRequest: (state, payload) => {
      state.loadingCustomerEditServiceType = true;
    },
    getCustomerEditDepartmentInvestigationDetailRequest: (
      state,
      { payload }
    ) => {
      state.loadingCustomerEditDepartmentInvestigationDetail = true;
    },
    //external reducer get data
    getCustomerEditPalikaSuccess: (state, { payload: { results } }) => {
      state.loadingCustomerEditPalika = false;
      state.palikas = results;
    },
    getCustomerEditPalikaFail: (state) => {
      state.loadingCustomerEditPalika = false;
    },
    getCustomerEditTitleSuccess: (state, { payload: { results } }) => {
      state.loadingCustomerEditTitle = false;
      state.titles = results;
    },
    getCustomerEditTitleFail: (state) => {
      state.loadingCustomerEditTitle = false;
    },
    getCustomerEditDepartmentSuccess: (state, { payload: { results } }) => {
      state.loadingCustomerEditDepartment = false;
      state.departments = results;
    },
    getCustomerEditDepartmentFail: (state) => {
      state.loadingCustomerEditDepartment = false;
    },
    getCustomerEditProvinceSuccess: (state, { payload: { results } }) => {
      state.loadingCustomerEditProvince = false;
      state.provinces = results;
    },
    getCustomerEditProvinceFail: (state) => {
      state.loadingCustomerEditProvince = false;
    },
    getCustomerEditDistrictSuccess: (state, { payload: { results } }) => {
      state.loadingCustomerEditDistrict = false;
      state.districts = results;
    },
    getCustomerEditDistrictFail: (state) => {
      state.loadingCustomerEditDistrict = false;
    },
    getCustomerEditCustomerTypeSuccess: (state, { payload: { results } }) => {
      state.loadingCustomerEditCustomerType = false;
      state.customerTypes = results;
    },
    getCustomerEditCustomerTypeFail: (state) => {
      state.loadingCustomerEditCustomerType = false;
    },
    getCustomerEditDoctorSuccess: (state, { payload: { results } }) => {
      state.loadingCustomerEditDoctor = false;
      state.doctors = results;
    },
    getCustomerEditDoctorFail: (state) => {
      state.loadingCustomerEditDoctor = false;
    },

    getCustomerEditDiscountSchemeSuccess: (state, { payload: { results } }) => {
      state.loadingCustomerEditDiscountScheme = false;
      state.discountSchemes = results;
    },
    getCustomerEditDiscountSchemeFail: (state) => {
      state.loadingCustomerEditDiscountScheme = false;
    },
    getCustomerEditJaatiTypeSuccess: (state, { payload: { results } }) => {
      state.loadingCustomerEditJaatiType = false;
      state.jaatiTypes = results;
    },
    getCustomerEditJaatiTypeFail: (state) => {
      state.loadingCustomerEditJaatiType = false;
    },
    getCustomerEditWardSuccess: (state, { payload: { results } }) => {
      state.loadingCustomerEditWard = false;
      state.wards = results.sort((row: any, data: any) => row.name - data.name);
    },
    getCustomerEditWardFail: (state) => {
      state.loadingCustomerEditWard = false;
      state.wards=[];
    },
    getCustomerEditServiceTypeSuccess: (state, { payload: { results } }) => {
      state.loadingCustomerEditServiceType = false;
      state.serviceTypes = results;
    },
    getCustomerEditServiceTypeFail: (state) => {
      state.loadingCustomerEditServiceType = false;
    },
    getCustomerEditDepartmentInvestigationDetailSuccess: (
      state,
      { payload: { results } }
    ) => {
      state.loadingCustomerEditDepartmentInvestigationDetail = false;
      state.departmentInvestigationDetail = results;
    },
    getCustomerEditDepartmentInvestigationDetailFail: (state) => {
      state.loadingCustomerEditDepartmentInvestigationDetail = false;
    },
    //loading
    loadingCustomerEditWardRequest: (state) => {
      state.loadingCustomerEditWard = true;
    },
    loadingCustomerEditDiscountSchemeRequest: (state) => {
      state.loadingCustomerEditDiscountScheme = true;
    },

    loadingCustomerEditJaatiTypeRequest: (state) => {
      state.loadingCustomerEditJaatiType = true;
    },
    loadingCustomerEditPalikaRequest: (state) => {
      state.loadingCustomerEditPalika = true;
    },
    loadingCustomerEditDoctorRequest: (state) => {
      state.loadingCustomerEditDoctor = true;
    },
    loadingCustomerEditServiceTypeRequest: (state) => {
      state.loadingCustomerEditServiceType = true;
    },
    //here all billing details
    getCustomerEditPrintCountRequest: (state, payload) => {
      state.loadingPrintCustomerEdit = true;
    },
    getCustomerEditPrintCountSuccess: (state, { payload: { results } }) => {
      state.loadingPrintCustomerEdit = false;
      state.customerEditPrintCount = results;
    },
    getCustomerEditPrintCountFail: (state) => {
      state.loadingPrintCustomerEdit = false;
    },
    getPrintCustomerEditCustomerDetailsSuccess: (
      state,
      { payload: results }
    ) => {
      state.loadingPrintCustomerEdit = false;
      state.customerEditId = results?.clinicSaleMain[0]?.id;
      state.customerEditRowData = {
        ...results,
        customerTypeCode: results?.customerType?.code,
        subTotal: results?.clinicSaleMain[0]?.subTotal,
        totalDiscount: results?.clinicSaleMain[0]?.totalDiscount,
        grandTotal: results?.clinicSaleMain[0]?.grandTotal,
        totalTax: results?.clinicSaleMain[0]?.totalTax,
        payTypeDisplay: results?.clinicSaleMain[0]?.payTypeDisplay,
        createdByUserName: results?.clinicSaleMain[0]?.createdByUserName,
        saleNoFull: results?.clinicSaleMain[0]?.saleNoFull,
        discountSchemeName: results?.clinicSaleMain[0]?.discountSchemeName,
      };
      state.viewCustomerEditPrintDetailsData =
        results?.clinicSaleMain[0]?.clinicSaleDetails?.map(
          (customerEditDetail: any, i: number) => [
            i + 1,
            customerEditDetail.testName,
            customerEditDetail.netAmount,
            customerEditDetail.qty,
            customerEditDetail.discountAmount,
            customerEditDetail.taxAmount,
            customerEditDetail.grossAmount,
            customerEditDetail.netAmount,
          ]
        );
      state.viewCustomerEditSmallPrintDetailsData =
        results?.clinicSaleMain[0]?.clinicSaleDetails?.map(
          (customerEditDetail: any, i: number) => [
            i + 1,
            customerEditDetail.testName,
            customerEditDetail.grossAmount,
            customerEditDetail.qty,
            customerEditDetail.netAmount,
          ]
        );
    },
    getPrintCustomerEditCustomerDetailsRequest: (state, { payload }) => {
      state.loadingPrintCustomerEdit = true;
    },
    getPrintCustomerEditCustomerDetailsFail: (state) => {
      state.loadingPrintCustomerEdit = false;
    },
    //post print count
    customerEditPrintCountRequest: (state, payload) => {
      state.loadingPrintCustomerEdit = true;
    },
    customerEditPrintCountSuccess: (state, payload) => {
      state.loadingPrintCustomerEdit = false;
    },
    customerEditPrintCountFail: (state) => {
      state.loadingPrintCustomerEdit = false;
    },
    //
    getCustomerEditOccupationSuccess: (state, { payload: { results } }) => {
      state.loadingCustomerEditOccupation = false;
      state.occupations = results;
    },
    getCustomerEditOccupationFail: (state) => {
      state.loadingCustomerEditOccupation = false;
    },
    getCustomerEditOccupationRequest: (state) => {
      state.loadingCustomerEditOccupation = true;
    },
    loadingCustomerEditOccupationRequest: (state) => {
      state.loadingCustomerEditOccupation = true;
    },
    //
    getCustomerEditCountryRequest: (state) => {
      state.loadingCustomerEditCountry = true;
    },
    getCustomerEditCountrySuccess: (state, { payload: { results } }) => {
      state.loadingCustomerEditCountry = false;
      state.countries = results;
    },
    getCustomerEditCountryFail: (state) => {
      state.loadingCustomerEditCountry = false;
    },
  },
});
// Action creators are generated for each case reducer function
export const {
  getCustomerEditRequest,
  getCustomerEditSuccess,
  getCustomerEditFail,
  getCustomerEditPreviousRequest,
  getCustomerEditNextRequest,
  createCustomerEditRequest,
  createCustomerEditSuccess,
  createCustomerEditFail,
  customerEditEditSuccess,
  updateCustomerEditSuccess,
  updateCustomerEditFail,
  clearCustomerEditData,
  updateCustomerEditRequest,
  getCustomerEditByIdRequest,
  customerEditEditFail,
  //external reducer request
  getCustomerEditDepartmentRequest,
  getCustomerEditDoctorRequest,
  getCustomerEditProvinceRequest,
  getCustomerEditDistrictRequest,
  getCustomerEditTitleRequest,
  getCustomerEditCustomerTypeRequest,
  getCustomerEditPalikaRequest,
  getCustomerEditWardRequest,
  getCustomerEditDiscountSchemeRequest,
  getCustomerEditJaatiTypeRequest,
  getCustomerEditServiceTypeRequest,
  getCustomerEditDepartmentInvestigationDetailRequest,
  //external reducer get data
  getCustomerEditTitleSuccess,
  getCustomerEditTitleFail,
  getCustomerEditDoctorSuccess,
  getCustomerEditDoctorFail,
  getCustomerEditDepartmentSuccess,
  getCustomerEditDepartmentFail,
  getCustomerEditProvinceSuccess,
  getCustomerEditProvinceFail,
  getCustomerEditDistrictSuccess,
  getCustomerEditDistrictFail,
  getCustomerEditCustomerTypeSuccess,
  getCustomerEditCustomerTypeFail,
  getCustomerEditPalikaSuccess,
  getCustomerEditPalikaFail,
  getCustomerEditJaatiTypeSuccess,
  getCustomerEditJaatiTypeFail,
  getCustomerEditDiscountSchemeSuccess,
  getCustomerEditDiscountSchemeFail,
  getCustomerEditWardSuccess,
  getCustomerEditWardFail,
  getCustomerEditServiceTypeSuccess,
  getCustomerEditServiceTypeFail,
  getCustomerEditDepartmentInvestigationDetailSuccess,
  getCustomerEditDepartmentInvestigationDetailFail,
  //loading
  loadingCustomerEditJaatiTypeRequest,
  loadingCustomerEditWardRequest,
  loadingCustomerEditDiscountSchemeRequest,
  loadingCustomerEditPalikaRequest,
  loadingCustomerEditDoctorRequest,
  loadingCustomerEditServiceTypeRequest,
  //print show details
  getPrintCustomerEditCustomerDetailsRequest,
  getPrintCustomerEditCustomerDetailsSuccess,
  getPrintCustomerEditCustomerDetailsFail,
  getCustomerEditPrintCountRequest,
  getCustomerEditPrintCountSuccess,
  getCustomerEditPrintCountFail,
  //post count
  customerEditPrintCountRequest,
  customerEditPrintCountSuccess,
  customerEditPrintCountFail,
  //
  getCustomerEditCountryFail,
  getCustomerEditCountrySuccess,
  getCustomerEditCountryRequest,
  loadingCustomerEditOccupationRequest,
  getCustomerEditOccupationRequest,
  getCustomerEditOccupationSuccess,
  getCustomerEditOccupationFail,
} = CustomerEdit.actions;
export default CustomerEdit.reducer;
