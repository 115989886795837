import { createSlice, createAction } from "@reduxjs/toolkit";
import {  MaterializedReportInitialState } from "./types";
const initialState: MaterializedReportInitialState = {
  suppliers: [],
  users:[],
  items:[],
  purchaseDetails:[],
  //loading 
  loadingGenerateReport: false,
  loadingMaterializedReportSupplier:false,
  loadingMaterializedReportItem:false,
  loadingMaterializedReportUser:false,
  loadingMaterializedReportDetail:false,
  count: 0,
  previous: "",
  next: "",
};

// export const getMaterializedReportDetailRequest = createAction(
//   "getMaterializedReportDetailRequest",
//   function prepare({ detailsRowsPerPage, page }) {
//     return {
//       payload: {
//         detailsRowsPerPage,
//         page,
//       },
//     };
//   }
// );
export const MaterializedReport = createSlice({
  name: "MaterializedReportReducer",
  initialState,
  reducers: {
    //loading
    loadingMaterializedReportDetail: (state) => {
      state.loadingMaterializedReportDetail = true;
    },
     //external reducer request
     getMaterializedReportDetailRequest:(state,{payload})=>{
     state.loadingMaterializedReportDetail=true;
     },
     getMaterializedReportSupplierRequest:(state)=>{
      state.loadingMaterializedReportSupplier=true;
    },
    getMaterializedReportItemRequest:(state)=>{
      state.loadingMaterializedReportItem=true;
    },
    getMaterializedReportUserRequest:(state)=>{
      state.loadingMaterializedReportUser=true;
    },
    getMaterializedReportAllSummaryRequest:(state,{payload})=>{
        state.loadingGenerateReport=true;
    },
    getMaterializedReportAllDetailRequest:(state,{payload})=>{
      state.loadingGenerateReport=true;
    },
    getMaterializedReportQuickSummaryRequest:(state,{payload})=>{
      state.loadingGenerateReport=true;
    },
    getMaterializedReportQuickDetailRequest:(state,{payload})=>{
    state.loadingGenerateReport=true;
    },
    getMaterializedReportNextRequest: (state, payload) => {
      state.loadingMaterializedReportDetail = true;
    },
    getMaterializedReportPreviousRequest: (state, payload) => {
      state.loadingMaterializedReportDetail = true;
    },
    //external get reducer  
    getMaterializedReportDetailSuccess: (
      state,
      { payload: { results, count, next, previous } }
    ) => {
      state.purchaseDetails = results;
      state.loadingMaterializedReportDetail = false;
      state.count = count;
      state.next = next;
      state.previous = previous;
    },
    getMaterializedReportDetailFail: (state) => {
      state.loadingMaterializedReportDetail = false;
    },
    getMaterializedReportUserSuccess: (state,{ payload: { results } }) => {
      state.loadingMaterializedReportUser = false;
      state.users = results;
    },
    getMaterializedReportUserFail: (state) => {
      state.loadingMaterializedReportUser = false;
    },
    getMaterializedReportSupplierSuccess: (state,{ payload: { results } }) => {
      state.loadingMaterializedReportSupplier = false;
      state.suppliers = results;
    },
    getMaterializedReportSupplierFail: (state) => {
      state.loadingMaterializedReportSupplier = false;
    },
    getMaterializedReportItemSuccess: (state,{ payload: { results } }) => {
      state.loadingMaterializedReportItem = false;
      state.items = results;
    },
    getMaterializedReportItemFail: (state) => {
      state.loadingMaterializedReportItem = false;
    },
    getMaterializedReportAllSummarySuccess: (state,{ payload: { results } }) => {
      state.loadingGenerateReport = false;
      state.purchaseDetails = results;
    },
    getMaterializedReportAllSummaryFail: (state) => {
      state.loadingGenerateReport = false;
    },
    getMaterializedReportQuickSummarySuccess:  (
      state,
      { payload: { results, count, next, previous } }
    ) => {
      state.loadingGenerateReport = false;
      state.purchaseDetails = results;
      state.count = count;
      state.next = next;
      state.previous = previous;
    },
    getMaterializedReportQuickSummaryFail: (state) => {
      state.loadingGenerateReport = false;
    },
    getMaterializedReportAllDetailSuccess: (state,{ payload: { results } }) => {
      state.loadingGenerateReport = false;
      state.purchaseDetails = results;
    },
    getMaterializedReportAllDetailFail: (state) => {
      state.loadingGenerateReport = false;
    },
    getMaterializedReportQuickDetailSuccess:  (
      state,
      { payload: { results, count, next, previous } }
    ) => {
      state.loadingGenerateReport = false;
      state.purchaseDetails = results;
      state.count = count;
      state.next = next;
      state.previous = previous;
    },
    getMaterializedReportQuickDetailFail: (state) => {
      state.loadingGenerateReport = false;
    },

    clearMaterializedReportData: (state) => {
      state.suppliers = [];
      state.users = [];
      state.items = [];
    },
    clearPrintUserActivityLogData: (state) => {
      state.purchaseDetails = [];
    },
  },
});
// Action creators are generated for each case reducer function
export const {
  //reducerReport
  getMaterializedReportDetailRequest,
  getMaterializedReportSupplierRequest,
  getMaterializedReportItemRequest,
  getMaterializedReportUserRequest,
  getMaterializedReportAllSummaryRequest,
  getMaterializedReportAllDetailRequest,
  getMaterializedReportQuickSummaryRequest,
  getMaterializedReportQuickDetailRequest,
  getMaterializedReportPreviousRequest,
  getMaterializedReportNextRequest,
  //reducer get data
  getMaterializedReportDetailSuccess,
  getMaterializedReportDetailFail,
  getMaterializedReportItemSuccess,
  getMaterializedReportItemFail,
  getMaterializedReportSupplierSuccess,
  getMaterializedReportSupplierFail,
  getMaterializedReportUserSuccess,
  getMaterializedReportUserFail,
  getMaterializedReportQuickDetailSuccess,
  getMaterializedReportQuickDetailFail,
  getMaterializedReportAllDetailSuccess,
  getMaterializedReportAllDetailFail,
  getMaterializedReportQuickSummarySuccess,
  getMaterializedReportQuickSummaryFail,
  getMaterializedReportAllSummarySuccess,
  getMaterializedReportAllSummaryFail,
  //loading
  loadingMaterializedReportDetail,
  //clear
  clearMaterializedReportData,
  clearPrintUserActivityLogData,
} = MaterializedReport.actions;
export default MaterializedReport.reducer;
