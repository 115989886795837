import { Action } from "@reduxjs/toolkit";
import { combineEpics } from "redux-observable";
import { Observable } from "rxjs";
import { filter, map } from "rxjs/operators";
import { mergeMap } from "rxjs/operators";
import {
  dispatchAction,
  stateAction,
} from "../../../../../../AppUtils/Utils/globalTypes";
import {
  clearAllContactData,
  createUserContactFail,
  createUserContactRequest,
  createUserContactSuccess,
  editUserContactFail,
  editUserContactRequest,
  editUserContactSuccess,
  getUserContactFail,
  getUserContactRequest,
  getUserContactSuccess,
  updateUserContactFail,
  updateUserContactRequest,
  updateUserContactSuccess,
} from "./contactSlice";
import {
  createUserContact,
  getEditedUserContact,
  getUserContactById,
  updateUserContact,
} from "./api";
import {
  alertErrorAction,
  alertSuccessAction,
  closeModal,
} from "../../../../../CommonAppRedux/CommonAppSlice";
import messages from "../../../../../../AppUtils/Utils/validationConstants";

const getUserContactByIdEpic = (
  action$: Observable<Action>,
  _: stateAction,
  { dispatch }: dispatchAction
) =>
  action$.pipe(
    filter(getUserContactRequest.match),
    mergeMap(async (action) => {
      try {
        const response = await getUserContactById(action?.payload);
        return { payload: response.data };
      } catch (e) {
        return { error: e };
      }
    }),
    map((action) =>
      action?.payload
        ? getUserContactSuccess(action?.payload)
        : getUserContactFail()
    )
  );

const createUserContactByIdEpic = (
  action$: Observable<Action>,
  _: stateAction,
  { dispatch }: dispatchAction
) =>
  action$.pipe(
    filter(createUserContactRequest.match),
    mergeMap(async ({ payload: { values } }) => {
      try {
        const body = JSON.stringify(values);
        const response = await createUserContact(body);
        if (response) {
          dispatch(alertSuccessAction(messages.createMessage));
          dispatch(getUserContactRequest({ customerId: values?.id }));
          dispatch(closeModal());
          dispatch(clearAllContactData());
        }
        return { payload: { response } };
      } catch (e) {
        dispatch(alertErrorAction(messages?.createFailMessage));
        return { error: e };
      }
    }),
    map((action) => {
      return action?.payload
        ? createUserContactSuccess()
        : createUserContactFail();
    })
  );

const updateUserContactByIdEpic = (
  action$: Observable<Action>,
  _: stateAction,
  { dispatch }: dispatchAction
) =>
  action$.pipe(
    filter(updateUserContactRequest.match),
    mergeMap(async ({ payload: { values } }) => {
      try {
        const body = JSON.stringify(values);
        const response = await updateUserContact(body, values?.id);
        if (response) {
          dispatch(getUserContactRequest({ customerId: values?.id }));
          dispatch(alertSuccessAction(messages.updateMessage));
          dispatch(closeModal());
          dispatch(clearAllContactData());
        }
        return { payload: { response } };
      } catch (e) {
        dispatch(alertErrorAction(messages?.updateFailMessage));
        return { error: e };
      }
    }),
    map((action) => {
      return action?.payload
        ? updateUserContactSuccess()
        : updateUserContactFail();
    })
  );

const getEditedUserContactEpic = (
  action$: Observable<Action>,
  _: stateAction,
  { dispatch }: dispatchAction
) =>
  action$.pipe(
    filter(editUserContactRequest.match),
    mergeMap(async ({ payload: { customerId, contactId } }) => {
      try {
        const response = await getEditedUserContact(customerId);
        const filteredContact = response?.data?.userContacts.find(
          (contact: any) => contact.id === contactId
        );
        return { payload: filteredContact };
      } catch (e) {
        return { error: e };
      }
    }),
    map((action) => {
      return action?.payload
        ? editUserContactSuccess(action?.payload)
        : editUserContactFail();
    })
  );

export const userContactEpic = combineEpics(
  getUserContactByIdEpic,
  createUserContactByIdEpic,
  updateUserContactByIdEpic,
  getEditedUserContactEpic
);
