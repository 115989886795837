import * as API from "./api";
import {
  createResultEntryFail,
  createResultEntryRequest,
  createResultEntrySuccess,
  editResultEntryFail,
  editResultEntryRequest,
  editResultEntrySuccess,
  getEnteredResultEntryReportCommentFail,
  getEnteredResultEntryReportCommentRequest,
  getEnteredResultEntryReportCommentSuccess,
  getEnteredResultEntryReportFooterFail,
  getEnteredResultEntryReportFooterRequest,
  getEnteredResultEntryReportFooterSuccess,
  getResultEntryCollectedTestDetailsFail,
  getResultEntryCollectedTestDetailsRequest,
  getResultEntryCollectedTestDetailsSuccess,
  getResultEntryCollectedTestFail,
  getResultEntryCollectedTestRequest,
  getResultEntryCollectedTestSuccess,
  getResultEntryFail,
  getResultEntryNextRequest,
  getResultEntryPreviousRequest,
  getResultEntryReportCommentFail,
  getResultEntryReportCommentRequest,
  getResultEntryReportCommentSuccess,
  getResultEntryReportFooterFail,
  getResultEntryReportFooterRequest,
  getResultEntryReportFooterSuccess,
  getResultEntryRequest,
  getResultEntrySuccess,
  getResultEntryTestSensitiveMedicineFail,
  getResultEntryTestSensitiveMedicineRequest,
  getResultEntryTestSensitiveMedicineSuccess,
  getSpecificTestSensitiveMedicineFail,
  getSpecificTestSensitiveMedicineRequest,
  getSpecificTestSensitiveMedicineSuccess,
  updateResultEntryFail,
  updateResultEntryRequest,
  updateResultEntrySuccess,
} from "./ResultEntrySlice";
import { getNext, getPrevious } from "../../../CommonAppRedux/api";
import {
  alertErrorAction,
  alertSuccessAction,
} from "../../../CommonAppRedux/CommonAppSlice";
import { filter, map, Observable } from "rxjs";
import { Action } from "@reduxjs/toolkit";
import {
  dispatchAction,
  stateAction,
} from "../../../../AppUtils/Utils/globalTypes";
import { mergeMap } from "rxjs";
import { combineEpics } from "redux-observable";
import messages from "../../../../AppUtils/Utils/validationConstants";
//get Result Entry epic
const getResultEntryEpic = (
  action$: Observable<Action>,
  _: stateAction,
  { dispatch }: dispatchAction
) =>
  action$.pipe(
    filter(getResultEntryRequest.match),
    mergeMap(async (action) => {
      try {
        const response = await API.getResultEntry(action.payload);
        return { payload: response.data };
      } catch (e) {
        return { error: e };
      }
    }),
    map((action) =>
      action?.payload
        ? getResultEntrySuccess(action?.payload)
        : getResultEntryFail()
    )
  );
//get next
const getResultEntryNext = (
  action$: Observable<Action>,
  _: stateAction,
  { dispatch }: dispatchAction
) =>
  action$.pipe(
    filter(getResultEntryNextRequest.match),
    mergeMap(async (action) => {
      try {
        const response = await getNext(action.payload);
        return { payload: response.data };
      } catch (e) {
        return { error: e };
      }
    }),
    map((action) =>
      action?.payload
        ? getResultEntrySuccess(action?.payload)
        : getResultEntryFail()
    )
  );
//get previous
const getResultEntryPrevious = (
  action$: Observable<Action>,
  _: stateAction,
  { dispatch }: dispatchAction
) =>
  action$.pipe(
    filter(getResultEntryPreviousRequest.match),
    mergeMap(async (action) => {
      try {
        const response = await getPrevious(action.payload);
        return { payload: response.data };
      } catch (e) {
        return { error: e };
      }
    }),
    map((action) =>
      action?.payload
        ? getResultEntrySuccess(action?.payload)
        : getResultEntryFail()
    )
  );
//get collected tests epic
const getResultEntryCollectedTestEpic = (
  action$: Observable<Action>,
  _: stateAction,
  { dispatch }: dispatchAction
) =>
  action$.pipe(
    filter(getResultEntryCollectedTestRequest.match),
    mergeMap(async (action) => {
      try {
        const response = await API.getResultEntryCollectedTest(action.payload);
        return { payload: response.data };
      } catch (e) {
        return { error: e };
      }
    }),
    map((action) =>
      action?.payload
        ? getResultEntryCollectedTestSuccess(action?.payload)
        : getResultEntryCollectedTestFail()
    )
  );
//get result entry report footer
const getResultEntryReportFooterEpic = (
  action$: Observable<Action>,
  _: stateAction,
  { dispatch }: dispatchAction
) =>
  action$.pipe(
    filter(getResultEntryReportFooterRequest.match),
    mergeMap(async (action) => {
      try {
        const response = await API.getResultEntryReportFooter(action.payload);
        return { payload: response.data };
      } catch (e) {
        return { error: e };
      }
    }),
    map((action) =>
      action?.payload
        ? getResultEntryReportFooterSuccess(action?.payload)
        : getResultEntryReportFooterFail()
    )
  );

//get result entry test details
const getResultEntryCollectedTestDetailsEpic = (
  action$: Observable<Action>,
  _: stateAction,
  { dispatch }: dispatchAction
) =>
  action$.pipe(
    filter(getResultEntryCollectedTestDetailsRequest.match),
    mergeMap(async (action) => {
      try {
        const response = await API.getResultEntryCollectedTestDetails(
          action.payload
        );
        return { payload: response.data };
      } catch (e) {
        return { error: e };
      }
    }),
    map((action) =>
      action?.payload
        ? getResultEntryCollectedTestDetailsSuccess(action?.payload)
        : getResultEntryCollectedTestDetailsFail()
    )
  );
//get result entry test details
const getResultEntryTestDetailsEpic = (
  action$: Observable<Action>,
  _: stateAction,
  { dispatch }: dispatchAction
) =>
  action$.pipe(
    filter(editResultEntryRequest.match),
    mergeMap(async (action) => {
      try {
        const response = await API.getResultEntryTestDetails(action.payload);
        return { payload: response.data };
      } catch (e) {
        return { error: e };
      }
    }),
    map((action) =>
      action?.payload
        ? editResultEntrySuccess(action?.payload)
        : editResultEntryFail()
    )
  );
//get sensitive medicine of specific test
const getSpecificSensitiveMedicineEpic = (
  action$: Observable<Action>,
  _: stateAction,
  { dispatch }: dispatchAction
) =>
  action$.pipe(
    filter(getSpecificTestSensitiveMedicineRequest.match),
    mergeMap(async (action) => {
      try {
        const response = await API.getSpecificSensitiveMedicine(action.payload);
        return { payload: response.data };
      } catch (e) {
        return { error: e };
      }
    }),
    map((action) =>
      action?.payload
        ? getSpecificTestSensitiveMedicineSuccess(action?.payload)
        : getSpecificTestSensitiveMedicineFail()
    )
  );
//get result entry sensitive medicine
const getResultEntrySensitiveMedicineEpic = (
  action$: Observable<Action>,
  _: stateAction,
  { dispatch }: dispatchAction
) =>
  action$.pipe(
    filter(getResultEntryTestSensitiveMedicineRequest.match),
    mergeMap(async (action) => {
      try {
        const response = await API.getResultEntryTestSensitiveMedicine(
          action.payload
        );
        return { payload: response.data };
      } catch (e) {
        return { error: e };
      }
    }),
    map((action) =>
      action?.payload
        ? getResultEntryTestSensitiveMedicineSuccess(action?.payload)
        : getResultEntryTestSensitiveMedicineFail()
    )
  );
//get result entry report comments
const getResultEntryReportCommentEpic = (
  action$: Observable<Action>,
  _: stateAction,
  { dispatch }: dispatchAction
) =>
  action$.pipe(
    filter(getResultEntryReportCommentRequest.match),
    mergeMap(async () => {
      try {
        const response = await API.getResultEntryReportComment();
        return { payload: response.data };
      } catch (e) {
        return { error: e };
      }
    }),
    map((action) =>
      action?.payload
        ? getResultEntryReportCommentSuccess(action?.payload)
        : getResultEntryReportCommentFail()
    )
  );
//get  entered result entry report comments
const getEnteredResultEntryReportCommentEpic = (
  action$: Observable<Action>,
  _: stateAction,
  { dispatch }: dispatchAction
) =>
  action$.pipe(
    filter(getEnteredResultEntryReportCommentRequest.match),
    mergeMap(async ({ payload }) => {
      try {
        const response = await API.getEnteredResultEntryReportComment(payload);
        return { payload: response.data };
      } catch (e) {
        return { error: e };
      }
    }),
    map((action) =>
      action?.payload
        ? getEnteredResultEntryReportCommentSuccess(action?.payload)
        : getEnteredResultEntryReportCommentFail()
    )
  );
//get  entered result entry report comments
const getEnteredResultEntryReportFooterEpic = (
  action$: Observable<Action>,
  _: stateAction,
  { dispatch }: dispatchAction
) =>
  action$.pipe(
    filter(getEnteredResultEntryReportFooterRequest.match),
    mergeMap(async ({ payload }) => {
      try {
        const response = await API.getEnteredResultEntryReportFooter(payload);
        return { payload: response.data };
      } catch (e) {
        return { error: e };
      }
    }),
    map((action) =>
      action?.payload
        ? getEnteredResultEntryReportFooterSuccess(action?.payload)
        : getEnteredResultEntryReportFooterFail()
    )
  );
//create result entry  epic
const createResultEntryEpic = (
  action$: Observable<Action>,
  _: stateAction,
  { dispatch }: dispatchAction
) =>
  action$.pipe(
    filter(createResultEntryRequest.match),
    mergeMap(async ({ payload }) => {
      try {
        const body = JSON.stringify(payload);
        const response = await API.createResultEntry(body);
        if (response) {
          dispatch(alertSuccessAction(messages.createMessage));
        }
        return { payload: { response } };
      } catch (e) {
        dispatch(alertErrorAction(messages.createFailMessage));
        return { error: e };
      }
    }),
    map((action) => {
      return action?.payload
        ? createResultEntrySuccess()
        : createResultEntryFail();
    })
  );
//update result entry  epic
const updateResultEntryEpic = (
  action$: Observable<Action>,
  _: stateAction,
  { dispatch }: dispatchAction
) =>
  action$.pipe(
    filter(updateResultEntryRequest.match),
    mergeMap(async ({ payload }) => {
      try {
        const body = JSON.stringify(payload);
        const response = await API.updateResultEntry(body);
        if (response) {
          dispatch(alertSuccessAction(messages.updateMessage));
        }
        return { payload: { response } };
      } catch (e) {
        dispatch(alertErrorAction(messages.updateFailMessage));
        return { error: e };
      }
    }),
    map((action) => {
      const { payload } = action;
      return payload ? updateResultEntrySuccess() : updateResultEntryFail();
    })
  );
export const resultEntryEpics = combineEpics(
  getResultEntryEpic,
  getResultEntryNext,
  getResultEntryPrevious,
  createResultEntryEpic,
  getResultEntryCollectedTestEpic,
  getResultEntryReportFooterEpic,
  getResultEntryCollectedTestDetailsEpic,
  getResultEntrySensitiveMedicineEpic,
  getSpecificSensitiveMedicineEpic,
  getResultEntryReportCommentEpic,
  getResultEntryTestDetailsEpic,
  getEnteredResultEntryReportCommentEpic,
  getEnteredResultEntryReportFooterEpic,
  updateResultEntryEpic
);
