import {
  getTotalPatientStatReportUserRequest,
  getTotalPatientStatReportAllSummaryRequest,
  getTotalPatientStatReportAllDetailRequest,
  getTotalPatientStatReportQuickSummaryRequest,
  getTotalPatientStatReportQuickDetailRequest,
  //reducer get data
  getTotalPatientStatReportUserSuccess,
  getTotalPatientStatReportUserFail,
  getTotalPatientStatReportQuickDetailSuccess,
  getTotalPatientStatReportQuickDetailFail,
  getTotalPatientStatReportAllDetailSuccess,
  getTotalPatientStatReportAllDetailFail,
  getTotalPatientStatReportQuickSummarySuccess,
  getTotalPatientStatReportQuickSummaryFail,
  getTotalPatientStatReportAllSummarySuccess,
  getTotalPatientStatReportAllSummaryFail,
  getTotalPatientStatReportDetailSuccess,
  getTotalPatientStatReportDetailFail,
  loadingTotalPatientStatReportDetail,
  getTotalPatientStatReportPreviousRequest,
  getTotalPatientStatReportNextRequest,
  getTotalPatientStatReportDetailRequest,
  getTotalPatientStatReportSummaryRequest,
  getTotalPatientStatReportSummarySuccess,
  getTotalPatientStatReportSummaryFail,
  getTotalPatientEthnicityDetailRequest,
  getTotalPatientEthnicityDetailSuccess,
  getTotalPatientEthnicityDetailFail,
  getTotalPatientRaceGroupDetailRequest,
  getTotalPatientRaceGroupDetailSuccess,
  getTotalPatientRaceGroupDetailFail,
  getTotalPatientStatJaatiTypeRequest,
  getTotalPatientStatJaatiTypeSuccess,
  getTotalPatientStatJaatiTypeFail,
} from "./totalPatientStatReportSlice";
import { mergeMap } from "rxjs";
import *  as API from "./api";
import { map, filter, tap } from "rxjs/operators";
import { Observable } from "rxjs";
import { Action } from "@reduxjs/toolkit";
import { combineEpics } from "redux-observable";
import {
  alertErrorAction,
  alertSuccessAction,
  closeModal,
} from "../../../../CommonAppRedux/CommonAppSlice";
import { getNext, getPrevious } from "../../../../CommonAppRedux/api";
import {
  dispatchAction,
  stateAction,
} from "../../../../../AppUtils/Utils/globalTypes";

export const controller = new AbortController();

//user
const getTotalPatientStatReportUserEpic = (
  action$: Observable<Action>,
  _: stateAction,
  { dispatch }: dispatchAction
) =>
  action$.pipe(
    filter(getTotalPatientStatReportUserRequest.match),
    mergeMap(async (action) => {
      try {
        const response = await API.getTotalPatientStatReportUser();
        return { payload: response.data };
      } catch (e) {
        return { error: e };
      }
    }),
    map((action) =>
      action?.payload
        ? getTotalPatientStatReportUserSuccess(action?.payload)
        : getTotalPatientStatReportUserFail()
    )
  );
//item

//get all purchase summary detail
// const getTotalPatientStatReportAllSummaryEpic = (
//   action$: Observable<Action>,
//   _: stateAction,
//   { dispatch }: dispatchAction
// ) =>
//   action$.pipe(
//     filter(getTotalPatientStatReportAllSummaryRequest.match),
//     mergeMap(async (action) => {
//       try {
//         const response = await API.getTotalPatientStatReportAllReport(action?.payload);
//         if (response?.data?.count === 0) {
//           dispatch(
//             alertErrorAction(`No Materialized Data Found`)
//           )
//         }
//         return { payload: response.data };
//       } catch (e) {
//         return { error: e };
//       }
//     }),
//     map((action) =>
//       action?.payload
//         ? getTotalPatientStatReportAllSummarySuccess(action?.payload)
//         : getTotalPatientStatReportAllSummaryFail()
//     )
//   );
//quick summary
// const getTotalPatientStatReportQuickSummaryEpic = (
//   action$: Observable<Action>,
//   _: stateAction,
//   { dispatch }: dispatchAction
// ) =>
//   action$.pipe(
//     filter(getTotalPatientStatReportQuickSummaryRequest.match),
//     mergeMap(async (action) => {
//       try {
//         const response = await API.getTotalPatientStatReportQuickReport(action.payload);
//         if (response?.data?.count === 0) {
//           dispatch(
//             alertErrorAction(`No Materialized Data Found`)
//           )
//         }
//         return { payload: response.data };
//       } catch (e) {
//         return { error: e };
//       }
//     }),
//     map((action) =>
//       action?.payload
//         ? getTotalPatientStatReportQuickSummarySuccess(action?.payload)
//         : getTotalPatientStatReportQuickSummaryFail()
//     )
//   );

//get all purchase summary detail
// const getTotalPatientStatReportAllDetailEpic = (
//   action$: Observable<Action>,
//   _: stateAction,
//   { dispatch }: dispatchAction
// ) =>
//   action$.pipe(
//     filter(getTotalPatientStatReportAllDetailRequest.match),
//     mergeMap(async (action) => {
//       try {
//         const response = await API.getTotalPatientStatReportAllReport(action?.payload);
//         return { payload: response.data };
//       } catch (e) {
//         return { error: e };
//       }
//     }),
//     map((action) =>
//       action?.payload
//         ? getTotalPatientStatReportAllDetailSuccess(action?.payload)
//         : getTotalPatientStatReportAllDetailFail()
//     )
//   );
//quick Detail
// const getTotalPatientStatReportQuickDetailEpic = (
//   action$: Observable<Action>,
//   _: stateAction,
//   { dispatch }: dispatchAction
// ) =>
//   action$.pipe(
//     filter(getTotalPatientStatReportQuickDetailRequest.match),
//     mergeMap(async (action) => {
//       try {
//         const response = await API.getTotalPatientStatReportQuickReport(action.payload);
//         return { payload: response.data };
//       } catch (e) {
//         return { error: e };
//       }
//     }),
//     map((action) =>
//       action?.payload
//         ? getTotalPatientStatReportQuickDetailSuccess(action?.payload)
//         : getTotalPatientStatReportQuickDetailFail()
//     )
//   );

//next and previous
//get next
const getTotalPatientStatReportNext = (
  action$: Observable<Action>,
  _: stateAction,
  { dispatch }: dispatchAction
) =>
  action$.pipe(
    filter(getTotalPatientStatReportNextRequest.match),
    mergeMap(async (action) => {
      dispatch(loadingTotalPatientStatReportDetail());
      try {
        const response = await getNext(action.payload);
        return { payload: response.data };
      } catch (e) {
        return { error: e };
      }
    }),
    map((action) =>
      action?.payload
        ? getTotalPatientStatReportDetailSuccess(action?.payload)
        : getTotalPatientStatReportDetailFail()
    )
  );
//get previous
const getTotalPatientStatReportPrevious = (
  action$: Observable<Action>,
  _: stateAction,
  { dispatch }: dispatchAction
) =>
  action$.pipe(
    filter(getTotalPatientStatReportPreviousRequest.match),
    mergeMap(async (action) => {
      dispatch(loadingTotalPatientStatReportDetail());
      try {
        const response = await getPrevious(action.payload);
        return { payload: response.data };
      } catch (e) {
        return { error: e };
      }
    }),
    map((action) =>
      action?.payload
        ? getTotalPatientStatReportDetailSuccess(action?.payload)
        : getTotalPatientStatReportDetailFail()
    )
  );
//het purchase report details
// const getTotalPatientStatReportEpic = (
//   action$: Observable<Action>,
//   _: stateAction,
//   { dispatch }: dispatchAction
// ) =>
//   action$.pipe(
//     filter(getTotalPatientStatReportDetailRequest.match),
//     mergeMap(async (action) => {
//       try {
//         const response = await API.getTotalPatientStatReportQuickReport(action.payload);

//         return { payload: response.data };
//       } catch (e) {
//         return { error: e };
//       }
//     }),
//     map((action) =>
//       action?.payload
//         ? getTotalPatientStatReportDetailSuccess(action?.payload)
//         : getTotalPatientStatReportDetailFail()
//     )
//   );

  //
  const getTotalPatientStatReportSummaryEpic = (
    action$: Observable<Action>,
    _: stateAction,
    { dispatch }: dispatchAction
  ) =>
    action$.pipe(
      filter(getTotalPatientStatReportSummaryRequest.match),
      mergeMap(async (action) => {
        try {
          const response = await API.getTotalPatientOpdStatReportSummary(action?.payload);
        
          return { payload: response.data };
        } catch (e) {
          return { error: e };
        }
      }),
      map((action) =>
        action?.payload
          ?getTotalPatientStatReportSummarySuccess(action?.payload)
          :getTotalPatientStatReportSummaryFail()
      )
    );
    //
    const getTotalPatientEthnicityDetailEpic = (
      action$: Observable<Action>,
      _: stateAction,
      { dispatch }: dispatchAction
    ) =>
      action$.pipe(
        filter(getTotalPatientEthnicityDetailRequest.match),
        mergeMap(async (action) => {
          try {
            const response = await API.getTotalPatientOpdEthinicityStatReport(action?.payload);
            return { payload: response.data };
          } catch (e) {
            return { error: e };
          }
        }),
        map((action) =>
          action?.payload
            ?getTotalPatientEthnicityDetailSuccess(action?.payload)
            :getTotalPatientEthnicityDetailFail()
        )
      );

      const  getTotalPatientRaceGroupDetailEpic = (
        action$: Observable<Action>,
        _: stateAction,
        { dispatch }: dispatchAction
      ) =>
        action$.pipe(
          filter( getTotalPatientRaceGroupDetailRequest.match),
          mergeMap(async (action) => {
            try {
              const response = await API.getTotalPatientOpdRaceGroupStatReport(action?.payload);
              if(response?.data?.results?.length === 0){
                dispatch(
                  alertErrorAction(`No Data Found`)
                );
              }
              return { payload: response.data };
            } catch (e) {
              return { error: e };
            }
          }),
          map((action) =>
            action?.payload
              ? getTotalPatientRaceGroupDetailSuccess(action?.payload)
              : getTotalPatientRaceGroupDetailFail()
          )
        );

        //
        const getTotalPatientStatJaatiTypeEpic = (
          action$: Observable<Action>,
          _: stateAction,
          { dispatch }: dispatchAction
        ) =>
          action$.pipe(
            filter(getTotalPatientStatJaatiTypeRequest.match),
            mergeMap(async (action) => {
              try {
                const response = await API.getTotalPatientStatJaatiType();
                return { payload: response.data };
              } catch (e) {
                return { error: e };
              }
            }),
            map((action) =>
              action?.payload
                ? getTotalPatientStatJaatiTypeSuccess(action?.payload)
                : getTotalPatientStatJaatiTypeFail()
            )
          );
export const TotalPatientStatReportEpics = combineEpics(
  getTotalPatientStatReportUserEpic,
  // getTotalPatientStatReportAllSummaryEpic,
  // getTotalPatientStatReportQuickSummaryEpic,
  // getTotalPatientStatReportQuickDetailEpic,
  // getTotalPatientStatReportEpic,
  // getTotalPatientStatReportAllDetailEpic,
  getTotalPatientStatReportPrevious,
  getTotalPatientStatReportNext,
  getTotalPatientStatReportSummaryEpic,
  getTotalPatientRaceGroupDetailEpic,
  getTotalPatientEthnicityDetailEpic,
  getTotalPatientStatJaatiTypeEpic,


);
