import { createAction, createSlice } from "@reduxjs/toolkit";

const initialState: any = {
  stores: [],
  edit: false,
  Store: null,
  loadingStore: false,
  isDefault: [],
  loadingMainStore: false,
  loadingCreateStore:false,
  count: 0,
  next: "",
  previous: "",
};

export const updateStoreRequest = createAction(
  "updateStoreRequest",
  function prepare({ values, id, rowsPerPage, page }) {
    return {
      payload: {
        values,
        id,
        rowsPerPage,
        page,
      },
    };
  }
);

export const getStoreRequest = createAction(
  "getStoreRequest",
  function prepare({ rowsPerPage, page, filterData }) {
    return {
      payload: {
        rowsPerPage,
        page,
        filterData,
      },
    };
  }
);
// get next request
export const getStoreNextRequest = createAction(
  "getStoreNextRequest",
  function prepare(next) {
    return {
      payload: next,
    };
  }
);
//get previous request
export const getStorePreviousRequest = createAction(
  "getStorePreviousRequest",
  function prepare(previous) {
    return {
      payload: previous,
    };
  }
);
export const Store = createSlice({
  name: "StoreReducer",
  initialState,
  reducers: {
    loadingStore: (state) => {
      state.loadingStore = true;
    },
    getStoreSuccess: (
      state,
      { payload: { results, count, next, previous } }
    ) => {
      state.loadingStore = false;
      state.stores = results;
      state.count = count;
      state.next = next;
      state.previous = previous;
    },
    getStoreFail: (state) => {
      state.loadingStore = false;
    },

    createStoreRequest: (state, payload) => {
      state.loadingCreateStore = true;
      state.closeModal = true;
    },
    createStoreSuccess: (state) => {
      state.loadingCreateStore = false;
    },
    createStoreFail: (state) => {
      state.loadingCreateStore = false;
    },
    getMainStoreRequest : (state) => {
      state.loadingMainStore = true;
    },
    getMainStoreSuccess: (state, {payload: {results }}) => {
      state.loadingMainStore = false;
      state.isDefault = results;
    },
    getMainStoreFail: (state) => {
      state.loadingMainStore = false;
    },
    getStoreByIdRequest: (state, payload) => {
      state.edit = true;
    },
    StoreEditSuccess: (state, { payload }) => {
      state.store = payload;
    },
    storeEditFail: (state) => {
      state.edit = false;
    },
    updateStoreSuccess: (state) => {
      state.loadingCreateStore = false;
    },
    updateStoreFail: (state) => {
      state.loadingCreateStore = false;
    },
    
    clearStoreData: (state) => {
      state.edit = false;
      state.store = null;
    },
  },
});
// Action creators are generated for each case reducer function
export const {
  loadingStore,
  getStoreSuccess,
  getStoreFail,
  createStoreRequest,
  createStoreSuccess,
  createStoreFail,
  StoreEditSuccess,
  updateStoreSuccess,
  updateStoreFail,
  clearStoreData,
  getStoreByIdRequest,
  storeEditFail,
  getMainStoreRequest,
  getMainStoreSuccess,
  getMainStoreFail,

} = Store.actions;
export default Store.reducer;
