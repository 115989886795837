import { memo } from "react";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import { alertDialog } from "./types";
import DialogTitle from "@mui/material/DialogTitle";
import { useAppDispatch } from "../../AppUtils/Utils/appHooks";
import { alertSuccessAction } from "../../Pages/CommonAppRedux/CommonAppSlice";
const AlertDialog = ({
  handleSubmit,
  values,
  showAlertModal,
  setShowAlertModal,
  types,
  setSubmitLoading,
  setShowModal,
  setLoading,
  directMessage,
}: alertDialog) => {

  const dispatch = useAppDispatch();
  const handleClickOpen = () => {
    types !== "verify" && handleSubmit(values);
    types === "verify" && dispatch(handleSubmit(values));
    types === "testFinding" &&
      dispatch(alertSuccessAction("Delete Successfully"));
    setShowAlertModal(false);
  };
  const handleClose = () => {
    setShowAlertModal(false);
    if (setLoading) {
      types !== "noteModule" && setLoading(false);
    }
    types === "paymentModule" &&
      setShowModal(false) &&
      dispatch(alertSuccessAction("Additional Charge Added Successfully"));
    types !== "unsavedDataPrompt" &&
      types !== "testFinding" &&
      types !== "appointmentModule" &&
      types === "biometrics" &&
      setSubmitLoading(false);
  };
  const message = `${
    types === "openAlert"
      ? "Do You Want To Close Modal"
      : types === "paymentModule"
      ? "Do you Want To Add Payment Details"
      : types === "appointmentModule"
      ? `Dr. ${
          values?.doctor?.fullName ? values?.doctor.fullName : ""
        } has already an appointment in selected time slot! Would you like to make appointment anyway?`
      : types === "testFinding"
      ? "Do You Want To Delete Reference Range?"
      : types === "verify"
      ? "Do You Want To Verify This Purchase"
      : " Are You Sure ?"
  }`;
  return (
    <>
      <Dialog
        open={showAlertModal}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">Confirmation</DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            {directMessage ?? message}
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClickOpen} autoFocus>
            YES
          </Button>
          <Button onClick={handleClose}>NO</Button>
        </DialogActions>
      </Dialog>
    </>
  );
};
export default memo(AlertDialog);
