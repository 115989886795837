import {
  createJaatiTypeFail,
  createJaatiTypeRequest,
  createJaatiTypeSuccess,
  getJaatiTypeFail,
  getJaatiTypeSuccess,
  loadingJaatiType,
  updateJaatiTypeFail,
  updateJaatiTypeSuccess,
  getJaatiTypeRequest,
  getJaatiTypeNextRequest,
  getJaatiTypePreviousRequest,
  updateJaatiTypeRequest,
  clearJaatiTypeData,
  getJaatiTypeByIdRequest,
  jaatiTypeEditSuccess,
  jaatiTypeEditFail,
  getMainJaatiTypeSuccess,
  getMainJaatiTypeFail,
  getMainJaatiTypeRequest,
} from "./jaatiTypeSlice";
import { mergeMap } from "rxjs";
import {
  createJaatiType,
  getJaatiType,
  updateJaatiType,
  getAllJaatiTypeForDefault,
  getJaatiTypeById,
} from "./api";
import { map, filter } from "rxjs/operators";
import { Observable } from "rxjs";
import { Action } from "@reduxjs/toolkit";
import { combineEpics } from "redux-observable";
import {
  alertErrorAction,
  alertSuccessAction,
  closeModal,
} from "../../../CommonAppRedux/CommonAppSlice";
import { getNext, getPrevious } from "../../../CommonAppRedux/api";
import {
  dispatchAction,
  stateAction,
} from "../../../../AppUtils/Utils/globalTypes";
import messages from "../../../../AppUtils/Utils/validationConstants";
export const controller = new AbortController();
// import { searchData } from "../../../CommonAppRedux/commonAppFunctions";
//get JaatiTypeType epic
const getJaatiTypeEpic = (
  action$: Observable<Action>,
  _: stateAction,
  { dispatch }: dispatchAction
) =>
  action$.pipe(
    filter(getJaatiTypeRequest.match),
    mergeMap(async (action) => {
      dispatch(loadingJaatiType());
      try {
        const response = await getJaatiType(action.payload);
        return { payload: response.data };
      } catch (e) {
        return { error: e };
      }
    }),
    map((action) =>
      action?.payload ? getJaatiTypeSuccess(action?.payload) : getJaatiTypeFail()
    )
  );

//get stor by id
const getJaatiTypeByIdEpic = (
  action$: Observable<Action>,
  _: stateAction,
  { dispatch }: dispatchAction
) =>
  action$.pipe(
    filter(getJaatiTypeByIdRequest.match),
    mergeMap(async (action) => {
      try {
        const response = await getJaatiTypeById(action.payload);
        return { payload: response.data };
      } catch (e) {
        return { error: e };
      }
    }),
    map((action) =>
      action?.payload ? jaatiTypeEditSuccess(action.payload) : jaatiTypeEditFail()
    )
  );

//get next
const getJaatiTypeNext = (
  action$: Observable<Action>,
  _: stateAction,
  { dispatch }: dispatchAction
) =>
  action$.pipe(
    filter(getJaatiTypeNextRequest.match),
    mergeMap(async (action) => {
      dispatch(loadingJaatiType());
      try {
        const response = await getNext(action.payload);
        return { payload: response.data };
      } catch (e) {
        return { error: e };
      }
    }),
    map((action) =>
      action?.payload ? getJaatiTypeSuccess(action?.payload) : getJaatiTypeFail()
    )
  );
//get previous
const getJaatiTypePrevious = (
  action$: Observable<Action>,
  _: stateAction,
  { dispatch }: dispatchAction
) =>
  action$.pipe(
    filter(getJaatiTypePreviousRequest.match),
    mergeMap(async (action) => {
      dispatch(loadingJaatiType());
      try {
        const response = await getPrevious(action.payload);
        return { payload: response.data };
      } catch (e) {
        return { error: e };
      }
    }),
    map((action) =>
      action?.payload ? getJaatiTypeSuccess(action?.payload) : getJaatiTypeFail()
    )
  );
//create JaatiTypeType epic
const createJaatiTypeEpic = (
  action$: Observable<Action>,
  _: stateAction,
  { dispatch }: dispatchAction
) =>
  action$.pipe(
    filter(createJaatiTypeRequest.match),
    mergeMap(async ({ payload: { values, rowsPerPage, page } }) => {
      try {
        const body = new FormData();
        for (let [key, value] of Object.entries(values)) {
          // @ts-ignore
          body.append(`${key}`, value);
        }
        const response = await createJaatiType(body);
        if (response) {
          dispatch(getJaatiTypeRequest({ rowsPerPage, page }));
          dispatch(alertSuccessAction(messages.createMessage));
          dispatch(closeModal());
        }
        return { payload: { response } };
      } catch (e) {
        dispatch(alertErrorAction(messages.createFailMessage));
        return { error: e };
      }
    }),
    map((action) => {
      return action?.payload ? createJaatiTypeSuccess() : createJaatiTypeFail();
    })
  );
//update JaatiTypeType epic
const updateJaatiTypeEpic = (
  action$: Observable<Action>,
  _: stateAction,
  { dispatch }: dispatchAction
) =>
  action$.pipe(
    filter(updateJaatiTypeRequest.match),
    mergeMap(async ({ payload: { values, id, rowsPerPage, page } }) => {
      try {
        const body = new FormData();
        for (let [key, value] of Object.entries(values)) {
          // @ts-ignore
          body.append(`${key}`, value);
        }

        const response = await updateJaatiType(body, id);
        if (response) {
          dispatch(getJaatiTypeRequest({ rowsPerPage, page }));
          dispatch(alertSuccessAction(messages.updateMessage));
          dispatch(closeModal());
          dispatch(clearJaatiTypeData());
        }
        return { payload: { response, rowsPerPage } };
      } catch (e) {
        dispatch(alertErrorAction(messages.updateFailMessage));
        return { error: e };
      }
    }),
    map((action) =>
      action?.payload ? updateJaatiTypeSuccess() : updateJaatiTypeFail()
    )
  );

const getAllJaatiTypeDefaultEpic = (
  action$: Observable<Action>,
  _: stateAction,
  { dispatch }: dispatchAction
) =>
  action$.pipe(
    filter(getMainJaatiTypeRequest.match),
    mergeMap(async (action) => {
      // dispatch(loadingJaatiType());
      try {
        const response = await getAllJaatiTypeForDefault();
        return { payload: response.data };
      } catch (e) {
        return { error: e };
      }
    }),
    map((action) =>
      action?.payload
        ? getMainJaatiTypeSuccess(action?.payload)
        : getMainJaatiTypeFail()
    )
  );

export const jaatiTypeEpics = combineEpics(
  getJaatiTypeEpic,
  createJaatiTypeEpic,
  updateJaatiTypeEpic,
  getJaatiTypeNext,
  getJaatiTypePrevious,
  getAllJaatiTypeDefaultEpic,
  getJaatiTypeByIdEpic
);
