import { axiosInstance } from "../../../../AppUtils/Utils/axios";
import {
  searchProps,
  getApiProps,
} from "../../../../AppUtils/Utils/globalTypes";
import { filterApiData } from "../../../../AppUtils/Utils/appFunctions";

const moldTypeModURL = `api/v1/appointment-app/mold-type`;

//get moldType
export const getMoldType = (data: getApiProps) => {
  const { rowsPerPage, page, search } = data;
  return axiosInstance.get(
    `${moldTypeModURL}?offset=${
      rowsPerPage * page
    }&limit=${rowsPerPage}&search=${search ?? ""}${
      data?.filterData ? filterApiData(data?.filterData) : ""
    }`
  );
};
//get moldType by id
export const getMoldTypeById = (id: number) => {
  return axiosInstance.get(`${moldTypeModURL}/${id}`);
};
//search moldType
export const searchMoldType = ({ rowsPerPage, search }: searchProps) => {
  return `${moldTypeModURL}?offset=0&limit=${rowsPerPage}&search=${
    search ?? ""
  }`;
};
//update moldType
export const updateMoldType = (body: string | FormData, id: number) =>
  axiosInstance.patch(`${moldTypeModURL}/${id}`, body);
//create moldType
export const createMoldType = (body: string | FormData) =>
  axiosInstance.post(`${moldTypeModURL}`, body);
