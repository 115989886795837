import {
  getMaterializedReportSupplierRequest,
  getMaterializedReportItemRequest,
  getMaterializedReportUserRequest,
  getMaterializedReportAllSummaryRequest,
  getMaterializedReportAllDetailRequest,
  getMaterializedReportQuickSummaryRequest,
  getMaterializedReportQuickDetailRequest,
  //reducer get data
  getMaterializedReportItemSuccess,
  getMaterializedReportItemFail,
  getMaterializedReportSupplierSuccess,
  getMaterializedReportSupplierFail,
  getMaterializedReportUserSuccess,
  getMaterializedReportUserFail,
  getMaterializedReportQuickDetailSuccess,
  getMaterializedReportQuickDetailFail,
  getMaterializedReportAllDetailSuccess,
  getMaterializedReportAllDetailFail,
  getMaterializedReportQuickSummarySuccess,
  getMaterializedReportQuickSummaryFail,
  getMaterializedReportAllSummarySuccess,
  getMaterializedReportAllSummaryFail,
  getMaterializedReportDetailSuccess,
  getMaterializedReportDetailFail,
  loadingMaterializedReportDetail,
  getMaterializedReportPreviousRequest,
  getMaterializedReportNextRequest,
  getMaterializedReportDetailRequest,
} from "./materializedReportSlice";
import { mergeMap } from "rxjs";
import *  as API from "./api";
import { map, filter, tap } from "rxjs/operators";
import { Observable } from "rxjs";
import { Action } from "@reduxjs/toolkit";
import { combineEpics } from "redux-observable";
import {
  alertErrorAction,
  alertSuccessAction,
  closeModal,
} from "../../../../CommonAppRedux/CommonAppSlice";
import { getNext, getPrevious } from "../../../../CommonAppRedux/api";
import {
  dispatchAction,
  stateAction,
} from "../../../../../AppUtils/Utils/globalTypes";

export const controller = new AbortController();
const getMaterializedReportSupplierEpic = (
  action$: Observable<Action>,
  _: stateAction,
  { dispatch }: dispatchAction
) =>
  action$.pipe(
    filter(getMaterializedReportSupplierRequest.match),
    mergeMap(async (action) => {
      try {
        const response = await API.getMaterializedReportSupplier();
        return { payload: response.data };
      } catch (e) {
        return { error: e };
      }
    }),
    map((action) =>
      action?.payload
        ? getMaterializedReportSupplierSuccess(action?.payload)
        : getMaterializedReportSupplierFail()
    )
  );
//user
const getMaterializedReportUserEpic = (
  action$: Observable<Action>,
  _: stateAction,
  { dispatch }: dispatchAction
) =>
  action$.pipe(
    filter(getMaterializedReportUserRequest.match),
    mergeMap(async (action) => {
      try {
        const response = await API.getMaterializedReportUser();
        return { payload: response.data };
      } catch (e) {
        return { error: e };
      }
    }),
    map((action) =>
      action?.payload
        ? getMaterializedReportUserSuccess(action?.payload)
        : getMaterializedReportUserFail()
    )
  );
//item
const getMaterializedReportItemEpic = (
  action$: Observable<Action>,
  _: stateAction,
  { dispatch }: dispatchAction
) =>
  action$.pipe(
    filter(getMaterializedReportItemRequest.match),
    mergeMap(async (action) => {
      try {
        const response = await API.getMaterializedReportItem();
        return { payload: response.data };
      } catch (e) {
        return { error: e };
      }
    }),
    map((action) =>
      action?.payload
        ? getMaterializedReportItemSuccess(action?.payload)
        : getMaterializedReportItemFail()
    )
  );

//get all purchase summary detail
const getMaterializedReportAllSummaryEpic = (
  action$: Observable<Action>,
  _: stateAction,
  { dispatch }: dispatchAction
) =>
  action$.pipe(
    filter(getMaterializedReportAllSummaryRequest.match),
    mergeMap(async (action) => {
      try {
        const response = await API.getMaterializedReportAllReport(action?.payload);
        if (response?.data?.count === 0) {
          dispatch(
            alertErrorAction(`No Materialized Data Found`)
          )
        }
        return { payload: response.data };
      } catch (e) {
        return { error: e };
      }
    }),
    map((action) =>
      action?.payload
        ? getMaterializedReportAllSummarySuccess(action?.payload)
        : getMaterializedReportAllSummaryFail()
    )
  );
//quick summary
const getMaterializedReportQuickSummaryEpic = (
  action$: Observable<Action>,
  _: stateAction,
  { dispatch }: dispatchAction
) =>
  action$.pipe(
    filter(getMaterializedReportQuickSummaryRequest.match),
    mergeMap(async (action) => {
      try {
        const response = await API.getMaterializedReportQuickReport(action.payload);
        if (response?.data?.count === 0) {
          dispatch(
            alertErrorAction(`No Materialized Data Found`)
          )
        }
        return { payload: response.data };
      } catch (e) {
        return { error: e };
      }
    }),
    map((action) =>
      action?.payload
        ? getMaterializedReportQuickSummarySuccess(action?.payload)
        : getMaterializedReportQuickSummaryFail()
    )
  );

//get all purchase summary detail
const getMaterializedReportAllDetailEpic = (
  action$: Observable<Action>,
  _: stateAction,
  { dispatch }: dispatchAction
) =>
  action$.pipe(
    filter(getMaterializedReportAllDetailRequest.match),
    mergeMap(async (action) => {
      try {
        const response = await API.getMaterializedReportAllReport(action?.payload);
        return { payload: response.data };
      } catch (e) {
        return { error: e };
      }
    }),
    map((action) =>
      action?.payload
        ? getMaterializedReportAllDetailSuccess(action?.payload)
        : getMaterializedReportAllDetailFail()
    )
  );
//quick Detail
const getMaterializedReportQuickDetailEpic = (
  action$: Observable<Action>,
  _: stateAction,
  { dispatch }: dispatchAction
) =>
  action$.pipe(
    filter(getMaterializedReportQuickDetailRequest.match),
    mergeMap(async (action) => {
      try {
        const response = await API.getMaterializedReportQuickReport(action.payload);
        return { payload: response.data };
      } catch (e) {
        return { error: e };
      }
    }),
    map((action) =>
      action?.payload
        ? getMaterializedReportQuickDetailSuccess(action?.payload)
        : getMaterializedReportQuickDetailFail()
    )
  );

//next and previous
//get next
const getMaterializedReportNext = (
  action$: Observable<Action>,
  _: stateAction,
  { dispatch }: dispatchAction
) =>
  action$.pipe(
    filter(getMaterializedReportNextRequest.match),
    mergeMap(async (action) => {
      dispatch(loadingMaterializedReportDetail());
      try {
        const response = await getNext(action.payload);
        return { payload: response.data };
      } catch (e) {
        return { error: e };
      }
    }),
    map((action) =>
      action?.payload
        ? getMaterializedReportDetailSuccess(action?.payload)
        : getMaterializedReportDetailFail()
    )
  );
//get previous
const getMaterializedReportPrevious = (
  action$: Observable<Action>,
  _: stateAction,
  { dispatch }: dispatchAction
) =>
  action$.pipe(
    filter(getMaterializedReportPreviousRequest.match),
    mergeMap(async (action) => {
      dispatch(loadingMaterializedReportDetail());
      try {
        const response = await getPrevious(action.payload);
        return { payload: response.data };
      } catch (e) {
        return { error: e };
      }
    }),
    map((action) =>
      action?.payload
        ? getMaterializedReportDetailSuccess(action?.payload)
        : getMaterializedReportDetailFail()
    )
  );
//het purchase report details
const getMaterializedReportEpic = (
  action$: Observable<Action>,
  _: stateAction,
  { dispatch }: dispatchAction
) =>
  action$.pipe(
    filter(getMaterializedReportDetailRequest.match),
    mergeMap(async (action) => {
      try {
        const response = await API.getMaterializedReportQuickReport(action.payload);

        return { payload: response.data };
      } catch (e) {
        return { error: e };
      }
    }),
    map((action) =>
      action?.payload
        ? getMaterializedReportDetailSuccess(action?.payload)
        : getMaterializedReportDetailFail()
    )
  );
export const materializedReportEpics = combineEpics(
  getMaterializedReportSupplierEpic,
  getMaterializedReportItemEpic,
  getMaterializedReportUserEpic,
  getMaterializedReportAllSummaryEpic,
  getMaterializedReportQuickSummaryEpic,
  getMaterializedReportQuickDetailEpic,
  getMaterializedReportAllDetailEpic,
  getMaterializedReportPrevious,
  getMaterializedReportNext,
  getMaterializedReportEpic,
);
