import { axiosInstance } from "../../../../../AppUtils/Utils/axios";

const baseUrl = "api/v1/appointment-app/medical-referral";
// get medical referral
export const getMedicalReferral = () => axiosInstance.get(`${baseUrl}`);

export const getFilteredMedicalReferral = (id: number) => {
  return axiosInstance.get(`${baseUrl}?customer=${id}`);
};

// get clinic
export const getMedicalReferralClinic = () =>
  axiosInstance.get(`${baseUrl}/clinic?offset=0&limit=20`);
// get doctor
export const getMedicalReferralDoctor = ({ rowsPerPage }: any) =>
  axiosInstance.get(`${baseUrl}/doctor?offset=0&limit=${rowsPerPage}`);
// get title
export const getMedicalReferralTitle = () =>
  axiosInstance.get(`${baseUrl}/title?offset=0&limit=0`);
// get site
export const getMedicalReferralSite = () =>
  axiosInstance.get(`${baseUrl}/site?offset=0&limit=20`);
// get appointmentType
export const getMedicalReferralAppointmentType = () =>
  axiosInstance.get(`${baseUrl}/appointment-type?offset=0&limit=20`);
// get referralType
export const getMedicalReferralReferralType = () =>
  axiosInstance.get(`${baseUrl}/referral-type?offset=0&limit=20`);
// for medical referral form
export const getClinicFromSite = (site: number) =>
  axiosInstance.get(`${baseUrl}/clinic?offset=0&limit=0&site=${site}`);
export const getDoctorFromClinic = (clinic: number) =>
  axiosInstance.get(`${baseUrl}/doctor?offset=0&limit=0&clinic=${clinic}`);

//create MedicalReferral
export const createMedicalReferral = (body: string | FormData) =>
  axiosInstance.post(`${baseUrl}`, body);
//update MedicalReferral
export const updateMedicalReferral = (body: string | FormData, id: number) =>
  axiosInstance.patch(`${baseUrl}/${id}`, body);

// create discharge note
export const createDischargeNote = (body: string | FormData) =>
  axiosInstance.post(`api/v1/appointment-app/discharge-note`, body);

export const getMedReferralById = (id: number) => {
  return axiosInstance.get(`${baseUrl}/${id}`);
};
