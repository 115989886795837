import { filterApiData } from "../../../../AppUtils/Utils/appFunctions";
import { axiosInstance } from "../../../../AppUtils/Utils/axios";
import {
  getApiProps,
  searchProps,
} from "../../../../AppUtils/Utils/globalTypes";
import { sampleCollection } from "../types";
const sampleCollectionApi =
  "api/v1/lab-sample-collection-app/sample-collection";
// create sample collection
export const createSampleCollection = (body: any, id?: number) =>
  axiosInstance.post<sampleCollection>(`${sampleCollectionApi}`, body);
//   get sample collection data
export const getSampleCollection = (data: getApiProps) => {
  const { rowsPerPage, page, search } = data;
  return axiosInstance.get(
    `${sampleCollectionApi}?offset=${
      rowsPerPage * page
    }&limit=${rowsPerPage}&search=${search ?? ""}${
      data?.filterData ? filterApiData(data?.filterData) : ""
    }`
  );
};
// get sample collection specific data
export const getSampleCollectionCustomerTest = (id: number) =>
  axiosInstance.get(
    `${sampleCollectionApi}/customer-test/${id}?offset=0&limit=0`
  );
//obtaining the sample type
export const getSampleType = () =>
  axiosInstance.get(`${sampleCollectionApi}/sample-type?offset=0&limit=0`);
//obtaining all data
export const getAllSampleCollection = () =>
  axiosInstance.get<sampleCollection[]>(`api/v1/lab-app/test-category`);
//handle search
export const searchSampleCollection = ({
  rowsPerPage,
  search,
}: searchProps) => {
  return `${sampleCollectionApi}?offset=0&limit=${rowsPerPage}&search=${
    search ?? ""
  }`;
};
