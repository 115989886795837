import { axiosInstance } from "../../../../../../AppUtils/Utils/axios";

export const deviceRepairUrl = "api/v1/appointment-app/device-repair";

// get device repair by id
export const getDeviceRepairById = ({ id, rowsPerpage, page }: any) => {
  console.log("value of id ", id);
  return axiosInstance.get(
    `${deviceRepairUrl}?date_after=&date_before=&customer=${id}`
  );
};

// create device repair
export const createDeviceRepairById = (body: string | FormData) => {
  return axiosInstance.post(`${deviceRepairUrl}`, body);
};

// update device repair
export const updateDeviceRepairById = (body: string | FormData, id: number) => {
  return axiosInstance.patch(`${deviceRepairUrl}/${id}`, body);
};

// get edited item
export const getEditedDeviceRepair = (id: number) => {
  return axiosInstance.get(`${deviceRepairUrl}/${id}`);
};
// get item list
export const getDeviceRepairItemList = () => {
  return axiosInstance.get(`${deviceRepairUrl}/item`);
};
