import { axiosInstance } from "../../../../AppUtils/Utils/axios";
import { filterApiData } from "../../../../AppUtils/Utils/appFunctions";
import {
  getApiProps,
  searchProps,
} from "../../../../AppUtils/Utils/globalTypes";

const manufacturerModURL = 'api/v1/inventory-item-app/manufacturer';

//get manufacturer data api
export const getManufacturer = (data: getApiProps) => {
  const { rowsPerPage, page } = data;
  return axiosInstance.get(
    `${manufacturerModURL}?offset=${
      rowsPerPage * page
    }&limit=${rowsPerPage}${
      data?.filterData ? filterApiData(data?.filterData) : ""
    }`
  );
};
//handle search
export const searchManufacturer = ({ rowsPerPage, search }: searchProps) => {
  return `${manufacturerModURL}?offset=0&limit=${rowsPerPage}&search=${search}`;
};
//create manufacturer
export const createManufacturer = (body: string | FormData) =>
  axiosInstance.post(`${manufacturerModURL}`, body);
//update manufacturer
export const updateManufacturer = (body: string | FormData, id: number) =>
  axiosInstance.patch(`${manufacturerModURL}/${id}`, body);
