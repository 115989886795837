import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import CancelIcon from "@mui/icons-material/Cancel";
import { useStatusStyles } from "./statusStyles";
import React from "react";
const Status = ({ active }: any) => {
  const globalClasses = useStatusStyles();
  return (
    <div>
      {active ? (
        <span>
          <CheckCircleIcon className={globalClasses.checkCircleIconCustom} />
        </span>
      ) : (
        <span className={globalClasses.cancelIconCustom}>
          <CancelIcon />
        </span>
      )}
    </div>
  );
};

export default Status;
