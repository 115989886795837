import { createSlice } from "@reduxjs/toolkit";
import { referralTypeInitialState } from "./types";

const initialState: referralTypeInitialState = {
  referralTypes: [],
  edit: false,
  referralType: null,
  loadingReferralType: false,
  loadingCreateReferralType: false,
  count: 0,
  previous: "",
  next: "",
};

export const ReferralType = createSlice({
  name: "referralTypeReducer",
  initialState,
  reducers: {
    getReferralTypeRequest: (state, payload) => {
      state.loadingReferralType = true;
    },
    getReferralTypeSuccess: (
      state,
      { payload: { results, count, next, previous } }
    ) => {
      state.loadingReferralType = false;
      state.referralTypes = results;
      state.count = count;
      state.next = next;
      state.previous = previous;
    },
    getReferralTypeFail: (state) => {
      state.loadingReferralType = false;
    },

    getReferralTypeNextRequest: (state, payload) => {
      state.loadingReferralType = true;
    },
    getReferralTypePreviousRequest: (state, payload) => {
      state.loadingReferralType = true;
    },
    createReferralTypeRequest: (state, payload) => {
      state.loadingCreateReferralType = true;
    },
    updateReferralTypeRequest: (state, payload) => {
      state.loadingCreateReferralType = true;
    },
    createReferralTypeSuccess: (state) => {
      state.loadingCreateReferralType = false;
    },
    createReferralTypeFail: (state) => {
      state.loadingCreateReferralType = false;
    },
    getReferralTypeByIdRequest: (state, payload) => {
      state.edit = true;
    },
    referralTypeEditSuccess: (state, { payload }) => {
      state.referralType = payload;
    },
    referralTypeEditFail: (state) => {
      state.edit = false;
    },
    updateReferralTypeSuccess: (state) => {
      state.loadingCreateReferralType = false;
    },
    updateReferralTypeFail: (state) => {
      state.loadingCreateReferralType = false;
    },
    clearAllData: (state) => {
      state.edit = false;
      state.referralType = null;
    },
  },
});

//action creaters generated for each case reducer
export const {
  getReferralTypeRequest,
  getReferralTypeFail,
  getReferralTypeSuccess,
  getReferralTypeNextRequest,
  getReferralTypePreviousRequest,
  createReferralTypeFail,
  createReferralTypeRequest,
  createReferralTypeSuccess,
  referralTypeEditSuccess,
  updateReferralTypeFail,
  updateReferralTypeSuccess,
  updateReferralTypeRequest,
  clearAllData,
  getReferralTypeByIdRequest,
  referralTypeEditFail,
} = ReferralType.actions;

export default ReferralType.reducer;
