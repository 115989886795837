import { axiosInstance } from "../../../../../AppUtils/Utils/axios";
import { filterApiData } from "../../../../../AppUtils/Utils/appFunctions";
import {
  getApiProps,
  searchProps,
} from "../../../../../AppUtils/Utils/globalTypes";
import { MaterializedReport } from "./types";

const MaterializedReportModURL = 'api/v1/clinic-ird-report-app/materialized-view-report';


export const getMaterializedReportSupplier = () =>
axiosInstance.get(`${MaterializedReportModURL}/supplier?offset=0&limit=${0}`);
export const getMaterializedReportUser = () =>
axiosInstance.get(`${MaterializedReportModURL}/user?offset=0&limit=${0}`);
export const getMaterializedReportItem = () =>
axiosInstance.get(`${MaterializedReportModURL}/item?offset=0&limit=${0}`);
export const getMaterializedReportAllReport = (data:any) =>{
  const {updatedValue} = data;

  const {
    item,
    summary,
    detail,
    supplier,
    user,
    purchaseType,
    startDateAd,
    endDateAd,
  } = updatedValue;
  return axiosInstance.get(
    `${MaterializedReportModURL}?offset=0&limit=${0}${
      data?.filterData ? filterApiData(data?.filterData) : ""
    }&date_after=${startDateAd}&date_before=${endDateAd}`
  )};
export const getMaterializedReportQuickReport = (data: any) => {
  const { rowsPerPage, page ,updatedValue} = data;
  const {
    item,
    summary,
    detail,
    supplier,
    user,
    purchaseType,
    startDateAd,
    endDateAd,
  } = updatedValue;
  return axiosInstance.get(
    `${MaterializedReportModURL}?offset=${
      rowsPerPage * page
    }&limit=${rowsPerPage}${
      data?.filterData ? filterApiData(data?.filterData) : ""
    }&date_after=${startDateAd}&date_before=${endDateAd}`
  );
};

export const searchMaterializedReport = ({ rowsPerPage, search ,updatedValue}: any) => {
  const {
    item,
    summary,
    detail,
    supplier,
    user,
    purchaseType,
    startDateAd,
    endDateAd,
  } = updatedValue;

  return `${MaterializedReportModURL}?offset=0&limit=${rowsPerPage}&search=${search}&date_after=${startDateAd}&date_before=${endDateAd}`;
};