import { axiosInstance } from "../../../../AppUtils/Utils/axios";
import { filterApiData } from "../../../../AppUtils/Utils/appFunctions";
import {
  getApiProps,
  searchProps,
} from "../../../../AppUtils/Utils/globalTypes";

export const clinicSetupModURL = "/api/v1/clinic-app/clinic-setup";

export const getClinicSetup = (data: getApiProps) => {
  const { rowsPerPage, page } = data;
  return axiosInstance.get(
    `${clinicSetupModURL}?offset=${rowsPerPage * page}&limit=${rowsPerPage}${
      data?.filterData ? filterApiData(data?.filterData) : ""
    }`
  );
};

//get clinicSetup type by id
export const getClinicSetupById = (id: number) => {
  return axiosInstance.get(`${clinicSetupModURL}/${id}`);
};

export const getClinicSite = (rowsPerPage: number) => {
  return axiosInstance.get(
    `${clinicSetupModURL}/site?offset=0&limit=${rowsPerPage}`
  )
}

export const searchClinicSetup = ({ rowsPerPage, search }: searchProps) => {
  return `${clinicSetupModURL}?offset=0&limit=${rowsPerPage}&search=${search}`;
};
export const createClinicSetup = (body: string | FormData) =>
  axiosInstance.post(`${clinicSetupModURL}`, body);
//update ClinicSetupType
export const updateClinicSetup = (body: string | FormData, id: number) =>
  axiosInstance.patch(`${clinicSetupModURL}/${id}`, body);
