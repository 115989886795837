import React from "react";
import {
  Search,
  StyledInputBase,
} from "../../../Component/Layout/Sidebar/SidebarStyles";
import { InputChangeEvent } from "../../../AppUtils/Utils/globalTypes";
import { Grid } from "@mui/material";

function SearchInput({ setSearchData, placeholder }: any) {
  const setHandleSearch = ({ target: { value } }: InputChangeEvent) => {
    setSearchData(value.trimStart());
  };

  return (
    <Grid item xs={12} sx={{ mb: 1 }}>
      <Search className="search-wrapper">
        <StyledInputBase
          placeholder={placeholder}
          inputProps={{ "aria-label": "search" }}
          onChange={setHandleSearch}
          autoFocus
        />
        {/* <SearchIconWrapper className="search-icon-wrapper">
                  <SearchIcon style={{ color: "grey" }} />
                </SearchIconWrapper> */}
      </Search>
    </Grid>
  );
}
export default SearchInput;
