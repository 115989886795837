import {
  createSaleFail,
  createSaleRequest,
  createSaleSuccess,
  getSaleFail,
  getSaleSuccess,
  getSaleRequest,
  getSaleNextRequest,
  getSalePreviousRequest,
  clearSaleData,
  getSaleCustomerRequest,
  getSaleCustomerSuccess,
  getSaleCustomerFail,
  getSaleDiscountSchemeRequest,
  getSaleDiscountSchemeSuccess,
  getSaleItemRequest,
  getSaleItemSuccess,
  //external reducer
  getSalePaymentModeRequest,
  getSalePaymentModeSuccess,
  getSalePaymentModeFail,
  getViewSaleDetailsRequest,
  getViewSaleDetailsSuccess,
  getViewSaleDetailsFail,
  getViewSaleDetailsNextRequest,
  getViewSaleDetailsPreviousRequest,
  getPrintSaleDetailsRequest,
  getPrintSaleDetailsSuccess,
  getPrintSaleDetailsFail,
  salePrintCountRequest,
  salePrintCountSuccess,
  salePrintCountFail,
  getSalePrintCountRequest,
  getSalePrintCountSuccess,
  getSalePrintCountFail,
  getSaleDiscountSchemeFail,
  getSaleItemFail,
  getSalePackingTypeDetailSuccess,
  getSalePackingTypeDetailFail,
  getSalePackingTypeDetailRequest,
  getSaleAdditionalChargeRequest,
  getSaleAdditionalChargeSuccess,
  getSaleAdditionalChargeFail,
  getSaleCustomerDetailsSuccess,
  getSaleCustomerDetailsFail,
  getSaleCustomerDetailsRequest,
  getSalePriceSuccess,
  getSalePriceFail,
  getSalePriceRequest,
  getSaleCustomerTypeRequest,
  getSaleCustomerTypeSuccess,
  getSaleCustomerTypeFail,
} from "./saleSlice";
import { mergeMap } from "rxjs";
import {
  createSale,
  getSale,
  // getSaleReferenceRange,
  // getAllTest,
} from "./api";
import { map, filter } from "rxjs/operators";
import { Observable } from "rxjs";
import { Action } from "@reduxjs/toolkit";
import { combineEpics } from "redux-observable";
import {
  alertErrorAction,
  alertSuccessAction,
  closeModal,
} from "../../../CommonAppRedux/CommonAppSlice";
import { getNext, getPrevious } from "../../../CommonAppRedux/api";
import * as API from "./api";
import {
  dispatchAction,
  stateAction,
} from "../../../../AppUtils/Utils/globalTypes";
import messages from "../../../../AppUtils/Utils/validationConstants";
import { clearPaymentDetails } from "../../../CommonAppPages/PaymentModeDetails/Redux/paymentDetailsSlice";
import { getSaleReportItemFail } from "../../Purchase/SaleReport/Redux/saleReportSlice";
// import { filterDataTodayListing } from "../../../../AppUtils/Utils/appFunctions";
export const controller = new AbortController();
// import { searchData } from "../../../CommonAppRedux/commonAppFunctions";
//get Type epic
export const getSaleEpic = (
  action$: Observable<Action>,
  _: stateAction,
  { dispatch }: dispatchAction
) =>
  action$.pipe(
    filter(getSaleRequest.match),
    mergeMap(async (action) => {
      try {
        const response = await getSale(action.payload);
        return { payload: response.data };
      } catch (e) {
        return { error: e };
      }
    }),
    map((action) =>
      action?.payload ? getSaleSuccess(action?.payload) : getSaleFail()
    )
  );

const getSaleNext = (
  action$: Observable<Action>,
  _: stateAction,
  { dispatch }: dispatchAction
) =>
  action$.pipe(
    filter(getSaleNextRequest.match),
    mergeMap(async (action) => {
      try {
        const response = await getNext(action.payload);
        return { payload: response.data };
      } catch (e) {
        return { error: e };
      }
    }),
    map((action) =>
      action?.payload ? getSaleSuccess(action?.payload) : getSaleFail()
    )
  );
const getSalePrevious = (
  action$: Observable<Action>,
  _: stateAction,
  { dispatch }: dispatchAction
) =>
  action$.pipe(
    filter(getSalePreviousRequest.match),
    mergeMap(async (action) => {
      try {
        const response = await getPrevious(action.payload);
        return { payload: response.data };
      } catch (e) {
        return { error: e };
      }
    }),
    map((action) =>
      action?.payload ? getSaleSuccess(action?.payload) : getSaleFail()
    )
  );
const createSaleEpic = (
  action$: Observable<Action>,
  _: stateAction,
  { dispatch }: dispatchAction
) =>
  action$.pipe(
    filter(createSaleRequest.match),
    mergeMap(
      async ({ payload: { values, rowsPerPage, page, saveAndPrint } }) => {
        try {
          const body = JSON.stringify(values);
          const response = await createSale(body);
          if (response) {
            dispatch(getSaleRequest({ rowsPerPage, page }));
            dispatch(alertSuccessAction(messages.createMessage));
            dispatch(clearSaleData());
            !saveAndPrint && dispatch(closeModal());
            dispatch(clearPaymentDetails());
          }
          return { payload: { response } };
        } catch (e) {
          dispatch(alertErrorAction(messages.createFailMessage));
          return { error: e };
        }
      }
    ),
    map((action) => {
      return action?.payload
        ? createSaleSuccess(action?.payload)
        : createSaleFail();
    })
  );

const getSaleCustomerEpic = (
  action$: Observable<Action>,
  _: stateAction,
  { dispatch }: dispatchAction
) =>
  action$.pipe(
    filter(getSaleCustomerRequest.match),
    mergeMap(async (action) => {
      try {
        const response = await API.getSaleCustomer(action.payload);
        return { payload: response.data };
      } catch (e) {
        return { error: e };
      }
    }),
    map((action) =>
      action?.payload
        ? getSaleCustomerSuccess(action?.payload)
        : getSaleCustomerFail()
    )
  );
const getSaleDiscountSchemeEpic = (
  action$: Observable<Action>,
  _: stateAction,
  { dispatch }: dispatchAction
) =>
  action$.pipe(
    filter(getSaleDiscountSchemeRequest.match),
    mergeMap(async (action) => {
      try {
        const response = await API.getSaleDiscountScheme(action.payload);
        return { payload: response.data };
      } catch (e) {
        return { error: e };
      }
    }),
    map((action) =>
      action?.payload
        ? getSaleDiscountSchemeSuccess(action?.payload)
        : getSaleDiscountSchemeFail()
    )
  );

const getSaleItemEpic = (
  action$: Observable<Action>,
  _: stateAction,
  { dispatch }: dispatchAction
) =>
  action$.pipe(
    filter(getSaleItemRequest.match),
    mergeMap(async (action) => {
      try {
        const response = await API.getSaleItem(action.payload);
        return { payload: response.data };
      } catch (e) {
        return { error: e };
      }
    }),
    map((action) =>
      action?.payload ? getSaleItemSuccess(action?.payload) : getSaleItemFail()
    )
  );

//payment mode for sale
//get purchase payment mode
const getSalePaymentModeEpic = (
  action$: Observable<Action>,
  _: stateAction,
  { dispatch }: dispatchAction
) =>
  action$.pipe(
    filter(getSalePaymentModeRequest.match),
    mergeMap(async (action) => {
      try {
        const response = await API.getSalePaymentMode();
        return { payload: response.data };
      } catch (e) {
        return { error: e };
      }
    }),
    map((action) =>
      action?.payload
        ? getSalePaymentModeSuccess(action?.payload)
        : getSalePaymentModeFail()
    )
  );

//view details
const getViewSaleDetails = (
  action$: Observable<Action>,
  _: stateAction,
  { dispatch }: dispatchAction
) =>
  action$.pipe(
    filter(getViewSaleDetailsRequest.match),
    mergeMap(async (action) => {
      try {
        const response = await API.getViewDetails(action.payload);
        return { payload: response.data };
      } catch (e) {
        return { error: e };
      }
    }),
    map((action) =>
      action?.payload
        ? getViewSaleDetailsSuccess(action?.payload)
        : getViewSaleDetailsFail()
    )
  );

// //get next
const getViewSaleDetailsNext = (
  action$: Observable<Action>,
  _: stateAction,
  { dispatch }: dispatchAction
) =>
  action$.pipe(
    filter(getViewSaleDetailsNextRequest.match),
    mergeMap(async (action) => {
      try {
        const response = await getNext(action.payload);
        return { payload: response.data };
      } catch (e) {
        return { error: e };
      }
    }),
    map((action) =>
      action?.payload
        ? getViewSaleDetailsSuccess(action?.payload)
        : getViewSaleDetailsFail()
    )
  );
//get previous
const getViewSaleDetailsPrevious = (
  action$: Observable<Action>,
  _: stateAction,
  { dispatch }: dispatchAction
) =>
  action$.pipe(
    filter(getViewSaleDetailsPreviousRequest.match),
    mergeMap(async (action) => {
      try {
        const response = await getPrevious(action.payload);
        return { payload: response.data };
      } catch (e) {
        return { error: e };
      }
    }),
    map((action) =>
      action?.payload
        ? getViewSaleDetailsSuccess(action?.payload)
        : getViewSaleDetailsFail()
    )
  );

const getPrintSaleDetails = (
  action$: Observable<Action>,
  _: stateAction,
  { dispatch }: dispatchAction
) =>
  action$.pipe(
    filter(getPrintSaleDetailsRequest.match),
    mergeMap(async (action) => {
      try {
        const response = await API.getPrintDetails(action.payload);
        return { payload: response.data };
      } catch (e) {
        return { error: e };
      }
    }),
    map((action) =>
      action?.payload
        ? getPrintSaleDetailsSuccess(action?.payload)
        : getPrintSaleDetailsFail()
    )
  );
// get sale customer details
const getSaleCustomerDetailsEpic = (
  action$: Observable<Action>,
  _: stateAction,
  { dispatch }: dispatchAction
) =>
  action$.pipe(
    filter(getSaleCustomerDetailsRequest.match),
    mergeMap(async (action) => {
      try {
        const response = await API.getPrintCustomerDetails(action?.payload?.id);
        return { payload: response.data };
      } catch (e) {
        return { error: e };
      }
    }),
    map((action) =>
      action?.payload
        ? getSaleCustomerDetailsSuccess(action?.payload)
        : getSaleCustomerDetailsFail()
    )
  );
const saleInvoicePrintCountEpic = (
  action$: Observable<Action>,
  _: stateAction,
  { dispatch }: dispatchAction
) =>
  action$.pipe(
    filter(salePrintCountRequest.match),
    mergeMap(async (action) => {
      try {
        const body = JSON.stringify(action?.payload);
        const response = await API.printCount(body);
        return { payload: response.data };
      } catch (e) {
        return { error: e };
      }
    }),
    map((action) =>
      action?.payload
        ? salePrintCountSuccess(action?.payload)
        : salePrintCountFail()
    )
  );

//get sale packing type detail
const getSalePackingTypeDetailEpic = (
  action$: Observable<Action>,
  _: stateAction,
  { dispatch }: dispatchAction
) =>
  action$.pipe(
    filter(getSalePackingTypeDetailRequest.match),
    mergeMap(async (action) => {
      try {
        const response = await API.getPackingTypeDetail(action.payload);
        return { payload: response.data };
      } catch (e) {
        return { error: e };
      }
    }),
    map((action) =>
      action?.payload
        ? getSalePackingTypeDetailSuccess(action?.payload)
        : getSalePackingTypeDetailFail()
    )
  );
const getSalePrintCountEpic = (
  action$: Observable<Action>,
  _: stateAction,
  { dispatch }: dispatchAction
) =>
  action$.pipe(
    filter(getSalePrintCountRequest.match),
    mergeMap(async (action) => {
      try {
        const response = await API.getSalePrintCount(action.payload);
        return { payload: response.data };
      } catch (e) {
        return { error: e };
      }
    }),
    map((action) =>
      action?.payload
        ? getSalePrintCountSuccess(action?.payload)
        : getSalePrintCountFail()
    )
  );

//additional mode for billing
const getSaleAdditionalChargeEpic = (
  action$: Observable<Action>,
  _: stateAction,
  { dispatch }: dispatchAction
) =>
  action$.pipe(
    filter(getSaleAdditionalChargeRequest.match),
    mergeMap(async (action) => {
      try {
        const response = await API.getSaleAdditionalCharge();
        return { payload: response.data };
      } catch (e) {
        return { error: e };
      }
    }),
    map((action) =>
      action?.payload
        ? getSaleAdditionalChargeSuccess(action?.payload)
        : getSaleAdditionalChargeFail()
    )
  );
//get Sale price
const getSalePriceEpic = (
  action$: Observable<Action>,
  _: stateAction,
  { dispatch }: dispatchAction
) =>
  action$.pipe(
    filter(getSalePriceRequest.match),
    mergeMap(async ({ payload }) => {
      const { customerType, item } = payload;
      try {
        const response = await API.getSalePrice(item, customerType);
        return { payload: response.data };
      } catch (e) {
        return { error: e };
      }
    }),
    map((action) =>
      action?.payload
        ? getSalePriceSuccess(action?.payload)
        : getSalePriceFail()
    )
  );
//get Sale customer type
const getSaleCustomerTypeEpic = (
  action$: Observable<Action>,
  _: stateAction,
  { dispatch }: dispatchAction
) =>
  action$.pipe(
    filter(getSaleCustomerTypeRequest.match),
    mergeMap(async () => {
      try {
        const response = await API.getSaleCustomerType();
        return { payload: response.data };
      } catch (e) {
        return { error: e };
      }
    }),
    map((action) =>
      action?.payload
        ? getSaleCustomerTypeSuccess(action?.payload)
        : getSaleCustomerTypeFail()
    )
  );
export const saleEpics = combineEpics(
  getSaleEpic,
  createSaleEpic,
  getSaleNext,
  getSalePrevious,
  getSaleCustomerEpic,
  getSaleDiscountSchemeEpic,
  getSaleItemEpic,
  //external Epics
  getSalePaymentModeEpic,
  getViewSaleDetailsNext,
  getViewSaleDetailsPrevious,
  getPrintSaleDetails,
  getSalePrintCountEpic,
  saleInvoicePrintCountEpic,
  getViewSaleDetails,
  getSalePackingTypeDetailEpic,
  getSaleAdditionalChargeEpic,
  getSaleCustomerDetailsEpic,
  // sale price
  getSalePriceEpic,
  // sale customer type
  getSaleCustomerTypeEpic
);
