import { createSlice, createAction } from "@reduxjs/toolkit";
import { service, serviceInitialState } from "./types";
const initialState: serviceInitialState = {
  services: [],
  loadingService: false,
  loadingCreateService: false,
  edit: false,
  service: null,
  itemCategories: [],
  loadingItemCategory: false,
  count: 0,
  previous: "",
  next: "",
};

export const Service = createSlice({
  name: "Service Reducer",
  initialState,
  reducers: {
    getServiceRequest: (state, payload) => {
      state.loadingService = true;
    },
    getServiceSuccess: (
      state,
      { payload: { results, count, next, previous } }
    ) => {
      state.loadingService = false;
      state.services = results;
      state.count = count;
      state.next = next;
      state.previous = previous;
    },
    getServiceFail: (state) => {
      state.loadingService = false;
    },
    getServiceNextRequest: (state, payload) => {
      state.loadingService = true;
    },
    getServicePreviousRequest: (state, payload) => {
      state.loadingService = true;
    },
    getServiceItemCategoryRequest: (state, payload) => {
      state.loadingItemCategory = true;
    },
    getServiceItemCategorySuccess: (state, { payload: { results } }) => {
      state.itemCategories = results;
      state.loadingItemCategory = false;
    },
    getServiceItemCategoryFail: (state) => {
      state.loadingItemCategory = false;
    },
    createServiceRequest: (state, payload) => {
      state.loadingCreateService = true;
    },
    createServiceSuccess: (state) => {
      state.loadingCreateService = false;
    },
    createServiceFail: (state) => {
      state.loadingCreateService = false;
    },
    serviceEditSuccess: (state, { payload }) => {
      state.service = payload;
    },
    getServiceByIdRequest: (state, payload) => {
      state.edit = true;
    },
    serviceEditFail: (state) => {
      state.edit = false;
    },
    updateServiceRequest: (state, payload) => {
      state.loadingCreateService = true;
    },
    updateServiceSuccess: (state) => {
      state.loadingCreateService = false;
    },
    updateServiceFail: (state) => {
      state.loadingCreateService = false;
    },
    clearServiceData: (state) => {
      state.edit = false;
      state.service = null;
    },
  },
});
// Action creators are generated for each case reducer function
export const {
  getServiceRequest,
  getServiceSuccess,
  getServiceFail,
  getServiceNextRequest,
  getServicePreviousRequest,
  getServiceItemCategoryRequest,
  getServiceItemCategorySuccess,
  getServiceItemCategoryFail,
  createServiceRequest,
  createServiceSuccess,
  createServiceFail,
  getServiceByIdRequest,
  serviceEditSuccess,
  serviceEditFail,
  updateServiceRequest,
  updateServiceSuccess,
  updateServiceFail,
  clearServiceData,
} = Service.actions;
export default Service.reducer;
