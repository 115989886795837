import { createSlice } from "@reduxjs/toolkit";
const initialState: any = {
  suppliers: [],
  loadingSupplier: false,
  loadingCreateSupplier: false,
  loadingTaxGroup: false,
  supplierTaxGroups: [],
  edit: false,
  supplier: null,
  count: 0,
  previous: "",
  next: "",
  createdSupplierId: "",
};
export const Supplier = createSlice({
  name: "SupplierReducer",
  initialState,
  reducers: {
    loadingSupplier: (state) => {
      state.loadingSupplier = true;
    },
    getSupplierRequest: (state, payload) => {
      state.loadingSupplier = true;
    },
    getSupplierSuccess: (
      state,
      { payload: { results, count, next, previous } }
    ) => {
      state.loadingSupplier = false;
      state.suppliers = results;
      state.count = count;
      state.next = next;
      state.previous = previous;
    },
    getSupplierFail: (state) => {
      state.loadingSupplier = false;
    },
    getSupplierTaxGroupRequest: (state, payload) => {
      state.loadingTaxGroup = true;
    },
    getSupplierTaxGroupSuccess: (state, { payload: { results } }) => {
      state.loadingTaxGroup = false;
      state.supplierTaxGroups = results;
    },
    getSupplierTaxGroupFail: (state) => {
      state.loadingTaxGroup = false;
    },
    getSupplierNextRequest: (state, payload) => {
      state.loadingSupplier = true;
    },
    getSupplierPreviousRequest: (state, payload) => {
      state.loadingSupplier = true;
    },
    createSupplierRequest: (state, payload) => {
      state.loadingCreateSupplier = true;
    },
    createSupplierSuccess: (
      state,
      {
        payload: {
          response: { data },
        },
      }
    ) => {
      state.loadingCreateSupplier = false;
      state.createdSupplierId = data?.id;
    },
    createSupplierFail: (state) => {
      state.loadingCreateSupplier = false;
    },
    supplierEditSuccess: (state, { payload }) => {
      state.edit = true;
      state.supplier = payload;
    },
    supplierEditFail: (state) => {
      state.edit = false;
    },
    updateSupplierRequest: (state, payload) => {
      state.loadingCreateSupplier = true;
    },
    updateSupplierSuccess: (state) => {
      state.loadingCreateSupplier = false;
    },
    updateSupplierFail: (state) => {
      state.loadingCreateSupplier = false;
    },
    clearSupplierData: (state) => {
      state.edit = false;
      state.supplier = null;
    },
    //
    getSupplierByIdRequest: (state, payload) => {
      state.edit = true;
    },
  },
});
// Action creators are generated for each case reducer function
export const {
  loadingSupplier,
  getSupplierRequest,
  getSupplierSuccess,
  getSupplierFail,
  getSupplierNextRequest,
  getSupplierPreviousRequest,
  createSupplierRequest,
  createSupplierSuccess,
  createSupplierFail,
  supplierEditSuccess,
  updateSupplierRequest,
  updateSupplierSuccess,
  updateSupplierFail,
  clearSupplierData,
  getSupplierTaxGroupRequest,
  getSupplierTaxGroupSuccess,
  getSupplierTaxGroupFail,
  getSupplierByIdRequest,
  supplierEditFail
} = Supplier.actions;
export default Supplier.reducer;
