import { filterApiData } from "../../../../../AppUtils/Utils/appFunctions";
import { axiosInstance } from "../../../../../AppUtils/Utils/axios";
import { defaultRowsPerPage } from "../../../../../AppUtils/Utils/globalConstant";
export const testClinicReportModURL =
  "api/v1/clinic-financial-report-app/test-wise-count-report";
// query for report
const getReportQuery = (updatedValue: any) => {
  const { test, customer, user, saleType, startDateAd, endDateAd, payType } =
    updatedValue;
  return `&created_by=${user}&sale_type=${
    saleType === "SALE-RETURN" ? "RETURN" : saleType
  }&customer=${customer}&test=${test ?? ""}&pay_type=${
    payType ?? ""
  }&date_after=${startDateAd}&date_before=${endDateAd}`;
};
export const getTestClinicReportCustomer = (rowsPerPage: number) =>
  axiosInstance.get(
    `${testClinicReportModURL}/customer?offset=0&limit=${rowsPerPage}`
  );
// get user
export const getTestClinicReportUser = () =>
  axiosInstance.get(
    `${testClinicReportModURL}/user?offset=0&limit=${defaultRowsPerPage}`
  );
// get test
export const getClinicSaleReportTest = () =>
  axiosInstance.get(
    `${testClinicReportModURL}/test?offset=0&limit=${defaultRowsPerPage}`
  );
// get all data
export const getTestClinicReportAllReport = (data: any) => {
  const { updatedValue } = data;
  const { summary } = updatedValue;
  return axiosInstance.get(
    `${testClinicReportModURL}/${
      summary ? "summary" : "detail"
    }?offset=0&limit=${0}${
      data?.filterData ? filterApiData(data?.filterData) : ""
    }`
  );
};
export const getTestClinicReportQuickReport = (data: any) => {
  const { rowsPerPage, page, updatedValue } = data;
  const { summary } = updatedValue;
  return axiosInstance.get(
    `${testClinicReportModURL}/${summary ? "summary" : "detail"}?offset=${
      rowsPerPage * page
    }&limit=${rowsPerPage}${
      data?.filterData ? filterApiData(data?.filterData) : ""
    }`
  );
};
export const searchTestClinicReport = ({
  rowsPerPage,
  search,
  updatedValue,
}: any) => {
  const { summary } = updatedValue;
  return `${testClinicReportModURL}/${
    summary ? "summary" : "detail"
  }?offset=0&limit=${rowsPerPage}&search=${search}`;
};
