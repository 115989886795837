// clinic permission constants
export const opdRegistrationPermission = [
  "view_clinic_opd_registration",
  "add_clinic_opd_registration",
  "edit_clinic_opd_registration",
  "print_clinic_opd_registration",
];
export const quickRegistrationPermission = [
  "view_clinic_quick_registration",
  "add_clinic_quick_registration",
  "edit_clinic_quick_registration",
];
export const hospitalReferralPermission = [
  "view_hospital_referral",
  "add_hospital_referral",
  "edit_hospital_referral",
  "print_hospital_referral",
];
export const foreignWorkerPermission = [
  "view_clinic_foreign_worker_registration",
  "add_clinic_foreign_worker_registration",
  "edit_clinic_foreign_worker_registration",
  "print_clinic_foreign_worker_registration",
];
export const followUpPermission = [
  "view_opd_follow_up",
  "add_opd_follow_up",
  "edit_opd_follow_up",
  "print_opd_follow_up",
];
export const billingPermission = [
  "view_clinic_billing",
  "add_clinic_billing",
  "edit_clinic_billing",
  "delete_clinic_billing",
  "print_clinic_billing",
];
export const refundBillingPermission = [
  "view_clinic_refund",
  "add_clinic_refund",
  "update_clinic_refund",
];
export const clinicSaleReportPermission = [
  "view_clinic_sale_report",
  "print_clinic_sale_report",
  "export_pdf_clinic_sale_report",
  "export_excel_clinic_sale_report",
];
export const insClaimPermission = [
  "view_insurance_claim",
  "print_insurance_claim",
  "export_pdf_insurance_claim",
  "export_excel_insurance_claim",
];
export const activityReportPermission = [
  "view_clinic_user_activity_log_report",
  "print_clinic_user_activity_log_report",
  "export_excel_clinic_user_activity_log_report",
  "export_pdf_clinic_user_activity_log_report",
];
export const labMaterializedPermission = [
  "view_clinic_materialized_report",
  "print_clinic_materialized_report",
  "export_pdf_clinic_materialized_report",
  "export_excel_clinic_materialized_report",
];
export const irdSyncPermission = [
  "view_clinic_ird_sync",
  "add_clinic_ird_sync",
];
export const doctorPermission = [
  "view_doctor",
  "add_doctor",
  "edit_doctor",
  "delete_doctor",
];
export const referrerPermission = [
  "view_referrer",
  "add_referrer",
  "update_referrer",
];
export const sitePermission = [
  "view_site_setup",
  "add_site_setup",
  "edit_site_setup",
];
export const referralTypePermission = [
  "view_referral_type",
  "add_referral_type",
  "edit_referral_type",
];
export const appointmentTypePermission = [
  "view_appointment_type",
  "add_appointment_type",
  "edit_appointment_type",
];
export const relationPermission = [
  "view_relation",
  "add_relation",
  "edit_relation",
  "delete_relation",
];
export const jobPostPermission = [
  "view_job_setup",
  "add_job_setup",
  "edit_job_setup",
  "delete_job_setup",
];
export const recruitmentPermission = [
  "view_recruitment_agency",
  "add_recruitment_agency",
  "edit_recruitment_agency",
  "delete_recruitment_agency",
];
export const appointmentPermission = [
  "view_appointment",
  "add_appointment",
  "update_appointment",
];
export const patientDetailsPermission = ["view_patient_details"];
export const clinicSetupPermission = [
  "view_clinic_setup",
  "add_clinic_setup",
  "update_clinic_setup",
];
export const appointmentRoutinePermission = [
  "view_appointment_routine",
  "add_appointment_routine",
  "update_appointment_routine",
];
export const referralEditPermission = [
  "view_clinic_sale_referrer",
  "edit_clinic_sale_referrer",
];
export const clinicDepartmentWiseSaleReportPermission = [
  "view_clinic_department_wise_sale_report",
  "print_clinic_department_wise_sale_report",
  "export_pdf_clinic_department_wise_sale_report",
  "export_excel_clinic_department_wise_sale_report",
];
export const clinicPatientWiseReportPermission = [
  "view_clinic_patient_wise_revenue_report",
  "print_clinic_patient_wise_revenue_report",
  "export_pdf_clinic_patient_wise_revenue_report",
  "excel_clinic_patient_wise_revenue_report",
];

export const clinicSaleTestReportPermission = [
  "export_excel_clinic_test_wise_revenue_report",
  "export_pdf_clinic_test_wise_revenue_report",
  "view_clinic_test_wise_revenue_report",
  "print_clinic_test_wise_revenue_report",
];

export const clinicTotalPatientReportPermission = [
  "export_excel_clinic_patient_stat_report",
  "export_pdf_clinic_patient_stat_report",
  "print_clinic_patient_stat_report",
  "view_clinic_patient_stat_report",
];
export const clinicPatientListReportPermission = [
  "export_pdf_clinic_patient_list_report",
  "export_excel_clinic_patient_list_report",
  "print_clinic_patient_list_report",
  "view_clinic_patient_list_report",
];

export const clinicOrderPermission = ["view_clinic_order", "edit_clinic_order"];
export const moldOrderPermission = ["view_mold_order", "edit_mold_order"];

export const contactRequestPermission = ["view_mold_order", "edit_mold_order"];

export const moldOrderAcceptPermission = [
  "view_clinic_setup",
  "add_clinic_setup",
  "update_clinic_setup",
];

export const serviceOrderAcceptPermission = [
  "view_clinic_setup",
  "add_clinic_setup",
  "update_clinic_setup",
];
export const deviceOrderAcceptPermission = [
  "view_clinic_setup",
  "add_clinic_setup",
  "update_clinic_setup",
];

export const repairOrderAcceptPermission = [
  "view_repair_order",
  "add_repair_order",
  "update_repair_order",
];

export const moldTypePermission = [
  "view_mold_type",
  "add_mold_type",
  "edit_mold_type",
];

export const depositPermission = [
  "view_deposit",
  "add_deposit",
  "edit_deposit",
];

export const depositReturnPermission = [
  "view_deposit_return",
  "add_deposit_return",
  "edit_deposit_return",
];

export const deviceRepairSpecialistPermission = [
  "view_device_repair_specialist",
  "add_device_repair_specialist",
  "edit_device_repair_specialist",
];

export const clinicCreditSalesInfoPermission = [
  "view_clinic_credit_sales_info",
];
