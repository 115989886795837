import {
  getDeviceRepairSpecialistRequest,
  getDeviceRepairSpecialistFail,
  getDeviceRepairSpecialistSuccess,
  createDeviceRepairSpecialistFail,
  createDeviceRepairSpecialistRequest,
  createDeviceRepairSpecialistSuccess,
  updateDeviceRepairSpecialistFail,
  updateDeviceRepairSpecialistSuccess,
  clearAllData,
  getDeviceRepairSpecialistNextRequest,
  getDeviceRepairSpecialistPreviousRequest,
  updateDeviceRepairSpecialistRequest,
  getDeviceRepairSpecialistByIdRequest,
  deviceRepairSpecialistEditSuccess,
  deviceRepairSpecialistEditFail,
} from "./DeviceRepairSpecialistSlice";
import { mergeMap } from "rxjs";
import { getDeviceRepairSpecialist, createDeviceRepairSpecialist, updateDeviceRepairSpecialist, getDeviceRepairSpecialistById } from "./api";
import { map, filter } from "rxjs/operators";
import { Observable } from "rxjs";
import { Action } from "@reduxjs/toolkit";
import { combineEpics } from "redux-observable";
import {
  alertErrorAction,
  alertSuccessAction,
  closeModal,
} from "../../../CommonAppRedux/CommonAppSlice";
import { getNext, getPrevious } from "../../../CommonAppRedux/api";
import {
  dispatchAction,
  stateAction,
} from "../../../../AppUtils/Utils/globalTypes";
import messages from "../../../../AppUtils/Utils/validationConstants";
export const controller = new AbortController();

//get DeviceRepairSpecialist
const getDeviceRepairSpecialistEpic = (
  action$: Observable<Action>,
  _: stateAction,
  { dispatch }: dispatchAction
) =>
  action$.pipe(
    filter(getDeviceRepairSpecialistRequest.match),
    mergeMap(async (action: any) => {
      try {
        const response = await getDeviceRepairSpecialist(action.payload);
        return { payload: response.data };
      } catch (e) {
        return { error: e };
      }
    }),
    map((action) =>
      action?.payload ? getDeviceRepairSpecialistSuccess(action?.payload) : getDeviceRepairSpecialistFail()
    )
  );

//get deviceRepairSpecialist by id epic
const getDeviceRepairSpecialistByIdEpic = (
  action$: Observable<Action>,
  _: stateAction,
  { dispatch }: dispatchAction
) =>
  action$.pipe(
    filter(getDeviceRepairSpecialistByIdRequest.match),
    mergeMap(async (action) => {
      try {
        const response = await getDeviceRepairSpecialistById(action.payload);
        return { payload: response.data };
      } catch (e) {
        return { error: e };
      }
    }),
    map((action) =>
      action?.payload ? deviceRepairSpecialistEditSuccess(action.payload) : deviceRepairSpecialistEditFail()
    )
  );

//create
const createDeviceRepairSpecialistEpic = (
  action$: Observable<Action>,
  _: stateAction,
  { dispatch }: dispatchAction
) =>
  action$.pipe(
    filter(createDeviceRepairSpecialistRequest.match),
    mergeMap(async ({ payload: { values, rowsPerPage, page } }) => {
      try {
        const body = new FormData();
        for (let [key, value] of Object.entries(values)) {
          body.append(`${key}`, String(value));
        }
        const response = await createDeviceRepairSpecialist(body);
        if (response) {
          dispatch(getDeviceRepairSpecialistRequest({ rowsPerPage, page }));
          dispatch(alertSuccessAction(messages.createMessage));
          dispatch(closeModal());
        }
        return { payload: { response } };
      } catch (e) {
        dispatch(alertErrorAction(messages.createFailMessage));
        return { error: e };
      }
    }),
    map((action) => {
      return action?.payload ? createDeviceRepairSpecialistSuccess() : createDeviceRepairSpecialistFail();
    })
  );

//update
const updateDeviceRepairSpecialistEpic = (
  action$: Observable<Action>,
  _: stateAction,
  { dispatch }: dispatchAction
) =>
  action$.pipe(
    filter(updateDeviceRepairSpecialistRequest.match),
    mergeMap(async ({ payload: { values, id, rowsPerPage, page, search } }) => {
      try {
        const body = new FormData();
        for (let [key, value] of Object.entries(values)) {
          body.append(`${key}`, String(value));
        }
        const response = await updateDeviceRepairSpecialist(body, id);
        if (response) {
          dispatch(getDeviceRepairSpecialistRequest({ rowsPerPage, page, search }));
          dispatch(alertSuccessAction(messages.updateMessage));
          dispatch(closeModal());
          dispatch(clearAllData());
        }
        return { payload: { response, rowsPerPage } };
      } catch (e) {
        dispatch(alertErrorAction(messages.updateFailMessage));
        return { error: e };
      }
    }),
    map((action) => {
      return action?.payload ? updateDeviceRepairSpecialistSuccess() : updateDeviceRepairSpecialistFail();
    })
  );

//get next
const getDeviceRepairSpecialistNext = (
  action$: Observable<Action>,
  _: stateAction,
  { dispatch }: dispatchAction
) =>
  action$.pipe(
    filter(getDeviceRepairSpecialistNextRequest.match),
    mergeMap(async (action) => {
      try {
        const response = await getNext(action.payload);
        return { payload: response.data };
      } catch (e) {
        return { error: e };
      }
    }),
    map((action) => {
      return action?.payload ? getDeviceRepairSpecialistSuccess(action?.payload) : getDeviceRepairSpecialistFail();
    })
  );

//get previous
const getDeviceRepairSpecialistPrevious = (
  action$: Observable<Action>,
  _: stateAction,
  { dispatch }: dispatchAction
) =>
  action$.pipe(
    filter(getDeviceRepairSpecialistPreviousRequest.match),
    mergeMap(async (action) => {
      try {
        const response = await getPrevious(action.payload);
        return { payload: response.data };
      } catch (e) {
        return { error: e };
      }
    }),
    map((action) =>
      action?.payload ? getDeviceRepairSpecialistSuccess(action?.payload) : getDeviceRepairSpecialistFail()
    )
  );

export const deviceRepairSpecialistEpic = combineEpics(
  getDeviceRepairSpecialistEpic,
  createDeviceRepairSpecialistEpic,
  updateDeviceRepairSpecialistEpic,
  getDeviceRepairSpecialistNext,
  getDeviceRepairSpecialistPrevious,
  getDeviceRepairSpecialistByIdEpic
);
