import { Grid, TextField } from "@mui/material";
import LocalizationProvider from "@mui/lab/LocalizationProvider";
import AdapterDateFns from "@mui/lab/AdapterDateFns";
import { DesktopDatePicker } from "@mui/lab";
import TextError from "../../TextError/TextError";
import { ErrorMessage } from "formik";
import { handleFocus } from "../../../AppUtils/FocusElement/focusElement";
import { commonAppSelector } from "../../../Pages/CommonAppRedux/selector";
import { useAppSelector } from "../../../AppUtils/Utils/appHooks";
import { memo } from "react";
import { appDatePickerProps } from "./types";
import "./datePicker.css";
const AppDatePicker = ({
    name,
    value,
    inputRef,
    onChange,
    focusElement,
    required,
    xs,
    sm,
    lg,
    md,
    label,
    applyMarginTop,
    isNotFormik,
    onKeyPress,
    views,
    disabled,
    id,
    className,
    viewWithTime,
}: appDatePickerProps) => {
    //handle focus birth date
    const handleFocusElement = (e: React.KeyboardEvent<HTMLDivElement>) =>
        handleFocus(focusElement, e, required && value);

    const { globalOrganizationRules } = useAppSelector(commonAppSelector);
    let inputFormat =
        `${globalOrganizationRules?.dateFormat}` === "YYYY-MM-DD"
            ? "yyyy-MM-dd"
            : "dd-MM-yyyy";
    let inputFormatWithTime =
        `${globalOrganizationRules?.dateFormat}` === "YYYY-MM-DD"
            ? "yyyy-MM-dd hh:mm a"
            : "dd-MM-yyyy hh:mm a";
    let maskInput =
        `${globalOrganizationRules?.dateFormat}` === "YYYY-MM-DD"
            ? "____-__-__"
            : "__-__-____";
    let maskInputWithTime =
        `${globalOrganizationRules?.dateFormat}` === "YYYY-MM-DD"
            ? "____-__-__ __:__ _M"
            : "__-__-____ __:__ _M";
    const firstDateValue = new Date(1930, 0, 1);
    const lastDateValue = new Date();
    return (
        <>
            <LocalizationProvider dateAdapter={AdapterDateFns}>
                <Grid
                    item
                    xs={xs}
                    sm={sm}
                    lg={lg}
                    md={md}
                    className={className ? className : "date-textfield-wrapper"}
                    id="user-date-picker-wrapper"
                    mt={applyMarginTop ? 1 : ""}
                >
                    <DesktopDatePicker
                        // minDate={firstDateValue}
                        // maxDate={lastDateValue}
                        inputFormat={
                            views
                                ? "yyyy-MM"
                                : viewWithTime
                                ? inputFormatWithTime
                                : inputFormat
                        }
                        mask={
                            views
                                ? "____-__"
                                : viewWithTime
                                ? maskInputWithTime
                                : maskInput
                        }
                        views={
                            views ? ["year", "month"] : ["year", "month", "day"]
                        }
                        disabled={disabled}
                        value={value === "" ? null : value}
                        onChange={onChange}
                        inputRef={inputRef}
                        renderInput={(params) => (
                            <TextField
                                {...params}
                                InputLabelProps={{ shrink: true }}
                                label={`${label} (${globalOrganizationRules?.dateFormat})`}
                                onKeyDown={onKeyPress || handleFocusElement}
                                name={name}
                                required={required}
                                variant="outlined"
                                fullWidth
                            />
                        )}
                        disableMaskedInput={false}
                    />
                    {!isNotFormik && (
                        <ErrorMessage name={name} component={TextError} />
                    )}
                </Grid>
            </LocalizationProvider>
        </>
    );
};

export default memo(AppDatePicker);
