import { MenuItem } from "@mui/material";
import React, { useState, useEffect, Suspense, lazy } from "react";
import AppBar from "@mui/material/AppBar";
import Toolbar from "@mui/material/Toolbar";
import { useDispatch } from "react-redux";
import Grid from "@mui/material/Grid";
import "./header.css";
import { Badge, IconButton, Menu, Typography } from "@mui/material";
import PowerSettingsNewIcon from "@mui/icons-material/PowerSettingsNew";
import { styled } from "@mui/material/styles";
import ShortcutIcon from "@mui/icons-material/Shortcut";
import AddShoppingCartIcon from "@mui/icons-material/AddShoppingCart";
import Tooltip, { TooltipProps, tooltipClasses } from "@mui/material/Tooltip";
import { Link, useHistory } from "react-router-dom";
import NotificationsIcon from "@mui/icons-material/Notifications";
import { AccountCircle } from "@mui/icons-material";
import PersonIcon from "@mui/icons-material/Person";
import FullscreenIcon from "@mui/icons-material/Fullscreen";
import FullscreenExitIcon from "@mui/icons-material/FullscreenExit";
import KeyIcon from "@mui/icons-material/Key";
import { headerStyles } from "./HeaderStyles";
import { loginFail } from "../../../Pages/Auth/Redux/authSlice";
import TooltipContent from "../../Tooltip/TooltipContent";
import { loginSelector } from "../../../Pages/Auth/Redux/selector";
import { useAppSelector } from "../../../AppUtils/Utils/appHooks";
import clinicSVG from "../../../Assets/icons/AppBarIcons/Clinic.svg";
import clinicWhiteSVG from "../../../Assets/icons/AppBarIcons/ClinicWhite.svg";
import SVGImage from "../../AppElements/AppSVG/SVGImage";
import InventoryIcon from "@mui/icons-material/Inventory";
import MarkEmailReadIcon from "@mui/icons-material/MarkEmailRead";
import AdminPanelSettingsIcon from "@mui/icons-material/AdminPanelSettings";
import ScienceIcon from "@mui/icons-material/Science";
import { userSelector } from "../../../Pages/CoreSetupPages/User/Redux/selector";

const ShortCutKeyTable = lazy(() => import("../../ShortCutKey"));
const OrderReminderListing = lazy(() => import("../../OrderReminderList"));
const Modal = lazy(() => import("../../Modal"));

const FixedAppBar = ({ openSidebar, setOpenSidebar }: any) => {
  const dispatch = useDispatch();
  const classes = headerStyles();
  let history = useHistory();
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const [showModalShortcut, setShowModalShortcut] = useState(false);
  const [showOrderModal, setShowOrderModal] = useState(false);
  const { permissions, isSuperuser } = useAppSelector(loginSelector);
  const { loginUser } = useAppSelector(userSelector);

  const showCoreSetup = permissions?.some(
    (permission: any) => permission?.mainModule === 1
  );
  const showInventory = permissions?.some(
    (permission: any) => permission?.mainModule === 2
  );
  const showClinic = permissions?.some(
    (permission: any) => permission?.mainModule === 3
  );
  const showLab = permissions?.some(
    (permission: any) => permission?.mainModule === 4
  );

  // state to handle fullscreen
  const [exit, setExit] = useState(false);
  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event?.currentTarget);
  };
  const { openMasterSidebar, openInvSidebar } = openSidebar;
  const handleClose = () => {
    setAnchorEl(null);
  };
  const handleProfile = () => {
    setAnchorEl(null);
  };
  const handleShortcut = () => {
    setShowModalShortcut(true);
  };

  //handle Full screen
  const handleFullScreen = () => {
    setExit((prev) => !prev);
    if (document.fullscreenElement) {
      document.exitFullscreen();
    } else {
      document.documentElement.requestFullscreen();
    }
  };

  const HtmlTooltip = styled(({ className, ...props }: TooltipProps) => (
    <Tooltip {...props} classes={{ popper: className }} />
  ))(({ theme }) => ({
    [`& .${tooltipClasses.tooltip}`]: {
      backgroundColor: "#f5f5f9",
      color: "rgba(0, 0, 0, 0.87)",
      maxWidth: 220,
      fontSize: theme.typography.pxToRem(12),
      border: "1px solid #dadde9",
    },
  }));

  const [active, setActive] = useState<any>("core-setup");
  const [showModal, setShowModal] = useState(false);

  //set the active field
  useEffect(() => {
    if (openMasterSidebar) {
      setActive("core-setup");
    } else if (openInvSidebar) {
      setActive("inventory");
    }
  }, [openMasterSidebar, openInvSidebar]);
  const [keyPressed, setKeyPressed] = useState(false);

  //runs whenever the history.location.pathname property changes
  useEffect(() => {
    localStorage?.setItem(
      "activeSidebar",
      `${history?.location?.pathname.split("/")[1]}`
    );

    // function will be called whenever the visibilitychange event fires
    const handleVisibilityChange = () => {
      localStorage?.setItem(
        "activeSidebar",
        `${history?.location?.pathname.split("/")[1]}`
      );
      // sets the active state to the current path name
      setActive(`${history?.location?.pathname.split("/")[1]}`);
    };
    document.addEventListener("visibilitychange", handleVisibilityChange); //adds an event listener to track tab changes.
    return () => {
      document.removeEventListener("visibilitychange", handleVisibilityChange);
    };
  }, [history?.location?.pathname]);

  useEffect(() => {
    const handleKeyPress = (event: any) => {
      if (event.ctrlKey && event.code === "KeyI") {
        setKeyPressed(true);
        setShowModal(true);
      }
    };
    //add event if key press is false
    !keyPressed &&
      window.addEventListener("keypress", handleKeyPress, {
        passive: true,
      });
    return () => {
      window.removeEventListener("keypress", handleKeyPress);
    };
  }, [keyPressed]);

  useEffect(() => {
    const activeTab = localStorage.getItem("activeSidebar");
    if (showModal === false) {
      setKeyPressed(false);
      setActive(activeTab);
    }
  }, [showModal]);

  const handleLogout = () => {
    setAnchorEl(null);
    dispatch(loginFail());
  };

  const handleOrders = () => {
    setShowOrderModal(true);
  };

  return (
    <>
      <AppBar position="fixed" className={classes.fixedHeader}>
        <Grid
          container
          spacing={2}
          sx={{
            display: "flex",
            flexDirection: "row",
            justifyContent: "center",
            alignItems: "center",
            position: "relative",
          }}
        >
          <Grid item>
            <Toolbar disableGutters>
              <Grid style={{ width: "100%" }} justifyContent="center">
                <Link to="/clinic">
                  {(isSuperuser || showClinic) && (
                    <>
                      <button
                        className={`fixed-header-button ${
                          active === "clinic" ? "activeButton" : ""
                        }`}
                        onClick={() => {
                          setActive("clinic");
                          localStorage?.setItem("activeSidebar", "clinic");
                          setOpenSidebar({
                            openClinicSidebar: true,
                            openLabSidebar: false,
                            openInvSidebar: false,
                            openMasterSidebar: false,
                          });
                        }}
                      >
                        <Grid container alignItems="center" spacing={0.6}>
                          <Grid item>
                            <SVGImage
                              src={
                                active !== "clinic" ? clinicWhiteSVG : clinicSVG
                              }
                            />
                            {/* <ScienceIcon /> */}
                          </Grid>
                          <Grid item>
                            <span>Clinic</span>
                          </Grid>
                        </Grid>
                      </button>
                    </>
                  )}
                </Link>

                {(isSuperuser || showLab) && (
                  <>
                    <Link to="/lab">
                      <button
                        className={`fixed-header-button ${
                          active === "lab" ? "activeButton" : ""
                        }`}
                        onClick={() => {
                          setActive("lab");
                          localStorage?.setItem("activeSidebar", "lab");
                          setOpenSidebar({
                            openLabSidebar: true,
                            openInvSidebar: false,
                            openMasterSidebar: false,
                          });
                        }}
                      >
                        <Grid container alignItems="center" spacing={0.6}>
                          <Grid item>
                            {/* <SVGImage src={labSVG} /> */}
                            <ScienceIcon />
                          </Grid>
                          <Grid item>
                            <span>Lab</span>
                          </Grid>
                        </Grid>
                      </button>
                    </Link>
                  </>
                )}
                {(isSuperuser || showInventory) && (
                  <>
                    <Link to="/inventory">
                      <button
                        className={`fixed-header-button ${
                          active === "inventory" ? "activeButton" : ""
                        }`}
                        onClick={() => {
                          setActive("inventory");
                          localStorage?.setItem("activeSidebar", "inventory");
                          setOpenSidebar({
                            openInvSidebar: true,
                            openMasterSidebar: false,
                          });
                        }}
                      >
                        <Grid container alignItems="center" spacing={0.6}>
                          <Grid item>
                            <InventoryIcon />
                          </Grid>
                          <Grid item>
                            <span>Inventory</span>
                          </Grid>
                        </Grid>
                      </button>
                    </Link>
                  </>
                )}
                {(isSuperuser || showCoreSetup) && (
                  <>
                    <Link to="/core-setup">
                      <button
                        className={`fixed-header-button ${
                          active === "core-setup" ? "activeButton" : ""
                        }`}
                        onClick={() => {
                          setActive("core-setup");
                          localStorage?.setItem("activeSidebar", "core-setup");
                          // setOpenLabSideBar(false);
                          // setOpenPharmacySidebar(false);
                          // setOpenMasterSidebar(true);
                          // setOpenInvSidebar(false);
                          setOpenSidebar({
                            openInvSidebar: false,
                            openMasterSidebar: true,
                          });
                        }}
                      >
                        <Grid container alignItems="center" spacing={0.6}>
                          <Grid item>
                            <AdminPanelSettingsIcon />
                          </Grid>
                          <Grid item>
                            <span>Core Setup</span>
                          </Grid>
                        </Grid>
                      </button>
                    </Link>
                  </>
                )}
              </Grid>
            </Toolbar>
          </Grid>
          <Grid item className="fixedHeaderIcons">
            <Toolbar disableGutters>
              <Grid
                container
                spacing={1}
                direction="row"
                justifyContent="center"
                alignItems="center"
              >
                <Grid item>
                  <IconButton
                    color="inherit"
                    className={classes.badgeIcons}
                    onClick={handleOrders}
                  >
                    <Badge badgeContent={0} color="secondary">
                      <TooltipContent title={"Orders"}>
                        <AddShoppingCartIcon
                          className={classes.fixedHeaderIconsButton}
                          fontSize="large"
                        />
                      </TooltipContent>
                    </Badge>
                  </IconButton>
                </Grid>
                <Grid item>
                  <HtmlTooltip
                    title={
                      <React.Fragment>
                        <Typography color="inherit">
                          ShortCut Keys For System
                        </Typography>
                        <em>{"We Added All Required"}</em>
                        <em>{"It's very Convenience For You"}</em>{" "}
                      </React.Fragment>
                    }
                  >
                    <IconButton
                      color="inherit"
                      className={classes.badgeIcons}
                      onClick={handleShortcut}
                    >
                      <Badge badgeContent={0} color="secondary">
                        <ShortcutIcon
                          className={classes.fixedHeaderIconsButton}
                          fontSize="large"
                        />
                      </Badge>
                    </IconButton>
                  </HtmlTooltip>
                </Grid>
                <Grid item>
                  <IconButton
                    color="inherit"
                    className={classes.badgeIcons}
                    onClick={handleFullScreen}
                  >
                    <Badge badgeContent={0} color="secondary">
                      <TooltipContent
                        title={exit ? "Exit Full Screen" : "Full Screen"}
                      >
                        {exit ? (
                          <FullscreenExitIcon
                            className={classes.fixedHeaderIconsButton}
                          />
                        ) : (
                          <FullscreenIcon
                            className={classes.fixedHeaderIconsButton}
                          />
                        )}
                      </TooltipContent>
                    </Badge>
                  </IconButton>
                </Grid>
                <Grid item>
                  <IconButton color="inherit" className={classes.badgeIcons}>
                    <Badge badgeContent={0} color="secondary">
                      <TooltipContent title={"Upcoming Featured"}>
                        <NotificationsIcon
                          className={classes.fixedHeaderIconsButton}
                          fontSize="large"
                        />
                      </TooltipContent>
                    </Badge>
                  </IconButton>
                </Grid>
                <Grid item>
                  <IconButton
                    color="inherit"
                    aria-controls="simple-menu"
                    aria-haspopup="true"
                    onClick={handleClick}
                    className={classes.badgeIcons}
                  >
                    <AccountCircle
                      className={classes.fixedHeaderIconsButton}
                      fontSize="large"
                    />
                  </IconButton>
                </Grid>
                <Menu
                  id="simple-menu"
                  anchorEl={anchorEl}
                  keepMounted
                  open={Boolean(anchorEl)}
                  onClose={handleClose}
                >
                  <Link to="/profile" className={classes.menuLink}>
                    <MenuItem onClick={handleProfile}>
                      <PersonIcon color="primary" />
                      &nbsp; Profile
                    </MenuItem>
                  </Link>
                  <Link to="/change-password" className={classes.menuLink}>
                    <MenuItem onClick={handleClose}>
                      <KeyIcon color="primary" />
                      &nbsp; Change Password
                    </MenuItem>
                  </Link>
                  {!loginUser?.isEmailVerified && (
                    <Link to="/verify-email" className={classes.menuLink}>
                      <MenuItem onClick={handleClose}>
                        <MarkEmailReadIcon color="primary" />
                        &nbsp; Verify Email
                      </MenuItem>
                    </Link>
                  )}
                  <Link to="/" className={classes.menuLink}>
                    <MenuItem onClick={handleLogout}>
                      <PowerSettingsNewIcon color="primary" />
                      &nbsp; Logout
                    </MenuItem>
                  </Link>
                </Menu>
              </Grid>
            </Toolbar>
          </Grid>
        </Grid>
      </AppBar>
      <>
        {showModalShortcut && (
          <Suspense fallback={<></>}>
            <Modal
              showModal={showModalShortcut}
              setShowModal={setShowModalShortcut}
              types="shortCutKey"
              title="Shortcut Key List"
              maxWidth="lg"
              displayTitleOnly={true}
            >
              <ShortCutKeyTable />
            </Modal>
          </Suspense>
        )}
        {showOrderModal && (
          <Suspense fallback={<></>}>
            <Modal
              showModal={showOrderModal}
              setShowModal={setShowOrderModal}
              types="ordersKey"
              title="Orders Reminder List"
              maxWidth="lg"
              displayTitleOnly={true}
            >
              <OrderReminderListing />
            </Modal>
          </Suspense>
        )}
      </>
    </>
  );
};

export default FixedAppBar;
