import { createSlice } from "@reduxjs/toolkit";
import { saleClinicReportInitialState } from "./types";
const initialState: saleClinicReportInitialState = {
  customers: [],
  users: [],
  tests: [],
  saleDetails: [],
  //loading
  loadingGenerateReport: false,
  loadingSaleClinicReportCustomer: false,
  loadingSaleClinicReportTest: false,
  loadingSaleClinicReportUser: false,
  count: 0,
  previous: "",
  next: "",
};

// export const getSaleClinicReportDetailRequest = createAction(
//   "getSaleClinicReportDetailRequest",
//   function prepare({ detailsRowsPerPage, page }) {
//     return {
//       payload: {
//         detailsRowsPerPage,
//         page,
//       },
//     };
//   }
// );
export const SaleClinicReport = createSlice({
  name: "SaleClinicReportReducer",
  initialState,
  reducers: {
    //loading
    loadingSaleClinicReportDetail: (state) => {
      state.loadingGenerateReport = true;
    },
    //external reducer request
    getSaleClinicReportDetailRequest: (state, { payload }) => {
      state.loadingGenerateReport = true;
    },
    getSaleClinicReportCustomerRequest: (state, payload) => {
      state.loadingSaleClinicReportCustomer = true;
    },
    getSaleClinicReportUserRequest: (state) => {
      state.loadingSaleClinicReportUser = true;
    },
    getSaleClinicReportAllSummaryRequest: (state, { payload }) => {
      state.loadingGenerateReport = true;
    },
    getSaleClinicReportAllDetailRequest: (state, { payload }) => {
      state.loadingGenerateReport = true;
    },
    getSaleClinicReportQuickSummaryRequest: (state, { payload }) => {
      state.loadingGenerateReport = true;
    },
    getSaleClinicReportQuickDetailRequest: (state, { payload }) => {
      state.loadingGenerateReport = true;
    },
    getSaleClinicReportNextRequest: (state, payload) => {
      state.loadingGenerateReport = true;
    },
    getSaleClinicReportPreviousRequest: (state, payload) => {
      state.loadingGenerateReport = true;
    },
    loadingSaleClinicReportCustomerSearch: (state) => {
      state.loadingSaleClinicReportCustomer = true;
    },

    //external get reducer
    getSaleClinicReportDetailSuccess: (
      state,
      { payload: { results, count, next, previous } }
    ) => {
      state.saleDetails = results;
      state.loadingGenerateReport = false;
      state.count = count;
      state.next = next;
      state.previous = previous;
    },
    getSaleClinicReportDetailFail: (state) => {
      state.loadingGenerateReport = false;
    },
    // user
    loadingSaleClinicReportUserSearch: (state) => {
      state.loadingSaleClinicReportUser = true;
    },
    getSaleClinicReportUserSuccess: (state, { payload: { results } }) => {
      state.loadingSaleClinicReportUser = false;
      state.users = results;
    },
    getSaleClinicReportUserFail: (state) => {
      state.loadingSaleClinicReportUser = false;
    },
    getSaleClinicReportCustomerSuccess: (state, { payload: { results } }) => {
      state.loadingSaleClinicReportCustomer = false;
      state.customers = results;
    },
    getSaleClinicReportCustomerFail: (state) => {
      state.loadingSaleClinicReportCustomer = false;
    },
    // get test
    loadingClinicReportTest: (state) => {
      state.loadingSaleClinicReportTest = true;
    },
    getSaleClinicReportTestRequest: (state) => {
      state.loadingSaleClinicReportTest = true;
    },
    getClinicSaleReportTestSuccess: (state, { payload: { results } }) => {
      state.loadingSaleClinicReportTest = false;
      state.tests = results;
    },
    getClinicSaleReportTestFail: (state) => {
      state.loadingSaleClinicReportTest = false;
    },
    getSaleClinicReportAllSummarySuccess: (state, { payload: { results } }) => {
      state.loadingGenerateReport = false;
      state.saleDetails = results;
    },
    getSaleClinicReportAllSummaryFail: (state) => {
      state.loadingGenerateReport = false;
    },
    getSaleClinicReportQuickSummarySuccess: (
      state,
      { payload: { results, count, next, previous } }
    ) => {
      state.loadingGenerateReport = false;
      state.saleDetails = results;
      state.count = count;
      state.next = next;
      state.previous = previous;
    },
    getSaleClinicReportQuickSummaryFail: (state) => {
      state.loadingGenerateReport = false;
    },
    getSaleClinicReportAllDetailSuccess: (state, { payload: { results } }) => {
      state.loadingGenerateReport = false;
      state.saleDetails = results;
    },
    getSaleClinicReportAllDetailFail: (state) => {
      state.loadingGenerateReport = false;
    },
    getSaleClinicReportQuickDetailSuccess: (
      state,
      { payload: { results, count, next, previous } }
    ) => {
      state.loadingGenerateReport = false;
      state.saleDetails = results;
      state.count = count;
      state.next = next;
      state.previous = previous;
    },
    getSaleClinicReportQuickDetailFail: (state) => {
      state.loadingGenerateReport = false;
    },
    clearSaleClinicReportData: (state) => {
      state.customers = [];
      state.users = [];
      state.tests = [];
    },
    clearSaleDetails: (state) => {
      state.saleDetails = [];
    },
  },
});
// Action creators are generated for each case reducer function
export const {
  //reducerReport
  getSaleClinicReportDetailRequest,
  getSaleClinicReportCustomerRequest,
  getSaleClinicReportTestRequest,
  getSaleClinicReportUserRequest,
  getSaleClinicReportAllSummaryRequest,
  getSaleClinicReportAllDetailRequest,
  getSaleClinicReportQuickSummaryRequest,
  getSaleClinicReportQuickDetailRequest,
  getSaleClinicReportPreviousRequest,
  getSaleClinicReportNextRequest,
  loadingSaleClinicReportCustomerSearch,
  //reducer get data
  getSaleClinicReportDetailSuccess,
  getSaleClinicReportDetailFail,
  // test
  loadingClinicReportTest,
  getClinicSaleReportTestSuccess,
  getClinicSaleReportTestFail,
  getSaleClinicReportCustomerSuccess,
  getSaleClinicReportCustomerFail,
  // user
  getSaleClinicReportUserSuccess,
  loadingSaleClinicReportUserSearch,
  getSaleClinicReportUserFail,
  getSaleClinicReportQuickDetailSuccess,
  getSaleClinicReportQuickDetailFail,
  getSaleClinicReportAllDetailSuccess,
  getSaleClinicReportAllDetailFail,
  getSaleClinicReportQuickSummarySuccess,
  getSaleClinicReportQuickSummaryFail,
  getSaleClinicReportAllSummarySuccess,
  getSaleClinicReportAllSummaryFail,
  //loading
  loadingSaleClinicReportDetail,
  //clear
  clearSaleClinicReportData,
  clearSaleDetails,
} = SaleClinicReport.actions;
export default SaleClinicReport.reducer;
