// import { axiosInstance } from "../../../AppUtils/Utils/axios";

import { axiosInstance } from "../../../../AppUtils/Utils/axios";

const passwordModURL = "api/v1/user-app";

//for reset password
export const resetPassword = (body: string) =>
  axiosInstance.post(`${passwordModURL}/password-reset/`, body);
//confirm reset password
export const confirmPassword = (body: string) =>
  axiosInstance.post(`${passwordModURL}/password-reset/confirm/`, body);
//change password
export const changePassword = (body: FormData | string, id: number) =>
  axiosInstance.patch(`${passwordModURL}/change-password/${id}`, body);
