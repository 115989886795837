import { Switch } from "react-router-dom";
import {
  activityReportPermission,
  appointmentPermission,
  appointmentRoutinePermission,
  appointmentTypePermission,
  billingPermission,
  clinicPatientWiseReportPermission,
  clinicDepartmentWiseSaleReportPermission,
  clinicPatientListReportPermission,
  clinicSaleReportPermission,
  clinicSaleTestReportPermission,
  clinicSetupPermission,
  clinicTotalPatientReportPermission,
  doctorPermission,
  irdSyncPermission,
  labMaterializedPermission,
  patientDetailsPermission,
  recruitmentPermission,
  referralEditPermission,
  referralTypePermission,
  referrerPermission,
  refundBillingPermission,
  sitePermission,
  moldOrderAcceptPermission,
  serviceOrderAcceptPermission,
  deviceOrderAcceptPermission,
  moldTypePermission,
  repairOrderAcceptPermission,
  depositPermission,
  deviceRepairSpecialistPermission,
  depositReturnPermission,
} from "../Component/Layout/Sidebar/Clinic/ClinicPermissionConstants";
import ProtectedRoutes from "./ProtectedRoutes";
import { lazyWithReload } from "./Utils";
import { invServicePermission } from "../Component/Layout/Sidebar/Inventory/InvPermissionConstants";

// lab components imports
const Clinic = lazyWithReload(() => import("../Pages/ClinicPages"));
const Appointment = lazyWithReload(
  () => import("../Pages/ClinicPages/Appointment/AppointmentPages")
);
const ClinicSetup = lazyWithReload(
  () => import("../Pages/ClinicPages/ClinicSetup/ClinicSetupPages")
);
const PatientDetails = lazyWithReload(
  () => import("../Pages/ClinicPages/PatientDetails/PatientDetailsPages")
);
const PatientOverview = lazyWithReload(
  () =>
    import(
      "../Pages/ClinicPages/PatientDetails/PatientDetailsPages/PatientOverview"
    )
);
const Diagnosis = lazyWithReload(
  () =>
    import("../Pages/ClinicPages/PatientDetails/PatientDetailsPages/Diagnosis")
);
const OrderDetails = lazyWithReload(
  () =>
    import(
      "../Pages/ClinicPages/PatientDetails/PatientDetailsPages/OrderDetails"
    )
);
// const MedicalReferral = lazyWithReload(
//     () =>
//         import(
//             "../Pages/ClinicPages/PatientDetails/PatientDetailsPages/MedicalReferral"
//         )
// );
const AppointmentInsidePatientDetails = lazyWithReload(
  () =>
    import(
      "../Pages/ClinicPages/PatientDetails/PatientDetailsPages/Appointment"
    )
);
const MedicalReferral = lazyWithReload(
  () =>
    import(
      "../Pages/ClinicPages/PatientDetails/PatientDetailsPages/MedicalRefer"
    )
);
const PendingAppointment = lazyWithReload(
  () =>
    import(
      "../Pages/ClinicPages/PatientDetails/PatientDetailsPages/PendingAppointment"
    )
);
const Notes = lazyWithReload(
  () =>
    import(
      "../Pages/ClinicPages/PatientDetails/PatientDetailsPages/Notes/NotePages"
    )
);
const Address = lazyWithReload(
  () =>
    import("../Pages/ClinicPages/PatientDetails/PatientDetailsPages/Address")
);
const Contact = lazyWithReload(
  () =>
    import("../Pages/ClinicPages/PatientDetails/PatientDetailsPages/Contact")
);
const ContactRequest = lazyWithReload(
  () =>
    import(
      "../Pages/ClinicPages/PatientDetails/PatientDetailsPages/ContactRequest/ContactRequestPages"
    )
);
const Repair = lazyWithReload(
  () =>
    import(
      "../Pages/ClinicPages/PatientDetails/PatientDetailsPages/DeviceRepair/DeviceRepairPages"
    )
);
const ClinicOrder = lazyWithReload(
  () =>
    import(
      "../Pages/ClinicPages/PatientDetails/PatientDetailsPages/ClinicOrder/ClinicOrderPages"
    )
);

const MoldOrder = lazyWithReload(
  () =>
    import(
      "../Pages/ClinicPages/PatientDetails/PatientDetailsPages/MoldOrder/MoldOrderPages"
    )
);

const Referral = lazyWithReload(
  () => import("../Pages/ClinicPages/Referral/ReferralPages")
);
const ReferralEdit = lazyWithReload(
  () => import("../Pages/ClinicPages/ReferralEdit/ReferralEditPages")
);

const Billing = lazyWithReload(
  () => import("../Pages/ClinicPages/Billing/BillingPages")
);

const RefundBilling = lazyWithReload(
  () => import("../Pages/ClinicPages//RefundBilling/RefundBillingPages")
);

const SiteSetup = lazyWithReload(
  () => import("../Pages/ClinicPages/SiteSetup/SiteSetupPages")
);
const ReferralType = lazyWithReload(
  () => import("../Pages/ClinicPages/ReferralType/ReferralPages")
);
const MoldType = lazyWithReload(
  () => import("../Pages/ClinicPages/MoldType/MoldTypePages")
);
const AppointmentType = lazyWithReload(
  () => import("../Pages/ClinicPages/AppointmentType/AppointmentTypePages")
);
const Doctor = lazyWithReload(
  () => import("../Pages/ClinicPages/Doctor/DoctorPages")
);
const AppointmentRoutine = lazyWithReload(
  () =>
    import(
      "../Pages/ClinicPages/AppointmentRoutineSetup/AppointmentRoutinePages"
    )
);
const UserActivityLogReport = lazyWithReload(
  () =>
    import(
      "../Pages/ClinicPages/IRDReport/UserActivityLogReport/UserActivityLogReportPages/Index"
    )
);
const MaterializedReport = lazyWithReload(
  () =>
    import(
      "../Pages/ClinicPages/IRDReport/MaterializedReport/MaterializedReportPages/Index"
    )
);

const IRDSync = lazyWithReload(
  () => import("../Pages/ClinicPages/IRDReport/IRDSync/IRDSyncPages")
);

const NoteTemplates = lazyWithReload(
  () => import("../Pages/ClinicPages/NoteTemplates/NotesTemplatePages")
);

const SaleClinicReport = lazyWithReload(
  () =>
    import(
      "../Pages/ClinicPages/Billing/ClinicSaleReport/ClinicSaleReportPages"
    )
);
const TotalPatientStatReport = lazyWithReload(
  () =>
    import(
      "../Pages/ClinicPages/ClinicReport/TotalPatientStatReport/TotalPatientStatReportPages/Index"
    )
);
const DepartmentWiseSaleReport = lazyWithReload(
  () =>
    import(
      "../Pages/ClinicPages/ClinicReport/DepartmentWiseSaleReport/DepartmentWiseSaleReportPages/Index"
    )
);
const PatientListReport = lazyWithReload(
  () =>
    import(
      "../Pages/ClinicPages/ClinicReport/PatientListReport/PatientListReportPages/Index"
    )
);
const CustomerWiseClinicReport = lazyWithReload(
  () =>
    import(
      "../Pages/ClinicPages/ClinicReport/ClinicCustomerWiseReport/ClinicCustomerWiseReportPages/Index"
    )
);
const SaleTestClinicReport = lazyWithReload(
  () =>
    import(
      "../Pages/ClinicPages/ClinicReport/ClinicTestReport/ClinicTestReportPages/index"
    )
);
const MoldOrderAccept = lazyWithReload(
  () => import("../Pages/ClinicPages/MoldOrder/MoldOrderPages")
);
const DeviceOrderAccept = lazyWithReload(
  () => import("../Pages/ClinicPages/DeviceOrder/DeviceOrderPages")
);
const RepairOrder = lazyWithReload(
  () => import("../Pages/ClinicPages/RepairOrder/RepairOrderPages")
);
const ServiceOrderAccept = lazyWithReload(
  () => import("../Pages/ClinicPages/ServiceOrder/ServiceOrderPages")
);

const Service = lazyWithReload(
  () => import("../Pages/InventoryPages/Service/ServicePages")
);
const Deposit = lazyWithReload(
  () => import("../Pages/ClinicPages/Deposit/DepositPages")
);
const DepositReturn = lazyWithReload(
  () => import("../Pages/ClinicPages/DepositReturn/DepositReturnPages")
);

const DeviceRepairSpecialist = lazyWithReload(
  () =>
    import(
      "../Pages/ClinicPages/DeviceRepairSpecialist/DeviceRepairSpecialistPages"
    )
);

const ClinicRoutes = () => {
  return (
    <>
      <Switch>
        <ProtectedRoutes
          exact
          path="/clinic"
          component={Clinic}
          permission={[{ mainModule: true }]}
        />

        <ProtectedRoutes
          exact
          path="/clinic/appointment"
          component={Appointment}
          permission={appointmentPermission}
        />
        <ProtectedRoutes
          exact
          path="/clinic/clinic-setup"
          component={ClinicSetup}
          permission={clinicSetupPermission}
        />
        <ProtectedRoutes
          exact
          path="/clinic/appointment-routine"
          component={AppointmentRoutine}
          permission={appointmentRoutinePermission}
        />
        <ProtectedRoutes
          exact
          path="/clinic/patient-details"
          component={PatientDetails}
          permission={patientDetailsPermission}
        />
        <ProtectedRoutes
          exact
          path="/clinic/patient-details/overview"
          component={PatientOverview}
          permission=""
        />
        <ProtectedRoutes
          exact
          path="/clinic/patient-details/diagnosis"
          component={Diagnosis}
          permission=""
        />
        <ProtectedRoutes
          exact
          path="/clinic/service"
          component={Service}
          permission={invServicePermission}
        />
        <ProtectedRoutes
          exact
          path="/clinic/patient-details/orders"
          component={OrderDetails}
          permission=""
        />
        {/* <ProtectedRoutes
          exact
          path="/clinic/patient-details/medical-referral"
          component={MedicalReferral}
          permission=""
        /> */}

        <ProtectedRoutes
          exact
          path="/clinic/patient-details/Appointment"
          component={AppointmentInsidePatientDetails}
          permission=""
        />
        <ProtectedRoutes
          exact
          path="/clinic/patient-details/medical-referral"
          component={MedicalReferral}
          permission=""
        />
        <ProtectedRoutes
          exact
          path="/clinic/patient-details/pending-appointment"
          component={PendingAppointment}
          permission=""
        />
        <ProtectedRoutes
          exact
          path="/clinic/patient-details/customer-notes"
          component={Notes}
          permission=""
        />
        <ProtectedRoutes
          exact
          path="/clinic/patient-details/address"
          component={Address}
          permission=""
        />
        <ProtectedRoutes
          exact
          path="/clinic/patient-details/contact"
          component={Contact}
          permission=""
        />
        <ProtectedRoutes
          exact
          path="/clinic/patient-details/contact-request"
          component={ContactRequest}
          permission=""
        />
        <ProtectedRoutes
          exact
          path="/clinic/patient-details/clinic-order"
          component={ClinicOrder}
          permission=""
        />
        <ProtectedRoutes
          exact
          path="/clinic/patient-details/mold-order"
          component={MoldOrder}
          permission=""
        />
        <ProtectedRoutes
          exact
          path="/clinic/patient-details/device-repair"
          component={Repair}
          permission=""
        />
        <ProtectedRoutes
          exact
          path="/clinic/direct-billing"
          component={Billing}
          permission={billingPermission}
        />

        <ProtectedRoutes
          exact
          path="/clinic/refund-billing"
          component={RefundBilling}
          permission={refundBillingPermission}
        />

        <ProtectedRoutes
          exact
          path="/clinic/referrer"
          component={Referral}
          permission={referrerPermission}
        />
        <ProtectedRoutes
          exact
          path="/clinic/referrer-edit"
          component={ReferralEdit}
          permission={referralEditPermission}
        />
        <ProtectedRoutes
          exact
          path="/clinic/site-setup"
          component={SiteSetup}
          permission={sitePermission}
        />
        <ProtectedRoutes
          exact
          path="/clinic/referral-type"
          component={ReferralType}
          permission={referralTypePermission}
        />
        <ProtectedRoutes
          exact
          path="/clinic/mold-type"
          component={MoldType}
          permission={moldTypePermission}
        />
        <ProtectedRoutes
          exact
          path="/clinic/appointment-type"
          component={AppointmentType}
          permission={appointmentTypePermission}
        />
        <ProtectedRoutes
          exact
          path="/clinic/doctor"
          component={Doctor}
          permission={doctorPermission}
        />

        <ProtectedRoutes
          exact
          path="/clinic/lab-ird-user-activity-report"
          component={UserActivityLogReport}
          permission={activityReportPermission}
        />
        <ProtectedRoutes
          exact
          path="/clinic/lab-ird-materialized-report"
          component={MaterializedReport}
          permission={labMaterializedPermission}
        />
        <ProtectedRoutes
          exact
          path="/clinic/ird-sync"
          component={IRDSync}
          permission={irdSyncPermission}
        />
        {/* <ProtectedRoutes
          exact
          path="/clinic/recruiting-agency"
          component={RecruitingAgency}
          permission=""
        /> */}

        <ProtectedRoutes
          exact
          path="/clinic/note-templates"
          component={NoteTemplates}
          permission={recruitmentPermission}
        />
        <ProtectedRoutes
          exact
          path="/clinic/sale-clinic-report"
          component={SaleClinicReport}
          permission={clinicSaleReportPermission}
        />

        <ProtectedRoutes
          exact
          path="/clinic/department-wise-sale-report"
          component={DepartmentWiseSaleReport}
          permission={clinicDepartmentWiseSaleReportPermission}
        />
        <ProtectedRoutes
          exact
          path="/clinic/total-patient-stat-report"
          component={TotalPatientStatReport}
          permission={clinicTotalPatientReportPermission}
        />
        <ProtectedRoutes
          exact
          path="/clinic/patient-list-report"
          component={PatientListReport}
          permission={clinicPatientListReportPermission}
        />
        <ProtectedRoutes
          exact
          path="/clinic/customer-wise-report"
          component={CustomerWiseClinicReport}
          permission={clinicPatientWiseReportPermission}
        />

        <ProtectedRoutes
          exact
          path="/clinic/sale-test-report"
          component={SaleTestClinicReport}
          permission={clinicSaleTestReportPermission}
        />
        <ProtectedRoutes
          exact
          path="/clinic/mold-order"
          component={MoldOrderAccept}
          permission={moldOrderAcceptPermission}
        />
        <ProtectedRoutes
          exact
          path="/clinic/service-order"
          component={ServiceOrderAccept}
          permission={serviceOrderAcceptPermission}
        />
        <ProtectedRoutes
          exact
          path="/clinic/device-order"
          component={DeviceOrderAccept}
          permission={deviceOrderAcceptPermission}
        />
        <ProtectedRoutes
          exact
          path="/clinic/repair-order"
          component={RepairOrder}
          permission={repairOrderAcceptPermission}
        />
        <ProtectedRoutes
          exact
          path="/clinic/deposit"
          component={Deposit}
          permission={depositPermission}
        />
        <ProtectedRoutes
          exact
          path="/clinic/deposit-return"
          component={DepositReturn}
          permission={depositReturnPermission}
        />
        <ProtectedRoutes
          exact
          path="/clinic/device-repair-specialist"
          component={DeviceRepairSpecialist}
          permission={deviceRepairSpecialistPermission}
        />
      </Switch>
    </>
  );
};

export default ClinicRoutes;
