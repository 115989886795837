import { createSlice } from "@reduxjs/toolkit";
const initialState: any = {
  sampleTypes: [],
  loadingSampleType: false,
  loadingCreateSampleType: false,
  edit: false,
  sampleType: null,
  count: 0,
  previous: "",
  next: "",
};

export const SampleType = createSlice({
  name: "SampleType Reducer",
  initialState,
  reducers: {
    getSampleTypeRequest: (state, payload) => {
      state.loadingSampleType = true;
    },
    getSampleTypeSuccess: (
      state,
      { payload: { results, count, next, previous } }
    ) => {
      state.loadingSampleType = false;
      state.sampleTypes = results;
      state.count = count;
      state.next = next;
      state.previous = previous;
    },
    getSampleTypeFail: (state) => {
      state.loadingSampleType = false;
    },
    getSampleTypeNextRequest: (state, payload) => {
      state.loadingSampleType = true;
    },
    getSampleTypePreviousRequest: (state, payload) => {
      state.loadingSampleType = true;
    },
    createSampleTypeRequest: (state, payload) => {
      state.loadingCreateSampleType = true;
    },
    createSampleTypeSuccess: (state) => {
      state.loadingCreateSampleType = false;
    },
    createSampleTypeFail: (state) => {
      state.loadingCreateSampleType = false;
    },
    getSampleTypeByIdRequest: (state, payload) => {
      state.edit = true;
    },
    sampleTypeEditSuccess: (state, { payload }) => {
      state.sampleType = payload;
    },
    sampleTypeEditFail: (state) => {
      state.edit = false;
    },
    updateSampleTypeRequest: (state, payload) => {
      state.loadingCreateSampleType = true;
    },
    updateSampleTypeSuccess: (state) => {
      state.loadingCreateSampleType = false;
    },
    updateSampleTypeFail: (state) => {
      state.loadingCreateSampleType = false;
    },
    clearSampleTypeData: (state) => {
      state.edit = false;
      state.sampleType = null;
    },
  },
});
// Action creators are generated for each case reducer function
export const {
  getSampleTypeRequest,
  getSampleTypeSuccess,
  getSampleTypeFail,
  getSampleTypeNextRequest,
  getSampleTypePreviousRequest,
  createSampleTypeRequest,
  createSampleTypeSuccess,
  createSampleTypeFail,
  sampleTypeEditSuccess,
  updateSampleTypeRequest,
  updateSampleTypeSuccess,
  updateSampleTypeFail,
  clearSampleTypeData,
  getSampleTypeByIdRequest,
  sampleTypeEditFail,
} = SampleType.actions;
export default SampleType.reducer;
