import { axiosInstance } from "../../../../AppUtils/Utils/axios";
import { filterApiData } from "../../../../AppUtils/Utils/appFunctions";
import {
  getApiProps,
  searchProps,
} from "../../../../AppUtils/Utils/globalTypes";

const ServiceTypeModURL = "api/v1/core-app/service-type";

export const getServiceType = (data: getApiProps) => {
  const { rowsPerPage, page } = data;
  return axiosInstance.get(
    `${ServiceTypeModURL}?offset=${rowsPerPage * page}&limit=${rowsPerPage}${
      data?.filterData ? filterApiData(data?.filterData) : ""
    }`
  );
};

//get ServiceType by Id
export const getServiceTypeById = (id: number) => {
  return axiosInstance.get(`${ServiceTypeModURL}/${id}`);
};

export const searchServiceType = ({ rowsPerPage, search }: searchProps) => {
  return `${ServiceTypeModURL}?offset=0&limit=${rowsPerPage}&search=${search}`;
};
export const createServiceType = (body: string | FormData) =>
  axiosInstance.post(`${ServiceTypeModURL}`, body);
//update ServiceType
export const updateServiceType = (body: string | FormData, id: number) =>
  axiosInstance.patch(`${ServiceTypeModURL}/${id}`, body);

export const getAllServiceTypeForDefault = () =>
  axiosInstance.get<any>(`${ServiceTypeModURL}?is_default=true`);
