import { filterApiData } from "../../../../../../AppUtils/Utils/appFunctions";
import { axiosInstance } from "../../../../../../AppUtils/Utils/axios";
import { searchProps } from "../../../../../../AppUtils/Utils/globalTypes";

export const contactRequestUrl = "/api/v1/appointment-app/contact-request";

// Get all contact requests
export const getContactRequests = (data: any) => {
    const { rowsPerPage, page } = data;
    return axiosInstance.get(
        `${contactRequestUrl}?offset=${rowsPerPage * page
        }&limit=${rowsPerPage}${data?.filterData ? filterApiData(data?.filterData) : ""
        }`
    );
}

// Get contact request by customer id
export const getCustomerContactRequests = (data: any) => {
    const { id, rowsPerPage, page } = data;
    return axiosInstance.get(
        `${contactRequestUrl}?customer=${id}&offset=${rowsPerPage * page
        }&limit=${rowsPerPage}${data?.filterData ? filterApiData(data?.filterData) : ""
        }`
    );
};

export const getContactRequestById = (id: number) => {
    return axiosInstance.get(`${contactRequestUrl}/${id}`);
};

// Create contact request
export const createContactRequestById = (body: string | FormData) => {
    return axiosInstance.post(`${contactRequestUrl}`, body);
};

// Update contact request
export const updateContactRequestById = (
    body: string | FormData,
    id: number
) => {
    return axiosInstance.patch(`${contactRequestUrl}/${id}`, body);
};

// Get Edited contact request
export const getEditedContactRequest = (id: number) => {
    return axiosInstance.get(`${contactRequestUrl}/${id}`);
};

// get contact request for list
export const getContactRequestForList = () => {
    return axiosInstance.get(`${contactRequestUrl}/user`);
};

// search contact request
export const searchContactRequest = ({ rowsPerPage, search }: searchProps) => {
    return `${contactRequestUrl}?offset=0&limit=${rowsPerPage}&search=${search}`;
};
