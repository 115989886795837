import { axiosInstance } from "../../../../AppUtils/Utils/axios";
import { filterApiData } from "../../../../AppUtils/Utils/appFunctions";
import {
  getApiProps,
  searchProps,
} from "../../../../AppUtils/Utils/globalTypes";

const storeModURL = "api/v1/core-app/store";

export const getStore = (data: getApiProps) => {
  const { rowsPerPage, page } = data;
  return axiosInstance.get(
    `${storeModURL}?offset=${rowsPerPage * page}&limit=${rowsPerPage}${
      data?.filterData ? filterApiData(data?.filterData) : ""
    }`
  );
};

//get store by Id
export const getStoreById = (id: number) => {
  return axiosInstance.get(`${storeModURL}/${id}`);
};

export const searchStore = ({ rowsPerPage, search }: searchProps) => {
  return `${storeModURL}?offset=0&limit=${rowsPerPage}&search=${search}`;
};
export const createStore = (body: string | FormData) =>
  axiosInstance.post(`${storeModURL}`, body);
//update Store
export const updateStore = (body: string | FormData, id: number) =>
  axiosInstance.patch(`${storeModURL}/${id}`, body);

export const getAllStoreForDefault = () =>
  axiosInstance.get<any>(`${storeModURL}?is_default=true`);
