import { filterApiData } from "../../../../AppUtils/Utils/appFunctions";
import { axiosInstance } from "../../../../AppUtils/Utils/axios";
import {
  getApiProps,
  searchProps,
} from "../../../../AppUtils/Utils/globalTypes";

const paymentModURL = 'api/v1/core-app/payment-mode';

//get payment mode data 
export const getPaymentMode = (data: getApiProps) => {
  const { rowsPerPage, page, search} = data;
  return axiosInstance.get(
    `${paymentModURL}?offset=${rowsPerPage * page}&limit=${rowsPerPage}&search=${search ?? ""}${
      data?.filterData ? filterApiData(data?.filterData) : ""
    }`
  );
}
//get Payment mode by id
export const getPaymentModeById = (id: number) => {
  return axiosInstance.get(`${paymentModURL}/${id}`);
}

//handle search
export const searchPaymentMode = ({ rowsPerPage, search }: searchProps) => {
  return `${paymentModURL}?offset=0&limit=${rowsPerPage}&search=${search ?? ""}`;
};

//create payment mode
export const createPaymentMode = (body: string | FormData) =>
  axiosInstance.post(`${paymentModURL}`, body);

  //update user
export const updatePaymentMode = (body: string | FormData, id: number) =>
  axiosInstance.patch(`${paymentModURL}/${id}`, body);

  // get cash api
export const getCash = () =>
  axiosInstance.get(`${paymentModURL}?cash=true`);
