import { filterApiData } from "../../../../AppUtils/Utils/appFunctions";
import ArrayUtils from "../../../../AppUtils/Utils/arrayUtils";
import { axiosInstance } from "../../../../AppUtils/Utils/axios";
import {
  getApiProps,
  globalApiData,
  searchProps,
} from "../../../../AppUtils/Utils/globalTypes";
import { verifyPrint } from "./types";
const verificationAndPrintURL = "api/v1/lab-report-app/lab-report";
//get VerificationAndPrint data
export const getVerificationAndPrint = (data: getApiProps) => {
  const { rowsPerPage, page, search } = data;
  return axiosInstance.get(
    `${verificationAndPrintURL}?offset=${
      rowsPerPage * page
    }&limit=${rowsPerPage}&search=${search ?? ""}${
      data?.filterData ? filterApiData(data?.filterData) : ""
    }`
  );
};

//get unit by id
export const getVerificationAndPrintById = (id: number) => {
  return axiosInstance.get(`${verificationAndPrintURL}/${id}`);
};

//handle search
export const searchVerificationAndPrint = ({
  rowsPerPage,
  search,
}: searchProps) => {
  return `${verificationAndPrintURL}?offset=0&limit=${rowsPerPage}&search=${
    search ?? ""
  }`;
};

//create unit
export const createVerificationAndPrint = (body: string | FormData) =>
  axiosInstance.post(`${verificationAndPrintURL}`, body);

//update unit
export const updateVerificationAndPrint = (
  body: string | FormData,
  id: number
) => axiosInstance.patch(`${verificationAndPrintURL}/${id}`, body);
// get customer result entried tests
export const getCreatedResultEntryTest = (id: number[]) =>
  axiosInstance.get<globalApiData>(
    `${verificationAndPrintURL}/customer-test?sample_main=${ArrayUtils.splitArrayOfNumberToStringById(
      id
    )}&offset=0&limit=0
    `
  );
// get customer test result
export const getResultEnteredTestDetails = (selectedTestId: number[]) => {
  let customer_test = selectedTestId
    ?.map((testId) => `customer_test=${testId}`)
    .join("&");
  return axiosInstance.get<globalApiData>(
    `${verificationAndPrintURL}/customer-test-result?${customer_test}
    `
  );
};
// get customer test report footer
export const getResultEnteredTestReportFooter = (sampleId: number) =>
  axiosInstance.get<globalApiData>(
    `api/v1/lab-report-app/report-footer?sample_main=${sampleId}`
  );
// verify report
export const verifyReport = (body: any) =>
  axiosInstance.post<verifyPrint>(`${verificationAndPrintURL}/verify`, body);
//unapprove test
export const unApproveTest = (body: string) =>
  axiosInstance.patch(`${verificationAndPrintURL}/unapprove`, body);
// print count
export const printCount = (body: string) => {
  return axiosInstance.post(
    `${verificationAndPrintURL}/update-report-print-count`,
    body
  );
};
// send lab report via email
export const sendLabReportEmail = (body: FormData) =>
  axiosInstance.post(`api/v1/lab-report-app/send-email`, body);
