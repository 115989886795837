import { axiosInstance } from "../../../../AppUtils/Utils/axios";

const baseUrl = "api/v1/appointment-app/customer";

interface rowsPerPage {
  rowsPerPage: number;
}

interface customerNo {
  customerNo: number | string;
}
// get customer list
export const getPatientLists = ({ rowsPerPage }: rowsPerPage) => {
  return axiosInstance.get(`${baseUrl}?offset=0&limit=${rowsPerPage}`);
};

// get customer from customerNoFull
export const getFilteredPatient = ({ customerNo }: customerNo) => {
  return axiosInstance.get(
    `${baseUrl}?offset=0&limit=0&customer_no_full=${customerNo}`
  );
};

export const getPatientListsById = (id: number) => {
  return axiosInstance.get(`${baseUrl}/${id}`);
};
