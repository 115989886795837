import { memo, MouseEvent, useState } from "react";
import Box from "@mui/material/Box";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableContainer from "@mui/material/TableContainer";
import Paper from "@mui/material/Paper";
import { Order, TableProps } from "../types";
import AppTableBody from "./AppTableBody";
import AppTableHeader from "./AppTableHeader";
import FilterData from "../../FilterData/FilterData";
import Pagination from "../TableUtils/Pagination";
import AppButton from "../../AppElements/Button/AppButton";
import ListingTableData from "../../Loader/ListingTableData";
import CloudDownloadIcon from "@mui/icons-material/CloudDownload";
import NoData from "../../NoData/NoData";
import { tableStyles } from "../styles";
import { ReactComponent as Excel } from "../../../Assets/exceLIcon.svg";

var XLSX = require("xlsx");

const AppTable = ({
  data,
  columns,
  count,
  handleClick,
  handleEdit,
  handleDelete,
  handleCancel,
  handleViewDetails,
  handlePrintDetails,
  handleFilterData,
  title,
  loading,
  hidePaginationAndFilterField,
  showDeleteButton,
  tableContainerForInsideDetail,
  showAddIcon,
  hideAction,
  showDetailsButton,
  handleStickerPrintDetails,
  showPrintDoubleButton,
  showPrintButton,
  hideEditButton,
  hidePreferences,
  hideSort,
  setSelected,
  showSelectAllCheckBox,
  checkBoxData,
  multipleSelect,
  downloadImage,
  showMoreIcon,
  handleReceive,
  handleDispatch,
  handleMakeBill,
  handlePrintPdfReport,
  handleVerify,
  showMorePurchaseIcon,
  showMoreContactIcon,
  handleStatusUpdate,
  handleViewContacts,
  handleRepaired,
}: TableProps) => {
  const classes = tableStyles();
  const [order, setOrder] = useState<Order>("asc");
  const [dense, setDense] = useState(false);
  const [orderBy, setOrderBy] = useState("");
  const handleRequestSort = (event: MouseEvent<unknown>, property: string) => {
    const isAsc = orderBy === property && order === "asc";
    setOrder(isAsc ? "desc" : "asc");
    setOrderBy(property);
  };

  // header props
  const headerProps = {
    columns,
    order,
    orderBy,
    onRequestSort: handleRequestSort,
    rowCount: data.length,
    hideAction,
    hidePreferences,
    hideSort,
    setSelected,
    showSelectAllCheckBox,
    checkBoxData,
    data,
    multipleSelect,
  };

  //body props
  const bodyProps = {
    columns,
    data,
    order,
    orderBy,
    handleEdit,
    handleReceive,
    handleDispatch,
    handleDelete,
    handleMakeBill,
    handleCancel,
    handleViewDetails,
    handlePrintDetails,
    showDeleteButton,
    showAddIcon,
    hideAction,
    showDetailsButton,
    showPrintButton,
    hideEditButton,
    hidePreferences,
    handleStickerPrintDetails,
    showPrintDoubleButton,
    setSelected,
    showSelectAllCheckBox,
    checkBoxData,
    showMoreIcon,
    handlePrintPdfReport,
    handleVerify,
    showMorePurchaseIcon,
    showMoreContactIcon,
    handleStatusUpdate,
    handleViewContacts,
    handleRepaired,
  };

  const paginationProps = { count, handleClick, dense, setDense };
  const handleExportExcel = () => {
    var wb = XLSX.utils.book_new();
    var ws = XLSX.utils.json_to_sheet(data);
    XLSX.utils.book_append_sheet(wb, ws, "DataSheet");
    XLSX.writeFile(wb, `${title}_Sheet.xlsx`);
  };

  return (
    <Box>
      <>
        {!hidePaginationAndFilterField && (
          <Paper className={classes.filterDataPaper}>
            <>
              <FilterData
                columns={columns}
                handleFilterData={handleFilterData}
              />
              {showSelectAllCheckBox ? (
                <AppButton
                  style={{ marginRight: 15 }}
                  submit={false}
                  tooltipTitle="Download Insurance Image"
                  onClick={downloadImage}
                  disabled={checkBoxData?.length === 0}
                >
                  <CloudDownloadIcon />
                </AppButton>
              ) : (
                <AppButton
                  style={{ marginRight: 15, marginTop: 8 }}
                  submit={false}
                  tooltipTitle="Download Excel Sheet"
                  onClick={handleExportExcel}
                >
                  <Excel width={18} height={20} />
                </AppButton>
              )}
            </>
          </Paper>
        )}
        <Paper className={classes.tableDataPaper}>
          <TableContainer
            className={
              // data?.length === 0
              //   ? ""
              //   : tableContainerForInsideDetail
              //   ? data?.length === 0
              //     ? classes.noDataDisplay
              //     : classes.tableContainerForDetail
              //   : classes.tableContainer
              data?.length === 0
                ? ""
                : !hidePreferences
                ? classes.tableContainer
                : classes.tableContainerForDetail
            }
          >
            <Table
              size={!dense ? "small" : "medium"}
              stickyHeader
              aria-label="sticky table"
              className="custom-table"
              // style={{  maxHeight: "300px",overflow:"auto",marginTop:10}}
            >
              <AppTableHeader {...headerProps} />
              {/* show loader if it is in loading state */}
              {loading ? (
                /* pass header length  */
                <ListingTableData
                  headerLength={
                    hideAction ? columns?.length + 1 : columns?.length + 3
                  }
                />
              ) : (
                <>
                  {hidePaginationAndFilterField}
                  <TableBody>
                    <AppTableBody {...bodyProps} />
                  </TableBody>
                </>
              )}
            </Table>
          </TableContainer>
          {!loading && data?.length === 0 && <NoData />}
          {/* pagination */}
          {!hidePaginationAndFilterField && <Pagination {...paginationProps} />}
          {/* <Pagination /> */}
        </Paper>
      </>
    </Box>
  );
};
export default memo(AppTable);
