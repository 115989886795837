import { filterApiData } from "../../../../AppUtils/Utils/appFunctions";
import { axiosInstance } from "../../../../AppUtils/Utils/axios";
import { getISODateFormat } from "../../../../AppUtils/Utils/dateFunctions";
import {
  getApiProps,
  searchProps,
} from "../../../../AppUtils/Utils/globalTypes";
export const appointmentRoutineModURL =
  "api/v1/appointment-app/appointment-routine";

//get appointment
export const getAppointmentRoutine = (data: getApiProps) => {
  const { rowsPerPage, page } = data;
  return axiosInstance.get(
    `${appointmentRoutineModURL}?offset=${
      rowsPerPage * page
    }&limit=${rowsPerPage}${
      data?.filterData ? filterApiData(data?.filterData) : ""
    }`
  );
};

//get appointment routine  by id
export const getAppointmentRoutineById = (id: number) => {
  return axiosInstance.get(`${appointmentRoutineModURL}/${id}`);
};

//get appointment routing site
export const getAppointmentRoutineSite = (rowsPerPage: number) => {
  return axiosInstance.get(
    `${appointmentRoutineModURL}/site?offset=0&limit=${rowsPerPage}`
  );
};

//get clinic from site
export const getClinicFromSite = (site: number) => {
  return axiosInstance.get(
    `${appointmentRoutineModURL}/clinic?offset=0&limit=0&site=${site}`
  );
};

//get doctor from clinic
export const getDoctorFromClinic = (clinic: number) => {
  return axiosInstance.get(
    `${appointmentRoutineModURL}/doctor?offset=0&limit=0&clinic=${clinic}`
  );
};

//get appointment routing clinic
export const getAppointmentRoutineClinic = (rowsPerPage: number) => {
  return axiosInstance.get(
    `${appointmentRoutineModURL}/clinic?offset=0&limit=${rowsPerPage}`
  );
};

//get appointment routing doctor
export const getAppointmentRoutineDoctor = (rowsPerPage: number) => {
  return axiosInstance.get(
    `${appointmentRoutineModURL}/doctor?&offset=0&limit=${rowsPerPage}`
  );
};

//search appointment routing
export const searchAppointmentRoutine = ({
  rowsPerPage,
  search,
}: searchProps) => {
  return `${appointmentRoutineModURL}?offset=0&limit=${rowsPerPage}&search=${search}`;
};

//create Appointment routine
export const createAppointmentRoutine = (body: string | FormData) =>
  axiosInstance.post(`${appointmentRoutineModURL}`, body);

//update Appointment routine
export const updateAppointmentRoutine = (body: string | FormData, id: number) =>
  axiosInstance.post(`${appointmentRoutineModURL}/${id}`, body);

//get appointment routine on click generate routine
export const getAppointmentRoutineOnGenerate = (data: any) => {
  const { site, clinic, doctor, startDate, endDate } = data;
  const sDate = getISODateFormat(startDate);
  const eDate = getISODateFormat(endDate);
  return axiosInstance.get(
    `${appointmentRoutineModURL}/get-appointment-routine?offset=0&limit=0&clinic=${clinic}&site=${site}&doctor=${doctor}&date_after=${sDate}&date_before=${eDate}`
  );
};

//site: number, clinic: number, doctor: number, startDate: Date, endDate: Date
//date_after=2023-01-08&date_before=2023-01-10`
