import { axiosInstance } from "../../../../AppUtils/Utils/axios";
import { filterApiData } from "../../../../AppUtils/Utils/appFunctions";
import {
  getApiProps,
  searchProps,
} from "../../../../AppUtils/Utils/globalTypes";

export const serviceModURL = "/api/v1/inventory-item-app/service";
//get Service data api
export const getService = (data: getApiProps) => {
  const { rowsPerPage, page } = data;
  return axiosInstance.get(
    `${serviceModURL}?offset=${rowsPerPage * page}&limit=${rowsPerPage}${
      data?.filterData ? filterApiData(data?.filterData) : ""
    }`
  );
};

//get service by id
export const getServiceById = (id: number) => {
  return axiosInstance.get(`${serviceModURL}/${id}`);
};

//handle search
export const searchService = ({ rowsPerPage, search }: searchProps) => {
  return `${serviceModURL}?offset=0&limit=${rowsPerPage}&search=${search}`;
};
// get service item-category
export const getServiceItemCategory = ({ rowsPerPage }: any) =>
  axiosInstance.get(
    `${serviceModURL}/item-category?offset=0&limit=${0}`
  );

export const createService = (body: string | FormData) =>
  axiosInstance.post(`${serviceModURL}`, body);
//update Service
export const updateService = (body: string | FormData, id: number) =>
  axiosInstance.patch(`${serviceModURL}/${id}`, body);
