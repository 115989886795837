import { filterApiData } from "../../AppUtils/Utils/appFunctions";
import { axiosInstance } from "../../AppUtils/Utils/axios";
import { getApiProps } from "../../AppUtils/Utils/globalTypes";

//get next
export const getNext = (next: string) => axiosInstance.get(next);
//get previous
export const getPrevious = (next: string) => axiosInstance.get(next);

const OrganizationRuleModURL = "/api/v1/global-api/organization-rule";
const OrganizationModURL = "/api/v1/global-api/organization-setup";

export const getGlobalOrganizationSetup = () => {
  return axiosInstance.get(`${OrganizationModURL}`);
};

export const getGlobalOrganizationRule = () => {
  return axiosInstance.get(`${OrganizationRuleModURL}`);
};

export const getInventoryCreditSalesInfoAPI = (data: getApiProps) => {
  const { rowsPerPage, page } = data;
  return axiosInstance.get(
    `${"/api/v1/inventory-credit-clearance-app/credit-sales-info"}?offset=${
      rowsPerPage * page
    }&limit=${rowsPerPage}${
      data?.filterData ? filterApiData(data?.filterData) : ""
    }`
  );
};


export const getClinicCreditSalesInfoAPI = (data: getApiProps) => {
  const { rowsPerPage, page } = data;
  return axiosInstance.get(
    `${"/api/v1/clinic-credit-management-app/credit-sales-info"}?offset=${
      rowsPerPage * page
    }&limit=${rowsPerPage}${
      data?.filterData ? filterApiData(data?.filterData) : ""
    }`
  );
};
