import { Switch } from "react-router-dom";
import {
  departmentPermission,
  footerNotePermission,
  packageTestPermission,
  reagentPermission,
  referenceRangePermission,
  reportCommentPermission,
  reportFooterPermission,
  resultEntryPermission,
  sampleCollectionPermission,
  sampleTypePermission,
  sensitiveMedPermission,
  testCategoryPermission,
  testFindingPermission,
  testMethodPermission,
  testPermission,
  testSensitiveMedPermission,
  testUnitPermission,
  verificationAndPrintPermission,
} from "../Component/Layout/Sidebar/Lab/LabPermissionConstants";
import ProtectedRoutes from "./ProtectedRoutes";
import { lazyWithReload } from "./Utils";
// lab components imports
const Lab = lazyWithReload(() => import("../Pages/LabPages"));
const Department = lazyWithReload(
  () => import("../Pages/LabPages/Department/DepartmentPages")
);
const TestCategory = lazyWithReload(
  () => import("../Pages/LabPages/TestCategory/TestCategoryPages")
);
const FooterNote = lazyWithReload(
  () => import("../Pages/LabPages/FooterNote/FooterNotePages")
);
const Unit = lazyWithReload(() => import("../Pages/LabPages/Unit/UnitPages"));
const TestMethod = lazyWithReload(
  () => import("../Pages/LabPages/TestMethod/TestMethodPages")
);
const SensitiveMedicine = lazyWithReload(
  () => import("../Pages/LabPages/SensitiveMedicine/SensitiveMedicinePages")
);
const ReportComment = lazyWithReload(
  () => import("../Pages/LabPages/ReportComment/ReportCommentPages")
);
const TestSensitiveMedicine = lazyWithReload(
  () =>
    import("../Pages/LabPages/TestSensitiveMedicine/TestSensitiveMedicinePages")
);
const TestFinding = lazyWithReload(
  () => import("../Pages/LabPages/TestFinding/TestFindingPages")
);

const Test = lazyWithReload(() => import("../Pages/LabPages/Test/TestPages"));

const PackageTest = lazyWithReload(
  () => import("../Pages/LabPages/PackageTest/PackageTestPages")
);
const ReferenceRange = lazyWithReload(
  () => import("../Pages/LabPages/ReferenceRange/ReferenceRangePages")
);
const SampleType = lazyWithReload(
  () => import("../Pages/LabPages/SampleType/SampleTypePages")
);

const Reagent = lazyWithReload(
  () => import("../Pages/LabPages/Reagent/ReagentPages")
);

const ReportFooter = lazyWithReload(
  () => import("../Pages/LabPages/ReportFooter/ReportFooterPages")
);
const SampleCollection = lazyWithReload(
  () => import("../Pages/LabPages/SampleCollection/SampleCollectionPages")
);
const ResultEntry = lazyWithReload(
  () => import("../Pages/LabPages/ResultEntry/ResultEntryPages")
);
const VerificationAndPrint = lazyWithReload(
  () =>
    import("../Pages/LabPages/VerificationAndPrint/VerificationAndPrintPages")
);
// const QuickRegistration = lazyWithReload(
//   () => import("../Pages/LabPages/QuickRegistration/QuickRegistrationPages")
// );
// const ForeignWorkerRegistration = lazyWithReload(
//   () => import("../Pages/LabPages/ForeignWorkerRegistration/ForeignWorkerRegistrationPages")
// );
const LabRoutes = () => {
  return (
    <>
      <Switch>
        <ProtectedRoutes
          exact
          path="/lab"
          component={Lab}
          permission={[{ mainModule: true }]}
        />
        <ProtectedRoutes
          exact
          path="/lab/unit"
          component={Unit}
          permission={testUnitPermission}
        />
        <ProtectedRoutes
          exact
          path="/lab/sensitive-medicine"
          component={SensitiveMedicine}
          permission={sensitiveMedPermission}
        />
        <ProtectedRoutes
          exact
          path="/lab/test-method"
          component={TestMethod}
          permission={testMethodPermission}
        />
        <ProtectedRoutes
          exact
          path="/lab/test"
          component={Test}
          permission={testPermission}
        />
        <ProtectedRoutes
          exact
          path="/lab/package-test"
          component={PackageTest}
          permission={packageTestPermission}
        />
        <ProtectedRoutes
          exact
          path="/lab/reference-range"
          component={ReferenceRange}
          permission={referenceRangePermission}
        />
        <ProtectedRoutes
          exact
          path="/lab/report-comment"
          component={ReportComment}
          permission={reportCommentPermission}
        />
        <ProtectedRoutes
          exact
          path="/lab/report-footer"
          component={ReportFooter}
          permission={reportFooterPermission}
        />
        <ProtectedRoutes
          exact
          path="/lab/department"
          component={Department}
          permission={departmentPermission}
        />
        <ProtectedRoutes
          exact
          path="/lab/test-category"
          component={TestCategory}
          permission={testCategoryPermission}
        />
        <ProtectedRoutes
          exact
          path="/lab/footer-note"
          component={FooterNote}
          permission={footerNotePermission}
        />
        <ProtectedRoutes
          exact
          path="/lab/test-sensitive-medicine"
          component={TestSensitiveMedicine}
          permission={testSensitiveMedPermission}
        />
        <ProtectedRoutes
          exact
          path="/lab/test-finding"
          component={TestFinding}
          permission={testFindingPermission}
        />
        <ProtectedRoutes
          exact
          path="/lab/sample-collection"
          component={SampleCollection}
          permission={sampleCollectionPermission}
        />
        <ProtectedRoutes
          exact
          path="/lab/result-entry"
          component={ResultEntry}
          permission={resultEntryPermission}
        />
        <ProtectedRoutes
          exact
          path="/lab/lab-verification"
          component={VerificationAndPrint}
          permission={verificationAndPrintPermission}
        />

        <ProtectedRoutes
          exact
          path="/lab/sample-type"
          component={SampleType}
          permission={sampleTypePermission}
        />
        <ProtectedRoutes
          exact
          path="/lab/reagent"
          component={Reagent}
          permission={reagentPermission}
        />
      </Switch>
    </>
  );
};

export default LabRoutes;
