import { createSlice, createAction } from "@reduxjs/toolkit";
const initialState: any = {
  packageTests: [],
  loadingPackageTest: false,
  loadingCreatePackageTest: false,
  loadingPackageTestTests: false,
  edit: false,
  packageTest: null,
  testLists: [],
  departments: [],
  packageTestLists: [],
  count: 0,
  previous: "",
  next: "",
};

export const PackageTest = createSlice({
  name: "Package Test Reducer",
  initialState,
  reducers: {
    loadingPackageTest: (state) => {
      state.loadingPackageTest = true;
    },
    getPackageTestRequest: (state, payload) => {
      state.loadingPackageTest = true;
    },
    getPackageTestSuccess: (
      state,
      { payload: { results, count, next, previous } }
    ) => {
      state.loadingPackageTest = false;
      state.packageTests = results;
      state.count = count;
      state.next = next;
      state.previous = previous;
    },
    getPackageTestFail: (state) => {
      state.loadingPackageTest = false;
    },
    getPackageTestNextRequest: (state, payload) => {
      state.loadingPackageTest = true;
    },
    getPackageTestPreviousRequest: (state, payload) => {
      state.loadingPackageTest = true;
    },
    getPackageTestDepartmentRequest: (state, payload) => {
      state.loadingPackageTest = true;
    },
    getPackageTestDepartmentSuccess: (state, { payload: { results } }) => {
      state.loadingPackageTest = false;
      state.departments = results;
    },
    getPackageTestDepartmentFail: (state) => {
      state.loadingPackageTest = false;
    },
    getPackageTestListRequest: (state, payload) => {
      state.loadingPackageTest = true;
    },
    getPackageTestListSuccess: (state, { payload: { results } }) => {
      state.loadingPackageTest = false;
      state.packageTestLists = results;
    },
    getPackageTestListFail: (state) => {
      state.loadingPackageTest = false;
    },
    loadingPackageTestTestsReq: (state) => {
      state.loadingPackageTestTests = true;
    },
    getPackageTestTestRequest: (state, payload) => {
      state.loadingPackageTestTests = true;
    },
    getPackageTestTestSuccess: (state, { payload: { results } }) => {
      state.loadingPackageTestTests = false;
      state.testLists = results;
    },
    getPackageTestTestFail: (state) => {
      state.loadingPackageTestTests = false;
    },
    getSpecificTestRequest: (state, payload) => {
      state.loadingPackageTestTests = true;
    },
    getSpecificTestSuccess: (state, { payload: { results } }) => {
      state.loadingPackageTestTests = false;
      state.testLists = results;
    },
    getSpecificTestFail: (state) => {
      state.loadingPackageTestTests = false;
    },
    createPackageTestRequest: (state, payload) => {
      state.loadingCreatePackageTest = true;
      state.closeModal = true;
    },
    createPackageTestSuccess: (state) => {
      state.loadingCreatePackageTest = false;
    },
    createPackageTestFail: (state) => {
      state.loadingCreatePackageTest = false;
    },
    //
    getPackageTestByIdRequest: (state, payload) => {
      state.edit=true;
    },
    packageTestEditSuccess: (state, { payload }) => {
      state.packageTest = payload;
    },
    packageTestEditFail: (state) => {
      state.edit = false;


    },
    //
    updatePackageTestRequest: (state, payload) => {
      state.loadingCreatePackageTest = true;
    },
    updatePackageTestSuccess: (state) => {
      state.loadingCreatePackageTest = false;
    },
    updatePackageTestFail: (state) => {
      state.loadingCreatePackageTest = false;
    },
    clearPackageTestData: (state) => {
      state.edit = false;
      state.packageTest = null;
      state.loadingPackageTest = false;
    },
  },
});
// Action creators are generated for each case reducer function
export const {
  loadingPackageTest,
  getPackageTestRequest,
  getPackageTestSuccess,
  getPackageTestFail,
  getPackageTestNextRequest,
  getPackageTestPreviousRequest,
  getPackageTestDepartmentRequest,
  getPackageTestDepartmentSuccess,
  getPackageTestDepartmentFail,
  getPackageTestListRequest,
  getPackageTestListSuccess,
  getPackageTestListFail,
  loadingPackageTestTestsReq,
  getPackageTestTestRequest,
  getPackageTestTestSuccess,
  getPackageTestTestFail,
  getSpecificTestRequest,
  getSpecificTestSuccess,
  getSpecificTestFail,
  createPackageTestRequest,
  createPackageTestSuccess,
  createPackageTestFail,
  //
  getPackageTestByIdRequest,
  packageTestEditSuccess,
  packageTestEditFail,
  //
  updatePackageTestRequest,
  updatePackageTestSuccess,
  updatePackageTestFail,
  clearPackageTestData,
} = PackageTest.actions;
export default PackageTest.reducer;
