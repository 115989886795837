import {
  createPackageTestFail,
  createPackageTestRequest,
  createPackageTestSuccess,
  getPackageTestSuccess,
  getPackageTestFail,
  getPackageTestDepartmentRequest,
  getPackageTestDepartmentSuccess,
  getPackageTestDepartmentFail,
  getPackageTestListRequest,
  getPackageTestListSuccess,
  getPackageTestListFail,
  getPackageTestTestRequest,
  getPackageTestTestSuccess,
  getPackageTestTestFail,
  getSpecificTestRequest,
  getSpecificTestSuccess,
  getSpecificTestFail,
  updatePackageTestFail,
  updatePackageTestSuccess,
  getPackageTestRequest,
  updatePackageTestRequest,
  getPackageTestNextRequest,
  getPackageTestPreviousRequest,
  clearPackageTestData,
  getPackageTestByIdRequest,
  packageTestEditSuccess,
  packageTestEditFail,
} from "./packageTestSlice";
import { mergeMap } from "rxjs";
import {
  createPackageTest,
  getPackageTest,
  updatePackageTest,
  getPackageTestList,
  getPackageTestTest,
  getPackageTestDepartment,
  getSpecificTest,
  getPackageTestById,
} from "./api";
import { map, filter, tap } from "rxjs/operators";
import { Observable } from "rxjs";
import { Action } from "@reduxjs/toolkit";
import { combineEpics } from "redux-observable";
import {
  alertErrorAction,
  alertSuccessAction,
  closeModal,
} from "../../../CommonAppRedux/CommonAppSlice";
import { getNext, getPrevious } from "../../../CommonAppRedux/api";
import {
  dispatchAction,
  stateAction,
} from "../../../../AppUtils/Utils/globalTypes";
import messages from "../../../../AppUtils/Utils/validationConstants";

export const controller = new AbortController();

// get PackageTest epic
const getPackageTestEpic = (
  action$: Observable<Action>,
  _: stateAction,
  { dispatch }: dispatchAction
) =>
  action$.pipe(
    filter(getPackageTestRequest.match),
    mergeMap(async (action) => {
      try {
        const response = await getPackageTest(action.payload);
        return { payload: response.data };
      } catch (e) {
        return { error: e };
      }
    }),
    map((action) =>
      action?.payload
        ? getPackageTestSuccess(action?.payload)
        : getPackageTestFail()
    )
  );

const getPackageTestByIdEpic = (
  action$: Observable<Action>,
  _: stateAction,
  { dispatch }: dispatchAction
) =>
  action$.pipe(
    filter(getPackageTestByIdRequest.match),
    mergeMap(async (action) => {
      try {
        const response = await getPackageTestById(action.payload);
        return { payload: response.data };
      } catch (e) {
        return { error: e };
      }
    }),
    map((action) =>
      action?.payload
        ? packageTestEditSuccess(action?.payload)
        : packageTestEditFail()
    )
  );

//get next
const getPackageTestNext = (
  action$: Observable<Action>,
  _: stateAction,
  { dispatch }: dispatchAction
) =>
  action$.pipe(
    filter(getPackageTestNextRequest.match),
    mergeMap(async (action) => {
      try {
        const response = await getNext(action.payload);
        return { payload: response.data };
      } catch (e) {
        return { error: e };
      }
    }),
    map((action) =>
      action?.payload
        ? getPackageTestSuccess(action?.payload)
        : getPackageTestFail()
    )
  );

//get previous
const getPackageTestPrevious = (
  action$: Observable<Action>,
  _: stateAction,
  { dispatch }: dispatchAction
) =>
  action$.pipe(
    filter(getPackageTestPreviousRequest.match),
    mergeMap(async (action) => {
      try {
        const response = await getPrevious(action.payload);
        return { payload: response.data };
      } catch (e) {
        return { error: e };
      }
    }),
    map((action) =>
      action?.payload
        ? getPackageTestSuccess(action?.payload)
        : getPackageTestFail()
    )
  );
// get PackageTest lists
const getAllPackageTestLists = (
  action$: Observable<Action>,
  _: stateAction,
  { dispatch }: dispatchAction
) =>
  action$.pipe(
    filter(getPackageTestListRequest.match),
    mergeMap(async (action) => {
      try {
        const response = await getPackageTestList(action?.payload);
        return { payload: response.data };
      } catch (e) {
        return { error: e };
      }
    }),
    map((action) =>
      action?.payload
        ? getPackageTestListSuccess(action?.payload)
        : getPackageTestListFail()
    )
  );
// get PackageTest department
const getAllPackageTestDepartments = (
  action$: Observable<Action>,
  _: stateAction,
  { dispatch }: dispatchAction
) =>
  action$.pipe(
    filter(getPackageTestDepartmentRequest.match),
    mergeMap(async (action) => {
      try {
        const response = await getPackageTestDepartment(action.payload);
        return { payload: response.data };
      } catch (e) {
        return { error: e };
      }
    }),
    map((action) =>
      action?.payload
        ? getPackageTestDepartmentSuccess(action?.payload)
        : getPackageTestDepartmentFail()
    )
  );
// get PackageTest tests
const getAllPackageTestTests = (
  action$: Observable<Action>,
  _: stateAction,
  { dispatch }: dispatchAction
) =>
  action$.pipe(
    filter(getPackageTestTestRequest.match),
    mergeMap(async (action) => {
      try {
        const response = await getPackageTestTest(action.payload);
        return { payload: response.data };
      } catch (e) {
        return { error: e };
      }
    }),
    map((action) =>
      action?.payload
        ? getPackageTestTestSuccess(action?.payload)
        : getPackageTestTestFail()
    )
  );
// get PackageTest category
const getSpecificTests = (
  action$: Observable<Action>,
  _: stateAction,
  { dispatch }: dispatchAction
) =>
  action$.pipe(
    filter(getSpecificTestRequest.match),
    mergeMap(async (action) => {
      try {
        const response = await getSpecificTest(action.payload);
        return { payload: response.data };
      } catch (e) {
        return { error: e };
      }
    }),
    map((action) =>
      action?.payload
        ? getSpecificTestSuccess(action?.payload)
        : getSpecificTestFail()
    )
  );
//create PackageTest epic
const createPackageTestEpic = (
  action$: Observable<Action>,
  _: stateAction,
  { dispatch }: dispatchAction
) =>
  action$.pipe(
    filter(createPackageTestRequest.match),
    mergeMap(async ({ payload: { values, rowsPerPage, page } }) => {
      const { packageTest, department, active, tests } = values;
      try {
        const body = JSON.stringify({
          packageTest: packageTest?.id,
          department: department?.id,
          active,
          tests,
        });
        const response = await createPackageTest(body);
        if (response) {
          dispatch(getPackageTestRequest({ rowsPerPage, page }));
          dispatch(alertSuccessAction(messages.createMessage));
          dispatch(closeModal());
        }
        return { payload: { response } };
      } catch (e) {
        dispatch(alertErrorAction(messages.createFailMessage));
        return { error: e };
      }
    }),
    map((action) => {
      return action?.payload
        ? createPackageTestSuccess()
        : createPackageTestFail();
    })
  );

//update PackageTest epic
const updatePackageTestEpic = (
  action$: Observable<Action>,
  _: stateAction,
  { dispatch }: dispatchAction
) =>
  action$.pipe(
    filter(updatePackageTestRequest.match),
    mergeMap(async ({ payload: { values, id, rowsPerPage, page } }) => {
      const { packageTest, active, tests, remarks } = values;
      try {
        const body = JSON.stringify({
          packageTest: packageTest?.id,
          active,
          tests,
          remarks,
        });
        const response = await updatePackageTest(body, id);
        if (response) {
          dispatch(getPackageTestRequest({ rowsPerPage, page }));
          dispatch(alertSuccessAction(messages.updateMessage));
          dispatch(clearPackageTestData());
          dispatch(closeModal());
        }
        return { payload: { response, rowsPerPage } };
      } catch (e) {
        dispatch(alertErrorAction(messages.updateFailMessage));
        return { error: e };
      }
    }),
    map((action) =>
      action?.payload ? updatePackageTestSuccess() : updatePackageTestFail()
    )
  );

export const packageTestEpics = combineEpics(
  getPackageTestEpic,
  createPackageTestEpic,
  updatePackageTestEpic,
  getPackageTestNext,
  getPackageTestPrevious,
  getAllPackageTestLists,
  getAllPackageTestTests,
  getAllPackageTestDepartments,
  getSpecificTests,
  getPackageTestByIdEpic
);
