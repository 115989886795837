import { axiosInstance } from "../../../../AppUtils/Utils/axios";
import { filterApiData } from "../../../../AppUtils/Utils/appFunctions";
import {
  getApiProps,
  searchProps,
} from "../../../../AppUtils/Utils/globalTypes";

const footerNoteModURL = "api/v1/lab-app/test-footer-note";

//get FooterNote data api
export const getFooterNote = (data: getApiProps) => {
  const { rowsPerPage, page } = data;
  return axiosInstance.get(
    `${footerNoteModURL}?offset=${rowsPerPage * page}&limit=${rowsPerPage}${
      data?.filterData ? filterApiData(data?.filterData) : ""
    }`
  );
};

export const getFooterNoteTest = ({ rowsPerPage }: any) => {
  return axiosInstance.get(
    `${footerNoteModURL}/test?offset=0&limit=${rowsPerPage}`
  );
};
//handle search
export const searchFooterNote = ({ rowsPerPage, search }: searchProps) => {
  return `${footerNoteModURL}?offset=0&limit=${rowsPerPage}&search=${search}`;
};
//create FooterNote
export const createFooterNote = (body: string | FormData) =>
  axiosInstance.post(`${footerNoteModURL}`, body);
//update FooterNote
export const updateFooterNote = (body: string | FormData, id: number) =>
  axiosInstance.patch(`${footerNoteModURL}/${id}`, body);

//get FooterNote by id
export const getFooterNoteById = (id: number) =>
  axiosInstance.get(`${footerNoteModURL}/${id}`);