import { createSlice } from "@reduxjs/toolkit";
import { repairOrderInitialState } from "./types";
const initialState: repairOrderInitialState = {
  repairOrders: [],
  loadingRepairOrder: false,
  loadingCreateRepairOrder: false,
  edit: false,
  repairOrder: null,
  repairOrderItems: [],
  loadingRepairOrderItems: false,
  repairOrderCustomers: [],
  loadingRepairOrderCustomers: false,
  //
  loadingUpdateRepairReceivedStatus: false,
  loadingUpdateRepairDispatchedStatus: false,
  //
  repairOrderSpecialist: [],
  loadingRepairOrderSpecialist: false,
  loadingUpdateDeviceOrderSpecialist: false,
  //
  count: 0,
  previous: "",
  next: "",
};

export const RepairOrder = createSlice({
  name: "RepairOrder Reducer",
  initialState,
  reducers: {
    loadingRepairOrder: (state) => {
      state.loadingRepairOrder = true;
    },
    getRepairOrderRequest: (state, payload) => {
      state.loadingRepairOrder = true;
    },
    getRepairOrderSuccess: (
      state,
      { payload: { results, count, next, previous } }
    ) => {
      state.loadingRepairOrder = false;
      state.repairOrders = results;
      state.count = count;
      state.next = next;
      state.previous = previous;
    },
    getRepairOrderFail: (state) => {
      state.loadingRepairOrder = false;
    },
    //
    getRepairOrderNextRequest: (state, payload) => {
      state.loadingRepairOrder = true;
    },
    getRepairOrderPreviousRequest: (state, payload) => {
      state.loadingRepairOrder = true;
    },
    //
    createRepairOrderRequest: (state, payload) => {
      state.loadingCreateRepairOrder = true;
    },
    createRepairOrderSuccess: (state) => {
      state.loadingCreateRepairOrder = false;
    },
    createRepairOrderFail: (state) => {
      state.loadingCreateRepairOrder = false;
    },
    //
    getRepairOrderByIdRequest: (state, payload) => {
      state.edit = true;
    },
    repairOrderEditSuccess: (state, { payload }) => {
      state.edit = true;
      state.repairOrder = payload;
    },
    repairOrderEditFail: (state) => {
      state.edit = false;
    },
    //
    updateRepairOrderRequest: (state, payload) => {
      state.loadingCreateRepairOrder = true;
    },
    updateRepairOrderSuccess: (state) => {
      state.loadingCreateRepairOrder = false;
    },
    updateRepairOrderFail: (state) => {
      state.loadingCreateRepairOrder = false;
    },
    //
    loadingRepairOrderItemRequest: (state) => {
      state.loadingRepairOrderItems = true;
    },
    getRepairOrderItemRequest: (state, { payload }) => {
      state.loadingRepairOrderItems = true;
    },
    getRepairOrderItemSuccess: (state, { payload: { results } }) => {
      state.loadingRepairOrderItems = false;
      state.repairOrderItems = results;
    },
    getRepairOrderItemFail: (state) => {
      state.loadingRepairOrderItems = false;
    },
    //
    loadingRepairOrderCustomerRequest: (state) => {
      state.loadingRepairOrderCustomers = true;
    },
    getRepairOrderCustomerRequest: (state, { payload }) => {
      state.loadingRepairOrderCustomers = true;
    },
    getRepairOrderCustomerSuccess: (state, { payload: { results } }) => {
      state.loadingRepairOrderCustomers = false;
      state.repairOrderCustomers = results;
    },
    getRepairOrderCustomerFail: (state) => {
      state.loadingRepairOrderCustomers = false;
    },
    //
    clearRepairOrderData: (state) => {
      state.edit = false;
      state.repairOrder = null;
    },
    // update received status
    updateRepairReceivedStatusRequest: (state, payload) => {
      state.loadingUpdateRepairReceivedStatus = true;
    },
    updateRepairReceivedStatusSuccess: (state) => {
      state.loadingUpdateRepairReceivedStatus = false;
    },
    updateRepairReceivedStatusFail: (state) => {
      state.loadingUpdateRepairReceivedStatus = false;
    },
    // update dispatched status
    updateRepairDispatchedStatusRequest: (state, payload) => {
      state.loadingUpdateRepairDispatchedStatus = true;
    },
    updateRepairDispatchedStatusSuccess: (state) => {
      state.loadingUpdateRepairDispatchedStatus = false;
    },
    updateRepairDispatchedStatusFail: (state) => {
      state.loadingUpdateRepairDispatchedStatus = false;
    },
    // get device order specialist
    loadingRepairOrderSpecialistRequest: (state) => {
      state.loadingRepairOrderSpecialist = true;
    },
    getRepairOrderSpecialistRequest: (state, payload) => {
      state.loadingRepairOrderSpecialist = true;
    },
    getRepairOrderSpecialistSuccess: (state, { payload: { results } }) => {
      state.loadingRepairOrderSpecialist = false;
      state.repairOrderSpecialist = results;
    },
    getRepairOrderSpecialistFail: (state) => {
      state.loadingRepairOrderSpecialist = false;
    },
    // update device order specialist
    updateDeviceOrderSpecialistRequest: (state, payload) => {
      state.loadingUpdateDeviceOrderSpecialist = true;
    },
    updateDeviceOrderSpecialistSuccess: (state) => {
      state.loadingUpdateDeviceOrderSpecialist = false;
    },
    updateDeviceOrderSpecialistFail: (state) => {
      state.loadingUpdateDeviceOrderSpecialist = false;
    },
  },
});
// Action creators are generated for each case reducer function
export const {
  loadingRepairOrder,
  getRepairOrderRequest,
  getRepairOrderSuccess,
  getRepairOrderFail,
  //
  getRepairOrderNextRequest,
  getRepairOrderPreviousRequest,
  //
  createRepairOrderRequest,
  createRepairOrderSuccess,
  createRepairOrderFail,
  //
  getRepairOrderByIdRequest,
  repairOrderEditSuccess,
  repairOrderEditFail,
  //
  updateRepairOrderRequest,
  updateRepairOrderSuccess,
  updateRepairOrderFail,
  //
  getRepairOrderItemRequest,
  loadingRepairOrderItemRequest,
  getRepairOrderItemSuccess,
  getRepairOrderItemFail,
  //
  getRepairOrderCustomerRequest,
  loadingRepairOrderCustomerRequest,
  getRepairOrderCustomerSuccess,
  getRepairOrderCustomerFail,
  //
  clearRepairOrderData,
  //
  updateRepairReceivedStatusRequest,
  updateRepairReceivedStatusSuccess,
  updateRepairReceivedStatusFail,
  //
  updateRepairDispatchedStatusRequest,
  updateRepairDispatchedStatusSuccess,
  updateRepairDispatchedStatusFail,
  //
  loadingRepairOrderSpecialistRequest,
  getRepairOrderSpecialistRequest,
  getRepairOrderSpecialistSuccess,
  getRepairOrderSpecialistFail,
  updateDeviceOrderSpecialistRequest,
  updateDeviceOrderSpecialistSuccess,
  updateDeviceOrderSpecialistFail,
} = RepairOrder.actions;
export default RepairOrder.reducer;
