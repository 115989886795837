import React from "react";
import { headerProps } from "./types";
import clsx from "clsx";
import "../Sidebar/Sidebar.css";
import {
  AppBar,
  Search,
  SearchIconWrapper,
  StyledInputBase,
} from "../Sidebar/SidebarStyles";
import { headerStyles } from "./HeaderStyles";
import MenuIcon from "@mui/icons-material/Menu";
import SearchIcon from "@mui/icons-material/Search";
import {
  Avatar,
  Box,
  Chip,
  Grid,
  IconButton,
  Toolbar,
  Typography,
} from "@mui/material";
import "./header.css";
import { InputChangeEvent } from "../../../AppUtils/Utils/globalTypes";
import {
  useAppDispatch,
  useAppSelector,
} from "../../../AppUtils/Utils/appHooks";
import { searchData } from "../../../Pages/CommonAppRedux/CommonAppSlice";
import { commonAppSelector } from "../../../Pages/CommonAppRedux/selector";
import useCustomerData from "../../../Pages/ClinicPages/PatientDetails/PatientDetailsPages/CustomerData";
import { patientDetailSelector } from "../../../Pages/ClinicPages/PatientDetails/Redux/selector";

const Header = ({ open, setOpen }: headerProps) => {
  const handleDrawer = () => {
    setOpen(!open);
  };
  const { search, hideHeader, showPatientCard } =
    useAppSelector(commonAppSelector);
  const dispatch = useAppDispatch();
  const { selectedPatient } = useAppSelector(patientDetailSelector);

  const handleSearch = ({ target: { value } }: InputChangeEvent) => {
    dispatch(searchData(value));
    // setSearch(e?.target?.value.toLowerCase());
  };
  const updateCustomer = useCustomerData();
  const classes = headerStyles();
  return (
    <>
      <AppBar position="fixed" open={open} className="secondary-header">
        <Toolbar className={classes.toolbar}>
          <>
            <Typography
              component="h1"
              variant="h4"
              color="inherit"
              noWrap
              className={classes.title}
            >
              <IconButton
                className={clsx(
                  classes.menuButton,
                  !open && classes.menuButtonHidden
                )}
                onClick={handleDrawer}
              >
                <MenuIcon />
              </IconButton>
            </Typography>
          </>
          {!hideHeader && (
            <Grid
              className={classes.searchAppbar}
              id="main-searchBar-container"
            >
              <Search className="search-wrapper">
                <StyledInputBase
                  value={search}
                  className="search-wrapper-input"
                  placeholder="Search..."
                  inputProps={{ "aria-label": "search" }}
                  onChange={handleSearch}
                />
                <SearchIconWrapper className="search-icon-wrapper">
                  <SearchIcon />
                </SearchIconWrapper>
              </Search>
            </Grid>
          )}
          {showPatientCard && (
            <Box sx={{ width: "300px", textAlign: "right" }}>
              <Chip
                className="selected-patient-chip"
                avatar={
                  <Avatar
                    src={updateCustomer?.firstName}
                    alt={updateCustomer?.firstName}
                    sx={{ background: "#FFFFFF" }}
                  />
                }
                label={`${updateCustomer?.fullName} ${updateCustomer?.customerNoFull}`}
              />
            </Box>
          )}
          {selectedPatient && (
            <Grid item>
              <Chip
                avatar={<Avatar>{selectedPatient?.firstName?.charAt()}</Avatar>}
                label={
                  selectedPatient?.fullName +
                  " " +
                  `(${selectedPatient?.customerNoFull})`
                }
                className="selected-patient-chip"
              />
            </Grid>
          )}
        </Toolbar>
      </AppBar>
    </>
  );
};
export default React.memo(Header);
