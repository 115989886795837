import React, { Suspense } from "react";
import { Redirect, Route } from "react-router-dom";
import { Switch } from "react-router-dom";
import { useAppSelector } from "../AppUtils/Utils/appHooks";
import { commonAppSelector } from "../Pages/CommonAppRedux/selector";
import ClinicRoutes from "./ClinicRoutes";
import CoreSetupRoutes from "./CoreSetupRoutes";
import InventoryRoutes from "./InventoryRoutes";
import LabRoutes from "./LabRoutes";
import { lazyWithReload } from "./Utils";
const PageNotFound = lazyWithReload(
  () => import("../Component/ErrorPages/PageNotFound")
);
// lab components imports
const PrivateRoutes = () => {
  const { globalOrganizationRules, globalOrganizations } =
    useAppSelector(commonAppSelector);
  return (
    <>
      {/* <ErrorBoundary FallbackComponent={ErrorHandler}> */}
      <Suspense fallback={<></>}>
        {/* <ProtectedRoutes exact path="/" component={Profile} permission="" /> */}
        {/* {globalOrganizationRules === null || globalOrganizations === null ? (
          <>
            <Redirect
              to={
                globalOrganizationRules === null
                  ? "/core-setup/organization-rule"
                  : "/core-setup/organization-setup"
              }
              exact
            />
            <CoreSetupRoutes />
          </>
        ) : ( */}
        <>
          <CoreSetupRoutes />
          <InventoryRoutes />
          <LabRoutes />
          <ClinicRoutes />
          <Switch>
            <Redirect from="/" to="/profile" exact />
            {/* add page not found component if url is incorrect */}
            {/* <ProtectedRoutes exact path="*" component={PageNotFound} /> */}
          </Switch>
        </>
        {/* )} */}
      </Suspense>
      {/* </ErrorBoundary> */}
    </>
  );
};

export default PrivateRoutes;
