import { axiosInstance } from "../../../../AppUtils/Utils/axios";
import { filterApiData } from "../../../../AppUtils/Utils/appFunctions";
import {
  getApiProps,
  searchProps,
} from "../../../../AppUtils/Utils/globalTypes";

export const referralEditModURL = "/api/v1/clinic-sale-app/referrer-edit";
// export const getReferralEdit = (data: getApiProps) => {
//   const { rowsPerPage, page } = data;
//   return axiosInstance.get(
//     `/api/v1/core-app/referralEdit?offset=${rowsPerPage * page}&limit=${rowsPerPage}${
//       data?.filterData ? filterApiData(data?.filterData) : ""
//     }`
//   );
// };
// export const searchReferralEdit = ({ rowsPerPage, search }: searchProps) => {
//   return `/api/v1/core-app/referralEdit?offset=0&limit=${rowsPerPage}&search=${search}`;
// };
// export const createReferralEdit = (body: string | FormData) =>
//   axiosInstance.post(`/api/v1/core-app/referralEdit`, body);
// //update ReferralEdit
export const updateReferralEdit = (body: string | FormData, id: number) =>
  axiosInstance.patch(`${referralEditModURL}/${id}`, body);

export const getAllReferralEditCustomer = (rowsPerPage: number) =>
  axiosInstance.get(
    `${referralEditModURL}/customer?offset=0&limit=${rowsPerPage}`
  );

export const getSaleNo = (id: number) =>
  axiosInstance.get(`${referralEditModURL}/sale?customer=${id}`);

export const getAllReferralEditReferral = (rowsPerPage: number) =>
  axiosInstance.get(
    `${referralEditModURL}/referrer?offset=0&limit=${rowsPerPage}`
  );
