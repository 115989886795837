import {
  createDoctorFail,
  createDoctorRequest,
  createDoctorSuccess,
  //
  getDoctorRequest,
  getDoctorFail,
  getDoctorSuccess,
  //
  updateDoctorRequest,
  updateDoctorFail,
  updateDoctorSuccess,
  //
  getDoctorNextRequest,
  getDoctorPreviousRequest,
  //
  clearDoctorData,
  getDoctorByIdRequest,
  //
  doctorEditSuccess,
  doctorEditFail,
  //
  getDoctorTitleRequest,
  getDoctorTitleFail,
  getDoctorTitleSuccess,
  //
  getDoctorClinicRequest,
  getDoctorClinicSuccess,
  getDoctorClinicFail,
} from "./doctorSlice";
import { mergeMap } from "rxjs";
import {
  createDoctor,
  getDoctor,
  getDoctorById,
  getDoctorTitle,
  updateDoctor,
  getDoctorClinic,
} from "./api";
import { map, filter } from "rxjs/operators";
import { Observable } from "rxjs";
import { Action } from "@reduxjs/toolkit";
import { combineEpics } from "redux-observable";
import {
  alertErrorAction,
  alertSuccessAction,
  closeModal,
} from "../../../CommonAppRedux/CommonAppSlice";
import { getNext, getPrevious } from "../../../CommonAppRedux/api";
import {
  dispatchAction,
  stateAction,
} from "../../../../AppUtils/Utils/globalTypes";
import messages from "../../../../AppUtils/Utils/validationConstants";
export const controller = new AbortController();

//get doctor epic
const getDoctorEpic = (
  action$: Observable<Action>,
  _: stateAction,
  { dispatch }: dispatchAction
) =>
  action$.pipe(
    filter(getDoctorRequest.match),
    mergeMap(async (action) => {
      try {
        const response = await getDoctor(action.payload);
        return { payload: response.data };
      } catch (e) {
        return { error: e };
      }
    }),
    map((action) =>
      action?.payload ? getDoctorSuccess(action?.payload) : getDoctorFail()
    )
  );

//get doctor title
const getDoctorTitleEpic = (
  action$: Observable<Action>,
  _: stateAction,
  { dispatch }: dispatchAction
) =>
  action$.pipe(
    filter(getDoctorTitleRequest.match),
    mergeMap(async (action: any) => {
      try {
        const response = await getDoctorTitle(action.payload);
        return { payload: response.data };
      } catch (e) {
        return { error: e };
      }
    }),
    map((action) =>
      action?.payload
        ? getDoctorTitleSuccess(action?.payload)
        : getDoctorTitleFail()
    )
  );

//get doctor clinic
const getDoctorClinicEpic = (
  action$: Observable<Action>,
  _: stateAction,
  { dispatch }: dispatchAction
) =>
  action$.pipe(
    filter(getDoctorClinicRequest.match),
    mergeMap(async (action: any) => {
      try {
        const response = await getDoctorClinic(action.payload);
        return { payload: response.data };
      } catch (e) {
        return { error: e };
      }
    }),
    map((action) =>
      action?.payload
        ? getDoctorClinicSuccess(action?.payload)
        : getDoctorClinicFail()
    )
  );

//get doctor by id
const getDoctorByIdEpic = (
  action$: Observable<Action>,
  _: stateAction,
  { dispatch }: dispatchAction
) =>
  action$.pipe(
    filter(getDoctorByIdRequest.match),
    mergeMap(async (action) => {
      try {
        const response = await getDoctorById(action.payload);
        return { payload: response.data };
      } catch (e) {
        return { error: e };
      }
    }),
    map((action) =>
      action?.payload ? doctorEditSuccess(action?.payload) : doctorEditFail()
    )
  );

//get next
const getDoctorNext = (
  action$: Observable<Action>,
  _: stateAction,
  { dispatch }: dispatchAction
) =>
  action$.pipe(
    filter(getDoctorNextRequest.match),
    mergeMap(async (action) => {
      try {
        const response = await getNext(action.payload);
        return { payload: response.data };
      } catch (e) {
        return { error: e };
      }
    }),
    map((action) =>
      action?.payload ? getDoctorSuccess(action?.payload) : getDoctorFail()
    )
  );

//get previous
const getDoctorPrevious = (
  action$: Observable<Action>,
  _: stateAction,
  { dispatch }: dispatchAction
) =>
  action$.pipe(
    filter(getDoctorPreviousRequest.match),
    mergeMap(async (action) => {
      try {
        const response = await getPrevious(action.payload);
        return { payload: response.data };
      } catch (e) {
        return { error: e };
      }
    }),
    map((action) =>
      action?.payload ? getDoctorSuccess(action?.payload) : getDoctorFail()
    )
  );

//create doctor epic
const createDoctorEpic = (
  action$: Observable<Action>,
  _: stateAction,
  { dispatch }: dispatchAction
) =>
  action$.pipe(
    filter(createDoctorRequest.match),
    mergeMap(
      async ({ payload: { values, rowsPerPage, page, setShowDoctor } }) => {
        try {
          const body = new FormData();
          for (let [key, value] of Object.entries(values)) {
            // @ts-ignore
            body.append(`${key}`, value);
          }
          const response = await createDoctor(body);
          if (response) {
            dispatch(getDoctorRequest({ rowsPerPage, page }));
            dispatch(alertSuccessAction(messages.createMessage));
            // dispatch(closeModal());
            setShowDoctor ? setShowDoctor(false) : dispatch(closeModal());
          }
          return { payload: { response } };
        } catch (e) {
          dispatch(alertErrorAction(messages.createFailMessage));
          return { error: e };
        }
      }
    ),
    map((action) => {
      return action?.payload ? createDoctorSuccess() : createDoctorFail();
    })
  );

//update doctor epic
const updateDoctorEpic = (
  action$: Observable<Action>,
  _: stateAction,
  { dispatch }: dispatchAction
) =>
  action$.pipe(
    filter(updateDoctorRequest.match),
    mergeMap(async ({ payload: { values, id, rowsPerPage, page, search } }) => {
      try {
        let body = new FormData();
        for (let [key, value] of Object.entries(values)) {
          // @ts-ignore
          body.append(`${key}`, value);
        }
        const response = await updateDoctor(body, id);
        if (response) {
          dispatch(getDoctorRequest({ rowsPerPage, page, search }));
          dispatch(alertSuccessAction(messages.updateMessage));
          dispatch(closeModal());
          dispatch(clearDoctorData());
        }
        return { payload: { response, rowsPerPage } };
      } catch (e) {
        dispatch(alertErrorAction(messages.updateFailMessage));
        return { error: e };
      }
    }),
    map((action) =>
      action?.payload ? updateDoctorSuccess() : updateDoctorFail()
    )
  );

export const doctorEpics = combineEpics(
  getDoctorEpic,
  createDoctorEpic,
  updateDoctorEpic,
  getDoctorNext,
  getDoctorPrevious,
  getDoctorByIdEpic,
  getDoctorTitleEpic,
  getDoctorClinicEpic
);
