import { Grid, Typography } from "@mui/material";
import WarningIcon from "@mui/icons-material/Warning";
import informationIcon from "../../Assets/information-icon.svg";

import "./noData.css";
export interface NoDataProps {
  hidePreferences?: boolean;
}
const NoData = ({ hidePreferences }: NoDataProps) => {
  return (
    <>
      <Typography
        variant="body1"
        color="initial"
        className="no-data-container"
        sx={{ height: "240px" }}
      >
        <img src={informationIcon} alt="icon" />
        <br />
        No Data To Display
      </Typography>
    </>
  );
};
export default NoData;
