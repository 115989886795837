import { createSlice } from "@reduxjs/toolkit";
import produce from "immer";
import {
  resultEntry,
  resultEntryInitialState,
  sensitiveMedicine,
} from "../types";
const initialState: resultEntryInitialState = {
  // get result entries data
  resultEntries: [],
  loadingResultEntry: false,
  loadingCreateResultEntry: false,
  loadingReportFooter: false,
  loadingCollectedTest: false,
  loadingCollectedTestDetails: false,
  loadingCollectedTestSensitiveMedicine: false,
  loadingSpecificTestSensitiveMedicine: false,
  closeResultEntryModal: false,
  loadingReportComment: false,
  resultEntryReportComments: [],
  collectedResultEntries: [],
  // state to update values after result entry
  enteredResultEntryReportComment: [],
  enteredResultEntryReportFooter: [],
  resultEntryCollectedTestDetails: [],
  collectedTestSensitiveMedicine: [],
  testSensitiveMedicines: [],
  sensitiveTypes: null,
  collectedTests: [],
  reportFooters: [],
  // leftReportFooters: [],
  // centerReportFooters: [],
  // rightReportFooters: [],
  resultEntry: null,
  count: 0,
  next: "",
  previous: "",
  edit: false,
};
export const ResultEntry = createSlice({
  name: "resultEntry",
  initialState,
  reducers: {
    // get result entry listing
    loadingResultEntry: (state) => {
      state.loadingResultEntry = true;
    },
    getResultEntryRequest: (state, payload) => {
      state.loadingResultEntry = true;
    },
    getResultEntrySuccess: (
      state,
      { payload: { results, count, next, previous } }
    ) => {
      state.loadingResultEntry = false;
      state.closeResultEntryModal = false;
      state.resultEntries = results;
      state.count = count;
      state.next = next;
      state.previous = previous;
    },
    getResultEntryFail: (state) => {
      state.loadingResultEntry = false;
    },
    getResultEntryNextRequest: (state, payload) => {
      state.loadingResultEntry = true;
    },
    getResultEntryPreviousRequest: (state, payload) => {
      state.loadingResultEntry = true;
    },
    createResultEntryRequest: (state, payload) => {
      state.loadingCreateResultEntry = true;
    },
    // create result entry
    createResultEntrySuccess: (state) => {
      state.loadingCreateResultEntry = false;
      state.closeResultEntryModal = true;
    },
    createResultEntryFail: (state) => {
      state.loadingCreateResultEntry = false;
    },
    // edit result entry
    editResultEntryRequest: (state, payload) => {
      state.loadingCollectedTestDetails = true;
    },
    editResultEntrySuccess: (state, { payload: { results } }) => {
      state.loadingCollectedTestDetails = false;
      state.edit = true;
      state.collectedResultEntries = results.map((resultEntry: resultEntry) => {
        resultEntry.testTestFindingReferenceRange =
          resultEntry.testTestFindingReferenceRange
            ? resultEntry.testTestFindingReferenceRange
            : [];
        return resultEntry;
      });
    },
    editResultEntryFail: (state) => {
      state.loadingCollectedTestDetails = false;
    },
    updateResultEntryRequest: (state, payload) => {
      state.loadingCreateResultEntry = true;
    },
    updateResultEntrySuccess: (state) => {
      state.loadingCreateResultEntry = false;
      state.closeResultEntryModal = true;
      state.edit = false;
    },
    updateResultEntryFail: (state) => {
      state.loadingCreateResultEntry = false;
    },
    // get collected test
    getResultEntryCollectedTestRequest: (state, { payload }) => {
      state.loadingCollectedTest = true;
    },
    getResultEntryCollectedTestSuccess: (state, { payload: { results } }) => {
      state.collectedTests = results;
      state.loadingCollectedTest = false;
    },
    getResultEntryCollectedTestFail: (state) => {
      state.loadingCollectedTest = false;
    },
    // get report footer
    loadingResultEntryReportFooter: (state) => {
      state.loadingReportFooter = true;
    },
    getResultEntryReportFooterRequest: (state, payload) => {
      state.loadingReportFooter = true;
    },
    getResultEntryReportFooterSuccess: (state, { payload: { results } }) => {
      // state.resultEntryReportFooters = results;
      state.reportFooters = results;
      // state.leftReportFooters = filterById(results, 1, "alignment");
      // state.centerReportFooters = filterById(results, 3, "alignment");
      // state.rightReportFooters = filterById(results, 2, "alignment");
      state.loadingReportFooter = false;
    },
    getResultEntryReportFooterFail: (state) => {
      state.loadingReportFooter = false;
    },
    getResultEntryCollectedTestDetailsRequest: (state, payload) => {
      state.loadingCollectedTestDetails = true;
    },
    getResultEntryCollectedTestDetailsSuccess: (
      state,
      { payload: { results } }
    ) => {
      state.resultEntryCollectedTestDetails = results;
      state.loadingCollectedTestDetails = false;
    },
    getResultEntryCollectedTestDetailsFail: (state) => {
      state.loadingCollectedTestDetails = false;
    },
    //report comment after result entry
    getEnteredResultEntryReportCommentRequest: (state, payload) => {
      state.loadingReportComment = true;
    },
    getEnteredResultEntryReportCommentSuccess: (
      state,
      { payload: { results } }
    ) => {
      state.enteredResultEntryReportComment = results;
      state.loadingReportComment = false;
    },
    getEnteredResultEntryReportCommentFail: (state) => {
      state.loadingReportComment = false;
    },
    //report footer after result entry
    getEnteredResultEntryReportFooterRequest: (state, payload) => {
      state.loadingReportFooter = true;
    },
    getEnteredResultEntryReportFooterSuccess: (
      state,
      { payload: { results } }
    ) => {
      state.enteredResultEntryReportFooter = results;
      state.loadingReportFooter = false;
    },
    getEnteredResultEntryReportFooterFail: (state) => {
      state.loadingReportFooter = false;
    },
    // get report comment
    getResultEntryReportCommentRequest: (state) => {
      state.loadingReportComment = true;
    },
    getResultEntryReportCommentSuccess: (state, { payload: { results } }) => {
      state.resultEntryReportComments = results;
      state.loadingReportComment = false;
    },
    getResultEntryReportCommentFail: (state) => {
      state.loadingReportComment = false;
    },
    // get test sensitive medicine
    getResultEntryTestSensitiveMedicineRequest: (state, payload) => {
      state.loadingCollectedTestSensitiveMedicine = true;
    },
    getResultEntryTestSensitiveMedicineSuccess: (
      state,
      { payload: { results } }
    ) => {
      state.collectedTestSensitiveMedicine = results;
      state.loadingCollectedTestSensitiveMedicine = false;
      // state.edit = !state.edit;
    },
    getResultEntryTestSensitiveMedicineFail: (state) => {
      state.loadingCollectedTestSensitiveMedicine = false;
    },
    // get specific test sensitive medicine
    getSpecificTestSensitiveMedicineRequest: (state, payload) => {
      state.loadingSpecificTestSensitiveMedicine = true;
    },
    getSpecificTestSensitiveMedicineSuccess: (
      state,
      { payload: { results } }
    ) => {
      state.testSensitiveMedicines = results?.map(
        (testSensitive: sensitiveMedicine) => {
          const getSensitiveTypes = (data: sensitiveMedicine[]) => {
            return data?.find(
              (sensitiveId) => +sensitiveId?.id! === testSensitive.id!
            );
          };
          let updatedSensitive = getSensitiveTypes(
            state.sensitiveTypes?.sensitive!
          );
          let updatedPartiallySensitive = getSensitiveTypes(
            state.sensitiveTypes?.partiallySensitive!
          );
          let updatedResistive = getSensitiveTypes(
            state.sensitiveTypes?.resistive!
          );
          return produce(testSensitive, (draft) => {
            draft.sensitiveTypes =
              updatedSensitive?.id === testSensitive?.id
                ? updatedSensitive?.sensitiveTypes
                : updatedPartiallySensitive?.id === testSensitive?.id
                ? updatedPartiallySensitive?.sensitiveTypes
                : updatedResistive?.id === testSensitive?.id
                ? updatedResistive?.sensitiveTypes
                : testSensitive.sensitiveTypes ?? null;
          });
        }
      );
      state.loadingSpecificTestSensitiveMedicine = false;
    },
    updateSensitiveTypes: (state, { payload }) => {
      state.sensitiveTypes = payload;
    },
    updateTestSensitiveMedicines: (state, { payload }) => {
      state.testSensitiveMedicines = payload;
    },
    clearTestSensitiveMedicines: (state) => {
      state.testSensitiveMedicines = [];
    },
    getSpecificTestSensitiveMedicineFail: (state) => {
      state.loadingSpecificTestSensitiveMedicine = false;
    },
    clearResultEntryData: (state) => {
      state.loadingResultEntry = false;
      state.edit = false;
      state.resultEntryCollectedTestDetails = [];
      state.collectedTestSensitiveMedicine = [];
      state.resultEntryReportComments = [];
      state.closeResultEntryModal = true;
      state.enteredResultEntryReportFooter = [];
    },
    clearCollectedTestsData: (state) => {
      state.collectedTests = [];
      state.collectedTestSensitiveMedicine = [];
    },
  },
});
// Action creators are generated for each case reducer function
export const {
  // get result entry
  loadingResultEntry,
  getResultEntryRequest,
  getResultEntrySuccess,
  getResultEntryFail,
  getResultEntryNextRequest,
  getResultEntryPreviousRequest,
  // get collected test
  getResultEntryCollectedTestRequest,
  getResultEntryCollectedTestSuccess,
  getResultEntryCollectedTestFail,
  // get report footer
  loadingResultEntryReportFooter,
  getResultEntryReportFooterRequest,
  getResultEntryReportFooterSuccess,
  getResultEntryReportFooterFail,
  // get collected test after result entry
  getResultEntryCollectedTestDetailsRequest,
  getResultEntryCollectedTestDetailsSuccess,
  getResultEntryCollectedTestDetailsFail,
  // get report comment  after result entry
  getEnteredResultEntryReportCommentRequest,
  getEnteredResultEntryReportCommentSuccess,
  getEnteredResultEntryReportCommentFail,
  // get report footer  after result entry
  getEnteredResultEntryReportFooterRequest,
  getEnteredResultEntryReportFooterSuccess,
  getEnteredResultEntryReportFooterFail,
  //get test sensitive medicine
  getResultEntryTestSensitiveMedicineRequest,
  getResultEntryTestSensitiveMedicineSuccess,
  getResultEntryTestSensitiveMedicineFail,
  // get specific sensitive medicine
  getSpecificTestSensitiveMedicineRequest,
  getSpecificTestSensitiveMedicineSuccess,
  getSpecificTestSensitiveMedicineFail,
  // get report comment
  getResultEntryReportCommentRequest,
  getResultEntryReportCommentSuccess,
  getResultEntryReportCommentFail,
  updateTestSensitiveMedicines,
  // create result entry
  createResultEntryRequest,
  createResultEntrySuccess,
  createResultEntryFail,
  editResultEntryRequest,
  editResultEntrySuccess,
  editResultEntryFail,
  clearResultEntryData,
  updateSensitiveTypes,
  // update result entry
  updateResultEntryRequest,
  updateResultEntrySuccess,
  updateResultEntryFail,
  clearTestSensitiveMedicines,
  clearCollectedTestsData,
} = ResultEntry.actions;
export default ResultEntry.reducer;
