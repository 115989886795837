import {
  getUserActivityLogReportSupplierRequest,
  getUserActivityLogReportItemRequest,
  getUserActivityLogReportUserRequest,
  getUserActivityLogReportAllSummaryRequest,
  getUserActivityLogReportAllDetailRequest,
  getUserActivityLogReportQuickSummaryRequest,
  getUserActivityLogReportQuickDetailRequest,
//reducer get data
getUserActivityLogReportItemSuccess,
getUserActivityLogReportItemFail,
getUserActivityLogReportSupplierSuccess,
getUserActivityLogReportSupplierFail,
getUserActivityLogReportUserSuccess,
getUserActivityLogReportUserFail,
getUserActivityLogReportQuickDetailSuccess,
getUserActivityLogReportQuickDetailFail,
getUserActivityLogReportAllDetailSuccess,
getUserActivityLogReportAllDetailFail,
getUserActivityLogReportQuickSummarySuccess,
getUserActivityLogReportQuickSummaryFail,
getUserActivityLogReportAllSummarySuccess,
getUserActivityLogReportAllSummaryFail,
getUserActivityLogReportDetailSuccess,
getUserActivityLogReportDetailFail,
loadingUserActivityLogReportDetail,
getUserActivityLogReportPreviousRequest,
getUserActivityLogReportNextRequest,
getUserActivityLogReportDetailRequest,
} from "./userActivityLogReportSlice";
import { mergeMap } from "rxjs";
import  *  as API from  "./api";
import { map, filter, tap } from "rxjs/operators";
import { Observable } from "rxjs";
import { Action } from "@reduxjs/toolkit";
import { combineEpics } from "redux-observable";
import {
  alertErrorAction,
  alertSuccessAction,
  closeModal,
} from "../../../../CommonAppRedux/CommonAppSlice";
import { getNext, getPrevious } from "../../../../CommonAppRedux/api";
import {
  dispatchAction,
  stateAction,
} from "../../../../../AppUtils/Utils/globalTypes";
import messages from "../../../../../AppUtils/Utils/validationConstants";
import { ContactsOutlined } from "@mui/icons-material";
export const controller = new AbortController();
const getUserActivityLogReportSupplierEpic = (
  action$: Observable<Action>,
  _: stateAction,
  { dispatch }: dispatchAction
) =>
  action$.pipe(
    filter(getUserActivityLogReportSupplierRequest.match),
    mergeMap(async (action) => {
      try {
        const response = await API.getUserActivityLogReportSupplier();
        return { payload: response.data };
      } catch (e) {
        return { error: e };
      }
    }),
    map((action) =>
      action?.payload
        ? getUserActivityLogReportSupplierSuccess(action?.payload)
        : getUserActivityLogReportSupplierFail()
    )
  );
  //user
  const getUserActivityLogReportUserEpic = (
    action$: Observable<Action>,
    _: stateAction,
    { dispatch }: dispatchAction
  ) =>
    action$.pipe(
      filter(getUserActivityLogReportUserRequest.match),
      mergeMap(async (action) => {
        try {
          const response = await API.getUserActivityLogReportUser();
          return { payload: response.data };
        } catch (e) {
          return { error: e };
        }
      }),
      map((action) =>
        action?.payload
          ? getUserActivityLogReportUserSuccess(action?.payload)
          : getUserActivityLogReportUserFail()
      )
    );
    //item
    const getUserActivityLogReportItemEpic = (
      action$: Observable<Action>,
      _: stateAction,
      { dispatch }: dispatchAction
    ) =>
      action$.pipe(
        filter(getUserActivityLogReportItemRequest.match),
        mergeMap(async (action) => {
          try {
            const response = await API.getUserActivityLogReportItem();
            return { payload: response.data };
          } catch (e) {
            return { error: e };
          }
        }),
        map((action) =>
          action?.payload
            ? getUserActivityLogReportItemSuccess(action?.payload)
            : getUserActivityLogReportItemFail()
        )
      );

      //get all purchase summary detail
      const getUserActivityLogReportAllSummaryEpic = (
        action$: Observable<Action>,
        _: stateAction,
        { dispatch }: dispatchAction
      ) =>
        action$.pipe(
          filter(getUserActivityLogReportAllSummaryRequest.match),
          mergeMap(async (action) => {
            try {
              const response = await API.getUserActivityLogReportAllReport(action?.payload);
              if (response?.data?.count === 0) {
                dispatch(
                  alertErrorAction(`No User Activity Data Found`)
                )
              }
              return { payload: response.data };
            } catch (e) {
              return { error: e };
            }
          }),
          map((action) =>
            action?.payload
              ? getUserActivityLogReportAllSummarySuccess(action?.payload)
              : getUserActivityLogReportAllSummaryFail()
          )
        );
        //quick summary
        const getUserActivityLogReportQuickSummaryEpic = (
          action$: Observable<Action>,
          _: stateAction,
          { dispatch }: dispatchAction
        ) =>
          action$.pipe(
            filter(getUserActivityLogReportQuickSummaryRequest.match),
            mergeMap(async (action) => {
              try {
                const response = await API.getUserActivityLogReportQuickReport(action.payload);
                if (response?.data?.count === 0) {
                  dispatch(
                    alertErrorAction(`No User Activity Data Found`)
                  )
                }
                return { payload: response.data };
              } catch (e) {
                return { error: e };
              }
            }),
            map((action) =>
              action?.payload
                ? getUserActivityLogReportQuickSummarySuccess(action?.payload)
                : getUserActivityLogReportQuickSummaryFail()
            )
          );

           //get all purchase summary detail
      const getUserActivityLogReportAllDetailEpic = (
        action$: Observable<Action>,
        _: stateAction,
        { dispatch }: dispatchAction
      ) =>
        action$.pipe(
          filter(getUserActivityLogReportAllDetailRequest.match),
          mergeMap(async (action) => {
            try {
              const response = await API.getUserActivityLogReportAllReport(action?.payload);
              return { payload: response.data };
            } catch (e) {
              return { error: e };
            }
          }),
          map((action) =>
            action?.payload
              ? getUserActivityLogReportAllDetailSuccess(action?.payload)
              : getUserActivityLogReportAllDetailFail()
          )
        );
        //quick Detail
        const getUserActivityLogReportQuickDetailEpic = (
          action$: Observable<Action>,
          _: stateAction,
          { dispatch }: dispatchAction
        ) =>
        action$.pipe(
          filter(getUserActivityLogReportQuickDetailRequest.match),
          mergeMap(async (action) => {
            try {
              const response = await API.getUserActivityLogReportQuickReport(action.payload);
              return { payload: response.data };
            } catch (e) {
              return { error: e };
            }
          }),
          map((action) =>
            action?.payload
              ? getUserActivityLogReportQuickDetailSuccess(action?.payload)
              : getUserActivityLogReportQuickDetailFail()
          )
        );

        //next and previous
        //get next
const getUserActivityLogReportNext = (
  action$: Observable<Action>,
  _: stateAction,
  { dispatch }: dispatchAction
) =>
  action$.pipe(
    filter(getUserActivityLogReportNextRequest.match),
    mergeMap(async (action) => {
      dispatch(loadingUserActivityLogReportDetail());
      try {
        const response = await getNext(action.payload);
        return { payload: response.data };
      } catch (e) {
        return { error: e };
      }
    }),
    map((action) =>
      action?.payload
        ? getUserActivityLogReportDetailSuccess(action?.payload)
        : getUserActivityLogReportDetailFail()
    )
  );
//get previous
const getUserActivityLogReportPrevious = (
  action$: Observable<Action>,
  _: stateAction,
  { dispatch }: dispatchAction
) =>
  action$.pipe(
    filter(getUserActivityLogReportPreviousRequest.match),
    mergeMap(async (action) => {
      dispatch(loadingUserActivityLogReportDetail());
      try {
        const response = await getPrevious(action.payload);
        return { payload: response.data };
      } catch (e) {
        return { error: e };
      }
    }),
    map((action) =>
      action?.payload
        ? getUserActivityLogReportDetailSuccess(action?.payload)
        : getUserActivityLogReportDetailFail()
    )
  );
  //het purchase report details
  const getUserActivityLogReportEpic = (
    action$: Observable<Action>,
    _: stateAction,
    { dispatch }: dispatchAction
  ) =>
  action$.pipe(
    filter(getUserActivityLogReportDetailRequest.match),
    mergeMap(async (action) => {
      try {
        const response = await API.getUserActivityLogReportQuickReport(action.payload);
        return { payload: response.data };
      } catch (e) {
        return { error: e };
      }
    }),
    map((action) =>
      action?.payload
        ? getUserActivityLogReportDetailSuccess(action?.payload)
        : getUserActivityLogReportDetailFail()
    )
  );
export const userActivityLogReportEpics = combineEpics(
  getUserActivityLogReportSupplierEpic,
  getUserActivityLogReportItemEpic,
  getUserActivityLogReportUserEpic,
  getUserActivityLogReportAllSummaryEpic,
  getUserActivityLogReportQuickSummaryEpic,
  getUserActivityLogReportQuickDetailEpic,
  getUserActivityLogReportAllDetailEpic,
  getUserActivityLogReportPrevious,
  getUserActivityLogReportNext,
  getUserActivityLogReportEpic,
);
