import { createSlice } from "@reduxjs/toolkit";
import ArrayUtils from "../../../../AppUtils/Utils/arrayUtils";
import { sampleCollectionInitialState } from "../types";
const initialState: sampleCollectionInitialState = {
  sampleCollections: [],
  collectedCustomerTests: [],
  pendingCustomerTests: [],
  loadingSampleCollection: false,
  loadingCreateSampleCollection: false,
  loadingSampleCollectionCustomerTest: false,
  loadingSampleType: false,
  edit: false,
  sampleCollection: null,
  collectedSampleData: null,
  sampleTypes: [],
  customerTests: [],
  count: 0,
  next: "",
  saleMainId: "",
  previous: "",
};
export const SampleCollection = createSlice({
  name: "sampleCollection",
  initialState,
  reducers: {
    loadingSampleCollection: (state) => {
      state.loadingSampleCollection = true;
    },
    getSampleCollectionRequest: (state, payload) => {
      state.loadingSampleCollection = true;
    },
    getSampleCollectionSuccess: (
      state,
      { payload: { results, count, next, previous } }
    ) => {
      state.loadingSampleCollection = false;
      state.sampleCollections = results;
      state.count = count;
      state.next = next;
      state.previous = previous;
    },
    getSampleCollectionFail: (state) => {
      state.loadingSampleCollection = false;
    },
    getSampleCollectionNextRequest: (state, payload) => {
      state.loadingSampleCollection = true;
    },
    getSampleCollectionPreviousRequest: (state, payload) => {
      state.loadingSampleCollection = true;
    },
    getSampleCollectionCustomerTestRequest: (state, { payload }) => {
      state.loadingSampleCollectionCustomerTest = true;
      state.sampleCollection = payload;
    },
    // get collected tests
    getSampleCollectionCustomerTestSuccess: (
      state,
      { payload: { results } }
    ) => {
      state.loadingSampleCollectionCustomerTest = false;
      state.customerTests = results;
      state.collectedCustomerTests = ArrayUtils.filterById(
        results,
        "COLLECTED",
        "testStatus"
      );
      state.pendingCustomerTests = ArrayUtils.filterById(
        results,
        "BILLED",
        "testStatus"
      );
    },
    getSampleCollectionCustomerTestFail: (state) => {
      state.loadingSampleCollectionCustomerTest = false;
    },
    createSampleCollectionRequest: (state, payload) => {
      state.loadingCreateSampleCollection = true;
    },
    createSampleCollectionSuccess: (state) => {
      state.loadingCreateSampleCollection = false;
    },
    createSampleCollectionFail: (state) => {
      state.loadingCreateSampleCollection = false;
    },
    requestSampleType: (state) => {
      state.loadingSampleType = true;
    },
    getSampleTypeSuccess: (state, { payload: { results } }) => {
      state.sampleTypes = results;
      state.loadingSampleType = false;
    },
    getSampleTypeFail: (state) => {
      state.loadingSampleType = false;
    },
    sampleCollectionEditSuccess: (state, { payload }) => {
      state.edit = true;
      state.sampleCollection = payload;
    },
    updateSampleCollectionRequest: (state, payload) => {
      state.loadingCreateSampleCollection = true;
    },
    updateSampleCollectionSuccess: (state) => {
      state.loadingCreateSampleCollection = false;
    },
    updateSampleCollectionFail: (state) => {
      state.loadingCreateSampleCollection = false;
    },
    clearSampleCollectionData: (state) => {
      state.edit = false;
      state.sampleCollection = null;
      state.loadingSampleCollection = false;
      state.collectedCustomerTests = [];
      state.pendingCustomerTests = [];
    },
  },
});
// Action creators are generated for each case reducer function
export const {
  loadingSampleCollection,
  getSampleCollectionRequest,
  getSampleCollectionSuccess,
  getSampleCollectionFail,
  getSampleCollectionNextRequest,
  getSampleCollectionPreviousRequest,
  getSampleCollectionCustomerTestRequest,
  getSampleCollectionCustomerTestSuccess,
  getSampleCollectionCustomerTestFail,
  createSampleCollectionRequest,
  createSampleCollectionSuccess,
  createSampleCollectionFail,
  sampleCollectionEditSuccess,
  updateSampleCollectionRequest,
  updateSampleCollectionSuccess,
  updateSampleCollectionFail,
  clearSampleCollectionData,
  requestSampleType,
  getSampleTypeSuccess,
  getSampleTypeFail,
} = SampleCollection.actions;
export default SampleCollection.reducer;
