import { Observable } from "rxjs";
import { Action } from "@reduxjs/toolkit";
import {
    dispatchAction,
    stateAction,
} from "../../../../AppUtils/Utils/globalTypes";
import {
    clearNoteTemplatesData,
    createNoteTemplatesFail,
    createNoteTemplatesRequest,
    createNoteTemplatesSuccess,
    getNoteTemplatesFail,
    getNoteTemplatesRequest,
    getNoteTemplatesSuccess,
    updateNoteTemplatesFail,
    updateNoteTemplatesRequest,
    updateNoteTemplatesSuccess,
    getNextNoteTemplateRequest,
    getPreviousNoteTemplateRequest,
    editNoteTemplateRequest,
    editNoteTemplatesSuccess,
    editNoteTemplatesFail,
} from "./noteTemplateSlice";
import { filter, map } from "rxjs/operators";
import { mergeMap } from "rxjs";
import {
    createNoteTemplates,
    getEditedNoteTemplates,
    getNoteTemplates,
    updateNoteTemplates,
} from "./api";
import { combineEpics } from "redux-observable";
import {
    alertErrorAction,
    alertSuccessAction,
    closeModal,
} from "../../../CommonAppRedux/CommonAppSlice";
import messages from "../../../../AppUtils/Utils/validationConstants";
import { getNext, getPrevious } from "../../../CommonAppRedux/api";
const getNoteTemplatesEpic = (
    action$: Observable<Action>,
    _: stateAction,
    { dispatch }: dispatchAction
) =>
    action$.pipe(
        filter(getNoteTemplatesRequest.match),
        mergeMap(async (action) => {
            try {
                const response = await getNoteTemplates(action?.payload);
                return { payload: response.data };
            } catch (e) {
                return { error: e };
            }
        }),
        map((action) => {
            return action?.payload
                ? getNoteTemplatesSuccess(action?.payload)
                : getNoteTemplatesFail();
        })
    );
const createNoteTemplatesEpic = (
    action$: Observable<Action>,
    _: stateAction,
    { dispatch }: dispatchAction
) =>
    action$.pipe(
        filter(createNoteTemplatesRequest.match),
        mergeMap(async ({ payload: { values, rowsPerPage, page } }) => {
            try {
                const body = JSON.stringify(values);
                const response = await createNoteTemplates(body);
                dispatch(getNoteTemplatesRequest({ rowsPerPage, page }));
                dispatch(alertSuccessAction(messages.createMessage));
                dispatch(closeModal());
                dispatch(clearNoteTemplatesData());
                return { payload: { response } };
            } catch (e) {
                dispatch(alertErrorAction(messages.createFailMessage));
                return { error: e };
            }
        }),
        map((action) => {
            return action?.payload
                ? createNoteTemplatesSuccess()
                : createNoteTemplatesFail();
        })
    );
const updateNoteTemplatesEpic = (
    action$: Observable<Action>,
    _: stateAction,
    { dispatch }: dispatchAction
) =>
    action$.pipe(
        filter(updateNoteTemplatesRequest.match),
        mergeMap(
            async ({ payload: { values, id, rowsPerPage, page, search } }) => {
                try {
                    const body = JSON.stringify(values);
                    const response = await updateNoteTemplates(body, id);
                    if (response) {
                        dispatch(alertSuccessAction(messages?.updateMessage));
                        dispatch(closeModal());
                        dispatch(
                            getNoteTemplatesRequest({
                                rowsPerPage,
                                page,
                                search,
                            })
                        );
                        dispatch(clearNoteTemplatesData());
                    }
                    return { payload: { response, rowsPerPage } };
                } catch (e) {
                    dispatch(alertErrorAction(messages.updateFailMessage));
                    return { error: e };
                }
            }
        ),
        map((action) => {
            return action?.payload
                ? updateNoteTemplatesSuccess()
                : updateNoteTemplatesFail();
        })
    );

const getNextNoteTemplateEpic = (
    action$: Observable<Action>,
    _: stateAction,
    { dispatch }: dispatchAction
) =>
    action$.pipe(
        filter(getNextNoteTemplateRequest.match),
        mergeMap(async (action) => {
            try {
                const response = await getNext(action?.payload);
                return {payload: response.data}
            } catch (e) {
                return {error: e}
            }
        }),
        map((action)=>{
            return action?.payload ? getNoteTemplatesSuccess(action?.payload) : getNoteTemplatesFail()
        })
    );
const getPreviousNoteTemplateEpic = (
    action$: Observable<Action>,
    _: stateAction,
    { dispatch }: dispatchAction
) =>
    action$.pipe(
        filter(getPreviousNoteTemplateRequest.match),
        mergeMap(async (action) => {
            try {
                const response = await getPrevious(action?.payload);
                return {payload: response.data}
            } catch (e) {
                return {error: e}
            }
        }),
        map((action)=>{
            return action?.payload ? getNoteTemplatesSuccess(action?.payload) : getNoteTemplatesFail()
        })
    );

    const getEditedNoteTemplateEpic = (action$: Observable<Action>, _:stateAction, {dispatch}: dispatchAction) =>
    action$.pipe(
        filter(editNoteTemplateRequest.match),
        mergeMap(async (action)=>{
            try{
                const response = await getEditedNoteTemplates(action?.payload)
                return {payload: response.data}
            }catch(e){return {error: e}}
        }),
        map((action)=>{
            return action?.payload ? editNoteTemplatesSuccess(action?.payload) : editNoteTemplatesFail()
        })
    )
export const noteTemplatesEpic = combineEpics(
    getNoteTemplatesEpic,
    createNoteTemplatesEpic,
    getEditedNoteTemplateEpic,
    updateNoteTemplatesEpic,
    getNextNoteTemplateEpic,
    getPreviousNoteTemplateEpic,
);
