import LibraryBooksIcon from "@mui/icons-material/LibraryBooks";
import userRole from "../../../../Assets/CoreSetup/userRole.webp";

//sidebar images
import otherSetupIcon from "../../../../Assets/icons/LabIcons/others_setup.svg";
import reportSetupIcon from "../../../../Assets/icons/LabIcons/reports_setup.svg";
import testSetupIcon from "../../../../Assets/icons/LabIcons/tests_setup1.svg";
import testSetupIcon2 from "../../../../Assets/icons/LabIcons/tests_setup2.svg";

//mid icons
import sampleCollectionMidIcon from "../../../../Assets/icons/LabMidIcons/sample_collection.svg";
import resultEntryMidIcon from "../../../../Assets/icons/LabMidIcons/entry.svg";
import verificationMidIcon from "../../../../Assets/icons/LabMidIcons/verification.svg";
import testMidIcon from "../../../../Assets/icons/LabMidIcons/test.svg";
import testMethodsMidIcon from "../../../../Assets/icons/LabMidIcons/test_methods.svg";
import testFindingMidIcon from "../../../../Assets/icons/LabMidIcons/test_finding.svg";
import testUnitMidIcon from "../../../../Assets/icons/LabMidIcons/test_units.svg";
import parameterRangeMidIcon from "../../../../Assets/icons/LabMidIcons/parameter_range.svg";
import testFooterNoteMidIcon from "../../../../Assets/icons/LabMidIcons/test_footer_note.svg";
import testCategoryMidIcon from "../../../../Assets/icons/LabMidIcons/test_category.svg";
import departmentMidIcon from "../../../../Assets/icons/LabMidIcons/department.svg";
import reportCommentMidIcon from "../../../../Assets/icons/LabMidIcons/report_comment.svg";
import sensitiveMedMidIcon from "../../../../Assets/icons/LabMidIcons/sensitive_medicine.svg";
import testSensitiveMedMidIcon from "../../../../Assets/icons/LabMidIcons/test_sensitive_medicine.svg";
import reportFooterMidIcon from "../../../../Assets/icons/LabMidIcons/report_footer.svg";
import sampleTypeMidIcon from "../../../../Assets/icons/LabMidIcons/sample_types.svg";
import reagentMidIcon from "../../../../Assets/icons/LabMidIcons/reagent.svg";
import packageTest from "../../../../Assets/icons/LabMidIcons/package_test.svg";


import {  
  departmentPermission,
  footerNotePermission,
  packageTestPermission,
  reagentPermission,
  referenceRangePermission,
  reportCommentPermission,
  reportFooterPermission,
  resultEntryPermission,
  sampleCollectionPermission,
  sampleTypePermission,
  sensitiveMedPermission,
  testCategoryPermission,
  testFindingPermission,
  testMethodPermission,
  testPermission,
  testSensitiveMedPermission,
  testUnitPermission,
  verificationAndPrintPermission,
} from "./LabPermissionConstants";
export const labSidebarData = [
  {
    name: "Lab Management",
    Icon: LibraryBooksIcon,
    items: [
      {
        name: "Sample Collection",
        image: sampleCollectionMidIcon,
        link: "/lab/sample-collection",
        permission: sampleCollectionPermission,
      },
      {
        name: "Result Entry",
        link: "/lab/result-entry",
        image: resultEntryMidIcon,
        permission: resultEntryPermission,
      },
      {
        name: "Verification And Print",
        link: "/lab/lab-verification",
        image: verificationMidIcon,
        permission: verificationAndPrintPermission,
      },
    ],
  },

  {
    name: "Test Setup",
    customIcon: testSetupIcon2,
    items: [
      {
        name: "Test",
        link: "/lab/test",
        image: testMidIcon,
        permission: testPermission,
      },
      {
        name: "Test Method",
        link: "/lab/test-method",
        image: testMethodsMidIcon,
        permission: testMethodPermission,
      },
      {
        name: "Package Test",
        link: "/lab/package-test",
        image: packageTest,
        permission: packageTestPermission,
      },
      {
        name: "Test Finding",
        link: "/lab/test-finding",
        image: testFindingMidIcon,
        permission: testFindingPermission,
      },
      {
        name: "Test Unit",
        link: "/lab/unit",
        image: testUnitMidIcon,
        permission: testUnitPermission,
      },
      {
        name: "Parameter Range",
        link: "/lab/reference-range",
        image: parameterRangeMidIcon,
        permission: referenceRangePermission,
      },
      {
        name: "Test Footer Note",
        link: "/lab/footer-note",
        image: testFooterNoteMidIcon,
        permission: footerNotePermission,
      },
      {
        name: "Test Category",
        link: "/lab/test-category",
        image: testCategoryMidIcon,
        permission: testCategoryPermission,
      },
    ],
  },
  {
    name: "Other Setup",
    customIcon: otherSetupIcon,
    items: [
      {
        name: "Department",
        link: "/lab/department",
        image: departmentMidIcon,
        permission: departmentPermission,
      },
    ],
  },
  {
    name: "Report Setup",
    customIcon: reportSetupIcon,
    items: [
      {
        name: "Report Comment",
        link: "/lab/report-comment",
        image: reportCommentMidIcon,
        permission: reportCommentPermission,
      },
      {
        name: "Sensitive Medicine",
        link: "/lab/sensitive-medicine",
        image: sensitiveMedMidIcon,
        permission: sensitiveMedPermission,
      },
      {
        name: "Test Sensitive Medicine",
        link: "/lab/test-sensitive-medicine",
        image: testSensitiveMedMidIcon,
        permission: testSensitiveMedPermission,
      },
      {
        name: "Report Footer",
        link: "/lab/report-footer",
        image: reportFooterMidIcon,
        permission: reportFooterPermission,
      },
    ],
  },

  {
    name: "Lab Admin Setup",
    customIcon: testSetupIcon,
    items: [
      {
        name: "Sample Type",
        link: "/lab/sample-type",
        image: sampleTypeMidIcon,
        permission: sampleTypePermission,
      },
      {
        name: "Reagent",
        link: "/lab/reagent",
        image: reagentMidIcon,
        permission: reagentPermission,
      },
      // {
      //   name: "Doctor",
      //   link: "/doctor",
      //   image: userRole,
      //   permission: [
      //     "view_doctor",
      //     "add_doctor",
      //     "edit_doctor",
      //     "delete_doctor",
      //   ],
      // },
      // {
      //   name: "Referral",
      //   link: "/referral",
      //   image: userRole,
      //   permission: [
      //     "view_referral",
      //     "add_referral",
      //     "edit_referral",
      //     "delete_referral",
      //   ],
      // },
    ],
  },
];
