import { createSlice } from "@reduxjs/toolkit";

const initialState: any = {
  customers: [],
  loadingCustomers: false,
  showSelectedPatientInAppointment: false,
  selectedPatient: null,
  loadingCustomerById:false,
  customer:null,
};

export const PatientList = createSlice({
  name: "Patient List",
  initialState,
  reducers: {
    getPatientListRequest: (state, payload) => {
      state.loadingCustomers = true;
    },
    getPatientListSuccess: (state, { payload: { results } }) => {
      state.loadingCustomers = false;
      state.customers = results;
    },
    getPatientListFail: (state) => {
      state.loadingCustomers = false;
    },
    getFilteredPatientRequest: (state, payload) => {
      state.loadingCustomers = true;
    },
    getFilteredPatientSuccess: (state, { payload: { results } }) => {
      state.loadingCustomers = false;
      state.customers = results;
    },
    getFilteredPatientFail: (state) => {
      state.loadingCustomers = false;
    },
    setShowSelectedPatientInAppointment: (state, { payload }) => {
      state.showSelectedPatientInAppointment = payload;
    },
    setSelectedPatient: (state, { payload }) => {
      state.selectedPatient = payload;
    },

    //
    getPatientListByIdRequest: (state, payload) => {
      state.loadingCustomerById = true;
    },
    getPatientListByIdSuccess: (state, {payload}) => {
      state.loadingCustomerById = false;
      state.customer = payload;
    },
    getPatientListByIdFail: (state) => {
      state.loadingCustomerById = false;
    },
  },
});

// Action creators are generated for each case reducer function
export const {
  getPatientListRequest,
  getPatientListSuccess,
  getPatientListFail,
  getFilteredPatientRequest,
  getFilteredPatientSuccess,
  getFilteredPatientFail,
  setShowSelectedPatientInAppointment,
  setSelectedPatient,
  getPatientListByIdRequest,
  getPatientListByIdSuccess,
  getPatientListByIdFail
} = PatientList.actions;

export default PatientList.reducer;
