import { createAction, createSlice } from "@reduxjs/toolkit";

const initialState: any = {
  jaatiTypes: [],
  edit: false,
  jaatiType: null,
  loadingJaatiType: false,
  isDefaultJaati: [],
  loadingMainJaatiType: false,
  loadingCreateJaatiType: false,
  count: 0,
  next: "",
  previous: "",
};

export const updateJaatiTypeRequest = createAction(
  "updateJaatiTypeRequest",
  function prepare({ values, id, rowsPerPage, page }) {
    return {
      payload: {
        values,
        id,
        rowsPerPage,
        page,
      },
    };
  }
);

export const getJaatiTypeRequest = createAction(
  "getJaatiTypeRequest",
  function prepare({ rowsPerPage, page, filterData }) {
    return {
      payload: {
        rowsPerPage,
        page,
        filterData,
      },
    };
  }
);
// get next request
export const getJaatiTypeNextRequest = createAction(
  "getJaatiTypeNextRequest",
  function prepare(next) {
    return {
      payload: next,
    };
  }
);
//get previous request
export const getJaatiTypePreviousRequest = createAction(
  "getJaatiTypePreviousRequest",
  function prepare(previous) {
    return {
      payload: previous,
    };
  }
);
export const jaatiType = createSlice({
  name: "jaatiTypeReducer",
  initialState,
  reducers: {
    loadingJaatiType: (state) => {
      state.loadingJaatiType = true;
    },
    getJaatiTypeSuccess: (
      state,
      { payload: { results, count, next, previous } }
    ) => {
      state.loadingJaatiType = false;
      state.jaatiTypes = results;
      state.count = count;
      state.next = next;
      state.previous = previous;
    },
    getJaatiTypeFail: (state) => {
      state.loadingJaatiType = false;
    },

    createJaatiTypeRequest: (state, payload) => {
      state.loadingCreateJaatiType = true;
      state.closeModal = true;
    },
    createJaatiTypeSuccess: (state) => {
      state.loadingCreateJaatiType = false;
    },
    createJaatiTypeFail: (state) => {
      state.loadingCreateJaatiType = false;
    },
    getMainJaatiTypeRequest: (state) => {
      state.loadingMainJaatiType = true;
    },
    getMainJaatiTypeSuccess: (state, { payload: { results } }) => {
      state.loadingMainJaatiType = false;
      state.isDefaultJaati = results;
    },
    getMainJaatiTypeFail: (state) => {
      state.loadingMainJaatiType = false;
    },
    getJaatiTypeByIdRequest: (state, payload) => {
      state.edit = true;
    },
    jaatiTypeEditSuccess: (state, { payload }) => {
      state.jaatiType = payload;
    },
    jaatiTypeEditFail: (state) => {
      state.edit = false;
    },
    updateJaatiTypeSuccess: (state) => {
      state.loadingCreateJaatiType = false;
    },
    updateJaatiTypeFail: (state) => {
      state.loadingCreateJaatiType = false;
    },

    clearJaatiTypeData: (state) => {
      state.edit = false;
      state.jaatiType = null;
    },
  },
});
// Action creators are generated for each case reducer function
export const {
  loadingJaatiType,
  getJaatiTypeSuccess,
  getJaatiTypeFail,
  createJaatiTypeRequest,
  createJaatiTypeSuccess,
  createJaatiTypeFail,
  jaatiTypeEditSuccess,
  updateJaatiTypeSuccess,
  updateJaatiTypeFail,
  clearJaatiTypeData,
  getJaatiTypeByIdRequest,
  jaatiTypeEditFail,
  getMainJaatiTypeRequest,
  getMainJaatiTypeSuccess,
  getMainJaatiTypeFail,
} = jaatiType.actions;
export default jaatiType.reducer;
