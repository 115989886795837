import { Grid, IconButton, Typography, Stack } from "@mui/material";
import LocationOnIcon from "@mui/icons-material/LocationOn";
import LocalPhoneIcon from "@mui/icons-material/LocalPhone";
import EmailIcon from "@mui/icons-material/Email";
import nepalHearingLogo from "../../../../src/Assets/nhclogo.png";
import Logo from "../../../../src/Assets/nhscclogo.png";

const AuthRightSide = () => {
  const linkContentList = [
    {
      icon: <EmailIcon className="organization_link_icon" />,
      text: "info@merakitechs.com",
      type: "email",
    },
    {
      icon: <LocalPhoneIcon className="organization_link_icon" />,
      text: "+977-9868713167",
      type: "phone",
    },
    {
      icon: <LocationOnIcon className="organization_link_icon" />,
      text: "Kathmandu, Nepal",
      type: "address",
    },
  ];
  return (
    <>
      {/* Login page right side */}
      <Grid item xs={6} sm={6} md={6} className="organization-logo-container">
        <Grid
          container
          direction="column"
          spacing={2}
          justifyContent="center"
          alignItems="center"
          className="organization-logo-wrapper"
        >
          <Grid item className="organization-logo-item">
            <img
              src={nepalHearingLogo}
              alt="organization_logo"
              className="organization-logo"
            />
          </Grid>
          {/* <Grid item className="organization-logo-item">
            <img
              src={Logo}
              alt="organization_logo"
              className="organization-logo"
            />
          </Grid> */}
          <Grid item className="organization_content">
            <Typography variant="h3" className="organization_title">
              Meraki - NHSCC
            </Typography>
            <Typography variant="body1" className="organization_description">
              Healthcare - Management Information System
            </Typography>
          </Grid>

          <Grid item className="organization_link_Content">
            {linkContentList?.map((link, index) => (
              <Stack
                direction="row"
                alignItems="center"
                component={"a"}
                href={
                  link.type === "email"
                    ? `mailto:${link.text}`
                    : link.type === "phone"
                    ? `tel:${link.text}`
                    : ""
                }
                className="organization_link_item"
                sx={{ textDecoration: "none" }}
                key={index}
              >
                <IconButton>{link.icon}</IconButton>
                <Typography variant="body1" className="organization_link_text">
                  {link.text}
                </Typography>
              </Stack>
            ))}
          </Grid>
        </Grid>
      </Grid>
    </>
  );
};

export default AuthRightSide;
