import { axiosInstance } from "../../../../AppUtils/Utils/axios";
import { filterApiData } from "../../../../AppUtils/Utils/appFunctions";
import {
  getApiProps,
  searchProps,
} from "../../../../AppUtils/Utils/globalTypes";

const referralModURL = '/api/v1/core-app/referrer';

export const getReferral = (data: getApiProps) => {
  const { rowsPerPage, page } = data;
  return axiosInstance.get(
    `${referralModURL}?offset=${
      rowsPerPage * page
    }&limit=${rowsPerPage}${
      data?.filterData ? filterApiData(data?.filterData) : ""
    }`
  );
};

//get referral by id
export const getReferralById = (id: number) => {
  return axiosInstance.get(`${referralModURL}/${id}`);
};

export const searchReferral = ({ rowsPerPage, search }: searchProps) => {
  return `${referralModURL}?offset=0&limit=${rowsPerPage}&search=${search}`;
};
export const createReferral = (body: string | FormData) =>
  axiosInstance.post(`${referralModURL}`, body);

//update Referral

export const updateReferral = (body: string | FormData, id: number) =>
  axiosInstance.patch(`${referralModURL}/${id}`, body);
export const getAllReferralForDefault = () =>
  axiosInstance.get<any>(`${referralModURL}`);
