import { createSlice, createAction } from "@reduxjs/toolkit";
const initialState: any = {
  serviceOrders: [],
  serviceOrderDetails: [],
  loadingServiceOrder: false,
  loadingServiceOrderDepartment: false,
  loadingServiceOrderCategory: false,
  loadingServiceOrderCustomerType: false,
  loadingCreateServiceOrder: false,
  edit: false,
  serviceOrderDetailEdit: false,
  serviceOrder: null,
  serviceOrderDetail: null,
  customerTypes: [],
  departments: [],
  serviceOrderCategories: [],
  count: 0,
  previous: "",
  next: "",
  loadingServiceOrderDetailsAdded:false,
  // loadingServiceOrderCustomer:false,
  // loadingHospitalReferralCustomer:false,
  // serviceOrderCustomers:[],
  loadingServiceOrderItem:false,
  loadingHospitalReferralItem:false,
  serviceOrderItems:[],

};

export const ServiceOrder = createSlice({
  name: "ServiceOrder Reducer",
  initialState,
  reducers: {
    loadingServiceOrder: (state) => {
      state.loadingServiceOrder = true;
    },
    getServiceOrderRequest: (state, payload) => {
      state.loadingServiceOrder = true;
    },
    getServiceOrderSuccess: (
      state,
      { payload: { results, count, next, previous } }
    ) => {
      state.loadingServiceOrder = false;
      state.serviceOrders = results;
      state.count = count;
      state.next = next;
      state.previous = previous;
    },
    getServiceOrderFail: (state) => {
      state.loadingServiceOrder = false;
    },
    getServiceOrderNextRequest: (state, payload) => {
      state.loadingServiceOrder = true;
    },
    getServiceOrderPreviousRequest: (state, payload) => {
      state.loadingServiceOrder = true;
    },
    getServiceOrderCustomerTypeRequest: (state, payload) => {
      state.loadingServiceOrderCustomerType = true;
    },
    getServiceOrderCustomerTypeSuccess: (state, { payload: { results } }) => {
      state.loadingServiceOrderCustomerType = false;
      state.customerTypes = results;
    },
    getServiceOrderCustomerTypeFail: (state) => {
      state.loadingServiceOrderCustomerType = false;
    },
    getServiceOrderDepartmentRequest: (state, payload) => {
      state.loadingServiceOrderDepartment = true;
    },
    getServiceOrderDepartmentSuccess: (state, { payload: { results } }) => {
      state.loadingServiceOrderDepartment = false;
      state.departments = results;
    },
    getServiceOrderDepartmentFail: (state) => {
      state.loadingServiceOrderDepartment = false;
    },
    getServiceOrderCategoryRequest: (state, payload) => {
      state.loadingServiceOrderCategory = true;
    },
    getServiceOrderCategorySuccess: (state, { payload: { results } }) => {
      state.loadingServiceOrderCategory = false;
      state.serviceOrderCategories = results;
    },
    getServiceOrderCategoryFail: (state) => {
      state.loadingServiceOrderCategory = false;
    },
    createServiceOrderRequest: (state, payload) => {
      state.loadingCreateServiceOrder = true;
      state.closeModal = true;
    },
    createServiceOrderSuccess: (state) => {
      state.loadingCreateServiceOrder = false;
    },
    createServiceOrderFail: (state) => {
      state.loadingCreateServiceOrder = false;
    },
    getServiceOrderByIdRequest: (state, payload) => {
      state.edit = true;
    },
    serviceOrderEditSuccess: (state, { payload }) => {
      // const editableRow = state.serviceOrders?.find(
      //   (serviceOrder: any) => serviceOrder?.id === payload?.id
      // );
      state.edit = true;
      state.serviceOrder = payload;
      state.serviceOrderDetails = payload?.serviceOrderDetails;
    },
    serviceOrderEditFail: (state) => {
      state.edit = false;
      state.serviceOrder = null;
      state.serviceOrderDetails = null;
    },
    serviceOrderDetailEditSuccess: (state, { payload }) => {
      const updatedServiceOrderDetail = state.serviceOrderDetails.find(
        (serviceOrder: any) => serviceOrder?.id === payload?.id
      );
      const updatedServiceOrderDetails = state.serviceOrderDetails.filter(
        (serviceOrder: any) => serviceOrder.id !== payload.id
      );
      state.serviceOrderDetail = updatedServiceOrderDetail;
      state.serviceOrderDetails = updatedServiceOrderDetails;
      state.serviceOrderDetailEdit = true;
      // state.loadingServiceOrder = false;
    },
    serviceOrderDetailUpdateSuccess: (state, { payload }) => {
      state.serviceOrderDetails = [...state.serviceOrderDetails, payload];
      state.serviceOrderDetailEdit = false;
      state.edit = true;
      state.loadingServiceOrderDetailsAdded = false;
    },
    serviceOrderDetailAddedSuccess: (state, { payload }) => {
      state.serviceOrderDetails = [...state.serviceOrderDetails, payload];
      state.serviceOrderDetailEdit = false;
      state.loadingServiceOrderDetailsAdded = false;
    },
    serviceOrderDetailDeleteSuccess: (state, { payload }) => {
      const updatedServiceOrderDetails = state.serviceOrderDetails.filter(
        (serviceOrder: any) => serviceOrder.unique_id !== payload.unique_id
      );
      state.serviceOrderDetails = updatedServiceOrderDetails;
      state.serviceOrderDetailEdit = false;
      state.loadingServiceOrderDetailsAdded = false;
    },
    updateServiceOrderRequest: (state, payload) => {
      state.loadingCreateServiceOrder = true;
    },
    updateServiceOrderSuccess: (state) => {
      state.loadingCreateServiceOrder = false;
    },
    updateServiceOrderFail: (state) => {
      state.loadingCreateServiceOrder = false;
    },
    clearServiceOrderData: (state) => {
      state.edit = false;
      state.serviceOrder = null;
      state.serviceOrderDetail = null;
      state.serviceOrderDetails = [];
      state.loadingServiceOrder = false;
      state.serviceOrderDetailEdit = false;
    },
    ///
        //customer request
        // getServiceOrderCustomerSuccess: (state, { payload: { results } }) => {
        //   state.loadingServiceOrderCustomer = false;
        //   state.hospitalReferralCustomers = results;
        // },
        // getServiceOrderCustomerFail: (state) => {
        //   state.loadingServiceOrderCustomer = false;
        // },
        // loadingServiceOrderCustomerRequest: (state) => {
        //   state.loadingServiceOrderCustomer = true;
        // },
        // getServiceOrderCustomerRequest: (state, payload) => {
        //   state.loadingHospitalReferralCustomer = true;
        // },
        //
        getServiceOrderItemSuccess: (state, { payload: { results } }) => {
          state.loadingServiceOrderItem = false;
          state.serviceOrderItems = results;
        },
        getServiceOrderItemFail: (state) => {
          state.loadingServiceOrderItem = false;
        },
        loadingServiceOrderItemRequest: (state) => {
          state.loadingServiceOrderItem = true;
        },
        getServiceOrderItemRequest: (state, payload) => {
          state.loadingHospitalReferralItem = true;
        },
  },
});
// Action creators are generated for each case reducer function
export const {
  loadingServiceOrder,
  getServiceOrderRequest,
  getServiceOrderSuccess,
  getServiceOrderFail,
  getServiceOrderNextRequest,
  getServiceOrderPreviousRequest,
  getServiceOrderCustomerTypeRequest,
  getServiceOrderCustomerTypeSuccess,
  getServiceOrderCustomerTypeFail,
  getServiceOrderDepartmentRequest,
  getServiceOrderDepartmentSuccess,
  getServiceOrderDepartmentFail,
  getServiceOrderCategoryRequest,
  getServiceOrderCategorySuccess,
  getServiceOrderCategoryFail,
  createServiceOrderRequest,
  createServiceOrderSuccess,
  createServiceOrderFail,
  serviceOrderEditSuccess,
  serviceOrderEditFail,
  serviceOrderDetailEditSuccess,
  serviceOrderDetailUpdateSuccess,
  serviceOrderDetailAddedSuccess,
  serviceOrderDetailDeleteSuccess,
  updateServiceOrderRequest,
  updateServiceOrderSuccess,
  updateServiceOrderFail,
  clearServiceOrderData,
  getServiceOrderByIdRequest,
  // getServiceOrderCustomerSuccess,
  // getServiceOrderCustomerFail,
  // loadingServiceOrderCustomerRequest,
  // getServiceOrderCustomerRequest,
  //
  getServiceOrderItemSuccess,
  getServiceOrderItemFail,
  loadingServiceOrderItemRequest,
  getServiceOrderItemRequest,


} = ServiceOrder.actions;
export default ServiceOrder.reducer;
