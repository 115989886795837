import { axiosInstance } from "../../../../AppUtils/Utils/axios";
import { filterApiData } from "../../../../AppUtils/Utils/appFunctions";
import {
  getApiProps,
  searchProps,
} from "../../../../AppUtils/Utils/globalTypes";

export const testFindingModURL = "/api/v1/lab-app/test-finding";

export const getTestFinding = (data: getApiProps) => {
  const { rowsPerPage, page } = data;
  return axiosInstance.get(
    `${testFindingModURL}?offset=${rowsPerPage * page}&limit=${rowsPerPage}${
      data?.filterData ? filterApiData(data?.filterData) : ""
    }`
  );
};
export const searchTestFinding = ({ rowsPerPage, search }: searchProps) => {
  return `${testFindingModURL}?offset=0&limit=${rowsPerPage}&search=${search}`;
};
export const createTestFinding = (body: string | FormData) =>
  axiosInstance.post(`${testFindingModURL}`, body);
//update TestFinding
export const updateTestFinding = (body: string | FormData, id: number) =>
  axiosInstance.patch(`${testFindingModURL}/${id}`, body);

export const getAllTestFindingForDefault = () =>
  axiosInstance.get<any>(`${testFindingModURL}`);

export const getAllTest = (rowsPerPage: number) =>
  axiosInstance.get(`${testFindingModURL}/test?offset=0&limit=${rowsPerPage}`);

export const getTestFindingReferenceRange = (rowsPerPage: number,id:number) =>
  axiosInstance.get(
    `${testFindingModURL}/reference-range?test=${id}&offset=0&limit=${rowsPerPage}`
  );
  export const getTestFindingById = (id: number) => {
    return axiosInstance.get(`${testFindingModURL}/${id}`);
  };