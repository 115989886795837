import {
  getPatientListReportUserRequest,
  getPatientListReportAllSummaryRequest,
  getPatientListReportAllDetailRequest,
  getPatientListReportQuickSummaryRequest,
  getPatientListReportQuickDetailRequest,
  //reducer get data
  getPatientListReportUserSuccess,
  getPatientListReportUserFail,
  getPatientListReportQuickDetailSuccess,
  getPatientListReportQuickDetailFail,
  getPatientListReportAllDetailSuccess,
  getPatientListReportAllDetailFail,
  getPatientListReportQuickSummarySuccess,
  getPatientListReportQuickSummaryFail,
  getPatientListReportAllSummarySuccess,
  getPatientListReportAllSummaryFail,
  getPatientListReportDetailSuccess,
  getPatientListReportDetailFail,
  loadingPatientListReportDetail,
  getPatientListReportPreviousRequest,
  getPatientListReportNextRequest,
  getPatientListReportDetailRequest,
} from "./patientListReportSlice";
import { mergeMap } from "rxjs";
import *  as API from "./api";
import { map, filter, tap } from "rxjs/operators";
import { Observable } from "rxjs";
import { Action } from "@reduxjs/toolkit";
import { combineEpics } from "redux-observable";
import {
  alertErrorAction,
  alertSuccessAction,
  closeModal,
} from "../../../../CommonAppRedux/CommonAppSlice";
import { getNext, getPrevious } from "../../../../CommonAppRedux/api";
import {
  dispatchAction,
  stateAction,
} from "../../../../../AppUtils/Utils/globalTypes";

export const controller = new AbortController();

//user
const getPatientListReportUserEpic = (
  action$: Observable<Action>,
  _: stateAction,
  { dispatch }: dispatchAction
) =>
  action$.pipe(
    filter(getPatientListReportUserRequest.match),
    mergeMap(async (action) => {
      try {
        const response = await API.getPatientListReportUser();
        return { payload: response.data };
      } catch (e) {
        return { error: e };
      }
    }),
    map((action) =>
      action?.payload
        ? getPatientListReportUserSuccess(action?.payload)
        : getPatientListReportUserFail()
    )
  );
//item

//get all purchase summary detail
const getPatientListReportAllSummaryEpic = (
  action$: Observable<Action>,
  _: stateAction,
  { dispatch }: dispatchAction
) =>
  action$.pipe(
    filter(getPatientListReportAllSummaryRequest.match),
    mergeMap(async (action) => {
      try {
        const response = await API.getPatientListReportAllReport(action?.payload);
        if (response?.data?.count === 0) {
          dispatch(
            alertErrorAction(`No Materialized Data Found`)
          )
        }
        return { payload: response.data };
      } catch (e) {
        return { error: e };
      }
    }),
    map((action) =>
      action?.payload
        ? getPatientListReportAllSummarySuccess(action?.payload)
        : getPatientListReportAllSummaryFail()
    )
  );
//quick summary
const getPatientListReportQuickSummaryEpic = (
  action$: Observable<Action>,
  _: stateAction,
  { dispatch }: dispatchAction
) =>
  action$.pipe(
    filter(getPatientListReportQuickSummaryRequest.match),
    mergeMap(async (action) => {
      try {
        const response = await API.getPatientListReportQuickReport(action.payload);
        if (response?.data?.count === 0) {
          dispatch(
            alertErrorAction(`No Materialized Data Found`)
          )
        }
        return { payload: response.data };
      } catch (e) {
        return { error: e };
      }
    }),
    map((action) =>
      action?.payload
        ? getPatientListReportQuickSummarySuccess(action?.payload)
        : getPatientListReportQuickSummaryFail()
    )
  );

//get all purchase summary detail
const getPatientListReportAllDetailEpic = (
  action$: Observable<Action>,
  _: stateAction,
  { dispatch }: dispatchAction
) =>
  action$.pipe(
    filter(getPatientListReportAllDetailRequest.match),
    mergeMap(async (action) => {
      try {
        const response = await API.getPatientListReportAllReport(action?.payload);
        return { payload: response.data };
      } catch (e) {
        return { error: e };
      }
    }),
    map((action) =>
      action?.payload
        ? getPatientListReportAllDetailSuccess(action?.payload)
        : getPatientListReportAllDetailFail()
    )
  );
//quick Detail
const getPatientListReportQuickDetailEpic = (
  action$: Observable<Action>,
  _: stateAction,
  { dispatch }: dispatchAction
) =>
  action$.pipe(
    filter(getPatientListReportQuickDetailRequest.match),
    mergeMap(async (action) => {
      try {
        const response = await API.getPatientListReportQuickReport(action.payload);
        return { payload: response.data };
      } catch (e) {
        return { error: e };
      }
    }),
    map((action) =>
      action?.payload
        ? getPatientListReportQuickDetailSuccess(action?.payload)
        : getPatientListReportQuickDetailFail()
    )
  );

//next and previous
//get next
const getPatientListReportNext = (
  action$: Observable<Action>,
  _: stateAction,
  { dispatch }: dispatchAction
) =>
  action$.pipe(
    filter(getPatientListReportNextRequest.match),
    mergeMap(async (action) => {
      dispatch(loadingPatientListReportDetail());
      try {
        const response = await getNext(action.payload);
        return { payload: response.data };
      } catch (e) {
        return { error: e };
      }
    }),
    map((action) =>
      action?.payload
        ? getPatientListReportDetailSuccess(action?.payload)
        : getPatientListReportDetailFail()
    )
  );
//get previous
const getPatientListReportPrevious = (
  action$: Observable<Action>,
  _: stateAction,
  { dispatch }: dispatchAction
) =>
  action$.pipe(
    filter(getPatientListReportPreviousRequest.match),
    mergeMap(async (action) => {
      dispatch(loadingPatientListReportDetail());
      try {
        const response = await getPrevious(action.payload);
        return { payload: response.data };
      } catch (e) {
        return { error: e };
      }
    }),
    map((action) =>
      action?.payload
        ? getPatientListReportDetailSuccess(action?.payload)
        : getPatientListReportDetailFail()
    )
  );
//het purchase report details
const getPatientListReportEpic = (
  action$: Observable<Action>,
  _: stateAction,
  { dispatch }: dispatchAction
) =>
  action$.pipe(
    filter(getPatientListReportDetailRequest.match),
    mergeMap(async (action) => {
      try {
        const response = await API.getPatientListReportQuickReport(action.payload);

        return { payload: response.data };
      } catch (e) {
        return { error: e };
      }
    }),
    map((action) =>
      action?.payload
        ? getPatientListReportDetailSuccess(action?.payload)
        : getPatientListReportDetailFail()
    )
  );
export const PatientListReportEpics = combineEpics(
  getPatientListReportUserEpic,
  getPatientListReportAllSummaryEpic,
  getPatientListReportQuickSummaryEpic,
  getPatientListReportQuickDetailEpic,
  getPatientListReportAllDetailEpic,
  getPatientListReportPrevious,
  getPatientListReportNext,
  getPatientListReportEpic,
);
