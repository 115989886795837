import { createSlice } from "@reduxjs/toolkit";
import { saleDetails, saleInitialState } from "../types";

const initialState: saleInitialState = {
  loadingSale: false,
  loadingCreateSale: false,
  loadingSaleItem: false,
  loadingSearchData: false,
  loadingSaleDiscountScheme: false,
  loadingSaleCustomer: false,
  loadingSalePaymentMode: false,
  count: 0,
  previous: "",
  next: "",
  sales: [],
  createdSalesId: "",
  salePrintCount: [],
  saleDetails: [],
  saleDiscountSchemes: [],
  saleRowData: null,
  saleCustomers: [],
  saleItems: [],
  saleOrganizationRules: null,
  //payment details sale
  salePaymentModes: [],
  //view details sale
  viewSaleDetails: [],
  viewSalePrintDetailsData: [],
  loadingViewDetails: false,
  saleId: "",
  saleDetailCount: 0,
  saleDetailNext: "",
  saleDetailPrevious: "",
  loadingSaleDetails: false,
  loadingPrintSale: false,
  // customer details
  loadingSaleCustomerDetails: false,
  loadingDiscountScheme: false,
  loadingSalePackingTypeDetail: false,
  salePackingTypeDetails: [],
  //additional charge type
  saleAdditionalCharges: [],
  loadingSaleAdditionalCharge: false,
  // sale price
  loadingSalePrice: false,
  salePrices: [],
  // customer types
  loadingSaleCustomerType: false,
  saleCustomerTypes: [],
  createdCustomer: null,
  customerId: "",
  viewBillingSmallPrintDetailsData: [],
  infoBarData: [],
  viewBillingA4PrintDetailsData:[],
};

export const Sale = createSlice({
  name: "SaleReducer",
  initialState,
  reducers: {
    getSaleRequest: (state, payload) => {
      state.loadingSale = true;
    },
    getSaleSuccess: (
      state,
      { payload: { results, count, next, previous, infoBarData } }
    ) => {
      state.loadingSale = false;
      state.sales = results;
      state.count = count;
      state.next = next;
      state.previous = previous;
      state.infoBarData = infoBarData;
    },
    getSaleFail: (state) => {
      state.loadingSale = false;
    },
    getSaleNextRequest: (state, payload) => {
      state.loadingSale = true;
    },
    getSalePreviousRequest: (state, payload) => {
      state.loadingSale = true;
    },
    getSaleSearchDataSuccess: (state, { payload: { results } }) => {
      state.loadingSearchData = true;
    },
    getSaleSearchDataFail: (state) => {
      state.loadingSearchData = false;
    },
    createSaleRequest: (state, payload) => {
      state.loadingCreateSale = true;
    },
    createSaleSuccess: (state, { payload }) => {
      state.loadingCreateSale = false;
      state.createdSalesId = payload?.response?.data?.id;
    },
    createSaleFail: (state) => {
      state.loadingCreateSale = false;
    },
    //delete sale details
    deleteSaleDetailsSuccess: (state, { payload }) => {
      state.saleDetails = state.saleDetails.filter(
        (data: any) => data?.uniqueId !== payload?.uniqueId
      );
    },
    //discount scheme
    loadingSaleDiscountSchemeRequest: (state) => {
      state.loadingSaleDiscountScheme = true;
    },
    getSaleDiscountSchemeSuccess: (state, { payload: { results } }) => {
      state.loadingDiscountScheme = false;
      state.saleDiscountSchemes = results;
    },
    getSaleDiscountSchemeFail: (state) => {
      state.loadingSaleDiscountScheme = false;
    },
    //loading autocomplete && request Loading
    loadingSaleCustomerRequest: (state, { payload }) => {
      state.loadingSaleCustomer = true;
    },
    getSaleCustomerSuccess: (state, { payload: { results } }) => {
      state.loadingSaleCustomer = false;
      state.saleCustomers = results;
      state.createdCustomer = state.customerId !== "" ? results[0] : null;
    },
    getSaleCustomerFail: (state) => {
      state.loadingSaleCustomer = false;
    },
    // item request
    getSaleItemRequest: (state, payload) => {
      state.loadingSaleItem = true;
    },
    loadingSaleItemRequest: (state) => {
      state.loadingSaleItem = true;
    },
    getSaleItemSuccess: (state, { payload: { results } }) => {
      state.loadingSaleItem = false;
      state.saleItems = results;
    },
    getSaleItemFail: (state) => {
      state.loadingSaleItem = false;
    },
    getSaleCustomerRequest: (state, { payload }) => {
      state.loadingSaleCustomer = true;
      state.customerId = payload?.createdCustomerId;
    },
    getSaleDiscountSchemeRequest: (state, payload) => {
      state.loadingSaleDiscountScheme = true;
    },
    //add form data to table
    addSaleDetails: (state, { payload }) => {
      state.saleDetails = [...state.saleDetails, payload];
    },
    //updated
    updateSaleDetails: (state, { payload }) => {
      state.saleDetails = payload;
    },
    //payment mode
    getSalePaymentModeRequest: (state) => {
      state.loadingSalePaymentMode = true;
    },
    getSalePaymentModeSuccess: (state, { payload }) => {
      state.salePaymentModes = payload.results;
      state.loadingSalePaymentMode = false;
    },
    getSalePaymentModeFail: (state) => {
      state.loadingSalePaymentMode = false;
    },
    //view details
    getViewSaleDetailsRequest: (state, { payload }) => {
      state.loadingViewDetails = true;
      state.saleRowData = payload?.rowData;
      state.saleId = payload?.id;
    },
    getViewSaleDetailsSuccess: (
      state,
      { payload: { results, count, next, previous } }
    ) => {
      state.loadingViewDetails = false;
      state.viewSaleDetails = results;
      state.saleDetailCount = count;
      state.saleDetailNext = next;
      state.saleDetailPrevious = previous;
    },
    getViewSaleDetailsFail: (state) => {
      state.loadingViewDetails = false;
    },
    getViewSaleDetailsNextRequest: (state, payload) => {
      state.loadingViewDetails = true;
    },
    getViewSaleDetailsPreviousRequest: (state, payload) => {
      state.loadingViewDetails = true;
    },
    // get sale details request
    getSaleDetailsRequest: (state, payload) => {
      state.loadingSaleDetails = true;
    },
    getSaleDetailsSuccess: (state, { payload: { results } }) => {
      state.loadingSaleDetails = false;
      state.saleDetails = results?.map((result: any) => ({
        ...result,
        netAmount: 0 * result?.cost,
        grossAmount: 0 * result?.cost,
        discountRate:
          Number(result.discountAmount * 100) /
          (result.cost * result.qty === 0 ? 1 : result.cost * result.qty),
        discountAmount: 0,
        returnQty: 0 * result?.returnQty,
      }));
    },
    getSaleDetailsFail: (state) => {
      state.loadingSaleDetails = false;
    },
    //get print sale details request
    getPrintSaleDetailsRequest: (state, { payload }) => {
      // state.saleRowData = payload?.rowData;
      state.loadingPrintSale = true;
      state.saleId = payload?.id;
    },
    getPrintSaleDetailsSuccess: (state, { payload: { results } }) => {
      state.loadingPrintSale = false;
      state.viewSaleDetails = results;
      state.viewSalePrintDetailsData = results?.map(
        (saleDetail: saleDetails, i: number) => [
          i + 1,
          // saleDetail.itemCode,
          saleDetail.itemName,
          saleDetail.netAmount,
          saleDetail.qty,
          saleDetail.discountAmount,
          saleDetail.taxAmount,
          saleDetail.grossAmount,
          saleDetail.netAmount,
        ]
      );
      state.viewBillingA4PrintDetailsData = results?.map(
        (saleDetail: any, i: number) => {
          let serialNos = '';
          if (saleDetail?.saleDetailSerialNos?.length > 0) {
            saleDetail.saleDetailSerialNos.forEach((serialNoObj: any) => {
              const serialNo = serialNoObj?.serialNo;
              serialNos += `${serialNo}||`;
            });
          }
          return [
            i + 1,
            `${saleDetail?.itemName} ${saleDetail?.batchNo !== null ? `Batch No. ${saleDetail?.batchNo}` : ""} ${saleDetail?.itemCode !== "" || saleDetail?.itemCode !== null ? `(Code:${saleDetail.itemCode})` : ""}  ${saleDetail?.expiryDateAd === null || saleDetail?.expiryDateAd == ""? "":`(Exp:${saleDetail.expiryDateBs})`} ${serialNos !==""?`Serial No:${serialNos}`:""} `,
            saleDetail.cost,
            saleDetail.qty,
            saleDetail.grossAmount,
          ];
        }
      );
      state.viewBillingSmallPrintDetailsData = results?.map(
        (saleDetail: any, i: number) => [
          i + 1,
          `${saleDetail?.itemName}`,
          saleDetail.cost,
          saleDetail.qty,
          saleDetail.grossAmount,
        ]
      );
    },
    getPrintSaleDetailsFail: (state) => {
      state.loadingPrintSale = false;
    },
    //get print count
    getSalePrintCountRequest: (state, payload) => {
      state.loadingPrintSale = true;
    },
    getSalePrintCountSuccess: (state, { payload: { results } }) => {
      state.loadingPrintSale = false;
      state.salePrintCount = results;
    },
    getSalePrintCountFail: (state) => {
      state.loadingPrintSale = false;
    },
    // post print count
    salePrintCountRequest: (state, payload) => {
      state.loadingPrintSale = true;
    },
    salePrintCountSuccess: (state, payload) => {
      state.loadingPrintSale = false;
    },
    salePrintCountFail: (state) => {
      state.loadingPrintSale = false;
    },
    // get customer details
    getSaleCustomerDetailsRequest: (state, payload) => {
      state.loadingSaleCustomerDetails = true;
    },
    getSaleCustomerDetailsSuccess: (state, { payload }) => {
      state.saleRowData = {
        ...payload,
        customerTypeCode: `${payload?.customerType?.code}/${payload?.isIpd ? "IPD" : "OPD"}`,
      };
      state.loadingSaleCustomerDetails = false;
    },
    getSaleCustomerDetailsFail: (state) => {
      state.loadingSaleCustomerDetails = false;
    },
    // get sale packing type details
    getSalePackingTypeDetailRequest: (state, payload) => {
      state.loadingSalePackingTypeDetail = true;
    },
    getSalePackingTypeDetailSuccess: (state, { payload }) => {
      state.salePackingTypeDetails = payload.results;
      state.loadingSalePackingTypeDetail = false;
    },
    getSalePackingTypeDetailFail: (state) => {
      state.loadingSalePackingTypeDetail = false;
    },
    //additional charge
    getSaleAdditionalChargeRequest: (state) => {
      state.loadingSaleAdditionalCharge = true;
    },
    getSaleAdditionalChargeSuccess: (state, { payload }) => {
      state.saleAdditionalCharges = payload.results;
      state.loadingSaleAdditionalCharge = false;
    },
    getSaleAdditionalChargeFail: (state) => {
      state.loadingSaleAdditionalCharge = false;
    },
    //get sale customer types
    getSaleCustomerTypeRequest: (state) => {
      state.loadingSaleCustomerType = true;
    },
    getSaleCustomerTypeSuccess: (state, { payload }) => {
      state.loadingSaleCustomerType = false;
      state.saleCustomerTypes = payload?.results;
    },
    getSaleCustomerTypeFail: (state) => {
      state.loadingSaleCustomerType = false;
    },
    // get sale price
    getSalePriceRequest: (state, payload) => {
      state.loadingSalePrice = true;
    },
    getSalePriceSuccess: (state, { payload }) => {
      state.loadingSalePrice = false;
      state.salePrices = payload?.results;
    },
    getSalePriceFail: (state) => {
      state.loadingSalePrice = false;
    },
    // clear sale data
    clearSaleData: (state) => {
      state.saleDetails = [];
      state.salePrices = [];
      state.viewSaleDetails = [];
      state.saleRowData = null;
      state.viewBillingSmallPrintDetailsData= [];
      state.viewBillingA4PrintDetailsData=[];
    },
    //
  },
});
// Action creators are generated for each case reducer function
export const {
  getSaleRequest,
  getSaleSuccess,
  getSaleFail,
  createSaleRequest,
  createSaleSuccess,
  createSaleFail,
  clearSaleData,
  getSaleSearchDataSuccess,
  getSaleSearchDataFail,
  getSaleNextRequest,
  getSalePreviousRequest,
  // get organization rule
  getSaleCustomerDetailsRequest,
  getSaleCustomerDetailsSuccess,
  getSaleCustomerDetailsFail,
  //customer
  getSaleCustomerFail,
  getSaleCustomerSuccess,
  loadingSaleCustomerRequest,
  getSaleCustomerRequest,
  //discount scheme
  getSaleDiscountSchemeFail,
  getSaleDiscountSchemeSuccess,
  loadingSaleDiscountSchemeRequest,
  getSaleDiscountSchemeRequest,
  //sale test
  getSaleItemFail,
  getSaleItemSuccess,
  loadingSaleItemRequest,
  getSaleItemRequest,
  //add
  addSaleDetails,
  //delete
  deleteSaleDetailsSuccess,
  updateSaleDetails,
  //payment
  getSalePaymentModeRequest,
  getSalePaymentModeSuccess,
  getSalePaymentModeFail,
  // packing type
  getSalePackingTypeDetailRequest,
  getSalePackingTypeDetailSuccess,
  getSalePackingTypeDetailFail,
  //view details
  getViewSaleDetailsRequest,
  getViewSaleDetailsSuccess,
  getViewSaleDetailsFail,
  getViewSaleDetailsNextRequest,
  getViewSaleDetailsPreviousRequest,
  getSaleDetailsRequest,
  getSaleDetailsSuccess,
  getSaleDetailsFail,
  getPrintSaleDetailsRequest,
  getPrintSaleDetailsSuccess,
  getPrintSaleDetailsFail,
  getSalePrintCountRequest,
  getSalePrintCountSuccess,
  getSalePrintCountFail,
  salePrintCountRequest,
  salePrintCountSuccess,
  salePrintCountFail,
  //additional charge
  getSaleAdditionalChargeRequest,
  getSaleAdditionalChargeSuccess,
  getSaleAdditionalChargeFail,
  // sale price
  getSalePriceRequest,
  getSalePriceSuccess,
  getSalePriceFail,
  // sale customer type
  getSaleCustomerTypeRequest,
  getSaleCustomerTypeSuccess,
  getSaleCustomerTypeFail,
} = Sale.actions;
export default Sale.reducer;
