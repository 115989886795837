import { createSlice, createAction } from "@reduxjs/toolkit";
import {  UserActivityLogReportInitialState } from "./types";
const initialState: UserActivityLogReportInitialState = {
  suppliers: [],
  users:[],
  items:[],
  purchaseDetails:[],
  //loading 
  loadingGenerateReport: false,
  loadingUserActivityLogReportSupplier:false,
  loadingUserActivityLogReportItem:false,
  loadingUserActivityLogReportUser:false,
  loadingUserActivityLogReportDetail:false,
  count: 0,
  previous: "",
  next: "",
};

export const UserActivityLogReport = createSlice({
  name: "userActivityLogReportReducer",
  initialState,
  reducers: {
    //loading
    loadingUserActivityLogReportDetail: (state) => {
      state.loadingUserActivityLogReportDetail = true;
    },
     //external reducer request
     getUserActivityLogReportDetailRequest:(state,{payload})=>{
     state.loadingUserActivityLogReportDetail=true;
     },
     getUserActivityLogReportSupplierRequest:(state)=>{
      state.loadingUserActivityLogReportSupplier=true;
    },
    getUserActivityLogReportItemRequest:(state)=>{
      state.loadingUserActivityLogReportItem=true;
    },
    getUserActivityLogReportUserRequest:(state)=>{
      state.loadingUserActivityLogReportUser=true;
    },
    getUserActivityLogReportAllSummaryRequest:(state,{payload})=>{
        state.loadingGenerateReport=true;
    },
    getUserActivityLogReportAllDetailRequest:(state,{payload})=>{
      state.loadingGenerateReport=true;
    },
    getUserActivityLogReportQuickSummaryRequest:(state,{payload})=>{
      state.loadingGenerateReport=true;
    },
    getUserActivityLogReportQuickDetailRequest:(state,{payload})=>{
    state.loadingGenerateReport=true;
    },
    getUserActivityLogReportNextRequest: (state, payload) => {
      state.loadingUserActivityLogReportDetail = true;
    },
    getUserActivityLogReportPreviousRequest: (state, payload) => {
      state.loadingUserActivityLogReportDetail = true;
    },
    //external get reducer  
    getUserActivityLogReportDetailSuccess: (
      state,
      { payload: { results, count, next, previous } }
    ) => {
      state.purchaseDetails = results;
      state.loadingUserActivityLogReportDetail = false;
      state.count = count;
      state.next = next;
      state.previous = previous;
    },
    getUserActivityLogReportDetailFail: (state) => {
      state.loadingUserActivityLogReportDetail = false;
    },
    getUserActivityLogReportUserSuccess: (state,{ payload: { results } }) => {
      state.loadingUserActivityLogReportUser = false;
      state.users = results;
    },
    getUserActivityLogReportUserFail: (state) => {
      state.loadingUserActivityLogReportUser = false;
    },
    getUserActivityLogReportSupplierSuccess: (state,{ payload: { results } }) => {
      state.loadingUserActivityLogReportSupplier = false;
      state.suppliers = results;
    },
    getUserActivityLogReportSupplierFail: (state) => {
      state.loadingUserActivityLogReportSupplier = false;
    },
    getUserActivityLogReportItemSuccess: (state,{ payload: { results } }) => {
      state.loadingUserActivityLogReportItem = false;
      state.items = results;
    },
    getUserActivityLogReportItemFail: (state) => {
      state.loadingUserActivityLogReportItem = false;
    },
    getUserActivityLogReportAllSummarySuccess: (state,{ payload: { results } }) => {
      state.loadingGenerateReport = false;
      state.purchaseDetails = results;
    },
    getUserActivityLogReportAllSummaryFail: (state) => {
      state.loadingGenerateReport = false;
    },
    getUserActivityLogReportQuickSummarySuccess:  (
      state,
      { payload: { results, count, next, previous } }
    ) => {
      state.loadingGenerateReport = false;
      state.purchaseDetails = results;
      state.count = count;
      state.next = next;
      state.previous = previous;
    },
    getUserActivityLogReportQuickSummaryFail: (state) => {
      state.loadingGenerateReport = false;
    },
    getUserActivityLogReportAllDetailSuccess: (state,{ payload: { results } }) => {
      state.loadingGenerateReport = false;
      state.purchaseDetails = results;
    },
    getUserActivityLogReportAllDetailFail: (state) => {
      state.loadingGenerateReport = false;
    },
    getUserActivityLogReportQuickDetailSuccess:  (
      state,
      { payload: { results, count, next, previous } }
    ) => {
      state.loadingGenerateReport = false;
      state.purchaseDetails = results;
      state.count = count;
      state.next = next;
      state.previous = previous;
    },
    getUserActivityLogReportQuickDetailFail: (state) => {
      state.loadingGenerateReport = false;
    },

    clearUserActivityLogReportData: (state) => {
      state.suppliers = [];
      state.users = [];
      state.items = [];
    },
    clearPrintUserActivityLogData: (state) => {
      state.purchaseDetails = [];
    },
  },
});
// Action creators are generated for each case reducer function
export const {
  //reducerReport
  getUserActivityLogReportDetailRequest,
  getUserActivityLogReportSupplierRequest,
  getUserActivityLogReportItemRequest,
  getUserActivityLogReportUserRequest,
  getUserActivityLogReportAllSummaryRequest,
  getUserActivityLogReportAllDetailRequest,
  getUserActivityLogReportQuickSummaryRequest,
  getUserActivityLogReportQuickDetailRequest,
  getUserActivityLogReportPreviousRequest,
  getUserActivityLogReportNextRequest,
  //reducer get data
  getUserActivityLogReportDetailSuccess,
  getUserActivityLogReportDetailFail,
  getUserActivityLogReportItemSuccess,
  getUserActivityLogReportItemFail,
  getUserActivityLogReportSupplierSuccess,
  getUserActivityLogReportSupplierFail,
  getUserActivityLogReportUserSuccess,
  getUserActivityLogReportUserFail,
  getUserActivityLogReportQuickDetailSuccess,
  getUserActivityLogReportQuickDetailFail,
  getUserActivityLogReportAllDetailSuccess,
  getUserActivityLogReportAllDetailFail,
  getUserActivityLogReportQuickSummarySuccess,
  getUserActivityLogReportQuickSummaryFail,
  getUserActivityLogReportAllSummarySuccess,
  getUserActivityLogReportAllSummaryFail,
  //loading
  loadingUserActivityLogReportDetail,
  //clear
  clearUserActivityLogReportData,
  clearPrintUserActivityLogData,
} = UserActivityLogReport.actions;
export default UserActivityLogReport.reducer;
