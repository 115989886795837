import { Suspense, useMemo, useState, useEffect, useCallback } from "react";
import Modal from "../Modal";
import { ModalProps } from "../Modal/types";
import { useAppSelector } from "../../AppUtils/Utils/appHooks";
import { commonAppSelector } from "../../Pages/CommonAppRedux/selector";
import { useAppDispatch } from "../../AppUtils/Utils/appHooks";
import {
  hideCreditSalesInfo,
  setShowCreditSalesInfo,
} from "../../Pages/CommonAppRedux/CommonAppSlice";
import AppTable from "../Table/AppTable";
import AppButton from "../AppElements/Button/AppButton";
import {
  getInventoryCreditSalesInfoRequest,
  getClinicCreditSalesInfoRequest,
} from "../../Pages/CommonAppRedux/CommonAppSlice";
import { filterData } from "../FilterData/types";
import { hasRequiredPermissions } from "../../AppUtils/Utils/permissions";
import { clinicCreditSalesInfoPermission } from "../Layout/Sidebar/Clinic/ClinicPermissionConstants";
import { inventoryCreditSalesInfoPermission } from "../Layout/Sidebar/Inventory/InvPermissionConstants";
import { loginSelector } from "../../Pages/Auth/Redux/selector";

export default function SalesInfo() {
  const dispatch = useAppDispatch();

  const {
    rowsPerPage,
    page,
    showCreditSalesInfo,
    loadingInventoryCreditSalesInfo,
    loadingClinicCreditSalesInfo,
    clinicCreditSalesInfo,
    inventoryCreditSalesInfo,
  } = useAppSelector(commonAppSelector);
  const { permissions, isSuperuser } = useAppSelector(loginSelector);

  const [activeSalesInfo, setActiveSalesInfo] = useState<
    "Inventory" | "Clinic" | null
  >(null);

  const canViewInventory = hasRequiredPermissions(
    isSuperuser,
    permissions,
    inventoryCreditSalesInfoPermission
  );
  
  const canViewClinic = hasRequiredPermissions(
    isSuperuser,
    permissions,
    clinicCreditSalesInfoPermission
  );

  useEffect(() => {
    if (canViewInventory && !activeSalesInfo) {
      setActiveSalesInfo("Inventory");
    } else if (canViewClinic && !activeSalesInfo) {
      setActiveSalesInfo("Clinic");
    }
  }, [canViewInventory, canViewClinic, activeSalesInfo]);

  useEffect(() => {
    dispatch(getInventoryCreditSalesInfoRequest({ rowsPerPage, page }));
    dispatch(getClinicCreditSalesInfoRequest({ rowsPerPage, page }));
  }, [dispatch, page, rowsPerPage]);

  const columns = useMemo(
    () => [
      {
        id: "saleNoFull",
        label: "Sale No",
        filterField: "sale_no",
        minWidth: 130,
        styleId: true,
      },
      {
        id: "createdDateAd",
        label: "Bill Date",
        filterField: "created_date_ad",
        minWidth: 100,
        date: true,
      },
      { id: "customerFullName", label: "Customer", avatar: true },
      {
        id: "customerContactNo",
        filterField: "mobile_no",
        label: "Contact No",
      },
      {
        id: "creditAmount",
        label: "Credit Amount",
        minWidth: 100,
        amount: true,
      },
      { id: "paidAmount", label: "Paid Amont", minWidth: 100, amount: true },
      { id: "dueAmount", label: "Due Amount", minWidth: 100, amount: true },
      {
        id: "createdByFullName",
        label: "Billed By",
        minWidth: 100,
        avatar: true,
      },
    ],
    []
  );

  const ModalProps: ModalProps = useMemo(
    () => ({
      title: "Credit Sales Info",
      types: "details",
      maxWidth: "lg",
      displayTitleOnly: true,
      showModal: showCreditSalesInfo,
      setShowModal: (show) => {
        if (show) {
          dispatch(setShowCreditSalesInfo(true));
        } else {
          dispatch(hideCreditSalesInfo());
        }
      },
    }),
    [showCreditSalesInfo, dispatch]
  );

  const count = 10;

  //handle filter data
  const handleFilterInventoryData = useCallback(
    (filterData: filterData) => {
      dispatch(
        getInventoryCreditSalesInfoRequest({ rowsPerPage, page, filterData })
      );
    },
    [rowsPerPage, dispatch, page]
  );

  const inventoryTableProps = useMemo(() => {
    return {
      columns,
      data: inventoryCreditSalesInfo,
      count,
      hideAction: true,
      handleFilterData: handleFilterInventoryData,
      hidePaginationAndFilterField: false,
      hidePreferences: true,
      title: "Inventory Credit Sales",
      loading: loadingInventoryCreditSalesInfo,
    };
  }, [
    inventoryCreditSalesInfo,
    columns,
    loadingInventoryCreditSalesInfo,
    handleFilterInventoryData,
  ]);

  const clinicTableProps = useMemo(() => {
    return {
      columns,
      data: clinicCreditSalesInfo,
      count,
      handleFilterData: handleFilterInventoryData,
      hideAction: true,
      hidePaginationAndFilterField: false,
      hidePreferences: true,
      title: "Clinic Credit Sales",
      loading: loadingClinicCreditSalesInfo,
    };
  }, [
    clinicCreditSalesInfo,
    columns,
    loadingClinicCreditSalesInfo,
    handleFilterInventoryData,
  ]);

  return (
    <Suspense fallback={<></>}>
      <Modal {...ModalProps}>
        <div style={{ display: "flex", gap: "10px", marginBottom: "20px" }}>
          {canViewInventory && (
            <AppButton
              title="Inventory"
              tooltipTitle="Inventory Credit Sales Info"
              style={{
                padding: "10px 20px",
                ...(activeSalesInfo !== "Inventory" && {
                  backgroundColor: "#f0f0f0",
                  color: "#000",
                }),
              }}
              onClick={() => setActiveSalesInfo("Inventory")}
            />
          )}
          {canViewClinic && (
            <AppButton
              title="Clinic"
              tooltipTitle="Clinic Credit Sales Info"
              style={{
                padding: "10px 20px",
                ...(activeSalesInfo !== "Clinic" && {
                  backgroundColor: "#f0f0f0",
                  color: "#000",
                }),
              }}
              onClick={() => setActiveSalesInfo("Clinic")}
            />
          )}
        </div>
        {activeSalesInfo === "Inventory" && (
          <AppTable {...inventoryTableProps} />
        )}
        {activeSalesInfo === "Clinic" && <AppTable {...clinicTableProps} />}
      </Modal>
    </Suspense>
  );
}
