import { axiosInstance } from "../../../../AppUtils/Utils/axios";
import { filterApiData } from "../../../../AppUtils/Utils/appFunctions";
import {
  getApiProps,
  searchProps,
} from "../../../../AppUtils/Utils/globalTypes";

// constant api for refund billing
export const refundBillingApi = "api/v1/clinic-sale-app/clinic-sale-return";

//get refund billing data
export const getRefundBilling = (data: getApiProps) => {
  const { rowsPerPage, page } = data;
  return axiosInstance.get(
    `${refundBillingApi}?offset=${rowsPerPage * page}&limit=${rowsPerPage}${
      data?.filterData ? filterApiData(data?.filterData) : ""
    }`
  );
};
// get all customers for refund billing
export const getAllCustomers = ({ rowsPerPage }: any) =>
  axiosInstance.get(
    `${refundBillingApi}/customer?offset=0&limit=${rowsPerPage}`
  );

// get all pending billings respect to customer
export const getAllPendingBillings = ({ id }: any) =>
  axiosInstance.get(`${refundBillingApi}/sale?customer=${id}`);

// get specific billing to return respect to sale main
export const getSpecificBillingReturn = ({ id }: any) =>
  axiosInstance.get(
    `${refundBillingApi}/sale-detail-for-return?sale_main=${id}`
  );

// get payment modes
export const getPaymentMode = () =>
  axiosInstance.get(`${refundBillingApi}/payment-mode?offset=0limit=0`);
//handle search
export const searchRefundBilling = ({ rowsPerPage, search }: searchProps) => {
  return `${refundBillingApi}?offset=0&limit=${rowsPerPage}&search=${search}`;
};
//create RefundBilling
export const createRefundBilling = (body: string | FormData) =>
  axiosInstance.post(`${refundBillingApi}`, body);

export const getViewRefundDetails = ({ id, rowsPerPage, page }: any) =>
  axiosInstance.get(
    `api/v1/clinic-sale-app/clinic-sale-return/sale-detail?sale_main=${id}&offset=${
      rowsPerPage * page
    }&limit=${rowsPerPage}`
  );

export const getPrintRefundDetails = ({ id }: any) =>
  axiosInstance.get(
    `api/v1/clinic-sale-app/clinic-sale-return/sale-detail?sale_main=${id}&offset=0&limit=0`
  );
  export const getPrintCustomerDetails = ({ id }: any) =>
  axiosInstance.get(
    `api/v1/clinic-sale-app/clinic-sale-return/${id}`
  );
//print count
export const printCount = (body: string) =>
  axiosInstance.post(
    `api/v1/clinic-sale-app/clinic-sale-return/update-invoice-print-count`,
    body
  );

//get print count
export const getRefundPrintCount = (id: number) =>
  axiosInstance.get(
    `api/v1/clinic-sale-app/clinic-sale/invoice-print-count?id=${id}`
  );

