import { createSlice } from "@reduxjs/toolkit";
import { depositInitialState } from "./types";

const initialState: depositInitialState = {
  deposits: [],
  edit: false,
  deposit: null,
  loadingDeposit: false,
  loadingCreateDeposit: false,
  loadingDepositCustomer: false,
  customers: [],
  loadingDepositOrder: false,
  orders: [],
  count: 0,
  previous: "",
  next: "",
};

export const Deposit = createSlice({
  name: "depositReducer",
  initialState,
  reducers: {
    getDepositRequest: (state, payload) => {
      state.loadingDeposit = true;
    },
    getDepositSuccess: (
      state,
      { payload: { results, count, next, previous } }
    ) => {
      state.loadingDeposit = false;
      state.deposits = results;
      state.count = count;
      state.next = next;
      state.previous = previous;
    },
    getDepositFail: (state) => {
      state.loadingDeposit = false;
    },

    getDepositNextRequest: (state, payload) => {
      state.loadingDeposit = true;
    },
    getDepositPreviousRequest: (state, payload) => {
      state.loadingDeposit = true;
    },
    createDepositRequest: (state, payload) => {
      state.loadingCreateDeposit = true;
    },
    updateDepositRequest: (state, payload) => {
      state.loadingCreateDeposit = true;
    },
    createDepositSuccess: (state) => {
      state.loadingCreateDeposit = false;
    },
    createDepositFail: (state) => {
      state.loadingCreateDeposit = false;
    },
    getDepositByIdRequest: (state, payload) => {
      state.edit = true;
    },
    depositEditSuccess: (state, { payload }) => {
      state.deposit = payload;
    },
    depositEditFail: (state) => {
      state.edit = false;
    },
    updateDepositSuccess: (state) => {
      state.loadingCreateDeposit = false;
    },
    updateDepositFail: (state) => {
      state.loadingCreateDeposit = false;
    },
    loadingDepositCustomerRequest: (state) => {
      state.loadingDepositCustomer = true;
    },
    getDepositCustomerRequest: (state, { payload }) => {
      state.loadingDepositCustomer = true;
    },
    getDepositCustomerSuccess: (state, { payload: { results } }) => {
      state.loadingDepositCustomer = false;
      state.customers = results;
    },
    getDepositCustomerFail: (state) => {
      state.loadingDepositCustomer = false;
    },
    loadingDepositOrderRequest: (state) => {
      state.loadingDepositOrder = true;
    },
    getDepositOrderRequest: (state, { payload }) => {
      state.loadingDepositOrder = true;
    },
    getDepositOrderSuccess: (state, { payload: { results } }) => {
      state.loadingDepositOrder = false;
      state.orders = results;
    },
    getDepositOrderFail: (state) => {
      state.loadingDepositOrder = false;
    },
    clearAllData: (state) => {
      state.edit = false;
      state.deposit = null;
      state.orders = [];
      state.customers = [];
    },
  },
});

//action creaters generated for each case reducer
export const {
  getDepositRequest,
  getDepositFail,
  getDepositSuccess,
  //
  getDepositNextRequest,
  getDepositPreviousRequest,
  //
  createDepositFail,
  createDepositRequest,
  createDepositSuccess,
  //
  depositEditSuccess,
  updateDepositFail,
  updateDepositSuccess,
  updateDepositRequest,
  //
  clearAllData,
  //
  getDepositByIdRequest,
  depositEditFail,
  //
  loadingDepositCustomerRequest,
  getDepositCustomerRequest,
  getDepositCustomerSuccess,
  getDepositCustomerFail,
  //
  loadingDepositOrderRequest,
  getDepositOrderRequest,
  getDepositOrderSuccess,
  getDepositOrderFail,
} = Deposit.actions;

export default Deposit.reducer;
