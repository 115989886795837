import { createSlice, createAction } from "@reduxjs/toolkit";
const initialState: any = {
  clinicOrders: [],
  clinicOrderDetails: [],
  loadingClinicOrder: false,
  loadingClinicOrderDepartment: false,
  loadingClinicOrderCategory: false,
  loadingClinicOrderCustomerType: false,
  loadingCreateClinicOrder: false,
  edit: false,
  clinicOrderDetailEdit: false,
  clinicOrder: null,
  clinicOrderDetail: null,
  customerTypes: [],
  departments: [],
  clinicOrderCategories: [],
  count: 0,
  previous: "",
  next: "",
  loadingClinicOrderDetailsAdded:false,
  // loadingClinicOrderCustomer:false,
  // loadingHospitalReferralCustomer:false,
  // clinicOrderCustomers:[],
  loadingClinicOrderItem:false,
  loadingHospitalReferralItem:false,
  clinicOrderItems:[],
  loadingDeviceOrderItemCategory:false,
  deviceOrderItemCategories:[],
  loadingItemCategory:true,

};

export const ClinicOrder = createSlice({
  name: "ClinicOrder Reducer",
  initialState,
  reducers: {
    loadingClinicOrder: (state) => {
      state.loadingClinicOrder = true;
    },
    getClinicOrderRequest: (state, payload) => {
      state.loadingClinicOrder = true;
    },
    getClinicOrderSuccess: (
      state,
      { payload: { results, count, next, previous } }
    ) => {
      state.loadingClinicOrder = false;
      state.clinicOrders = results;
      state.count = count;
      state.next = next;
      state.previous = previous;
    },
    getClinicOrderFail: (state) => {
      state.loadingClinicOrder = false;
    },
    getClinicOrderNextRequest: (state, payload) => {
      state.loadingClinicOrder = true;
    },
    getClinicOrderPreviousRequest: (state, payload) => {
      state.loadingClinicOrder = true;
    },
    getClinicOrderCustomerTypeRequest: (state, payload) => {
      state.loadingClinicOrderCustomerType = true;
    },
    getClinicOrderCustomerTypeSuccess: (state, { payload: { results } }) => {
      state.loadingClinicOrderCustomerType = false;
      state.customerTypes = results;
    },
    getClinicOrderCustomerTypeFail: (state) => {
      state.loadingClinicOrderCustomerType = false;
    },
    getClinicOrderDepartmentRequest: (state, payload) => {
      state.loadingClinicOrderDepartment = true;
    },
    getClinicOrderDepartmentSuccess: (state, { payload: { results } }) => {
      state.loadingClinicOrderDepartment = false;
      state.departments = results;
    },
    getClinicOrderDepartmentFail: (state) => {
      state.loadingClinicOrderDepartment = false;
    },
    getClinicOrderCategoryRequest: (state, payload) => {
      state.loadingClinicOrderCategory = true;
    },
    getClinicOrderCategorySuccess: (state, { payload: { results } }) => {
      state.loadingClinicOrderCategory = false;
      state.clinicOrderCategories = results;
    },
    getClinicOrderCategoryFail: (state) => {
      state.loadingClinicOrderCategory = false;
    },
    createClinicOrderRequest: (state, payload) => {
      state.loadingCreateClinicOrder = true;
      state.closeModal = true;
    },
    createClinicOrderSuccess: (state) => {
      state.loadingCreateClinicOrder = false;
    },
    createClinicOrderFail: (state) => {
      state.loadingCreateClinicOrder = false;
    },
    getClinicOrderByIdRequest: (state, payload) => {
      state.edit = true;
    },
    clinicOrderEditSuccess: (state, { payload }) => {
      // const editableRow = state.clinicOrders?.find(
      //   (clinicOrder: any) => clinicOrder?.id === payload?.id
      // );
      state.edit = true;
      state.clinicOrder = payload;
      state.clinicOrderDetails = payload?.clinicOrderDetails;
    },
    clinicOrderEditFail: (state) => {
      state.edit = false;
      state.clinicOrder = null;
      state.clinicOrderDetails = null;
    },
    clinicOrderDetailEditSuccess: (state, { payload }) => {
      const updatedClinicOrderDetail = state.clinicOrderDetails.find(
        (clinicOrder: any) => clinicOrder?.id === payload?.id
      );
      const updatedClinicOrderDetails = state.clinicOrderDetails.filter(
        (clinicOrder: any) => clinicOrder.id !== payload.id
      );
      state.clinicOrderDetail = updatedClinicOrderDetail;
      state.clinicOrderDetails = updatedClinicOrderDetails;
      state.clinicOrderDetailEdit = true;
      // state.loadingClinicOrder = false;
    },
    clinicOrderDetailUpdateSuccess: (state, { payload }) => {
      state.clinicOrderDetails = [...state.clinicOrderDetails, payload];
      state.clinicOrderDetailEdit = false;
      state.edit = true;
      state.loadingClinicOrderDetailsAdded = false;
    },
    clinicOrderDetailAddedSuccess: (state, { payload }) => {
      state.clinicOrderDetails = [...state.clinicOrderDetails, payload];
      state.clinicOrderDetailEdit = false;
      state.loadingClinicOrderDetailsAdded = false;
    },
    clinicOrderDetailDeleteSuccess: (state, { payload }) => {
      const updatedClinicOrderDetails = state.clinicOrderDetails.filter(
        (clinicOrder: any) => clinicOrder.unique_id !== payload.unique_id
      );
      state.clinicOrderDetails = updatedClinicOrderDetails;
      state.clinicOrderDetailEdit = false;
      state.loadingClinicOrderDetailsAdded = false;
    },
    updateClinicOrderRequest: (state, payload) => {
      state.loadingCreateClinicOrder = true;
    },
    updateClinicOrderSuccess: (state) => {
      state.loadingCreateClinicOrder = false;
    },
    updateClinicOrderFail: (state) => {
      state.loadingCreateClinicOrder = false;
    },
    clearClinicOrderData: (state) => {
      state.edit = false;
      state.clinicOrder = null;
      state.clinicOrderDetail = null;
      state.clinicOrderDetails = [];
      state.loadingClinicOrder = false;
      state.clinicOrderDetailEdit = false;
    },
    ///
        //customer request
        // getClinicOrderCustomerSuccess: (state, { payload: { results } }) => {
        //   state.loadingClinicOrderCustomer = false;
        //   state.hospitalReferralCustomers = results;
        // },
        // getClinicOrderCustomerFail: (state) => {
        //   state.loadingClinicOrderCustomer = false;
        // },
        // loadingClinicOrderCustomerRequest: (state) => {
        //   state.loadingClinicOrderCustomer = true;
        // },
        // getClinicOrderCustomerRequest: (state, payload) => {
        //   state.loadingHospitalReferralCustomer = true;
        // },
        //
        getClinicOrderItemSuccess: (state, { payload: { results } }) => {
          state.loadingClinicOrderItem = false;
          state.clinicOrderItems = results;
        },
        getClinicOrderItemFail: (state) => {
          state.loadingClinicOrderItem = false;
        },
        loadingClinicOrderItemRequest: (state) => {
          state.loadingClinicOrderItem = true;
        },
        getClinicOrderItemRequest: (state, payload) => {
          state.loadingClinicOrderItem = true;
        },
        getDeviceOrderItemCategorySuccess: (state, { payload: { results } }) => {
          state.loadingDeviceOrderItemCategory = false;
          state.deviceOrderItemCategories = results;
        },
        getDeviceOrderItemCategoryFail: (state) => {
          state.loadingDeviceOrderItemCategory = false;
        },
        loadingDeviceOrderItemCategoryRequest: (state) => {
          state.loadingDeviceOrderItemCategory = true;
        },
        getDeviceOrderItemCategoryRequest: (state, payload) => {
          state.loadingItemCategory = true;
        },
  },
});
// Action creators are generated for each case reducer function
export const {
  loadingClinicOrder,
  getClinicOrderRequest,
  getClinicOrderSuccess,
  getClinicOrderFail,
  getClinicOrderNextRequest,
  getClinicOrderPreviousRequest,
  getClinicOrderCustomerTypeRequest,
  getClinicOrderCustomerTypeSuccess,
  getClinicOrderCustomerTypeFail,
  getClinicOrderDepartmentRequest,
  getClinicOrderDepartmentSuccess,
  getClinicOrderDepartmentFail,
  getClinicOrderCategoryRequest,
  getClinicOrderCategorySuccess,
  getClinicOrderCategoryFail,
  createClinicOrderRequest,
  createClinicOrderSuccess,
  createClinicOrderFail,
  clinicOrderEditSuccess,
  clinicOrderEditFail,
  clinicOrderDetailEditSuccess,
  clinicOrderDetailUpdateSuccess,
  clinicOrderDetailAddedSuccess,
  clinicOrderDetailDeleteSuccess,
  updateClinicOrderRequest,
  updateClinicOrderSuccess,
  updateClinicOrderFail,
  clearClinicOrderData,
  getClinicOrderByIdRequest,
  // getClinicOrderCustomerSuccess,
  // getClinicOrderCustomerFail,
  // loadingClinicOrderCustomerRequest,
  // getClinicOrderCustomerRequest,
  //
  getClinicOrderItemSuccess,
  getClinicOrderItemFail,
  loadingClinicOrderItemRequest,
  getClinicOrderItemRequest,

  getDeviceOrderItemCategorySuccess,
  getDeviceOrderItemCategoryFail,
  loadingDeviceOrderItemCategoryRequest,
  getDeviceOrderItemCategoryRequest,

} = ClinicOrder.actions;
export default ClinicOrder.reducer;
