import { mergeMap } from "rxjs";
import {
  createUserAddress,
  getEditedUserAddress,
  getUserAddressById,
  getUserAddressDistrict,
  getUserAddressProvince,
  updateUserAddress,
} from "./api";
import { map, filter } from "rxjs/operators";
import { Observable } from "rxjs";
import { Action } from "@reduxjs/toolkit";
import { combineEpics } from "redux-observable";

import {
  clearAllAddressData,
  createUserAddressFail,
  createUserAddressRequest,
  createUserAddressSuccess,
  editPatientDetailsAddressFail,
  editPatientDetailsAddressRequest,
  editPatientDetailsAddressSuccess,
  getUserAddressRequest,
  getUserAddressByIdSuccess,
  getUserAddressByIdFail,
  getUserAddressDistrictFail,
  getUserAddressDistrictRequest,
  getUserAddressDistrictSuccess,
  getUserAddressProvinceFail,
  getUserAddressProvinceRequest,
  getUserAddressProvinceSuccess,
  updateUserAddressFail,
  updateUserAddressRequest,
  updateUserAddressSuccess,
} from "./addressSlice";
import {
  dispatchAction,
  stateAction,
} from "../../../../../../AppUtils/Utils/globalTypes";
import {
  alertErrorAction,
  alertSuccessAction,
  closeModal,
} from "../../../../../CommonAppRedux/CommonAppSlice";
import messages from "../../../../../../AppUtils/Utils/validationConstants";

export const controller = new AbortController();

const getUserAddressByIdEpic = (
  action$: Observable<Action>,
  _: stateAction,
  { dispatch }: dispatchAction
) =>
  action$.pipe(
    filter(getUserAddressRequest.match),
    mergeMap(async (action) => {
      try {
        const response = await getUserAddressById(action?.payload);
        return { payload: response.data };
      } catch (e) {
        return { error: e };
      }
    }),
    map((action) =>
      action?.payload
        ? getUserAddressByIdSuccess(action?.payload)
        : getUserAddressByIdFail()
    )
  );

const createUserAddressByIdEpic = (
  action$: Observable<Action>,
  _: stateAction,
  { dispatch }: dispatchAction
) =>
  action$.pipe(
    filter(createUserAddressRequest.match),
    mergeMap(async ({ payload: { values } }) => {
      try {
        const body = JSON.stringify(values);
        const response = await createUserAddress(body);
        if (response) {
          dispatch(getUserAddressRequest({ customerId: values?.id }));
          dispatch(alertSuccessAction(messages.createMessage));
          dispatch(closeModal());
          dispatch(clearAllAddressData());
        }
        return { payload: { response } };
      } catch (e) {
        // @ts-ignore
        dispatch(alertErrorAction(messages?.createFailMessage));
        return { error: e };
      }
    }),
    map((action) => {
      return action?.payload
        ? createUserAddressSuccess()
        : createUserAddressFail();
    })
  );

const updateUserAddressByIdEpic = (
  action$: Observable<Action>,
  _: stateAction,
  { dispatch }: dispatchAction
) =>
  action$.pipe(
    filter(updateUserAddressRequest.match),
    mergeMap(async ({ payload: { values } }) => {
      try {
        const body = JSON.stringify(values);
        const response = await updateUserAddress(body, values?.id);
        if (response) {
          dispatch(getUserAddressRequest({ customerId: values?.id }));
          dispatch(alertSuccessAction(messages.updateMessage));
          dispatch(closeModal());
          dispatch(clearAllAddressData());
        }
        return { payload: { response } };
      } catch (e) {
        // @ts-ignore
        dispatch(alertErrorAction(messages?.updateFailMessage));
        return { error: e };
      }
    }),
    map((action) => {
      return action?.payload
        ? updateUserAddressSuccess()
        : updateUserAddressFail();
    })
  );

const getEditedUserAddressEpic = (
  action$: Observable<Action>,
  _: stateAction,
  { dispatch }: dispatchAction
) =>
  action$.pipe(
    filter(editPatientDetailsAddressRequest.match),
    mergeMap(async ({ payload: { customerId, addressId } }) => {
      try {
        const response = await getEditedUserAddress(customerId);
        const filteredAddress = response?.data?.userAddresses.find(
          (address: any) => address.id === addressId
        );
        return { payload: filteredAddress };
      } catch (e) {
        return { error: e };
      }
    }),
    map((action) => {
      return action?.payload
        ? editPatientDetailsAddressSuccess(action?.payload)
        : editPatientDetailsAddressFail();
    })
  );

//get user address province
const getUserAddressProvinceEpic = (
  action$: Observable<Action>,
  _: stateAction,
  { dispatch }: dispatchAction
) =>
  action$.pipe(
    filter(getUserAddressProvinceRequest.match),
    mergeMap(async (action) => {
      try {
        const response = await getUserAddressProvince();
        return { payload: response.data };
      } catch (e) {
        return { error: e };
      }
    }),
    map((action) =>
      action?.payload
        ? getUserAddressProvinceSuccess(action?.payload)
        : getUserAddressProvinceFail()
    )
  );

//get user address district
const getUserAddressDistrictEpic = (
  action$: Observable<Action>,
  _: stateAction,
  { dispatch }: dispatchAction
) =>
  action$.pipe(
    filter(getUserAddressDistrictRequest.match),
    mergeMap(async (action) => {
      try {
        const response = await getUserAddressDistrict(action?.payload);
        return { payload: response.data };
      } catch (e) {
        return { error: e };
      }
    }),
    map((action) =>
      action?.payload
        ? getUserAddressDistrictSuccess(action?.payload)
        : getUserAddressDistrictFail()
    )
  );

export const userAddressEpic = combineEpics(
  getUserAddressByIdEpic,
  createUserAddressByIdEpic,
  updateUserAddressByIdEpic,
  getEditedUserAddressEpic,
  getUserAddressDistrictEpic,
  getUserAddressProvinceEpic
);
