import { axiosInstance } from "../../../../AppUtils/Utils/axios";
import { filterApiData } from "../../../../AppUtils/Utils/appFunctions";
import {
  getApiProps,
  searchProps,
} from "../../../../AppUtils/Utils/globalTypes";

export const billingModURL = "api/v1/clinic-sale-app/clinic-sale";

//get billing data api
export const getBilling = (data: getApiProps) => {
  const { rowsPerPage, page } = data;
  return axiosInstance.get(
    `${billingModURL}?offset=${rowsPerPage * page}&limit=${rowsPerPage}${
      data?.filterData ? filterApiData(data?.filterData) : ""
    }`
  );
};
//handle search
export const searchBilling = ({ rowsPerPage, search }: searchProps) => {
  return `${billingModURL}?offset=0&limit=${rowsPerPage}&search=${search}`;
};
//create billing
export const createBilling = (body: string | FormData) =>
  axiosInstance.post(`${billingModURL}`, body);
//update Palika
export const updateBilling = (body: string | FormData, id: number) =>
  axiosInstance.patch(`${billingModURL}/${id}`, body);

//request external api
export const getBillingCustomer = (rowsPerPage: number) =>
  axiosInstance.get(`/${billingModURL}/customer?offset=0&limit=${rowsPerPage}`);

export const getBillingDiscountScheme = (rowsPerPage: number) =>
  axiosInstance.get(
    `/${billingModURL}/discount-scheme?offset=0&limit=${rowsPerPage}`
  );
export const getBillingReferral = (rowsPerPage: number) =>
  axiosInstance.get(`/${billingModURL}/referrer?offset=0&limit=${rowsPerPage}`);
export const getBillingTest = ({rowsPerPage,customerType}:any) =>{
  return axiosInstance.get(`/${billingModURL}/test?customer_type=${customerType?.id}&offset=0&limit=${rowsPerPage}`)};

export const getBillingCustomerType = (rowsPerPage: number) =>
  axiosInstance.get(
    `/${billingModURL}/customer-type?offset=0&limit=${rowsPerPage}`
  );

//payment mode
export const getBillingPaymentMode = () =>
  axiosInstance.get(
    `/api/v1/clinic-sale-app/clinic-sale/payment-mode?offset=0&limit=0`
  );
//additonal charge
export const getBillingAdditionalCharge = () =>
  axiosInstance.get(`/api/v1/core-app/additional-charge-type?offset=0&limit=0`);

//view details

export const getViewDetails = ({ id, rowsPerPage, page }: any) =>
  axiosInstance.get(
    `api/v1/clinic-sale-app/clinic-sale/sale-detail?sale_main=${id}&offset=${
      rowsPerPage * page
    }&limit=${rowsPerPage}`
  );

export const getPrintDetails = ({ id }: any) =>
  axiosInstance.get(
    `api/v1/clinic-sale-app/clinic-sale/sale-detail?sale_main=${id}&offset=0&limit=0`
  );
export const getPrintCustomerDetails = ({ id }: any) =>
  axiosInstance.get(`api/v1/clinic-sale-app/clinic-sale/${id}`);

//print count
export const printCount = (body: string) =>
  axiosInstance.post(
    `api/v1/clinic-sale-app/clinic-sale/update-invoice-print-count`,
    body
  );

//get print count
export const getBillingPrintCount = (id: number) =>
  axiosInstance.get(
    `api/v1/clinic-sale-app/clinic-sale/invoice-print-count?id=${id}`
  );
