import { createSlice, createAction } from "@reduxjs/toolkit";
import { itemUnit } from "./types";
const initialState: any = {
  itemUnits: [],
  loadingItemUnit: false,
  loadingCreateItemUnit: false,
  edit: false,
  itemUnit: null,
  count: 0,
  previous: "",
  next: "",
};

export const ItemUnit = createSlice({
  name: "itemUnitReducer",
  initialState,
  reducers: {
    loadingItemUnit: (state) => {
      state.loadingItemUnit = true;
    },
    getItemUnitRequest: (state, payload) => {
      state.loadingItemUnit = true;
    },
    getItemUnitSuccess: (
      state,
      { payload: { results, count, next, previous } }
    ) => {
      state.loadingItemUnit = false;
      state.itemUnits = results;
      state.count = count;
      state.next = next;
      state.previous = previous;
    },
    getItemUnitFail: (state) => {
      state.loadingItemUnit = false;
    },
    getItemUnitNextRequest: (state, payload) => {
      state.loadingItemUnit = true;
    },
    getItemUnitPreviousRequest: (state, payload) => {
      state.loadingItemUnit = true;
    },
    createItemUnitRequest: (state, payload) => {
      state.loadingCreateItemUnit = true;
      state.closeModal = true;
    },
    createItemUnitSuccess: (state) => {
      state.loadingCreateItemUnit = false;
    },
    createItemUnitFail: (state) => {
      state.loadingCreateItemUnit = false;
    },
    itemUnitEditSuccess: (state, { payload }) => {
      const selectedItemUnit = state.itemUnits.find(
        (selected: itemUnit) => selected.id === payload.id
      );
      state.edit = true;
      state.itemUnit = selectedItemUnit;
    },
    updateItemUnitRequest: (state, payload) => {
      state.loadingCreateItemUnit = true;
    },
    updateItemUnitSuccess: (state) => {
      state.loadingCreateItemUnit = false;
    },
    updateItemUnitFail: (state) => {
      state.loadingCreateItemUnit = false;
    },
    clearItemUnitData: (state) => {
      state.edit = false;
      state.itemUnit = null;
    },
  },
});
// Action creators are generated for each case reducer function
export const {
  loadingItemUnit,
  getItemUnitRequest,
  getItemUnitSuccess,
  getItemUnitFail,
  getItemUnitNextRequest,
  getItemUnitPreviousRequest,
  createItemUnitRequest,
  createItemUnitSuccess,
  createItemUnitFail,
  itemUnitEditSuccess,
  updateItemUnitRequest,
  updateItemUnitSuccess,
  updateItemUnitFail,
  clearItemUnitData,
} = ItemUnit.actions;
export default ItemUnit.reducer;
