import { createAction, createSlice } from "@reduxjs/toolkit";

const initialState: any = {
  serviceTypes: [],
  edit: false,
  serviceType: null,
  loadingServiceType: false,
  mainServiceType: [],
  loadingMainServiceType: false,
  loadingCreateServiceType: false,
  count: 0,
  next: "",
  previous: "",
};

export const updateServiceTypeRequest = createAction(
  "updateServiceTypeRequest",
  function prepare({ values, id, rowsPerPage, page }) {
    return {
      payload: {
        values,
        id,
        rowsPerPage,
        page,
      },
    };
  }
);

export const getServiceTypeRequest = createAction(
  "getServiceTypeRequest",
  function prepare({ rowsPerPage, page, filterData }) {
    return {
      payload: {
        rowsPerPage,
        page,
        filterData,
      },
    };
  }
);
// get next request
export const getServiceTypeNextRequest = createAction(
  "getServiceTypeNextRequest",
  function prepare(next) {
    return {
      payload: next,
    };
  }
);
//get previous request
export const getServiceTypePreviousRequest = createAction(
  "getServiceTypePreviousRequest",
  function prepare(previous) {
    return {
      payload: previous,
    };
  }
);
export const ServiceType = createSlice({
  name: "ServiceTypeReducer",
  initialState,
  reducers: {
    loadingServiceType: (state) => {
      state.loadingServiceType = true;
    },
    getServiceTypeSuccess: (
      state,
      { payload: { results, count, next, previous } }
    ) => {
      state.loadingServiceType = false;
      state.serviceTypes = results;
      state.count = count;
      state.next = next;
      state.previous = previous;
    },
    getServiceTypeFail: (state) => {
      state.loadingServiceType = false;
    },

    createServiceTypeRequest: (state, payload) => {
      state.loadingCreateServiceType = true;
      state.closeModal = true;
    },
    createServiceTypeSuccess: (state) => {
      state.loadingCreateServiceType = false;
    },
    createServiceTypeFail: (state) => {
      state.loadingCreateServiceType = false;
    },
    getMainServiceTypeRequest: (state) => {
      state.loadingMainServiceType = true;
    },
    getMainServiceTypeSuccess: (state, { payload: { results } }) => {
      state.loadingMainServiceType = false;
      state.mainServiceType = results;
    },
    getMainServiceTypeFail: (state) => {
      state.loadingMainServiceType = false;
    },
    getServiceTypeByIdRequest: (state, payload) => {
      state.edit = true;
    },
    ServiceTypeEditSuccess: (state, { payload }) => {
      state.serviceType = payload;
    },
    ServiceTypeEditFail: (state) => {
      state.edit = false;
    },
    updateServiceTypeSuccess: (state) => {
      state.loadingCreateServiceType = false;
    },
    updateServiceTypeFail: (state) => {
      state.loadingCreateServiceType = false;
    },

    clearServiceTypeData: (state) => {
      state.edit = false;
      state.serviceType = null;
    },
  },
});
// Action creators are generated for each case reducer function
export const {
  loadingServiceType,
  getServiceTypeSuccess,
  getServiceTypeFail,
  createServiceTypeRequest,
  createServiceTypeSuccess,
  createServiceTypeFail,
  ServiceTypeEditSuccess,
  updateServiceTypeSuccess,
  updateServiceTypeFail,
  clearServiceTypeData,
  getServiceTypeByIdRequest,
  ServiceTypeEditFail,
  getMainServiceTypeRequest,
  getMainServiceTypeSuccess,
  getMainServiceTypeFail,
} = ServiceType.actions;
export default ServiceType.reducer;
