import * as API from "./api";
import {
  createSampleCollectionFail,
  createSampleCollectionRequest,
  createSampleCollectionSuccess,
  getSampleCollectionCustomerTestFail,
  getSampleCollectionCustomerTestRequest,
  getSampleCollectionCustomerTestSuccess,
  getSampleCollectionFail,
  getSampleCollectionNextRequest,
  getSampleCollectionPreviousRequest,
  getSampleCollectionRequest,
  getSampleCollectionSuccess,
  getSampleTypeFail,
  getSampleTypeSuccess,
  requestSampleType,
} from "./SampleCollectionSlice";
import { getNext, getPrevious } from "../../../CommonAppRedux/api";
import {
  alertErrorAction,
  alertSuccessAction,
} from "../../../CommonAppRedux/CommonAppSlice";
import { filter, map, Observable } from "rxjs";
import { Action } from "@reduxjs/toolkit";
import {
  dispatchAction,
  stateAction,
} from "../../../../AppUtils/Utils/globalTypes";
import { mergeMap } from "rxjs";
import { combineEpics } from "redux-observable";
import messages from "../../../../AppUtils/Utils/validationConstants";

//get SampleCollection epic
const getSampleCollectionEpic = (
  action$: Observable<Action>,
  _: stateAction,
  { dispatch }: dispatchAction
) =>
  action$.pipe(
    filter(getSampleCollectionRequest.match),
    mergeMap(async (action) => {
      try {
        const response = await API.getSampleCollection(action.payload);
        return { payload: response.data };
      } catch (e) {
        return { error: e };
      }
    }),
    map((action) =>
      action?.payload
        ? getSampleCollectionSuccess(action?.payload)
        : getSampleCollectionFail()
    )
  );
//get next
const getSampleCollectionNext = (
  action$: Observable<Action>,
  _: stateAction,
  { dispatch }: dispatchAction
) =>
  action$.pipe(
    filter(getSampleCollectionNextRequest.match),
    mergeMap(async (action) => {
      try {
        const response = await getNext(action.payload);
        return { payload: response.data };
      } catch (e) {
        return { error: e };
      }
    }),
    map((action) =>
      action?.payload
        ? getSampleCollectionSuccess(action?.payload)
        : getSampleCollectionFail()
    )
  );
//get previous
const getSampleCollectionPrevious = (
  action$: Observable<Action>,
  _: stateAction,
  { dispatch }: dispatchAction
) =>
  action$.pipe(
    filter(getSampleCollectionPreviousRequest.match),
    mergeMap(async (action) => {
      try {
        const response = await getPrevious(action.payload);
        return { payload: response.data };
      } catch (e) {
        return { error: e };
      }
    }),
    map((action) =>
      action?.payload
        ? getSampleCollectionSuccess(action?.payload)
        : getSampleCollectionFail()
    )
  );
//get SampleCollection epic
const getSampleCollectionCustomerTestEpic = (
  action$: Observable<Action>,
  _: stateAction,
  { dispatch }: dispatchAction
) =>
  action$.pipe(
    filter(getSampleCollectionCustomerTestRequest.match),
    mergeMap(async (action) => {
      try {
        const response = await API.getSampleCollectionCustomerTest(
          action.payload.id
        );
        return { payload: response.data };
      } catch (e) {
        return { error: e };
      }
    }),
    map((action) =>
      action?.payload
        ? getSampleCollectionCustomerTestSuccess(action?.payload)
        : getSampleCollectionCustomerTestFail()
    )
  );
//get SampleCollection epic
const getSampleTypEpic = (
  action$: Observable<Action>,
  _: stateAction,
  { dispatch }: dispatchAction
) =>
  action$.pipe(
    filter(requestSampleType.match),
    mergeMap(async () => {
      try {
        const response = await API.getSampleType();
        return { payload: response.data };
      } catch (e) {
        return { error: e };
      }
    }),
    map((action) =>
      action?.payload
        ? getSampleTypeSuccess(action?.payload)
        : getSampleTypeFail()
    )
  );
//create sample collection  epic
const createSampleCollectionEpic = (
  action$: Observable<Action>,
  _: stateAction,
  { dispatch }: dispatchAction
) =>
  action$.pipe(
    filter(createSampleCollectionRequest.match),
    mergeMap(async ({ payload: { values, sampleCollection } }) => {
      try {
        const body = JSON.stringify(values);
        const response = await API.createSampleCollection(body);
        if (response) {
          dispatch(getSampleCollectionCustomerTestRequest(sampleCollection));
          dispatch(alertSuccessAction("Collected Successfully"));
        }
        return { payload: { response } };
      } catch (e) {
        dispatch(alertErrorAction("Failed To Collect"));
        return { error: e };
      }
    }),
    map((action) => {
      return action?.payload
        ? createSampleCollectionSuccess()
        : createSampleCollectionFail();
    })
  );
export const sampleCollectionEpics = combineEpics(
  getSampleCollectionEpic,
  getSampleCollectionNext,
  getSampleCollectionPrevious,
  getSampleCollectionCustomerTestEpic,
  createSampleCollectionEpic,
  getSampleTypEpic
);
