import { Action } from "@reduxjs/toolkit";
import { Observable } from "rxjs";
import { filter, mergeMap, map } from "rxjs/operators";
import {
  dispatchAction,
  stateAction,
} from "../../../../../../AppUtils/Utils/globalTypes";
import {
  clearAllDeviceRepairData,
  createDeviceRepairFail,
  createDeviceRepairRequest,
  createDeviceRepairSuccess,
  editDeviceRepairFail,
  editDeviceRepairRequest,
  editDeviceRepairSuccess,
  getDeviceRepairNextRequest,
  getDeviceRepairPreviousRequest,
  getDeviceRepairFail,
  getDeviceRepairItemFail,
  getDeviceRepairItemRequest,
  getDeviceRepairItemSuccess,
  getDeviceRepairRequest,
  getDeviceRepairSuccess,
  updateDeviceRepairFail,
  updateDeviceRepairRequest,
  updateDeviceRepairSuccess,
} from "./deviceRepairSlice";
import {
  createDeviceRepairById,
  getDeviceRepairById,
  getDeviceRepairItemList,
  getEditedDeviceRepair,
  updateDeviceRepairById,
} from "./api";
import { combineEpics } from "redux-observable";
import messages from "../../../../../../AppUtils/Utils/validationConstants";
import {
  alertErrorAction,
  alertSuccessAction,
  closeModal,
} from "../../../../../CommonAppRedux/CommonAppSlice";
import { getNext, getPrevious } from "../../../../../CommonAppRedux/api";

// get device repair by id
const getDeviceRepairByIdEpic = (
  action$: Observable<Action>,
  _: stateAction,
  { dispatch }: dispatchAction
) =>
  action$.pipe(
    filter(getDeviceRepairRequest.match),
    mergeMap(async (action) => {
      try {
        const response = await getDeviceRepairById(action?.payload);

        return { payload: response.data };
      } catch (e) {
        return { error: e };
      }
    }),
    map((action) => {
      return action?.payload
        ? getDeviceRepairSuccess(action?.payload)
        : getDeviceRepairFail();
    })
  );

const createDeviceRepairByIdEpic = (
  action$: Observable<Action>,
  _: stateAction,
  { dispatch }: dispatchAction
) =>
  action$.pipe(
    filter(createDeviceRepairRequest.match),
    mergeMap(async ({ payload: { values, setShowModal } }) => {
      const customerId = localStorage.getItem("customerId");
      try {
        const body = JSON.stringify(values);
        const response = await createDeviceRepairById(body);
        if (response) {
          dispatch(alertSuccessAction(messages.createMessage));
          dispatch(getDeviceRepairRequest(customerId));
          dispatch(closeModal());
          dispatch(clearAllDeviceRepairData());
          setShowModal(false);
        }
        return { payload: { response } };
      } catch (e) {
        dispatch(alertErrorAction(messages.createFailMessage));
        return { error: e };
      }
    }),
    map((action) => {
      return action?.payload
        ? createDeviceRepairSuccess()
        : createDeviceRepairFail();
    })
  );

const updateDeviceRepairByIdEpic = (
  action$: Observable<Action>,
  _: stateAction,
  { dispatch }: dispatchAction
) =>
  action$.pipe(
    filter(updateDeviceRepairRequest.match),
    mergeMap(async ({ payload: { values, id, setShowModal } }) => {
      // dispatch()
      try {
        const body = JSON.stringify(values);
        const response = await updateDeviceRepairById(body, id);
        const customerId = localStorage.getItem("customerId");

        if (response) {
          dispatch(getDeviceRepairRequest(customerId));
          dispatch(alertSuccessAction(messages.updateMessage));
          dispatch(closeModal());
          dispatch(clearAllDeviceRepairData());
          setShowModal(false);
        }
        return { payload: { response } };
      } catch (e) {
        dispatch(alertErrorAction(messages.updateFailMessage));
        return { error: e };
      }
    }),
    map((action) => {
      return action?.payload
        ? updateDeviceRepairSuccess()
        : updateDeviceRepairFail();
    })
  );

// get device repair item list
const getDeviceRepairItemEpic = (
  action$: Observable<Action>,
  _: stateAction,
  { dispatch }: dispatchAction
) =>
  action$.pipe(
    filter(getDeviceRepairItemRequest.match),
    mergeMap(async (action) => {
      try {
        const response = await getDeviceRepairItemList();
        return { payload: response.data };
      } catch (e) {
        return { error: e };
      }
    }),
    map((action) => {
      return action?.payload
        ? getDeviceRepairItemSuccess(action?.payload)
        : getDeviceRepairItemFail();
    })
  );

// edited device repair epic
const getEditedDeviceRepairEpic = (
  action$: Observable<Action>,
  _: stateAction,
  { dispatch }: dispatchAction
) =>
  action$.pipe(
    filter(editDeviceRepairRequest.match),
    mergeMap(async (action) => {
      try {
        const response = await getEditedDeviceRepair(action?.payload);
        return { payload: response.data };
      } catch (e) {
        return { error: e };
      }
    }),
    map((action) => {
      return action?.payload
        ? editDeviceRepairSuccess(action?.payload)
        : editDeviceRepairFail();
    })
  );

//get next
const getDeviceRepairNext = (
  action$: Observable<Action>,
  _: stateAction,
  { dispatch }: dispatchAction
) =>
  action$.pipe(
    filter(getDeviceRepairNextRequest.match),
    mergeMap(async (action) => {
      try {
        const response = await getNext(action.payload);
        return { payload: response.data };
      } catch (e) {
        return { error: e };
      }
    }),
    map((action) =>
      action?.payload
        ? getDeviceRepairSuccess(action?.payload)
        : getDeviceRepairFail()
    )
  );

//get previous
const getDeviceRepairPrevious = (
  action$: Observable<Action>,
  _: stateAction,
  { dispatch }: dispatchAction
) =>
  action$.pipe(
    filter(getDeviceRepairPreviousRequest.match),
    mergeMap(async (action) => {
      try {
        const response = await getPrevious(action.payload);
        return { payload: response.data };
      } catch (e) {
        return { error: e };
      }
    }),
    map((action) =>
      action?.payload
        ? getDeviceRepairSuccess(action?.payload)
        : getDeviceRepairFail()
    )
  );

export const deviceRepairEpic = combineEpics(
  getDeviceRepairByIdEpic,
  createDeviceRepairByIdEpic,
  updateDeviceRepairByIdEpic,
  getEditedDeviceRepairEpic,
  getDeviceRepairItemEpic,
  getDeviceRepairNext,
  getDeviceRepairPrevious
);
