import {
  createCustomerTypeFail,
  createCustomerTypeRequest,
  createCustomerTypeSuccess,
  getCustomerTypeFail,
  getCustomerTypeSuccess,
  loadingCustomerType,
  updateCustomerTypeFail,
  updateCustomerTypeSuccess,
  getCustomerTypeRequest,
  getCustomerTypeNextRequest,
  getCustomerTypePreviousRequest,
  updateCustomerTypeRequest,
  clearCustomerTypeData,
  getCustomerTypeByIdRequest,
  customerTypeEditSuccess,
  customerTypeEditFail,
  getDefaultCustomerTypeRequest,
  getDefaultCustomerTypeSuccess,
  getDefaultCustomerTypeFail,
} from "./customerTypeSlice";
import { mergeMap } from "rxjs";
import {
  createCustomerType,
  getCustomerType,
  updateCustomerType,
  getDefaultCustomerType,
  getCustomerTypeById,
} from "./api";
import { map, filter } from "rxjs/operators";
import { Observable } from "rxjs";
import { Action } from "@reduxjs/toolkit";
import { combineEpics } from "redux-observable";
import {
  alertErrorAction,
  alertSuccessAction,
  closeModal,
} from "../../../CommonAppRedux/CommonAppSlice";
import { getNext, getPrevious } from "../../../CommonAppRedux/api";
import {
  dispatchAction,
  stateAction,
} from "../../../../AppUtils/Utils/globalTypes";
import messages from "../../../../AppUtils/Utils/validationConstants";
export const controller = new AbortController();
// import { searchData } from "../../../CommonAppRedux/commonAppFunctions";
//get CustomerType epic
const getCustomerTypeEpic = (
  action$: Observable<Action>,
  _: stateAction,
  { dispatch }: dispatchAction
) =>
  action$.pipe(
    filter(getCustomerTypeRequest.match),
    mergeMap(async (action) => {
      dispatch(loadingCustomerType());
      try {
        const response = await getCustomerType(action.payload);
        return { payload: response.data };
      } catch (e) {
        return { error: e };
      }
    }),
    map((action) =>
      action?.payload
        ? getCustomerTypeSuccess(action?.payload)
        : getCustomerTypeFail()
    )
  );

//get customer type by id
const getCustomerTypeByIdEpic = (
  action$: Observable<Action>,
  _: stateAction,
  { dispatch }: dispatchAction
) =>
  action$.pipe(
    filter(getCustomerTypeByIdRequest.match),
    mergeMap(async (action) => {
      try {
        const response = await getCustomerTypeById(action.payload);
        return { payload: response.data };
      } catch (e) {
        return { error: e };
      }
    }),
    map((action) =>
      action?.payload
        ? customerTypeEditSuccess(action.payload)
        : customerTypeEditFail()
    )
  );

//get next
const getCustomerTypeNext = (
  action$: Observable<Action>,
  _: stateAction,
  { dispatch }: dispatchAction
) =>
  action$.pipe(
    filter(getCustomerTypeNextRequest.match),
    mergeMap(async (action) => {
      dispatch(loadingCustomerType());
      try {
        const response = await getNext(action.payload);
        return { payload: response.data };
      } catch (e) {
        return { error: e };
      }
    }),
    map((action) =>
      action?.payload
        ? getCustomerTypeSuccess(action?.payload)
        : getCustomerTypeFail()
    )
  );
//get previous
const getCustomerTypePrevious = (
  action$: Observable<Action>,
  _: stateAction,
  { dispatch }: dispatchAction
) =>
  action$.pipe(
    filter(getCustomerTypePreviousRequest.match),
    mergeMap(async (action) => {
      dispatch(loadingCustomerType());
      try {
        const response = await getPrevious(action.payload);
        return { payload: response.data };
      } catch (e) {
        return { error: e };
      }
    }),
    map((action) =>
      action?.payload
        ? getCustomerTypeSuccess(action?.payload)
        : getCustomerTypeFail()
    )
  );
//create CustomerType epic
const createCustomerTypeEpic = (
  action$: Observable<Action>,
  _: stateAction,
  { dispatch }: dispatchAction
) =>
  action$.pipe(
    filter(createCustomerTypeRequest.match),
    mergeMap(async ({ payload: { values, rowsPerPage, page } }) => {
      try {
        const body = new FormData();
        for (let [key, value] of Object.entries(values)) {
          // @ts-ignore
          body.append(`${key}`, value);
        }
        const response = await createCustomerType(body);
        if (response) {
          dispatch(getCustomerTypeRequest({ rowsPerPage, page }));
          dispatch(alertSuccessAction(messages.createMessage));
          dispatch(closeModal());
        }
        return { payload: { response } };
      } catch (e) {
        dispatch(alertErrorAction(messages.createFailMessage));
        return { error: e };
      }
    }),
    map((action) => {
      return action?.payload
        ? createCustomerTypeSuccess()
        : createCustomerTypeFail();
    })
  );
//update CustomerType epic
const updateCustomerTypeEpic = (
  action$: Observable<Action>,
  _: stateAction,
  { dispatch }: dispatchAction
) =>
  action$.pipe(
    filter(updateCustomerTypeRequest.match),
    mergeMap(async ({ payload: { values, id, rowsPerPage, page } }) => {
      try {
        const body = new FormData();
        for (let [key, value] of Object.entries(values)) {
          // @ts-ignore
          body.append(`${key}`, value);
        }
        const response = await updateCustomerType(body, id);
        if (response) {
          dispatch(getCustomerTypeRequest({ rowsPerPage, page }));
          dispatch(alertSuccessAction(messages.updateMessage));
          dispatch(closeModal());
          dispatch(clearCustomerTypeData());
        }
        return { payload: { response, rowsPerPage } };
      } catch (e) {
        dispatch(alertErrorAction(messages.updateFailMessage));
        return { error: e };
      }
    }),
    map((action) =>
      action?.payload ? updateCustomerTypeSuccess() : updateCustomerTypeFail()
    )
  );

const getDefaultCustomerTypeEpic = (
  action$: Observable<Action>,
  _: stateAction,
  { dispatch }: dispatchAction
) =>
  action$.pipe(
    filter(getDefaultCustomerTypeRequest.match),
    mergeMap(async (action) => {
      try {
        const response = await getDefaultCustomerType();
        return { payload: response.data };
      } catch (e) {
        return { error: e };
      }
    }),
    map((action) =>
      action?.payload
        ? getDefaultCustomerTypeSuccess(action?.payload)
        : getDefaultCustomerTypeFail()
    )
  );

export const customerTypeEpics = combineEpics(
  getCustomerTypeEpic,
  createCustomerTypeEpic,
  updateCustomerTypeEpic,
  getCustomerTypeNext,
  getCustomerTypePrevious,
  getDefaultCustomerTypeEpic,
  getCustomerTypeByIdEpic
);
