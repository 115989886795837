import {
  createBillingFail,
  createBillingRequest,
  createBillingSuccess,
  getBillingFail,
  getBillingSuccess,
  loadingBilling,
  updateBillingFail,
  updateBillingSuccess,
  getBillingRequest,
  getBillingNextRequest,
  getBillingPreviousRequest,
  updateBillingRequest,
  clearBillingData,
  getBillingCustomerRequest,
  getBillingCustomerSuccess,
  getBillingCustomerFail,
  getBillingReferralRequest,
  getBillingReferralSuccess,
  getBillingReferralFail,
  getBillingDiscountSchemeRequest,
  getBillingDiscountSchemeSuccess,
  getBillingTestRequest,
  getBillingTestSuccess,
  getBillingCustomerTypeSuccess,
  getBillingCustomerTypeFail,
  getBillingCustomerTypeRequest,
  //external reducer
  getBillingPaymentModeRequest,
  getBillingPaymentModeSuccess,
  getBillingPaymentModeFail,
  getViewBillingDetailsRequest,
  getViewBillingDetailsSuccess,
  getViewBillingDetailsFail,
  getViewBillingDetailsNextRequest,
  getViewBillingDetailsPreviousRequest,
  getPrintBillingDetailsRequest,
  getPrintBillingDetailsSuccess,
  getPrintBillingDetailsFail,
  billingPrintCountRequest,
  billingPrintCountSuccess,
  billingPrintCountFail,
  getBillingPrintCountRequest,
  getBillingPrintCountSuccess,
  getBillingPrintCountFail,
  getBillingDiscountSchemeFail,
  getPrintBillingCustomerDetailsSuccess,
  getPrintBillingCustomerDetailsFail,
  //additional charge
  getBillingAdditionalChargeRequest,
  getBillingAdditionalChargeSuccess,
  getBillingAdditionalChargeFail,
  //followup check
  getBillingCheckFollowUpRequest,
  getBillingCheckFollowUpSuccess,
  getBillingCheckFollowUpFail,
} from "./billingSlice";
import { mergeMap } from "rxjs";
import {
  createBilling,
  getBilling,
  updateBilling,
  // getBillingReferenceRange,
  // getAllTest,
} from "./api";
import { map, filter } from "rxjs/operators";
import { Observable } from "rxjs";
import { Action } from "@reduxjs/toolkit";
import { combineEpics } from "redux-observable";
import {
  alertErrorAction,
  alertSuccessAction,
  closeModal,
} from "../../../CommonAppRedux/CommonAppSlice";
import { getNext, getPrevious } from "../../../CommonAppRedux/api";
import * as API from "./api";
import {
  dispatchAction,
  stateAction,
} from "../../../../AppUtils/Utils/globalTypes";
import messages from "../../../../AppUtils/Utils/validationConstants";
import { clearPaymentDetails } from "../../../CommonAppPages/PaymentModeDetails/Redux/paymentDetailsSlice";
export const controller = new AbortController();
// import { searchData } from "../../../CommonAppRedux/commonAppFunctions";
//get Type epic
const getBillingEpic = (
  action$: Observable<Action>,
  _: stateAction,
  { dispatch }: dispatchAction
) =>
  action$.pipe(
    filter(getBillingRequest.match),
    mergeMap(async (action) => {
      dispatch(loadingBilling());
      try {
        const response = await getBilling(action.payload);
        return { payload: response.data };
      } catch (e) {
        return { error: e };
      }
    }),
    map((action) =>
      action?.payload ? getBillingSuccess(action?.payload) : getBillingFail()
    )
  );

const getBillingNext = (
  action$: Observable<Action>,
  _: stateAction,
  { dispatch }: dispatchAction
) =>
  action$.pipe(
    filter(getBillingNextRequest.match),
    mergeMap(async (action) => {
      dispatch(loadingBilling());
      try {
        const response = await getNext(action.payload);
        return { payload: response.data };
      } catch (e) {
        return { error: e };
      }
    }),
    map((action) =>
      action?.payload ? getBillingSuccess(action?.payload) : getBillingFail()
    )
  );
const getBillingPrevious = (
  action$: Observable<Action>,
  _: stateAction,
  { dispatch }: dispatchAction
) =>
  action$.pipe(
    filter(getBillingPreviousRequest.match),
    mergeMap(async (action) => {
      dispatch(loadingBilling());
      try {
        const response = await getPrevious(action.payload);
        return { payload: response.data };
      } catch (e) {
        return { error: e };
      }
    }),
    map((action) =>
      action?.payload ? getBillingSuccess(action?.payload) : getBillingFail()
    )
  );
const createBillingEpic = (
  action$: Observable<Action>,
  _: stateAction,
  { dispatch }: dispatchAction
) =>
  action$.pipe(
    filter(createBillingRequest.match),
    mergeMap(
      async ({ payload: { values, rowsPerPage, page, saveAndPrint } }) => {
        try {
          const body = JSON.stringify(values);
          const response = await createBilling(body);
          if (response) {
            dispatch(getBillingRequest({ rowsPerPage, page }));
            dispatch(alertSuccessAction(messages.createMessage));
            dispatch(clearBillingData());
            !saveAndPrint && dispatch(closeModal());
            dispatch(clearPaymentDetails());
          }
          return { payload: { response } };
        } catch (e) {
          dispatch(alertErrorAction(messages.createFailMessage));
          return { error: e };
        }
      }
    ),
    map((action) => {
      return action?.payload
        ? createBillingSuccess(action?.payload)
        : createBillingFail();
    })
  );
const updateBillingEpic = (
  action$: Observable<Action>,
  _: stateAction,
  { dispatch }: dispatchAction
) =>
  action$.pipe(
    filter(updateBillingRequest.match),
    mergeMap(async ({ payload: { values, id, rowsPerPage, page } }) => {
      try {
        const body = JSON.stringify(values);
        const response = await updateBilling(body, id);
        if (response) {
          dispatch(getBillingRequest({ rowsPerPage, page }));
          dispatch(alertSuccessAction(messages.updateMessage));
          dispatch(closeModal());
          dispatch(clearBillingData());
        }
        return { payload: { response, rowsPerPage } };
      } catch (e) {
        dispatch(alertErrorAction(messages.updateFailMessage));
        return { error: e };
      }
    }),
    map((action) =>
      action?.payload ? updateBillingSuccess() : updateBillingFail()
    )
  );

//external Epics
// const getAllTestEpic = (
//   action$: Observable<Action>,
//   _: stateAction,
//   { dispatch }: dispatchAction
// ) =>
//   action$.pipe(
//     filter(getAllTestFindingTestRequest.match),
//     mergeMap(async (action) => {
//       try {
//         const response = await getAllTest(action?.payload);
//         return { payload: response.data };
//       } catch (e) {
//         return { error: e };
//       }
//     }),
//     map((action) =>
//       action?.payload ? getBillingTestSuccess(action?.payload) : getBillingTestFail()
//     )
//   );

// const getBillingReferenceRangeEpic = (
//   action$: Observable<Action>,
//   _: stateAction,
//   { dispatch }: dispatchAction
// ) =>
//   action$.pipe(
//     filter(getBillingReferenceRangeRequest.match),
//     mergeMap(async (action) => {
//       try {
//         const response = await getBillingReferenceRange(action?.payload);
//         return { payload: response.data };
//       } catch (e) {
//         return { error: e };
//       }
//     }),
//     map((action) =>
//       action?.payload
//         ? getBillingReferenceRangeSuccess(action?.payload)
//         : getBillingReferenceRangeFail()
//     )
//   );
const getBillingCustomerEpic = (
  action$: Observable<Action>,
  _: stateAction,
  { dispatch }: dispatchAction
) =>
  action$.pipe(
    filter(getBillingCustomerRequest.match),
    mergeMap(async (action) => {
      try {
        const response = await API.getBillingCustomer(action.payload);
        return { payload: response.data };
      } catch (e) {
        return { error: e };
      }
    }),
    map((action) =>
      action?.payload
        ? getBillingCustomerSuccess(action?.payload)
        : getBillingCustomerFail()
    )
  );

const getBillingCustomerTypeEpic = (
  action$: Observable<Action>,
  _: stateAction,
  { dispatch }: dispatchAction
) =>
  action$.pipe(
    filter(getBillingCustomerTypeRequest.match),
    mergeMap(async (action) => {
      try {
        const response = await API.getBillingCustomerType(action.payload);
        return { payload: response.data };
      } catch (e) {
        return { error: e };
      }
    }),
    map((action) =>
      action?.payload
        ? getBillingCustomerTypeSuccess(action?.payload)
        : getBillingCustomerTypeFail()
    )
  );

const getBillingReferralEpic = (
  action$: Observable<Action>,
  _: stateAction,
  { dispatch }: dispatchAction
) =>
  action$.pipe(
    filter(getBillingReferralRequest.match),
    mergeMap(async (action) => {
      try {
        const response = await API.getBillingReferral(action.payload);
        return { payload: response.data };
      } catch (e) {
        return { error: e };
      }
    }),
    map((action) =>
      action?.payload
        ? getBillingReferralSuccess(action?.payload)
        : getBillingReferralFail()
    )
  );

const getBillingDiscountSchemeEpic = (
  action$: Observable<Action>,
  _: stateAction,
  { dispatch }: dispatchAction
) =>
  action$.pipe(
    filter(getBillingDiscountSchemeRequest.match),
    mergeMap(async (action) => {
      try {
        const response = await API.getBillingDiscountScheme(action.payload);
        return { payload: response.data };
      } catch (e) {
        return { error: e };
      }
    }),
    map((action) =>
      action?.payload
        ? getBillingDiscountSchemeSuccess(action?.payload)
        : getBillingDiscountSchemeFail()
    )
  );

const getBillingTestEpic = (
  action$: Observable<Action>,
  _: stateAction,
  { dispatch }: dispatchAction
) =>
  action$.pipe(
    filter(getBillingTestRequest.match),
    mergeMap(async (action) => {
      try {
        const response = await API.getBillingTest(action.payload);
        return { payload: response.data };
      } catch (e) {
        return { error: e };
      }
    }),
    map((action) =>
      action?.payload
        ? getBillingTestSuccess(action?.payload)
        : getBillingReferralFail()
    )
  );

//payment mode for billing
//get purchase payment mode
const getBillingPaymentModeEpic = (
  action$: Observable<Action>,
  _: stateAction,
  { dispatch }: dispatchAction
) =>
  action$.pipe(
    filter(getBillingPaymentModeRequest.match),
    mergeMap(async (action) => {
      try {
        const response = await API.getBillingPaymentMode();
        return { payload: response.data };
      } catch (e) {
        return { error: e };
      }
    }),
    map((action) =>
      action?.payload
        ? getBillingPaymentModeSuccess(action?.payload)
        : getBillingPaymentModeFail()
    )
  );

//additional mode for billing
//get purchase additional mode
const getBillingAdditionalChargeEpic = (
  action$: Observable<Action>,
  _: stateAction,
  { dispatch }: dispatchAction
) =>
  action$.pipe(
    filter(getBillingAdditionalChargeRequest.match),
    mergeMap(async (action) => {
      try {
        const response = await API.getBillingAdditionalCharge();
        return { payload: response.data };
      } catch (e) {
        return { error: e };
      }
    }),
    map((action) =>
      action?.payload
        ? getBillingAdditionalChargeSuccess(action?.payload)
        : getBillingAdditionalChargeFail()
    )
  );

// //   //view details
const getViewBillingDetails = (
  action$: Observable<Action>,
  _: stateAction,
  { dispatch }: dispatchAction
) =>
  action$.pipe(
    filter(getViewBillingDetailsRequest.match),
    mergeMap(async (action) => {
      try {
        const response = await API.getViewDetails(action.payload);
        return { payload: response.data };
      } catch (e) {
        return { error: e };
      }
    }),
    map((action) =>
      action?.payload
        ? getViewBillingDetailsSuccess(action?.payload)
        : getViewBillingDetailsFail()
    )
  );

// //get next
const getViewBillingDetailsNext = (
  action$: Observable<Action>,
  _: stateAction,
  { dispatch }: dispatchAction
) =>
  action$.pipe(
    filter(getViewBillingDetailsNextRequest.match),
    mergeMap(async (action) => {
      try {
        const response = await getNext(action.payload);
        return { payload: response.data };
      } catch (e) {
        return { error: e };
      }
    }),
    map((action) =>
      action?.payload
        ? getViewBillingDetailsSuccess(action?.payload)
        : getViewBillingDetailsFail()
    )
  );
//get previous
const getViewBillingDetailsPrevious = (
  action$: Observable<Action>,
  _: stateAction,
  { dispatch }: dispatchAction
) =>
  action$.pipe(
    filter(getViewBillingDetailsPreviousRequest.match),
    mergeMap(async (action) => {
      try {
        const response = await getPrevious(action.payload);
        return { payload: response.data };
      } catch (e) {
        return { error: e };
      }
    }),
    map((action) =>
      action?.payload
        ? getViewBillingDetailsSuccess(action?.payload)
        : getViewBillingDetailsFail()
    )
  );

const getPrintBillingDetails = (
  action$: Observable<Action>,
  _: stateAction,
  { dispatch }: dispatchAction
) =>
  action$.pipe(
    filter(getPrintBillingDetailsRequest.match),
    mergeMap(async (action) => {
      try {
        const response = await API.getPrintDetails(action.payload);
        return { payload: response.data };
      } catch (e) {
        return { error: e };
      }
    }),
    map((action) =>
      action?.payload
        ? getPrintBillingDetailsSuccess(action?.payload)
        : getPrintBillingDetailsFail()
    )
  );

const getPrintBillingCustomerDetails = (
  action$: Observable<Action>,
  _: stateAction,
  { dispatch }: dispatchAction
) =>
  action$.pipe(
    filter(getPrintBillingDetailsRequest.match),
    mergeMap(async (action) => {
      try {
        const response = await API.getPrintCustomerDetails(action.payload);
        return { payload: response.data };
      } catch (e) {
        return { error: e };
      }
    }),
    map((action) =>
      action?.payload
        ? getPrintBillingCustomerDetailsSuccess(action?.payload)
        : getPrintBillingCustomerDetailsFail()
    )
  );
const getViewBillingCustomerDetails = (
  action$: Observable<Action>,
  _: stateAction,
  { dispatch }: dispatchAction
) =>
  action$.pipe(
    filter(getViewBillingDetailsRequest.match),
    mergeMap(async (action) => {
      try {
        const response = await API.getPrintCustomerDetails(action.payload);
        return { payload: response.data };
      } catch (e) {
        return { error: e };
      }
    }),
    map((action) =>
      action?.payload
        ? getPrintBillingCustomerDetailsSuccess(action?.payload)
        : getPrintBillingCustomerDetailsFail()
    )
  );
const billingInvoicePrintCountEpic = (
  action$: Observable<Action>,
  _: stateAction,
  { dispatch }: dispatchAction
) =>
  action$.pipe(
    filter(billingPrintCountRequest.match),
    mergeMap(async (action) => {
      try {
        const body = JSON.stringify(action.payload);
        const response = await API.printCount(body);
        return { payload: response.data };
      } catch (e) {
        return { error: e };
      }
    }),
    map((action) =>
      action?.payload
        ? billingPrintCountSuccess(action?.payload)
        : billingPrintCountFail()
    )
  );

const getBillingPrintCountEpic = (
  action$: Observable<Action>,
  _: stateAction,
  { dispatch }: dispatchAction
) =>
  action$.pipe(
    filter(getBillingPrintCountRequest.match),
    mergeMap(async (action) => {
      try {
        const response = await API.getBillingPrintCount(action.payload);
        return { payload: response.data };
      } catch (e) {
        return { error: e };
      }
    }),
    map((action) =>
      action?.payload
        ? getBillingPrintCountSuccess(action?.payload)
        : getBillingPrintCountFail()
    )
  );

const getBillingCheckFollowUpEpic = (
  action$: Observable<Action>,
  _: stateAction,
  { dispatch }: dispatchAction
) =>
  action$.pipe(
    filter(getBillingCheckFollowUpRequest.match),
    mergeMap(async (action) => {
      try {
        const response = await API.getBillingCustomer(action.payload);

        return { payload: response.data };
      } catch (e) {
        return { error: e };
      }
    }),
    map((action) =>
      action?.payload
        ? getBillingCheckFollowUpSuccess(action?.payload)
        : getBillingCheckFollowUpFail(action?.payload)
    )
  );

export const billingEpics = combineEpics(
  getBillingEpic,
  createBillingEpic,
  updateBillingEpic,
  getBillingNext,
  getBillingPrevious,
  getBillingCustomerEpic,
  getBillingReferralEpic,
  getBillingDiscountSchemeEpic,
  getBillingTestEpic,
  getBillingCustomerTypeEpic,
  //external Epics
  getBillingPaymentModeEpic,
  getViewBillingDetailsNext,
  getViewBillingDetailsPrevious,
  getPrintBillingDetails,
  getBillingPrintCountEpic,
  billingInvoicePrintCountEpic,
  getViewBillingDetails,
  getPrintBillingCustomerDetails,
  getViewBillingCustomerDetails,
  getBillingAdditionalChargeEpic,
  getBillingCheckFollowUpEpic
);
