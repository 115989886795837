import { createSlice } from "@reduxjs/toolkit";
const initialState: any = {
  testCategories: [],
  loadingTestCategory: false,
  loadingCreateTestCategory: false,
  edit: false,
  testCategory: null,
  count: 0,
  previous: "",
  next: "",
};

export const TestCategory = createSlice({
  name: "TestCategoryReducer",
  initialState,
  reducers: {
    loadingTestCategory: (state) => {
      state.loadingTestCategory = true;
    },
    getTestCategoryRequest: (state, payload) => {
      state.loadingTestCategory = true;
    },
    getTestCategorySuccess: (
      state,
      { payload: { results, count, next, previous } }
    ) => {
      state.loadingTestCategory = false;
      state.testCategories = results;
      state.count = count;
      state.next = next;
      state.previous = previous;
    },
    getTestCategoryFail: (state) => {
      state.loadingTestCategory = false;
    },
    getTestCategoryNextRequest: (state, payload) => {
      state.loadingTestCategory = true;
    },
    getTestCategoryPreviousRequest: (state, payload) => {
      state.loadingTestCategory = true;
    },
    createTestCategoryRequest: (state, payload) => {
      state.loadingCreateTestCategory = true;
      state.closeModal = true;
    },
    createTestCategorySuccess: (state) => {
      state.loadingCreateTestCategory = false;
    },
    createTestCategoryFail: (state) => {
      state.loadingCreateTestCategory = false;
    },
    getTestCategoryByIdRequest: (state, payload) => {
      state.edit = true;
    },
    testCategoryEditSuccess: (state, { payload }) => {
      state.testCategory = payload;
    },
    testCategoryEditFail: (state) => {
      state.edit = false;
    },
    updateTestCategoryRequest: (state, payload) => {
      state.loadingCreateTestCategory = true;
    },
    updateTestCategorySuccess: (state) => {
      state.loadingCreateTestCategory = false;
    },
    updateTestCategoryFail: (state) => {
      state.loadingCreateTestCategory = false;
    },
    clearTestCategoryData: (state) => {
      state.edit = false;
      state.testCategory = null;
    },
  },
});
// Action creators are generated for each case reducer function
export const {
  loadingTestCategory,
  getTestCategoryRequest,
  getTestCategorySuccess,
  getTestCategoryFail,
  getTestCategoryNextRequest,
  getTestCategoryPreviousRequest,
  createTestCategoryRequest,
  createTestCategorySuccess,
  createTestCategoryFail,
  testCategoryEditSuccess,
  updateTestCategoryRequest,
  updateTestCategorySuccess,
  updateTestCategoryFail,
  clearTestCategoryData,
  getTestCategoryByIdRequest,
  testCategoryEditFail,
} = TestCategory.actions;
export default TestCategory.reducer;
