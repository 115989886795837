import { filterApiData } from "../../../../../../AppUtils/Utils/appFunctions";
import { axiosInstance } from "../../../../../../AppUtils/Utils/axios";
import {
  getApiProps,
  searchProps,
} from "../../../../../../AppUtils/Utils/globalTypes";

export const moldOrderModURL = "/api/v1/appointment-app/mold-order";
//get MoldOrder data api
export const getMoldOrder = (data: any) => {
  const { id, rowsPerPage, page } = data;
  return axiosInstance.get(
    `${moldOrderModURL}?customer=${id}&offset=${
      rowsPerPage * page
    }&limit=${rowsPerPage}${
      data?.filterData ? filterApiData(data?.filterData) : ""
    }`
  );
};
export const getMoldOrderCustomerType = ({ rowsPerPage }: any) => {
  return axiosInstance.get(
    `${moldOrderModURL}/customer-type?offset=0&limit=${rowsPerPage}`
  );
};
export const getMoldOrderDepartment = (rowsPerPage: number) => {
  return axiosInstance.get(
    `${moldOrderModURL}/department?offset=0&limit=${rowsPerPage}`
  );
};
export const getMoldOrderCategory = ({ rowsPerPage }: any) => {
  return axiosInstance.get(
    `${moldOrderModURL}/clinicorder-category?offset=0&limit=${rowsPerPage}`
  );
};
//handle search
export const searchMoldOrder = ({ rowsPerPage, search }: searchProps) => {
  return `${moldOrderModURL}?offset=0&limit=${rowsPerPage}&search=${search}`;
};
//create MoldOrder
export const createMoldOrder = (body: string | FormData) =>
  axiosInstance.post(`${moldOrderModURL}`, body);
//update MoldOrder
export const updateMoldOrder = (body: string | FormData, id: number) =>
  axiosInstance.patch(`${moldOrderModURL}/${id}`, body);
//
export const getMoldOrderById = (id: number) => {
  return axiosInstance.get(`${moldOrderModURL}/${id}`);
};

//
export const getMoldOrderItem = (rowsPerPage: number) =>
  axiosInstance.get(`${moldOrderModURL}/item?offset=0&limit=${rowsPerPage}`);
// export const getMoldOrderCustomer = (rowsPerPage:number) =>
// axiosInstance.get(`${moldOrderModURL}/customer?offset=0&limit=${rowsPerPage}`);

export const getMoldType = (rowsPerPage: number) => {
  return axiosInstance.get(
    `${moldOrderModURL}/mold-type?offset=0&limit=${rowsPerPage}`
  );
};
