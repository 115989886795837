import { useCallback, useEffect, useMemo } from "react";
import {
  useAppDispatch,
  useAppSelector,
} from "../../../../AppUtils/Utils/appHooks";
import { itemListDetailSelector } from "../Redux/Selector";
import { filterData } from "../../../../Component/FilterData/types";
import CommonTable from "../../CommonTable/CommonTables";
import SearchInput from "../../SearchComponent/SearchInput";
import { useImmer } from "use-immer";
import {
  getItemListDetailNextRequest,
  getItemListDetailPreviousRequest,
  getItemListDetailRequest,
  getItemListDetailSuccess,
} from "../Redux/ItemListDetailSlice";
import { commonAppSelector } from "../../../CommonAppRedux/selector";
import { useSearch } from "../../../../AppUtils/CustomHooks/useSearch";
import { searchItemListDetail } from "../Redux/api";
import { InputChangeEvent } from "../../../../AppUtils/Utils/globalTypes";
const ItemListDetail = (showModal: any) => {
  const { itemListDetails, count, next, previous } = useAppSelector(
    itemListDetailSelector
  );

  const { detailsRowsPerPage, detailsPage, search } =
    useAppSelector(commonAppSelector);
  const dispatch = useAppDispatch();
  const columns = [
    { id: "itemName", label: "Name", filterField: "name" },
    { id: "batchNo", label: "Batch No" },
    { id: "ccAmount", label: "CC Amount" },
    { id: "ccRate", label: "CC Rate" },
    { id: "discountRate", label: "discountRate" },
    { id: "expiryDateAd", label: "Expire date(A.D)" },
    { id: "packQty", label: "Pack Qty" },
    { id: "purchaseCost", label: "Purchase Cost" },
    { id: "qty", label: "Purchase Qty" },
    { id: "saleCost", label: "Sale Cost" },
    { id: "saleQty", label: "Sale Qty" },
  ];
  //handle filter data
  const handleFilterData = useCallback(
    (filterData: filterData) => {},
    [dispatch]
  );
  const [searchData, setSearchData] = useImmer("");
  const [searchDispatch, setSearchDispatch] = useImmer("");
  useSearch(searchDispatch, getItemListDetailSuccess);
  const updatedItemListDetails = itemListDetails.map((row: any) => {
    const { expiry_date_ad } = row;
    const item_expiry_date_ad = new Date(row?.expiry_date_ad);
    const nearExpDate = new Date();
    // nearExpDate.setDate(
    //   nearExpDate.getDate() +
    //     organizationRules[0].item_expiry_date_sale_threshold
    // );
    const expired = item_expiry_date_ad < new Date();
    const nearExpDateVar = !expired && item_expiry_date_ad <= nearExpDate;
    return {
      ...row,
      expiry_date_ad: (
        <p
          className={
            expired
              ? "color-red"
              : nearExpDateVar
              ? "color-blue"
              : "color-green"
          }
        >
          {/* {`${expiry_date_ad} ${
            expired
              ? "(Expired)"
              : nearExpDateVar
              ? `(Will expire with in ${organizationRules[0].item_expiry_date_sale_threshold} days)`
              : ""
          }`} */}
        </p>
      ),
    };
  });
  const handleClick = useCallback(
    (type: string) => {
      if (type === "previous") {
        dispatch(getItemListDetailPreviousRequest(previous ? previous : ""));
      } else if (type === "next") {
        dispatch(getItemListDetailNextRequest(next ? next : ""));
      }
    },
    [previous, next, dispatch]
  );
  //table props
  const tableProps = useMemo(() => {
    return {
      count,
      columns,
      data: updatedItemListDetails,
      handleFilterData,
      title: "ItemList Detail",
      hideFilterField: true,
      showDeleteButton: true,
      tableContainerForInsideDetail: true,
      hideAction: true,
      handleClick,
    };
  }, [updatedItemListDetails]);
  useEffect(() => {
    searchData === ""
      ? dispatch(
          getItemListDetailRequest({
            rowsPerPage: detailsRowsPerPage,
            page: detailsPage,
          })
        )
      : // get search data if Title search
        setSearchDispatch(
          searchItemListDetail({
            rowsPerPage: detailsRowsPerPage,
            search: searchData,
          })
        );
  }, [searchData, detailsRowsPerPage, dispatch]);
  const searchInputProps = useMemo(() => {
    return {
      placeholder: "Search Item List Detail Here...",
      setSearchData,
    };
  }, [setSearchData]);

  return (
    <>
      {showModal && (
        <>
          <SearchInput {...searchInputProps} />
          <CommonTable {...tableProps} />
        </>
      )}
    </>
  );
};
export default ItemListDetail;
