import { createSlice, createAction } from "@reduxjs/toolkit";
import { UnitInitialState } from "./types";

const initialState: UnitInitialState = {
  units: [],
  edit: false,
  unit: null,
  loadingUnit: false,
  loadingCreateUnit: false,
  count: 0,
  next: "",
  previous: "",
};

export const loadingUnitRequest = createAction(
  "loadingUnitRequest",
  function prepare(id) {
    return {
      payload: id,
    };
  }
);

export const Unit = createSlice({
  name: "unitReducer",
  initialState,
  reducers: {
    getUnitRequest: (state, payload) => {
      state.loadingUnit = true;
    },
    getUnitSuccess: (
      state,
      { payload: { results, count, next, previous } }
    ) => {
      state.loadingUnit = false;
      state.units = results;
      state.count = count;
      state.next = next;
      state.previous = previous;
    },
    getUnitFail: (state) => {
      state.loadingUnit = false;
    },
    getUnitNextRequest: (state, payload) => {
      state.loadingUnit = true;
    },
    getUnitPreviousRequest: (state, payload) => {
      state.loadingUnit = true;
    },
    createUnitRequest: (state, payload) => {
      state.loadingCreateUnit = true;
    },
    updateUnitRequest: (state, payload) => {
      state.loadingCreateUnit = true;
    },
    createUnitSuccess: (state) => {
      state.loadingCreateUnit = false;
    },
    createUnitFail: (state) => {
      state.loadingCreateUnit = false;
    },
    getUnitByIdRequest: (state, payload) => {
      state.edit = true;
    },
    unitEditSuccess: (state, { payload }) => {
      state.unit = payload;
    },
    unitEditFail: (state) => {
      state.edit = false;
    },
    updateUnitSuccess: (state) => {
      state.loadingCreateUnit = false;
    },
    updateUnitFail: (state) => {
      state.loadingCreateUnit = false;
    },
    clearUnitData: (state) => {
      state.edit = false;
      state.unit = null;
    },
  },
});

//action creaters generated for each case reducer
export const {
  getUnitRequest,
  getUnitSuccess,
  getUnitFail,
  getUnitPreviousRequest,
  getUnitNextRequest,
  createUnitRequest,
  createUnitSuccess,
  createUnitFail,
  unitEditSuccess,
  updateUnitSuccess,
  updateUnitFail,
  clearUnitData,
  updateUnitRequest,
  getUnitByIdRequest,
  unitEditFail,
} = Unit.actions;

export default Unit.reducer;
