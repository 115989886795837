import { createSlice, createAction } from "@reduxjs/toolkit";
const initialState: any = {
  tests: [],
  testDetails: [],
  loadingTest: false,
  loadingTestDepartment: false,
  loadingTestCategory: false,
  loadingTestCustomerType: false,
  loadingCreateTest: false,
  edit: false,
  testDetailEdit: false,
  test: null,
  testDetail: null,
  customerTypes: [],
  departments: [],
  testCategories: [],
  count: 0,
  previous: "",
  next: "",
  loadingTestDetailsAdded:false,
};

export const Test = createSlice({
  name: "Test Reducer",
  initialState,
  reducers: {
    loadingTest: (state) => {
      state.loadingTest = true;
    },
    getTestRequest: (state, payload) => {
      state.loadingTest = true;
    },
    getTestSuccess: (
      state,
      { payload: { results, count, next, previous } }
    ) => {
      state.loadingTest = false;
      state.tests = results;
      state.count = count;
      state.next = next;
      state.previous = previous;
    },
    getTestFail: (state) => {
      state.loadingTest = false;
    },
    getTestNextRequest: (state, payload) => {
      state.loadingTest = true;
    },
    getTestPreviousRequest: (state, payload) => {
      state.loadingTest = true;
    },
    getTestCustomerTypeRequest: (state, payload) => {
      state.loadingTestCustomerType = true;
    },
    getTestCustomerTypeSuccess: (state, { payload: { results } }) => {
      state.loadingTestCustomerType = false;
      state.customerTypes = results;
    },
    getTestCustomerTypeFail: (state) => {
      state.loadingTestCustomerType = false;
    },
    getTestDepartmentRequest: (state, payload) => {
      state.loadingTestDepartment = true;
    },
    getTestDepartmentSuccess: (state, { payload: { results } }) => {
      state.loadingTestDepartment = false;
      state.departments = results;
    },
    getTestDepartmentFail: (state) => {
      state.loadingTestDepartment = false;
    },
    getTestCategoryRequest: (state, payload) => {
      state.loadingTestCategory = true;
    },
    getTestCategorySuccess: (state, { payload: { results } }) => {
      state.loadingTestCategory = false;
      state.testCategories = results;
    },
    getTestCategoryFail: (state) => {
      state.loadingTestCategory = false;
    },
    createTestRequest: (state, payload) => {
      state.loadingCreateTest = true;
      state.closeModal = true;
    },
    createTestSuccess: (state) => {
      state.loadingCreateTest = false;
    },
    createTestFail: (state) => {
      state.loadingCreateTest = false;
    },
    getTestByIdRequest: (state, payload) => {
      state.edit = true;
    },
    testEditSuccess: (state, { payload }) => {
      // const editableRow = state.tests?.find(
      //   (test: any) => test?.id === payload?.id
      // );
      state.edit = true;
      state.test = payload;
      state.testDetails = payload?.testPrices;
    },
    testEditFail: (state) => {
      state.edit = false;
      state.test = null;
      state.testDetails = null;
    },
    testDetailEditSuccess: (state, { payload }) => {
      const updatedTestDetail = state.testDetails.find(
        (test: any) => test?.id === payload?.id
      );
      const updatedTestDetails = state.testDetails.filter(
        (test: any) => test.id !== payload.id
      );
      state.testDetail = updatedTestDetail;
      state.testDetails = updatedTestDetails;
      state.testDetailEdit = true;
      // state.loadingTest = false;
    },
    testDetailUpdateSuccess: (state, { payload }) => {
      state.testDetails = [...state.testDetails, payload];
      state.testDetailEdit = false;
      state.edit = true;
      state.loadingTestDetailsAdded = false;
    },
    testDetailAddedSuccess: (state, { payload }) => {
      state.testDetails = [...state.testDetails, payload];
      state.testDetailEdit = false;
      state.loadingTestDetailsAdded = false;
    },
    testDetailDeleteSuccess: (state, { payload }) => {
      const updatedTestDetails = state.testDetails.filter(
        (test: any) => test.unique_id !== payload.unique_id
      );
      state.testDetails = updatedTestDetails;
      state.testDetailEdit = false;
      state.loadingTestDetailsAdded = false;
    },
    updateTestRequest: (state, payload) => {
      state.loadingCreateTest = true;
    },
    updateTestSuccess: (state) => {
      state.loadingCreateTest = false;
    },
    updateTestFail: (state) => {
      state.loadingCreateTest = false;
    },
    clearTestData: (state) => {
      state.edit = false;
      state.test = null;
      state.testDetail = null;
      state.testDetails = [];
      state.loadingTest = false;
      state.testDetailEdit = false;
    },
  },
});
// Action creators are generated for each case reducer function
export const {
  loadingTest,
  getTestRequest,
  getTestSuccess,
  getTestFail,
  getTestNextRequest,
  getTestPreviousRequest,
  getTestCustomerTypeRequest,
  getTestCustomerTypeSuccess,
  getTestCustomerTypeFail,
  getTestDepartmentRequest,
  getTestDepartmentSuccess,
  getTestDepartmentFail,
  getTestCategoryRequest,
  getTestCategorySuccess,
  getTestCategoryFail,
  createTestRequest,
  createTestSuccess,
  createTestFail,
  testEditSuccess,
  testEditFail,
  testDetailEditSuccess,
  testDetailUpdateSuccess,
  testDetailAddedSuccess,
  testDetailDeleteSuccess,
  updateTestRequest,
  updateTestSuccess,
  updateTestFail,
  clearTestData,
  getTestByIdRequest,
} = Test.actions;
export default Test.reducer;
