import { createSlice, createAction } from "@reduxjs/toolkit";
const initialState: any = {
  medicalReferrals: [],
  loadingMedicalReferral: false,
  loadingCreateMedicalReferral: false,
  loadingDischargeNote: false,
  loadingCreateDischargeNote: false,
  edit: false,
  medicalReferral: null,
  loadingSite: false,
  sites: [],
  loadingClinic: false,
  clinics: [],
  loadingDoctor: false,
  doctors: [],
  loadingTitle: false,
  titles: [],
  loadingAppointmentType: false,
  appointmentTypes: [],
  loadingReferralType: false,
  referralTypes: [],
  selectedCustomer: null,
  filterMedicalReferral: null,
  loadingFilterMedicalReferral: false,
};

export const MedicalReferral = createSlice({
  name: "Medical Referral Reducer",
  initialState,
  reducers: {
    getMedicalReferralRequest: (state) => {
      state.loadingMedicalReferral = true;
    },
    getMedicalReferralSuccess: (state, { payload: { results } }) => {
      state.loadingMedicalReferral = false;
      state.medicalReferrals = results;
    },
    getMedicalReferralFail: (state) => {
      state.loadingMedicalReferral = false;
    },
    getFilterMedicalReferralRequest: (state, payload) => {
      state.loadingMedicalReferral = true;
    },
    getFilterMedicalReferralSuccess: (state, { payload: { results } }) => {
      state.loadingMedicalReferral = false;
      state.filterMedicalReferral = results;
      state.loadingCreateMedicalReferral = false;
    },
    getFilterMedicalReferralFail: (state) => {
      state.loadingMedicalReferral = false;
    },
    loadingMedicalReferralClinicReq: (state) => {
      state.loadingClinic = true;
    },
    getMedicalReferralClinicRequest: (state) => {
      state.loadingClinic = true;
    },
    getMedicalReferralClinicSuccess: (state, { payload: { results } }) => {
      state.loadingClinic = false;
      state.clinics = results;
      state.filterClinics = results;
    },
    getMedicalReferralClinicFail: (state) => {
      state.loadingClinic = false;
    },
    loadingMedicalReferralSiteReq: (state) => {
      state.loadingSite = true;
    },
    getMedicalReferralSiteRequest: (state) => {
      state.loadingSite = true;
    },
    getMedicalReferralSiteSuccess: (state, { payload: { results } }) => {
      state.loadingSite = false;
      state.sites = results;
    },
    getMedicalReferralSiteFail: (state) => {
      state.loadingSite = false;
    },
    loadingMedicalReferralDoctorReq: (state) => {
      state.loadingDoctor = true;
    },
    getMedicalReferralDoctorRequest: (state, payload) => {
      state.loadingDoctor = true;
    },
    getMedicalReferralDoctorSuccess: (state, { payload: { results } }) => {
      state.loadingDoctor = false;
      state.doctors = results;
    },
    getMedicalReferralDoctorFail: (state) => {
      state.loadingDoctor = false;
    },
    loadingMedicalReferralTitleReq: (state) => {
      state.loadingTitle = true;
    },
    getMedicalReferralTitleRequest: (state) => {
      state.loadingTitle = true;
    },
    getMedicalReferralTitleSuccess: (state, { payload: { results } }) => {
      state.loadingTitle = false;
      state.titles = results;
    },
    getMedicalReferralTitleFail: (state) => {
      state.loadingTitle = false;
    },
    loadingMedicalReferralAppointmentTypeReq: (state) => {
      state.loadingAppointmentType = true;
    },
    getMedicalReferralAppointmentTypeRequest: (state) => {
      state.loadingAppointmentType = true;
    },
    getMedicalReferralAppointmentTypeSuccess: (
      state,
      { payload: { results } }
    ) => {
      state.loadingAppointmentType = false;
      state.appointmentTypes = results;
    },
    getMedicalReferralAppointmentTypeFail: (state) => {
      state.loadingAppointmentType = false;
    },
    loadingMedicalReferralReferralTypeReq: (state) => {
      state.loadingReferralType = true;
    },
    getMedicalReferralReferralTypeRequest: (state) => {
      state.loadingReferralType = true;
    },
    getMedicalReferralReferralTypeSuccess: (
      state,
      { payload: { results } }
    ) => {
      state.loadingReferralType = false;
      state.referralTypes = results;
    },
    getMedicalReferralReferralTypeFail: (state) => {
      state.loadingReferralType = false;
    },
    getDoctorFromClinicRequest: (state, payload) => {
      state.loadingDoctor = true;
    },
    getDoctorFromClinicSuccess: (state, { payload: { results } }) => {
      state.loadingDoctor = false;
      state.doctors = results;
    },
    getDoctorFromClinicFail: (state) => {
      state.loadingDoctor = false;
    },
    getClinicFromSiteRequest: (state, payload) => {
      state.loadingClinic = true;
    },
    getClinicFromSiteSuccess: (state, { payload: { results } }) => {
      state.loadingClinic = false;
      state.clinics = results;
    },
    getClinicFromSiteFail: (state) => {
      state.loadingClinic = false;
    },
    setCustomerForMedicalReferral: (state, { payload }) => {
      state.selectedCustomer = payload;
    },
    createMedicalReferralRequest: (state, payload) => {
      state.loadingCreateMedicalReferral = true;
    },
    createMedicalReferralSuccess: (state) => {
      state.loadingCreateMedicalReferral = false;
    },
    createMedicalReferralFail: (state) => {
      state.loadingCreateMedicalReferral = false;
    },
    updateMedicalReferralRequest: (state, payload) => {
      state.loadingCreateMedicalReferral = true;
    },
    updateMedicalReferralSuccess: (state) => {
      state.loadingCreateMedicalReferral = false;
    },
    updateMedicalReferralFail: (state) => {
      state.loadingCreateMedicalReferral = false;
    },
    //
    getMedicalReferralById: (state, payload) => {
      state.edit = true;
    },
    medicalReferralEditSuccess: (state, { payload }) => {
      state.medicalReferral = payload;
      state.loadingCreateMedicalReferral = false;
    },
    medicalReferralEditFail: (state) => {
      state.edit = false;
    },
    clearMedicalReferralData: (state) => {
      state.medicalReferral = null;
      state.filterMedicalReferral = null;
      state.edit = false;
    },
    //
    createDischargeNoteRequest: (state, payload) => {
      state.loadingCreateDischargeNote = true;
    },
    createDischargeNoteSuccess: (state) => {
      state.loadingCreateDischargeNote = false;
    },
    createDischargeNoteFail: (state) => {
      state.loadingCreateDischargeNote = false;
    },
    clearMedicalReferralCustomer: (state) => {
      state.selectedCustomer = null;
    },
  },
});
// Action creators are generated for each case reducer function
export const {
  getMedicalReferralRequest,
  getMedicalReferralSuccess,
  getMedicalReferralFail,
  getFilterMedicalReferralRequest,
  getFilterMedicalReferralSuccess,
  getFilterMedicalReferralFail,
  loadingMedicalReferralClinicReq,
  getMedicalReferralClinicRequest,
  getMedicalReferralClinicSuccess,
  getMedicalReferralClinicFail,
  loadingMedicalReferralSiteReq,
  getMedicalReferralSiteRequest,
  getMedicalReferralSiteSuccess,
  getMedicalReferralSiteFail,
  loadingMedicalReferralDoctorReq,
  getMedicalReferralDoctorRequest,
  getMedicalReferralDoctorSuccess,
  getMedicalReferralDoctorFail,
  loadingMedicalReferralTitleReq,
  getMedicalReferralTitleRequest,
  getMedicalReferralTitleSuccess,
  getMedicalReferralTitleFail,
  loadingMedicalReferralAppointmentTypeReq,
  getMedicalReferralAppointmentTypeRequest,
  getMedicalReferralAppointmentTypeSuccess,
  getMedicalReferralAppointmentTypeFail,
  loadingMedicalReferralReferralTypeReq,
  getMedicalReferralReferralTypeRequest,
  getMedicalReferralReferralTypeSuccess,
  getMedicalReferralReferralTypeFail,
  setCustomerForMedicalReferral,
  getClinicFromSiteRequest,
  getClinicFromSiteSuccess,
  getClinicFromSiteFail,
  getDoctorFromClinicRequest,
  getDoctorFromClinicSuccess,
  getDoctorFromClinicFail,
  createMedicalReferralRequest,
  createMedicalReferralSuccess,
  createMedicalReferralFail,
  updateMedicalReferralRequest,
  updateMedicalReferralSuccess,
  updateMedicalReferralFail,
  createDischargeNoteRequest,
  createDischargeNoteSuccess,
  createDischargeNoteFail,
  clearMedicalReferralCustomer,
  //
  getMedicalReferralById,
  medicalReferralEditSuccess,
  medicalReferralEditFail,
  clearMedicalReferralData,
  //
} = MedicalReferral.actions;
export default MedicalReferral.reducer;
