import { createSlice, createAction } from "@reduxjs/toolkit";
import { itemCategory } from "./types";
const initialState: any = {
  itemCategories: [],
  loading: false,
  loadingCreateItemCategory: false,
  edit: false,
  itemCategory: null,
  count: 0,
  previous: "",
  next: "",
};

export const ItemCategory = createSlice({
  name: "ItemCategoryReducer",
  initialState,
  reducers: {
    loadingItemCategory: (state) => {
      state.loadingItemCategory = true;
    },
    getItemCategoryRequest: (state, payload) => {
      state.loadingItemCategory = true;
    },
    getItemCategorySuccess: (
      state,
      { payload: { results, count, next, previous } }
    ) => {
      state.loadingItemCategory = false;
      state.itemCategories = results;
      state.count = count;
      state.next = next;
      state.previous = previous;
    },
    getItemCategoryFail: (state) => {
      state.loadingItemCategory = false;
    },
    getItemCategoryNextRequest: (state, payload) => {
      state.loadingItemCategory = true;
    },
    getItemCategoryPreviousRequest: (state, payload) => {
      state.loadingItemCategory = true;
    },
    createItemCategoryRequest: (state, payload) => {
      state.loadingCreateItemCategory = true;
    },
    createItemCategorySuccess: (state) => {
      state.loadingCreateItemCategory = false;
    },
    createItemCategoryFail: (state) => {
      state.loadingCreateItemCategory = false;
    },
    itemCategoryEditSuccess: (state, { payload }) => {
      const selectedItemCategory = state.itemCategories.find(
        (selected: itemCategory) => selected?.id === payload.id
      );
      state.edit = true;
      state.itemCategory = selectedItemCategory;
    },
    updateItemCategoryRequest: (state, payload) => {
      state.loadingCreateItemCategory = true;
    },
    updateItemCategorySuccess: (state) => {
      state.loadingCreateItemCategory = false;
    },
    updateItemCategoryFail: (state) => {
      state.loadingCreateItemCategory = false;
    },
    clearItemCategoryData: (state) => {
      state.edit = false;
      state.itemCategory = null;
    },
  },
});
// Action creators are generated for each case reducer function
export const {
  loadingItemCategory,
  getItemCategoryRequest,
  getItemCategoryNextRequest,
  getItemCategoryPreviousRequest,
  getItemCategorySuccess,
  getItemCategoryFail,
  createItemCategoryRequest,
  createItemCategorySuccess,
  createItemCategoryFail,
  itemCategoryEditSuccess,
  updateItemCategoryRequest,
  updateItemCategorySuccess,
  updateItemCategoryFail,
  clearItemCategoryData,
} = ItemCategory.actions;
export default ItemCategory.reducer;
