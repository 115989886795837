import { createAction, createSlice } from "@reduxjs/toolkit";

const initialState: any = {
  reportComments: [],
  edit: false,
  reportComment: null,
  loadingReportComment: false,
  loadingCreateReportComment:false,
  count: 0,
  next: "",
  previous: "",
};

export const updateReportCommentRequest = createAction(
  "updateReportCommentRequest",
  function prepare({ values, id, rowsPerPage, page }) {
    return {
      payload: {
        values,
        id,
        rowsPerPage,
        page,
      },
    };
  }
);

export const getReportCommentRequest = createAction(
  "getReportCommentRequest",
  function prepare({ rowsPerPage, page, filterData }) {
    return {
      payload: {
        rowsPerPage,
        page,
        filterData,
      },
    };
  }
);
export const getAllReportCommentDefaultRequest = createAction(
  "getAllReportCommentDefaultRequest",
  function prepare() {
    return {
      payload:{},
    };
  }
);
// get next request
export const getReportCommentNextRequest = createAction(
  "getReportCommentNextRequest",
  function prepare(next) {
    return {
      payload: next,
    };
  }
);
//get previous request
export const getReportCommentPreviousRequest = createAction(
  "getReportCommentPreviousRequest",
  function prepare(previous) {
    return {
      payload: previous,
    };
  }
);
export const ReportComment = createSlice({
  name: "ReportCommentReducer",
  initialState,
  reducers: {
    loadingReportComment: (state) => {
      state.loadingReportComment = true;
    },
    getReportCommentSuccess: (
      state,
      { payload: { results, count, next, previous } }
    ) => {
      state.loadingReportComment = false;
      state.reportComments = results;
      state.count = count;
      state.next = next;
      state.previous = previous;
    },
    getReportCommentFail: (state) => {
      state.loadingReportComment = false;
    },

    createReportCommentRequest: (state, payload) => {
      state.loadingCreateReportComment = true;
      state.closeModal = true;
    },
    createReportCommentSuccess: (state) => {
      state.loadingCreateReportComment = false;
    },
    createReportCommentFail: (state) => {
      state.loadingCreateReportComment = false;
    },
    reportCommentEditSuccess: (state, { payload }) => {
      state.edit = true;
      state.reportComment = payload;
    },
    updateReportCommentSuccess: (state) => {
      state.loadingCreateReportComment = false;
    },
    updateReportCommentFail: (state) => {
      state.loadingCreateReportComment = false;
    },
    
    clearReportCommentData: (state) => {
      state.edit = false;
    },
  },
});
// Action creators are generated for each case reducer function
export const {
  loadingReportComment,
  getReportCommentSuccess,
  getReportCommentFail,
  createReportCommentRequest,
  createReportCommentSuccess,
  createReportCommentFail,
  reportCommentEditSuccess,
  updateReportCommentSuccess,
  updateReportCommentFail,
  clearReportCommentData,
} = ReportComment.actions;
export default ReportComment.reducer;
