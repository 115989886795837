import {
  getVerificationAndPrintFail,
  getVerificationAndPrintSuccess,
  updateVerificationAndPrintFail,
  updateVerificationAndPrintSuccess,
  getVerificationAndPrintRequest,
  getVerificationAndPrintNextRequest,
  getVerificationAndPrintPreviousRequest,
  updateVerificationAndPrintRequest,
  clearVerificationAndPrintData,
  getVerificationAndPrintByIdRequest,
  verificationAndPrintEditSuccess,
  verificationAndPrintEditFail,
  getVerificationAndPrintCreatedResultEntryTestRequest,
  verificationAndPrintCreatedResultEntryTestSuccess,
  verificationAndPrintCreatedResultEntryTestFail,
  getResultEnteredTestDetailsSuccess,
  getResultEnteredTestDetailsFail,
  getResultEnteredTestDetailsRequest,
  getResultEnteredReportFooterSuccess,
  getResultEnteredReportFooterFail,
  getResultEnteredReportFooterRequest,
  verifyPrintReportRequest,
  verifyPrintReportSuccess,
  verifyPrintReportFail,
  unApproveReportRequest,
  unApproveReportSuccess,
  unApproveReportFail,
  printCountRequest,
  printCountSuccess,
  printCountFail,
  sendLabReportEmailRequest,
  sendLabReportEmailSuccess,
  sendLabReportEmailFail,
} from "./verificationAndPrintSlice";
import { mergeMap } from "rxjs";
import * as API from "./api";
import { map, filter } from "rxjs/operators";
import { Observable } from "rxjs";
import { Action } from "@reduxjs/toolkit";
import { combineEpics } from "redux-observable";
import {
  alertErrorAction,
  alertSuccessAction,
  closeModal,
} from "../../../CommonAppRedux/CommonAppSlice";
import { getNext, getPrevious } from "../../../CommonAppRedux/api";
import {
  dispatchAction,
  stateAction,
} from "../../../../AppUtils/Utils/globalTypes";
import messages from "../../../../AppUtils/Utils/validationConstants";
export const controller = new AbortController();

//get VerificationAndPrint epic
const getVerificationAndPrintEpic = (
  action$: Observable<Action>,
  _: stateAction,
  { dispatch }: dispatchAction
) =>
  action$.pipe(
    filter(getVerificationAndPrintRequest.match),
    mergeMap(async (action: any) => {
      try {
        const response = await API.getVerificationAndPrint(action.payload);
        return { payload: response.data };
      } catch (e) {
        return { error: e };
      }
    }),
    map((action) =>
      action?.payload
        ? getVerificationAndPrintSuccess(action?.payload)
        : getVerificationAndPrintFail()
    )
  );

//get verificationAndPrint by id epic
const getVerificationAndPrintByIdEpic = (
  action$: Observable<Action>,
  _: stateAction,
  { dispatch }: dispatchAction
) =>
  action$.pipe(
    filter(getVerificationAndPrintByIdRequest.match),
    mergeMap(async (action) => {
      try {
        const response = await API.getVerificationAndPrintById(action.payload);
        return { payload: response.data };
      } catch (e) {
        return { error: e };
      }
    }),
    map((action) =>
      action?.payload
        ? verificationAndPrintEditSuccess(action.payload)
        : verificationAndPrintEditFail()
    )
  );
//get verificationAndPrint by id epic
const getVerificationAndPrintCreatedResultEntryTestsEpic = (
  action$: Observable<Action>,
  _: stateAction,
  { dispatch }: dispatchAction
) =>
  action$.pipe(
    filter(getVerificationAndPrintCreatedResultEntryTestRequest.match),
    mergeMap(async (action) => {
      try {
        const response = await API.getCreatedResultEntryTest(action.payload);
        return { payload: response.data };
      } catch (e) {
        return { error: e };
      }
    }),
    map((action) => {
      const { payload } = action;
      return payload
        ? verificationAndPrintCreatedResultEntryTestSuccess(payload)
        : verificationAndPrintCreatedResultEntryTestFail();
    })
  );
// get result entered test details
const getResultEnteredTestDetailsEpic = (
  action$: Observable<Action>,
  _: stateAction,
  { dispatch }: dispatchAction
) =>
  action$.pipe(
    filter(getResultEnteredTestDetailsRequest.match),
    mergeMap(async (action) => {
      try {
        const response = await API.getResultEnteredTestDetails(action.payload);
        return { payload: response.data };
      } catch (e) {
        return { error: e };
      }
    }),
    map((action) => {
      const { payload } = action;
      return payload
        ? getResultEnteredTestDetailsSuccess(payload)
        : getResultEnteredTestDetailsFail();
    })
  );
// get result entered footer
const getResultEnteredReportFooterEpic = (
  action$: Observable<Action>,
  _: stateAction,
  { dispatch }: dispatchAction
) =>
  action$.pipe(
    filter(getResultEnteredReportFooterRequest.match),
    mergeMap(async (action) => {
      try {
        const response = await API.getResultEnteredTestReportFooter(
          action.payload
        );
        return { payload: response.data };
      } catch (e) {
        return { error: e };
      }
    }),
    map((action) => {
      const { payload } = action;
      return payload
        ? getResultEnteredReportFooterSuccess(payload)
        : getResultEnteredReportFooterFail();
    })
  );

//verify report report
const verifyPrintReportEpic = (
  action$: Observable<Action>,
  _: stateAction,
  { dispatch }: dispatchAction
) =>
  action$.pipe(
    filter(verifyPrintReportRequest.match),
    mergeMap(async ({ payload }) => {
      try {
        const body = JSON.stringify(payload);
        const response = await API.verifyReport(body);
        if (response) {
          dispatch(alertSuccessAction("Verified Successfully"));
        }
        return { payload: { response } };
      } catch (e) {
        dispatch(alertErrorAction("Failed To Verify"));
        return { error: e };
      }
    }),
    map((action) => {
      return action?.payload
        ? verifyPrintReportSuccess()
        : verifyPrintReportFail();
    })
  );
// unapprove report
const unApproveReportEpic = (
  action$: Observable<Action>,
  _: stateAction,
  { dispatch }: dispatchAction
) =>
  action$.pipe(
    filter(unApproveReportRequest.match),
    mergeMap(async ({ payload }) => {
      try {
        const body = JSON.stringify(payload);
        const response = await API.unApproveTest(body);
        if (response) {
          dispatch(alertSuccessAction("UnApprove Successfully"));
        }
        return { payload: { response } };
      } catch (e) {
        dispatch(alertErrorAction("Failed To UnApprove"));
        return { error: e };
      }
    }),
    map((action) => {
      return action?.payload ? unApproveReportSuccess() : unApproveReportFail();
    })
  );
// send email
const sendLabReportEmailEpic = (
  action$: Observable<Action>,
  _: stateAction,
  { dispatch }: dispatchAction
) =>
  action$.pipe(
    filter(sendLabReportEmailRequest.match),
    mergeMap(async ({ payload }) => {
      try {
        const body = new FormData();
        for (let [key, value] of Object.entries(payload)) {
          // @ts-ignore
          body.append(`${key}`, value);
        }
        const response = await API.sendLabReportEmail(body);
        if (response) {
          dispatch(alertSuccessAction("Email send successfully"));
        }
        return { payload: { response } };
      } catch (e) {
        dispatch(alertErrorAction("Failed to send email."));
        return { error: e };
      }
    }),
    map((action) => {
      return action?.payload
        ? sendLabReportEmailSuccess()
        : sendLabReportEmailFail();
    })
  );
//update
const updateVerificationAndPrintEpic = (
  action$: Observable<Action>,
  _: stateAction,
  { dispatch }: dispatchAction
) =>
  action$.pipe(
    filter(updateVerificationAndPrintRequest.match),
    mergeMap(async ({ payload: { values, id, rowsPerPage, page, search } }) => {
      try {
        const body = new FormData();
        for (let [key, value] of Object.entries(values)) {
          body.append(`${key}`, String(value));
        }
        const response = await API.updateVerificationAndPrint(body, id);
        if (response) {
          dispatch(
            getVerificationAndPrintRequest({ rowsPerPage, page, search })
          );
          dispatch(alertSuccessAction(messages.updateMessage));
          dispatch(closeModal());
          dispatch(clearVerificationAndPrintData());
        }
        return { payload: { response, rowsPerPage } };
      } catch (e) {
        dispatch(alertErrorAction(messages.updateFailMessage));
        return { error: e };
      }
    }),
    map((action) => {
      return action?.payload
        ? updateVerificationAndPrintSuccess()
        : updateVerificationAndPrintFail();
    })
  );

//print count
const printCountEpic = (
  action$: Observable<Action>,
  _: stateAction,
  { dispatch }: dispatchAction
) =>
  action$.pipe(
    filter(printCountRequest.match),
    mergeMap(async (action) => {
      try {
        const body = JSON.stringify({ ...action.payload, remarks: "" });
        const response = await API.printCount(body);
        return { payload: response.data };
      } catch (e) {
        return { error: e };
      }
    }),
    map((action) => {
      return action?.payload ? printCountSuccess() : printCountFail();
    })
  );

//get next
const getVerificationAndPrintNext = (
  action$: Observable<Action>,
  _: stateAction,
  { dispatch }: dispatchAction
) =>
  action$.pipe(
    filter(getVerificationAndPrintNextRequest.match),
    mergeMap(async (action) => {
      try {
        const response = await getNext(action.payload);
        return { payload: response.data };
      } catch (e) {
        return { error: e };
      }
    }),
    map((action) => {
      return action?.payload
        ? getVerificationAndPrintSuccess(action?.payload)
        : getVerificationAndPrintFail();
    })
  );
//get previous
const getVerificationAndPrintPrevious = (
  action$: Observable<Action>,
  _: stateAction,
  { dispatch }: dispatchAction
) =>
  action$.pipe(
    filter(getVerificationAndPrintPreviousRequest.match),
    mergeMap(async (action) => {
      try {
        const response = await getPrevious(action.payload);
        return { payload: response.data };
      } catch (e) {
        return { error: e };
      }
    }),
    map((action) =>
      action?.payload
        ? getVerificationAndPrintSuccess(action?.payload)
        : getVerificationAndPrintFail()
    )
  );

export const verificationAndPrintEpics = combineEpics(
  getVerificationAndPrintEpic,
  verifyPrintReportEpic,
  updateVerificationAndPrintEpic,
  getVerificationAndPrintNext,
  getVerificationAndPrintPrevious,
  getVerificationAndPrintByIdEpic,
  getVerificationAndPrintCreatedResultEntryTestsEpic,
  getResultEnteredTestDetailsEpic,
  getResultEnteredReportFooterEpic,
  unApproveReportEpic,
  printCountEpic,
  sendLabReportEmailEpic
);
