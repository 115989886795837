import { useCallback, useEffect, useState } from "react";
import { catchError, from, of } from "rxjs";
import { useAppDispatch } from "../Utils/appHooks";
import { axiosInstance } from "../Utils/axios";
// import { alertErrorAction } from "../../Redux/Alert/action";
export interface apiData {
  results: any;
}
export const initialDuplicateValues = {
  url: "",
  value: "",
  edit: false,
  editType: "",
  componentName: "",
};
export const useSearch = (url: string, slice?: any, errorSlice?: any) => {
  const controller = new AbortController();
  const dispatch = useAppDispatch();
  //the function is located in separate memory on each render
  // The function is recreated on each component render and
  // therefore will always be different than the function created during the previous render.
  const [apiData, setApiData] = useState<apiData>();
  // prevent
  const getFormData = useCallback(() => {
    from(
      axiosInstance.get(url, {
        signal: controller?.signal,
      })
    )
      // catching error
      .pipe(catchError((err) => of(err)))
      .subscribe((response) => {
        setApiData(response.data);
      });
  }, [url]);
  //call api if url changes
  useEffect(() => {
    url && getFormData();
    return () => controller.abort();
  }, [dispatch, url, getFormData]);

  //dispatch actions if apiData exist
  useEffect(() => {
    apiData?.results.length >= 0 && dispatch(slice?.(apiData));
  }, [apiData, dispatch]);
};
