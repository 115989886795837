import {
  createReferralFail,
  createReferralRequest,
  createReferralSuccess,
  getReferralFail,
  getReferralSuccess,
  loadingReferral,
  updateReferralFail,
  updateReferralSuccess,
  getReferralRequest,
  getReferralNextRequest,
  getReferralPreviousRequest,
  updateReferralRequest,
  clearReferralData,
  getReferralByIdRequest,
  referralEditSuccess,
  referralEditFail,
} from "./referralSlice";
import { mergeMap } from "rxjs";
import {
  createReferral,
  getReferral,
  getReferralById,
  updateReferral,
} from "./api";
import { map, filter } from "rxjs/operators";
import { Observable } from "rxjs";
import { Action } from "@reduxjs/toolkit";
import { combineEpics } from "redux-observable";
import {
  alertErrorAction,
  alertSuccessAction,
  closeModal,
} from "../../../CommonAppRedux/CommonAppSlice";
import { getNext, getPrevious } from "../../../CommonAppRedux/api";
import {
  dispatchAction,
  stateAction,
} from "../../../../AppUtils/Utils/globalTypes";
import messages from "../../../../AppUtils/Utils/validationConstants";
export const controller = new AbortController();
// import { searchData } from "../../../CommonAppRedux/commonAppFunctions";
//get Type epic
const getReferralEpic = (
  action$: Observable<Action>,
  _: stateAction,
  { dispatch }: dispatchAction
) =>
  action$.pipe(
    filter(getReferralRequest.match),
    mergeMap(async (action) => {
      dispatch(loadingReferral());
      try {
        const response = await getReferral(action.payload);
        return { payload: response.data };
      } catch (e) {
        return { error: e };
      }
    }),
    map((action) =>
      action?.payload ? getReferralSuccess(action?.payload) : getReferralFail()
    )
  );

//get referral by id
const getReferralByIdEpic = (
  action$: Observable<Action>,
  _: stateAction,
  { dispatch }: dispatchAction
) =>
  action$.pipe(
    filter(getReferralByIdRequest.match),
    mergeMap(async (action) => {
      try {
        const response = await getReferralById(action.payload);
        return { payload: response.data };
      } catch (e) {
        return { error: e };
      }
    }),
    map((action) =>
      action?.payload ? referralEditSuccess(action.payload) : referralEditFail()
    )
  );

//get next
const getReferralNext = (
  action$: Observable<Action>,
  _: stateAction,
  { dispatch }: dispatchAction
) =>
  action$.pipe(
    filter(getReferralNextRequest.match),
    mergeMap(async (action) => {
      dispatch(loadingReferral());
      try {
        const response = await getNext(action.payload);
        return { payload: response.data };
      } catch (e) {
        return { error: e };
      }
    }),
    map((action) =>
      action?.payload ? getReferralSuccess(action?.payload) : getReferralFail()
    )
  );
//get previous
const getReferralPrevious = (
  action$: Observable<Action>,
  _: stateAction,
  { dispatch }: dispatchAction
) =>
  action$.pipe(
    filter(getReferralPreviousRequest.match),
    mergeMap(async (action) => {
      dispatch(loadingReferral());
      try {
        const response = await getPrevious(action.payload);
        return { payload: response.data };
      } catch (e) {
        return { error: e };
      }
    }),
    map((action) =>
      action?.payload ? getReferralSuccess(action?.payload) : getReferralFail()
    )
  );
const createReferralEpic = (
  action$: Observable<Action>,
  _: stateAction,
  { dispatch }: dispatchAction
) =>
  action$.pipe(
    filter(createReferralRequest.match),
    mergeMap(
      async ({ payload: { values, rowsPerPage, page, setShowReferrer } }) => {
        try {
          // const body = new FormData();
          const body = JSON.stringify(values);
          // for (let [key, value] of Object.entries(values)) {
          //   // @ts-ignore
          //   body.append(`${key}`, value);
          // }
          const response = await createReferral(body);
          if (response) {
            dispatch(alertSuccessAction(messages.createMessage));
            !setShowReferrer &&
              dispatch(getReferralRequest({ rowsPerPage, page }));
            setShowReferrer ? setShowReferrer(false) : dispatch(closeModal());
          }
          return { payload: { response } };
        } catch (e) {
          dispatch(alertErrorAction(messages.createFailMessage));
          return { error: e };
        }
      }
    ),
    map((action) => {
      return action?.payload ? createReferralSuccess() : createReferralFail();
    })
  );
//update Type epic
const updateReferralEpic = (
  action$: Observable<Action>,
  _: stateAction,
  { dispatch }: dispatchAction
) =>
  action$.pipe(
    filter(updateReferralRequest.match),
    mergeMap(async ({ payload: { values, id, rowsPerPage, page } }) => {
      try {
        // const body = new FormData();
        const body = JSON.stringify(values);

        // for (let [key, value] of Object.entries(values)) {
        //   // @ts-ignore
        //   body.append(`${key}`, value);
        // }

        const response = await updateReferral(body, id);
        if (response) {
          dispatch(getReferralRequest({ rowsPerPage, page }));
          dispatch(alertSuccessAction(messages.updateMessage));
          dispatch(closeModal());
          dispatch(clearReferralData());
        }
        return { payload: { response, rowsPerPage } };
      } catch (e) {
        dispatch(alertErrorAction(messages.updateFailMessage));
        return { error: e };
      }
    }),
    map((action) =>
      action?.payload ? updateReferralSuccess() : updateReferralFail()
    )
  );
export const referralEpics = combineEpics(
  getReferralEpic,
  createReferralEpic,
  updateReferralEpic,
  getReferralNext,
  getReferralPrevious,
  getReferralByIdEpic
);
