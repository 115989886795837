import {
  createStoreFail,
  createStoreRequest,
  createStoreSuccess,
  getStoreFail,
  getStoreSuccess,
  loadingStore,
  updateStoreFail,
  updateStoreSuccess,
  getStoreRequest,
  getStoreNextRequest,
  getStorePreviousRequest,
  updateStoreRequest,
  clearStoreData,
  getStoreByIdRequest,
  StoreEditSuccess,
  storeEditFail,
  getMainStoreSuccess,
  getMainStoreFail,
  getMainStoreRequest,
} from "./storeSlice";
import { mergeMap } from "rxjs";
import {
  createStore,
  getStore,
  updateStore,
  getAllStoreForDefault,
  getStoreById,
} from "./api";
import { map, filter } from "rxjs/operators";
import { Observable } from "rxjs";
import { Action } from "@reduxjs/toolkit";
import { combineEpics } from "redux-observable";
import {
  alertErrorAction,
  alertSuccessAction,
  closeModal,
} from "../../../CommonAppRedux/CommonAppSlice";
import { getNext, getPrevious } from "../../../CommonAppRedux/api";
import {
  dispatchAction,
  stateAction,
} from "../../../../AppUtils/Utils/globalTypes";
import messages from "../../../../AppUtils/Utils/validationConstants";
export const controller = new AbortController();
// import { searchData } from "../../../CommonAppRedux/commonAppFunctions";
//get storeType epic
const getStoreEpic = (
  action$: Observable<Action>,
  _: stateAction,
  { dispatch }: dispatchAction
) =>
  action$.pipe(
    filter(getStoreRequest.match),
    mergeMap(async (action) => {
      dispatch(loadingStore());
      try {
        const response = await getStore(action.payload);
        return { payload: response.data };
      } catch (e) {
        return { error: e };
      }
    }),
    map((action) =>
      action?.payload ? getStoreSuccess(action?.payload) : getStoreFail()
    )
  );

//get stor by id
const getStoreByIdEpic = (
  action$: Observable<Action>,
  _: stateAction,
  { dispatch }: dispatchAction
) =>
  action$.pipe(
    filter(getStoreByIdRequest.match),
    mergeMap(async (action) => {
      try {
        const response = await getStoreById(action.payload);
        return { payload: response.data };
      } catch (e) {
        return { error: e };
      }
    }),
    map((action) =>
      action?.payload ? StoreEditSuccess(action.payload) : storeEditFail()
    )
  );

//get next
const getStoreNext = (
  action$: Observable<Action>,
  _: stateAction,
  { dispatch }: dispatchAction
) =>
  action$.pipe(
    filter(getStoreNextRequest.match),
    mergeMap(async (action) => {
      dispatch(loadingStore());
      try {
        const response = await getNext(action.payload);
        return { payload: response.data };
      } catch (e) {
        return { error: e };
      }
    }),
    map((action) =>
      action?.payload ? getStoreSuccess(action?.payload) : getStoreFail()
    )
  );
//get previous
const getStorePrevious = (
  action$: Observable<Action>,
  _: stateAction,
  { dispatch }: dispatchAction
) =>
  action$.pipe(
    filter(getStorePreviousRequest.match),
    mergeMap(async (action) => {
      dispatch(loadingStore());
      try {
        const response = await getPrevious(action.payload);
        return { payload: response.data };
      } catch (e) {
        return { error: e };
      }
    }),
    map((action) =>
      action?.payload ? getStoreSuccess(action?.payload) : getStoreFail()
    )
  );
//create storeType epic
const createStoreEpic = (
  action$: Observable<Action>,
  _: stateAction,
  { dispatch }: dispatchAction
) =>
  action$.pipe(
    filter(createStoreRequest.match),
    mergeMap(async ({ payload: { values, rowsPerPage, page } }) => {
      try {
        const body = new FormData();
        for (let [key, value] of Object.entries(values)) {
          // @ts-ignore
          body.append(`${key}`, value);
        }
        const response = await createStore(body);
        if (response) {
          dispatch(getStoreRequest({ rowsPerPage, page }));
          dispatch(alertSuccessAction(messages.createMessage));
          dispatch(closeModal());
        }
        return { payload: { response } };
      } catch (e) {
        dispatch(alertErrorAction(messages.createFailMessage));
        return { error: e };
      }
    }),
    map((action) => {
      return action?.payload ? createStoreSuccess() : createStoreFail();
    })
  );
//update storeType epic
const updateStoreEpic = (
  action$: Observable<Action>,
  _: stateAction,
  { dispatch }: dispatchAction
) =>
  action$.pipe(
    filter(updateStoreRequest.match),
    mergeMap(async ({ payload: { values, id, rowsPerPage, page } }) => {
      try {
        const body = new FormData();
        for (let [key, value] of Object.entries(values)) {
          // @ts-ignore
          body.append(`${key}`, value);
        }

        const response = await updateStore(body, id);
        if (response) {
          dispatch(getStoreRequest({ rowsPerPage, page }));
          dispatch(alertSuccessAction(messages.updateMessage));
          dispatch(closeModal());
          dispatch(clearStoreData());
        }
        return { payload: { response, rowsPerPage } };
      } catch (e) {
        dispatch(alertErrorAction(messages.updateFailMessage));
        return { error: e };
      }
    }),
    map((action) =>
      action?.payload ? updateStoreSuccess() : updateStoreFail()
    )
  );

const getAllStoreDefaultEpic = (
  action$: Observable<Action>,
  _: stateAction,
  { dispatch }: dispatchAction
) =>
  action$.pipe(
    filter(getMainStoreRequest.match),
    mergeMap(async (action) => {
      // dispatch(loadingStore());
      try {
        const response = await getAllStoreForDefault();
        return { payload: response.data };
      } catch (e) {
        return { error: e };
      }
    }),
    map((action) =>
      action?.payload
        ? getMainStoreSuccess(action?.payload)
        : getMainStoreFail()
    )
  );

export const storeEpics = combineEpics(
  getStoreEpic,
  createStoreEpic,
  updateStoreEpic,
  getStoreNext,
  getStorePrevious,
  getAllStoreDefaultEpic,
  getStoreByIdEpic
);
