import { filterApiData } from "../../../../../AppUtils/Utils/appFunctions";
import { axiosInstance } from "../../../../../AppUtils/Utils/axios";
import { defaultRowsPerPage } from "../../../../../AppUtils/Utils/globalConstant";
export const saleClinicReportModURL =
  "/api/v1/clinic-financial-report-app/sale-report";
// query for report
const getReportQuery = (updatedValue: any) => {
  const { test, customer, user, saleType, startDateAd, endDateAd, payType } =
    updatedValue;
  return `&created_by=${user}&sale_type=${
    saleType === "SALE-RETURN" ? "RETURN" : saleType
  }&customer=${customer}&test=${test ?? ""}&pay_type=${
    payType ?? ""
  }&date_after=${startDateAd}&date_before=${endDateAd}`;
};
export const getSaleClinicReportCustomer = (rowsPerPage: number) =>
  axiosInstance.get(
    `${saleClinicReportModURL}/customer?offset=0&limit=${rowsPerPage}`
  );
// get user
export const getSaleClinicReportUser = () =>
  axiosInstance.get(
    `${saleClinicReportModURL}/user?offset=0&limit=${defaultRowsPerPage}`
  );
// get test
export const getClinicSaleReportTest = () =>
  axiosInstance.get(
    `${saleClinicReportModURL}/test?offset=0&limit=${defaultRowsPerPage}`
  );
// get all data
export const getSaleClinicReportAllReport = (data: any) => {
  const { updatedValue } = data;
  const { summary } = updatedValue;
  return axiosInstance.get(
    `${saleClinicReportModURL}/${
      summary ? "summary" : "detail"
    }?offset=0&limit=${0}${
      data?.filterData ? filterApiData(data?.filterData) : ""
    }${getReportQuery(updatedValue)}`
  );
};
export const getSaleClinicReportQuickReport = (data: any) => {
  const { rowsPerPage, page, updatedValue } = data;
  const { summary } = updatedValue;
  return axiosInstance.get(
    `${saleClinicReportModURL}/${summary ? "summary" : "detail"}?offset=${
      rowsPerPage * page
    }&limit=${rowsPerPage}${
      data?.filterData ? filterApiData(data?.filterData) : ""
    }${getReportQuery(updatedValue)}`
  );
};
export const searchSaleClinicReport = ({
  rowsPerPage,
  search,
  updatedValue,
}: any) => {
  const { summary } = updatedValue;
  return `${saleClinicReportModURL}/${
    summary ? "summary" : "detail"
  }?offset=0&limit=${rowsPerPage}&search=${search}${getReportQuery(
    updatedValue
  )}`;
};
