import { createSlice } from "@reduxjs/toolkit";
import { noteTemplates } from "./type";

const initialState: noteTemplates = {
    noteTemplates: [],
    loadingNoteTemplates: false,
    count: 0,
    next: 0,
    previous: 0,
    edit: false,
    editNoteTemplates: null,
    loadingNoteTemplateSubmitButton: false,
};

export const NoteTemplates = createSlice({
    name: "NoteTemplatesReducer",
    initialState,
    reducers: {
        getNoteTemplatesRequest: (state, payload) => {
            state.loadingNoteTemplates = true;
        },
        getNoteTemplatesSuccess: (
            state,
            { payload: { results, count, next, previous } }
        ) => {
            state.loadingNoteTemplates = false;
            state.noteTemplates = results;
            state.count = count;
            state.next = next;
            state.previous = previous;
        },
        getNoteTemplatesFail: (state) => {
            state.loadingNoteTemplates = false;
        },
        createNoteTemplatesRequest: (state, {payload})=>{
            state.loadingNoteTemplateSubmitButton= true
        },
        createNoteTemplatesSuccess: (state)=>{
            state.loadingNoteTemplateSubmitButton= false
        },
        createNoteTemplatesFail: (state)=>{
            state.loadingNoteTemplateSubmitButton= false
        },
        editNoteTemplateRequest: (state, payload)=>{
            state.edit = true
        },
        editNoteTemplatesSuccess: (state, { payload }) => {
            state.edit = true;
            state.editNoteTemplates = payload;
        },
        editNoteTemplatesFail: (state) => {
            state.edit = false;
            state.editNoteTemplates = null;
        },
        updateNoteTemplatesRequest: (state, {payload})=>{
            state.loadingNoteTemplateSubmitButton = true
        },
        updateNoteTemplatesSuccess: (state)=>{
            state.loadingNoteTemplateSubmitButton = false
        },
        updateNoteTemplatesFail: (state)=>{
            state.loadingNoteTemplateSubmitButton = false
        },
        getNextNoteTemplateRequest: (state, payload)=>{
            state.loadingNoteTemplates = true
        },
        getPreviousNoteTemplateRequest: (state, payload) =>{
            state.loadingNoteTemplates = true
        },
        clearNoteTemplatesData: (state) => {
            state.edit = false;
            state.editNoteTemplates = null;

        },
    },
});

export const {
    getNoteTemplatesRequest,
    getNoteTemplatesSuccess,
    getNoteTemplatesFail,
    editNoteTemplateRequest,
    editNoteTemplatesSuccess,
    editNoteTemplatesFail,
    createNoteTemplatesRequest,
    createNoteTemplatesSuccess,
    createNoteTemplatesFail,
    updateNoteTemplatesRequest,
    updateNoteTemplatesSuccess,
    updateNoteTemplatesFail,
    getNextNoteTemplateRequest,
    getPreviousNoteTemplateRequest,
    clearNoteTemplatesData,
} = NoteTemplates.actions;

export default NoteTemplates.reducer;
