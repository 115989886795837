import React, { FC } from "react";
import { Route } from "react-router";
import { useAppSelector } from "../AppUtils/Utils/appHooks";
import Unauthorized from "../Component/ErrorPages/Unauthorized";
import { loginSelector } from "../Pages/Auth/Redux/selector";
import { ProtectedRouteProps } from "./types";

const ProtectedRoute: FC<ProtectedRouteProps> = ({
  component: Component,
  permission,
  path,
  ...rest
}) => {
  const { permissions, isSuperuser } = useAppSelector(loginSelector);
  return (
    <>
      {isSuperuser ||
      permissions?.some(({ codeName }: any) =>
        permission?.includes(codeName)
      ) ||
      permission[0]?.mainModule ? (
        <Route
          {...rest}
          render={(props) => {
            return <Component {...props} />;
          }}
        />
      ) : (
        <Unauthorized />
      )}
    </>
  );
};

export default React.memo(ProtectedRoute);
