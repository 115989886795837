import axios from "axios";
import { axiosInstance } from "../../../AppUtils/Utils/axios";

import { branch, credential, IResetPasswordConfirm, user } from "./types";

const loginModURL = "api/v1/user-app";

export const getLogin = (body: credential) =>
  axiosInstance.post<user>(`${loginModURL}/login`, body);
export const getAllBranches = () => axiosInstance.get<branch[]>(`branches`);
//for logout
export const logout = (body: string) =>
  axiosInstance.post(`${loginModURL}/logout`, body);
//for reset password
export const resetPassword = (body: string) =>
  axiosInstance.post(`${loginModURL}/forget-password-verification`, body);

export const confirmPassword = ({ branch, body }: IResetPasswordConfirm) => {
  return axiosInstance.post(`/${loginModURL}/forget-password`, body, {
    baseURL: `https://${branch}`,
  });
};
export const changePassword = (id: number, body: string) =>
  axiosInstance.patch(`${loginModURL}/change-password/${id}`, body);

//for email verfication
  export const emailVerification = (body: string) =>
  axiosInstance.post(`${loginModURL}/send-verification-email`, body);

  export const emailVerificationSuccess = (body: string) =>
  axiosInstance.post(`${loginModURL}/verify-email`, body);


