import { axiosInstance } from "../../../../AppUtils/Utils/axios";
import { filterApiData } from "../../../../AppUtils/Utils/appFunctions";
import {
  getApiProps,
  searchProps,
} from "../../../../AppUtils/Utils/globalTypes";

const reagentModURL = "api/v1/lab-app/reagent";

//get reagent
export const getReagent = (data: getApiProps) => {
  const { rowsPerPage, page } = data;
  return axiosInstance.get(
    `${reagentModURL}?offset=${rowsPerPage * page}&limit=${rowsPerPage}${
      data?.filterData ? filterApiData(data?.filterData) : ""
    }`
  );
};

//get reagent by id
export const getReagentById = (id: number) => {
  return axiosInstance.get(`${reagentModURL}/${id}`);
};

//handle search
export const searchReagent = ({ rowsPerPage, search }: searchProps) => {
  return `${reagentModURL}?offset=0&limit=${rowsPerPage}&search=${search}`;
};

//create reagent
export const createReagent = (body: string | FormData) => {
  return axiosInstance.post(`${reagentModURL}`, body);
};

//update reagent
export const updateReagent = (body: string | FormData, id: number) => {
  return axiosInstance.patch(`${reagentModURL}/${id}`, body);
};
