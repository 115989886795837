import { createSlice, createAction } from "@reduxjs/toolkit";
const initialState: any = {
  deviceOrders: [],
  deviceOrderDetails: [],
  loadingDeviceOrder: false,
  loadingDeviceOrderDepartment: false,
  loadingDeviceOrderCategory: false,
  loadingDeviceOrderCustomerType: false,
  loadingCreateDeviceOrder: false,
  edit: false,
  deviceOrderDetailEdit: false,
  deviceOrder: null,
  deviceOrderDetail: null,
  customerTypes: [],
  departments: [],
  deviceOrderCategories: [],
  count: 0,
  previous: "",
  next: "",
  loadingDeviceOrderDetailsAdded: false,
  // loadingHospitalReferralCustomer:false,
  loadingDeviceOrderCustomer: false,
  deviceOrderCustomers: [],
  loadingDeviceOrderItem: false,
  loadingHospitalReferralItem: false,
  deviceOrderItems: [],
  loadingDeviceOrderItemCategory: false,
  deviceOrderItemCategories: [],
  loadingItemCategory: true,
  //
  loadingUpdateDeviceReceivedStatus: false,
  loadingUpdateDeviceDispatchedStatus: false,
  deviceOrderReferrers: [],
  loadingDeviceOrderReferrer: false,
};

export const DeviceOrder = createSlice({
  name: "DeviceOrder Reducer",
  initialState,
  reducers: {
    loadingDeviceOrder: (state) => {
      state.loadingDeviceOrder = true;
    },
    getDeviceOrderRequest: (state, payload) => {
      state.loadingDeviceOrder = true;
    },
    getDeviceOrderSuccess: (
      state,
      { payload: { results, count, next, previous } }
    ) => {
      state.loadingDeviceOrder = false;
      state.deviceOrders = results;
      state.count = count;
      state.next = next;
      state.previous = previous;
    },
    getDeviceOrderFail: (state) => {
      state.loadingDeviceOrder = false;
    },
    getDeviceOrderNextRequest: (state, payload) => {
      state.loadingDeviceOrder = true;
    },
    getDeviceOrderPreviousRequest: (state, payload) => {
      state.loadingDeviceOrder = true;
    },
    getDeviceOrderCustomerTypeRequest: (state, payload) => {
      state.loadingDeviceOrderCustomerType = true;
    },
    getDeviceOrderCustomerTypeSuccess: (state, { payload: { results } }) => {
      state.loadingDeviceOrderCustomerType = false;
      state.customerTypes = results;
    },
    getDeviceOrderCustomerTypeFail: (state) => {
      state.loadingDeviceOrderCustomerType = false;
    },
    getDeviceOrderDepartmentRequest: (state, payload) => {
      state.loadingDeviceOrderDepartment = true;
    },
    getDeviceOrderDepartmentSuccess: (state, { payload: { results } }) => {
      state.loadingDeviceOrderDepartment = false;
      state.departments = results;
    },
    getDeviceOrderDepartmentFail: (state) => {
      state.loadingDeviceOrderDepartment = false;
    },
    getDeviceOrderCategoryRequest: (state, payload) => {
      state.loadingDeviceOrderCategory = true;
    },
    getDeviceOrderCategorySuccess: (state, { payload: { results } }) => {
      state.loadingDeviceOrderCategory = false;
      state.deviceOrderCategories = results;
    },
    getDeviceOrderCategoryFail: (state) => {
      state.loadingDeviceOrderCategory = false;
    },
    createDeviceOrderRequest: (state, payload) => {
      state.loadingCreateDeviceOrder = true;
      state.closeModal = true;
    },
    createDeviceOrderSuccess: (state) => {
      state.loadingCreateDeviceOrder = false;
    },
    createDeviceOrderFail: (state) => {
      state.loadingCreateDeviceOrder = false;
    },
    getDeviceOrderByIdRequest: (state, payload) => {
      state.edit = true;
    },
    deviceOrderEditSuccess: (state, { payload }) => {
      // const editableRow = state.deviceOrders?.find(
      //   (deviceOrder: any) => deviceOrder?.id === payload?.id
      // );
      state.edit = true;
      state.deviceOrder = payload;
      state.deviceOrderDetails = payload?.clinicOrderDetails;
    },
    deviceOrderEditFail: (state) => {
      state.edit = false;
      state.deviceOrder = null;
      state.deviceOrderDetails = null;
    },
    deviceOrderDetailEditSuccess: (state, { payload }) => {
      const updatedDeviceOrderDetail = state.deviceOrderDetails.find(
        (deviceOrder: any) => deviceOrder?.id === payload?.id
      );
      const updatedDeviceOrderDetails = state.deviceOrderDetails.filter(
        (deviceOrder: any) => deviceOrder.id !== payload.id
      );
      state.deviceOrderDetail = updatedDeviceOrderDetail;
      state.deviceOrderDetails = updatedDeviceOrderDetails;
      state.deviceOrderDetailEdit = true;
      // state.loadingDeviceOrder = false;
    },
    deviceOrderDetailUpdateSuccess: (state, { payload }) => {
      state.deviceOrderDetails = [...state.deviceOrderDetails, payload];
      state.deviceOrderDetailEdit = false;
      state.edit = true;
      state.loadingDeviceOrderDetailsAdded = false;
    },
    deviceOrderDetailAddedSuccess: (state, { payload }) => {
      state.deviceOrderDetails = [...state.deviceOrderDetails, payload];
      state.deviceOrderDetailEdit = false;
      state.loadingDeviceOrderDetailsAdded = false;
    },
    deviceOrderDetailDeleteSuccess: (state, { payload }) => {
      const updatedDeviceOrderDetails = state.deviceOrderDetails.filter(
        (deviceOrder: any) => deviceOrder.unique_id !== payload.unique_id
      );
      state.deviceOrderDetails = updatedDeviceOrderDetails;
      state.deviceOrderDetailEdit = false;
      state.loadingDeviceOrderDetailsAdded = false;
    },
    updateDeviceOrderRequest: (state, payload) => {
      state.loadingCreateDeviceOrder = true;
    },
    updateDeviceOrderSuccess: (state) => {
      state.loadingCreateDeviceOrder = false;
    },
    updateDeviceOrderFail: (state) => {
      state.loadingCreateDeviceOrder = false;
    },
    clearDeviceOrderData: (state) => {
      state.edit = false;
      state.deviceOrder = null;
      state.deviceOrderDetail = null;
      state.deviceOrderDetails = [];
      state.loadingDeviceOrder = false;
      state.deviceOrderDetailEdit = false;
    },
    ///
    getDeviceOrderCustomerSuccess: (state, { payload: { results } }) => {
      state.loadingDeviceOrderCustomer = false;
      state.deviceOrderCustomers = results;
    },
    getDeviceOrderCustomerFail: (state) => {
      state.loadingDeviceOrderCustomer = false;
    },
    loadingDeviceOrderCustomerRequest: (state) => {
      state.loadingDeviceOrderCustomer = true;
    },
    getDeviceOrderCustomerRequest: (state, payload) => {
      state.loadingDeviceOrderCustomer = true;
    },
    //
    getDeviceOrderItemSuccess: (state, { payload: { results } }) => {
      state.loadingDeviceOrderItem = false;
      state.deviceOrderItems = results;
    },
    getDeviceOrderItemFail: (state) => {
      state.loadingDeviceOrderItem = false;
    },
    loadingDeviceOrderItemRequest: (state) => {
      state.loadingDeviceOrderItem = true;
    },
    getDeviceOrderItemRequest: (state, { payload }) => {
      state.loadingHospitalReferralItem = true;
    },
    getDeviceOrderItemCategorySuccess: (state, { payload: { results } }) => {
      state.loadingDeviceOrderItemCategory = false;
      state.deviceOrderItemCategories = results;
    },
    getDeviceOrderItemCategoryFail: (state) => {
      state.loadingDeviceOrderItemCategory = false;
    },
    loadingDeviceOrderItemCategoryRequest: (state) => {
      state.loadingDeviceOrderItemCategory = true;
    },
    getDeviceOrderItemCategoryRequest: (state, payload) => {
      state.loadingItemCategory = true;
    },
    // update received status
    updateDeviceReceivedStatusRequest: (state, payload) => {
      state.loadingUpdateDeviceReceivedStatus = true;
    },
    updateDeviceReceivedStatusSuccess: (state) => {
      state.loadingUpdateDeviceReceivedStatus = false;
    },
    updateDeviceReceivedStatusFail: (state) => {
      state.loadingUpdateDeviceReceivedStatus = false;
    },
    // update dispatched status
    updateDeviceDispatchedStatusRequest: (state, payload) => {
      state.loadingUpdateDeviceDispatchedStatus = true;
    },
    updateDeviceDispatchedStatusSuccess: (state) => {
      state.loadingUpdateDeviceDispatchedStatus = false;
    },
    updateDeviceDispatchedStatusFail: (state) => {
      state.loadingUpdateDeviceDispatchedStatus = false;
    },
    //deveice order referrers
    getDeviceOrderReferrerSuccess: (state, { payload: { results } }) => {
      state.loadingDeviceOrderReferrer = false;
      state.deviceOrderReferrers = results;
    },
    getDeviceOrderReferrerFail: (state) => {
      state.loadingDeviceOrderReferrer = false;
    },
    loadingDeviceOrderReferrerRequest: (state) => {
      state.loadingDeviceOrderReferrer = true;
    },
    getDeviceOrderReferrerRequest: (state, payload) => {
      state.loadingDeviceOrderReferrer = true;
    },
    //
  },
});
// Action creators are generated for each case reducer function
export const {
  loadingDeviceOrder,
  getDeviceOrderRequest,
  getDeviceOrderSuccess,
  getDeviceOrderFail,
  getDeviceOrderNextRequest,
  getDeviceOrderPreviousRequest,
  getDeviceOrderCustomerTypeRequest,
  getDeviceOrderCustomerTypeSuccess,
  getDeviceOrderCustomerTypeFail,
  getDeviceOrderDepartmentRequest,
  getDeviceOrderDepartmentSuccess,
  getDeviceOrderDepartmentFail,
  getDeviceOrderCategoryRequest,
  getDeviceOrderCategorySuccess,
  getDeviceOrderCategoryFail,
  createDeviceOrderRequest,
  createDeviceOrderSuccess,
  createDeviceOrderFail,
  deviceOrderEditSuccess,
  deviceOrderEditFail,
  deviceOrderDetailEditSuccess,
  deviceOrderDetailUpdateSuccess,
  deviceOrderDetailAddedSuccess,
  deviceOrderDetailDeleteSuccess,
  updateDeviceOrderRequest,
  updateDeviceOrderSuccess,
  updateDeviceOrderFail,
  clearDeviceOrderData,
  getDeviceOrderByIdRequest,
  //
  getDeviceOrderCustomerSuccess,
  getDeviceOrderCustomerFail,
  loadingDeviceOrderCustomerRequest,
  getDeviceOrderCustomerRequest,
  //
  getDeviceOrderItemSuccess,
  getDeviceOrderItemFail,
  loadingDeviceOrderItemRequest,
  getDeviceOrderItemRequest,
  getDeviceOrderItemCategorySuccess,
  getDeviceOrderItemCategoryFail,
  loadingDeviceOrderItemCategoryRequest,
  getDeviceOrderItemCategoryRequest,
  //
  updateDeviceReceivedStatusRequest,
  updateDeviceReceivedStatusSuccess,
  updateDeviceReceivedStatusFail,
  //
  updateDeviceDispatchedStatusRequest,
  updateDeviceDispatchedStatusSuccess,
  updateDeviceDispatchedStatusFail,
  //
  loadingDeviceOrderReferrerRequest,
  getDeviceOrderReferrerRequest,
  getDeviceOrderReferrerSuccess,
  getDeviceOrderReferrerFail,
} = DeviceOrder.actions;
export default DeviceOrder.reducer;
