import { createAction, createSlice } from "@reduxjs/toolkit";

const initialState: any = {
  referrals: [],
  edit: false,
  referral: null,
  loadingReferral: false,
  loadingCreateReferral:false,
  count: 0,
  next: "",
  previous: "",
};

export const updateReferralRequest = createAction(
  "updateReferralRequest",
  function prepare({ values, id, rowsPerPage, page }) {
    return {
      payload: {
        values,
        id,
        rowsPerPage,
        page,
      },
    };
  }
);
export const getReferralRequest = createAction(
  "getReferralRequest",
  function prepare({ rowsPerPage, page, filterData }) {
    return {
      payload: {
        rowsPerPage,
        page,
        filterData,
      },
    };
  }
);
// get next request
export const getReferralNextRequest = createAction(
  "getReferralNextRequest",
  function prepare(next) {
    return {
      payload: next,
    };
  }
);
//get previous request
export const getReferralPreviousRequest = createAction(
  "getReferralPreviousRequest",
  function prepare(previous) {
    return {
      payload: previous,
    };
  }
);
export const Referral = createSlice({
  name: "ReferralReducer",
  initialState,
  reducers: {
    loadingReferral: (state) => {
      state.loadingReferral = true;
    },
    getReferralSuccess: (
      state,
      { payload: { results, count, next, previous } }
    ) => {
      state.loadingReferral = false;
      state.referrals = results;
      state.count = count;
      state.next = next;
      state.previous = previous;
    },
    getReferralFail: (state) => {
      state.loadingReferral = false;
    },
    createReferralRequest: (state, payload) => {
      state.loadingCreateReferral = true;
      state.closeModal = true;
    },
    createReferralSuccess: (state) => {
      state.loadingCreateReferral = false;
    },
    createReferralFail: (state) => {
      state.loadingCreateReferral = false;
    },
    getReferralByIdRequest: (state, payload) => {
      state.edit = true;
    },
    referralEditSuccess: (state, { payload }) => {
      state.referral = payload;
    },
    referralEditFail: (state) => {
      state.edit = false;
    },
    updateReferralSuccess: (state) => {
      state.loadingCreateReferral = false;
    },
    updateReferralFail: (state) => {
      state.loadingCreateReferral = false;
    },
    clearReferralData: (state) => {
      state.edit = false;
      state.referral = null;
    },
  },
});
// Action creators are generated for each case reducer function
export const {
  loadingReferral,
  getReferralSuccess,
  getReferralFail,
  createReferralRequest,
  createReferralSuccess,
  createReferralFail,
  referralEditSuccess,
  updateReferralSuccess,
  updateReferralFail,
  clearReferralData,
  getReferralByIdRequest,
  referralEditFail,
} = Referral.actions;
export default Referral.reducer;
