import {
  createTestFail,
  createTestRequest,
  createTestSuccess,
  getTestSuccess,
  getTestFail,
  getTestCategoryRequest,
  getTestCategorySuccess,
  getTestCategoryFail,
  getTestDepartmentRequest,
  getTestDepartmentSuccess,
  getTestDepartmentFail,
  getTestCustomerTypeRequest,
  getTestCustomerTypeSuccess,
  getTestCustomerTypeFail,
  updateTestFail,
  updateTestSuccess,
  getTestRequest,
  updateTestRequest,
  getTestNextRequest,
  getTestPreviousRequest,
  clearTestData,
  getTestByIdRequest,
  testEditSuccess,
  testEditFail,
} from "./testSlice";
import { mergeMap } from "rxjs";
import {
  createTest,
  getTest,
  updateTest,
  getTestCustomerType,
  getTestCategory,
  getTestDepartment,
  getTestById,
} from "./api";
import { map, filter, tap } from "rxjs/operators";
import { Observable } from "rxjs";
import { Action } from "@reduxjs/toolkit";
import { combineEpics } from "redux-observable";
import {
  alertErrorAction,
  alertSuccessAction,
  closeModal,
} from "../../../CommonAppRedux/CommonAppSlice";
import { getNext, getPrevious } from "../../../CommonAppRedux/api";
import {
  dispatchAction,
  stateAction,
} from "../../../../AppUtils/Utils/globalTypes";
import messages from "../../../../AppUtils/Utils/validationConstants";

export const controller = new AbortController();

// get test epic
const getTestEpic = (
  action$: Observable<Action>,
  _: stateAction,
  { dispatch }: dispatchAction
) =>
  action$.pipe(
    filter(getTestRequest.match),
    mergeMap(async (action) => {
      try {
        const response = await getTest(action.payload);
        return { payload: response.data };
      } catch (e) {
        return { error: e };
      }
    }),
    map((action) =>
      action?.payload ? getTestSuccess(action?.payload) : getTestFail()
    )
  );

//get next
const getTestNext = (
  action$: Observable<Action>,
  _: stateAction,
  { dispatch }: dispatchAction
) =>
  action$.pipe(
    filter(getTestNextRequest.match),
    mergeMap(async (action) => {
      try {
        const response = await getNext(action.payload);
        return { payload: response.data };
      } catch (e) {
        return { error: e };
      }
    }),
    map((action) =>
      action?.payload ? getTestSuccess(action?.payload) : getTestFail()
    )
  );

//get previous
const getTestPrevious = (
  action$: Observable<Action>,
  _: stateAction,
  { dispatch }: dispatchAction
) =>
  action$.pipe(
    filter(getTestPreviousRequest.match),
    mergeMap(async (action) => {
      try {
        const response = await getPrevious(action.payload);
        return { payload: response.data };
      } catch (e) {
        return { error: e };
      }
    }),
    map((action) =>
      action?.payload ? getTestSuccess(action?.payload) : getTestFail()
    )
  );
// get test customer type
const getAllTestCustomerType = (
  action$: Observable<Action>,
  _: stateAction,
  { dispatch }: dispatchAction
) =>
  action$.pipe(
    filter(getTestCustomerTypeRequest.match),
    mergeMap(async (action) => {
      try {
        const response = await getTestCustomerType(action.payload);
        return { payload: response.data };
      } catch (e) {
        return { error: e };
      }
    }),
    map((action) =>
      action?.payload
        ? getTestCustomerTypeSuccess(action?.payload)
        : getTestCustomerTypeFail()
    )
  );
// get test department
const getAllTestDepartment = (
  action$: Observable<Action>,
  _: stateAction,
  { dispatch }: dispatchAction
) =>
  action$.pipe(
    filter(getTestDepartmentRequest.match),
    mergeMap(async (action) => {
      try {
        const response = await getTestDepartment(action.payload);
        return { payload: response.data };
      } catch (e) {
        return { error: e };
      }
    }),
    map((action) =>
      action?.payload
        ? getTestDepartmentSuccess(action?.payload)
        : getTestDepartmentFail()
    )
  );
// get test category
const getAllTestCategory = (
  action$: Observable<Action>,
  _: stateAction,
  { dispatch }: dispatchAction
) =>
  action$.pipe(
    filter(getTestCategoryRequest.match),
    mergeMap(async (action) => {
      try {
        const response = await getTestCategory(action.payload);
        return { payload: response.data };
      } catch (e) {
        return { error: e };
      }
    }),
    map((action) =>
      action?.payload
        ? getTestCategorySuccess(action?.payload)
        : getTestCategoryFail()
    )
  );
//create Test epic
const createTestEpic = (
  action$: Observable<Action>,
  _: stateAction,
  { dispatch }: dispatchAction
) =>
  action$.pipe(
    filter(createTestRequest.match),
    mergeMap(async ({ payload: { values, rowsPerPage, page } }) => {
      const {
        name,
        code,
        department,
        testCategory,
        displayOrder,
        testPrices,
        packageTest,
        fractionable,
        specimen,
        stability,
        turnaroundTime,
        active,
        remarks,
        reportFormatType,
        testType,
      } = values;
      try {
        const body = JSON.stringify({
          name,
          code,
          displayOrder,
          department: department?.id,
          testCategory: testCategory?.id,
          packageTest,
          fractionable,
          specimen,
          stability,
          turnaroundTime,
          reportFormatType: reportFormatType?.id,
          testPrices,
          active,
          remarks,
          testType: testType ? testType?.id : null,
        });

        const response = await createTest(body);
        if (response) {
          dispatch(getTestRequest({ rowsPerPage, page }));
          dispatch(alertSuccessAction(messages.createMessage));
          dispatch(closeModal());
          dispatch(clearTestData());
        }
        return { payload: { response } };
      } catch (e) {
        dispatch(alertErrorAction(messages.createFailMessage));
        return { error: e };
      }
    }),
    map((action) => {
      return action?.payload ? createTestSuccess() : createTestFail();
    })
  );

//update Test epic
const updateTestEpic = (
  action$: Observable<Action>,
  _: stateAction,
  { dispatch }: dispatchAction
) =>
  action$.pipe(
    filter(updateTestRequest.match),
    mergeMap(async ({ payload: { values, id, rowsPerPage, page } }) => {
      const {
        name,
        code,
        department,
        testCategory,
        displayOrder,
        packageTest,
        fractionable,
        specimen,
        stability,
        turnaroundTime,
        testPrices,
        active,
        remarks,
        reportFormatType,
        testType,
      } = values;
      try {
        const body = JSON.stringify({
          name,
          code,
          displayOrder,
          department: department?.id,
          testCategory: testCategory?.id,
          packageTest,
          fractionable,
          specimen,
          stability,
          turnaroundTime,
          testPrices,
          active,
          reportFormatType: reportFormatType?.id,
          remarks,
          testType: testType?.id,
        });
        const response = await updateTest(body, id);
        if (response) {
          dispatch(getTestRequest({ rowsPerPage, page }));
          dispatch(alertSuccessAction(messages.updateMessage));
          dispatch(clearTestData());
          dispatch(closeModal());
        }
        return { payload: { response, rowsPerPage } };
      } catch (e) {
        dispatch(alertErrorAction(messages.updateFailMessage));
        return { error: e };
      }
    }),
    map((action) => (action?.payload ? updateTestSuccess() : updateTestFail()))
  );

const getTestByIdEpic = (
  action$: Observable<Action>,
  _: stateAction,
  { dispatch }: dispatchAction
) =>
  action$.pipe(
    filter(getTestByIdRequest.match),
    mergeMap(async (action) => {
      try {
        const response = await getTestById(action.payload);
        return { payload: response.data };
      } catch (e) {
        return { error: e };
      }
    }),
    map((action) =>
      action?.payload ? testEditSuccess(action.payload) : testEditFail()
    )
  );

export const testEpics = combineEpics(
  getTestEpic,
  createTestEpic,
  updateTestEpic,
  getTestNext,
  getTestPrevious,
  getAllTestCustomerType,
  getAllTestDepartment,
  getAllTestCategory,
  getTestByIdEpic
);
