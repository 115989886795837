import { createSlice, createAction } from "@reduxjs/toolkit";
import { CustomerWiseClinicReportInitialState } from "./types";
const initialState: CustomerWiseClinicReportInitialState = {
  users: [],
  customerWiseClinicDetails: [],
  //loading 
  loadingGenerateReport: false,
  loadingCustomerWiseClinicReportUser: false,
  loadingCustomerWiseClinicReportDetail: false,
  count: 0,
  previous: "",
  next: "",
};

// export const getCustomerWiseClinicReportDetailRequest = createAction(
//   "getCustomerWiseClinicReportDetailRequest",
//   function prepare({ detailsRowsPerPage, page }) {
//     return {
//       payload: {
//         detailsRowsPerPage,
//         page,
//       },
//     };
//   }
// );
export const CustomerWiseClinicReport = createSlice({
  name: "CustomerWiseClinicReportReducer",
  initialState,
  reducers: {
    //loading
    loadingCustomerWiseClinicReportDetail: (state) => {
      state.loadingCustomerWiseClinicReportDetail = true;
    },
    //external reducer request
    getCustomerWiseClinicReportDetailRequest: (state, { payload }) => {
      state.loadingCustomerWiseClinicReportDetail = true;
    },
    getCustomerWiseClinicReportUserRequest: (state) => {
      state.loadingCustomerWiseClinicReportUser = true;
    },
    getCustomerWiseClinicReportAllSummaryRequest: (state, { payload }) => {
      state.loadingGenerateReport = true;
    },
    getCustomerWiseClinicReportAllDetailRequest: (state, { payload }) => {
      state.loadingGenerateReport = true;
    },
    getCustomerWiseClinicReportQuickSummaryRequest: (state, { payload }) => {
      state.loadingGenerateReport = true;
    },
    getCustomerWiseClinicReportQuickDetailRequest: (state, { payload }) => {
      state.loadingGenerateReport = true;
    },
    getCustomerWiseClinicReportNextRequest: (state, payload) => {
      state.loadingCustomerWiseClinicReportDetail = true;
    },
    getCustomerWiseClinicReportPreviousRequest: (state, payload) => {
      state.loadingCustomerWiseClinicReportDetail = true;
    },
    //external get reducer  
    getCustomerWiseClinicReportDetailSuccess: (
      state,
      { payload: { results, count, next, previous } }
    ) => {
      state.customerWiseClinicDetails = results;
      state.loadingCustomerWiseClinicReportDetail = false;
      state.count = count;
      state.next = next;
      state.previous = previous;
    },
    getCustomerWiseClinicReportDetailFail: (state) => {
      state.loadingCustomerWiseClinicReportDetail = false;
    },
    getCustomerWiseClinicReportUserSuccess: (state, { payload: { results } }) => {
      state.loadingCustomerWiseClinicReportUser = false;
      state.users = results;
    },
    getCustomerWiseClinicReportUserFail: (state) => {
      state.loadingCustomerWiseClinicReportUser = false;
    },
    getCustomerWiseClinicReportAllSummarySuccess: (state, { payload: { results } }) => {
      state.loadingGenerateReport = false;
      state.customerWiseClinicDetails = results;
    },
    getCustomerWiseClinicReportAllSummaryFail: (state) => {
      state.loadingGenerateReport = false;
    },
    getCustomerWiseClinicReportQuickSummarySuccess: (
      state,
      { payload: { results, count, next, previous } }
    ) => {
      state.loadingGenerateReport = false;
      state.customerWiseClinicDetails = results;
      state.count = count;
      state.next = next;
      state.previous = previous;
    },
    getCustomerWiseClinicReportQuickSummaryFail: (state) => {
      state.loadingGenerateReport = false;
    },
    getCustomerWiseClinicReportAllDetailSuccess: (state, { payload: { results } }) => {
      state.loadingGenerateReport = false;
      state.customerWiseClinicDetails = results;
    },
    getCustomerWiseClinicReportAllDetailFail: (state) => {
      state.loadingGenerateReport = false;
    },
    getCustomerWiseClinicReportQuickDetailSuccess: (
      state,
      { payload: { results, count, next, previous } }
    ) => {
      state.loadingGenerateReport = false;
      state.customerWiseClinicDetails = results;
      state.count = count;
      state.next = next;
      state.previous = previous;
    },
    getCustomerWiseClinicReportQuickDetailFail: (state) => {
      state.loadingGenerateReport = false;
    },

    clearCustomerWiseClinicReportData: (state) => {
      state.users = [];
    },
    clearPrintUserActivityLogData: (state) => {
      state.customerWiseClinicDetails = [];
    },
  },
});
// Action creators are generated for each case reducer function
export const {
  //reducerReport
  getCustomerWiseClinicReportDetailRequest,
  getCustomerWiseClinicReportUserRequest,
  getCustomerWiseClinicReportAllSummaryRequest,
  getCustomerWiseClinicReportAllDetailRequest,
  getCustomerWiseClinicReportQuickSummaryRequest,
  getCustomerWiseClinicReportQuickDetailRequest,
  getCustomerWiseClinicReportPreviousRequest,
  getCustomerWiseClinicReportNextRequest,
  //reducer get data
  getCustomerWiseClinicReportDetailSuccess,
  getCustomerWiseClinicReportDetailFail,
  getCustomerWiseClinicReportUserSuccess,
  getCustomerWiseClinicReportUserFail,
  getCustomerWiseClinicReportQuickDetailSuccess,
  getCustomerWiseClinicReportQuickDetailFail,
  getCustomerWiseClinicReportAllDetailSuccess,
  getCustomerWiseClinicReportAllDetailFail,
  getCustomerWiseClinicReportQuickSummarySuccess,
  getCustomerWiseClinicReportQuickSummaryFail,
  getCustomerWiseClinicReportAllSummarySuccess,
  getCustomerWiseClinicReportAllSummaryFail,
  //loading
  loadingCustomerWiseClinicReportDetail,
  //clear
  clearCustomerWiseClinicReportData,
  clearPrintUserActivityLogData,
} = CustomerWiseClinicReport.actions;
export default CustomerWiseClinicReport.reducer;
