import { createAction, createSlice } from "@reduxjs/toolkit";

const initialState: any = {
  testFindings: [],
  edit: false,
  testFinding: null,
  loadingTestFinding: false,
  loadingCreateTestFinding: false,
  tests: [],
  searchData: [],
  referenceRanges: [],
  count: 0,
  next: "",
  previous: "",
  loadingTestFindingTest: false,
  loadingTestFindingReferenceRange: false,
};
export const getTestFindingReferenceRangeRequest = createAction(
  "getTestFindingReferenceRangeRequest",
  function prepare(rowsPerPage) {
    return {
      payload: rowsPerPage,
    };
  }
);
export const getAllTestFindingTestRequest = createAction(
  "getAllTestFindingTestRequest",
  function prepare(rowsPerPage) {
    return {
      payload: rowsPerPage,
    };
  }
);

export const updateTestFindingRequest = createAction(
  "updateTestFindingRequest",
  function prepare({ values, id, rowsPerPage, page }) {
    return {
      payload: {
        values,
        id,
        rowsPerPage,
        page,
      },
    };
  }
);
export const getTestFindingRequest = createAction(
  "getTestFindingRequest",
  function prepare({ rowsPerPage, page, filterData }) {
    return {
      payload: {
        rowsPerPage,
        page,
        filterData,
      },
    };
  }
);
// get next request
export const getTestFindingNextRequest = createAction(
  "getTestFindingNextRequest",
  function prepare(next) {
    return {
      payload: next,
    };
  }
);
//get previous request
export const getTestFindingPreviousRequest = createAction(
  "getTestFindingPreviousRequest",
  function prepare(previous) {
    return {
      payload: previous,
    };
  }
);
export const TestFinding = createSlice({
  name: "TestFindingReducer",
  initialState,
  reducers: {
    loadingTestFinding: (state) => {
      state.loadingTestFinding = true;
    },
    getTestFindingSuccess: (
      state,
      { payload: { results, count, next, previous } }
    ) => {
      state.loadingTestFinding = false;
      state.testFindings = results;
      state.count = count;
      state.next = next;
      state.previous = previous;
    },
    getTestFindingTestSuccess: (state, { payload: { results } }) => {
      state.tests = results;
      state.loadingTestFindingTest = false;
    },
    getTestFindingTestFail: (state) => {
      state.loading = false;
    },
    getTestFindingSearchDataSuccess: (state, { payload: { results } }) => {
      state.loading = true;
      state.searchData = results;
    },
    getTestFindingSearchDataFail: (state) => {
      state.loading = false;
    },
    getTestFindingReferenceRangeSuccess: (state, { payload: { results } }) => {
      state.loading = true;
      state.referenceRanges = results;
      state.loadingTestFindingReferenceRange = false;
    },
    getTestFindingReferenceRangeFail: (state) => {
      state.loading = false;
    },
    getTestFindingFail: (state) => {
      state.loadingTestFinding = false;
    },
    createTestFindingRequest: (state, payload) => {
      state.loadingCreateTestFinding = true;
      state.closeModal = true;
    },
    createTestFindingSuccess: (state) => {
      state.loadingCreateTestFinding = false;
    },
    createTestFindingFail: (state) => {
      state.loadingCreateTestFinding = false;
    },

    updateTestFindingSuccess: (state) => {
      state.loadingCreateTestFinding = false;
    },
    updateTestFindingFail: (state) => {
      state.loadingCreateTestFinding = false;
    },
    //loading
    loadingTestFindingTestRequest: (state) => {
      state.loadingTestFindingTest = true;
    },
    loadingTestFindingReferenceRangeRequest: (state) => {
      state.loadingTestFindingReferenceRange = true;
    },
    clearTestFindingData: (state) => {
      state.edit = false;
      state.testFinding = null;
    },
    getTestFindingByIdRequest: (state, payload) => {
      state.edit = true;
    },
    testFindingEditSuccess: (state, { payload }) => {
      state.testFinding = payload;
    },
    testFindingEditFail: (state) => {
      state.edit = false;
    },
  },
});
// Action creators are generated for each case reducer function
export const {
  loadingTestFindingTestRequest,
  loadingTestFindingReferenceRangeRequest,
  loadingTestFinding,
  getTestFindingSuccess,
  getTestFindingFail,
  createTestFindingRequest,
  createTestFindingSuccess,
  createTestFindingFail,
  testFindingEditSuccess,
  updateTestFindingSuccess,
  updateTestFindingFail,
  clearTestFindingData,
  getTestFindingTestSuccess,
  getTestFindingTestFail,
  getTestFindingReferenceRangeSuccess,
  getTestFindingReferenceRangeFail,
  getTestFindingSearchDataSuccess,
  getTestFindingSearchDataFail,
  getTestFindingByIdRequest,
  testFindingEditFail,
} = TestFinding.actions;
export default TestFinding.reducer;
