import { filterApiData } from "../../../../AppUtils/Utils/appFunctions";
import { axiosInstance } from "../../../../AppUtils/Utils/axios";

export const serviceOrderModURL = "/api/v1/appointment-app/clinic-order";
//get ServiceOrder data api
export const getServiceOrder = (data: any) => {
  const { rowsPerPage, page } = data;
  return axiosInstance.get(
    `${serviceOrderModURL}?offset=${rowsPerPage * page}&limit=${rowsPerPage}${
      data?.filterData ? filterApiData(data?.filterData) : ""
    }`
  );
};
export const getServiceOrderCustomerType = ({ rowsPerPage }: any) => {
  return axiosInstance.get(
    `${serviceOrderModURL}/customer-type?offset=0&limit=${rowsPerPage}`
  );
};
export const getServiceOrderDepartment = (rowsPerPage: number) => {
  return axiosInstance.get(
    `${serviceOrderModURL}/department?offset=0&limit=${rowsPerPage}`
  );
};
export const getServiceOrderCategory = ({ rowsPerPage }: any) => {
  return axiosInstance.get(
    `${serviceOrderModURL}/clinicorder-category?offset=0&limit=${rowsPerPage}`
  );
};
//handle search
export const searchServiceOrder = ({id, rowsPerPage, search }: any) => {
  return `${serviceOrderModURL}?customer=${id}&offset=0&limit=${rowsPerPage}&search=${search}`;
};
//create ServiceOrder
export const createServiceOrder = (body: string | FormData) =>
  axiosInstance.post(`${serviceOrderModURL}`, body);
//update ServiceOrder
export const updateServiceOrder = (body: string | FormData, id: number) =>
  axiosInstance.patch(`${serviceOrderModURL}/${id}`, body);
//
export const getServiceOrderById = (id: number) => {
  return axiosInstance.get(`${serviceOrderModURL}/${id}`);
};

//
export const getServiceOrderItem = (rowsPerPage:number) =>
axiosInstance.get(`${serviceOrderModURL}/item?offset=0&limit=${rowsPerPage}`);
// export const getServiceOrderCustomer = (rowsPerPage:number) =>
// axiosInstance.get(`${serviceOrderModURL}/customer?offset=0&limit=${rowsPerPage}`);
