import React from "react";
interface SVGProps {
  src: string;
  className?: string;
}
const SVGImage = ({ src, className }: SVGProps) => {
  return (
    <div className={className}>
      <img src={src} alt="" width="20px" height="24px" />
    </div>
  );
};

export default SVGImage;
