import { axiosInstance } from "../../../../../AppUtils/Utils/axios";
import { filterApiData } from "../../../../../AppUtils/Utils/appFunctions";
import {
  getApiProps,
  searchProps,
} from "../../../../../AppUtils/Utils/globalTypes";
import { TotalPatientStatReport } from "./types";

const TotalPatientStatReportModURL = '/api/v1/clinic-financial-report-app';
const TotalPatientClinicStatReportModURL = '/api/v1/clinic-statistical-report-app';



export const getTotalPatientStatReportSupplier = () =>
axiosInstance.get(`${TotalPatientStatReportModURL}/supplier?offset=0&limit=${0}`);
export const getTotalPatientStatReportUser = () =>
axiosInstance.get(`${TotalPatientStatReportModURL}/user?offset=0&limit=${0}`);
export const getTotalPatientStatReportItem = () =>
axiosInstance.get(`${TotalPatientStatReportModURL}/item?offset=0&limit=${0}`);
// export const getTotalPatientStatReportAllReport = (data:any) =>{
//   const {updatedValue} = data;
//   const {
//     summary,
//     detail,
//     user,
//     startDateAd,
//     endDateAd,
//   } = updatedValue;
//   return axiosInstance.get(
//     `${TotalPatientStatReportModURL}?offset=0&limit=${0}${
//       data?.filterData ? filterApiData(data?.filterData) : ""
//     }&date_after=${startDateAd}&date_before=${endDateAd}`
//   )};


// export const getTotalPatientStatReportQuickReport = (data: any) => {
//   const { rowsPerPage, page ,updatedValue} = data;
//   const {
//     summary,
//     detail,
//     user,
//     startDateAd,
//     endDateAd,
//   } = updatedValue;
//   return axiosInstance.get(
//     `${TotalPatientStatReportModURL}?offset=${
//       rowsPerPage * page
//     }&limit=${rowsPerPage}${
//       data?.filterData ? filterApiData(data?.filterData) : ""
//     }&date_after=${startDateAd}&date_before=${endDateAd}`
//   );
// };

// export const searchTotalPatientStatReport = ({ rowsPerPage, search ,updatedValue}: any) => {
//   const {
//     summary,
//     detail,
//     user,
//     startDateAd,
//     endDateAd,
//   } = updatedValue;
//   return `${TotalPatientStatReportModURL}?offset=0&limit=${rowsPerPage}&search=${search}&date_after=${startDateAd}&date_before=${endDateAd}`;
// };

///
//
export const getTotalPatientOpdStatReportSummary = (data:any) =>{
  const {updatedValue} = data;
  const {
    summary,
    detail,
    user,
    startDateAd,
    endDateAd,
  } = updatedValue;
  return axiosInstance.get(
    `${TotalPatientClinicStatReportModURL}/opd-stat-report/summary?offset=0&limit=${0}&date_after=${startDateAd}&date_before=${endDateAd}`
  )};
  export const getTotalPatientOpdRaceGroupStatReport = (data:any) =>{
    const {updatedValue} = data;
    const {
      summary,
      detail,
      user,
      startDateAd,
      endDateAd,
    } = updatedValue;
    return axiosInstance.get(
      `${TotalPatientClinicStatReportModURL}/customer-race-group-report?offset=0&limit=${0}&date_after=${startDateAd}&date_before=${endDateAd}`
    )};

    export const getTotalPatientOpdEthinicityStatReport = (data:any) =>{
      const {updatedValue} = data;
      const {
        summary,
        detail,
        user,
        startDateAd,
        endDateAd,
      } = updatedValue;
      return axiosInstance.get(
        `${TotalPatientClinicStatReportModURL}/ethnicity-service-report?offset=0&limit=${0}&date_after=${startDateAd}&date_before=${endDateAd}`
      )};


      
export const getTotalPatientStatJaatiType = () =>
axiosInstance.get(`${TotalPatientClinicStatReportModURL}/jaati-type?offset=0&limit=${0}`);