import { axiosInstance } from "../../../../AppUtils/Utils/axios";
import { filterApiData } from "../../../../AppUtils/Utils/appFunctions";
import {
  getApiProps,
  searchProps,
} from "../../../../AppUtils/Utils/globalTypes";

export const testModURL = "/api/v1/lab-app/test";
//get Test data api
export const getTest = (data: getApiProps) => {
  const { rowsPerPage, page } = data;
  return axiosInstance.get(
    `${testModURL}?offset=${rowsPerPage * page}&limit=${rowsPerPage}${
      data?.filterData ? filterApiData(data?.filterData) : ""
    }`
  );
};
export const getTestCustomerType = ({ rowsPerPage }: any) => {
  return axiosInstance.get(
    `${testModURL}/customer-type?offset=0&limit=${rowsPerPage}`
  );
};
export const getTestDepartment = (rowsPerPage: number) => {
  return axiosInstance.get(
    `${testModURL}/department?offset=0&limit=${rowsPerPage}`
  );
};
export const getTestCategory = ({ rowsPerPage }: any) => {
  return axiosInstance.get(
    `${testModURL}/test-category?offset=0&limit=${rowsPerPage}`
  );
};
//handle search
export const searchTest = ({ rowsPerPage, search }: searchProps) => {
  return `${testModURL}?offset=0&limit=${rowsPerPage}&search=${search}`;
};
//create Test
export const createTest = (body: string | FormData) =>
  axiosInstance.post(`${testModURL}`, body);
//update Test
export const updateTest = (body: string | FormData, id: number) =>
  axiosInstance.patch(`${testModURL}/${id}`, body);
//
export const getTestById = (id: number) => {
  return axiosInstance.get(`${testModURL}/${id}`);
};
