import { axiosInstance } from "../../../../AppUtils/Utils/axios";
import { filterApiData } from "../../../../AppUtils/Utils/appFunctions";
import {
  getApiProps,
  searchProps,
} from "../../../../AppUtils/Utils/globalTypes";

const reportCommentModURL = "/api/v1/lab-app/report-comment";

export const getReportComment = (data: getApiProps) => {
  const { rowsPerPage, page } = data;
  return axiosInstance.get(
    `${reportCommentModURL}?offset=${rowsPerPage * page}&limit=${rowsPerPage}${
      data?.filterData ? filterApiData(data?.filterData) : ""
    }`
  );
};
export const searchReportComment = ({ rowsPerPage, search }: searchProps) => {
  return `${reportCommentModURL}?offset=0&limit=${rowsPerPage}&search=${search}`;
};
export const createReportComment = (body: string | FormData) =>
  axiosInstance.post(`${reportCommentModURL}`, body);
//update Store
export const updateReportComment = (body: string | FormData, id: number) =>
  axiosInstance.patch(`${reportCommentModURL}/${id}`, body);

export const getAllReportCommentForDefault = () =>
  axiosInstance.get<any>(`${reportCommentModURL}`);
