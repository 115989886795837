import { axiosInstance } from "../../../../AppUtils/Utils/axios";
import { filterApiData } from "../../../../AppUtils/Utils/appFunctions";
import {
  getApiProps,
  searchProps,
} from "../../../../AppUtils/Utils/globalTypes";

const packageTestModURL = "/api/v1/lab-app/package-test";

//get PackageTest data api
export const getPackageTest = (data: getApiProps) => {
  const { rowsPerPage, page } = data;
  return axiosInstance.get(
    `${packageTestModURL}?offset=${rowsPerPage * page}&limit=${rowsPerPage}${
      data?.filterData ? filterApiData(data?.filterData) : ""
    }`
  );
};
export const getPackageTestTest = ({ rowsPerPage }: any) => {
  return axiosInstance.get(
    `${packageTestModURL}/test?offset=0&limit=${rowsPerPage}`
  );
};
export const getSpecificTest = ({ rowsPerPage, department }: any) => {
  return axiosInstance.get(
    `${packageTestModURL}/test?department=${department}&offset=0&limit=${rowsPerPage}`
  );
};

export const getPackageTestDepartment = ({ rowsPerPage }: any) => {
  return axiosInstance.get(
    `${packageTestModURL}/department?offset=0&limit=${rowsPerPage}`
  );
};
export const getPackageTestList = ({ rowsPerPage }: any) => {
  return axiosInstance.get(
    `${packageTestModURL}/package-test-list?offset=0&limit=${rowsPerPage}`
  );
};
//handle search
export const searchPackageTest = ({ rowsPerPage, search }: searchProps) => {
  return `${packageTestModURL}?offset=0&limit=${rowsPerPage}&search=${search}`;
};
//create PackageTest
export const createPackageTest = (body: string | FormData) =>
  axiosInstance.post(`${packageTestModURL}`, body);
//update PackageTest
export const updatePackageTest = (body: string | FormData, id: number) =>
  axiosInstance.patch(`${packageTestModURL}/${id}`, body);
//get package test by id
export const getPackageTestById = (id: number) =>
  axiosInstance.get(`${packageTestModURL}/${id}`);
