import {
  getSiteSetupRequest,
  getSiteSetupFail,
  getSiteSetupSuccess,
  createSiteSetupFail,
  createSiteSetupRequest,
  createSiteSetupSuccess,
  updateSiteSetupFail,
  updateSiteSetupSuccess,
  clearAllData,
  getSiteSetupNextRequest,
  getSiteSetupPreviousRequest,
  updateSiteSetupRequest,
  getSiteSetupByIdRequest,
  siteSetupEditSuccess,
  siteSetupEditFail,
} from "./siteSetupSlice";
import { mergeMap } from "rxjs";
import { getSiteSetup, createSiteSetup, updateSiteSetup, getSiteSetupById } from "./api";
import { map, filter } from "rxjs/operators";
import { Observable } from "rxjs";
import { Action } from "@reduxjs/toolkit";
import { combineEpics } from "redux-observable";
import {
  alertErrorAction,
  alertSuccessAction,
  closeModal,
} from "../../../CommonAppRedux/CommonAppSlice";
import { getNext, getPrevious } from "../../../CommonAppRedux/api";
import {
  dispatchAction,
  stateAction,
} from "../../../../AppUtils/Utils/globalTypes";
import messages from "../../../../AppUtils/Utils/validationConstants";
export const controller = new AbortController();

//get SiteSetup
const getSiteSetupEpic = (
  action$: Observable<Action>,
  _: stateAction,
  { dispatch }: dispatchAction
) =>
  action$.pipe(
    filter(getSiteSetupRequest.match),
    mergeMap(async (action: any) => {
      try {
        const response = await getSiteSetup(action.payload);
        return { payload: response.data };
      } catch (e) {
        return { error: e };
      }
    }),
    map((action) =>
      action?.payload ? getSiteSetupSuccess(action?.payload) : getSiteSetupFail()
    )
  );

//get siteSetup by id epic
const getSiteSetupByIdEpic = (
  action$: Observable<Action>,
  _: stateAction,
  { dispatch }: dispatchAction
) =>
  action$.pipe(
    filter(getSiteSetupByIdRequest.match),
    mergeMap(async (action) => {
      try {
        const response = await getSiteSetupById(action.payload);
        return { payload: response.data };
      } catch (e) {
        return { error: e };
      }
    }),
    map((action) =>
      action?.payload ? siteSetupEditSuccess(action.payload) : siteSetupEditFail()
    )
  );

//create
const createSiteSetupEpic = (
  action$: Observable<Action>,
  _: stateAction,
  { dispatch }: dispatchAction
) =>
  action$.pipe(
    filter(createSiteSetupRequest.match),
    mergeMap(async ({ payload: { values, rowsPerPage, page } }) => {
      try {
        const body = new FormData();
        for (let [key, value] of Object.entries(values)) {
          body.append(`${key}`, String(value));
        }
        const response = await createSiteSetup(body);
        if (response) {
          dispatch(getSiteSetupRequest({ rowsPerPage, page }));
          dispatch(alertSuccessAction(messages.createMessage));
          dispatch(closeModal());
        }
        return { payload: { response } };
      } catch (e) {
        dispatch(alertErrorAction(messages.createFailMessage));
        return { error: e };
      }
    }),
    map((action) => {
      return action?.payload ? createSiteSetupSuccess() : createSiteSetupFail();
    })
  );

//update
const updateSiteSetupEpic = (
  action$: Observable<Action>,
  _: stateAction,
  { dispatch }: dispatchAction
) =>
  action$.pipe(
    filter(updateSiteSetupRequest.match),
    mergeMap(async ({ payload: { values, id, rowsPerPage, page, search } }) => {
      try {
        const body = new FormData();
        for (let [key, value] of Object.entries(values)) {
          body.append(`${key}`, String(value));
        }
        const response = await updateSiteSetup(body, id);
        if (response) {
          dispatch(getSiteSetupRequest({ rowsPerPage, page, search }));
          dispatch(alertSuccessAction(messages.updateMessage));
          dispatch(closeModal());
          dispatch(clearAllData());
        }
        return { payload: { response, rowsPerPage } };
      } catch (e) {
        dispatch(alertErrorAction(messages.updateFailMessage));
        return { error: e };
      }
    }),
    map((action) => {
      return action?.payload ? updateSiteSetupSuccess() : updateSiteSetupFail();
    })
  );

//get next
const getSiteSetupNext = (
  action$: Observable<Action>,
  _: stateAction,
  { dispatch }: dispatchAction
) =>
  action$.pipe(
    filter(getSiteSetupNextRequest.match),
    mergeMap(async (action) => {
      try {
        const response = await getNext(action.payload);
        return { payload: response.data };
      } catch (e) {
        return { error: e };
      }
    }),
    map((action) => {
      return action?.payload ? getSiteSetupSuccess(action?.payload) : getSiteSetupFail();
    })
  );

//get previous
const getSiteSetupPrevious = (
  action$: Observable<Action>,
  _: stateAction,
  { dispatch }: dispatchAction
) =>
  action$.pipe(
    filter(getSiteSetupPreviousRequest.match),
    mergeMap(async (action) => {
      try {
        const response = await getPrevious(action.payload);
        return { payload: response.data };
      } catch (e) {
        return { error: e };
      }
    }),
    map((action) =>
      action?.payload ? getSiteSetupSuccess(action?.payload) : getSiteSetupFail()
    )
  );

export const siteSetupEpic = combineEpics(
  getSiteSetupEpic,
  createSiteSetupEpic,
  updateSiteSetupEpic,
  getSiteSetupNext,
  getSiteSetupPrevious,
  getSiteSetupByIdEpic
);
