import { createSlice, createAction } from "@reduxjs/toolkit";
import { referenceRange } from "./types";
const initialState: any = {
  referenceRanges: [],
  loadingReferenceRange: false,
  loadingCreateReferenceRange: false,
  loadingReferenceRangeDepartment: false,
  loadingReferenceRangeTest: false,
  loadingReferenceRangeTestMethod: false,
  loadingReferenceRangeTestUnit: false,
  edit: false,
  referenceRange: null,
  tests: [],
  departments: [],
  testUnits: [],
  methods: [],
  count: 0,
  previous: "",
  next: "",
};

export const ReferenceRange = createSlice({
  name: "Reference Range Reducer",
  initialState,
  reducers: {
    loadingReferenceRange: (state) => {
      state.loadingReferenceRange = true;
    },
    getReferenceRangeRequest: (state, payload) => {
      state.loadingReferenceRange = true;
    },
    getReferenceRangeSuccess: (
      state,
      { payload: { results, count, next, previous } }
    ) => {
      state.loadingReferenceRange = false;
      state.referenceRanges = results;
      state.count = count;
      state.next = next;
      state.previous = previous;
    },
    getReferenceRangeFail: (state) => {
      state.loadingReferenceRange = false;
    },
    getReferenceRangeNextRequest: (state, payload) => {
      state.loadingReferenceRange = true;
    },
    getReferenceRangePreviousRequest: (state, payload) => {
      state.loadingReferenceRange = true;
    },
    // get departments
    loadingReferenceRangeDepartmentReq: (state) => {
      state.loadingReferenceRangeDepartment = true;
    },
    getReferenceRangeDepartmentRequest: (state, payload) => {
      state.loadingReferenceRangeDepartment = true;
    },
    getReferenceRangeDepartmentSuccess: (state, { payload: { results } }) => {
      state.loadingReferenceRangeDepartment = false;
      state.departments = results;
    },
    getReferenceRangeDepartmentFail: (state) => {
      state.loadingReferenceRangeDepartment = false;
    },
    // get tests
    loadingReferenceRangeTestReq: (state) => {
      state.loadingReferenceRangeTest = true;
    },
    getReferenceRangeTestRequest: (state, payload) => {
      state.loadingReferenceRangeTest = true;
    },
    getReferenceRangeTestSuccess: (state, { payload: { results } }) => {
      state.loadingReferenceRangeTest = false;
      state.tests = results;
    },
    getReferenceRangeTestFail: (state) => {
      state.loadingReferenceRangeTest = false;
    },
    // get filtered tests from department
    getReferenceFilteredTestRequest: (state, payload) => {
      state.loadingReferenceRangeTest = true;
    },
    getReferenceFilteredTestSuccess: (state, { payload: { results } }) => {
      state.loadingReferenceRangeTest = false;
      state.tests = results;
    },
    getReferenceFilteredTestFail: (state) => {
      state.loadingReferenceRangeTest = false;
    },
    // get test units
    loadingReferenceRangeTestUnitReq: (state) => {
      state.loadingReferenceRangeTestUnit = true;
    },
    getReferenceRangeTestUnitRequest: (state, payload) => {
      state.loadingReferenceRangeTestUnit = true;
    },
    getReferenceRangeTestUnitSuccess: (state, { payload: { results } }) => {
      state.loadingReferenceRangeTestUnit = false;
      state.testUnits = results;
    },
    getReferenceRangeTestUnitFail: (state) => {
      state.loadingReferenceRangeTestUnit = false;
    },
    // get methods
    loadingReferenceRangeTestMethodReq: (state) => {
      state.loadingReferenceRangeTestMethod = true;
    },
    getReferenceRangeTestMethodRequest: (state, payload) => {
      state.loadingReferenceRangeTestMethod = true;
    },
    getReferenceRangeTestMethodSuccess: (state, { payload: { results } }) => {
      state.loadingReferenceRangeTestMethod = false;
      state.methods = results;
    },
    getReferenceRangeTestMethodFail: (state) => {
      state.loadingReferenceRangeTestMethod = false;
    },
    // create reference range
    createReferenceRangeRequest: (state, payload) => {
      state.loadingCreateReferenceRange = true;
      state.closeModal = true;
    },
    createReferenceRangeSuccess: (state) => {
      state.loadingCreateReferenceRange = false;
    },
    createReferenceRangeFail: (state) => {
      state.loadingCreateReferenceRange = false;
    },
    getReferenceRangeByIdRequest: (state, payload) => {
      state.edit = true;
    },
    referenceRangeEditSuccess: (state, { payload }) => {
      state.referenceRange = payload;
    },
    referenceRangeEditFail: (state) => {
      state.edit = false;
    },
    updateReferenceRangeRequest: (state, payload) => {
      state.loadingCreateReferenceRange = true;
    },
    updateReferenceRangeSuccess: (state) => {
      state.loadingCreateReferenceRange = false;
    },
    updateReferenceRangeFail: (state) => {
      state.loadingCreateReferenceRange = false;
    },
    clearReferenceRangeData: (state) => {
      state.edit = false;
      state.referenceRange = null;
      state.loadingReferenceRange = false;
    },
    clearAllReferenceRangeData: (state) => {
      state.edit = false;
      state.referenceRanges = [];
      state.loadingReferenceRange = false;
    },
  },
});
// Action creators are generated for each case reducer function
export const {
  loadingReferenceRange,
  getReferenceRangeRequest,
  getReferenceRangeSuccess,
  getReferenceRangeFail,
  getReferenceRangeNextRequest,
  getReferenceRangePreviousRequest,
  loadingReferenceRangeDepartmentReq,
  getReferenceRangeDepartmentRequest,
  getReferenceRangeDepartmentSuccess,
  getReferenceRangeDepartmentFail,
  loadingReferenceRangeTestReq,
  getReferenceRangeTestRequest,
  getReferenceRangeTestSuccess,
  getReferenceRangeTestFail,
  getReferenceFilteredTestRequest,
  getReferenceFilteredTestSuccess,
  getReferenceFilteredTestFail,
  loadingReferenceRangeTestUnitReq,
  getReferenceRangeTestUnitRequest,
  getReferenceRangeTestUnitSuccess,
  getReferenceRangeTestUnitFail,
  loadingReferenceRangeTestMethodReq,
  getReferenceRangeTestMethodRequest,
  getReferenceRangeTestMethodSuccess,
  getReferenceRangeTestMethodFail,
  createReferenceRangeRequest,
  createReferenceRangeSuccess,
  createReferenceRangeFail,
  //
  getReferenceRangeByIdRequest,
  referenceRangeEditSuccess,
  referenceRangeEditFail,
  //
  updateReferenceRangeRequest,
  updateReferenceRangeSuccess,
  updateReferenceRangeFail,
  clearReferenceRangeData,
  clearAllReferenceRangeData,
} = ReferenceRange.actions;
export default ReferenceRange.reducer;
