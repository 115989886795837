import {
  createMedicalReferralFail,
  createMedicalReferralRequest,
  createMedicalReferralSuccess,
  createDischargeNoteRequest,
  createDischargeNoteSuccess,
  createDischargeNoteFail,
  getMedicalReferralRequest,
  getMedicalReferralSuccess,
  getMedicalReferralFail,
  getMedicalReferralSiteRequest,
  getMedicalReferralSiteSuccess,
  getMedicalReferralSiteFail,
  getMedicalReferralClinicRequest,
  getMedicalReferralClinicSuccess,
  getMedicalReferralClinicFail,
  getMedicalReferralDoctorRequest,
  getMedicalReferralDoctorSuccess,
  getMedicalReferralDoctorFail,
  getMedicalReferralTitleRequest,
  getMedicalReferralTitleSuccess,
  getMedicalReferralTitleFail,
  getMedicalReferralAppointmentTypeRequest,
  getMedicalReferralAppointmentTypeSuccess,
  getMedicalReferralAppointmentTypeFail,
  getMedicalReferralReferralTypeRequest,
  getMedicalReferralReferralTypeSuccess,
  getMedicalReferralReferralTypeFail,
  getClinicFromSiteRequest,
  getClinicFromSiteSuccess,
  getClinicFromSiteFail,
  getDoctorFromClinicRequest,
  getDoctorFromClinicSuccess,
  getDoctorFromClinicFail,
  updateMedicalReferralFail,
  updateMedicalReferralSuccess,
  updateMedicalReferralRequest,
  clearMedicalReferralCustomer,
  getFilterMedicalReferralRequest,
  getFilterMedicalReferralFail,
  getFilterMedicalReferralSuccess,
  getMedicalReferralById,
  medicalReferralEditSuccess,
  medicalReferralEditFail,
  clearMedicalReferralData,
} from "./medicalReferralSlice";
import { mergeMap } from "rxjs";
import {
  getMedicalReferral,
  createMedicalReferral,
  updateMedicalReferral,
  getMedicalReferralSite,
  getMedicalReferralDoctor,
  getMedicalReferralClinic,
  getMedicalReferralTitle,
  getMedicalReferralAppointmentType,
  getMedicalReferralReferralType,
  getClinicFromSite,
  getDoctorFromClinic,
  createDischargeNote,
  getFilteredMedicalReferral,
  getMedReferralById,
} from "./api";
import { map, filter, tap } from "rxjs/operators";
import { Observable } from "rxjs";
import { Action } from "@reduxjs/toolkit";
import { combineEpics } from "redux-observable";
import {
  alertErrorAction,
  alertSuccessAction,
  closeModal,
} from "../../../../CommonAppRedux/CommonAppSlice";
import {
  dispatchAction,
  stateAction,
} from "../../../../../AppUtils/Utils/globalTypes";
import {
  showConfirmationModalAfterAppointmentCompleted,
  showAppointmentReviewModal,
  showAppointmentReferralModal,
  showAppointmentDischargeModal,
} from "../../Redux/appointmentSlice";
import messages from "../../../../../AppUtils/Utils/validationConstants";

export const controller = new AbortController();

// get medical referral epic
const getMedicalReferralEpic = (
  action$: Observable<Action>,
  _: stateAction,
  { dispatch }: dispatchAction
) =>
  action$.pipe(
    filter(getMedicalReferralRequest.match),
    mergeMap(async (action) => {
      try {
        const response = await getMedicalReferral();
        return { payload: response.data };
      } catch (e) {
        return { error: e };
      }
    }),
    map((action) =>
      action?.payload
        ? getMedicalReferralSuccess(action?.payload)
        : getMedicalReferralFail()
    )
  );
// get filter medical referral epic
const getFilterMedicalReferralEpic = (
  action$: Observable<Action>,
  _: stateAction,
  { dispatch }: dispatchAction
) =>
  action$.pipe(
    filter(getFilterMedicalReferralRequest.match),
    mergeMap(async (action) => {
      try {
        // @ts-ignore
        const response = await getFilteredMedicalReferral(action?.payload);
        if (response) {
          dispatch(clearMedicalReferralData());
        }
        return { payload: response.data };
      } catch (e) {
        return { error: e };
      }
    }),
    map((action) =>
      action?.payload //the success api call is being called with wrong id check it
        ? getFilterMedicalReferralSuccess(action?.payload)
        : getFilterMedicalReferralFail()
    )
  );
// get site
const getAllMedicalReferralSite = (
  action$: Observable<Action>,
  _: stateAction,
  { dispatch }: dispatchAction
) =>
  action$.pipe(
    filter(getMedicalReferralSiteRequest.match),
    mergeMap(async (action) => {
      try {
        const response = await getMedicalReferralSite();
        return { payload: response.data };
      } catch (e) {
        return { error: e };
      }
    }),
    map((action) =>
      action?.payload
        ? getMedicalReferralSiteSuccess(action?.payload)
        : getMedicalReferralSiteFail()
    )
  );

// get clinic
const getAllMedicalReferralClinic = (
  action$: Observable<Action>,
  _: stateAction,
  { dispatch }: dispatchAction
) =>
  action$.pipe(
    filter(getMedicalReferralClinicRequest.match),
    mergeMap(async (action) => {
      try {
        const response = await getMedicalReferralClinic();
        return { payload: response.data };
      } catch (e) {
        return { error: e };
      }
    }),
    map((action) =>
      action?.payload
        ? getMedicalReferralClinicSuccess(action?.payload)
        : getMedicalReferralClinicFail()
    )
  );

// get doctor
const getAllMedicalReferralDoctor = (
  action$: Observable<Action>,
  _: stateAction,
  { dispatch }: dispatchAction
) =>
  action$.pipe(
    filter(getMedicalReferralDoctorRequest.match),
    mergeMap(async (action) => {
      try {
        const response = await getMedicalReferralDoctor(action.payload);
        return { payload: response.data };
      } catch (e) {
        return { error: e };
      }
    }),
    map((action) =>
      action?.payload
        ? getMedicalReferralDoctorSuccess(action?.payload)
        : getMedicalReferralDoctorFail()
    )
  );
// get title
const getAllMedicalReferralTitle = (
  action$: Observable<Action>,
  _: stateAction,
  { dispatch }: dispatchAction
) =>
  action$.pipe(
    filter(getMedicalReferralTitleRequest.match),
    mergeMap(async (action) => {
      try {
        const response = await getMedicalReferralTitle();
        return { payload: response.data };
      } catch (e) {
        return { error: e };
      }
    }),
    map((action) =>
      action?.payload
        ? getMedicalReferralTitleSuccess(action?.payload)
        : getMedicalReferralTitleFail()
    )
  );
// get appointment type
const getAllMedicalReferralAppointmentType = (
  action$: Observable<Action>,
  _: stateAction,
  { dispatch }: dispatchAction
) =>
  action$.pipe(
    filter(getMedicalReferralAppointmentTypeRequest.match),
    mergeMap(async (action) => {
      try {
        const response = await getMedicalReferralAppointmentType();
        return { payload: response.data };
      } catch (e) {
        return { error: e };
      }
    }),
    map((action) =>
      action?.payload
        ? getMedicalReferralAppointmentTypeSuccess(action?.payload)
        : getMedicalReferralAppointmentTypeFail()
    )
  );
// get referral type
const getAllMedicalReferralReferralType = (
  action$: Observable<Action>,
  _: stateAction,
  { dispatch }: dispatchAction
) =>
  action$.pipe(
    filter(getMedicalReferralReferralTypeRequest.match),
    mergeMap(async (action) => {
      try {
        const response = await getMedicalReferralReferralType();
        return { payload: response.data };
      } catch (e) {
        return { error: e };
      }
    }),
    map((action) =>
      action?.payload
        ? getMedicalReferralReferralTypeSuccess(action?.payload)
        : getMedicalReferralReferralTypeFail()
    )
  );

// get clinic from  site
const getAllClinicFromSite = (
  action$: Observable<Action>,
  _: stateAction,
  { dispatch }: dispatchAction
) =>
  action$.pipe(
    filter(getClinicFromSiteRequest.match),
    mergeMap(async (action) => {
      try {
        const response = await getClinicFromSite(action.payload);
        return { payload: response.data };
      } catch (e) {
        return { error: e };
      }
    }),
    map((action) =>
      action?.payload
        ? getClinicFromSiteSuccess(action?.payload)
        : getClinicFromSiteFail()
    )
  );

// get doctor from clinic
const getAllDoctorFromClinic = (
  action$: Observable<Action>,
  _: stateAction,
  { dispatch }: dispatchAction
) =>
  action$.pipe(
    filter(getDoctorFromClinicRequest.match),
    mergeMap(async (action) => {
      try {
        const response = await getDoctorFromClinic(action.payload);
        return { payload: response.data };
      } catch (e) {
        return { error: e };
      }
    }),
    map((action) =>
      action?.payload
        ? getDoctorFromClinicSuccess(action?.payload)
        : getDoctorFromClinicFail()
    )
  );

//create MedicalReferral epic
const createMedicalReferralEpic = (
  action$: Observable<Action>,
  _: stateAction,
  { dispatch }: dispatchAction
) =>
  action$.pipe(
    filter(createMedicalReferralRequest.match),
    mergeMap(async ({ payload: { values, referral_type, setShowModal } }) => {
      try {
        const body = JSON.stringify(values);
        // for (let [key, value] of Object.entries(values)) {
        //   // @ts-ignore
        //   body.append(`${key}`, value);
        // }
        const response = await createMedicalReferral(body);
        if (response) {
          dispatch(alertSuccessAction(messages.createMessage));
          setShowModal?.(false);
          dispatch(clearMedicalReferralCustomer());
          dispatch(clearMedicalReferralData());

          if (referral_type === "internal") {
            dispatch(showAppointmentReviewModal(false));
            dispatch(showConfirmationModalAfterAppointmentCompleted(false));
          } else {
            dispatch(showAppointmentReferralModal(false));
            dispatch(showConfirmationModalAfterAppointmentCompleted(false));
          }
        }
        return { payload: { response } };
      } catch (e) {
        dispatch(alertErrorAction(messages.createFailMessage));

        return { error: e };
      }
    }),
    map((action) => {
      const customerId = localStorage.getItem("customerId");
      return action?.payload
        ? dispatch(getFilterMedicalReferralRequest(customerId))
        : createMedicalReferralFail();
    })
  );

// create discharge note
const createDischargeNoteEpic = (
  action$: Observable<Action>,
  _: stateAction,
  { dispatch }: dispatchAction
) =>
  action$.pipe(
    filter(createDischargeNoteRequest.match),
    mergeMap(async ({ payload: { values } }) => {
      try {
        const body = new FormData();
        for (let [key, value] of Object.entries(values)) {
          // @ts-ignore
          body.append(`${key}`, value);
        }
        const response = await createDischargeNote(body);
        if (response) {
          dispatch(alertSuccessAction(messages.createMessage));
          dispatch(clearMedicalReferralCustomer());
          dispatch(showAppointmentDischargeModal(false));
          dispatch(showConfirmationModalAfterAppointmentCompleted(false));
        }
        return { payload: { response } };
      } catch (e) {
        dispatch(alertErrorAction(messages.createFailMessage));
        return { error: e };
      }
    }),
    map((action) => {
      return action?.payload
        ? createDischargeNoteSuccess()
        : createDischargeNoteFail();
    })
  );
//update MedicalReferral epic
const updateMedicalReferralEpic = (
  action$: Observable<Action>,
  _: stateAction,
  { dispatch }: dispatchAction
) =>
  action$.pipe(
    filter(updateMedicalReferralRequest.match),
    mergeMap(
      async ({
        payload: { values, id, scheduler, dateRange, setShowModal },
      }) => {
        const { status } = values;
        try {
          const body = new FormData();
          for (let [key, value] of Object.entries(values)) {
            // @ts-ignore
            body.append(`${key}`, value);
          }
          const response = await updateMedicalReferral(body, id);
          if (response) {
            dispatch(alertSuccessAction(messages.updateMessage));
            setShowModal(false);
            dispatch(clearMedicalReferralData());
          }
          return { payload: { response } };
        } catch (e) {
          dispatch(alertErrorAction(messages.updateFailMessage));
          return { error: e };
        }
      }
    ),
    map((action) =>
      action?.payload
        ? updateMedicalReferralSuccess()
        : updateMedicalReferralFail()
    )
  );

const getMedicalReferralByIdEpic = (
  action$: Observable<Action>,
  _: stateAction,
  { dispatch }: dispatchAction
) =>
  action$.pipe(
    filter(getMedicalReferralById.match),
    mergeMap(async (action) => {
      try {
        const response = await getMedReferralById(action.payload);

        return { payload: response.data };
      } catch (e) {
        return { error: e };
      }
    }),
    map((action) =>
      action?.payload
        ? medicalReferralEditSuccess(action.payload)
        : medicalReferralEditFail()
    )
  );

export const medicalReferralEpics = combineEpics(
  getMedicalReferralEpic,
  getFilterMedicalReferralEpic,
  createMedicalReferralEpic,
  createDischargeNoteEpic,
  updateMedicalReferralEpic,
  getAllMedicalReferralSite,
  getAllMedicalReferralClinic,
  getAllMedicalReferralDoctor,
  getAllMedicalReferralTitle,
  getAllMedicalReferralAppointmentType,
  getAllMedicalReferralReferralType,
  getAllClinicFromSite,
  getAllDoctorFromClinic,
  getMedicalReferralByIdEpic
);
