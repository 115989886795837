export const hasRequiredPermissions = (
  isSuperuser: boolean,
  permissions: Array<{ codeName: string }>,
  requiredPermissions: string[]
) => {
  return (
    isSuperuser ||
    permissions?.some(({ codeName }) => requiredPermissions.includes(codeName))
  );
};
