import { createSlice } from "@reduxjs/toolkit";
import { siteSetupInitialState } from "./types";

const initialState: siteSetupInitialState = {
  siteSetups: [],
  edit: false,
  siteSetup: null,
  loadingSiteSetup: false,
  loadingCreateSiteSetup: false,
  count: 0,
  previous: "",
  next: "",
};

export const SiteSetup = createSlice({
  name: "siteSetupReducer",
  initialState,
  reducers: {
    getSiteSetupRequest: (state, payload) => {
      state.loadingSiteSetup = true;
    },
    getSiteSetupSuccess: (
      state,
      { payload: { results, count, next, previous } }
    ) => {
      state.loadingSiteSetup = false;
      state.siteSetups = results;
      state.count = count;
      state.next = next;
      state.previous = previous;
    },
    getSiteSetupFail: (state) => {
      state.loadingSiteSetup = false;
    },

    getSiteSetupNextRequest: (state, payload) => {
      state.loadingSiteSetup = true;
    },
    getSiteSetupPreviousRequest: (state, payload) => {
      state.loadingSiteSetup = true;
    },
    createSiteSetupRequest: (state, payload) => {
      state.loadingCreateSiteSetup = true;
    },
    updateSiteSetupRequest: (state, payload) => {
      state.loadingCreateSiteSetup = true;
    },
    createSiteSetupSuccess: (state) => {
      state.loadingCreateSiteSetup = false;
    },
    createSiteSetupFail: (state) => {
      state.loadingCreateSiteSetup = false;
    },
    getSiteSetupByIdRequest: (state, payload) => {
      state.edit = true;
    },
    siteSetupEditSuccess: (state, { payload }) => {
      state.siteSetup = payload;
    },
    siteSetupEditFail: (state) => {
      state.edit = false;
    },
    updateSiteSetupSuccess: (state) => {
      state.loadingCreateSiteSetup = false;
    },
    updateSiteSetupFail: (state) => {
      state.loadingCreateSiteSetup = false;
    },
    clearAllData: (state) => {
      state.edit = false;
      state.siteSetup = null;
    },
  },
});

//action creaters generated for each case reducer
export const {
  getSiteSetupRequest,
  getSiteSetupFail,
  getSiteSetupSuccess,
  getSiteSetupNextRequest,
  getSiteSetupPreviousRequest,
  createSiteSetupFail,
  createSiteSetupRequest,
  createSiteSetupSuccess,
  siteSetupEditSuccess,
  updateSiteSetupFail,
  updateSiteSetupSuccess,
  updateSiteSetupRequest,
  clearAllData,
  getSiteSetupByIdRequest,
  siteSetupEditFail,
} = SiteSetup.actions;

export default SiteSetup.reducer;
