import React, { useEffect } from "react";
import {
  useAppDispatch,
  useAppSelector,
} from "../../../../AppUtils/Utils/appHooks";
import { medicalReferralSelector } from "../../Appointment/ConfirmModal/Redux/selector";
import { patientDetailSelector } from "../Redux/selector";
import { getPatientListByIdRequest } from "../Redux/patientDetailSlice";

const useCustomerData = () => {
  const dispatch = useAppDispatch();
  const { selectedCustomer } = useAppSelector(medicalReferralSelector);
  const { customer } = useAppSelector(patientDetailSelector);
  const updateCustomer = selectedCustomer ?? customer;

  if (selectedCustomer) {
    localStorage.setItem("customerId", selectedCustomer?.customer);
    localStorage.setItem("patientId", selectedCustomer?.id);
  }

  return updateCustomer;
};

export default useCustomerData;
