import {
  createMoldOrderFail,
  createMoldOrderRequest,
  createMoldOrderSuccess,
  getMoldOrderSuccess,
  getMoldOrderFail,
  getMoldOrderCategoryRequest,
  getMoldOrderCategorySuccess,
  getMoldOrderCategoryFail,
  getMoldOrderDepartmentRequest,
  getMoldOrderDepartmentSuccess,
  getMoldOrderDepartmentFail,
  getMoldOrderCustomerTypeRequest,
  getMoldOrderCustomerTypeSuccess,
  getMoldOrderCustomerTypeFail,
  updateMoldOrderFail,
  updateMoldOrderSuccess,
  getMoldOrderRequest,
  updateMoldOrderRequest,
  getMoldOrderNextRequest,
  getMoldOrderPreviousRequest,
  clearMoldOrderData,
  getMoldOrderByIdRequest,
  moldOrderEditSuccess,
  moldOrderEditFail,
  // getMoldOrderCustomerRequest,
  // getMoldOrderCustomerSuccess,
  // getMoldOrderCustomerFail,
  getMoldOrderItemRequest,
  getMoldOrderItemSuccess,
  getMoldOrderItemFail,
  getMoldTypeRequest,
  getMoldTypeSuccess,
  getMoldTypeFail,
} from "./moldOrderSlice";
import { mergeMap } from "rxjs";
import {
  createMoldOrder,
  getMoldOrder,
  updateMoldOrder,
  getMoldOrderCustomerType,
  getMoldOrderCategory,
  getMoldOrderDepartment,
  getMoldOrderById,
  getMoldOrderItem,
  getMoldType,
} from "./api";
import { map, filter, tap } from "rxjs/operators";
import { Observable } from "rxjs";
import { Action } from "@reduxjs/toolkit";
import { combineEpics } from "redux-observable";
import {
  alertErrorAction,
  alertSuccessAction,
  closeModal,
} from "../../../../../CommonAppRedux/CommonAppSlice";
import {
  dispatchAction,
  stateAction,
} from "../../../../../../AppUtils/Utils/globalTypes";
import { getNext, getPrevious } from "../../../../../CommonAppRedux/api";
import messages from "../../../../../../AppUtils/Utils/validationConstants";

export const controller = new AbortController();

// get clinicorder epic
const getMoldOrderEpic = (
  action$: Observable<Action>,
  _: stateAction,
  { dispatch }: dispatchAction
) =>
  action$.pipe(
    filter(getMoldOrderRequest.match),
    mergeMap(async (action) => {
      try {
        const response = await getMoldOrder(action.payload);
        return { payload: response.data };
      } catch (e) {
        return { error: e };
      }
    }),
    map((action) =>
      action?.payload
        ? getMoldOrderSuccess(action?.payload)
        : getMoldOrderFail()
    )
  );

//get next
const getMoldOrderNext = (
  action$: Observable<Action>,
  _: stateAction,
  { dispatch }: dispatchAction
) =>
  action$.pipe(
    filter(getMoldOrderNextRequest.match),
    mergeMap(async (action) => {
      try {
        const response = await getNext(action.payload);
        return { payload: response.data };
      } catch (e) {
        return { error: e };
      }
    }),
    map((action) =>
      action?.payload
        ? getMoldOrderSuccess(action?.payload)
        : getMoldOrderFail()
    )
  );

//get previous
const getMoldOrderPrevious = (
  action$: Observable<Action>,
  _: stateAction,
  { dispatch }: dispatchAction
) =>
  action$.pipe(
    filter(getMoldOrderPreviousRequest.match),
    mergeMap(async (action) => {
      try {
        const response = await getPrevious(action.payload);
        return { payload: response.data };
      } catch (e) {
        return { error: e };
      }
    }),
    map((action) =>
      action?.payload
        ? getMoldOrderSuccess(action?.payload)
        : getMoldOrderFail()
    )
  );
// get clinicorder customer type
const getAllMoldOrderCustomerType = (
  action$: Observable<Action>,
  _: stateAction,
  { dispatch }: dispatchAction
) =>
  action$.pipe(
    filter(getMoldOrderCustomerTypeRequest.match),
    mergeMap(async (action) => {
      try {
        const response = await getMoldOrderCustomerType(action.payload);
        return { payload: response.data };
      } catch (e) {
        return { error: e };
      }
    }),
    map((action) =>
      action?.payload
        ? getMoldOrderCustomerTypeSuccess(action?.payload)
        : getMoldOrderCustomerTypeFail()
    )
  );
// get clinicorder department
const getAllMoldOrderDepartment = (
  action$: Observable<Action>,
  _: stateAction,
  { dispatch }: dispatchAction
) =>
  action$.pipe(
    filter(getMoldOrderDepartmentRequest.match),
    mergeMap(async (action) => {
      try {
        const response = await getMoldOrderDepartment(action.payload);
        return { payload: response.data };
      } catch (e) {
        return { error: e };
      }
    }),
    map((action) =>
      action?.payload
        ? getMoldOrderDepartmentSuccess(action?.payload)
        : getMoldOrderDepartmentFail()
    )
  );
// get clinicorder category
const getAllMoldOrderCategory = (
  action$: Observable<Action>,
  _: stateAction,
  { dispatch }: dispatchAction
) =>
  action$.pipe(
    filter(getMoldOrderCategoryRequest.match),
    mergeMap(async (action) => {
      try {
        const response = await getMoldOrderCategory(action.payload);
        return { payload: response.data };
      } catch (e) {
        return { error: e };
      }
    }),
    map((action) =>
      action?.payload
        ? getMoldOrderCategorySuccess(action?.payload)
        : getMoldOrderCategoryFail()
    )
  );
//create MoldOrder epic
const createMoldOrderEpic = (
  action$: Observable<Action>,
  _: stateAction,
  { dispatch }: dispatchAction
) =>
  action$.pipe(
    filter(createMoldOrderRequest.match),
    mergeMap(async ({ payload: { values, rowsPerPage, page } }) => {
      try {
        const body = JSON.stringify(values);

        const response = await createMoldOrder(body);
        if (response) {
          dispatch(
            getMoldOrderRequest({ id: values?.customer, rowsPerPage, page })
          );
          dispatch(alertSuccessAction(messages.createMessage));
          dispatch(closeModal());
          dispatch(clearMoldOrderData());
        }
        return { payload: { response } };
      } catch (e) {
        dispatch(alertErrorAction(messages.createFailMessage));
        return { error: e };
      }
    }),
    map((action) => {
      return action?.payload ? createMoldOrderSuccess() : createMoldOrderFail();
    })
  );

//update MoldOrder epic
const updateMoldOrderEpic = (
  action$: Observable<Action>,
  _: stateAction,
  { dispatch }: dispatchAction
) =>
  action$.pipe(
    filter(updateMoldOrderRequest.match),
    mergeMap(async ({ payload: { values, id, rowsPerPage, page } }) => {
      try {
        const body = JSON.stringify(values);
        const response = await updateMoldOrder(body, id);
        if (response) {
          dispatch(getMoldOrderRequest({ rowsPerPage, page }));
          dispatch(alertSuccessAction(messages.updateMessage));
          dispatch(clearMoldOrderData());
          dispatch(closeModal());
        }
        return { payload: { response, rowsPerPage } };
      } catch (e) {
        dispatch(alertErrorAction(messages.updateFailMessage));
        return { error: e };
      }
    }),
    map((action) =>
      action?.payload ? updateMoldOrderSuccess() : updateMoldOrderFail()
    )
  );

const getMoldOrderByIdEpic = (
  action$: Observable<Action>,
  _: stateAction,
  { dispatch }: dispatchAction
) =>
  action$.pipe(
    filter(getMoldOrderByIdRequest.match),
    mergeMap(async (action) => {
      try {
        const response = await getMoldOrderById(action.payload);
        return { payload: response.data };
      } catch (e) {
        return { error: e };
      }
    }),
    map((action) =>
      action?.payload
        ? moldOrderEditSuccess(action.payload)
        : moldOrderEditFail()
    )
  );

//customer
// const getMoldOrderCustomerEpic = (
//   action$: Observable<Action>,
//   _: stateAction,
//   { dispatch }: dispatchAction
// ) =>
//   action$.pipe(
//     filter(getMoldOrderCustomerRequest.match),
//     mergeMap(async (action: any) => {
//       try {
//         const response = await getMoldOrderCustomer(action.payload);
//         return { payload: response.data };
//       } catch (e) {
//         return { error: e };
//       }
//     }),
//     map((action) =>
//       action?.payload
//         ? getMoldOrderCustomerSuccess(action?.payload)
//         : getMoldOrderCustomerFail()
//     )
//   );
//item
const getMoldOrderItemEpic = (
  action$: Observable<Action>,
  _: stateAction,
  { dispatch }: dispatchAction
) =>
  action$.pipe(
    filter(getMoldOrderItemRequest.match),
    mergeMap(async (action: any) => {
      try {
        const response = await getMoldOrderItem(action.payload);
        return { payload: response.data };
      } catch (e) {
        return { error: e };
      }
    }),
    map((action) =>
      action?.payload
        ? getMoldOrderItemSuccess(action?.payload)
        : getMoldOrderItemFail()
    )
  );

// get mold type
const getMoldTypeEpic = (
  action$: Observable<Action>,
  _: stateAction,
  { dispatch }: dispatchAction
) =>
  action$.pipe(
    filter(getMoldTypeRequest.match),
    mergeMap(async (action) => {
      try {
        const response = await getMoldType(action.payload);
        return { payload: response.data };
      } catch (e) {
        return { error: e };
      }
    }),
    map((action) =>
      action?.payload ? getMoldTypeSuccess(action?.payload) : getMoldTypeFail()
    )
  );
export const moldOrderEpics = combineEpics(
  getMoldOrderEpic,
  createMoldOrderEpic,
  updateMoldOrderEpic,
  getMoldOrderNext,
  getMoldOrderPrevious,
  getAllMoldOrderCustomerType,
  getAllMoldOrderDepartment,
  getAllMoldOrderCategory,
  getMoldOrderByIdEpic,
  getMoldOrderItemEpic,
  getMoldTypeEpic
  // getMoldOrderCustomerEpic,
);
