import { createSlice } from "@reduxjs/toolkit";
import { testClinicReportInitialState } from "./types";
const initialState: testClinicReportInitialState = {
  customers: [],
  users: [],
  tests: [],
  saleDetails: [],
  //loading
  loadingGenerateReport: false,
  loadingTestClinicReportCustomer: false,
  loadingTestClinicReportTest: false,
  loadingTestClinicReportUser: false,
  count: 0,
  previous: "",
  next: "",
};

// export const getTestClinicReportDetailRequest = createAction(
//   "getTestClinicReportDetailRequest",
//   function prepare({ detailsRowsPerPage, page }) {
//     return {
//       payload: {
//         detailsRowsPerPage,
//         page,
//       },
//     };
//   }
// );
export const TestClinicReport = createSlice({
  name: "TestClinicReportReducer",
  initialState,
  reducers: {
    //loading
    loadingTestClinicReportDetail: (state) => {
      state.loadingGenerateReport = true;
    },
    //external reducer request
    getTestClinicReportDetailRequest: (state, { payload }) => {
      state.loadingGenerateReport = true;
    },
    getTestClinicReportCustomerRequest: (state, payload) => {
      state.loadingTestClinicReportCustomer = true;
    },
    getTestClinicReportUserRequest: (state) => {
      state.loadingTestClinicReportUser = true;
    },
    getTestClinicReportAllSummaryRequest: (state, { payload }) => {
      state.loadingGenerateReport = true;
    },
    getTestClinicReportAllDetailRequest: (state, { payload }) => {
      state.loadingGenerateReport = true;
    },
    getTestClinicReportQuickSummaryRequest: (state, { payload }) => {
      state.loadingGenerateReport = true;
    },
    getTestClinicReportQuickDetailRequest: (state, { payload }) => {
      state.loadingGenerateReport = true;
    },
    getTestClinicReportNextRequest: (state, payload) => {
      state.loadingGenerateReport = true;
    },
    getTestClinicReportPreviousRequest: (state, payload) => {
      state.loadingGenerateReport = true;
    },
    loadingTestClinicReportCustomerSearch: (state) => {
      state.loadingTestClinicReportCustomer = true;
    },

    //external get reducer
    getTestClinicReportDetailSuccess: (
      state,
      { payload: { results, count, next, previous } }
    ) => {
      state.saleDetails = results;
      state.loadingGenerateReport = false;
      state.count = count;
      state.next = next;
      state.previous = previous;
    },
    getTestClinicReportDetailFail: (state) => {
      state.loadingGenerateReport = false;
    },
    // user
    loadingTestClinicReportUserSearch: (state) => {
      state.loadingTestClinicReportUser = true;
    },
    getTestClinicReportUserSuccess: (state, { payload: { results } }) => {
      state.loadingTestClinicReportUser = false;
      state.users = results;
    },
    getTestClinicReportUserFail: (state) => {
      state.loadingTestClinicReportUser = false;
    },
    getTestClinicReportCustomerSuccess: (state, { payload: { results } }) => {
      state.loadingTestClinicReportCustomer = false;
      state.customers = results;
    },
    getTestClinicReportCustomerFail: (state) => {
      state.loadingTestClinicReportCustomer = false;
    },
    // get test
    loadingClinicReportTest: (state) => {
      state.loadingTestClinicReportTest = true;
    },
    getTestClinicReportTestRequest: (state) => {
      state.loadingTestClinicReportTest = true;
    },
    getClinicSaleReportTestSuccess: (state, { payload: { results } }) => {
      state.loadingTestClinicReportTest = false;
      state.tests = results;
    },
    getClinicSaleReportTestFail: (state) => {
      state.loadingTestClinicReportTest = false;
    },
    getTestClinicReportAllSummarySuccess: (state, { payload: { results } }) => {
      state.loadingGenerateReport = false;
      state.saleDetails = results;
    },
    getTestClinicReportAllSummaryFail: (state) => {
      state.loadingGenerateReport = false;
    },
    getTestClinicReportQuickSummarySuccess: (
      state,
      { payload: { results, count, next, previous } }
    ) => {
      state.loadingGenerateReport = false;
      state.saleDetails = results;
      state.count = count;
      state.next = next;
      state.previous = previous;
    },
    getTestClinicReportQuickSummaryFail: (state) => {
      state.loadingGenerateReport = false;
    },
    getTestClinicReportAllDetailSuccess: (state, { payload: { results } }) => {
      state.loadingGenerateReport = false;
      state.saleDetails = results;
    },
    getTestClinicReportAllDetailFail: (state) => {
      state.loadingGenerateReport = false;
    },
    getTestClinicReportQuickDetailSuccess: (
      state,
      { payload: { results, count, next, previous } }
    ) => {
      state.loadingGenerateReport = false;
      state.saleDetails = results;
      state.count = count;
      state.next = next;
      state.previous = previous;
    },
    getTestClinicReportQuickDetailFail: (state) => {
      state.loadingGenerateReport = false;
    },
    clearTestClinicReportData: (state) => {
      state.customers = [];
      state.users = [];
      state.tests = [];
    },
    clearSaleDetails: (state) => {
      state.saleDetails = [];
    },
  },
});
// Action creators are generated for each case reducer function
export const {
  //reducerReport
  getTestClinicReportDetailRequest,
  getTestClinicReportCustomerRequest,
  getTestClinicReportTestRequest,
  getTestClinicReportUserRequest,
  getTestClinicReportAllSummaryRequest,
  getTestClinicReportAllDetailRequest,
  getTestClinicReportQuickSummaryRequest,
  getTestClinicReportQuickDetailRequest,
  getTestClinicReportPreviousRequest,
  getTestClinicReportNextRequest,
  loadingTestClinicReportCustomerSearch,
  //reducer get data
  getTestClinicReportDetailSuccess,
  getTestClinicReportDetailFail,
  // test
  loadingClinicReportTest,
  getClinicSaleReportTestSuccess,
  getClinicSaleReportTestFail,
  getTestClinicReportCustomerSuccess,
  getTestClinicReportCustomerFail,
  // user
  getTestClinicReportUserSuccess,
  loadingTestClinicReportUserSearch,
  getTestClinicReportUserFail,
  getTestClinicReportQuickDetailSuccess,
  getTestClinicReportQuickDetailFail,
  getTestClinicReportAllDetailSuccess,
  getTestClinicReportAllDetailFail,
  getTestClinicReportQuickSummarySuccess,
  getTestClinicReportQuickSummaryFail,
  getTestClinicReportAllSummarySuccess,
  getTestClinicReportAllSummaryFail,
  //loading
  loadingTestClinicReportDetail,
  //clear
  clearTestClinicReportData,
  clearSaleDetails,
} = TestClinicReport.actions;
export default TestClinicReport.reducer;
