import { createAction, createSlice } from "@reduxjs/toolkit";

const initialState: any = {
  loadingBilling: false,
  loadingCreateBilling: false,
  loadingBillingTest: false,
  loadingBillingPatient: false,
  loadingSearchData: false,
  loadingBillingCustomerType: false,
  loadingBillingDiscountScheme: false,
  loadingBillingCustomer: false,
  loadingBillingPaymentMode: false,
  edit: false,
  count: 0,
  previous: "",
  next: "",
  billings: [],
  billingPrintCount: [],
  billing: null,
  billingDetails: [],
  viewBillingPrintDetailsData: [],
  viewBillingSmallPrintDetailsData: [],
  saleMainId: "",
  // billingRowData: [],
  // viewBillingModal: false,
  // billingDetailCount: null,
  // billingDetailNext: null,
  // billingDetailPrevious: null,
  // billingDetail: [],
  //external reducer
  billingDataForPrints: [],
  billingCustomerTypes: [],
  billingDiscountSchemes: [],
  billingCustomers: [],
  billingTests: [],
  billingReferrals: [],
  //edit
  billingSaleEditDetails: [],
  billingRowEdit: false,
  //labsaleDetail
  labSaleDetails: [],
  //payment details billing
  billingPaymentModes: [],
  //view details billing
  viewBillingDetails: [],
  loadingViewDetails: false,
  billingRowData: {},
  billingId: "",
  billingDetailCount: null,
  billingDetailNext: null,
  billingDetailPrevious: null,
  loadingBillingDetails: false,
  loadingPrintBilling: false,
  //additinla charge
  billingAdditionalCharges: [],
  loadingBillingAdditionalCharge: false,
  createdBillingId: "",
  loadingBillingCheckFollowUp: false,
  billingCheckFollowUp: false,
};
export const updateBillingRequest = createAction(
  "updateBillingRequest",
  function prepare({ values, id, rowsPerPage, page }) {
    return {
      payload: {
        values,
        id,
        rowsPerPage,
        page,
      },
    };
  }
);
export const getBillingRequest = createAction(
  "getBillingRequest",
  function prepare({ rowsPerPage, page, filterData }) {
    return {
      payload: {
        rowsPerPage,
        page,
        filterData,
      },
    };
  }
);
// get next request
export const getBillingNextRequest = createAction(
  "getBillingNextRequest",
  function prepare(next) {
    return {
      payload: next,
    };
  }
);
//get previous request
export const getBillingPreviousRequest = createAction(
  "getBillingPreviousRequest",
  function prepare(previous) {
    return {
      payload: previous,
    };
  }
);
export const Billing = createSlice({
  name: "BillingReducer",
  initialState,
  reducers: {
    loadingBilling: (state) => {
      state.loadingBilling = true;
    },
    getBillingSuccess: (
      state,
      { payload: { results, count, next, previous } }
    ) => {
      state.loadingBilling = false;
      state.billings = results;
      state.count = count;
      state.next = next;
      state.previous = previous;
    },
    getBillingFail: (state) => {
      state.loadingBilling = false;
    },
    getBillingSearchDataSuccess: (state, { payload: { results } }) => {
      state.loadingSearchData = true;
      state.searchData = results;
    },
    getBillingSearchDataFail: (state) => {
      state.loadingSearchData = false;
    },
    createBillingRequest: (state, payload) => {
      state.loadingCreateBilling = true;
      state.closeModal = true;
    },
    createBillingSuccess: (state, { payload }) => {
      state.loadingCreateBilling = false;
      state.createdBillingId = payload?.response?.data?.id;
    },
    createBillingFail: (state) => {
      state.loadingCreateBilling = false;
    },
    billingEditSuccess: (state, { payload }) => {
      state.edit = true;
      state.billing = payload;
    },
    updateBillingSuccess: (state) => {
      state.loadingCreateBilling = false;
    },
    updateBillingFail: (state) => {
      state.loadingCreateBilling = false;
    },
    //edit external reducer
    editBillingSaleDetailsSuccess: (state, { payload }) => {
      state.billingRowEdit = true;
      state.billingSaleEditDetails = payload;
      state.billingDetails = state.billingDetails.filter(
        (data: any) => data?.id !== payload?.id
      );
    },
    //delete external reducer
    deleteBillingSaleDetailsSuccess: (state, { payload }) => {
      state.billingDetails = state.billingDetails.filter(
        (data: any) => data?.id !== payload?.id
      );
    },
    //External Reducer
    getBillingCustomerTypeSuccess: (state, { payload: { results } }) => {
      state.loadingCustomerType = true;
      state.billingCustomerTypes = results;
    },
    getBillingCustomerTypeFail: (state) => {
      state.loadingBillingCustomerType = false;
    },
    getBillingDiscountSchemeSuccess: (state, { payload: { results } }) => {
      state.loadingBillingDiscountScheme = false;
      state.billingDiscountSchemes = results;
    },
    getBillingDiscountSchemeFail: (state) => {
      state.loadingBillingDiscountScheme = false;
    },
    getBillingCustomerSuccess: (state, { payload: { results } }) => {
      state.loadingBillingCustomer = false;
      state.billingCustomers = results;
    },
    getBillingCustomerFail: (state) => {
      state.loadingBillingCustomer = false;
    },
    getBillingReferralSuccess: (state, { payload: { results } }) => {
      state.loadingBillingReferral = false;
      state.billingReferrals = results;
    },
    getBillingReferralFail: (state) => {
      state.loadingBillingReferral = false;
    },
    getBillingTestSuccess: (state, { payload: { results } }) => {
      state.loadingBillingTest = false;
      state.billingTests = results;
    },
    getBillingTestFail: (state) => {
      state.loadingBillingTest = false;
      state.billingTests = [];
    },
    //loading autocomplete && request Loading
    loadingBillingCustomerRequest: (state) => {
      state.loadingBillingCustomer = true;
    },
    loadingBillingCustomerTypeRequest: (state) => {
      state.loadingBillingCustomer = true;
    },
    loadingBillingDiscountSchemeRequest: (state) => {
      state.loadingBillingDiscountScheme = true;
    },
    loadingBillingReferralRequest: (state) => {
      state.loadingBillingReferral = true;
    },
    loadingBillingTestRequest: (state) => {
      state.loadingBillingTest = true;
    },
    //request external data
    getBillingCustomerRequest: (state, payload) => {
      state.loadingBillingCustomer = true;
    },
    getBillingCustomerTypeRequest: (state, payload) => {
      state.loadingBillingCustomerType = true;
    },
    getBillingReferralRequest: (state, payload) => {
      state.loadingBillingReferral = true;
    },
    getBillingDiscountSchemeRequest: (state, payload) => {
      state.loadingBillingDiscountScheme = true;
    },
    getBillingTestRequest: (state, payload) => {
      state.loadingBillingTest = true;
    },
    //add form data to table
    addLabSaleDetails: (state, { payload }) => {
      state.billingDetails = [...state.billingDetails, payload];
      state.billingRowEdit = false;
    },
    //updated
    updatedLabSaleDetails: (state, { payload }) => {
      state.billingDetails = payload;
      state.billingRowEdit = false;
    },
    //clear
    // clearBillingRowEdit: (state) => {
    //   state.billingRowEdit = false;
    // },
    //payment ode
    getBillingPaymentModeRequest: (state) => {
      state.loadingBillingPaymentMode = true;
    },
    getBillingPaymentModeSuccess: (state, { payload }) => {
      state.billingPaymentModes = payload.results;
      state.loadingBillingPaymentMode = false;
    },
    getBillingPaymentModeFail: (state) => {
      state.loadingBillingPaymentMode = false;
    },
    //additional charge
    getBillingAdditionalChargeRequest: (state) => {
      state.loadingBillingAdditionalCharge = true;
    },
    getBillingAdditionalChargeSuccess: (state, { payload }) => {
      state.billingAdditionalCharges = payload.results;
      state.loadingBillingAdditionalCharge = false;
    },
    getBillingAdditionalChargeFail: (state) => {
      state.loadingBillingAdditionalCharge = false;
    },
    //view details
    getViewBillingDetailsRequest: (state, { payload }) => {
      state.loadingViewDetails = true;
      state.billingRowData = payload?.rowData;
      state.billingId = payload?.id;
    },
    getViewBillingDetailsSuccess: (
      state,
      { payload: { results, count, next, previous } }
    ) => {
      state.loadingViewDetails = false;
      state.viewBillingDetails = results;
      state.billingDetailCount = count;
      state.billingDetailNext = next;
      state.billingDetailPrevious = previous;
    },
    getViewBillingDetailsFail: (state) => {
      state.loadingViewDetails = false;
    },
    getViewBillingDetailsNextRequest: (state, payload) => {
      state.loadingViewDetails = true;
    },
    getViewBillingDetailsPreviousRequest: (state, payload) => {
      state.loadingViewDetails = true;
    },
    getBillingDetailsRequest: (state, payload) => {
      state.loadingBillingDetails = true;
    },
    getBillingDetailsSuccess: (state, { payload: { results } }) => {
      state.loadingBillingDetails = false;
      state.billingDetails = results?.map((result: any) => ({
        ...result,
        netAmount: 0 * result?.cost,
        grossAmount: 0 * result?.cost,
        discountRate:
          Number(result.discountAmount * 100) /
          (result.cost * result.qty === 0 ? 1 : result.cost * result.qty),
        discountAmount: 0,
        returnQty: 0 * result?.returnQty,
      }));
    },
    getBillingDetailsFail: (state) => {
      state.loadingBillingDetails = false;
    },
    //
    getPrintBillingDetailsRequest: (state, { payload }) => {
      // state.billingRowData = payload?.rowData;
      state.loadingPrintBilling = true;
      // state.billingId = payload?.id;
    },
    getPrintBillingDetailsSuccess: (state, { payload: { results } }) => {
      state.loadingPrintBilling = false;
      state.viewBillingDetails = results;
      state.viewBillingPrintDetailsData = results?.map(
        (billingDetail: any, i: number) => [
          i + 1,
          billingDetail.testName,
          billingDetail.cost,
          billingDetail.qty,
          billingDetail.discountAmount,
          billingDetail.taxAmount,
          billingDetail.grossAmount,
          billingDetail.netAmount,
        ]
      );
      state.viewBillingSmallPrintDetailsData = results?.map(
        (billingDetail: any, i: number) => [
          i + 1,
          billingDetail.testName,
          billingDetail.cost,
          billingDetail.qty,
          billingDetail.netAmount,
        ]
      );
    },
    getPrintBillingDetailsFail: (state) => {
      state.loadingPrintBilling = false;
    },
    getPrintBillingCustomerDetailsSuccess: (state, { payload: results }) => {
      state.loadingPrintBilling = false;
      state.billingRowData = results;
      state.billingId = results?.id;
    },
    getPrintBillingCustomerDetailsFail: (state) => {
      state.loadingPrintBilling = false;
    },
    //print
    getBillingPrintCountRequest: (state, payload) => {
      state.loadingPrintBilling = true;
    },
    getBillingPrintCountSuccess: (state, { payload: { results } }) => {
      state.loadingPrintBilling = false;
      state.billingPrintCount = results;
    },
    getBillingPrintCountFail: (state) => {
      state.loadingPrintBilling = false;
    },
    billingPrintCountRequest: (state, payload) => {
      state.loadingPrintBilling = true;
    },
    billingPrintCountSuccess: (state, payload) => {
      state.loadingPrintBilling = true;
    },
    billingPrintCountFail: (state) => {
      state.loadingPrintBilling = true;
    },
    clearBillingData: (state) => {
      state.edit = false;
      state.billingDetails = [];
    },
    //checkFollow up
    getBillingCheckFollowUpRequest: (state, payload) => {
      state.loadingBillingCheckFollowUp = true;
    },
    getBillingCheckFollowUpSuccess: (state, { payload }) => {
      state.billingCheckFollowUp = payload;
    },
    getBillingCheckFollowUpFail: (state, { payload }) => {
      state.billingCheckFollowUp = payload;
    },
  },
});
// Action creators are generated for each case reducer function
export const {
  loadingBilling,
  getBillingSuccess,
  getBillingFail,
  createBillingRequest,
  createBillingSuccess,
  createBillingFail,
  billingEditSuccess,
  updateBillingSuccess,
  updateBillingFail,
  clearBillingData,
  getBillingSearchDataSuccess,
  getBillingSearchDataFail,
  //external reducer
  getBillingCustomerTypeSuccess,
  getBillingCustomerTypeFail,
  loadingBillingCustomerTypeRequest,
  getBillingCustomerTypeRequest,
  //customer
  getBillingCustomerFail,
  getBillingCustomerSuccess,
  loadingBillingCustomerRequest,
  getBillingCustomerRequest,
  //discount scheme
  getBillingDiscountSchemeFail,
  getBillingDiscountSchemeSuccess,
  loadingBillingDiscountSchemeRequest,
  getBillingDiscountSchemeRequest,
  //referral
  getBillingReferralFail,
  getBillingReferralSuccess,
  loadingBillingReferralRequest,
  getBillingReferralRequest,
  //billing test
  getBillingTestFail,
  getBillingTestSuccess,
  loadingBillingTestRequest,
  getBillingTestRequest,
  //edit
  editBillingSaleDetailsSuccess,
  //add
  addLabSaleDetails,
  //delete
  deleteBillingSaleDetailsSuccess,
  updatedLabSaleDetails,
  //payment
  getBillingPaymentModeRequest,
  getBillingPaymentModeSuccess,
  getBillingPaymentModeFail,
  //view details
  getViewBillingDetailsRequest,
  getViewBillingDetailsSuccess,
  getViewBillingDetailsFail,
  getViewBillingDetailsNextRequest,
  getViewBillingDetailsPreviousRequest,
  getBillingDetailsRequest,
  getBillingDetailsSuccess,
  getBillingDetailsFail,
  getPrintBillingDetailsRequest,
  getPrintBillingDetailsSuccess,
  getPrintBillingDetailsFail,
  getBillingPrintCountRequest,
  getBillingPrintCountSuccess,
  getBillingPrintCountFail,
  billingPrintCountRequest,
  billingPrintCountSuccess,
  billingPrintCountFail,
  getPrintBillingCustomerDetailsSuccess,
  getPrintBillingCustomerDetailsFail,
  //additional charge
  getBillingAdditionalChargeRequest,
  getBillingAdditionalChargeSuccess,
  getBillingAdditionalChargeFail,
  getBillingCheckFollowUpRequest,
  getBillingCheckFollowUpSuccess,
  getBillingCheckFollowUpFail,
} = Billing.actions;
export default Billing.reducer;
