import {
  getTestClinicReportCustomerRequest,
  getTestClinicReportTestRequest,
  getTestClinicReportUserRequest,
  getTestClinicReportAllSummaryRequest,
  getTestClinicReportAllDetailRequest,
  getTestClinicReportQuickSummaryRequest,
  getTestClinicReportQuickDetailRequest,
  //reducer get data
  getClinicSaleReportTestSuccess,
  getClinicSaleReportTestFail,
  getTestClinicReportCustomerSuccess,
  getTestClinicReportCustomerFail,
  getTestClinicReportUserSuccess,
  getTestClinicReportUserFail,
  getTestClinicReportQuickDetailSuccess,
  getTestClinicReportQuickDetailFail,
  getTestClinicReportAllDetailSuccess,
  getTestClinicReportAllDetailFail,
  getTestClinicReportQuickSummarySuccess,
  getTestClinicReportQuickSummaryFail,
  getTestClinicReportAllSummarySuccess,
  getTestClinicReportAllSummaryFail,
  getTestClinicReportDetailSuccess,
  getTestClinicReportDetailFail,
  loadingTestClinicReportDetail,
  getTestClinicReportPreviousRequest,
  getTestClinicReportNextRequest,
  getTestClinicReportDetailRequest,
} from "./clinicTestReportSlice";
import { mergeMap } from "rxjs";
import * as API from "./api";
import { map, filter } from "rxjs/operators";
import { Observable } from "rxjs";
import { Action } from "@reduxjs/toolkit";
import { combineEpics } from "redux-observable";
import { getNext, getPrevious } from "../../../../CommonAppRedux/api";
import {
  dispatchAction,
  stateAction,
} from "../../../../../AppUtils/Utils/globalTypes";
import { alertErrorAction } from "../../../../CommonAppRedux/CommonAppSlice";
import messages from "../../../../../AppUtils/Utils/validationConstants";
export const controller = new AbortController();
const getTestClinicReportCustomerEpic = (
  action$: Observable<Action>,
  _: stateAction,
  { dispatch }: dispatchAction
) =>
  action$.pipe(
    filter(getTestClinicReportCustomerRequest.match),
    mergeMap(async (action) => {
      try {
        const response = await API.getTestClinicReportCustomer(action.payload);
        return { payload: response.data };
      } catch (e) {
        return { error: e };
      }
    }),
    map((action) =>
      action?.payload
        ? getTestClinicReportCustomerSuccess(action?.payload)
        : getTestClinicReportCustomerFail()
    )
  );
//user
const getTestClinicReportUserEpic = (
  action$: Observable<Action>,
  _: stateAction,
  { dispatch }: dispatchAction
) =>
  action$.pipe(
    filter(getTestClinicReportUserRequest.match),
    mergeMap(async (action) => {
      try {
        const response = await API.getTestClinicReportUser();
        return { payload: response.data };
      } catch (e) {
        return { error: e };
      }
    }),
    map((action) =>
      action?.payload
        ? getTestClinicReportUserSuccess(action?.payload)
        : getTestClinicReportUserFail()
    )
  );
//item
const getClinicSaleReportTestEpic = (
  action$: Observable<Action>,
  _: stateAction,
  { dispatch }: dispatchAction
) =>
  action$.pipe(
    filter(getTestClinicReportTestRequest.match),
    mergeMap(async (action) => {
      try {
        const response = await API.getClinicSaleReportTest();
        return { payload: response.data };
      } catch (e) {
        return { error: e };
      }
    }),
    map((action) =>
      action?.payload
        ? getClinicSaleReportTestSuccess(action?.payload)
        : getClinicSaleReportTestFail()
    )
  );

//get all Sale summary detail
const getTestClinicReportAllSummaryEpic = (
  action$: Observable<Action>,
  _: stateAction,
  { dispatch }: dispatchAction
) =>
  action$.pipe(
    filter(getTestClinicReportAllSummaryRequest.match),
    mergeMap(async (action) => {
      try {
        const response = await API.getTestClinicReportAllReport(
          action?.payload
        );
        response?.data?.count === 0 &&
          dispatch(alertErrorAction(messages?.reportData));
        return { payload: response.data };
      } catch (e) {
        return { error: e };
      }
    }),
    map((action) =>
      action?.payload
        ? getTestClinicReportAllSummarySuccess(action?.payload)
        : getTestClinicReportAllSummaryFail()
    )
  );
//quick summary
const getTestClinicReportQuickSummaryEpic = (
  action$: Observable<Action>,
  _: stateAction,
  { dispatch }: dispatchAction
) =>
  action$.pipe(
    filter(getTestClinicReportQuickSummaryRequest.match),
    mergeMap(async (action) => {
      try {
        const response = await API.getTestClinicReportQuickReport(
          action.payload
        );
        response?.data?.count === 0 &&
          dispatch(alertErrorAction(messages?.reportData));
        return { payload: response.data };
      } catch (e) {
        return { error: e };
      }
    }),
    map((action) =>
      action?.payload
        ? getTestClinicReportQuickSummarySuccess(action?.payload)
        : getTestClinicReportQuickSummaryFail()
    )
  );

//get all Sale summary detail
const getTestClinicReportAllDetailEpic = (
  action$: Observable<Action>,
  _: stateAction,
  { dispatch }: dispatchAction
) =>
  action$.pipe(
    filter(getTestClinicReportAllDetailRequest.match),
    mergeMap(async (action) => {
      try {
        const response = await API.getTestClinicReportAllReport(
          action?.payload
        );
        response?.data?.count === 0 &&
          dispatch(alertErrorAction(messages?.reportData));
        return { payload: response.data };
      } catch (e) {
        return { error: e };
      }
    }),
    map((action) =>
      action?.payload
        ? getTestClinicReportAllDetailSuccess(action?.payload)
        : getTestClinicReportAllDetailFail()
    )
  );
//quick Detail
const getTestClinicReportQuickDetailEpic = (
  action$: Observable<Action>,
  _: stateAction,
  { dispatch }: dispatchAction
) =>
  action$.pipe(
    filter(getTestClinicReportQuickDetailRequest.match),
    mergeMap(async (action) => {
      try {
        const response = await API.getTestClinicReportQuickReport(
          action.payload
        );
        response?.data?.count === 0 &&
          dispatch(alertErrorAction(messages?.reportData));
        return { payload: response.data };
      } catch (e) {
        return { error: e };
      }
    }),
    map((action) =>
      action?.payload
        ? getTestClinicReportQuickDetailSuccess(action?.payload)
        : getTestClinicReportQuickDetailFail()
    )
  );

//next and previous
//get next
const getTestClinicReportNext = (
  action$: Observable<Action>,
  _: stateAction,
  { dispatch }: dispatchAction
) =>
  action$.pipe(
    filter(getTestClinicReportNextRequest.match),
    mergeMap(async (action) => {
      dispatch(loadingTestClinicReportDetail());
      try {
        const response = await getNext(action.payload);
        return { payload: response.data };
      } catch (e) {
        return { error: e };
      }
    }),
    map((action) =>
      action?.payload
        ? getTestClinicReportDetailSuccess(action?.payload)
        : getTestClinicReportDetailFail()
    )
  );
//get previous
const getTestClinicReportPrevious = (
  action$: Observable<Action>,
  _: stateAction,
  { dispatch }: dispatchAction
) =>
  action$.pipe(
    filter(getTestClinicReportPreviousRequest.match),
    mergeMap(async (action) => {
      dispatch(loadingTestClinicReportDetail());
      try {
        const response = await getPrevious(action.payload);
        return { payload: response.data };
      } catch (e) {
        return { error: e };
      }
    }),
    map((action) =>
      action?.payload
        ? getTestClinicReportDetailSuccess(action?.payload)
        : getTestClinicReportDetailFail()
    )
  );
//het Sale report details
const getTestClinicReportEpic = (
  action$: Observable<Action>,
  _: stateAction,
  { dispatch }: dispatchAction
) =>
  action$.pipe(
    filter(getTestClinicReportDetailRequest.match),
    mergeMap(async (action) => {
      try {
        const response = await API.getTestClinicReportQuickReport(
          action.payload
        );
        return { payload: response.data };
      } catch (e) {
        return { error: e };
      }
    }),
    map((action) =>
      action?.payload
        ? getTestClinicReportDetailSuccess(action?.payload)
        : getTestClinicReportDetailFail()
    )
  );
export const testClinicReportEpics = combineEpics(
  getTestClinicReportCustomerEpic,
  getClinicSaleReportTestEpic,
  getTestClinicReportUserEpic,
  getTestClinicReportAllSummaryEpic,
  getTestClinicReportQuickSummaryEpic,
  getTestClinicReportQuickDetailEpic,
  getTestClinicReportAllDetailEpic,
  getTestClinicReportPrevious,
  getTestClinicReportNext
  // getTestClinicReportEpic
);
