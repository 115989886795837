import { axiosInstance } from "../../../../AppUtils/Utils/axios";
import { filterApiData } from "../../../../AppUtils/Utils/appFunctions";
import {
  getApiProps,
  searchProps,
} from "../../../../AppUtils/Utils/globalTypes";

const departmentModURL = "api/v1/lab-app/department";

//get Department data api
export const getDepartment = (data: getApiProps) => {
  const { rowsPerPage, page } = data;
  return axiosInstance.get(
    `${departmentModURL}?offset=${
      rowsPerPage * page
    }&limit=${rowsPerPage}${
      data?.filterData ? filterApiData(data?.filterData) : ""
    }`
  );
};
//handle search
export const searchDepartment = ({ rowsPerPage, search }: searchProps) => {
  return `${departmentModURL}?offset=0&limit=${rowsPerPage}&search=${search}`;
};
//create Department
export const createDepartment = (body: string | FormData) =>
  axiosInstance.post(`${departmentModURL}`, body);
//update Department
export const updateDepartment = (body: string | FormData, id: number) =>
  axiosInstance.patch(`${departmentModURL}/${id}`, body);
  
  export const getDepartmentCustomerType = () =>
  axiosInstance.get(`${departmentModURL}/customer-type?offset=0&limit=${0}`);
  

  export const getDepartmentById = (id: number) => {
    return axiosInstance.get(`${departmentModURL}/${id}`);
  };