import { customerNoteProps } from "./types";
import { createSlice } from "@reduxjs/toolkit";
const initialState: customerNoteProps = {
  customerNote: [],
  loadingCustomerNote: false,
  count: 0,
  next: 0,
  previous: 0,
  edit: false,
  editedCustomerNote: null,
  loadingCreateNoteButton: false,
  customerNoteTemplates: [],
  loadingCustomerNoteTemplates: false,
  filterCustomerNoteTemplate: null,
  selectedCustomerNote: [],
  loadingSelectedCustomerNote: false,
};

export const noteSlice = createSlice({
  name: "customerNoteReducer",
  initialState,
  reducers: {
    getCustomerNoteRequest: (state, payload) => {
      state.loadingCustomerNote = true;
    },
    getCustomerNoteSuccess: (state, { payload: { results, count } }) => {
      state.loadingCustomerNote = false;
      state.customerNote = results;
      state.count = count;
    },
    getCustomerNoteFail: (state) => {
      state.loadingCustomerNote = false;
    },

    createCustomerNoteRequest: (state, { payload }) => {
      state.loadingCreateNoteButton = true;
    },
    createCustomerNoteSuccess: (state) => {
      state.loadingCreateNoteButton = false;
    },
    createCustomerNoteFail: (state) => {
      state.loadingCreateNoteButton = false;
    },
    updateCustomerNoteRequest: (state, { payload }) => {
      state.loadingCreateNoteButton = true;
    },
    updateCustomerNoteSuccess: (state) => {
      state.loadingCreateNoteButton = false;
    },
    updateCustomerNoteFail: (state) => {
      state.loadingCreateNoteButton = false;
    },
    clearAllCustomerNote: (state) => {
      state.edit = false;
      state.editedCustomerNote = null;
    },
    getEditCustomerNoteByIdRequest: (state, payload) => {
      state.edit = true;
    },
    editCustomerNoteSuccess: (state, { payload }) => {
      state.edit = true;
      state.editedCustomerNote = payload;
    },
    editCustomerNoteFail: (state) => {
      state.edit = false;
      state.editedCustomerNote = null;
    },
    getCustomerNoteTemplateRequest: (state) => {
      state.loadingCustomerNoteTemplates = true;
    },
    getCustomerNoteTemplateSuccess: (state, { payload: { results } }) => {
      state.customerNoteTemplates = results;
      state.loadingCustomerNoteTemplates = false;
    },
    getCustomerNoteTemplateFail: (state) => {
      state.loadingCustomerNoteTemplates = false;
    },
    getfilterCustomerNoteTemplateRequest: (state, payload) => {
      state.loadingCustomerNoteTemplates = true;
    },
    getfilterCustomerNoteTemplateSuccess: (state, { payload }) => {
      state.loadingCustomerNoteTemplates = false;
      state.filterCustomerNoteTemplate = payload;
    },
    getfilterCustomerNoteTemplateFail: (state) => {
      state.loadingCustomerNoteTemplates = false;
    },
    getSelectedCustomerNoteRequest: (state, payload) => {
      state.loadingSelectedCustomerNote = true;
    },
    getSelectedCustomerNoteSuccess: (state, { payload }) => {
      state.loadingSelectedCustomerNote = false;
      state.selectedCustomerNote = [...state.selectedCustomerNote, payload];
    },
    getSelectedCustomerNoteFail: (state) => {
      state.loadingSelectedCustomerNote = false;
    },
  },
});

export const {
  getCustomerNoteRequest,
  getCustomerNoteSuccess,
  getCustomerNoteFail,
  createCustomerNoteRequest,
  createCustomerNoteSuccess,
  createCustomerNoteFail,
  editCustomerNoteSuccess,
  editCustomerNoteFail,
  updateCustomerNoteRequest,
  updateCustomerNoteSuccess,
  updateCustomerNoteFail,
  clearAllCustomerNote,
  getEditCustomerNoteByIdRequest,
  getCustomerNoteTemplateRequest,
  getCustomerNoteTemplateSuccess,
  getCustomerNoteTemplateFail,
  getfilterCustomerNoteTemplateRequest,
  getfilterCustomerNoteTemplateSuccess,
  getfilterCustomerNoteTemplateFail,
  getSelectedCustomerNoteRequest,
  getSelectedCustomerNoteSuccess,
  getSelectedCustomerNoteFail,
} = noteSlice.actions;

export default noteSlice.reducer;
