import { axiosInstance } from "../../../../AppUtils/Utils/axios";
import { filterApiData } from "../../../../AppUtils/Utils/appFunctions";
import {
  getApiProps,
  searchProps,
} from "../../../../AppUtils/Utils/globalTypes";

const referenceRangeModURL = "api/v1/lab-app/reference-range";

//get ReferenceRange data api
export const getReferenceRange = ({ id, search }: any) => {
  return axiosInstance.get<any>(
    `${referenceRangeModURL}?offset=0&limit=0&test=${id}&search=${
      search ? search : ""
    }`
  );
};

export const getReferenceRangeDepartment = ({ rowsPerPage }: any) => {
  return axiosInstance.get(
    `/${referenceRangeModURL}/department?offset=0&limit=${rowsPerPage}`
  );
};

export const getReferenceRangeTest = ({ rowsPerPage }: any) => {
  return axiosInstance.get(
    `/${referenceRangeModURL}/test?offset=0&limit=${rowsPerPage}`
  );
};
export const getReferenceFilteredTest = ({ rowsPerPage, department }: any) => {
  return axiosInstance.get(
    `/${referenceRangeModURL}/test?department=${department}&offset=0&limit=${rowsPerPage}`
  );
};
export const getReferenceRangeTestMethod = ({ rowsPerPage }: any) => {
  return axiosInstance.get(
    `/${referenceRangeModURL}/test-method?offset=0&limit=${rowsPerPage}`
  );
};
export const getReferenceRangeTestUnit = ({ rowsPerPage }: any) => {
  return axiosInstance.get(
    `/${referenceRangeModURL}/test-unit?offset=0&limit=${rowsPerPage}`
  );
};

//handle search
export const searchReferenceRange = ({ rowsPerPage, search }: searchProps) => {
  return `/${referenceRangeModURL}?offset=0&limit=${rowsPerPage}&search=${search}`;
};
//create ReferenceRange
export const createReferenceRange = (body: string | FormData) =>
  axiosInstance.post(`/${referenceRangeModURL}`, body);
//update ReferenceRange
export const updateReferenceRange = (body: string | FormData, id: number) =>
  axiosInstance.patch(`/${referenceRangeModURL}/${id}`, body);

//get ReferenceRange data by id
export const getReferenceRangeById = (id: number) => {
  return axiosInstance.get<any>(`${referenceRangeModURL}/${id}`);
};
