import React from "react";
import spinner from "../../Assets/spinner.gif";
const Spinner = ({ color }: any) => {
  return (
    <img
      src={spinner}
      alt="spinner"
      color={color}
      height="30px"
      className="ml-4"
    />
  );
};

export default Spinner;
