import * as React from "react";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import MoreVertIcon from "@mui/icons-material/MoreVert";
import AppButton from "../AppElements/Button/AppButton";
import { verticalMenuProps } from "./types";

export default function VerticalMenusForPurchase({
  handleShowDetail,
  handleVerify,
  handleEdit,
  handlePrintPdfReport,
  row,
}: verticalMenuProps) {

  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);

  const handleClick = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleEditClick = () => {
    if (handleEdit) {
      handleEdit(row);
    }
    handleClose();
  };

  const handleShowDetailClick = () => {
    if (handleShowDetail) {
      handleShowDetail(row);
    }
    handleClose();
  };


  return (
    <>
      <AppButton
        tooltipTitle="More Options"
        submit={false}
        onClick={handleClick}
      >
        <MoreVertIcon fontSize="large" />
      </AppButton>
      <Menu anchorEl={anchorEl} open={open} onClose={handleClose}>
        <MenuItem
          onClick={handleShowDetailClick}
          disableRipple
        >
          Show Detail
        </MenuItem>
        {!row?.isVerified && (
          <MenuItem
            onClick={() => handleVerify?.(row, handleClose)}
            disableRipple
          >
            Verify
          </MenuItem>
        )}
        <MenuItem onClick={handleEditClick} disableRipple>
          Edit
        </MenuItem>
        <MenuItem onClick={() => handlePrintPdfReport?.(row, handleClose)} disableRipple>
          Export Pdf Report
        </MenuItem>
      </Menu>
    </>
  );
}
