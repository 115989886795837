import TablePagination from "@mui/material/TablePagination";
import { memo } from "react";
import {
  useAppDispatch,
  useAppSelector,
} from "../../../AppUtils/Utils/appHooks";
import {
  commonAppPage,
  commonAppRowsPerPage,
} from "../../../Pages/CommonAppRedux/CommonAppSlice";
import { Grid } from "@mui/material";
import { commonAppSelector } from "../../../Pages/CommonAppRedux/selector";
import TablePaginationActions from "./TablePaginationAction";

const Pagination = ({ count, handleClick, viewDetail }: any) => {
  const { page, rowsPerPage } = useAppSelector(commonAppSelector);
  const dispatch = useAppDispatch();
  const handleChangePage = async (event: unknown, newPage: number) => {
    //change page
    dispatch(commonAppPage(newPage));
    if (page - newPage === -1) {
      handleClick("next");
    } else if (page - newPage === 1) {
      handleClick("previous");
    } else {
      handleClick("firstAndLastData");
    }
  };
  const handleChangeRowsPerPage = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    //set rows per page
    dispatch(commonAppRowsPerPage(parseInt(event.target.value, 10)));
    //set page to 0
    dispatch(commonAppPage(0));
  };
  return (
    <Grid container justifyContent="space-between" alignItems="center">
      <Grid item>
        {/* { label: "All", value: 9999 } */}
        <TablePagination
          rowsPerPageOptions={[2, 10, 100, 200]}
          component="div"
          count={count}
          rowsPerPage={rowsPerPage}
          page={page}
          onPageChange={handleChangePage}
          onRowsPerPageChange={handleChangeRowsPerPage}
          ActionsComponent={TablePaginationActions}
        />
      </Grid>
    </Grid>
  );
};

export default memo(Pagination);
