import {
  createItemUnitSuccess,
  createItemUnitFail,
  createItemUnitRequest,
  getItemUnitSuccess,
  getItemUnitFail,
  loadingItemUnit,
  updateItemUnitFail,
  updateItemUnitSuccess,
  getItemUnitRequest,
  updateItemUnitRequest,
  getItemUnitNextRequest,
  getItemUnitPreviousRequest,
  clearItemUnitData,
} from "./itemUnitSlice";
import { mergeMap } from "rxjs";
import { createItemUnit, getItemUnit, updateItemUnit } from "./api";
import { map, filter, tap } from "rxjs/operators";
import { Observable } from "rxjs";
import { Action } from "@reduxjs/toolkit";
import { combineEpics } from "redux-observable";
import {
  alertErrorAction,
  alertSuccessAction,
  closeModal,
} from "../../../CommonAppRedux/CommonAppSlice";
import { getNext, getPrevious } from "../../../CommonAppRedux/api";
import {
  dispatchAction,
  stateAction,
} from "../../../../AppUtils/Utils/globalTypes";
import messages from "../../../../AppUtils/Utils/validationConstants";

export const controller = new AbortController();

// get itemUnit epic
const getItemUnitEpic = (
  action$: Observable<Action>,
  _: stateAction,
  { dispatch }: dispatchAction
) =>
  action$.pipe(
    filter(getItemUnitRequest.match),
    mergeMap(async (action) => {
      dispatch(loadingItemUnit());
      try {
        const response = await getItemUnit(action.payload);
        return { payload: response.data };
      } catch (e) {
        return { error: e };
      }
    }),
    map((action) =>
      action?.payload ? getItemUnitSuccess(action?.payload) : getItemUnitFail()
    )
  );
//get next
const getItemUnitNext = (
  action$: Observable<Action>,
  _: stateAction,
  { dispatch }: dispatchAction
) =>
  action$.pipe(
    filter(getItemUnitNextRequest.match),
    mergeMap(async (action) => {
      dispatch(loadingItemUnit());
      try {
        const response = await getNext(action.payload);
        return { payload: response.data };
      } catch (e) {
        return { error: e };
      }
    }),
    map((action) =>
      action?.payload ? getItemUnitSuccess(action?.payload) : getItemUnitFail()
    )
  );

//get previous
const getItemUnitPrevious = (
  action$: Observable<Action>,
  _: stateAction,
  { dispatch }: dispatchAction
) =>
  action$.pipe(
    filter(getItemUnitPreviousRequest.match),
    mergeMap(async (action) => {
      dispatch(loadingItemUnit());
      try {
        const response = await getPrevious(action.payload);
        return { payload: response.data };
      } catch (e) {
        return { error: e };
      }
    }),
    map((action) =>
      action?.payload ? getItemUnitSuccess(action?.payload) : getItemUnitFail()
    )
  );
//create ItemUnit epic
const createItemUnitEpic = (
  action$: Observable<Action>,
  _: stateAction,
  { dispatch }: dispatchAction
) =>
  action$.pipe(
    filter(createItemUnitRequest.match),
    mergeMap(
      async ({
        payload: { values, rowsPerPage, page, setShowModalFromAnotherModule },
      }) => {
        const { name, shortForm, displayOrder, active } = values;
        try {
          const body = JSON.stringify({
            name,
            shortForm,
            displayOrder,
            active,
          });
          const response = await createItemUnit(body);
          if (response) {
            dispatch(getItemUnitRequest({ rowsPerPage, page }));
            dispatch(alertSuccessAction(messages.createMessage));
            setShowModalFromAnotherModule
              ? setShowModalFromAnotherModule(false)
              : dispatch(closeModal());
          }
          return { payload: { response } };
        } catch (e) {
          dispatch(alertErrorAction(messages.createFailMessage));
          return { error: e };
        }
      }
    ),
    map((action) => {
      return action?.payload ? createItemUnitSuccess() : createItemUnitFail();
    })
  );

//update ItemUnit epic
const updateItemUnitEpic = (
  action$: Observable<Action>,
  _: stateAction,
  { dispatch }: dispatchAction
) =>
  action$.pipe(
    filter(updateItemUnitRequest.match),
    mergeMap(async ({ payload: { values, id, rowsPerPage, page } }) => {
      const { name, shortForm, displayOrder, active, remarks } = values;
      try {
        const body = JSON.stringify({
          name,
          shortForm,
          displayOrder,
          active,
          remarks,
        });
        const response = await updateItemUnit(body, id);
        if (response) {
          dispatch(getItemUnitRequest({ rowsPerPage, page }));
          dispatch(alertSuccessAction(messages.updateMessage));
          dispatch(clearItemUnitData());
          dispatch(closeModal());
        }
        return { payload: { response, rowsPerPage } };
      } catch (e) {
        dispatch(alertErrorAction(messages.updateFailMessage));
        return { error: e };
      }
    }),
    map((action) =>
      action?.payload ? updateItemUnitSuccess() : updateItemUnitFail()
    )
  );

export const itemUnitEpics = combineEpics(
  getItemUnitEpic,
  createItemUnitEpic,
  updateItemUnitEpic,
  getItemUnitNext,
  getItemUnitPrevious
);
