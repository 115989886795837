import { rowProps } from "../../Component/Table/types";

//check if cash type already exists
const isChecked = (cashPaymentMode: rowProps[]) => {
  return cashPaymentMode?.some((payment) => payment.cash);
};

// convert array to string
const splitArrayOfNumberToStringById = (data: number[]) => data?.join(",");
//filter by id
const filterById = (data: rowProps[], id: number | string, fieldName: string) =>
  data?.filter((data) => data[fieldName] === id) as any;

// get array of specified filed
const getArrayOfSpecifiedField = (data: any, specifiedKey: string) =>
  data?.map((dataObj: any) => dataObj[specifiedKey]);
// get element of specified field
const findElementOfSpecifiedField = (data: any, specifiedKey: string) =>
  data?.find((currentValue: rowProps) => currentValue[specifiedKey]);

//filter by id
const findById = (data: rowProps[], id: number | string, fieldName: string) =>
  data?.find((data) => data[fieldName] === id) as any;
const ArrayUtils = {
  getArrayOfSpecifiedField,
  splitArrayOfNumberToStringById,
  filterById,
  isChecked,
  findElementOfSpecifiedField,
  findById,
};
export default ArrayUtils;
