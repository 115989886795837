import {
  createTestCategoryFail,
  createTestCategoryRequest,
  createTestCategorySuccess,
  getTestCategorySuccess,
  getTestCategoryFail,
  updateTestCategoryFail,
  updateTestCategorySuccess,
  getTestCategoryRequest,
  updateTestCategoryRequest,
  getTestCategoryNextRequest,
  getTestCategoryPreviousRequest,
  clearTestCategoryData,
  getTestCategoryByIdRequest,
  testCategoryEditSuccess,
  testCategoryEditFail,
} from "./testCategorySlice";
import { mergeMap } from "rxjs";
import { createTestCategory, getTestCategory, getTestCategoryById, updateTestCategory } from "./api";
import { map, filter } from "rxjs/operators";
import { Observable } from "rxjs";
import { Action } from "@reduxjs/toolkit";
import { combineEpics } from "redux-observable";
import {
  alertErrorAction,
  alertSuccessAction,
  closeModal,
} from "../../../CommonAppRedux/CommonAppSlice";
import { getNext, getPrevious } from "../../../CommonAppRedux/api";
import {
  dispatchAction,
  stateAction,
} from "../../../../AppUtils/Utils/globalTypes";
import messages from "../../../../AppUtils/Utils/validationConstants";

export const controller = new AbortController();

// get manufacture epic
const getTestCategoryEpic = (
  action$: Observable<Action>,
  _: stateAction,
  { dispatch }: dispatchAction
) =>
  action$.pipe(
    filter(getTestCategoryRequest.match),
    mergeMap(async (action) => {
      try {
        const response = await getTestCategory(action.payload);
        return { payload: response.data };
      } catch (e) {
        return { error: e };
      }
    }),
    map((action) =>
      action?.payload
        ? getTestCategorySuccess(action?.payload)
        : getTestCategoryFail()
    )
);

//get test category by id epic
const getTestCategoryByIdEpic = (
  action$: Observable<Action>,
  _: stateAction,
  { dispatch }: dispatchAction
) => action$.pipe(
  filter(getTestCategoryByIdRequest.match),
  mergeMap(async (action) => {
    try {
      const response = await getTestCategoryById(action.payload);
      return { payload: response.data};
    } catch(e) {
      return { error: e};
    }
  }),
  map((action) => action?.payload ? testCategoryEditSuccess(action.payload) : testCategoryEditFail())
)

//get next
const getTestCategoryNext = (
  action$: Observable<Action>,
  _: stateAction,
  { dispatch }: dispatchAction
) =>
  action$.pipe(
    filter(getTestCategoryNextRequest.match),
    mergeMap(async (action) => {
      try {
        const response = await getNext(action.payload);
        return { payload: response.data };
      } catch (e) {
        return { error: e };
      }
    }),
    map((action) =>
      action?.payload
        ? getTestCategorySuccess(action?.payload)
        : getTestCategoryFail()
    )
  );

//get previous
const getTestCategoryPrevious = (
  action$: Observable<Action>,
  _: stateAction,
  { dispatch }: dispatchAction
) =>
  action$.pipe(
    filter(getTestCategoryPreviousRequest.match),
    mergeMap(async (action) => {
      try {
        const response = await getPrevious(action.payload);
        return { payload: response.data };
      } catch (e) {
        return { error: e };
      }
    }),
    map((action) =>
      action?.payload
        ? getTestCategorySuccess(action?.payload)
        : getTestCategoryFail()
    )
  );
//create TestCategory epic
const createTestCategoryEpic = (
  action$: Observable<Action>,
  _: stateAction,
  { dispatch }: dispatchAction
) =>
  action$.pipe(
    filter(createTestCategoryRequest.match),
    mergeMap(async ({ payload: { values, rowsPerPage, page ,setShowModalFromAnotherModule} }) => {
      try {
        const body = JSON.stringify(values);
        const response = await createTestCategory(body);
        if (response) {
          dispatch(getTestCategoryRequest({ rowsPerPage, page }));
          dispatch(alertSuccessAction(messages.createMessage));
          setShowModalFromAnotherModule
          ? setShowModalFromAnotherModule(false)
          : dispatch(closeModal());
        }
        return { payload: { response } };
      } catch (e) {
        dispatch(alertErrorAction(messages.createFailMessage));
        return { error: e };
      }
    }),
    map((action) => {
      return action?.payload
        ? createTestCategorySuccess()
        : createTestCategoryFail();
    })
  );

//update TestCategory epic
const updateTestCategoryEpic = (
  action$: Observable<Action>,
  _: stateAction,
  { dispatch }: dispatchAction
) =>
  action$.pipe(
    filter(updateTestCategoryRequest.match),
    mergeMap(async ({ payload: { values, id, rowsPerPage, page } }) => {
      try {
        const body = JSON.stringify(values);
        const response = await updateTestCategory(body, id);
        if (response) {
          dispatch(getTestCategoryRequest({ rowsPerPage, page }));
          dispatch(alertSuccessAction(messages.updateMessage));
          dispatch(clearTestCategoryData());
          dispatch(closeModal());
        }
        return { payload: { response, rowsPerPage } };
      } catch (e) {
        dispatch(alertErrorAction(messages.updateFailMessage));
        return { error: e };
      }
    }),
    map((action) =>
      action?.payload ? updateTestCategorySuccess() : updateTestCategoryFail()
    )
  );

export const testCategoryEpics = combineEpics(
  getTestCategoryEpic,
  createTestCategoryEpic,
  updateTestCategoryEpic,
  getTestCategoryNext,
  getTestCategoryPrevious,
  getTestCategoryByIdEpic,
);
