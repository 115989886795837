import { axiosInstance } from "../../../../AppUtils/Utils/axios";
import { filterApiData } from "../../../../AppUtils/Utils/appFunctions";
import {
  getApiProps,
  searchProps,
} from "../../../../AppUtils/Utils/globalTypes";

const testCategoryModURL = "api/v1/lab-app/test-category";

//get TestCategory data api
export const getTestCategory = (data: getApiProps) => {
  const { rowsPerPage, page } = data;
  return axiosInstance.get(
    `${testCategoryModURL}?offset=${
      rowsPerPage * page
    }&limit=${rowsPerPage}${
      data?.filterData ? filterApiData(data?.filterData) : ""
    }`
  );
};

//get test category by id
export const getTestCategoryById = (id: number) => {
  return axiosInstance.get(`${testCategoryModURL}/${id}`)
};

//handle search
export const searchTestCategory = ({ rowsPerPage, search }: searchProps) => {
  return `${testCategoryModURL}?offset=0&limit=${rowsPerPage}&search=${search}`;
};
//create TestCategory
export const createTestCategory = (body: string | FormData) =>
  axiosInstance.post(`${testCategoryModURL}`, body);
//update TestCategory
export const updateTestCategory = (body: string | FormData, id: number) =>
  axiosInstance.patch(`${testCategoryModURL}/${id}`, body);
