import { createAction, createSlice } from "@reduxjs/toolkit";

const initialState: any = {
  sensitiveMedicines: [],
  edit: false,
  sensitiveMedicine: null,
  loadingSensitiveMedicine: false,
  loadingCreateSensitiveMedicine:false,
  count: 0,
  next: "",
  previous: "",
};

export const updateSensitiveMedicineRequest = createAction(
  "updateSensitiveMedicineRequest",
  function prepare({ values, id, rowsPerPage, page }) {
    return {
      payload: {
        values,
        id,
        rowsPerPage,
        page,
      },
    };
  }
);
export const getSensitiveMedicineRequest = createAction(
  "getSensitiveMedicineRequest",
  function prepare({ rowsPerPage, page, filterData }) {
    return {
      payload: {
        rowsPerPage,
        page,
        filterData,
      },
    };
  }
);
// get next request
export const getSensitiveMedicineNextRequest = createAction(
  "getSensitiveMedicineNextRequest",
  function prepare(next) {
    return {
      payload: next,
    };
  }
);
//get previous request
export const getSensitiveMedicinePreviousRequest = createAction(
  "getSensitiveMedicinePreviousRequest",
  function prepare(previous) {
    return {
      payload: previous,
    };
  }
);
export const SensitiveMedicine = createSlice({
  name: "SensitiveMedicineReducer",
  initialState,
  reducers: {
    loadingSensitiveMedicine: (state) => {
      state.loadingSensitiveMedicine = true;
    },
    getSensitiveMedicineSuccess: (
      state,
      { payload: { results, count, next, previous } }
    ) => {
      state.loadingSensitiveMedicine = false;
      state.sensitiveMedicines = results;
      state.count = count;
      state.next = next;
      state.previous = previous;
    },
    getSensitiveMedicineFail: (state) => {
      state.loadingSensitiveMedicine = false;
    },
    createSensitiveMedicineRequest: (state, payload) => {
      state.loadingCreateSensitiveMedicine = true;
      state.closeModal = true;
    },
    createSensitiveMedicineSuccess: (state) => {
      state.loadingCreateSensitiveMedicine = false;
    },
    createSensitiveMedicineFail: (state) => {
      state.loadingCreateSensitiveMedicine = false;
    },
    sensitiveMedicineEditSuccess: (state, { payload }) => {
      state.edit = true;
      state.sensitiveMedicine = payload;
    },
    updateSensitiveMedicineSuccess: (state) => {
      state.loadingCreateSensitiveMedicine = false;
    },
    updateSensitiveMedicineFail: (state) => {
      state.loadingCreateSensitiveMedicine = false;
    },
    clearSensitiveMedicineData: (state) => {
      state.edit = false;
    },
  },
});
// Action creators are generated for each case reducer function
export const {
  loadingSensitiveMedicine,
  getSensitiveMedicineSuccess,
  getSensitiveMedicineFail,
  createSensitiveMedicineRequest,
  createSensitiveMedicineSuccess,
  createSensitiveMedicineFail,
  sensitiveMedicineEditSuccess,
  updateSensitiveMedicineSuccess,
  updateSensitiveMedicineFail,
  clearSensitiveMedicineData,
} = SensitiveMedicine.actions;
export default SensitiveMedicine.reducer;
