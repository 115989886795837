import {
  createDeviceOrderFail,
  createDeviceOrderRequest,
  createDeviceOrderSuccess,
  getDeviceOrderSuccess,
  getDeviceOrderFail,
  getDeviceOrderCategoryRequest,
  getDeviceOrderCategorySuccess,
  getDeviceOrderCategoryFail,
  getDeviceOrderDepartmentRequest,
  getDeviceOrderDepartmentSuccess,
  getDeviceOrderDepartmentFail,
  getDeviceOrderCustomerTypeRequest,
  getDeviceOrderCustomerTypeSuccess,
  getDeviceOrderCustomerTypeFail,
  updateDeviceOrderFail,
  updateDeviceOrderSuccess,
  getDeviceOrderRequest,
  updateDeviceOrderRequest,
  getDeviceOrderNextRequest,
  getDeviceOrderPreviousRequest,
  clearDeviceOrderData,
  getDeviceOrderByIdRequest,
  deviceOrderEditSuccess,
  deviceOrderEditFail,
  //
  getDeviceOrderCustomerRequest,
  getDeviceOrderCustomerSuccess,
  getDeviceOrderCustomerFail,
  //
  getDeviceOrderItemRequest,
  getDeviceOrderItemSuccess,
  getDeviceOrderItemFail,
  getDeviceOrderItemCategoryRequest,
  getDeviceOrderItemCategorySuccess,
  getDeviceOrderItemCategoryFail,
  //
  updateDeviceReceivedStatusRequest,
  updateDeviceReceivedStatusSuccess,
  updateDeviceReceivedStatusFail,
  //
  updateDeviceDispatchedStatusRequest,
  updateDeviceDispatchedStatusSuccess,
  updateDeviceDispatchedStatusFail,
  getDeviceOrderReferrerRequest,
  getDeviceOrderReferrerSuccess,
  getDeviceOrderReferrerFail,
} from "./deviceOrderSlice";
import { mergeMap } from "rxjs";
import {
  createDeviceOrder,
  getDeviceOrder,
  updateDeviceOrder,
  getDeviceOrderCustomerType,
  getDeviceOrderCategory,
  getDeviceOrderDepartment,
  getDeviceOrderById,
  getDeviceOrderItem,
  getDeviceOrderItemCategory,
  updateDeviceReceivedStatus,
  updateDeviceDispatchedStatus,
  getDeviceOrderCustomer,
  getDeviceOrderReferrer,
} from "./api";
import { map, filter, tap } from "rxjs/operators";
import { Observable } from "rxjs";
import { Action } from "@reduxjs/toolkit";
import { combineEpics } from "redux-observable";
import {
  alertErrorAction,
  alertSuccessAction,
  closeModal,
} from "../../../CommonAppRedux/CommonAppSlice";
import messages from "../../../../AppUtils/Utils/validationConstants";
import {
  dispatchAction,
  stateAction,
} from "../../../../AppUtils/Utils/globalTypes";
import { getNext, getPrevious } from "../../../CommonAppRedux/api";

export const controller = new AbortController();

// get clinicorder epic
const getDeviceOrderEpic = (
  action$: Observable<Action>,
  _: stateAction,
  { dispatch }: dispatchAction
) =>
  action$.pipe(
    filter(getDeviceOrderRequest.match),
    mergeMap(async (action) => {
      try {
        const response = await getDeviceOrder(action.payload);
        return { payload: response.data };
      } catch (e) {
        return { error: e };
      }
    }),
    map((action) =>
      action?.payload
        ? getDeviceOrderSuccess(action?.payload)
        : getDeviceOrderFail()
    )
  );

//get next
const getDeviceOrderNext = (
  action$: Observable<Action>,
  _: stateAction,
  { dispatch }: dispatchAction
) =>
  action$.pipe(
    filter(getDeviceOrderNextRequest.match),
    mergeMap(async (action) => {
      try {
        const response = await getNext(action.payload);
        return { payload: response.data };
      } catch (e) {
        return { error: e };
      }
    }),
    map((action) =>
      action?.payload
        ? getDeviceOrderSuccess(action?.payload)
        : getDeviceOrderFail()
    )
  );

//get previous
const getDeviceOrderPrevious = (
  action$: Observable<Action>,
  _: stateAction,
  { dispatch }: dispatchAction
) =>
  action$.pipe(
    filter(getDeviceOrderPreviousRequest.match),
    mergeMap(async (action) => {
      try {
        const response = await getPrevious(action.payload);
        return { payload: response.data };
      } catch (e) {
        return { error: e };
      }
    }),
    map((action) =>
      action?.payload
        ? getDeviceOrderSuccess(action?.payload)
        : getDeviceOrderFail()
    )
  );
// get clinicorder customer type
const getAllDeviceOrderCustomerType = (
  action$: Observable<Action>,
  _: stateAction,
  { dispatch }: dispatchAction
) =>
  action$.pipe(
    filter(getDeviceOrderCustomerTypeRequest.match),
    mergeMap(async (action) => {
      try {
        const response = await getDeviceOrderCustomerType(action.payload);
        return { payload: response.data };
      } catch (e) {
        return { error: e };
      }
    }),
    map((action) =>
      action?.payload
        ? getDeviceOrderCustomerTypeSuccess(action?.payload)
        : getDeviceOrderCustomerTypeFail()
    )
  );
// get clinicorder department
const getAllDeviceOrderDepartment = (
  action$: Observable<Action>,
  _: stateAction,
  { dispatch }: dispatchAction
) =>
  action$.pipe(
    filter(getDeviceOrderDepartmentRequest.match),
    mergeMap(async (action) => {
      try {
        const response = await getDeviceOrderDepartment(action.payload);
        return { payload: response.data };
      } catch (e) {
        return { error: e };
      }
    }),
    map((action) =>
      action?.payload
        ? getDeviceOrderDepartmentSuccess(action?.payload)
        : getDeviceOrderDepartmentFail()
    )
  );
// get clinicorder category
const getAllDeviceOrderCategory = (
  action$: Observable<Action>,
  _: stateAction,
  { dispatch }: dispatchAction
) =>
  action$.pipe(
    filter(getDeviceOrderCategoryRequest.match),
    mergeMap(async (action) => {
      try {
        const response = await getDeviceOrderCategory(action.payload);
        return { payload: response.data };
      } catch (e) {
        return { error: e };
      }
    }),
    map((action) =>
      action?.payload
        ? getDeviceOrderCategorySuccess(action?.payload)
        : getDeviceOrderCategoryFail()
    )
  );
//create DeviceOrder epic
const createDeviceOrderEpic = (
  action$: Observable<Action>,
  _: stateAction,
  { dispatch }: dispatchAction
) =>
  action$.pipe(
    filter(createDeviceOrderRequest.match),
    mergeMap(async ({ payload: { values, rowsPerPage, page } }) => {
      try {
        const body = JSON.stringify(values);

        const response = await createDeviceOrder(body);
        if (response) {
          dispatch(
            getDeviceOrderRequest({ id: values?.customer, rowsPerPage, page })
          );
          dispatch(alertSuccessAction(messages.createMessage));
          dispatch(closeModal());
          dispatch(clearDeviceOrderData());
        }
        return { payload: { response } };
      } catch (e) {
        dispatch(alertErrorAction(messages.createFailMessage));
        return { error: e };
      }
    }),
    map((action) => {
      return action?.payload
        ? createDeviceOrderSuccess()
        : createDeviceOrderFail();
    })
  );

//update DeviceOrder epic
const updateDeviceOrderEpic = (
  action$: Observable<Action>,
  _: stateAction,
  { dispatch }: dispatchAction
) =>
  action$.pipe(
    filter(updateDeviceOrderRequest.match),
    mergeMap(async ({ payload: { values, id, rowsPerPage, page } }) => {
      try {
        const body = JSON.stringify(values);
        const response = await updateDeviceOrder(body, id);
        if (response) {
          dispatch(getDeviceOrderRequest({ rowsPerPage, page }));
          dispatch(alertSuccessAction(messages.updateMessage));
          dispatch(clearDeviceOrderData());
          dispatch(closeModal());
        }
        return { payload: { response, rowsPerPage } };
      } catch (e) {
        dispatch(alertErrorAction(messages.updateFailMessage));
        return { error: e };
      }
    }),
    map((action) =>
      action?.payload ? updateDeviceOrderSuccess() : updateDeviceOrderFail()
    )
  );

const getDeviceOrderByIdEpic = (
  action$: Observable<Action>,
  _: stateAction,
  { dispatch }: dispatchAction
) =>
  action$.pipe(
    filter(getDeviceOrderByIdRequest.match),
    mergeMap(async (action) => {
      try {
        const response = await getDeviceOrderById(action.payload);
        return { payload: response.data };
      } catch (e) {
        return { error: e };
      }
    }),
    map((action) =>
      action?.payload
        ? deviceOrderEditSuccess(action.payload)
        : deviceOrderEditFail()
    )
  );

//update MoldOrder received status
const updateDeviceReceivedStatusEpic = (
  action$: Observable<Action>,
  _: stateAction,
  { dispatch }: dispatchAction
) =>
  action$.pipe(
    filter(updateDeviceReceivedStatusRequest.match),
    mergeMap(
      async ({
        payload: { values, id, rowsPerPage, page, setShowReceived },
      }) => {
        try {
          const body = JSON.stringify(values);
          const response = await updateDeviceReceivedStatus(body, id);
          if (response) {
            dispatch(getDeviceOrderRequest({ rowsPerPage, page }));
            dispatch(alertSuccessAction(messages.updateMessage));
            dispatch(clearDeviceOrderData());
            dispatch(closeModal());
            setShowReceived && setShowReceived(false);
          }
          return { payload: { response, rowsPerPage } };
        } catch (e) {
          dispatch(alertErrorAction(messages.updateFailMessage));
          return { error: e };
        }
      }
    ),
    map((action) =>
      action?.payload
        ? updateDeviceReceivedStatusSuccess()
        : updateDeviceReceivedStatusFail()
    )
  );

//update MoldOrder dispatched status
const updateDeviceDispatchedStatusEpic = (
  action$: Observable<Action>,
  _: stateAction,
  { dispatch }: dispatchAction
) =>
  action$.pipe(
    filter(updateDeviceDispatchedStatusRequest.match),
    mergeMap(
      async ({
        payload: { values, id, rowsPerPage, page, setShowDispatch },
      }) => {
        try {
          const body = JSON.stringify(values);
          const response = await updateDeviceDispatchedStatus(body, id);
          if (response) {
            dispatch(getDeviceOrderRequest({ rowsPerPage, page }));
            dispatch(alertSuccessAction(messages.updateMessage));
            dispatch(clearDeviceOrderData());
            dispatch(closeModal());
            setShowDispatch && setShowDispatch(false);
          }
          return { payload: { response, rowsPerPage } };
        } catch (e) {
          dispatch(alertErrorAction(messages.updateFailMessage));
          return { error: e };
        }
      }
    ),
    map((action) =>
      action?.payload
        ? updateDeviceDispatchedStatusSuccess()
        : updateDeviceDispatchedStatusFail()
    )
  );

//customer
const getDeviceOrderCustomerEpic = (
  action$: Observable<Action>,
  _: stateAction,
  { dispatch }: dispatchAction
) =>
  action$.pipe(
    filter(getDeviceOrderCustomerRequest.match),
    mergeMap(async (action: any) => {
      try {
        const response = await getDeviceOrderCustomer(action.payload);
        return { payload: response.data };
      } catch (e) {
        return { error: e };
      }
    }),
    map((action) =>
      action?.payload
        ? getDeviceOrderCustomerSuccess(action?.payload)
        : getDeviceOrderCustomerFail()
    )
  );
//item
const getDeviceOrderItemEpic = (
  action$: Observable<Action>,
  _: stateAction,
  { dispatch }: dispatchAction
) =>
  action$.pipe(
    filter(getDeviceOrderItemRequest.match),
    mergeMap(async (action: any) => {
      try {
        const response = await getDeviceOrderItem(action.payload);
        return { payload: response.data };
      } catch (e) {
        return { error: e };
      }
    }),
    map((action) =>
      action?.payload
        ? getDeviceOrderItemSuccess(action?.payload)
        : getDeviceOrderItemFail()
    )
  );
//category item
const getDeviceOrderItemCategoryEpic = (
  action$: Observable<Action>,
  _: stateAction,
  { dispatch }: dispatchAction
) =>
  action$.pipe(
    filter(getDeviceOrderItemCategoryRequest.match),
    mergeMap(async (action: any) => {
      try {
        const response = await getDeviceOrderItemCategory(action.payload);
        return { payload: response.data };
      } catch (e) {
        return { error: e };
      }
    }),
    map((action) =>
      action?.payload
        ? getDeviceOrderItemCategorySuccess(action?.payload)
        : getDeviceOrderItemCategoryFail()
    )
  );

//referrer
const getDeviceOrderReferrerEpic = (
  action$: Observable<Action>,
  _: stateAction,
  { dispatch }: dispatchAction
) =>
  action$.pipe(
    filter(getDeviceOrderReferrerRequest.match),
    mergeMap(async (action: any) => {
      try {
        const response = await getDeviceOrderReferrer(action.payload);
        return { payload: response.data };
      } catch (e) {
        return { error: e };
      }
    }),
    map((action) =>
      action?.payload
        ? getDeviceOrderReferrerSuccess(action?.payload)
        : getDeviceOrderReferrerFail()
    )
  );

export const deviceOrderEpics = combineEpics(
  getDeviceOrderEpic,
  createDeviceOrderEpic,
  updateDeviceOrderEpic,
  getDeviceOrderNext,
  getDeviceOrderPrevious,
  getAllDeviceOrderCustomerType,
  getAllDeviceOrderDepartment,
  getAllDeviceOrderCategory,
  getDeviceOrderByIdEpic,
  getDeviceOrderItemEpic,
  getDeviceOrderCustomerEpic,
  getDeviceOrderItemCategoryEpic,
  updateDeviceReceivedStatusEpic,
  updateDeviceDispatchedStatusEpic,
  getDeviceOrderReferrerEpic
);
