import { axiosInstance } from "../../../../AppUtils/Utils/axios";
import { filterApiData } from "../../../../AppUtils/Utils/appFunctions";
import {
  getApiProps,
  searchProps,
} from "../../../../AppUtils/Utils/globalTypes";

const itemUnitModURL = 'api/v1/inventory-item-app';

//get ItemUnit data api
export const getItemUnit = (data: getApiProps) => {
  const { rowsPerPage, page } = data;
  return axiosInstance.get(
    `${itemUnitModURL}/unit?offset=${
      rowsPerPage * page
    }&limit=${rowsPerPage}${
      data?.filterData ? filterApiData(data?.filterData) : ""
    }`
  );
};
//handle search
export const searchItemUnit = ({ rowsPerPage, search }: searchProps) => {
  return `${itemUnitModURL}/unit?offset=0&limit=${rowsPerPage}&search=${search}`;
};
//create ItemUnit
export const createItemUnit = (body: string | FormData) =>
  axiosInstance.post(`${itemUnitModURL}/unit`, body);
//update ItemUnit
export const updateItemUnit = (body: string | FormData, id: number) =>
  axiosInstance.patch(`${itemUnitModURL}/unit/${id}`, body);
