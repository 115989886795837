import { createSlice } from "@reduxjs/toolkit";
const initialState: any = {
  reagents: [],
  loadingReagent: false,
  loadingCreateReagent: false,
  edit: false,
  reagent: null,
  count: 0,
  previous: "",
  next: "",
};

export const Reagent = createSlice({
  name: "Reagent Reducer",
  initialState,
  reducers: {
    getReagentRequest: (state, payload) => {
      state.loadingReagent = true;
    },
    getReagentSuccess: (
      state,
      { payload: { results, count, next, previous } }
    ) => {
      state.loadingReagent = false;
      state.reagents = results;
      state.count = count;
      state.next = next;
      state.previous = previous;
    },
    getReagentFail: (state) => {
      state.loadingReagent = false;
    },
    getReagentNextRequest: (state, payload) => {
      state.loadingReagent = true;
    },
    getReagentPreviousRequest: (state, payload) => {
      state.loadingReagent = true;
    },
    createReagentRequest: (state, payload) => {
      state.loadingCreateReagent = true;
    },
    createReagentSuccess: (state) => {
      state.loadingCreateReagent = false;
    },
    createReagentFail: (state) => {
      state.loadingCreateReagent = false;
    },
    getReagentByIdRequest: (state, payload) => {
      state.edit = true;
    },
    reagentEditSuccess: (state, { payload }) => {
      state.reagent = payload;
    },
    reagentEditFail: (state) => {
      state.edit = false;
    },
    updateReagentRequest: (state, payload) => {
      state.loadingCreateReagent = true;
    },
    updateReagentSuccess: (state) => {
      state.loadingCreateReagent = false;
    },
    updateReagentFail: (state) => {
      state.loadingCreateReagent = false;
    },
    clearReagentData: (state) => {
      state.edit = false;
      state.reagent = null;
    },
  },
});
// Action creators are generated for each case reducer function
export const {
  getReagentRequest,
  getReagentSuccess,
  getReagentFail,
  getReagentNextRequest,
  getReagentPreviousRequest,
  createReagentRequest,
  createReagentSuccess,
  createReagentFail,
  reagentEditSuccess,
  updateReagentRequest,
  updateReagentSuccess,
  updateReagentFail,
  clearReagentData,
  getReagentByIdRequest,
  reagentEditFail,
} = Reagent.actions;
export default Reagent.reducer;
