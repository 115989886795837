import {
  getAppointmentTypeRequest,
  getAppointmentTypeFail,
  getAppointmentTypeSuccess,
  createAppointmentTypeFail,
  createAppointmentTypeRequest,
  createAppointmentTypeSuccess,
  updateAppointmentTypeFail,
  updateAppointmentTypeSuccess,
  clearAllData,
  getAppointmentTypeNextRequest,
  getAppointmentTypePreviousRequest,
  updateAppointmentTypeRequest,
  getAppointmentTypeByIdRequest,
  appointmentTypeEditSuccess,
  appointmentTypeEditFail,
} from "./appointmentTypeSlice";
import { mergeMap } from "rxjs";
import {
  getAppointmentType,
  createAppointmentType,
  updateAppointmentType,
  getAppointmentTypeById,
} from "./api";
import { map, filter } from "rxjs/operators";
import { Observable } from "rxjs";
import { Action } from "@reduxjs/toolkit";
import { combineEpics } from "redux-observable";
import {
  alertErrorAction,
  alertSuccessAction,
  closeModal,
} from "../../../CommonAppRedux/CommonAppSlice";
import { getNext, getPrevious } from "../../../CommonAppRedux/api";
import {
  dispatchAction,
  stateAction,
} from "../../../../AppUtils/Utils/globalTypes";
import messages from "../../../../AppUtils/Utils/validationConstants";
export const controller = new AbortController();

//get AppointmentType
const getAppointmentTypeEpic = (
  action$: Observable<Action>,
  _: stateAction,
  { dispatch }: dispatchAction
) =>
  action$.pipe(
    filter(getAppointmentTypeRequest.match),
    mergeMap(async (action: any) => {
      try {
        const response = await getAppointmentType(action.payload);
        return { payload: response.data };
      } catch (e) {
        return { error: e };
      }
    }),
    map((action) =>
      action?.payload
        ? getAppointmentTypeSuccess(action?.payload)
        : getAppointmentTypeFail()
    )
  );

//get appointmentType by id epic
const getAppointmentTypeByIdEpic = (
  action$: Observable<Action>,
  _: stateAction,
  { dispatch }: dispatchAction
) =>
  action$.pipe(
    filter(getAppointmentTypeByIdRequest.match),
    mergeMap(async (action) => {
      try {
        const response = await getAppointmentTypeById(action.payload);
        return { payload: response.data };
      } catch (e) {
        return { error: e };
      }
    }),
    map((action) =>
      action?.payload
        ? appointmentTypeEditSuccess(action.payload)
        : appointmentTypeEditFail()
    )
  );

//create
const createAppointmentTypeEpic = (
  action$: Observable<Action>,
  _: stateAction,
  { dispatch }: dispatchAction
) =>
  action$.pipe(
    filter(createAppointmentTypeRequest.match),
    mergeMap(async ({ payload: { values, rowsPerPage, page } }) => {
      try {
        const body = new FormData();
        for (let [key, value] of Object.entries(values)) {
          body.append(`${key}`, String(value));
        }
        const response = await createAppointmentType(body);
        if (response) {
          dispatch(getAppointmentTypeRequest({ rowsPerPage, page }));
          dispatch(alertSuccessAction(messages.createMessage));
          dispatch(closeModal());
        }
        return { payload: { response } };
      } catch (e) {
        dispatch(alertErrorAction(messages.createFailMessage));
        return { error: e };
      }
    }),
    map((action) => {
      return action?.payload
        ? createAppointmentTypeSuccess()
        : createAppointmentTypeFail();
    })
  );

//update
const updateAppointmentTypeEpic = (
  action$: Observable<Action>,
  _: stateAction,
  { dispatch }: dispatchAction
) =>
  action$.pipe(
    filter(updateAppointmentTypeRequest.match),
    mergeMap(async ({ payload: { values, id, rowsPerPage, page, search } }) => {
      try {
        const body = new FormData();
        for (let [key, value] of Object.entries(values)) {
          body.append(`${key}`, String(value));
        }
        const response = await updateAppointmentType(body, id);
        if (response) {
          dispatch(getAppointmentTypeRequest({ rowsPerPage, page, search }));
          dispatch(alertSuccessAction(messages.updateMessage));
          dispatch(closeModal());
          dispatch(clearAllData());
        }
        return { payload: { response, rowsPerPage } };
      } catch (e) {
        dispatch(alertErrorAction(messages.updateFailMessage));
        return { error: e };
      }
    }),
    map((action) => {
      return action?.payload
        ? updateAppointmentTypeSuccess()
        : updateAppointmentTypeFail();
    })
  );

//get next
const getAppointmentTypeNext = (
  action$: Observable<Action>,
  _: stateAction,
  { dispatch }: dispatchAction
) =>
  action$.pipe(
    filter(getAppointmentTypeNextRequest.match),
    mergeMap(async (action) => {
      try {
        const response = await getNext(action.payload);
        return { payload: response.data };
      } catch (e) {
        return { error: e };
      }
    }),
    map((action) => {
      return action?.payload
        ? getAppointmentTypeSuccess(action?.payload)
        : getAppointmentTypeFail();
    })
  );

//get previous
const getAppointmentTypePrevious = (
  action$: Observable<Action>,
  _: stateAction,
  { dispatch }: dispatchAction
) =>
  action$.pipe(
    filter(getAppointmentTypePreviousRequest.match),
    mergeMap(async (action) => {
      try {
        const response = await getPrevious(action.payload);
        return { payload: response.data };
      } catch (e) {
        return { error: e };
      }
    }),
    map((action) =>
      action?.payload
        ? getAppointmentTypeSuccess(action?.payload)
        : getAppointmentTypeFail()
    )
  );

export const appointmentTypeEpic = combineEpics(
  getAppointmentTypeEpic,
  createAppointmentTypeEpic,
  updateAppointmentTypeEpic,
  getAppointmentTypeNext,
  getAppointmentTypePrevious,
  getAppointmentTypeByIdEpic
);
