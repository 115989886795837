import { createSlice, createAction } from "@reduxjs/toolkit";
import { TotalPatientStatReportInitialState } from "./types";
const initialState: TotalPatientStatReportInitialState = {
  users: [],
  totalPatientDetails: [],
  //loading 
  loadingGenerateReport: false,
  loadingTotalPatientStatReportUser: false,
  loadingTotalPatientStatReportDetail: false,
  count: 0,
  previous: "",
  next: "",
  totalPatientStatDetails: [],
  totalPatientRaceGroupDetails: [],
  totalEthnicityDetails: [],
  loadingTotalPatientStatJaatiType:false,
  jaatiTypes:[],
};

// export const getTotalPatientStatReportDetailRequest = createAction(
//   "getTotalPatientStatReportDetailRequest",
//   function prepare({ detailsRowsPerPage, page }) {
//     return {
//       payload: {
//         detailsRowsPerPage,
//         page,
//       },
//     };
//   }
// );
export const TotalPatientStatReport = createSlice({
  name: "TotalPatientStatReportReducer",
  initialState,
  reducers: {
    //loading
    loadingTotalPatientStatReportDetail: (state) => {
      state.loadingTotalPatientStatReportDetail = true;
    },
    //external reducer request
    getTotalPatientStatReportDetailRequest: (state, { payload }) => {
      state.loadingTotalPatientStatReportDetail = true;
    },
    getTotalPatientStatReportUserRequest: (state) => {
      state.loadingTotalPatientStatReportUser = true;
    },
    getTotalPatientStatReportAllSummaryRequest: (state, { payload }) => {
      state.loadingGenerateReport = true;
    },
    getTotalPatientStatReportAllDetailRequest: (state, { payload }) => {
      state.loadingGenerateReport = true;
    },
    getTotalPatientStatReportQuickSummaryRequest: (state, { payload }) => {
      state.loadingGenerateReport = true;
    },
    getTotalPatientStatReportQuickDetailRequest: (state, { payload }) => {
      state.loadingGenerateReport = true;
    },
    getTotalPatientStatReportNextRequest: (state, payload) => {
      state.loadingTotalPatientStatReportDetail = true;
    },
    getTotalPatientStatReportPreviousRequest: (state, payload) => {
      state.loadingTotalPatientStatReportDetail = true;
    },
    //external get reducer  
    getTotalPatientStatReportDetailSuccess: (
      state,
      { payload: { results, count, next, previous } }
    ) => {
      state.totalPatientDetails = results;
      state.loadingTotalPatientStatReportDetail = false;
      state.count = count;
      state.next = next;
      state.previous = previous;
    },
    getTotalPatientStatReportDetailFail: (state) => {
      state.loadingTotalPatientStatReportDetail = false;
    },
    getTotalPatientStatReportUserSuccess: (state, { payload: { results } }) => {
      state.loadingTotalPatientStatReportUser = false;
      state.users = results;
    },
    getTotalPatientStatReportUserFail: (state) => {
      state.loadingTotalPatientStatReportUser = false;
    },
    getTotalPatientStatReportAllSummarySuccess: (state, { payload: { results } }) => {
      state.loadingGenerateReport = false;
      state.totalPatientDetails = results;
    },
    getTotalPatientStatReportAllSummaryFail: (state) => {
      state.loadingGenerateReport = false;
    },
    getTotalPatientStatReportQuickSummarySuccess: (
      state,
      { payload: { results, count, next, previous } }
    ) => {
      state.loadingGenerateReport = false;
      state.totalPatientDetails = results;
      state.count = count;
      state.next = next;
      state.previous = previous;
    },
    getTotalPatientStatReportQuickSummaryFail: (state) => {
      state.loadingGenerateReport = false;
    },
    getTotalPatientStatReportAllDetailSuccess: (state, { payload: { results } }) => {
      state.loadingGenerateReport = false;
      state.totalPatientDetails = results;
    },
    getTotalPatientStatReportAllDetailFail: (state) => {
      state.loadingGenerateReport = false;
    },
    getTotalPatientStatReportQuickDetailSuccess: (
      state,
      { payload: { results, count, next, previous } }
    ) => {
      state.loadingGenerateReport = false;
      state.totalPatientDetails = results;
      state.count = count;
      state.next = next;
      state.previous = previous;
    },
    getTotalPatientStatReportQuickDetailFail: (state) => {
      state.loadingGenerateReport = false;
    },

    clearTotalPatientStatReportData: (state) => {
      state.users = [];
    },
    clearPrintUserActivityLogData: (state) => {
      state.totalPatientDetails = [];
    },
    //
    getTotalPatientStatReportSummaryRequest: (state, { payload }) => {
      state.loadingGenerateReport = true;
    },
    getTotalPatientStatReportSummarySuccess: (state, { payload }) => {
      state.loadingGenerateReport = false;
      state.totalPatientStatDetails = [payload];
    },
    getTotalPatientStatReportSummaryFail: (state) => {
      state.loadingGenerateReport = false;
    },
    //
    getTotalPatientRaceGroupDetailRequest: (state, { payload }) => {
      state.loadingGenerateReport = true;
    },
    getTotalPatientRaceGroupDetailSuccess: (state, { payload: { results } }) => {
      state.loadingGenerateReport = false;
      state.totalPatientRaceGroupDetails = results;
    },
    getTotalPatientRaceGroupDetailFail: (state) => {
      state.loadingGenerateReport = false;
    },
    //
    getTotalPatientEthnicityDetailRequest: (state, { payload }) => {
      state.loadingGenerateReport = true;
    },
    getTotalPatientEthnicityDetailSuccess: (state, { payload: { results } }) => {
      state.loadingGenerateReport = false;
      state.totalEthnicityDetails = results;
    },
    getTotalPatientEthnicityDetailFail: (state) => {
      state.loadingGenerateReport = false;
    },
    //
   getTotalPatientStatJaatiTypeRequest: (state) => {
      state.loadingTotalPatientStatJaatiType = true;
    },
    getTotalPatientStatJaatiTypeSuccess: (state, { payload: { results } }) => {
      state.loadingTotalPatientStatJaatiType = false;
      state.jaatiTypes = results;
    },
    getTotalPatientStatJaatiTypeFail: (state) => {
      state.loadingTotalPatientStatJaatiType = false;
    },
  },
});
// Action creators are generated for each case reducer function
export const {
  //reducerReport
  getTotalPatientStatReportDetailRequest,
  getTotalPatientStatReportUserRequest,
  getTotalPatientStatReportAllSummaryRequest,
  getTotalPatientStatReportAllDetailRequest,
  getTotalPatientStatReportQuickSummaryRequest,
  getTotalPatientStatReportQuickDetailRequest,
  getTotalPatientStatReportPreviousRequest,
  getTotalPatientStatReportNextRequest,
  //reducer get data
  getTotalPatientStatReportDetailSuccess,
  getTotalPatientStatReportDetailFail,
  getTotalPatientStatReportUserSuccess,
  getTotalPatientStatReportUserFail,
  getTotalPatientStatReportQuickDetailSuccess,
  getTotalPatientStatReportQuickDetailFail,
  getTotalPatientStatReportAllDetailSuccess,
  getTotalPatientStatReportAllDetailFail,
  getTotalPatientStatReportQuickSummarySuccess,
  getTotalPatientStatReportQuickSummaryFail,
  getTotalPatientStatReportAllSummarySuccess,
  getTotalPatientStatReportAllSummaryFail,
  //loading
  loadingTotalPatientStatReportDetail,
  //clear
  clearTotalPatientStatReportData,
  clearPrintUserActivityLogData,
  //
  getTotalPatientStatReportSummaryRequest,
  getTotalPatientStatReportSummarySuccess,
  getTotalPatientStatReportSummaryFail,
  getTotalPatientEthnicityDetailFail,
  getTotalPatientEthnicityDetailSuccess,
  getTotalPatientEthnicityDetailRequest,
  getTotalPatientRaceGroupDetailRequest,
  getTotalPatientRaceGroupDetailSuccess,
  getTotalPatientRaceGroupDetailFail,
  getTotalPatientStatJaatiTypeRequest,
  getTotalPatientStatJaatiTypeSuccess,
  getTotalPatientStatJaatiTypeFail,
} = TotalPatientStatReport.actions;
export default TotalPatientStatReport.reducer;
