import { filterApiData } from "../../../../AppUtils/Utils/appFunctions";
import { axiosInstance } from "../../../../AppUtils/Utils/axios";
import { getApiProps, searchProps } from "../../../../AppUtils/Utils/globalTypes";

const baseUrl = "/api/v1/appointment-app/note-template";


// Get Note Templates
export const getNoteTemplates = (data: getApiProps) => {
    const { rowsPerPage, page } = data;
    return axiosInstance.get(
        `${baseUrl}?offset=${rowsPerPage * page}&limit=${rowsPerPage}${
            data?.filterData ? filterApiData(data?.filterData) : ""
        }`
    );
};

//handle search
export const searchNoteTemplate = ({ rowsPerPage, search }: searchProps) => {
    return `${baseUrl}?offset=0&limit=${rowsPerPage}&search=${search ?? ""}`;
  };

// Create Note Templates
export const createNoteTemplates = (body: string | FormData)=>{
    return axiosInstance.post(`${baseUrl}`, body)
}

// Update Note Templates 
export const updateNoteTemplates = (body: string | FormData, id: number)=>{
    return axiosInstance.patch(`${baseUrl}/${id}`, body)
}

// edit note templates
export const getEditedNoteTemplates = (id: number)=>{
    return axiosInstance.get(`${baseUrl}/${id}`)
}