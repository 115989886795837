import { makeStyles } from "@mui/styles";
export const tableStyles = makeStyles(() => ({
  filterDataPaper: {
    padding: "4px 4px",
    boxShadow: "0px 0px 8px 1px #ddd !important",
    margin: "4px 0px",
    display: "flex",
    alignItems: "center",
    width: "100%",
  },
  tableDataPaper: {
    padding: "4px",
    boxShadow: "0px 0px 4px 1px #ddd !important",
    margin: "4px 0px",
  },
  tableContainer: {
    display: "block",
    overflow: "scroll",
    // minHeight: "max-content",
    maxHeight: "80vh",
    "@media (min-height: 1080px)": {
      maxHeight: "77vh",
    },
    "@media (max-height: 1080px)": {
      maxHeight: "74vh",
    },
    "@media (max-height: 980px)": {
      maxHeight: "72vh",
    },
    "@media (max-height: 890px)": {
      maxHeight: "69vh",
    },
    "@media (max-height: 820px)": {
      maxHeight: "67vh",
    },
    "@media (max-height: 750px)": {
      maxHeight: "65vh",
    },
    "@media (max-height: 710px)": {
      maxHeight: "63vh",
    },
    "@media (max-height: 680px)": {
      maxHeight: "61vh",
    },
    "@media (max-height: 630px)": {
      maxHeight: "57vh",
    },
    "@media (max-height: 580px)": {
      maxHeight: "55vh",
    },
    "@media (max-height: 550px)": {
      maxHeight: "53vh",
    },
    "@media (max-height: 520px)": {
      maxHeight: "50vh",
    },
    "@media (max-height: 490px)": {
      maxHeight: "45vh",
    },
  },
  tableContainerForDetail: {
    display: "block",
    overflow: "scroll",
    minHeight: "max-content",
    maxHeight: "80vh",
    "@media (min-height: 1080px)": {
      maxHeight: "77vh",
    },
    "@media (max-height: 1080px)": {
      maxHeight: "74vh",
    },
    "@media (max-height: 980px)": {
      maxHeight: "72vh",
    },
    "@media (max-height: 890px)": {
      maxHeight: "69vh",
    },
    "@media (max-height: 820px)": {
      maxHeight: "67vh",
    },
    "@media (max-height: 750px)": {
      maxHeight: "65vh",
    },
    "@media (max-height: 710px)": {
      maxHeight: "63vh",
    },
    "@media (max-height: 680px)": {
      maxHeight: "61vh",
    },
    "@media (max-height: 630px)": {
      maxHeight: "57vh",
    },
    "@media (max-height: 580px)": {
      maxHeight: "55vh",
    },
    "@media (max-height: 550px)": {
      maxHeight: "53vh",
    },
    "@media (max-height: 520px)": {
      maxHeight: "50vh",
    },
    "@media (max-height: 490px)": {
      maxHeight: "45vh",
    },
  },
  noDataDisplay: {
    display: "block",
    overflow: "scroll",
    minHeight: "15vh",
    maxHeight: "15vh",
  },
  headCell: {
    // minWidth: "10 !important",
    fontSize: "8 !important",
    fontWeight: "bold !important",
    // padding: "0 !important",
  },
  tableData: {
    padding: "0px 6px !important",
    fontWeight: "400",
    fontSize: "0.875rem",
    lineHeight: " 1.5",
    letterSpacing: " 0.15px",
    color: " rgb(52 35 77 / 78%)",
  },
  // tableContainerForPurchase: {
  //   display: "block",
  //   overflow: "scroll",
  //   minHeight: "5vh",
  //   maxHeight: "35vh",
  // },
  labCustomTable: {
    boxShadow:
      "0px 5px 5px -3px rgb(0 0 0 / 20%), 0px 8px 10px 1px rgb(0 0 0 / 14%), 0px 3px 14px 2px rgb(0 0 0 / 12%) !important",
  },
  styleTableID: {
    textDecoration: "none",
    color: "red !important",
  },
  verificationTable: {
    color: "whitesmoke !important",
    whiteSpace: "nowrap",
  },
}));
