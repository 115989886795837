import {
  createServiceOrderFail,
  createServiceOrderRequest,
  createServiceOrderSuccess,
  getServiceOrderSuccess,
  getServiceOrderFail,
  getServiceOrderCategoryRequest,
  getServiceOrderCategorySuccess,
  getServiceOrderCategoryFail,
  getServiceOrderDepartmentRequest,
  getServiceOrderDepartmentSuccess,
  getServiceOrderDepartmentFail,
  getServiceOrderCustomerTypeRequest,
  getServiceOrderCustomerTypeSuccess,
  getServiceOrderCustomerTypeFail,
  updateServiceOrderFail,
  updateServiceOrderSuccess,
  getServiceOrderRequest,
  updateServiceOrderRequest,
  getServiceOrderNextRequest,
  getServiceOrderPreviousRequest,
  clearServiceOrderData,
  getServiceOrderByIdRequest,
  serviceOrderEditSuccess,
  serviceOrderEditFail,
  // getServiceOrderCustomerRequest,
  // getServiceOrderCustomerSuccess,
  // getServiceOrderCustomerFail,
  getServiceOrderItemRequest,
  getServiceOrderItemSuccess,
  getServiceOrderItemFail,
} from "./serviceOrderSlice";
import { mergeMap } from "rxjs";
import {
  createServiceOrder,
  getServiceOrder,
  updateServiceOrder,
  getServiceOrderCustomerType,
  getServiceOrderCategory,
  getServiceOrderDepartment,
  getServiceOrderById,
  getServiceOrderItem,
} from "./api";
import { map, filter, tap } from "rxjs/operators";
import { Observable } from "rxjs";
import { Action } from "@reduxjs/toolkit";
import { combineEpics } from "redux-observable";
import { alertErrorAction, alertSuccessAction, closeModal } from "../../../CommonAppRedux/CommonAppSlice";
import messages from "../../../../AppUtils/Utils/validationConstants";
import { dispatchAction, stateAction } from "../../../../AppUtils/Utils/globalTypes";
import { getNext, getPrevious } from "../../../CommonAppRedux/api";


export const controller = new AbortController();

// get clinicorder epic
const getServiceOrderEpic = (
  action$: Observable<Action>,
  _: stateAction,
  { dispatch }: dispatchAction
) =>
  action$.pipe(
    filter(getServiceOrderRequest.match),
    mergeMap(async (action) => {
      try {
        const response = await getServiceOrder(action.payload);
        return { payload: response.data };
      } catch (e) {
        return { error: e };
      }
    }),
    map((action) =>
      action?.payload ? getServiceOrderSuccess(action?.payload) : getServiceOrderFail()
    )
  );

//get next
const getServiceOrderNext = (
  action$: Observable<Action>,
  _: stateAction,
  { dispatch }: dispatchAction
) =>
  action$.pipe(
    filter(getServiceOrderNextRequest.match),
    mergeMap(async (action) => {
      try {
        const response = await getNext(action.payload);
        return { payload: response.data };
      } catch (e) {
        return { error: e };
      }
    }),
    map((action) =>
      action?.payload ? getServiceOrderSuccess(action?.payload) : getServiceOrderFail()
    )
  );

//get previous
const getServiceOrderPrevious = (
  action$: Observable<Action>,
  _: stateAction,
  { dispatch }: dispatchAction
) =>
  action$.pipe(
    filter(getServiceOrderPreviousRequest.match),
    mergeMap(async (action) => {
      try {
        const response = await getPrevious(action.payload);
        return { payload: response.data };
      } catch (e) {
        return { error: e };
      }
    }),
    map((action) =>
      action?.payload ? getServiceOrderSuccess(action?.payload) : getServiceOrderFail()
    )
  );
// get clinicorder customer type
const getAllServiceOrderCustomerType = (
  action$: Observable<Action>,
  _: stateAction,
  { dispatch }: dispatchAction
) =>
  action$.pipe(
    filter(getServiceOrderCustomerTypeRequest.match),
    mergeMap(async (action) => {
      try {
        const response = await getServiceOrderCustomerType(action.payload);
        return { payload: response.data };
      } catch (e) {
        return { error: e };
      }
    }),
    map((action) =>
      action?.payload
        ? getServiceOrderCustomerTypeSuccess(action?.payload)
        : getServiceOrderCustomerTypeFail()
    )
  );
// get clinicorder department
const getAllServiceOrderDepartment = (
  action$: Observable<Action>,
  _: stateAction,
  { dispatch }: dispatchAction
) =>
  action$.pipe(
    filter(getServiceOrderDepartmentRequest.match),
    mergeMap(async (action) => {
      try {
        const response = await getServiceOrderDepartment(action.payload);
        return { payload: response.data };
      } catch (e) {
        return { error: e };
      }
    }),
    map((action) =>
      action?.payload
        ? getServiceOrderDepartmentSuccess(action?.payload)
        : getServiceOrderDepartmentFail()
    )
  );
// get clinicorder category
const getAllServiceOrderCategory = (
  action$: Observable<Action>,
  _: stateAction,
  { dispatch }: dispatchAction
) =>
  action$.pipe(
    filter(getServiceOrderCategoryRequest.match),
    mergeMap(async (action) => {
      try {
        const response = await getServiceOrderCategory(action.payload);
        return { payload: response.data };
      } catch (e) {
        return { error: e };
      }
    }),
    map((action) =>
      action?.payload
        ? getServiceOrderCategorySuccess(action?.payload)
        : getServiceOrderCategoryFail()
    )
  );
//create ServiceOrder epic
const createServiceOrderEpic = (
  action$: Observable<Action>,
  _: stateAction,
  { dispatch }: dispatchAction
) =>
  action$.pipe(
    filter(createServiceOrderRequest.match),
    mergeMap(async ({ payload: { values, rowsPerPage, page } }) => {
      
      try {
        const body = JSON.stringify(values);

        const response = await createServiceOrder(body);
        if (response) {
          dispatch(getServiceOrderRequest({ id:values?.customer, rowsPerPage, page }));
          dispatch(alertSuccessAction(messages.createMessage));
          dispatch(closeModal());
          dispatch(clearServiceOrderData());
        }
        return { payload: { response } };
      } catch (e) {
        dispatch(alertErrorAction(messages.createFailMessage));
        return { error: e };
      }
    }),
    map((action) => {
      return action?.payload ? createServiceOrderSuccess() : createServiceOrderFail();
    })
  );

//update ServiceOrder epic
const updateServiceOrderEpic = (
  action$: Observable<Action>,
  _: stateAction,
  { dispatch }: dispatchAction
) =>
  action$.pipe(
    filter(updateServiceOrderRequest.match),
    mergeMap(async ({ payload: { values, id, rowsPerPage, page } }) => {
      try {
        const body = JSON.stringify(values);
        const response = await updateServiceOrder(body, id);
        if (response) {
          dispatch(getServiceOrderRequest({ rowsPerPage, page }));
          dispatch(alertSuccessAction(messages.updateMessage));
          dispatch(clearServiceOrderData());
          dispatch(closeModal());
        }
        return { payload: { response, rowsPerPage } };
      } catch (e) {
        dispatch(alertErrorAction(messages.updateFailMessage));
        return { error: e };
      }
    }),
    map((action) => (action?.payload ? updateServiceOrderSuccess() : updateServiceOrderFail()))
  );

const getServiceOrderByIdEpic = (
  action$: Observable<Action>,
  _: stateAction,
  { dispatch }: dispatchAction
) =>
  action$.pipe(
    filter(getServiceOrderByIdRequest.match),
    mergeMap(async (action) => {
      try {
        const response = await getServiceOrderById(action.payload);
        return { payload: response.data };
      } catch (e) {
        return { error: e };
      }
    }),
    map((action) =>
      action?.payload ? serviceOrderEditSuccess(action.payload) : serviceOrderEditFail()
    )
  );


  //customer
  // const getServiceOrderCustomerEpic = (
  //   action$: Observable<Action>,
  //   _: stateAction,
  //   { dispatch }: dispatchAction
  // ) =>
  //   action$.pipe(
  //     filter(getServiceOrderCustomerRequest.match),
  //     mergeMap(async (action: any) => {
  //       try {
  //         const response = await getServiceOrderCustomer(action.payload);
  //         return { payload: response.data };
  //       } catch (e) {
  //         return { error: e };
  //       }
  //     }),
  //     map((action) =>
  //       action?.payload
  //         ? getServiceOrderCustomerSuccess(action?.payload)
  //         : getServiceOrderCustomerFail()
  //     )
  //   );
    //item
    const getServiceOrderItemEpic = (
      action$: Observable<Action>,
      _: stateAction,
      { dispatch }: dispatchAction
    ) =>
      action$.pipe(
        filter(getServiceOrderItemRequest.match),
        mergeMap(async (action: any) => {
          try {
            const response = await getServiceOrderItem(action.payload);
            return { payload: response.data };
          } catch (e) {
            return { error: e };
          }
        }),
        map((action) =>
          action?.payload
            ? getServiceOrderItemSuccess(action?.payload)
            : getServiceOrderItemFail()
        )
      );
export const serviceOrderEpics = combineEpics(
  getServiceOrderEpic,
  createServiceOrderEpic,
  updateServiceOrderEpic,
  getServiceOrderNext,
  getServiceOrderPrevious,
  getAllServiceOrderCustomerType,
  getAllServiceOrderDepartment,
  getAllServiceOrderCategory,
  getServiceOrderByIdEpic,
  getServiceOrderItemEpic,
  // getServiceOrderCustomerEpic,
  
);
