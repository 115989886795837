import { createSlice, createAction } from "@reduxjs/toolkit";
import { reportFooter } from "./types";
const initialState: any = {
  reportFooters: [],
  loadingReportFooter: false,
  loadingCreateReportFooter: false,
  edit: false,
  reportFooter: null,
  doctors: [],
  loadingDoctor: false,
  count: 0,
  previous: "",
  next: "",
};

export const ReportFooter = createSlice({
  name: "Report Footer Reducer",
  initialState,
  reducers: {
    loadingReportFooter: (state) => {
      state.loadingReportFooter = true;
    },
    getReportFooterRequest: (state, payload) => {
      state.loadingReportFooter = true;
    },
    getReportFooterSuccess: (
      state,
      { payload: { results, count, next, previous } }
    ) => {
      state.loadingReportFooter = false;
      state.reportFooters = results;
      state.count = count;
      state.next = next;
      state.previous = previous;
    },
    getReportFooterFail: (state) => {
      state.loadingReportFooter = false;
    },
    getReportFooterNextRequest: (state, payload) => {
      state.loadingReportFooter = true;
    },
    getReportFooterPreviousRequest: (state, payload) => {
      state.loadingReportFooter = true;
    },
    loadingReportFooterDoctorReq: (state) => {
      state.loadingTest = true;
    },
    getReportFooterDoctorRequest: (state, payload) => {
      state.loadingDoctor = true;
    },
    getReportFooterDoctorSuccess: (state, { payload: { results } }) => {
      state.doctors = results;
      state.loadingDoctor = false;
    },
    getReportFooterDoctorFail: (state) => {
      state.loadingDoctor = false;
    },
    createReportFooterRequest: (state, payload) => {
      state.loadingCreateReportFooter = true;
      state.closeModal = true;
    },
    createReportFooterSuccess: (state) => {
      state.loadingCreateReportFooter = false;
    },
    createReportFooterFail: (state) => {
      state.loadingCreateReportFooter = false;
    },
    editReportFooterRequest: (state, payload) => {
      state.edit = true;
    },
    reportFooterEditSuccess: (state, { payload }) => {
      const editedReportFooter = state.reportFooters?.find(
        (note: reportFooter) => note?.id === payload?.id
      );
      state.edit = true;
      state.reportFooter = editedReportFooter;
    },
    editReportFooterFail: (state) => {},
    updateReportFooterRequest: (state, payload) => {
      state.loadingCreateReportFooter = true;
    },
    updateReportFooterSuccess: (state) => {
      state.loadingCreateReportFooter = false;
    },
    updateReportFooterFail: (state) => {
      state.loadingCreateReportFooter = false;
    },
    clearReportFooterData: (state) => {
      state.edit = false;
      state.reportFooter = null;
    },
  },
});
// Action creators are generated for each case reducer function
export const {
  // loading report footer
  loadingReportFooter,
  loadingReportFooterDoctorReq,
  getReportFooterRequest,
  getReportFooterSuccess,
  getReportFooterFail,
  getReportFooterNextRequest,
  getReportFooterPreviousRequest,
  // get doctor
  getReportFooterDoctorRequest,
  getReportFooterDoctorSuccess,
  getReportFooterDoctorFail,
  // create report footer
  createReportFooterRequest,
  createReportFooterSuccess,
  createReportFooterFail,
  reportFooterEditSuccess,
  // update report footer
  updateReportFooterRequest,
  updateReportFooterSuccess,
  updateReportFooterFail,
  clearReportFooterData,
  editReportFooterRequest,
  editReportFooterFail,
} = ReportFooter.actions;
export default ReportFooter.reducer;
