import {
  createClinicSetupFail,
  createClinicSetupRequest,
  createClinicSetupSuccess,
  getClinicSetupFail,
  getClinicSetupSuccess,
  updateClinicSetupFail,
  updateClinicSetupSuccess,
  getClinicSetupRequest,
  getClinicSetupNextRequest,
  getClinicSetupPreviousRequest,
  updateClinicSetupRequest,
  clearClinicSetupData,
  getClinicSetupByIdRequest,
  clinicSetupEditSuccess,
  clinicSetupEditFail,
  getClinicSiteRequest,
  getClinicSiteSuccess,
  getClinicSiteFail,
} from "./clinicSetupSlice";
import { mergeMap } from "rxjs";
import {
  createClinicSetup,
  getClinicSetup,
  updateClinicSetup,
  getClinicSetupById,
  getClinicSite,
} from "./api";
import { map, filter } from "rxjs/operators";
import { Observable } from "rxjs";
import { Action } from "@reduxjs/toolkit";
import { combineEpics } from "redux-observable";
import {
  alertErrorAction,
  alertSuccessAction,
  closeModal,
} from "../../../CommonAppRedux/CommonAppSlice";
import { getNext, getPrevious } from "../../../CommonAppRedux/api";
import {
  dispatchAction,
  stateAction,
} from "../../../../AppUtils/Utils/globalTypes";
import messages from "../../../../AppUtils/Utils/validationConstants";
export const controller = new AbortController();

//get ClinicSetup epic
const getClinicSetupEpic = (
  action$: Observable<Action>,
  _: stateAction,
  { dispatch }: dispatchAction
) =>
  action$.pipe(
    filter(getClinicSetupRequest.match),
    mergeMap(async (action) => {
      try {
        const response = await getClinicSetup(action.payload);
        return { payload: response.data };
      } catch (e) {
        return { error: e };
      }
    }),
    map((action) =>
      action?.payload
        ? getClinicSetupSuccess(action?.payload)
        : getClinicSetupFail()
    )
  );

//get clinicSetup by id
const getClinicSetupByIdEpic = (
  action$: Observable<Action>,
  _: stateAction,
  { dispatch }: dispatchAction
) =>
  action$.pipe(
    filter(getClinicSetupByIdRequest.match),
    mergeMap(async (action) => {
      try {
        const response = await getClinicSetupById(action.payload);
        return { payload: response.data };
      } catch (e) {
        return { error: e };
      }
    }),
    map((action) =>
      action?.payload
        ? clinicSetupEditSuccess(action.payload)
        : clinicSetupEditFail()
    )
  );

//get clinic site
const getClinicSiteEpic = (
  action$: Observable<Action>,
  _: stateAction,
  { dispatch }: dispatchAction
) =>
  action$.pipe(
    filter(getClinicSiteRequest.match),
    mergeMap(async (action: any) => {
      try {
        const response = await getClinicSite(action.payload);
        return { payload: response.data };
      } catch (e) {
        return { error: e };
      }
    }),
    map((action) =>
      action?.payload
        ? getClinicSiteSuccess(action?.payload)
        : getClinicSiteFail()
    )
  );

//get next
const getClinicSetupNext = (
  action$: Observable<Action>,
  _: stateAction,
  { dispatch }: dispatchAction
) =>
  action$.pipe(
    filter(getClinicSetupNextRequest.match),
    mergeMap(async (action) => {
      try {
        const response = await getNext(action.payload);
        return { payload: response.data };
      } catch (e) {
        return { error: e };
      }
    }),
    map((action) =>
      action?.payload
        ? getClinicSetupSuccess(action?.payload)
        : getClinicSetupFail()
    )
  );
//get previous
const getClinicSetupPrevious = (
  action$: Observable<Action>,
  _: stateAction,
  { dispatch }: dispatchAction
) =>
  action$.pipe(
    filter(getClinicSetupPreviousRequest.match),
    mergeMap(async (action) => {
      try {
        const response = await getPrevious(action.payload);
        return { payload: response.data };
      } catch (e) {
        return { error: e };
      }
    }),
    map((action) =>
      action?.payload
        ? getClinicSetupSuccess(action?.payload)
        : getClinicSetupFail()
    )
  );
//create ClinicSetup epic
const createClinicSetupEpic = (
  action$: Observable<Action>,
  _: stateAction,
  { dispatch }: dispatchAction
) =>
  action$.pipe(
    filter(createClinicSetupRequest.match),
    mergeMap(
      async ({
        payload: { values, rowsPerPage, page, setShowModalFromAnotherModule },
      }) => {
        try {
          const body = new FormData();
          for (let [key, value] of Object.entries(values)) {
            // @ts-ignore
            body.append(`${key}`, value);
          }
          const response = await createClinicSetup(body);
          if (response) {
            dispatch(getClinicSetupRequest({ rowsPerPage, page }));
            dispatch(alertSuccessAction(messages.createMessage));
            setShowModalFromAnotherModule
              ? setShowModalFromAnotherModule(false)
              : dispatch(closeModal());
          }
          return { payload: { response } };
        } catch (e) {
          dispatch(alertErrorAction(messages.createFailMessage));
          return { error: e };
        }
      }
    ),
    map((action) => {
      return action?.payload
        ? createClinicSetupSuccess()
        : createClinicSetupFail();
    })
  );
//update ClinicSetup epic
const updateClinicSetupEpic = (
  action$: Observable<Action>,
  _: stateAction,
  { dispatch }: dispatchAction
) =>
  action$.pipe(
    filter(updateClinicSetupRequest.match),
    mergeMap(async ({ payload: { values, id, rowsPerPage, page } }) => {
      try {
        const body = new FormData();
        for (let [key, value] of Object.entries(values)) {
          // @ts-ignore
          body.append(`${key}`, value);
        }
        const response = await updateClinicSetup(body, id);
        if (response) {
          dispatch(getClinicSetupRequest({ rowsPerPage, page }));
          dispatch(alertSuccessAction(messages.updateMessage));
          dispatch(closeModal());
          dispatch(clearClinicSetupData());
        }
        return { payload: { response, rowsPerPage } };
      } catch (e) {
        dispatch(alertErrorAction(messages.updateFailMessage));
        return { error: e };
      }
    }),
    map((action) =>
      action?.payload ? updateClinicSetupSuccess() : updateClinicSetupFail()
    )
  );

export const clinicSetupEpics = combineEpics(
  getClinicSetupEpic,
  createClinicSetupEpic,
  updateClinicSetupEpic,
  getClinicSetupNext,
  getClinicSetupPrevious,
  getClinicSetupByIdEpic,
  getClinicSiteEpic
);
