import {
  getCustomerWiseClinicReportUserRequest,
  getCustomerWiseClinicReportAllSummaryRequest,
  getCustomerWiseClinicReportAllDetailRequest,
  getCustomerWiseClinicReportQuickSummaryRequest,
  getCustomerWiseClinicReportQuickDetailRequest,
  //reducer get data
  getCustomerWiseClinicReportUserSuccess,
  getCustomerWiseClinicReportUserFail,
  getCustomerWiseClinicReportQuickDetailSuccess,
  getCustomerWiseClinicReportQuickDetailFail,
  getCustomerWiseClinicReportAllDetailSuccess,
  getCustomerWiseClinicReportAllDetailFail,
  getCustomerWiseClinicReportQuickSummarySuccess,
  getCustomerWiseClinicReportQuickSummaryFail,
  getCustomerWiseClinicReportAllSummarySuccess,
  getCustomerWiseClinicReportAllSummaryFail,
  getCustomerWiseClinicReportDetailSuccess,
  getCustomerWiseClinicReportDetailFail,
  loadingCustomerWiseClinicReportDetail,
  getCustomerWiseClinicReportPreviousRequest,
  getCustomerWiseClinicReportNextRequest,
  getCustomerWiseClinicReportDetailRequest,
} from "./clinicCustomerWiseReportSlice";
import { mergeMap } from "rxjs";
import *  as API from "./api";
import { map, filter, tap } from "rxjs/operators";
import { Observable } from "rxjs";
import { Action } from "@reduxjs/toolkit";
import { combineEpics } from "redux-observable";
import {
  alertErrorAction,
  alertSuccessAction,
  closeModal,
} from "../../../../CommonAppRedux/CommonAppSlice";
import { getNext, getPrevious } from "../../../../CommonAppRedux/api";
import {
  dispatchAction,
  stateAction,
} from "../../../../../AppUtils/Utils/globalTypes";

export const controller = new AbortController();

//user
const getCustomerWiseClinicReportUserEpic = (
  action$: Observable<Action>,
  _: stateAction,
  { dispatch }: dispatchAction
) =>
  action$.pipe(
    filter(getCustomerWiseClinicReportUserRequest.match),
    mergeMap(async (action) => {
      try {
        const response = await API.getCustomerWiseClinicReportUser();
        return { payload: response.data };
      } catch (e) {
        return { error: e };
      }
    }),
    map((action) =>
      action?.payload
        ? getCustomerWiseClinicReportUserSuccess(action?.payload)
        : getCustomerWiseClinicReportUserFail()
    )
  );
//item

//get all purchase summary detail
const getCustomerWiseClinicReportAllSummaryEpic = (
  action$: Observable<Action>,
  _: stateAction,
  { dispatch }: dispatchAction
) =>
  action$.pipe(
    filter(getCustomerWiseClinicReportAllSummaryRequest.match),
    mergeMap(async (action) => {
      try {
        const response = await API.getCustomerWiseClinicReportAllReport(action?.payload);
        if (response?.data?.count === 0) {
          dispatch(
            alertErrorAction(`No Materialized Data Found`)
          )
        }
        return { payload: response.data };
      } catch (e) {
        return { error: e };
      }
    }),
    map((action) =>
      action?.payload
        ? getCustomerWiseClinicReportAllSummarySuccess(action?.payload)
        : getCustomerWiseClinicReportAllSummaryFail()
    )
  );
//quick summary
const getCustomerWiseClinicReportQuickSummaryEpic = (
  action$: Observable<Action>,
  _: stateAction,
  { dispatch }: dispatchAction
) =>
  action$.pipe(
    filter(getCustomerWiseClinicReportQuickSummaryRequest.match),
    mergeMap(async (action) => {
      try {
        const response = await API.getCustomerWiseClinicReportQuickReport(action.payload);
        if (response?.data?.count === 0) {
          dispatch(
            alertErrorAction(`No Materialized Data Found`)
          )
        }
        return { payload: response.data };
      } catch (e) {
        return { error: e };
      }
    }),
    map((action) =>
      action?.payload
        ? getCustomerWiseClinicReportQuickSummarySuccess(action?.payload)
        : getCustomerWiseClinicReportQuickSummaryFail()
    )
  );

//get all purchase summary detail
const getCustomerWiseClinicReportAllDetailEpic = (
  action$: Observable<Action>,
  _: stateAction,
  { dispatch }: dispatchAction
) =>
  action$.pipe(
    filter(getCustomerWiseClinicReportAllDetailRequest.match),
    mergeMap(async (action) => {
      try {
        const response = await API.getCustomerWiseClinicReportAllReport(action?.payload);
        return { payload: response.data };
      } catch (e) {
        return { error: e };
      }
    }),
    map((action) =>
      action?.payload
        ? getCustomerWiseClinicReportAllDetailSuccess(action?.payload)
        : getCustomerWiseClinicReportAllDetailFail()
    )
  );
//quick Detail
const getCustomerWiseClinicReportQuickDetailEpic = (
  action$: Observable<Action>,
  _: stateAction,
  { dispatch }: dispatchAction
) =>
  action$.pipe(
    filter(getCustomerWiseClinicReportQuickDetailRequest.match),
    mergeMap(async (action) => {
      try {
        const response = await API.getCustomerWiseClinicReportQuickReport(action.payload);
        return { payload: response.data };
      } catch (e) {
        return { error: e };
      }
    }),
    map((action) =>
      action?.payload
        ? getCustomerWiseClinicReportQuickDetailSuccess(action?.payload)
        : getCustomerWiseClinicReportQuickDetailFail()
    )
  );

//next and previous
//get next
const getCustomerWiseClinicReportNext = (
  action$: Observable<Action>,
  _: stateAction,
  { dispatch }: dispatchAction
) =>
  action$.pipe(
    filter(getCustomerWiseClinicReportNextRequest.match),
    mergeMap(async (action) => {
      dispatch(loadingCustomerWiseClinicReportDetail());
      try {
        const response = await getNext(action.payload);
        return { payload: response.data };
      } catch (e) {
        return { error: e };
      }
    }),
    map((action) =>
      action?.payload
        ? getCustomerWiseClinicReportDetailSuccess(action?.payload)
        : getCustomerWiseClinicReportDetailFail()
    )
  );
//get previous
const getCustomerWiseClinicReportPrevious = (
  action$: Observable<Action>,
  _: stateAction,
  { dispatch }: dispatchAction
) =>
  action$.pipe(
    filter(getCustomerWiseClinicReportPreviousRequest.match),
    mergeMap(async (action) => {
      dispatch(loadingCustomerWiseClinicReportDetail());
      try {
        const response = await getPrevious(action.payload);
        return { payload: response.data };
      } catch (e) {
        return { error: e };
      }
    }),
    map((action) =>
      action?.payload
        ? getCustomerWiseClinicReportDetailSuccess(action?.payload)
        : getCustomerWiseClinicReportDetailFail()
    )
  );
//het purchase report details
const getCustomerWiseClinicReportEpic = (
  action$: Observable<Action>,
  _: stateAction,
  { dispatch }: dispatchAction
) =>
  action$.pipe(
    filter(getCustomerWiseClinicReportDetailRequest.match),
    mergeMap(async (action) => {
      try {
        const response = await API.getCustomerWiseClinicReportQuickReport(action.payload);

        return { payload: response.data };
      } catch (e) {
        return { error: e };
      }
    }),
    map((action) =>
      action?.payload
        ? getCustomerWiseClinicReportDetailSuccess(action?.payload)
        : getCustomerWiseClinicReportDetailFail()
    )
  );
export const CustomerWiseClinicReportEpics = combineEpics(
  getCustomerWiseClinicReportUserEpic,
  getCustomerWiseClinicReportAllSummaryEpic,
  getCustomerWiseClinicReportQuickSummaryEpic,
  getCustomerWiseClinicReportQuickDetailEpic,
  getCustomerWiseClinicReportAllDetailEpic,
  getCustomerWiseClinicReportPrevious,
  getCustomerWiseClinicReportNext,
  getCustomerWiseClinicReportEpic,
);
