import {
  createPackingTypeSuccess,
  createPackingTypeFail,
  createPackingTypeRequest,
  getPackingTypeSuccess,
  getPackingTypeFail,
  getUnitPackingTypeRequest,
  getUnitPackingTypeSuccess,
  getUnitPackingTypeFail,
  updatePackingTypeFail,
  updatePackingTypeSuccess,
  getPackingTypeRequest,
  updatePackingTypeRequest,
  getPackingTypeNextRequest,
  getPackingTypePreviousRequest,
  clearPackingTypeData,
} from "./packingTypeSlice";
import { mergeMap } from "rxjs";
import {
  createPackingType,
  getPackingType,
  updatePackingType,
  getUnitPackingType,
} from "./api";
import { map, filter, tap } from "rxjs/operators";
import { Observable } from "rxjs";
import { Action } from "@reduxjs/toolkit";
import { combineEpics } from "redux-observable";
import {
  alertErrorAction,
  alertSuccessAction,
  closeModal,
} from "../../../CommonAppRedux/CommonAppSlice";
import { getNext, getPrevious } from "../../../CommonAppRedux/api";
import {
  dispatchAction,
  stateAction,
} from "../../../../AppUtils/Utils/globalTypes";
import messages from "../../../../AppUtils/Utils/validationConstants";

export const controller = new AbortController();

// get PackingType epic
const getPackingTypeEpic = (
  action$: Observable<Action>,
  _: stateAction,
  { dispatch }: dispatchAction
) =>
  action$.pipe(
    filter(getPackingTypeRequest.match),
    mergeMap(async (action) => {
      try {
        const response = await getPackingType(action.payload);
        return { payload: response.data };
      } catch (e) {
        return { error: e };
      }
    }),
    map((action) =>
      action?.payload
        ? getPackingTypeSuccess(action?.payload)
        : getPackingTypeFail()
    )
  );
// get unit Packing type
const getAllUnitPackingType = (
  action$: Observable<Action>,
  _: stateAction,
  { dispatch }: dispatchAction
) =>
  action$.pipe(
    filter(getUnitPackingTypeRequest.match),
    mergeMap(async (action) => {
      try {
        const response = await getUnitPackingType();
        return { payload: response.data };
      } catch (e) {
        return { error: e };
      }
    }),
    map((action) =>
      action?.payload
        ? getUnitPackingTypeSuccess(action?.payload)
        : getUnitPackingTypeFail()
    )
  );
//get next
const getPackingTypeNext = (
  action$: Observable<Action>,
  _: stateAction,
  { dispatch }: dispatchAction
) =>
  action$.pipe(
    filter(getPackingTypeNextRequest.match),
    mergeMap(async (action) => {
      try {
        const response = await getNext(action.payload);
        return { payload: response.data };
      } catch (e) {
        return { error: e };
      }
    }),
    map((action) =>
      action?.payload
        ? getPackingTypeSuccess(action?.payload)
        : getPackingTypeFail()
    )
  );

//get previous
const getPackingTypePrevious = (
  action$: Observable<Action>,
  _: stateAction,
  { dispatch }: dispatchAction
) =>
  action$.pipe(
    filter(getPackingTypePreviousRequest.match),
    mergeMap(async (action) => {
      try {
        const response = await getPrevious(action.payload);
        return { payload: response.data };
      } catch (e) {
        return { error: e };
      }
    }),
    map((action) =>
      action?.payload
        ? getPackingTypeSuccess(action?.payload)
        : getPackingTypeFail()
    )
  );
//create PackingType epic
const createPackingTypeEpic = (
  action$: Observable<Action>,
  _: stateAction,
  { dispatch }: dispatchAction
) =>
  action$.pipe(
    filter(createPackingTypeRequest.match),
    mergeMap(async ({ payload: { values, rowsPerPage, page } }) => {
      const { name, shortName, unit, active } = values;
      try {
        const body = JSON.stringify({ name, shortName, unit, active });
        const response = await createPackingType(body);
        if (response) {
          dispatch(getPackingTypeRequest({ rowsPerPage, page }));
          dispatch(alertSuccessAction(messages.createMessage));
          dispatch(closeModal());
        }
        return { payload: { response } };
      } catch (e) {
        dispatch(alertErrorAction(messages.createFailMessage));
        return { error: e };
      }
    }),
    map((action) => {
      return action?.payload
        ? createPackingTypeSuccess()
        : createPackingTypeFail();
    })
  );

//update PackingType epic
const updatePackingTypeEpic = (
  action$: Observable<Action>,
  _: stateAction,
  { dispatch }: dispatchAction
) =>
  action$.pipe(
    filter(updatePackingTypeRequest.match),
    mergeMap(async ({ payload: { values, id, rowsPerPage, page } }) => {
      const { name, shortName, unit, active, remarks } = values;
      try {
        const body = JSON.stringify({ name, shortName, unit, active, remarks });
        const response = await updatePackingType(body, id);
        if (response) {
          dispatch(getPackingTypeRequest({ rowsPerPage, page }));
          dispatch(alertSuccessAction(messages.updateMessage));
          dispatch(clearPackingTypeData());
          dispatch(closeModal());
        }
        return { payload: { response, rowsPerPage } };
      } catch (e) {
        dispatch(alertErrorAction(messages.updateFailMessage));
        return { error: e };
      }
    }),
    map((action) =>
      action?.payload ? updatePackingTypeSuccess() : updatePackingTypeFail()
    )
  );

export const packingTypeEpics = combineEpics(
  getPackingTypeEpic,
  createPackingTypeEpic,
  updatePackingTypeEpic,
  getPackingTypeNext,
  getPackingTypePrevious,
  getAllUnitPackingType
);
