import { axiosInstance } from "../../../../../../AppUtils/Utils/axios";

export const customerNoteUrl = "api/v1/appointment-app/customer-note";

// get customer note by id
export const getCustomerNoteById = (customerId: number) => {
  return axiosInstance.get(
    `${customerNoteUrl}?date_after=&date_before=&customer=${customerId}`
  );
};

// create customer note
export const createCustomerNote = (
  body: FormData | string,
  customerId: number
) => {
  return axiosInstance.post(
    `${customerNoteUrl}?date_after=&date_before=&customer=${customerId}`,
    body
  );
};

// Edited customer note
export const getEditedCustomerNote = (customerId: number) => {
  return axiosInstance.get(`${customerNoteUrl}/${customerId}`);
};

// update customer note
export const updateCustomerNote = (
  body: string | FormData,
  customerId: number
) => {
  return axiosInstance.patch(`${customerNoteUrl}/${customerId}`, body);
};

// Customer Note Templates
export const getCustomerNoteTemplates = () => {
  return axiosInstance.get(`${customerNoteUrl}/note-template`);
};

// Filter Note Templates by id
export const filterCustomerNoteTemplates = (id: number) => {
  return axiosInstance.get(`${customerNoteUrl}/note-template?id=${id}&name=`);
};

// Get selected Customer Note by Ids
export const selectedCustomerNote = (id: number) => {
  return axiosInstance.get(`${customerNoteUrl}/${id}`);
};
