//lab permission constants
export const sampleCollectionPermission = [
  "view_lab_sample_collection",
  "add_lab_sample_collection",
  "undo_lab_sample_collection",
];
export const resultEntryPermission = [
  "view_result_entry",
  "add_result_entry",
  "edit_result_entry",
  "delete_result_entry",
];
export const testMethodPermission = [
  "view_test_method",
  "add_test_method",
  "update_test_method",
];
export const testPermission = ["view_test", "add_test", "update_test"];
export const packageTestPermission = [
  "view_package_test",
  "add_package_test",
  "update_package_test",
];
export const testFindingPermission = [
  "view_test_finding",
  "add_test_finding",
  "edit_test_finding",
  "delete_test_finding",
];
export const testUnitPermission = ["view_test_unit", "add_test_unit", "update_test_unit"];
export const referenceRangePermission = [
  "view_reference_range",
  "add_reference_range",
  "update_reference_range",
];
export const footerNotePermission = [
  "view_test_footer_note",
  "add_test_footer_note",
  "update_test_footer_note",
];
export const testCategoryPermission = [
  "view_test_category",
  "add_test_category",
  "update_test_category",
];
export const departmentPermission = [
  "view_department",
  "add_department",
  "update_department",
];
export const reportCommentPermission = [
  "view_Report_comment",
  "add_Report_comment",
  "update_Report_comment",
];
export const verificationAndPrintPermission = [
  "view_lab_report",
  "verify_lab_report",
  "print_lab_report",
  "email_lab_report",
  "unapprove_lab_report",
];
export const sensitiveMedPermission = [
  "view_sensitive_medicine",
  "add_sensitive_medicine",
  "update_sensitive_medicine",
];
export const testSensitiveMedPermission = [
  "view_test_sensitive_medicine",
  "add_test_sensitive_medicine",
  "update_test_sensitive_medicine",
];
export const sampleTypePermission = [
  "view_sample_type",
  "add_sample_type",
  "undo_sample_type",
];
export const reagentPermission = [
  "view_reagent",
  "add_reagent",
  "undo_reagent",
];
export const reportFooterPermission = [
  "view_report_footer",
  "add_report_footer",
  "update_report_footer",
];
