import { TableRow, TableCell, Grid, Checkbox, Chip } from "@mui/material";
import { memo, useRef } from "react";
import { sortFunction, getComparator } from "../TableUtils/SortFunction";
import EditIcon from "@mui/icons-material/Edit";
import DeleteIcon from "@mui/icons-material/Delete";
import { tableBodyProps } from "../types";
import AppButton from "../../AppElements/Button/AppButton";
import { commonAppSelector } from "../../../Pages/CommonAppRedux/selector";
import { useAppSelector } from "../../../AppUtils/Utils/appHooks";
import DescriptionIcon from "@mui/icons-material/Description";
import AddIcon from "@mui/icons-material/Add";
import Status from "../../../Component/Status/Status";
import PrintIcon from "@mui/icons-material/Print";
import { tableStyles } from "../styles";
import { loginSelector } from "../../../Pages/Auth/Redux/selector";
import { handlePermission } from "../../../AppUtils/Utils/permission";
import { getCreatedDate } from "../../../AppUtils/Utils/dateFunctions";
import LabelIcon from "@mui/icons-material/Label";
import AppAvatar from "../../AppElements/AppAvatar";
import VerticalMenus from "../../VerticalMenu/VerticalMenu";
import VerticalMenusForPurchase from "../../VerticalMenuForPurchase/VerticalMenuForPurchase";
import VerticalMenusForContact from "../../VerticalMenuContact/VerticalMenuForContact";

const AppTableBody = ({
  columns,
  data,
  order,
  orderBy,
  handleEdit,
  handleReceive,
  handleDispatch,
  handleMakeBill,
  handleDelete,
  handleCancel,
  handleViewDetails,
  handlePrintDetails,
  handleStickerPrintDetails,
  showPrintDoubleButton,
  showDeleteButton,
  showAddIcon,
  hideAction,
  showDetailsButton,
  showPrintButton,
  hideEditButton,
  hidePreferences,
  setSelected,
  showSelectAllCheckBox,
  checkBoxData,
  showMoreIcon,
  handlePrintPdfReport,
  handleVerify,
  showMorePurchaseIcon,
  showMoreContactIcon,
  handleStatusUpdate,
  handleViewContacts,
  handleRepaired,
}: // handleShowDetail,
// handleVerify,
// handleEdit,
// handlePrintPdfReport,
tableBodyProps) => {
  const { rowsPerPage, page, passPermission } =
    useAppSelector(commonAppSelector);
  const { permissions, isSuperuser } = useAppSelector(loginSelector);
  const value = useRef<any>("");
  const classes = tableStyles();

  const handleSelectRowId = (
    event: React.MouseEvent<HTMLButtonElement, MouseEvent>,
    row: any
  ) => {
    setSelected((prev: any) => {
      if (prev.includes(row?.id as number)) {
        return prev.filter((customerTest: any) => customerTest !== row?.id);
      } else {
        return [...prev, row?.id];
      }
    });
  };
  return (
    <>
      {sortFunction(data, getComparator(order, orderBy)).map(
        (row: any, i: number) => {
          return (
            <TableRow
              hover
              role="checkbox"
              tabIndex={-1}
              key={row.id ?? row?.uniqueId}
              style={{ paddingTop: 10 }}
            >
              <TableCell>{rowsPerPage * page + (i + 1)}</TableCell>
              {columns?.map((column: any) => {
                const {
                  id,
                  boolean,
                  date,
                  styleId,
                  dateWithTime,
                  avatar,
                  chip,
                  amount,
                  multiple,
                  text,
                  description,
                } = column;
                value.current = row[id];
                // if field is boolean then show status and if value is date obj then convert to date
                if (boolean) {
                  value.current = <Status active={row[id]} />;
                } else if (date || dateWithTime) {
                  value.current =
                    value.current !== "" &&
                    value.current !== null &&
                    value.current !== undefined
                      ? getCreatedDate(value.current, dateWithTime)
                      : value.current;
                } else if (avatar) {
                  value.current = <AppAvatar value={row[id]} />;
                } else if (chip) {
                  value.current = (
                    <Chip
                      label={row[id]}
                      size="small"
                      style={{
                        color: "white",
                        fontSize: "11.5px",
                        background: row[id] === "CASH" ? "#43a047" : "#e53935",
                      }}
                    />
                  );
                } else if (amount) {
                  value.current = `Rs. ${row[id]}`;
                } else if (description?.string) {
                  value.current = (
                    <div style={{ maxWidth: description?.maxWidth ?? "200px" }}>
                      {row[id] && typeof row[id] === "string"
                        ? description?.maxCharacters &&
                          row[id].length > description.maxCharacters
                          ? `${row[id].slice(0, description.maxCharacters)}...`
                          : row[id]
                        : row[id]}
                    </div>
                  );
                } else if (multiple) {
                  value.current = row[id]?.map((multiple: any, i: number) => {
                    return (
                      <Chip
                        key={i}
                        label={multiple?.name}
                        size="small"
                        variant="outlined"
                        color="success"
                      />
                    );
                  });
                }
                return (
                  <TableCell
                    key={id}
                    sx={{ fontSize: 14 }}
                    className={`${classes.tableData} ${
                      styleId ? classes.styleTableID : ""
                    }`}
                  >
                    {value.current
                      ? value.current
                      : value.current === 0
                      ? "0.00"
                      : typeof value.current === "boolean"
                      ? value.current
                      : "-"}
                  </TableCell>
                );
              })}
              {!hideAction && (
                <>
                  <TableCell>
                    <Grid
                      container
                      direction="row"
                      spacing={1}
                    >
                      {isSuperuser ||
                      handlePermission(
                        permissions,
                        showAddIcon
                          ? passPermission?.edit_permission
                          : passPermission?.edit_permission
                      ) ? (
                        <>
                          {!hideEditButton && (
                            <Grid item>
                              <AppButton
                                tooltipTitle={`${showAddIcon ? "ADD" : "Edit"}`}
                                onClick={() => {
                                  handleEdit?.(row);
                                }}
                                submit={false}
                              >
                                {showAddIcon ? <AddIcon /> : <EditIcon />}
                              </AppButton>
                            </Grid>
                          )}
                        </>
                      ) : null}
                      {showMoreIcon && (
                        <Grid item>
                          <VerticalMenus
                            handleReceive={handleReceive}
                            handleDispatch={handleDispatch}
                            handleMakeBill={handleMakeBill}
                            handleRepaired={handleRepaired}
                            handleCancel={handleCancel}
                            row={row}
                          />
                        </Grid>
                      )}
                      {showMorePurchaseIcon && (
                        <Grid item>
                          <VerticalMenusForPurchase
                            handleShowDetail={handleViewDetails}
                            handleVerify={handleVerify}
                            handleEdit={handleEdit}
                            handlePrintPdfReport={handlePrintPdfReport}
                            row={row}
                          />
                        </Grid>
                      )}
                      {showMoreContactIcon && (
                        <Grid item>
                          <VerticalMenusForContact
                            handleStatusUpdate={handleStatusUpdate}
                            handleShowDetail={handleViewDetails}
                            handleViewContacts={handleViewContacts}
                            row={row}
                          />
                        </Grid>
                      )}
                      {isSuperuser ||
                      handlePermission(
                        permissions,
                        passPermission?.view_permission
                      ) ? (
                        <>
                          {showDetailsButton && (
                            <Grid item>
                              <AppButton
                                tooltipTitle="View Details"
                                onClick={() => {
                                  handleViewDetails?.(row);
                                }}
                                submit={false}
                              >
                                <DescriptionIcon />
                              </AppButton>
                            </Grid>
                          )}
                        </>
                      ) : null}

                      {(showDeleteButton || row?.isNew) && (
                        <Grid item>
                          <AppButton
                            tooltipTitle="Delete"
                            onClick={() => {
                              handleDelete?.(row);
                            }}
                            submit={false}
                            isDelete={true}
                          >
                            <DeleteIcon />
                          </AppButton>
                        </Grid>
                      )}

                      {isSuperuser ||
                      handlePermission(
                        permissions,
                        passPermission?.print_permission
                      ) ? (
                        <>
                          {showPrintButton && (
                            <Grid item>
                              <AppButton
                                tooltipTitle="Print"
                                onClick={() => {
                                  handlePrintDetails?.(row);
                                }}
                                submit={false}
                              >
                                <PrintIcon />
                              </AppButton>
                            </Grid>
                          )}
                        </>
                      ) : null}
                      {isSuperuser ||
                      handlePermission(
                        permissions,
                        passPermission?.print_permission
                      ) ? (
                        <>
                          {showPrintDoubleButton && (
                            <Grid item>
                              <AppButton
                                tooltipTitle="Print Label"
                                onClick={() => {
                                  handleStickerPrintDetails?.(row);
                                }}
                                submit={false}
                              >
                                <LabelIcon />
                              </AppButton>
                            </Grid>
                          )}
                        </>
                      ) : null}
                    </Grid>
                  </TableCell>
                  {showSelectAllCheckBox && (
                    <TableCell>
                      <Checkbox
                        color="primary"
                        checked={checkBoxData?.includes(row?.id)}
                        onClick={(event) => {
                          handleSelectRowId(event, row);
                        }}
                        inputProps={{
                          "aria-label": "select all",
                        }}
                      />
                    </TableCell>
                  )}
                </>
              )}
              {!hidePreferences && <TableCell></TableCell>}
            </TableRow>
          );
        }
      )}
    </>
  );
};

export default memo(AppTableBody);
