import {
  getDepositReturnRequest,
  getDepositReturnFail,
  getDepositReturnSuccess,
  createDepositReturnFail,
  createDepositReturnRequest,
  createDepositReturnSuccess,
  updateDepositReturnFail,
  updateDepositReturnSuccess,
  clearAllData,
  getDepositReturnNextRequest,
  getDepositReturnPreviousRequest,
  updateDepositReturnRequest,
  getDepositReturnByIdRequest,
  depositEditSuccess,
  depositEditFail,
  getDepositReturnCustomerRequest,
  getDepositReturnCustomerSuccess,
  getDepositReturnCustomerFail,
  getDepositReturnDepositRequest,
  getDepositReturnDepositSuccess,
  getDepositReturnDepositFail,
} from "./depositReturnSlice";
import { mergeMap } from "rxjs";
import {
  getDepositReturn,
  createDepositReturn,
  updateDepositReturn,
  getDepositReturnById,
  getDepositReturnCustomer,
  getDepositReturnDeposit,
} from "./api";
import { map, filter } from "rxjs/operators";
import { Observable } from "rxjs";
import { Action } from "@reduxjs/toolkit";
import { combineEpics } from "redux-observable";
import {
  alertErrorAction,
  alertSuccessAction,
  closeModal,
} from "../../../CommonAppRedux/CommonAppSlice";
import { getNext, getPrevious } from "../../../CommonAppRedux/api";
import {
  dispatchAction,
  stateAction,
} from "../../../../AppUtils/Utils/globalTypes";
import messages from "../../../../AppUtils/Utils/validationConstants";
export const controller = new AbortController();

//get DepositReturn
const getDepositReturnEpic = (
  action$: Observable<Action>,
  _: stateAction,
  { dispatch }: dispatchAction
) =>
  action$.pipe(
    filter(getDepositReturnRequest.match),
    mergeMap(async (action: any) => {
      try {
        const response = await getDepositReturn(action.payload);
        return { payload: response.data };
      } catch (e) {
        return { error: e };
      }
    }),
    map((action) =>
      action?.payload
        ? getDepositReturnSuccess(action?.payload)
        : getDepositReturnFail()
    )
  );

//get deposit by id epic
const getDepositReturnByIdEpic = (
  action$: Observable<Action>,
  _: stateAction,
  { dispatch }: dispatchAction
) =>
  action$.pipe(
    filter(getDepositReturnByIdRequest.match),
    mergeMap(async (action) => {
      try {
        const response = await getDepositReturnById(action.payload);
        return { payload: response.data };
      } catch (e) {
        return { error: e };
      }
    }),
    map((action) =>
      action?.payload ? depositEditSuccess(action.payload) : depositEditFail()
    )
  );

//create
const createDepositReturnEpic = (
  action$: Observable<Action>,
  _: stateAction,
  { dispatch }: dispatchAction
) =>
  action$.pipe(
    filter(createDepositReturnRequest.match),
    mergeMap(async ({ payload: { values, rowsPerPage, page } }) => {
      try {
        const body = new FormData();
        for (let [key, value] of Object.entries(values)) {
          body.append(`${key}`, String(value));
        }
        const response = await createDepositReturn(body);
        if (response) {
          dispatch(getDepositReturnRequest({ rowsPerPage, page }));
          dispatch(alertSuccessAction(messages.createMessage));
          dispatch(closeModal());
        }
        return { payload: { response } };
      } catch (e) {
        dispatch(alertErrorAction(messages.createFailMessage));
        return { error: e };
      }
    }),
    map((action) => {
      return action?.payload
        ? createDepositReturnSuccess()
        : createDepositReturnFail();
    })
  );

//update
const updateDepositReturnEpic = (
  action$: Observable<Action>,
  _: stateAction,
  { dispatch }: dispatchAction
) =>
  action$.pipe(
    filter(updateDepositReturnRequest.match),
    mergeMap(async ({ payload: { values, id, rowsPerPage, page, search } }) => {
      try {
        const body = new FormData();
        for (let [key, value] of Object.entries(values)) {
          body.append(`${key}`, String(value));
        }
        const response = await updateDepositReturn(body, id);
        if (response) {
          dispatch(getDepositReturnRequest({ rowsPerPage, page, search }));
          dispatch(alertSuccessAction(messages.updateMessage));
          dispatch(closeModal());
          dispatch(clearAllData());
        }
        return { payload: { response, rowsPerPage } };
      } catch (e) {
        dispatch(alertErrorAction(messages.updateFailMessage));
        return { error: e };
      }
    }),
    map((action) => {
      return action?.payload
        ? updateDepositReturnSuccess()
        : updateDepositReturnFail();
    })
  );

//get next
const getDepositReturnNext = (
  action$: Observable<Action>,
  _: stateAction,
  { dispatch }: dispatchAction
) =>
  action$.pipe(
    filter(getDepositReturnNextRequest.match),
    mergeMap(async (action) => {
      try {
        const response = await getNext(action.payload);
        return { payload: response.data };
      } catch (e) {
        return { error: e };
      }
    }),
    map((action) => {
      return action?.payload
        ? getDepositReturnSuccess(action?.payload)
        : getDepositReturnFail();
    })
  );

//get previous
const getDepositReturnPrevious = (
  action$: Observable<Action>,
  _: stateAction,
  { dispatch }: dispatchAction
) =>
  action$.pipe(
    filter(getDepositReturnPreviousRequest.match),
    mergeMap(async (action) => {
      try {
        const response = await getPrevious(action?.payload);
        return { payload: response.data };
      } catch (e) {
        return { error: e };
      }
    }),
    map((action) =>
      action?.payload
        ? getDepositReturnSuccess(action?.payload)
        : getDepositReturnFail()
    )
  );

//customer
const getDepositReturnCustomerEpic = (
  action$: Observable<Action>,
  _: stateAction,
  { dispatch }: dispatchAction
) =>
  action$.pipe(
    filter(getDepositReturnCustomerRequest.match),
    mergeMap(async (action: any) => {
      try {
        const response = await getDepositReturnCustomer(action.payload);
        return { payload: response.data };
      } catch (e) {
        return { error: e };
      }
    }),
    map((action) =>
      action?.payload
        ? getDepositReturnCustomerSuccess(action?.payload)
        : getDepositReturnCustomerFail()
    )
  );

// order
const getDepositReturnDepositEpic = (
  action$: Observable<Action>,
  _: stateAction,
  { dispatch }: dispatchAction
) =>
  action$.pipe(
    filter(getDepositReturnDepositRequest.match),
    mergeMap(async ({ payload: { customer, defaultRowsPerPage } }) => {
      try {
        const response = await getDepositReturnDeposit(
          customer,
          defaultRowsPerPage
        );
        return { payload: response.data };
      } catch (e) {
        return { error: e };
      }
    }),
    map((action) =>
      action?.payload
        ? getDepositReturnDepositSuccess(action?.payload)
        : getDepositReturnDepositFail()
    )
  );

export const depositReturnEpics = combineEpics(
  getDepositReturnEpic,
  createDepositReturnEpic,
  updateDepositReturnEpic,
  getDepositReturnNext,
  getDepositReturnPrevious,
  getDepositReturnByIdEpic,
  getDepositReturnCustomerEpic,
  getDepositReturnDepositEpic
);
