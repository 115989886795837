import { createSlice } from "@reduxjs/toolkit";
import { contactRequestProps } from "./types";

const initialState: contactRequestProps = {
    contactRequest: [],
    loadingContactRequest: false,
    edit: false,
    editedContactRequest: null,
    loadingCreateContactRequest: false,
    contactRequestForList: [],
    loadingContactRequestForList: false,
    loadingAllContactRequests: false,
    count: 0,
    previous: "",
    next: "",
    allContactRequests: [],
    loadingUpdateContactStatus: false,
};

export const contactRequestSlice = createSlice({
    name: "contactRequestReducer",
    initialState,
    reducers: {
        getContactRequest: (state, payload) => {
            state.loadingContactRequest = true;
        },
        getContactRequestSuccess: (state, { payload: { results } }) => {
            state.contactRequest = results;
            state.loadingContactRequest = false;
        },
        getContactRequestFail: (state) => {
            state.loadingContactRequest = false;
        },
        getAllContactRequest: (state, payload) => {
            state.loadingAllContactRequests = true;
        },
        getAllContactRequestSuccess: (state, { payload: { results, count, next, previous } }) => {
            state.loadingAllContactRequests = false;
            state.allContactRequests = results;
            state.count = count;
            state.next = next;
            state.previous = previous;
        },
        getAllContactRequestFail: (state) => {
            state.loadingAllContactRequests = false;
        },
        getAllContactRequestNextRequest: (state, payload) => {
            state.loadingAllContactRequests = true;
        },
        getAllContactRequestPreviousRequest: (state, payload) => {
            state.loadingAllContactRequests = true;
        },
        getContactRequestByIdRequest: (state, payload) => {
            state.edit = true
        },
        editContactRequest: (state, payload) => {
            state.edit = true
        },
        editContactRequestSuccess: (state, { payload }) => {
            state.edit = true;
            state.editedContactRequest = payload;
        },
        editContactRequestFail: (state) => {
            state.edit = false;
            state.editedContactRequest = null;
        },
        updateContactStatusRequest: (state, payload) => {
            state.loadingUpdateContactStatus = true;
        },
        updateContactStatusSuccess: (state) => {
            state.loadingUpdateContactStatus = false;
        },
        updateContactStatusFail: (state) => {
            state.loadingUpdateContactStatus = false;
        },
        createContactRequestByIdRequest: (state, payload) => {
            state.loadingCreateContactRequest = true;
        },
        createContactRequestByIdSuccess: (state) => {
            state.loadingCreateContactRequest = false;
        },
        createContactRequestByIdFail: (state) => {
            state.loadingCreateContactRequest = false;
        },
        updateContactRequestByIdRequest: (state, payload) => {
            state.loadingCreateContactRequest = true;
        },
        updateContactRequestByIdSuccess: (state) => {
            state.loadingCreateContactRequest = false;
        },
        updateContactRequestByIdFail: (state) => {
            state.loadingCreateContactRequest = false;
        },
        getContactRequestForListRequest: (state) => {
            state.loadingContactRequestForList = true;
        },
        getContactRequestForListSuccess: (state, { payload: { results } }) => {
            state.loadingContactRequestForList = false;
            state.contactRequestForList = results
        },
        getContactRequestForListFail: (state) => {
            state.loadingContactRequestForList = false;
        },
        clearContactRequest: (state) => {
            state.edit = false;
            state.editedContactRequest = null;
        },
        clearAllContactRequests: (state) => {
            state.allContactRequests = [];
        }
    },
});

export const {
    getContactRequest,
    getContactRequestSuccess,
    getContactRequestFail,
    getContactRequestByIdRequest,
    editContactRequest,
    editContactRequestSuccess,
    editContactRequestFail,
    createContactRequestByIdRequest,
    createContactRequestByIdSuccess,
    createContactRequestByIdFail,
    updateContactRequestByIdRequest,
    updateContactRequestByIdSuccess,
    updateContactRequestByIdFail,
    getContactRequestForListRequest,
    getContactRequestForListSuccess,
    getContactRequestForListFail,
    clearContactRequest,
    //
    getAllContactRequest,
    getAllContactRequestFail,
    getAllContactRequestSuccess,
    getAllContactRequestNextRequest,
    getAllContactRequestPreviousRequest,
    clearAllContactRequests,
    //
    updateContactStatusRequest,
    updateContactStatusSuccess,
    updateContactStatusFail,
} = contactRequestSlice.actions;

export default contactRequestSlice.reducer;
