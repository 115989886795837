import { createSlice, createAction } from "@reduxjs/toolkit";
const initialState: any = {
  refundBillings: [],
  loadingRefundBilling: false,
  loadingCreateRefundBilling: false,
  edit: false,
  customers: [],
  loadingCustomer: false,
  billings: [],
  loadingBillings: false,
  refundBillingDetails: [],
  loadingRefundBillingDetails: false,
  loadingPaymentModes: false,
  refundBillingPaymentModes: [],
  count: 0,
  previous: "",
  next: "",
  // refund billing detail
  loadingViewRefundDetails: false,
  viewRefundDetails: [],
  refundBillingRowData: {},
  refundBillingId: "",
  refundBillingDetailCount: null,
  refundBillingDetailNext: null,
  refundBillingDetailPrevious: null,
  // print
  loadingPrintRefundBilling: false,
  refundPrintCount: [],
  viewRefundDetailsData: [],
  viewRefundSmallDetailsData: [],
  loadingPrintRefund: false,
  createRefundBillingId: "",
};

export const RefundBilling = createSlice({
  name: "Refund Billing Reducer",
  initialState,
  reducers: {
    getRefundBillingRequest: (state, payload) => {
      state.loadingRefundBilling = true;
    },
    getRefundBillingSuccess: (
      state,
      { payload: { results, count, next, previous } }
    ) => {
      state.loadingRefundBilling = false;
      state.refundBillings = results;
      state.count = count;
      state.next = next;
      state.previous = previous;
    },
    getRefundBillingFail: (state) => {
      state.loadingRefundBilling = false;
    },
    getRefundBillingNextRequest: (state, payload) => {
      state.loadingRefundBilling = true;
    },
    getRefundBillingPreviousRequest: (state, payload) => {
      state.loadingRefundBilling = true;
    },
    loadingCustomerReq: (state) => {
      state.loadingCustomer = true;
    },
    getAllCustomerRequest: (state, payload) => {
      state.loadingCustomer = true;
    },
    getAllCustomerSuccess: (state, { payload: { results } }) => {
      state.loadingCustomer = false;
      state.customers = results;
    },
    getAllCustomerFail: (state) => {
      state.loadingCustomer = false;
    },
    getBillingRequest: (state, payload) => {
      state.loadingBillings = true;
    },
    getBillingSuccess: (state, { payload: { results } }) => {
      state.loadingBillings = false;
      state.billings = results;
    },
    getBillingFail: (state) => {
      state.loadingBillings = false;
    },
    getRefundBillingDetailsRequest: (state, payload) => {
      state.loadingRefundBillingDetails = true;
    },
    getRefundBillingDetailsSuccess: (state, { payload: { results } }) => {
      state.loadingRefundBillingDetails = false;
      state.refundBillingDetails = results?.map((result: any) => ({
        ...result,
        netAmount: 0 * result?.cost,
        grossAmount: 0 * result?.cost,
        discountRate:
          Number(result.discountAmount * 100) /
          (result.cost * result.qty === 0 ? 1 : result.cost * result.qty),
        discountAmount: 0,
        returnQty: 0 * result?.returnQty,
      }));
    },
    getRefundBillingDetailsFail: (state) => {
      state.loadingRefundBillingDetails = false;
    },
    refundBillingDetailsUpdatedSuccess: (state, { payload }) => {
      state.refundBillingDetails = payload;
    },
    getRefundBillingPaymentModesRequest: (state) => {
      state.loadingPaymentModes = true;
    },
    getRefundBillingPaymentModesSuccess: (state, { payload: { results } }) => {
      state.loadingPaymentModes = false;
      state.refundBillingPaymentModes = results;
    },
    getRefundBillingPaymentModeFail: (state) => {
      state.loadingPaymentModes = false;
    },
    getViewRefundBillingDetailsRequest: (state, { payload }) => {
      state.loadingViewRefundDetails = true;
      // state.refundBillingRowData = payload?.rowData;
      // state.refundBillingId = payload?.id;
    },
    getViewRefundBillingDetailsSuccess: (
      state,
      { payload: { results, count, next, previous } }
    ) => {
      state.loadingViewRefundDetails = false;
      state.viewRefundDetails = results;
      state.refundBillingDetailCount = count;
      state.refundBillingDetailNext = next;
      state.refundBillingDetailPrevious = previous;
    },
    getViewRefundBillingDetailsFail: (state) => {
      state.loadingViewRefundDetails = false;
    },
    getViewRefundBillingDetailsNextRequest: (state, payload) => {
      state.loadingViewRefundDetails = true;
    },
    getViewRefundBillingDetailsPreviousRequest: (state, payload) => {
      state.loadingViewRefundDetails = true;
    },
    getPrintRefundBillingDetailsRequest: (state, { payload }) => {
      // state.refundBillingRowData = payload?.rowData;
      state.loadingPrintRefundBilling = true;
      // state.refundBillingId = payload?.id;
    },
    getPrintRefundBillingCustomerDetailsSuccess: (state, { payload }) => {
      state.loadingPrintRefundBilling = false;
      state.refundBillingRowData = payload;
      state.refundBillingId = payload?.id;
    },
    getPrintRefundBillingCustomerDetailsFail: (state) => {
      state.loadingPrintRefundBilling = false;
    },
    getPrintRefundBillingDetailsSuccess: (state, { payload: { results } }) => {
      state.loadingPrintRefundBilling = false;
      state.viewRefundDetails = results;
      state.viewRefundDetailsData = results?.map(
        (refundDetail: any, i: number) => [
          i + 1,
          refundDetail.testName,
          refundDetail.netAmount,
          refundDetail.qty,
          refundDetail.discountAmount,
          refundDetail.discountRate,
          refundDetail.grossAmount,
          refundDetail.netAmount,
        ]
      );
      state.viewRefundSmallDetailsData = results?.map(
        (refundDetail: any, i: number) => [
          i + 1,
          refundDetail.testName,
          refundDetail.cost,
          refundDetail.qty,
          refundDetail.netAmount,
        ]
      );
    },
    getPrintRefundBillingDetailsFail: (state) => {
      state.loadingPrintRefundBilling = false;
    },
    refundPrintCountRequest: (state, payload) => {
      state.loadingPrintRefundBilling = true;
    },
    refundPrintCountSuccess: (state, payload) => {
      state.loadingPrintRefundBilling = true;
    },
    refundPrintCountFail: (state) => {
      state.loadingPrintRefundBilling = true;
    },
    createRefundBillingRequest: (state, payload) => {
      state.loadingCreateRefundBilling = true;
    },
    createRefundBillingSuccess: (state, { payload }) => {
      state.loadingCreateRefundBilling = false;
      state.createRefundBillingId = payload?.response?.data?.id;
    },
    createRefundBillingFail: (state) => {
      state.loadingCreateRefundBilling = false;
    },
    clearRefundBillingCustomer: (state) => {
      state.billings = [];
      state.refundBillingDetails = [];
    },
    clearRefundBillingSaleNo: (state) => {
      state.refundBillingDetails = [];
    },
    clearRefundBillingData: (state) => {
      state.customers = [];
      state.billings = [];
      state.refundBillingDetails = [];
      state.loadingCustomers = false;
      state.loadingRefundBilling = false;
      state.loadingCreateRefundBilling = false;
      state.loadingRefundBillingDetails = false;
    },
    //print
    getRefundPrintCountRequest: (state, payload) => {
      state.loadingPrintRefund = true;
    },
    getRefundPrintCountSuccess: (state, { payload: { results } }) => {
      state.loadingPrintRefund = false;
      state.refundPrintCount = results;
    },
    getRefundPrintCountFail: (state) => {
      state.loadingPrintRefund = false;
    },
  },
});
// Action creators are generated for each case reducer function
export const {
  getRefundBillingRequest,
  getRefundBillingSuccess,
  getRefundBillingFail,
  getRefundBillingNextRequest,
  getRefundBillingPreviousRequest,
  loadingCustomerReq,
  getAllCustomerRequest,
  getAllCustomerSuccess,
  getAllCustomerFail,
  getBillingRequest,
  getBillingSuccess,
  getBillingFail,
  getRefundBillingDetailsRequest,
  getRefundBillingDetailsSuccess,
  getRefundBillingDetailsFail,
  refundBillingDetailsUpdatedSuccess,
  getRefundBillingPaymentModesRequest,
  getRefundBillingPaymentModesSuccess,
  getRefundBillingPaymentModeFail,
  getViewRefundBillingDetailsRequest,
  getViewRefundBillingDetailsSuccess,
  getViewRefundBillingDetailsFail,
  getViewRefundBillingDetailsPreviousRequest,
  getViewRefundBillingDetailsNextRequest,
  getPrintRefundBillingDetailsRequest,
  getPrintRefundBillingDetailsSuccess,
  getPrintRefundBillingDetailsFail,
  refundPrintCountRequest,
  refundPrintCountSuccess,
  refundPrintCountFail,
  createRefundBillingRequest,
  createRefundBillingSuccess,
  createRefundBillingFail,
  clearRefundBillingCustomer,
  clearRefundBillingSaleNo,
  clearRefundBillingData,
  getPrintRefundBillingCustomerDetailsSuccess,
  getPrintRefundBillingCustomerDetailsFail,
  getRefundPrintCountRequest,
  getRefundPrintCountSuccess,
  getRefundPrintCountFail,
} = RefundBilling.actions;
export default RefundBilling.reducer;
