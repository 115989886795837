import {
  createServiceFail,
  createServiceRequest,
  createServiceSuccess,
  //
  getServiceRequest,
  getServiceFail,
  getServiceSuccess,
  //
  updateServiceFail,
  updateServiceSuccess,
  updateServiceRequest,
  //
  getServiceNextRequest,
  getServicePreviousRequest,
  //
  clearServiceData,
  //
  getServiceByIdRequest,
  //
  serviceEditSuccess,
  serviceEditFail,
  //
  getServiceItemCategoryRequest,
  getServiceItemCategorySuccess,
  getServiceItemCategoryFail,
} from "./serviceSlice";
import { mergeMap } from "rxjs";
import {
  createService,
  getService,
  getServiceById,
  getServiceItemCategory,
  updateService,
} from "./api";
import { map, filter } from "rxjs/operators";
import { Observable } from "rxjs";
import { Action } from "@reduxjs/toolkit";
import { combineEpics } from "redux-observable";
import {
  alertErrorAction,
  alertSuccessAction,
  closeModal,
} from "../../../CommonAppRedux/CommonAppSlice";
import { getNext, getPrevious } from "../../../CommonAppRedux/api";
import {
  dispatchAction,
  stateAction,
} from "../../../../AppUtils/Utils/globalTypes";
import messages from "../../../../AppUtils/Utils/validationConstants";
export const controller = new AbortController();

//get Service epic
const getServiceEpic = (
  action$: Observable<Action>,
  _: stateAction,
  { dispatch }: dispatchAction
) =>
  action$.pipe(
    filter(getServiceRequest.match),
    mergeMap(async (action) => {
      try {
        const response = await getService(action.payload);
        return { payload: response.data };
      } catch (e) {
        return { error: e };
      }
    }),
    map((action) =>
      action?.payload ? getServiceSuccess(action?.payload) : getServiceFail()
    )
  );

//get service by id epic
const getServiceByIdEpic = (
  action$: Observable<Action>,
  _: stateAction,
  { dispatch }: dispatchAction
) =>
  action$.pipe(
    filter(getServiceByIdRequest.match),
    mergeMap(async (action) => {
      try {
        const response = await getServiceById(action.payload);
        return { payload: response.data };
      } catch (e) {
        return { error: e };
      }
    }),
    map((action) =>
      action?.payload ? serviceEditSuccess(action?.payload) : serviceEditFail()
    )
  );

//get service item category
const getServiceItemCategoryEpic = (
  action$: Observable<Action>,
  _: stateAction,
  { dispatch }: dispatchAction
) =>
  action$.pipe(
    filter(getServiceItemCategoryRequest.match),
    mergeMap(async (action) => {
      try {
        const response = await getServiceItemCategory(action.payload);
        return { payload: response.data };
      } catch (e) {
        return { error: e };
      }
    }),
    map((action) =>
      action?.payload
        ? getServiceItemCategorySuccess(action?.payload)
        : getServiceItemCategoryFail()
    )
  );

//get next
const getServiceNext = (
  action$: Observable<Action>,
  _: stateAction,
  { dispatch }: dispatchAction
) =>
  action$.pipe(
    filter(getServiceNextRequest.match),
    mergeMap(async (action) => {
      try {
        const response = await getNext(action.payload);
        return { payload: response.data };
      } catch (e) {
        return { error: e };
      }
    }),
    map((action) =>
      action?.payload ? getServiceSuccess(action?.payload) : getServiceFail()
    )
  );
//get previous
const getServicePrevious = (
  action$: Observable<Action>,
  _: stateAction,
  { dispatch }: dispatchAction
) =>
  action$.pipe(
    filter(getServicePreviousRequest.match),
    mergeMap(async (action) => {
      try {
        const response = await getPrevious(action.payload);
        return { payload: response.data };
      } catch (e) {
        return { error: e };
      }
    }),
    map((action) =>
      action?.payload ? getServiceSuccess(action?.payload) : getServiceFail()
    )
  );
//create Service epic
const createServiceEpic = (
  action$: Observable<Action>,
  _: stateAction,
  { dispatch }: dispatchAction
) =>
  action$.pipe(
    filter(createServiceRequest.match),
    mergeMap(async ({ payload: { values, rowsPerPage, page } }) => {
      const {
        name,
        code,
        itemCategory,
        basicInfo,
        saleCost,
        taxable,
        taxRate,
        discountable,
        active,
      } = values;
      try {
        const body = JSON.stringify({
          name,
          code,
          itemCategory,
          taxRate,
          taxable,
          basicInfo,
          saleCost,
          discountable,
          active,
        });

        const response = await createService(body);
        if (response) {
          dispatch(getServiceRequest({ rowsPerPage, page }));
          dispatch(alertSuccessAction(messages.createMessage));
          dispatch(closeModal());
        }
        return { payload: { response } };
      } catch (e) {
        dispatch(alertErrorAction(messages.createFailMessage));
        return { error: e };
      }
    }),
    map((action) => {
      return action?.payload ? createServiceSuccess() : createServiceFail();
    })
  );
//update Service epic
const updateServiceEpic = (
  action$: Observable<Action>,
  _: stateAction,
  { dispatch }: dispatchAction
) =>
  action$.pipe(
    filter(updateServiceRequest.match),
    mergeMap(async ({ payload: { values, id, rowsPerPage, page } }) => {
      const {
        name,
        code,
        itemCategory,
        basicInfo,
        saleCost,
        taxable,
        taxRate,
        discountable,
        active,
        remarks,
      } = values;
      try {
        const body = JSON.stringify({
          name,
          code,
          itemCategory,
          basicInfo,
          saleCost,
          taxable,
          taxRate,
          discountable,
          active,
          remarks,
        });
        const response = await updateService(body, id);
        if (response) {
          dispatch(getServiceRequest({ rowsPerPage, page }));
          dispatch(alertSuccessAction(messages.updateMessage));
          dispatch(closeModal());
          dispatch(clearServiceData());
        }
        return { payload: { response, rowsPerPage } };
      } catch (e) {
        dispatch(alertErrorAction(messages.updateFailMessage));
        return { error: e };
      }
    }),
    map((action) =>
      action?.payload ? updateServiceSuccess() : updateServiceFail()
    )
  );

//

export const serviceEpics = combineEpics(
  getServiceEpic,
  createServiceEpic,
  updateServiceEpic,
  getServiceNext,
  getServicePrevious,
  getServiceByIdEpic,
  getServiceItemCategoryEpic
);
