import { axiosInstance } from "../../../../AppUtils/Utils/axios";
import {
  searchProps,
  getApiProps,
} from "../../../../AppUtils/Utils/globalTypes";
import { filterApiData } from "../../../../AppUtils/Utils/appFunctions";

export const siteSetupModURL = `api/v1/clinic-app/site-setup`;

//get siteSetup
export const getSiteSetup = (data: getApiProps) => {
  const { rowsPerPage, page, search } = data;
  return axiosInstance.get(
    `${siteSetupModURL}?offset=${rowsPerPage * page}&limit=${rowsPerPage}&search=${
      search ?? ""
    }${data?.filterData ? filterApiData(data?.filterData) : ""}`
  );
};
//get siteSetup by id
export const getSiteSetupById = (id: number) => {
  return axiosInstance.get(`${siteSetupModURL}/${id}`);
};
//search siteSetup
export const searchSiteSetup = ({ rowsPerPage, search }: searchProps) => {
  return `${siteSetupModURL}?offset=0&limit=${rowsPerPage}&search=${search ?? ""}`;
};
//update siteSetup
export const updateSiteSetup = (body: string | FormData, id: number) =>
  axiosInstance.patch(`${siteSetupModURL}/${id}`, body);
//create siteSetup
export const createSiteSetup = (body: string | FormData) =>
  axiosInstance.post(`${siteSetupModURL}`, body);
