import { axiosInstance } from "../../../../../AppUtils/Utils/axios";
import { filterApiData } from "../../../../../AppUtils/Utils/appFunctions";
import {
  getApiProps,
  searchProps,
} from "../../../../../AppUtils/Utils/globalTypes";
import { CustomerWiseClinicReport } from "./types";

const CustomerWiseClinicReportModURL = '/api/v1/clinic-financial-report-app/customer-wise-sales-report';

export const getCustomerWiseClinicReportSupplier = () =>
axiosInstance.get(`${CustomerWiseClinicReportModURL}/supplier?offset=0&limit=${0}`);
export const getCustomerWiseClinicReportUser = () =>
axiosInstance.get(`${CustomerWiseClinicReportModURL}/user?offset=0&limit=${0}`);
export const getCustomerWiseClinicReportItem = () =>
axiosInstance.get(`${CustomerWiseClinicReportModURL}/item?offset=0&limit=${0}`);
export const getCustomerWiseClinicReportAllReport = (data:any) =>{
  const {updatedValue} = data;
  const {
    summary,
    detail,
    user,
    startDateAd,
    endDateAd,
  } = updatedValue;
  return axiosInstance.get(
    `${CustomerWiseClinicReportModURL}/${summary?"summary":"detail"}?offset=0&limit=${0}${
      data?.filterData ? filterApiData(data?.filterData) : ""
    }&date_after=${startDateAd}&date_before=${endDateAd}`
  )};
export const getCustomerWiseClinicReportQuickReport = (data: any) => {
  const { rowsPerPage, page ,updatedValue} = data;
  const {
    summary,
    detail,
    user,
    startDateAd,
    endDateAd,
  } = updatedValue;
  return axiosInstance.get(
    `${CustomerWiseClinicReportModURL}/${summary?"summary":"detail"}?offset=${
      rowsPerPage * page
    }&limit=${rowsPerPage}${
      data?.filterData ? filterApiData(data?.filterData) : ""
    }&date_after=${startDateAd}&date_before=${endDateAd}`
  );
};
export const searchCustomerWiseClinicReport = ({ rowsPerPage, search ,updatedValue}: any) => {
  const {
    summary,
    detail,
    user,
    startDateAd,
    endDateAd,
  } = updatedValue;
  return `${CustomerWiseClinicReportModURL}/${summary?"summary":"detail"}?offset=0&limit=${rowsPerPage}&search=${search}&date_after=${startDateAd}&date_before=${endDateAd}`;
};