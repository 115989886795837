import { axiosInstance } from "../../../../../../AppUtils/Utils/axios";
import { searchProps } from "../../../../../../AppUtils/Utils/globalTypes";

export const userContactUrl = "api/v1/appointment-app/user-contact";

interface customerIdProps {
  customerId: number;
}

// fetch user contact
export const getUserContactById = ({ customerId }: customerIdProps) => {
  return axiosInstance.get(
    `${userContactUrl}?id=${customerId}&date_after=&date_before=`
  );
};

// create user contact
export const createUserContact = (body: string | FormData) => {
  return axiosInstance.post(`${userContactUrl}`, body);
};

// update user contact
export const updateUserContact = (
  body: string | FormData,
  customerId: customerIdProps
) => axiosInstance.patch(`${userContactUrl}/${customerId}`, body);

// edit user contact
export const getEditedUserContact = (id: number) => {
  return axiosInstance.get(`${userContactUrl}/${id}`);
};

// search user contacts
export const searchUserContact = ({ rowsPerPage, search }: searchProps) => {
  return `${userContactUrl}?offset=0&limit=${rowsPerPage}&search=${search}`;
};
