import {
  createUnitFail,
  createUnitRequest,
  createUnitSuccess,
  getUnitFail,
  getUnitSuccess,
  updateUnitFail,
  updateUnitSuccess,
  getUnitRequest,
  getUnitNextRequest,
  getUnitPreviousRequest,
  updateUnitRequest,
  clearUnitData,
  getUnitByIdRequest,
  unitEditSuccess,
  unitEditFail,
} from "./UnitSlice";
import { mergeMap } from "rxjs";
import { getUnit, createUnit, updateUnit, getUnitById } from "./api";
import { map, filter } from "rxjs/operators";
import { Observable } from "rxjs";
import { Action } from "@reduxjs/toolkit";
import { combineEpics } from "redux-observable";
import {
  alertErrorAction,
  alertSuccessAction,
  closeModal,
} from "../../../CommonAppRedux/CommonAppSlice";
import { getNext, getPrevious } from "../../../CommonAppRedux/api";
import {
  dispatchAction,
  stateAction,
} from "../../../../AppUtils/Utils/globalTypes";
import messages from "../../../../AppUtils/Utils/validationConstants";
export const controller = new AbortController();

//get Unit epic
const getUnitEpic = (
  action$: Observable<Action>,
  _: stateAction,
  { dispatch }: dispatchAction
) =>
  action$.pipe(
    filter(getUnitRequest.match),
    mergeMap(async (action: any) => {
      try {
        const response = await getUnit(action.payload);
        return { payload: response.data };
      } catch (e) {
        return { error: e };
      }
    }),
    map((action) =>
      action?.payload ? getUnitSuccess(action?.payload) : getUnitFail()
    )
  );

//get unit by id epic
const getUnitByIdEpic = (
  action$: Observable<Action>,
  _: stateAction,
  { dispatch }: dispatchAction
) =>
  action$.pipe(
    filter(getUnitByIdRequest.match),
    mergeMap(async (action) => {
      try {
        const response = await getUnitById(action.payload);
        return { payload: response.data };
      } catch (e) {
        return { error: e };
      }
    }),
    map((action) =>
      action?.payload ? unitEditSuccess(action.payload) : unitEditFail()
    )
  );

//create
const createUnitEpic = (
  action$: Observable<Action>,
  _: stateAction,
  { dispatch }: dispatchAction
) =>
  action$.pipe(
    filter(createUnitRequest.match),
    mergeMap(
      async ({ payload: { values, rowsPerPage, page, setShowTestUnit } }) => {
        try {
          const body = new FormData();
          for (let [key, value] of Object.entries(values)) {
            body.append(`${key}`, String(value));
          }
          const response = await createUnit(body);
          if (response) {
            dispatch(getUnitRequest({ rowsPerPage, page }));
            dispatch(alertSuccessAction(messages.createMessage));
            setShowTestUnit ? setShowTestUnit(false) : dispatch(closeModal());
          }
          return { payload: { response } };
        } catch (e) {
          dispatch(alertErrorAction(messages.createFailMessage));
          return { error: e };
        }
      }
    ),
    map((action) => {
      return action?.payload ? createUnitSuccess() : createUnitFail();
    })
  );

//update
const updateUnitEpic = (
  action$: Observable<Action>,
  _: stateAction,
  { dispatch }: dispatchAction
) =>
  action$.pipe(
    filter(updateUnitRequest.match),
    mergeMap(async ({ payload: { values, id, rowsPerPage, page, search } }) => {
      try {
        const body = new FormData();
        for (let [key, value] of Object.entries(values)) {
          body.append(`${key}`, String(value));
        }
        const response = await updateUnit(body, id);
        
        if (response) {
          dispatch(getUnitRequest({ rowsPerPage, page, search }));
          dispatch(alertSuccessAction(messages.updateMessage));
          dispatch(closeModal());
          dispatch(clearUnitData());
        }
        return { payload: { response, rowsPerPage } };
      } catch (e) {
        dispatch(alertErrorAction(messages.updateFailMessage));
        return { error: e };
      }
    }),
    map((action) => {
      return action?.payload ? updateUnitSuccess() : updateUnitFail();
    })
  );

//get next
const getUnitNext = (
  action$: Observable<Action>,
  _: stateAction,
  { dispatch }: dispatchAction
) =>
  action$.pipe(
    filter(getUnitNextRequest.match),
    mergeMap(async (action) => {
      try {
        const response = await getNext(action.payload);
        return { payload: response.data };
      } catch (e) {
        return { error: e };
      }
    }),
    map((action) => {
      return action?.payload ? getUnitSuccess(action?.payload) : getUnitFail();
    })
  );

//get previous
const getUnitPrevious = (
  action$: Observable<Action>,
  _: stateAction,
  { dispatch }: dispatchAction
) =>
  action$.pipe(
    filter(getUnitPreviousRequest.match),
    mergeMap(async (action) => {
      try {
        const response = await getPrevious(action.payload);
        return { payload: response.data };
      } catch (e) {
        return { error: e };
      }
    }),
    map((action) =>
      action?.payload ? getUnitSuccess(action?.payload) : getUnitFail()
    )
  );

export const unitEpic = combineEpics(
  getUnitEpic,
  createUnitEpic,
  updateUnitEpic,
  getUnitNext,
  getUnitPrevious,
  getUnitByIdEpic
);
