import { Grid } from "@mui/material";
import React, { Fragment, memo } from "react";
import { InputChangeEvent } from "../../AppUtils/Utils/globalTypes";
import AppAutoComplete from "../AppElements/AppAutoComplete/AppAutoComplete";
import AppTextField from "../AppElements/AppTextField/AppTextField";
import AppButton from "../AppElements/Button/AppButton";
import { columnProps } from "../../Pages/ViewDetails/CommonTable/types";
import { filterColumnProps } from "./types";

const FilterColumns = ({
  columns,
  filterField,
  setFilterField,
  handleFilterApiData,
}: filterColumnProps) => {
  const handleFilterColumns = (_: object, value: columnProps[]) => {
    setFilterField(value);
  };
  const handleFilterLabel = (option: any) => option.label;
  const handleFilterField = (
    { target: { value } }: InputChangeEvent,
    id: string
  ) => {
    setFilterField((prev) => {
      return prev?.map((changeFieldValue) => {
        return changeFieldValue?.id === id
          ? {
              ...changeFieldValue,
              filterFieldValue: value,
            }
          : changeFieldValue;
      });
    });
  };

  return (
    <>
      <Grid item xs={12} sx={{ p: 1 }}>
        <AppAutoComplete
          xs={12}
          options={columns}
          isNotFormik
          value={filterField}
          multiple
          getOptionLabel={handleFilterLabel}
          label="Filter Column"
          onChange={handleFilterColumns}
        />
      </Grid>
      <Grid item xs={12} mt={2}>
        {filterField?.map((filterField) => {
          const { id, label, filterFieldValue } = filterField;
          return (
            <Fragment key={id}>
              <Grid item xs={12} mt={2}>
                <AppTextField
                  xs={4}
                  name={label}
                  value={filterFieldValue ?? ""}
                  autoFocus
                  isNotFormik
                  label={label}
                  onChange={(e: InputChangeEvent) => handleFilterField(e, id)}
                />
              </Grid>
            </Fragment>
          );
        })}
      </Grid>
      <Grid item xs={12} mt={2}>
        <AppButton
          tooltipTitle="Apply"
          submit={false}
          onClick={handleFilterApiData}
        >
          Apply
        </AppButton>
      </Grid>
    </>
  );
};

export default memo(FilterColumns);
