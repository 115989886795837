import { createSlice } from "@reduxjs/toolkit";
import { moldTypeInitialState } from "./types";

const initialState: moldTypeInitialState = {
  moldTypes: [],
  edit: false,
  moldType: null,
  loadingMoldType: false,
  loadingCreateMoldType: false,
  count: 0,
  previous: "",
  next: "",
};

export const MoldType = createSlice({
  name: "moldTypeReducer",
  initialState,
  reducers: {
    getMoldTypeRequest: (state, payload) => {
      state.loadingMoldType = true;
    },
    getMoldTypeSuccess: (
      state,
      { payload: { results, count, next, previous } }
    ) => {
      state.loadingMoldType = false;
      state.moldTypes = results;
      state.count = count;
      state.next = next;
      state.previous = previous;
    },
    getMoldTypeFail: (state) => {
      state.loadingMoldType = false;
    },

    getMoldTypeNextRequest: (state, payload) => {
      state.loadingMoldType = true;
    },
    getMoldTypePreviousRequest: (state, payload) => {
      state.loadingMoldType = true;
    },
    createMoldTypeRequest: (state, payload) => {
      state.loadingCreateMoldType = true;
    },
    updateMoldTypeRequest: (state, payload) => {
      state.loadingCreateMoldType = true;
    },
    createMoldTypeSuccess: (state) => {
      state.loadingCreateMoldType = false;
    },
    createMoldTypeFail: (state) => {
      state.loadingCreateMoldType = false;
    },
    getMoldTypeByIdRequest: (state, payload) => {
      state.edit = true;
    },
    moldTypeEditSuccess: (state, { payload }) => {
      state.moldType = payload;
    },
    moldTypeEditFail: (state) => {
      state.edit = false;
    },
    updateMoldTypeSuccess: (state) => {
      state.loadingCreateMoldType = false;
    },
    updateMoldTypeFail: (state) => {
      state.loadingCreateMoldType = false;
    },
    clearAllData: (state) => {
      state.edit = false;
      state.moldType = null;
    },
  },
});

//action creaters generated for each case reducer
export const {
  getMoldTypeRequest,
  getMoldTypeFail,
  getMoldTypeSuccess,
  getMoldTypeNextRequest,
  getMoldTypePreviousRequest,
  createMoldTypeFail,
  createMoldTypeRequest,
  createMoldTypeSuccess,
  moldTypeEditSuccess,
  updateMoldTypeFail,
  updateMoldTypeSuccess,
  updateMoldTypeRequest,
  clearAllData,
  getMoldTypeByIdRequest,
  moldTypeEditFail,
} = MoldType.actions;

export default MoldType.reducer;
