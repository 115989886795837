import { axiosInstance } from "../../../../AppUtils/Utils/axios";
import { filterApiData } from "../../../../AppUtils/Utils/appFunctions";
import {
  getApiProps,
  searchProps,
} from "../../../../AppUtils/Utils/globalTypes";
export const reportFooterURL = "api/v1/lab-app/report-footer";
//get ReportFooter data api
export const getReportFooter = (data: getApiProps) => {
  const { rowsPerPage, page } = data;
  return axiosInstance.get(
    `${reportFooterURL}?offset=${rowsPerPage * page}&limit=${rowsPerPage}${
      data?.filterData ? filterApiData(data?.filterData) : ""
    }`
  );
};
export const getReportFooterDoctor = ({ rowsPerPage }: any) => {
  return axiosInstance.get(
    `${reportFooterURL}/doctor?offset=0&limit=${rowsPerPage}`
  );
};
//handle search
export const searchReportFooter = ({ rowsPerPage, search }: searchProps) => {
  return `${reportFooterURL}?offset=0&limit=${rowsPerPage}&search=${search}`;
};
//create ReportFooter
export const createReportFooter = (body: string | FormData) =>
  axiosInstance.post(`${reportFooterURL}`, body);
//update ReportFooter
export const updateReportFooter = (body: string | FormData, id: number) =>
  axiosInstance.patch(`${reportFooterURL}/${id}`, body);
// get  particular report footer data
export const getSpecificReportFooter = (id: number) => {
  return axiosInstance.get(`${reportFooterURL}/${id}`);
};
