import {
  getDepositRequest,
  getDepositFail,
  getDepositSuccess,
  createDepositFail,
  createDepositRequest,
  createDepositSuccess,
  updateDepositFail,
  updateDepositSuccess,
  clearAllData,
  getDepositNextRequest,
  getDepositPreviousRequest,
  updateDepositRequest,
  getDepositByIdRequest,
  depositEditSuccess,
  depositEditFail,
  getDepositCustomerRequest,
  getDepositCustomerSuccess,
  getDepositCustomerFail,
  getDepositOrderRequest,
  getDepositOrderSuccess,
  getDepositOrderFail,
} from "./depositSlice";
import { mergeMap } from "rxjs";
import {
  getDeposit,
  createDeposit,
  updateDeposit,
  getDepositById,
  getDepositCustomer,
  getDepositOrder,
} from "./api";
import { map, filter } from "rxjs/operators";
import { Observable } from "rxjs";
import { Action } from "@reduxjs/toolkit";
import { combineEpics } from "redux-observable";
import {
  alertErrorAction,
  alertSuccessAction,
  closeModal,
} from "../../../CommonAppRedux/CommonAppSlice";
import { getNext, getPrevious } from "../../../CommonAppRedux/api";
import {
  dispatchAction,
  stateAction,
} from "../../../../AppUtils/Utils/globalTypes";
import messages from "../../../../AppUtils/Utils/validationConstants";
export const controller = new AbortController();

//get Deposit
const getDepositEpic = (
  action$: Observable<Action>,
  _: stateAction,
  { dispatch }: dispatchAction
) =>
  action$.pipe(
    filter(getDepositRequest.match),
    mergeMap(async (action: any) => {
      try {
        const response = await getDeposit(action.payload);
        return { payload: response.data };
      } catch (e) {
        return { error: e };
      }
    }),
    map((action) =>
      action?.payload ? getDepositSuccess(action?.payload) : getDepositFail()
    )
  );

//get deposit by id epic
const getDepositByIdEpic = (
  action$: Observable<Action>,
  _: stateAction,
  { dispatch }: dispatchAction
) =>
  action$.pipe(
    filter(getDepositByIdRequest.match),
    mergeMap(async (action) => {
      try {
        const response = await getDepositById(action.payload);
        return { payload: response.data };
      } catch (e) {
        return { error: e };
      }
    }),
    map((action) =>
      action?.payload ? depositEditSuccess(action.payload) : depositEditFail()
    )
  );

//create
const createDepositEpic = (
  action$: Observable<Action>,
  _: stateAction,
  { dispatch }: dispatchAction
) =>
  action$.pipe(
    filter(createDepositRequest.match),
    mergeMap(async ({ payload: { values, rowsPerPage, page } }) => {
      try {
        const body = new FormData();
        for (let [key, value] of Object.entries(values)) {
          body.append(`${key}`, String(value));
        }
        const response = await createDeposit(body);
        if (response) {
          dispatch(getDepositRequest({ rowsPerPage, page }));
          dispatch(alertSuccessAction(messages.createMessage));
          dispatch(closeModal());
        }
        return { payload: { response } };
      } catch (e) {
        dispatch(alertErrorAction(messages.createFailMessage));
        return { error: e };
      }
    }),
    map((action) => {
      return action?.payload ? createDepositSuccess() : createDepositFail();
    })
  );

//update
const updateDepositEpic = (
  action$: Observable<Action>,
  _: stateAction,
  { dispatch }: dispatchAction
) =>
  action$.pipe(
    filter(updateDepositRequest.match),
    mergeMap(async ({ payload: { values, id, rowsPerPage, page, search } }) => {
      try {
        const body = new FormData();
        for (let [key, value] of Object.entries(values)) {
          body.append(`${key}`, String(value));
        }
        const response = await updateDeposit(body, id);
        if (response) {
          dispatch(getDepositRequest({ rowsPerPage, page, search }));
          dispatch(alertSuccessAction(messages.updateMessage));
          dispatch(closeModal());
          dispatch(clearAllData());
        }
        return { payload: { response, rowsPerPage } };
      } catch (e) {
        dispatch(alertErrorAction(messages.updateFailMessage));
        return { error: e };
      }
    }),
    map((action) => {
      return action?.payload ? updateDepositSuccess() : updateDepositFail();
    })
  );

//get next
const getDepositNext = (
  action$: Observable<Action>,
  _: stateAction,
  { dispatch }: dispatchAction
) =>
  action$.pipe(
    filter(getDepositNextRequest.match),
    mergeMap(async (action) => {
      try {
        const response = await getNext(action.payload);
        return { payload: response.data };
      } catch (e) {
        return { error: e };
      }
    }),
    map((action) => {
      return action?.payload
        ? getDepositSuccess(action?.payload)
        : getDepositFail();
    })
  );

//get previous
const getDepositPrevious = (
  action$: Observable<Action>,
  _: stateAction,
  { dispatch }: dispatchAction
) =>
  action$.pipe(
    filter(getDepositPreviousRequest.match),
    mergeMap(async (action) => {
      try {
        const response = await getPrevious(action?.payload);
        return { payload: response.data };
      } catch (e) {
        return { error: e };
      }
    }),
    map((action) =>
      action?.payload ? getDepositSuccess(action?.payload) : getDepositFail()
    )
  );

//customer
const getDepositCustomerEpic = (
  action$: Observable<Action>,
  _: stateAction,
  { dispatch }: dispatchAction
) =>
  action$.pipe(
    filter(getDepositCustomerRequest.match),
    mergeMap(async (action: any) => {
      try {
        const response = await getDepositCustomer(action.payload);
        return { payload: response.data };
      } catch (e) {
        return { error: e };
      }
    }),
    map((action) =>
      action?.payload
        ? getDepositCustomerSuccess(action?.payload)
        : getDepositCustomerFail()
    )
  );

//order
const getDepositOrderEpic = (
  action$: Observable<Action>,
  _: stateAction,
  { dispatch }: dispatchAction
) =>
  action$.pipe(
    filter(getDepositOrderRequest.match),
    mergeMap(async ({ payload: { type, customer, defaultRowsPerPage } }) => {
      try {
        const response = await getDepositOrder(type, customer, defaultRowsPerPage);
        return { payload: response.data };
      } catch (e) {
        return { error: e };
      }
    }),
    map((action) =>
      action?.payload
        ? getDepositOrderSuccess(action?.payload)
        : getDepositOrderFail()
    )
  );

export const depositEpics = combineEpics(
  getDepositEpic,
  createDepositEpic,
  updateDepositEpic,
  getDepositNext,
  getDepositPrevious,
  getDepositByIdEpic,
  getDepositCustomerEpic,
  getDepositOrderEpic
);
