import { filterApiData } from "../../../../AppUtils/Utils/appFunctions";
import { axiosInstance } from "../../../../AppUtils/Utils/axios";

export const repairOrderModURL = "/api/v1/appointment-app/device-repair";

//get RepairOrder data api
export const getRepairOrder = (data: any) => {
  const { rowsPerPage, page, isDispatched } = data;
  return axiosInstance.get(
    `${repairOrderModURL}?offset=${rowsPerPage * page}&limit=${rowsPerPage}${
      data?.filterData ? filterApiData(data?.filterData) : ""
    }${isDispatched !== undefined ? `&is_dispatched=${isDispatched}` : ""}`
  );
};

//handle search
export const searchRepairOrder = ({ id, rowsPerPage, search }: any) => {
  return `${repairOrderModURL}?offset=0&limit=${rowsPerPage}&search=${search}`;
};
//create RepairOrder
export const createRepairOrder = (body: string | FormData) =>
  axiosInstance.post(`${repairOrderModURL}`, body);

//update RepairOrder
export const updateRepairOrder = (body: string | FormData, id: number) =>
  axiosInstance.patch(`${repairOrderModURL}/${id}`, body);
//
export const getRepairOrderById = (id: number) => {
  return axiosInstance.get(`${repairOrderModURL}/${id}`);
};

//
export const getRepairOrderItem = ({ defaultRowsPerPage }: any) => {
  return axiosInstance.get(
    `${repairOrderModURL}/item?offset=0&limit=${defaultRowsPerPage}`
  );
};

//update received status
export const updateRepairReceivedStatus = (body: string, id: number) => {
  return axiosInstance.patch(`${repairOrderModURL}/${id}/receive`, body);
};

//update dispatched status
export const updateRepairDispatchedStatus = (body: string, id: number) => {
  return axiosInstance.patch(`${repairOrderModURL}/${id}/dispatch`, body);
};

export const getRepairOrderCustomer = (rowsPerPage: number) =>
  axiosInstance.get(
    `api/v1/appointment-app/customer?offset=0&limit=${rowsPerPage}`
  );

//get RepairOrder specialist data api
export const getRepairOrderSpecialist = (data: any) => {
  const { rowsPerPage, page } = data;
  return axiosInstance.get(
    `${repairOrderModURL}/device-repair-specialist?offset=${
      rowsPerPage * page
    }&limit=${rowsPerPage}${
      data?.filterData ? filterApiData(data?.filterData) : ""
    }`
  );
};

//update device order specialist
export const updateDeviceOrderSpecialist = (body: string, id: number) => {
  return axiosInstance.patch(`${repairOrderModURL}/${id}/repaired`, body);
};
// cancel repair order
export const cancelRepairOrder = (id: number) => {
  return axiosInstance.patch(`${repairOrderModURL}/${id}/`);
};
