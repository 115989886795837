import { axiosInstance } from "../../../../AppUtils/Utils/axios";
import { filterApiData } from "../../../../AppUtils/Utils/appFunctions";
import {
  getApiProps,
  searchProps,
} from "../../../../AppUtils/Utils/globalTypes";

const testSensitiveMedicineModURL = "/api/v1/lab-app/test-sensitive-medicine";

export const getTestSensitiveMedicine = (data: getApiProps) => {
  const { rowsPerPage, page } = data;
  return axiosInstance.get(
    `${testSensitiveMedicineModURL}?offset=${
      rowsPerPage * page
    }&limit=${rowsPerPage}${
      data?.filterData ? filterApiData(data?.filterData) : ""
    }`
  );
};
export const searchTestSensitiveMedicine = ({
  rowsPerPage,
  search,
}: searchProps) => {
  return `${testSensitiveMedicineModURL}?offset=0&limit=${rowsPerPage}&search=${search}`;
};
export const createTestSensitiveMedicine = (body: string | FormData) =>
  axiosInstance.post(`${testSensitiveMedicineModURL}`, body);
//update TestSensitiveMedicine
export const updateTestSensitiveMedicine = (
  body: string | FormData,
  id: number
) => axiosInstance.patch(`${testSensitiveMedicineModURL}/${id}`, body);

export const getAllTestSensitiveMedicineForDefault = () =>
  axiosInstance.get<any>(`${testSensitiveMedicineModURL}`);

export const getAllTest = (rowsPerPage: number) =>
  axiosInstance.get(
    `${testSensitiveMedicineModURL}/test?offset=0&limit=${rowsPerPage}`
  );

export const getSensitiveMedicineTest = (rowsPerPage: number) =>
  axiosInstance.get(
    `${testSensitiveMedicineModURL}/sensitive-medicine?offset=0&limit=${rowsPerPage}`
  );

//get test sensitive medicine by id
export const getTestSensitiveMedicineById = (id: number) => {
  return axiosInstance.get(`${testSensitiveMedicineModURL}/${id}`);
}