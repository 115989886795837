import { axiosInstance } from "../../../../AppUtils/Utils/axios";
import {
  searchProps,
  getApiProps,
} from "../../../../AppUtils/Utils/globalTypes";
import { filterApiData } from "../../../../AppUtils/Utils/appFunctions";

const referralTypeModURL = `api/v1/appointment-app/referral-type`;

//get referralType
export const getReferralType = (data: getApiProps) => {
  const { rowsPerPage, page, search } = data;
  return axiosInstance.get(
    `${referralTypeModURL}?offset=${
      rowsPerPage * page
    }&limit=${rowsPerPage}&search=${search ?? ""}${
      data?.filterData ? filterApiData(data?.filterData) : ""
    }`
  );
};
//get referralType by id
export const getReferralTypeById = (id: number) => {
  return axiosInstance.get(`${referralTypeModURL}/${id}`);
};
//search referralType
export const searchReferralType = ({ rowsPerPage, search }: searchProps) => {
  return `${referralTypeModURL}?offset=0&limit=${rowsPerPage}&search=${
    search ?? ""
  }`;
};
//update referralType
export const updateReferralType = (body: string | FormData, id: number) =>
  axiosInstance.patch(`${referralTypeModURL}/${id}`, body);
//create referralType
export const createReferralType = (body: string | FormData) =>
  axiosInstance.post(`${referralTypeModURL}`, body);
