import {
  updateReferralEditFail,
  updateReferralEditSuccess,
  getReferralEditRequest,
  updateReferralEditRequest,
  clearReferralEditData,
  getAllCustomerRequest,
  getCustomerSuccess,
  getCustomerFail,
  getSaleNoSuccess,
  getSaleNoFail,
  getSaleNoRequest,
  getAllReferralRequest,
  getReferralSuccess,
  getReferralFail,
} from "./referralEditSlice";
import { mergeMap } from "rxjs";
import {
  updateReferralEdit,
  getAllReferralEditCustomer,
  getSaleNo,
  getAllReferralEditReferral,
} from "./api";
import { map, filter } from "rxjs/operators";
import { Observable } from "rxjs";
import { Action } from "@reduxjs/toolkit";
import { combineEpics } from "redux-observable";
import {
  alertErrorAction,
  alertSuccessAction,
  closeModal,
} from "../../../CommonAppRedux/CommonAppSlice";
import { getNext, getPrevious } from "../../../CommonAppRedux/api";
import {
  dispatchAction,
  stateAction,
} from "../../../../AppUtils/Utils/globalTypes";
import messages from "../../../../AppUtils/Utils/validationConstants";
export const controller = new AbortController();
// import { searchData } from "../../../CommonAppRedux/commonAppFunctions";

const getReferralEditCustomerEpic = (
  action$: Observable<Action>,
  _: stateAction,
  { dispatch }: dispatchAction
) =>
  action$.pipe(
    filter(getAllCustomerRequest.match),
    mergeMap(async (action) => {
      try {
        const response = await getAllReferralEditCustomer(action?.payload);
        return { payload: response.data };
      } catch (e) {
        return { error: e };
      }
    }),
    map((action) =>
      action?.payload ? getCustomerSuccess(action?.payload) : getCustomerFail()
    )
  );

const getReferralEditSaleNoEpic = (
  action$: Observable<Action>,
  _: stateAction,
  { dispatch }: dispatchAction
) =>
  action$.pipe(
    filter(getSaleNoRequest.match),
    mergeMap(async (action) => {
      try {
        const response = await getSaleNo(action?.payload);
        return { payload: response.data };
      } catch (e) {
        return { error: e };
      }
    }),
    map((action) =>
      action?.payload ? getSaleNoSuccess(action?.payload) : getSaleNoFail()
    )
  );

const getReferralEditReferralEpic = (
  action$: Observable<Action>,
  _: stateAction,
  { dispatch }: dispatchAction
) =>
  action$.pipe(
    filter(getAllReferralRequest.match),
    mergeMap(async (action) => {
      try {
        const response = await getAllReferralEditReferral(action?.payload);
        return { payload: response.data };
      } catch (e) {
        return { error: e };
      }
    }),
    map((action) =>
      action?.payload ? getReferralSuccess(action?.payload) : getReferralFail()
    )
  );

const updateReferralEditEpic = (
  action$: Observable<Action>,
  _: stateAction,
  { dispatch }: dispatchAction
) =>
  action$.pipe(
    filter(updateReferralEditRequest.match),
    mergeMap(async ({ payload: { values, id, rowsPerPage, page } }) => {
      try {
        const body = JSON.stringify(values);
        const response = await updateReferralEdit(body, id);
        if (response) {
          dispatch(getReferralEditRequest({ rowsPerPage, page }));
          dispatch(alertSuccessAction(messages.updateMessage));
          dispatch(closeModal());
          dispatch(clearReferralEditData());
          window.location.reload();
        }
        return { payload: { response, rowsPerPage } };
      } catch (e) {
        dispatch(alertErrorAction(messages.updateFailMessage));
        return { error: e };
      }
    }),
    map((action) =>
      action?.payload ? updateReferralEditSuccess() : updateReferralEditFail()
    )
  );
export const referralEditEpics = combineEpics(
  // getReferralEditEpic,
  // createReferralEditEpic,
  updateReferralEditEpic,
  getReferralEditCustomerEpic,
  getReferralEditSaleNoEpic,
  getReferralEditReferralEpic
);
