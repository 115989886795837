import {
  createSupplierFail,
  createSupplierRequest,
  createSupplierSuccess,
  getSupplierSuccess,
  getSupplierFail,
  loadingSupplier,
  updateSupplierFail,
  updateSupplierSuccess,
  getSupplierRequest,
  updateSupplierRequest,
  getSupplierNextRequest,
  getSupplierPreviousRequest,
  clearSupplierData,
  getSupplierTaxGroupRequest,
  getSupplierTaxGroupSuccess,
  getSupplierTaxGroupFail,
  supplierEditSuccess,
  supplierEditFail,
  getSupplierByIdRequest,
} from "./supplierSlice";
import { mergeMap } from "rxjs";
import {
  createSupplier,
  getSupplier,
  getSupplierById,
  getSupplierTaxGroup,
  updateSupplier,
} from "./api";
import { map, filter, tap } from "rxjs/operators";
import { Observable } from "rxjs";
import { Action } from "@reduxjs/toolkit";
import { combineEpics } from "redux-observable";
import {
  alertErrorAction,
  alertSuccessAction,
  closeModal,
} from "../../../CommonAppRedux/CommonAppSlice";
import { getNext, getPrevious } from "../../../CommonAppRedux/api";
import {
  dispatchAction,
  stateAction,
} from "../../../../AppUtils/Utils/globalTypes";
import messages from "../../../../AppUtils/Utils/validationConstants";

export const controller = new AbortController();

// get manufacture epic
const getSupplierEpic = (
  action$: Observable<Action>,
  _: stateAction,
  { dispatch }: dispatchAction
) =>
  action$.pipe(
    filter(getSupplierRequest.match),
    mergeMap(async (action) => {
      dispatch(loadingSupplier());
      try {
        const response = await getSupplier(action.payload);
        return { payload: response.data };
      } catch (e) {
        return { error: e };
      }
    }),
    map((action) =>
      action?.payload ? getSupplierSuccess(action?.payload) : getSupplierFail()
    )
  );

//get next
const getSupplierNext = (
  action$: Observable<Action>,
  _: stateAction,
  { dispatch }: dispatchAction
) =>
  action$.pipe(
    filter(getSupplierNextRequest.match),
    mergeMap(async (action) => {
      dispatch(loadingSupplier());
      try {
        const response = await getNext(action.payload);
        return { payload: response.data };
      } catch (e) {
        return { error: e };
      }
    }),
    map((action) =>
      action?.payload ? getSupplierSuccess(action?.payload) : getSupplierFail()
    )
  );

//get previous
const getSupplierPrevious = (
  action$: Observable<Action>,
  _: stateAction,
  { dispatch }: dispatchAction
) =>
  action$.pipe(
    filter(getSupplierPreviousRequest.match),
    mergeMap(async (action) => {
      dispatch(loadingSupplier());
      try {
        const response = await getPrevious(action.payload);
        return { payload: response.data };
      } catch (e) {
        return { error: e };
      }
    }),
    map((action) =>
      action?.payload ? getSupplierSuccess(action?.payload) : getSupplierFail()
    )
  );
//create Supplier epic
const createSupplierEpic = (
  action$: Observable<Action>,
  _: stateAction,
  { dispatch }: dispatchAction
) =>
  action$.pipe(
    filter(createSupplierRequest.match),
    mergeMap(
      async ({ payload: { values, rowsPerPage, page, setShowSupplier } }) => {
        try {
          const body = new FormData();
          for (let [key, value] of Object.entries(values)) {
            // @ts-ignore
            body.append(`${key}`, value);
          }
          const response = await createSupplier(body);
          if (response) {
            !setShowSupplier &&
              dispatch(getSupplierRequest({ rowsPerPage, page }));
            dispatch(alertSuccessAction(messages.createMessage));
            setShowSupplier ? setShowSupplier(false) : dispatch(closeModal());
          }
          return { payload: { response } };
        } catch (e) {
          dispatch(alertErrorAction(messages.createFailMessage));
          return { error: e };
        }
      }
    ),
    map((action) => {
      return action?.payload
        ? createSupplierSuccess(action.payload)
        : createSupplierFail();
    })
  );

//update Supplier epic
const updateSupplierEpic = (
  action$: Observable<Action>,
  _: stateAction,
  { dispatch }: dispatchAction
) =>
  action$.pipe(
    filter(updateSupplierRequest.match),
    mergeMap(async ({ payload: { values, id, rowsPerPage, page } }) => {
      try {
        const body = new FormData();
        for (let [key, value] of Object.entries(values)) {
          // @ts-ignore
          body.append(`${key}`, value);
        }
        const response = await updateSupplier(body, id);
        if (response) {
          dispatch(getSupplierRequest({ rowsPerPage, page }));
          dispatch(alertSuccessAction(messages.updateMessage));
          dispatch(clearSupplierData());
          dispatch(closeModal());
        }
        return { payload: { response, rowsPerPage } };
      } catch (e) {
        dispatch(alertErrorAction(messages.updateFailMessage));
        return { error: e };
      }
    }),
    map((action) =>
      action?.payload ? updateSupplierSuccess() : updateSupplierFail()
    )
  );
// get tax group
const getSupplierTaxGroupEpic = (
  action$: Observable<Action>,
  _: stateAction,
  { dispatch }: dispatchAction
) =>
  action$.pipe(
    filter(getSupplierTaxGroupRequest.match),
    mergeMap(async (action) => {
      try {
        const response = await getSupplierTaxGroup();
        return { payload: response.data };
      } catch (e) {
        return { error: e };
      }
    }),
    map((action) =>
      action?.payload
        ? getSupplierTaxGroupSuccess(action?.payload)
        : getSupplierTaxGroupFail()
    )
  );
//
const getSupplierByIdEpic = (
  action$: Observable<Action>,
  _: stateAction,
  { dispatch }: dispatchAction
) =>
  action$.pipe(
    filter(getSupplierByIdRequest.match),
    mergeMap(async (action) => {
      try {
        const response = await getSupplierById(action.payload);
        return { payload: response.data };
      } catch (e) {
        return { error: e };
      }
    }),
    map((action) =>
      action?.payload
        ? supplierEditSuccess(action.payload)
        : supplierEditFail()
    )
  );
export const supplierEpics = combineEpics(
  getSupplierEpic,
  createSupplierEpic,
  updateSupplierEpic,
  getSupplierNext,
  getSupplierPrevious,
  getSupplierTaxGroupEpic,
  getSupplierByIdEpic
);
