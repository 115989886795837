import { axiosInstance } from "../../../../AppUtils/Utils/axios";
import { defaultRowsPerPage } from "../../../../AppUtils/Utils/globalConstant";
import {
  getApiProps,
  searchProps,
} from "../../../../AppUtils/Utils/globalTypes";
import { clinicSetup } from "../../ClinicSetup/Redux/types";
import { doctor } from "../../Doctor/Redux/types";
import { siteSetup } from "../../SiteSetup/Redux/types";

export const baseUrl = "api/v1/appointment-app/appointment";
//get Appointment data api
export const getAppointment = ({ dateRange }: any) => {
  const { start, end } = dateRange;
  return axiosInstance.get(
    `${baseUrl}?start=${new Date(start).toISOString()}&end=${new Date(
      end
    ).toISOString()}&offset=0&limit=0`
  );
};

export const getFilteredAppointmentById = (id: number) => {
  return axiosInstance.get(`${baseUrl}?customer=${id}`);
};

//handle search
export const searchAppointment = ({ search, dateRange }: any) => {
  const { start, end } = dateRange;
  return `${baseUrl}?start=${new Date(start).toISOString()}&end=${new Date(
    end
  ).toISOString()}&offset=0&limit=0&search=${search}`;
};
// get clinic
export const getAppointmentClinic = () =>
  axiosInstance.get(`${baseUrl}/clinic?offset=0&limit=20`);
// get doctor
export const getAppointmentDoctor = ({ rowsPerPage }: any) =>
  axiosInstance.get(`${baseUrl}/doctor?offset=0&limit=${rowsPerPage}`);
// get title
export const getAppointmentTitle = () =>
  axiosInstance.get(`${baseUrl}/title?offset=0&limit=0`);
// get site
export const getAppointmentSite = () =>
  axiosInstance.get(`${baseUrl}/site?offset=0&limit=20`);
// get appointmentType
export const getAppointmentAppointmentType = () =>
  axiosInstance.get(`${baseUrl}/appointment-type?offset=0&limit=20`);
// get referralType
export const getAppointmentReferralType = () =>
  axiosInstance.get(`${baseUrl}/referral-type?offset=0&limit=20`);
// get appointment customer
export const getAppointmentCustomer = ({ rowsPerPage }: any) =>
  axiosInstance.get(`${baseUrl}/customer?offset=0&limit=${rowsPerPage}`);
//referrer
export const getAppointmentReferrer = () =>
  axiosInstance.get(`${baseUrl}/referrer?offset=0&limit=${defaultRowsPerPage}`);
// get appointment from site
export const getAppointmentFromSite = ({ dateRange, site }: any) => {
  const { start, end } = dateRange;
  let siteUrl = "";
  if (site?.length > 0) {
    for (let x in site) {
      siteUrl = siteUrl + `&site=${site[x].id}`;
    }
  } else {
    siteUrl = "";
  }
  return axiosInstance.get(
    `${baseUrl}?start=${new Date(start).toISOString()}&end=${new Date(
      end
    ).toISOString()}&offset=0&limit=0${siteUrl}`
  );
};
// get appointment from doctor
export const getAppointmentFromDoctor = ({ dateRange, doctor }: any) => {
  const { start, end } = dateRange;
  let doctorUrl = "";
  if (doctor?.length > 0) {
    for (let x in doctor) {
      doctorUrl = doctorUrl + `&doctor=${doctor[x].id}`;
    }
  } else {
    doctorUrl = "";
  }
  return axiosInstance.get(
    `${baseUrl}?start=${new Date(start).toISOString()}&end=${new Date(
      end
    ).toISOString()}&offset=0&limit=0${doctorUrl}`
  );
};
// get appointment from clinic
export const getAppointmentFromClinic = ({ dateRange, clinic }: any) => {
  const { start, end } = dateRange;
  let clinicUrl = "";
  if (clinic?.length > 0) {
    for (let x in clinic) {
      clinicUrl = clinicUrl + `&clinic=${clinic[x].id}`;
    }
  } else {
    clinicUrl = "";
  }
  return axiosInstance.get(
    `${baseUrl}?start=${new Date(start).toISOString()}&end=${new Date(
      end
    ).toISOString()}&offset=0&limit=0${clinicUrl}`
  );
};

// get appointment from doctor
export const getAppointmentFromStatus = ({ dateRange, status }: any) => {
  const { start, end } = dateRange;
  let statusUrl = "";
  if (status?.length > 0) {
    for (let x in status) {
      statusUrl = statusUrl + `&status=${status[x].id}`;
    }
  } else {
    statusUrl = "";
  }

  return axiosInstance.get(
    `${baseUrl}?start=${new Date(start).toISOString()}&end=${new Date(
      end
    ).toISOString()}&offset=0&limit=0${statusUrl}`
  );
};

// get appointment from customer number
export const getAppointmentFromCustomerNo = ({
  dateRange,
  customerNo,
}: any) => {
  const { start, end } = dateRange;
  return axiosInstance.get(
    `${baseUrl}?start=${new Date(start).toISOString()}&end=${new Date(
      end
    ).toISOString()}&offset=0&limit=0&customer_no_full=${customerNo}`
  );
};
// for appointment form
export const getClinicFromSite = (site: number) =>
  axiosInstance.get(`${baseUrl}/clinic?offset=0&limit=0&site=${site}`);
export const getDoctorFromClinic = (clinic: number) =>
  axiosInstance.get(`${baseUrl}/doctor?offset=0&limit=0&clinic=${clinic}`);

// for filter fields
export const getFilterClinicFromSite = (site: siteSetup[]) => {
  let siteUrl = "";
  if (site?.length > 0) {
    for (let x in site) {
      siteUrl = siteUrl + `&site=${site[x].id}`;
    }
  } else {
    siteUrl = "";
  }
  return axiosInstance.get(`${baseUrl}/clinic?offset=0&limit=0${siteUrl}`);
};

export const getFilterDoctorFromClinic = (clinic: clinicSetup[]) => {
  let clinicUrl = "";
  if (clinic?.length > 0) {
    for (let x in clinic) {
      clinicUrl = clinicUrl + `&clinic=${clinic[x].id}`;
    }
  } else {
    clinicUrl = "";
  }
  return axiosInstance.get(`${baseUrl}/doctor?offset=0&limit=0${clinicUrl}`);
};

//create Appointment
export const createAppointment = (body: string | FormData) =>
  axiosInstance.post(`${baseUrl}`, body);
// create appointment via referral
export const createAppointmentViaReferral = (body: string | FormData) =>
  axiosInstance.post(`${baseUrl}/appointment-via-referral`, body);
//update Appointment
export const updateAppointment = (body: string | FormData, id: number) =>
  axiosInstance.patch(`${baseUrl}/${id}`, body);

// check if appointment already exists
export const checkAppointment = ({ site, clinic, doctor, start, end }: any) => {
  return axiosInstance.get(
    `${baseUrl}?site=${site}&clinic=${clinic}&doctor=${doctor}&start=${start}&end=${end}`
  );
};

// get clinic wise appointment routine
export const getClinicAppointmentRoutine = ({ clinic }: any) => {
  return axiosInstance.get(`${baseUrl}/appointment-routine?clinic=${clinic}`);
};

// get pending referral list
export const getPendingReferralList = ({ clinic, appointmentId }: any) => {
  return axiosInstance.get(
    `${baseUrl}/pending-list?clinic=${clinic ? clinic : ""}&appointment_type=${
      appointmentId ? appointmentId : ""
    }`
  );
};

// get appointment province
export const getAppointmentProvince = () =>
  axiosInstance.get(`${baseUrl}/province?offset=0&limit=${defaultRowsPerPage}`);

// get appointment district
export const getAppointmentDistrict = (id?: number) =>
  axiosInstance.get(
    `${baseUrl}/district?offset=0&limit=${defaultRowsPerPage}&province=${
      id ? id : ""
    }`
  );

export const getAllAppointmentDistrict = () =>
  axiosInstance.get(`${baseUrl}/district?offset=0`);

//get all appointment type filters
export const getAppointmentFilter = () => {
  return axiosInstance.get(
    `${baseUrl}/pending-list/appointment-type?offset=0&limit=${defaultRowsPerPage}`
  );
};

//start the appointment
export const startAppointment = (id: number, body: string | FormData) => {
  return axiosInstance.patch(`${baseUrl}/${id}/start`, body);
};

export const stopAppointment = (id: number, body: string | FormData) => {
  return axiosInstance.patch(`${baseUrl}/${id}/stop`, body);
};


