import { createSlice, createAction } from "@reduxjs/toolkit";
import { footerNote } from "./types";
const initialState: any = {
  footerNotes: [],
  loadingFooterNote: false,
  loadingCreateFooterNote: false,
  edit: false,
  footerNote: null,
  tests: [],
  loadingTest: false,
  count: 0,
  previous: "",
  next: "",
};

export const FooterNote = createSlice({
  name: "FooterNoteReducer",
  initialState,
  reducers: {
    loadingFooterNote: (state) => {
      state.loadingFooterNote = true;
    },
    getFooterNoteRequest: (state, payload) => {
      state.loadingFooterNote = true;
    },
    getFooterNoteSuccess: (
      state,
      { payload: { results, count, next, previous } }
    ) => {
      state.loadingFooterNote = false;
      state.footerNotes = results;
      state.count = count;
      state.next = next;
      state.previous = previous;
    },
    getFooterNoteFail: (state) => {
      state.loadingFooterNote = false;
    },
    getFooterNoteNextRequest: (state, payload) => {
      state.loadingFooterNote = true;
    },
    getFooterNotePreviousRequest: (state, payload) => {
      state.loadingFooterNote = true;
    },
    loadingFooterNoteTestReq: (state) => {
      state.loadingTest = true;
    },
    getFooterNoteTestRequest: (state, payload) => {
      state.loadingTest = true;
    },
    getFooterNoteTestSuccess: (state, { payload: { results } }) => {
      state.tests = results;
      state.loadingTest = false;
    },
    getFooterNoteTestFail: (state) => {
      state.loadingTest = false;
    },
    createFooterNoteRequest: (state, payload) => {
      state.loadingCreateFooterNote = true;
      state.closeModal = true;
    },
    createFooterNoteSuccess: (state) => {
      state.loadingCreateFooterNote = false;
    },
    createFooterNoteFail: (state) => {
      state.loadingCreateFooterNote = false;
    },
    //
    getFooterNoteByIdRequest: (state, payload) => {
      state.edit = true;
    },
    footerNoteEditSuccess: (state, { payload }) => {
      state.footerNote = payload;
    },
    footerNoteEditFail: (state) => {
      state.edit = false;
    },
    //
    updateFooterNoteRequest: (state, payload) => {
      state.loadingCreateFooterNote = true;
    },
    updateFooterNoteSuccess: (state) => {
      state.loadingCreateFooterNote = false;
    },
    updateFooterNoteFail: (state) => {
      state.loadingCreateFooterNote = false;
    },
    clearFooterNoteData: (state) => {
      state.edit = false;
      state.footerNote = null;
    },
  },
});
// Action creators are generated for each case reducer function
export const {
  loadingFooterNote,
  loadingFooterNoteTestReq,
  getFooterNoteRequest,
  getFooterNoteSuccess,
  getFooterNoteFail,
  getFooterNoteNextRequest,
  getFooterNotePreviousRequest,
  getFooterNoteTestRequest,
  getFooterNoteTestSuccess,
  getFooterNoteTestFail,
  createFooterNoteRequest,
  createFooterNoteSuccess,
  createFooterNoteFail,
  //
  getFooterNoteByIdRequest,
  footerNoteEditSuccess,
  footerNoteEditFail,
  //
  updateFooterNoteRequest,
  updateFooterNoteSuccess,
  updateFooterNoteFail,
  clearFooterNoteData,
} = FooterNote.actions;
export default FooterNote.reducer;
