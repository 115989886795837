import { createSlice } from "@reduxjs/toolkit";
import { appointmentTypeInitialState } from "./types";

const initialState: appointmentTypeInitialState = {
  appointmentTypes: [],
  edit: false,
  appointmentType: null,
  loadingAppointmentType: false,
  loadingCreateAppointmentType: false,
  count: 0,
  previous: "",
  next: "",
};

export const AppointmentType = createSlice({
  name: "appointmentTypeReducer",
  initialState,
  reducers: {
    getAppointmentTypeRequest: (state, payload) => {
      state.loadingAppointmentType = true;
    },
    getAppointmentTypeSuccess: (
      state,
      { payload: { results, count, next, previous } }
    ) => {
      state.loadingAppointmentType = false;
      state.appointmentTypes = results;
      state.count = count;
      state.next = next;
      state.previous = previous;
    },
    getAppointmentTypeFail: (state) => {
      state.loadingAppointmentType = false;
    },

    getAppointmentTypeNextRequest: (state, payload) => {
      state.loadingAppointmentType = true;
    },
    getAppointmentTypePreviousRequest: (state, payload) => {
      state.loadingAppointmentType = true;
    },
    createAppointmentTypeRequest: (state, payload) => {
      state.loadingCreateAppointmentType = true;
    },
    updateAppointmentTypeRequest: (state, payload) => {
      state.loadingCreateAppointmentType = true;
    },
    createAppointmentTypeSuccess: (state) => {
      state.loadingCreateAppointmentType = false;
    },
    createAppointmentTypeFail: (state) => {
      state.loadingCreateAppointmentType = false;
    },
    getAppointmentTypeByIdRequest: (state, payload) => {
      state.edit = true;
    },
    appointmentTypeEditSuccess: (state, { payload }) => {
      state.appointmentType = payload;
    },
    appointmentTypeEditFail: (state) => {
      state.edit = false;
    },
    updateAppointmentTypeSuccess: (state) => {
      state.loadingCreateAppointmentType = false;
    },
    updateAppointmentTypeFail: (state) => {
      state.loadingCreateAppointmentType = false;
    },
    clearAllData: (state) => {
      state.edit = false;
      state.appointmentType = null;
    },
  },
});

//action creaters generated for each case reducer
export const {
  getAppointmentTypeRequest,
  getAppointmentTypeFail,
  getAppointmentTypeSuccess,
  getAppointmentTypeNextRequest,
  getAppointmentTypePreviousRequest,
  createAppointmentTypeFail,
  createAppointmentTypeRequest,
  createAppointmentTypeSuccess,
  appointmentTypeEditSuccess,
  updateAppointmentTypeFail,
  updateAppointmentTypeSuccess,
  updateAppointmentTypeRequest,
  clearAllData,
  getAppointmentTypeByIdRequest,
  appointmentTypeEditFail,
} = AppointmentType.actions;

export default AppointmentType.reducer;
