import { axiosInstance } from "../../../../AppUtils/Utils/axios";
import { filterApiData } from "../../../../AppUtils/Utils/appFunctions";
import {
  getApiProps,
  searchProps,
} from "../../../../AppUtils/Utils/globalTypes";

export const customerModURL =
  "api/v1/inventory-customer-app/customer-registration";

interface rowsPerPageProps {
  rowsPerPage: number;
}
//get customer
export const getCustomer = (data: getApiProps) => {
  const { rowsPerPage, page } = data;
  return axiosInstance.get(
    `${customerModURL}?offset=${rowsPerPage * page}&limit=${rowsPerPage}${
      data?.filterData ? filterApiData(data?.filterData) : ""
    }`
  );
};

//get customer by id
export const getCustomerById = (id: number) => {
  return axiosInstance.get(`${customerModURL}/${id}`);
};

//handle search
export const searchCustomer = ({ rowsPerPage, search }: searchProps) => {
  return `${customerModURL}?offset=0&limit=${rowsPerPage}&search=${
    search ?? ""
  }`;
};

// create customer
export const createCustomer = (body: string | FormData) => {
  return axiosInstance.post(`${customerModURL}`, body);
};

//update customer
export const updateCustomer = (body: string | FormData, id: number) => {
  return axiosInstance.patch(`${customerModURL}/${id}`, body);
};

//get customer/title
export const getCustomerTitle = ({ rowsPerPage }: rowsPerPageProps) => {
  return axiosInstance.get(
    `${customerModURL}/title?offset=0&limit=${rowsPerPage}`
  );
};

//get customer/customerType
export const getCustomerCustomerType = ({ rowsPerPage }: rowsPerPageProps) => {
  return axiosInstance.get(
    `${customerModURL}/customer-type?offset=0&limit=${rowsPerPage}`
  );
};

//get customer/taxGroup
export const getCustomerTaxGroup = ({ rowsPerPage }: rowsPerPageProps) => {
  return axiosInstance.get(
    `${customerModURL}/tax-group?offset=0&limit=${rowsPerPage}`
  );
};