import { axiosInstance } from "../../../../AppUtils/Utils/axios";
import { filterApiData } from "../../../../AppUtils/Utils/appFunctions";
import {
  getApiProps,
  searchProps,
} from "../../../../AppUtils/Utils/globalTypes";
import { purchaseURL } from "../../Purchase/Redux/api";
export const saleURL = "api/v1/inventory-sale-app/sale";
//get sale data api
export const getSale = (data: getApiProps) => {
  const { rowsPerPage, page } = data;
  return axiosInstance.get(
    `${saleURL}?offset=${rowsPerPage * page}&limit=${rowsPerPage}${
      data?.filterData ? filterApiData(data?.filterData) : ""
    }`
  );
};
//handle search
export const searchSale = ({ rowsPerPage, search }: searchProps) => {
  return `${saleURL}?offset=0&limit=${rowsPerPage}&search=${search}`;
};
//create sale
export const createSale = (body: string | FormData) =>
  axiosInstance.post(`${saleURL}`, body);
//request external api
export const getSaleCustomer = ({
  defaultRowsPerPage,
  createdCustomerId,
  walkIn,
}: any) => {
  return axiosInstance.get(
    `/${saleURL}/customer?offset=0&limit=${defaultRowsPerPage}&id=${
      createdCustomerId ?? ""
    }&walk_in=${walkIn ?? false}`
  );
};
// get sale discount scheme
export const getSaleDiscountScheme = (rowsPerPage: number) =>
  axiosInstance.get(
    `/${saleURL}/discount-scheme?offset=0&limit=${rowsPerPage}`
  );
export const getSaleReferral = (rowsPerPage: number) =>
  axiosInstance.get(`/${saleURL}/referral?offset=0&limit=${rowsPerPage}`);
export const getSaleItem = (rowsPerPage: number) =>
  axiosInstance.get(
    `/${saleURL}/get-purchase-for-sale?offset=0&limit=${rowsPerPage}`
  );
export const getSaleCustomerType = () =>
  axiosInstance.get(
    `/api/v1/inventory-sale-app/sale/customer-type?offset=0&limit=0`
  );
//payment mode
export const getSalePaymentMode = () =>
  axiosInstance.get(
    `/api/v1/inventory-sale-app/sale/payment-mode?offset=0&limit=0`
  );

//view details

export const getViewDetails = ({ id, rowsPerPage, page }: any) =>
  axiosInstance.get(
    `${saleURL}/sale-detail?sale_main=${id}&offset=${
      rowsPerPage * page
    }&limit=${rowsPerPage}`
  );

export const getPrintDetails = ({ id }: any) =>
  axiosInstance.get(`${saleURL}/sale-detail?sale_main=${id}&offset=0&limit=0`);

// get sale organization rule
export const getSaleOrganizationRule = () =>
  axiosInstance.get(`${saleURL}/organization-rule`);
// get selected packing type detail
export const getPackingTypeDetail = (id: number) =>
  axiosInstance.get(`${purchaseURL}/packing-type-detail?item=${id}`);
//additional charge
export const getSaleAdditionalCharge = () =>
  axiosInstance.get(`${saleURL}/additional-charge-type?offset=0&limit=0`);
// get customer details
export const getPrintCustomerDetails = (id: number) =>
  axiosInstance.get(`${saleURL}/${id}`);
//print count
export const printCount = (body: string) =>
  axiosInstance.post(`${saleURL}/update-invoice-print-count`, body);

//get print count
export const getSalePrintCount = (id: number) =>
  axiosInstance.get(`${saleURL}/invoice-print-count?id=${id}`);
// get sale price
export const getSalePrice = (item: number, customerType: number) =>
  axiosInstance.get(
    `/${saleURL}/item-price?customer_type=${customerType}&item=${item}`
  );
