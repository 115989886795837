import { filterApiData } from "../../../../../../AppUtils/Utils/appFunctions";
import { axiosInstance } from "../../../../../../AppUtils/Utils/axios";
import { getApiProps, searchProps } from "../../../../../../AppUtils/Utils/globalTypes";

export const clinicOrderModURL = "/api/v1/appointment-app/clinic-order";
//get ClinicOrder data api
export const getClinicOrder = (data: any) => {
  const { id,rowsPerPage, page } = data;
  return axiosInstance.get(
    `${clinicOrderModURL}?customer=${id}&offset=${rowsPerPage * page}&limit=${rowsPerPage}${
      data?.filterData ? filterApiData(data?.filterData) : ""
    }`
  );
};
export const getClinicOrderCustomerType = ({ rowsPerPage }: any) => {
  return axiosInstance.get(
    `${clinicOrderModURL}/customer-type?offset=0&limit=${rowsPerPage}`
  );
};
export const getClinicOrderDepartment = (rowsPerPage: number) => {
  return axiosInstance.get(
    `${clinicOrderModURL}/department?offset=0&limit=${rowsPerPage}`
  );
};
export const getClinicOrderCategory = ({ rowsPerPage }: any) => {
  return axiosInstance.get(
    `${clinicOrderModURL}/clinicorder-category?offset=0&limit=${rowsPerPage}`
  );
};
//handle search
export const searchClinicOrder = ({id, rowsPerPage, search }: any) => {
  return `${clinicOrderModURL}?customer=${id}&offset=0&limit=${rowsPerPage}&search=${search}`;
};
//create ClinicOrder
export const createClinicOrder = (body: string | FormData) =>
  axiosInstance.post(`${clinicOrderModURL}`, body);
//update ClinicOrder
export const updateClinicOrder = (body: string | FormData, id: number) =>
  axiosInstance.patch(`${clinicOrderModURL}/${id}`, body);
//
export const getClinicOrderById = (id: number) => {
  return axiosInstance.get(`${clinicOrderModURL}/${id}`);
};

//
export const getClinicOrderItem = ({id,rowsPerPage}:any) =>
axiosInstance.get(`${clinicOrderModURL}/item?item_category=${id}&offset=0&limit=${rowsPerPage}`);
// export const getDeviceOrderCustomer = (rowsPerPage:number) =>
// axiosInstance.get(`${clinicOrderModURL}/customer?offset=0&limit=${rowsPerPage}`);



export const getDeviceOrderItemCategory = (rowsPerPage:number) =>
axiosInstance.get(`${clinicOrderModURL}/item-category?offset=0&limit=${rowsPerPage}`);