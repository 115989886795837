import { TableCell, TableHead, TableRow } from "@mui/material";
import React, { memo } from "react";
import { tableHeaderProps } from "../types";
import { tableStyles } from "../styles";

const CommonTableHeader = (props: tableHeaderProps) => {
  const { columns, extraCell } = props;
  const classes = tableStyles();
  return (
    <>
      <TableHead>
        <TableRow>
          <TableCell style={{ minWidth: 5, fontSize: 17 }}>#</TableCell>
          {columns?.map((headCell: any) => (
            <TableCell
              key={headCell.id}
              align={headCell.numeric ? "right" : "left"}
              padding={headCell.disablePadding ? "none" : "normal"}
              className={classes.headCell}
            >
              {headCell.label}
            </TableCell>
          ))}
          {[...Array(extraCell?.current)]?.map((_: object, i: number) => {
            return (
              <TableCell
                style={{ minWidth: 5, fontSize: 17 }}
                key={i}
              ></TableCell>
            );
          })}
        </TableRow>
      </TableHead>
    </>
  );
};
export default memo(CommonTableHeader);
