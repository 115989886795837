// @ts-ignore
import adbs from "ad-bs-converter";
import moment from "moment";
import { enumTypes } from "../EnumValues/types";
import { FormikProps } from "formik";
import { routine } from "../../Pages/ClinicPages/AppointmentRoutineSetup/Redux/types";

//date formater
const dateFormater = (date: string | Date | null | any): any => {
  // @ts-ignore
  // @ts-ignore
  if (!isNaN(Date.parse(date))) {
    if (date) {
      return Intl.DateTimeFormat("fr-CA", {
        day: "2-digit",
        month: "2-digit",
        year: "numeric",
      }).format(typeof date === "string" ? new Date(date) : date);
    }
  }
};
// for getting current date
const current = new Date();
const year = current.getFullYear();
const month = (current.getMonth() + 1).toString().padStart(2, "0");
const day = current.getDate().toString().padStart(2, "0");
const todayDate = dateFormater(`${year}-${month}-${day}`);

// display date in readable format
const getCreatedDate = (date: string | Date, dateWithTime?: boolean) => {
  const options = { month: "short", day: "numeric", year: "numeric" }; //specifies the format of the date.
  const optionsWithTime = {
    day: "numeric",
    month: "short",
    year: "numeric",
    hour: "numeric",
    minute: "numeric",
    hour12: true,
  };
  return new Date(date).toLocaleDateString(
    "en-US",
    //@ts-ignore
    !dateWithTime ? options : optionsWithTime
  );
};

const getCurrentTime = (date: string) => {
  return new Date(date).getTime();
};
//compare start and end dates
const isDateGreater = (startDate: Date, endDate: Date) => {
  const parsedStartDate = Date.parse(startDate.toUTCString());
  const parsedEndDate = Date.parse(endDate.toUTCString());

  if (parsedEndDate >= parsedStartDate) {
    return true;
  } else {
    return false;
  }
};
//validate start date in past
const isValidStartDate = (startDate: any) => {
  const formattedStartDate = dateFormater(startDate);
  const formattedCurrentDate = dateFormater(current);

  return formattedStartDate >= formattedCurrentDate;
};
const getISODateFormat = (date: string) => {
  // @ts-ignore
  const monthDate = new Date(date).getDate();
  const year = new Date(date).getFullYear();
  const month = new Date(date).getMonth() + 1;
  return `${year}-${String(month).padStart(2, "0")}-${String(
    monthDate
  ).padStart(2, "0")}`;
};
const getISODateYear = (date: string) => {
  // @ts-ignore
  const year = new Date(date).getFullYear();
  return year;
};
const getISODateMonth = (date: string) => {
  const monthString = new Date(date).toLocaleString("en-US", {
    month: "long",
  });
  return monthString;
};
//get dates in between start and end dates
const getDatesBetween = (startDate: string, endDate: string): routine[] => {
  const start = new Date(startDate);
  const end = new Date(endDate);
  const dates = [];
  let currentDate = start;
  while (currentDate <= end) {
    const monthName = currentDate.toLocaleString("default", {
      month: "short",
    });
    const day = currentDate.getDate();
    const year = currentDate.getFullYear();
    dates.push(`${monthName} ${day} ${year}`);
    currentDate = new Date(currentDate.getTime() + 24 * 60 * 60 * 1000);
  }
  return dates.map((date) => ({ date, startTime: "", endTime: "" }));
};
//time formater
const timeFormater = (date: Date | null | string) => {
  if (date) {
    return Intl.DateTimeFormat("en-US", {
      hour: "numeric",
      minute: "numeric",
      second: "numeric",
    }).format(typeof date === "string" ? new Date(date) : date);
  }
};
//get Today Date Bs
const getTodayDateBs = () => {
  const date = new Date().toISOString().slice(0, 10);
  const adBs = adbs.ad2bs(date.split("-").join("/"));
  return `${adBs.en.year}-${String(adBs.en.month).length === 2 ? adBs.en.month : "0" + adBs.en.month
    }-${String(adBs.en.day).length === 2 ? adBs.en.day : "0" + adBs.en.day}`;
};
//get today date ad
const getTodayDateAd = () => {
  const date = new Date().toISOString().slice(0, 10);
  return date;
};

//function to convert ad-to-bs
const convertDateAdToBs = (value: Date | null | string | any) => {
  var date = dateFormater(value);
  let age = getAge(date, true);
  //@ts-ignore
  if (
    (typeof date === "number" || typeof date === "string") &&
    date !== undefined &&
    age < 150
  ) {
    const adBs = adbs.ad2bs(dateFormater(value!).split("-").join("/"));
    let convertedDate = `${adBs.en.year}-${String(adBs.en.month).length === 2 ? adBs.en.month : "0" + adBs.en.month
      }-${String(adBs.en.day).length === 2 ? adBs.en.day : "0" + adBs.en.day}`;
    return convertedDate;
  } else {
    let value = new Date().toDateString();
    const adBs = adbs.ad2bs(dateFormater(value!).split("-").join("/"));
    let convertedDate = `${adBs.en.year}-${String(adBs.en.month).length === 2 ? adBs.en.month : "0" + adBs.en.month
      }-${String(adBs.en.day).length === 2 ? adBs.en.day : "0" + adBs.en.day}`;
    return convertedDate;
  }
};
//get age by year/month/day
const getAge = (value: string, withoutAffix?: boolean) => {
  let years = moment().diff(value, "years");
  let months = moment().diff(value, "month");
  let days = moment().diff(value, "days");
  return months === 0
    ? withoutAffix
      ? days
      : `${days}Days`
    : years === 0
      ? withoutAffix
        ? months
        : `${months}mth`
      : withoutAffix
        ? years
        : `${years}Yrs`;
};

//get age type by dob
const getAgeTypeByDob = (value: string) => {
  let years = moment().diff(value, "years");
  let months = moment().diff(value, "month");
  let days = moment().diff(value, "days");
  // let ageType = {}
  if (years !== 0) {
    return { id: 1, name: "Year" };
  } else if (months !== 0) {
    return { id: 2, name: "Months" };
  } else if (days !== 0) {
    return { id: 3, name: "Days" };
  }
};
//function to convert bs-to-ad
const convertDateBsToAd = (value: string) => {
  const bsAd = adbs.bs2ad(value?.split("-").join("/"));
  let convertedDate = `${bsAd.year}-${bsAd.month}-${bsAd.day}`;
  return convertedDate;
};
const getDobByAge = (
  age: string,
  ageType: enumTypes | null,
  formik: FormikProps<any>
) => {
  let dob;
  if (ageType === null || ageType.name === "Year") {
    dob = moment().subtract(age, "years");
  } else if (ageType?.name === "Months") {
    dob = moment().subtract(age, "months");
  } else {
    dob = moment().subtract(age, "days");
  }
  var dobAD = moment(dob).format("YYYY-MM-DD");
  formik.setFieldValue("dobDateAd", dobAD);
  const adBs = adbs.ad2bs(dobAD.split("-").join("/"));
  formik.setFieldValue(
    "dobDateBs",
    `${adBs.en.year}-${String(adBs.en.month).length === 2 ? adBs.en.month : "0" + adBs.en.month
    }-${String(adBs.en.day).length === 2 ? adBs.en.day : "0" + adBs.en.day}`
  );
};

// date formater for start and end date in appointment module
const formatStartOrEndDate = (date: any): any => {
  // @ts-ignore
  if (!isNaN(Date.parse(date))) {
    if (date) {
      const dateToFormat = new Date(date);
      const dateOnly = dateToFormat?.toISOString().substring(0, 10);
      const options = {
        hour: "numeric",
        minute: "numeric",
        hour12: true,
      };
      //@ts-ignore
      const timeOnly = dateToFormat?.toLocaleString("en-US", options);
      const [time, period] = timeOnly.split(" ");
      const [hours, minutes] = time.split(":").map((x) => x.padStart(2, "0"));
      const finalTime = `${hours}:${minutes} ${period}`;
      const finalDateTime = dateOnly + " " + finalTime;
      // const dateToFormat = new Date(date);
      // const dateOnly = dateToFormat?.toISOString().substring(0, 10);
      // const timeOnly = dateToFormat?.toTimeString().substring(0, 5);
      // const finalDateTime = dateOnly + "T" + timeOnly;
      return finalDateTime;
    }
  }
};

const getDOB = (age: number) => {
  const today = new Date();
  const dob = new Date();
  dob.setFullYear(today.getFullYear() - age);
  return dob;
};

function convertDatetimeFormat(inputDatetimeStr: Date | string): string {
  const inputDatetime = new Date(inputDatetimeStr);

  const offsetMinutes = typeof inputDatetimeStr === 'string' ? parseInt(inputDatetimeStr.split(":")[1]) : 0;

  const adjustedDatetime = new Date(inputDatetime.getTime() + offsetMinutes * 60000);

  const options: Intl.DateTimeFormatOptions = {
    year: 'numeric',
    month: 'long',
    day: 'numeric',
    hour: '2-digit',
    minute: '2-digit',
    hour12: true,
  };
  const outputDatetimeStr = adjustedDatetime.toLocaleString('en-US', options);

  return outputDatetimeStr;
}

export {
  convertDatetimeFormat,
  dateFormater,
  timeFormater,
  getTodayDateBs,
  getAge,
  getAgeTypeByDob,
  getDobByAge,
  getTodayDateAd,
  formatStartOrEndDate,
  convertDateBsToAd,
  todayDate,
  convertDateAdToBs,
  isDateGreater,
  getDatesBetween,
  isValidStartDate,
  getISODateFormat,
  getCurrentTime,
  getCreatedDate,
  getDOB,
  getISODateYear,
  getISODateMonth,
};
