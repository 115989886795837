import { makeStyles } from "@mui/styles";

export const filterStyles = makeStyles(() => ({
  filterContainer: {
    padding: "0px 2px",
  },
  checkboxContainer: {
    marginTop: "12px !important",
    paddingTop: "4px !important",
  },
  label: {
    fontSize: "0.9rem",
    fontWeight: "bold",
    fontFamily: "sans-serif",
  },
  filterButton: {
    marginTop: "8px !important",
  },
}));
