import * as React from "react";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import ListItemAvatar from "@mui/material/ListItemAvatar";
import Dialog from "@mui/material/Dialog";
import { FormControlLabel, FormGroup, Switch, TextField } from "@mui/material";

export interface SimpleDialogProps {
  columns: any;
  open: boolean;
  onClose: (value: string) => void;
}

function SimpleDialog(props: SimpleDialogProps) {
  const { onClose, open, columns } = props;
  const handleListItemClick = (value: string) => {
    onClose(value);
  };
  return (
    <Dialog onClose={onClose} open={open}>
      <List sx={{ pt: 0 }}>
        <TextField
          id="standard-basic"
          label="Column Title"
          variant="standard"
          autoFocus
          sx={{ marginLeft: "12px", marginBottom: "25px", marginTop: "10px" }}
        />
        {columns.map((column: any) => (
          <ListItem button key={column?.id}>
            <ListItemAvatar>
              <FormGroup>
                <FormControlLabel control={<Switch />} label={column?.label} />
              </FormGroup>
            </ListItemAvatar>
          </ListItem>
        ))}
        <ListItem
          autoFocus
          button
          onClick={() => handleListItemClick("addAccount")}
        >
          {/* <ListItemText primary="Add account" /> */}
        </ListItem>
      </List>
    </Dialog>
  );
}
export default function ColumnPreferences({ open, setOpen, columns }: any) {
  const handleClose = (value: string) => {
    setOpen(false);
  };
  return (
    <>
      <SimpleDialog columns={columns} open={open} onClose={handleClose} />
    </>
  );
}
