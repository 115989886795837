import {
  getReferralTypeRequest,
  getReferralTypeFail,
  getReferralTypeSuccess,
  createReferralTypeFail,
  createReferralTypeRequest,
  createReferralTypeSuccess,
  updateReferralTypeFail,
  updateReferralTypeSuccess,
  clearAllData,
  getReferralTypeNextRequest,
  getReferralTypePreviousRequest,
  updateReferralTypeRequest,
  getReferralTypeByIdRequest,
  referralTypeEditSuccess,
  referralTypeEditFail,
} from "./referralTypeSlice";
import { mergeMap } from "rxjs";
import {
  getReferralType,
  createReferralType,
  updateReferralType,
  getReferralTypeById,
} from "./api";
import { map, filter } from "rxjs/operators";
import { Observable } from "rxjs";
import { Action } from "@reduxjs/toolkit";
import { combineEpics } from "redux-observable";
import {
  alertErrorAction,
  alertSuccessAction,
  closeModal,
} from "../../../CommonAppRedux/CommonAppSlice";
import { getNext, getPrevious } from "../../../CommonAppRedux/api";
import {
  dispatchAction,
  stateAction,
} from "../../../../AppUtils/Utils/globalTypes";
import messages from "../../../../AppUtils/Utils/validationConstants";
export const controller = new AbortController();

//get ReferralType
const getReferralTypeEpic = (
  action$: Observable<Action>,
  _: stateAction,
  { dispatch }: dispatchAction
) =>
  action$.pipe(
    filter(getReferralTypeRequest.match),
    mergeMap(async (action: any) => {
      try {
        const response = await getReferralType(action.payload);
        return { payload: response.data };
      } catch (e) {
        return { error: e };
      }
    }),
    map((action) =>
      action?.payload
        ? getReferralTypeSuccess(action?.payload)
        : getReferralTypeFail()
    )
  );

//get referralType by id epic
const getReferralTypeByIdEpic = (
  action$: Observable<Action>,
  _: stateAction,
  { dispatch }: dispatchAction
) =>
  action$.pipe(
    filter(getReferralTypeByIdRequest.match),
    mergeMap(async (action) => {
      try {
        const response = await getReferralTypeById(action.payload);
        return { payload: response.data };
      } catch (e) {
        return { error: e };
      }
    }),
    map((action) =>
      action?.payload
        ? referralTypeEditSuccess(action.payload)
        : referralTypeEditFail()
    )
  );

//create
const createReferralTypeEpic = (
  action$: Observable<Action>,
  _: stateAction,
  { dispatch }: dispatchAction
) =>
  action$.pipe(
    filter(createReferralTypeRequest.match),
    mergeMap(async ({ payload: { values, rowsPerPage, page } }) => {
      try {
        const body = new FormData();
        for (let [key, value] of Object.entries(values)) {
          body.append(`${key}`, String(value));
        }
        const response = await createReferralType(body);
        if (response) {
          dispatch(getReferralTypeRequest({ rowsPerPage, page }));
          dispatch(alertSuccessAction(messages.createMessage));
          dispatch(closeModal());
        }
        return { payload: { response } };
      } catch (e) {
        dispatch(alertErrorAction(messages.createFailMessage));
        return { error: e };
      }
    }),
    map((action) => {
      return action?.payload ? createReferralTypeSuccess() : createReferralTypeFail();
    })
  );

//update
const updateReferralTypeEpic = (
  action$: Observable<Action>,
  _: stateAction,
  { dispatch }: dispatchAction
) =>
  action$.pipe(
    filter(updateReferralTypeRequest.match),
    mergeMap(async ({ payload: { values, id, rowsPerPage, page, search } }) => {
      try {
        const body = new FormData();
        for (let [key, value] of Object.entries(values)) {
          body.append(`${key}`, String(value));
        }
        const response = await updateReferralType(body, id);
        if (response) {
          dispatch(getReferralTypeRequest({ rowsPerPage, page, search }));
          dispatch(alertSuccessAction(messages.updateMessage));
          dispatch(closeModal());
          dispatch(clearAllData());
        }
        return { payload: { response, rowsPerPage } };
      } catch (e) {
        dispatch(alertErrorAction(messages.updateFailMessage));
        return { error: e };
      }
    }),
    map((action) => {
      return action?.payload ? updateReferralTypeSuccess() : updateReferralTypeFail();
    })
  );

//get next
const getReferralTypeNext = (
  action$: Observable<Action>,
  _: stateAction,
  { dispatch }: dispatchAction
) =>
  action$.pipe(
    filter(getReferralTypeNextRequest.match),
    mergeMap(async (action) => {
      try {
        const response = await getNext(action.payload);
        return { payload: response.data };
      } catch (e) {
        return { error: e };
      }
    }),
    map((action) => {
      return action?.payload
        ? getReferralTypeSuccess(action?.payload)
        : getReferralTypeFail();
    })
  );

//get previous
const getReferralTypePrevious = (
  action$: Observable<Action>,
  _: stateAction,
  { dispatch }: dispatchAction
) =>
  action$.pipe(
    filter(getReferralTypePreviousRequest.match),
    mergeMap(async (action) => {
      try {
        const response = await getPrevious(action.payload);
        return { payload: response.data };
      } catch (e) {
        return { error: e };
      }
    }),
    map((action) =>
      action?.payload
        ? getReferralTypeSuccess(action?.payload)
        : getReferralTypeFail()
    )
  );

export const referralTypeEpic = combineEpics(
  getReferralTypeEpic,
  createReferralTypeEpic,
  updateReferralTypeEpic,
  getReferralTypeNext,
  getReferralTypePrevious,
  getReferralTypeByIdEpic
);
