import { axiosInstance } from "../../../../../../AppUtils/Utils/axios";

export const userAddressUrl = "api/v1/appointment-app/user-address";

interface customerIdProps {
  customerId: number;
}

// fetch user address
export const getUserAddressById = ({ customerId }: customerIdProps) => {
  return axiosInstance.get(
    `${userAddressUrl}?id=${customerId}&date_after=&date_before=`
  );
};

// create user adddress
export const createUserAddress = (body: string | FormData) => {
  return axiosInstance.post(`${userAddressUrl}`, body);
};

// update user address
export const updateUserAddress = (
  body: string | FormData,
  customerId: customerIdProps
) => axiosInstance.patch(`${userAddressUrl}/${customerId}`, body);

// edit user address
export const getEditedUserAddress = (id: number) => {
  return axiosInstance.get(`${userAddressUrl}/${id}`);
};

//get user address district
export const getUserAddressDistrict = (id: number) => {
  return axiosInstance(
    `${userAddressUrl}/district?province=${id ? id : ""}&offset=0&limit=10`
  );
};

//get user address province
export const getUserAddressProvince = () => {
  return axiosInstance(`${userAddressUrl}/province`);
};

// search user address
export const searchUserAddress = (customerId: customerIdProps) => {
  return `${userAddressUrl}?id=${customerId}&date_after=&date_before=`;
};
