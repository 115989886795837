import { createSlice, createAction } from "@reduxjs/toolkit";
import { PatientListReportInitialState } from "./types";
const initialState: PatientListReportInitialState = {
  users: [],
  patientListDetails: [],
  //loading 
  loadingGenerateReport: false,
  loadingPatientListReportUser: false,
  loadingPatientListReportDetail: false,
  count: 0,
  previous: "",
  next: "",
};

// export const getPatientListReportDetailRequest = createAction(
//   "getPatientListReportDetailRequest",
//   function prepare({ detailsRowsPerPage, page }) {
//     return {
//       payload: {
//         detailsRowsPerPage,
//         page,
//       },
//     };
//   }
// );
export const PatientListReport = createSlice({
  name: "PatientListReportReducer",
  initialState,
  reducers: {
    //loading
    loadingPatientListReportDetail: (state) => {
      state.loadingPatientListReportDetail = true;
    },
    //external reducer request
    getPatientListReportDetailRequest: (state, { payload }) => {
      state.loadingPatientListReportDetail = true;
    },
    getPatientListReportUserRequest: (state) => {
      state.loadingPatientListReportUser = true;
    },
    getPatientListReportAllSummaryRequest: (state, { payload }) => {
      state.loadingGenerateReport = true;
    },
    getPatientListReportAllDetailRequest: (state, { payload }) => {
      state.loadingGenerateReport = true;
    },
    getPatientListReportQuickSummaryRequest: (state, { payload }) => {
      state.loadingGenerateReport = true;
    },
    getPatientListReportQuickDetailRequest: (state, { payload }) => {
      state.loadingGenerateReport = true;
    },
    getPatientListReportNextRequest: (state, payload) => {
      state.loadingPatientListReportDetail = true;
    },
    getPatientListReportPreviousRequest: (state, payload) => {
      state.loadingPatientListReportDetail = true;
    },
    //external get reducer  
    getPatientListReportDetailSuccess: (
      state,
      { payload: { results, count, next, previous } }
    ) => {
      state.patientListDetails = results;
      state.loadingPatientListReportDetail = false;
      state.count = count;
      state.next = next;
      state.previous = previous;
    },
    getPatientListReportDetailFail: (state) => {
      state.loadingPatientListReportDetail = false;
    },
    getPatientListReportUserSuccess: (state, { payload: { results } }) => {
      state.loadingPatientListReportUser = false;
      state.users = results;
    },
    getPatientListReportUserFail: (state) => {
      state.loadingPatientListReportUser = false;
    },
    getPatientListReportAllSummarySuccess: (state, { payload: { results } }) => {
      state.loadingGenerateReport = false;
      state.patientListDetails = results;
    },
    getPatientListReportAllSummaryFail: (state) => {
      state.loadingGenerateReport = false;
    },
    getPatientListReportQuickSummarySuccess: (
      state,
      { payload: { results, count, next, previous } }
    ) => {
      state.loadingGenerateReport = false;
      state.patientListDetails = results;
      state.count = count;
      state.next = next;
      state.previous = previous;
    },
    getPatientListReportQuickSummaryFail: (state) => {
      state.loadingGenerateReport = false;
    },
    getPatientListReportAllDetailSuccess: (state, { payload: { results } }) => {
      state.loadingGenerateReport = false;
      state.patientListDetails = results;
    },
    getPatientListReportAllDetailFail: (state) => {
      state.loadingGenerateReport = false;
    },
    getPatientListReportQuickDetailSuccess: (
      state,
      { payload: { results, count, next, previous } }
    ) => {
      state.loadingGenerateReport = false;
      state.patientListDetails = results;
      state.count = count;
      state.next = next;
      state.previous = previous;
    },
    getPatientListReportQuickDetailFail: (state) => {
      state.loadingGenerateReport = false;
    },

    clearPatientListReportData: (state) => {
      state.users = [];
    },
    clearPrintUserActivityLogData: (state) => {
      state.patientListDetails = [];
    },
  },
});
// Action creators are generated for each case reducer function
export const {
  //reducerReport
  getPatientListReportDetailRequest,
  getPatientListReportUserRequest,
  getPatientListReportAllSummaryRequest,
  getPatientListReportAllDetailRequest,
  getPatientListReportQuickSummaryRequest,
  getPatientListReportQuickDetailRequest,
  getPatientListReportPreviousRequest,
  getPatientListReportNextRequest,
  //reducer get data
  getPatientListReportDetailSuccess,
  getPatientListReportDetailFail,
  getPatientListReportUserSuccess,
  getPatientListReportUserFail,
  getPatientListReportQuickDetailSuccess,
  getPatientListReportQuickDetailFail,
  getPatientListReportAllDetailSuccess,
  getPatientListReportAllDetailFail,
  getPatientListReportQuickSummarySuccess,
  getPatientListReportQuickSummaryFail,
  getPatientListReportAllSummarySuccess,
  getPatientListReportAllSummaryFail,
  //loading
  loadingPatientListReportDetail,
  //clear
  clearPatientListReportData,
  clearPrintUserActivityLogData,
} = PatientListReport.actions;
export default PatientListReport.reducer;
