import { axiosInstance } from "../../../../AppUtils/Utils/axios";
import { filterApiData } from "../../../../AppUtils/Utils/appFunctions";
import {
  getApiProps,
  searchProps,
} from "../../../../AppUtils/Utils/globalTypes";

export const itemApi = "api/v1/inventory-item-app/item";
//get Item data api
export const getItem = (data: getApiProps) => {
  const { rowsPerPage, page } = data;
  return axiosInstance.get(
    `${itemApi}?offset=${rowsPerPage * page}&limit=${rowsPerPage}${
      data?.filterData ? filterApiData(data?.filterData) : ""
    }`
  );
};
//handle search
export const searchItem = ({ rowsPerPage, search }: searchProps) => {
  return `${itemApi}?offset=0&limit=${rowsPerPage}&search=${search}`;
};
// get item item-category
export const getItemCategory = ({ rowsPerPage }: any) =>
  axiosInstance.get(`${itemApi}/item-category?offset=0&limit=${rowsPerPage}`);
// get item item-unit
export const getItemUnit = ({ rowsPerPage }: any) =>
  axiosInstance.get(`${itemApi}/unit?offset=0&limit=${rowsPerPage}`);
// get item item manufacturer
export const getItemManufacturer = ({ rowsPerPage }: any) =>
  axiosInstance.get(`${itemApi}/manufacturer?offset=0&limit=${rowsPerPage}`);
// get item packing type
export const getPackingType = () =>
  axiosInstance.get(`${itemApi}/packing-type`);
//create Item
export const createItem = (body: string | FormData) =>
  axiosInstance.post(`${itemApi}`, body);
//update Item
export const updateItem = (body: string | FormData, id: number) =>
  axiosInstance.patch(`${itemApi}/${id}`, body);
//get inventory item details
export const getInvItemDetails = (id: number) =>
  axiosInstance.get(`${itemApi}/${id}`);
// customer type
export const getItemCustomerType = () => {
  return axiosInstance.get(`${itemApi}/customer-type?offset=0&limit=0`);
};
