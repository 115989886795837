import TablePagination from "@mui/material/TablePagination";
import { memo } from "react";
import {
  useAppDispatch,
  useAppSelector,
} from "../../../../AppUtils/Utils/appHooks";
import {
  commonAppDetailsPage,
  commonAppDetailsRowsPerPage,
} from "../../../CommonAppRedux/CommonAppSlice";
import { Grid } from "@mui/material";
import { commonAppSelector } from "../../../CommonAppRedux/selector";
import TablePaginationActions from "../../../../Component/Table/TableUtils/TablePaginationAction";
const Pagination = ({ count, handleClick }: any) => {
  const { detailsPage, detailsRowsPerPage } = useAppSelector(commonAppSelector);
  const dispatch = useAppDispatch();
  const handleChangePage = async (event: unknown, newPage: number) => {
    //change page
    dispatch(commonAppDetailsPage(newPage));
    if (detailsPage! - newPage === -1) {
      handleClick("next");
    } else if (detailsPage! - newPage === 1) {
      handleClick("previous");
    } else {
      handleClick("firstAndLastData");
    }
  };
  const handleChangeRowsPerPage = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    //set rows per page
    dispatch(commonAppDetailsRowsPerPage(parseInt(event.target.value, 10)));
    //set page to 0
    dispatch(commonAppDetailsPage(0));
  };
  return (
    <Grid container justifyContent="space-between" alignItems="center">
      <Grid item>
        <TablePagination
          rowsPerPageOptions={[2, 10, 100, 200]}
          component="div"
          count={count}
          rowsPerPage={detailsRowsPerPage!}
          page={detailsPage!}
          onPageChange={handleChangePage}
          onRowsPerPageChange={handleChangeRowsPerPage}
          ActionsComponent={TablePaginationActions}
        />
      </Grid>
    </Grid>
  );
};

export default memo(Pagination);
