import {
  createReportFooterFail,
  createReportFooterRequest,
  createReportFooterSuccess,
  getReportFooterSuccess,
  getReportFooterFail,
  getReportFooterDoctorRequest,
  getReportFooterDoctorSuccess,
  getReportFooterDoctorFail,
  updateReportFooterFail,
  updateReportFooterSuccess,
  getReportFooterRequest,
  updateReportFooterRequest,
  getReportFooterNextRequest,
  getReportFooterPreviousRequest,
  clearReportFooterData,
  reportFooterEditSuccess,
  editReportFooterRequest,
  editReportFooterFail,
} from "./reportFooterSlice";
import { mergeMap } from "rxjs";
import {
  createReportFooter,
  getReportFooter,
  updateReportFooter,
  getReportFooterDoctor,
  getSpecificReportFooter,
} from "./api";
import { map, filter, tap } from "rxjs/operators";
import { Observable } from "rxjs";
import { Action } from "@reduxjs/toolkit";
import { combineEpics } from "redux-observable";
import {
  alertErrorAction,
  alertSuccessAction,
  closeModal,
} from "../../../CommonAppRedux/CommonAppSlice";
import { getNext, getPrevious } from "../../../CommonAppRedux/api";
import {
  dispatchAction,
  stateAction,
} from "../../../../AppUtils/Utils/globalTypes";
import messages from "../../../../AppUtils/Utils/validationConstants";

export const controller = new AbortController();

// get manufacture epic
const getReportFooterEpic = (
  action$: Observable<Action>,
  _: stateAction,
  { dispatch }: dispatchAction
) =>
  action$.pipe(
    filter(getReportFooterRequest.match),
    mergeMap(async (action) => {
      try {
        const response = await getReportFooter(action.payload);
        return { payload: response.data };
      } catch (e) {
        return { error: e };
      }
    }),
    map((action) =>
      action?.payload
        ? getReportFooterSuccess(action?.payload)
        : getReportFooterFail()
    )
  );
// get specific report footer  epic
const getSpecificReportFooterEpic = (
  action$: Observable<Action>,
  _: stateAction,
  { dispatch }: dispatchAction
) =>
  action$.pipe(
    filter(editReportFooterRequest.match),
    mergeMap(async (action) => {
      try {
        const response = await getSpecificReportFooter(action.payload);
        return { payload: response.data };
      } catch (e) {
        return { error: e };
      }
    }),
    map((action) =>
      action?.payload
        ? reportFooterEditSuccess(action?.payload)
        : editReportFooterFail()
    )
  );

//get next
const getReportFooterNext = (
  action$: Observable<Action>,
  _: stateAction,
  { dispatch }: dispatchAction
) =>
  action$.pipe(
    filter(getReportFooterNextRequest.match),
    mergeMap(async (action) => {
      try {
        const response = await getNext(action.payload);
        return { payload: response.data };
      } catch (e) {
        return { error: e };
      }
    }),
    map((action) =>
      action?.payload
        ? getReportFooterSuccess(action?.payload)
        : getReportFooterFail()
    )
  );

//get previous
const getReportFooterPrevious = (
  action$: Observable<Action>,
  _: stateAction,
  { dispatch }: dispatchAction
) =>
  action$.pipe(
    filter(getReportFooterPreviousRequest.match),
    mergeMap(async (action) => {
      try {
        const response = await getPrevious(action.payload);
        return { payload: response.data };
      } catch (e) {
        return { error: e };
      }
    }),
    map((action) =>
      action?.payload
        ? getReportFooterSuccess(action?.payload)
        : getReportFooterFail()
    )
  );
const getAllReportFooterDoctor = (
  action$: Observable<Action>,
  _: stateAction,
  { dispatch }: dispatchAction
) =>
  action$.pipe(
    filter(getReportFooterDoctorRequest.match),
    mergeMap(async (action) => {
      try {
        const response = await getReportFooterDoctor(action.payload);
        return { payload: response.data };
      } catch (e) {
        return { error: e };
      }
    }),
    map((action) =>
      action?.payload
        ? getReportFooterDoctorSuccess(action?.payload)
        : getReportFooterDoctorFail()
    )
  );
//create ReportFooter epic
const createReportFooterEpic = (
  action$: Observable<Action>,
  _: stateAction,
  { dispatch }: dispatchAction
) =>
  action$.pipe(
    filter(createReportFooterRequest.match),
    mergeMap(async ({ payload: { values, rowsPerPage, page } }) => {
      const { signatureImage, ...restValues } = values;
      try {
        const body = new FormData();
        for (let [key, value] of Object.entries(restValues)) {
          // @ts-ignore
          body.append(key, value);
        }
        signatureImage && body.append("signatureImage", signatureImage);
        // const body = new FormData();
        // body.append("displayDirection", displayDirection);
        // body.append("designation", designation);
        // body.append("nhpcNo", nhpcNo);
        // body.append("doctor", doctor);
        // body.append("active", active);
        const response = await createReportFooter(body);
        if (response) {
          dispatch(getReportFooterRequest({ rowsPerPage, page }));
          dispatch(alertSuccessAction(messages.createMessage));
          dispatch(closeModal());
        }
        return { payload: { response } };
      } catch (e) {
        dispatch(alertErrorAction(messages.createFailMessage));
        return { error: e };
      }
    }),
    map((action) => {
      return action?.payload
        ? createReportFooterSuccess()
        : createReportFooterFail();
    })
  );

//update ReportFooter epic
const updateReportFooterEpic = (
  action$: Observable<Action>,
  _: stateAction,
  { dispatch }: dispatchAction
) =>
  action$.pipe(
    filter(updateReportFooterRequest.match),
    mergeMap(async ({ payload: { values, id, rowsPerPage, page } }) => {
      const {
        signatureImage,
        alignment,
        nhpcNo,
        doctor,
        active,
        designation,
        displayOrder,
        remarks,
      } = values;
      try {
        const body = new FormData();
        // body.append("signatureImage",signatureImage);
        body.append("alignment", alignment);
        body.append("designation", designation);
        body.append("licenseNo", nhpcNo);
        body.append("doctor", doctor);
        body.append("active", active);
        body.append("remarks", remarks);
        body.append("displayOrder", displayOrder);
        if (signatureImage) {
          body.append("signatureImage", signatureImage);
        }
        const response = await updateReportFooter(body, id);
        if (response) {
          dispatch(getReportFooterRequest({ rowsPerPage, page }));
          dispatch(alertSuccessAction(messages.updateMessage));
          dispatch(clearReportFooterData());
          dispatch(closeModal());
        }
        return { payload: { response, rowsPerPage } };
      } catch (e) {
        dispatch(alertErrorAction(messages.updateFailMessage));
        return { error: e };
      }
    }),
    map((action) =>
      action?.payload ? updateReportFooterSuccess() : updateReportFooterFail()
    )
  );

export const reportFooterEpics = combineEpics(
  getReportFooterEpic,
  createReportFooterEpic,
  updateReportFooterEpic,
  getReportFooterNext,
  getReportFooterPrevious,
  getAllReportFooterDoctor,
  getSpecificReportFooterEpic
);
