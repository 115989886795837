import { createSlice, createAction } from "@reduxjs/toolkit";
import { item, itemPrice } from "./types";
const initialState: any = {
  items: [],
  loading: false,
  loadingCreateItem: false,
  edit: false,
  item: null,
  manufacturers: [],
  loadingManufacturer: false,
  units: [],
  loadingUnit: false,
  categories: [],
  loadingCategory: false,
  packingTypes: [],
  loadingPackingType: false,
  loadingItemCustomerType: false,
  itemCustomerTypes: [],
  createdItem: null,
  count: 0,
  previous: "",
  next: "",
  // price details
  itemDetails: [],
  itemDetail: null,
  itemDetailEdit: false,
};

export const Item = createSlice({
  name: "Item Reducer",
  initialState,
  reducers: {
    getItemRequest: (state, payload) => {
      state.loadingItem = true;
    },
    getItemSuccess: (
      state,
      { payload: { results, count, next, previous } }
    ) => {
      state.loadingItem = false;
      state.items = results;
      state.count = count;
      state.next = next;
      state.previous = previous;
    },
    getItemFail: (state) => {
      state.loadingItem = false;
    },
    getItemNextRequest: (state, payload) => {
      state.loadingItem = true;
    },
    getItemPreviousRequest: (state, payload) => {
      state.loadingItem = true;
    },
    loadingManufacturerReq: (state) => {
      state.loadingManufacturer = true;
    },
    getItemManufacturerRequest: (state, payload) => {
      state.loadingManufacturer = true;
    },
    getItemManufacturerSuccess: (state, { payload: { results } }) => {
      state.manufacturers = results;
      state.loadingManufacturer = false;
    },
    getItemManufacturerFail: (state) => {
      state.loadingManufacturer = false;
    },
    loadingUnitReq: (state) => {
      state.loadingUnit = true;
    },
    getItemUnitRequest: (state, payload) => {
      state.loadingUnit = true;
    },
    getItemUnitSuccess: (state, { payload: { results } }) => {
      state.units = results;
      state.loadingUnit = false;
    },
    getItemUnitFail: (state) => {
      state.loadingUnit = false;
    },
    loadingCategoryReq: (state) => {
      state.loadingCategory = true;
    },
    getItemCategoryRequest: (state, payload) => {
      state.loadingCategory = true;
    },
    getItemCategorySuccess: (state, { payload: { results } }) => {
      state.categories = results;
      state.loadingCategory = false;
    },
    getItemCategoryFail: (state) => {
      state.loadingCategory = false;
    },
    loadingPackingTypeReq: (state) => {
      state.loadingPackingType = true;
    },
    getPackingTypeRequest: (state) => {
      state.loadingPackingType = true;
    },
    getPackingTypeSuccess: (state, { payload: { results } }) => {
      state.loadingPackingType = false;
      state.packingTypes = results;
    },
    getPackingTypeFail: (state) => {
      state.loadingPackingType = false;
    },
    createItemRequest: (state, { payload }) => {
      state.loadingCreateItem = true;
      // state.createdItem = payload.response.data;
    },
    createItemSuccess: (state) => {
      state.loadingCreateItem = false;
      state.itemDetails = [];
    },
    createItemFail: (state) => {
      state.loadingCreateItem = false;
    },
    // item price details
    itemDetailEditSuccess: (state, { payload }) => {
      // state.itemDetail = {
      //   ...payload,
      //   customerTypeObj:
      //     typeof payload?.customerType === "object"
      //       ? payload?.customerType
      //       : payload?.customerTypeObj,
      //   includingTax: +payload?.netPrice > 0 ? true : false,
      // };
      // state.itemDetailEdit = true;
      const updatedItemDetail = state.itemDetails.find(
        (test: any) => test?.id === payload?.id
      );
      const updatedItemDetails = state.itemDetails.filter(
        (test: any) => test.id !== payload.id
      );
      state.itemDetail = updatedItemDetail;
      state.itemDetails = updatedItemDetails;
      state.itemDetailEdit = true;
      // state.loadingTest = false;
    },
    // update the item detail
    itemDetailUpdateSuccess: (state, { payload }) => {
      // state.itemDetails = state.itemDetails.map((item: itemPrice) => {
      //   // assign id according to the edit state
      //   const id = !payload?.isNew ? "id" : "unique_id";
      //   if (item[id] === state.itemDetail[id]) {
      //     return { ...item, ...payload };
      //   }
      //   return item;
      // });
      state.itemDetails = [...state.itemDetails, payload];
      state.edit = true;
      // state.loadingItemDetailsAdded = false;
      state.itemDetailEdit = false;
    },
    itemDetailAddedSuccess: (state, { payload }) => {
      state.itemDetails = [...state.itemDetails, payload];
      state.itemDetailEdit = false;
    },
    itemDetailDeleteSuccess: (state, { payload }) => {
      const updatedItemDetails = state.itemDetails.filter(
        (item: any) => item.unique_id !== payload.unique_id
      );
      state.itemDetails = updatedItemDetails;
      state.itemDetailEdit = false;
    },
    // customer type
    getItemCustomerTypeRequest: (state) => {
      state.loadingItemCustomerType = true;
    },
    getItemCustomerTypeSuccess: (state, { payload: { results } }) => {
      state.loadingItemCustomerType = false;
      state.itemCustomerTypes = results;
    },
    getItemCustomerTypeFail: (state) => {
      state.loadingItemCustomerType = false;
    },
    // get the details of items
    itemEditRequest: (state, payload) => {
      state.edit = true;
    },
    itemEditSuccess: (state, { payload }) => {
      state.item = false;
      state.item = payload;
      state.itemDetails = payload?.invItemPrices;
    },

    updateItemRequest: (state, payload) => {
      state.loadingCreateItem = true;
    },
    updateItemSuccess: (state) => {
      state.loadingCreateItem = false;
    },
    updateItemFail: (state) => {
      state.loadingCreateItem = false;
    },
    clearItemData: (state) => {
      state.edit = false;
      state.itemDetailEdit = false;
      state.item = null;
      state.itemDetails = [];
    },
  },
});
// Action creators are generated for each case reducer function
export const {
  loadingManufacturerReq,
  loadingUnitReq,
  loadingCategoryReq,
  loadingPackingTypeReq,
  getItemRequest,
  getItemSuccess,
  getItemFail,
  getItemNextRequest,
  getItemPreviousRequest,
  getItemManufacturerRequest,
  getItemManufacturerSuccess,
  getItemManufacturerFail,
  getItemUnitRequest,
  getItemUnitSuccess,
  getItemUnitFail,
  getItemCategoryRequest,
  getItemCategorySuccess,
  getItemCategoryFail,
  getPackingTypeRequest,
  getPackingTypeSuccess,
  getPackingTypeFail,
  createItemRequest,
  createItemSuccess,
  createItemFail,
  itemEditRequest,
  updateItemRequest,
  updateItemSuccess,
  updateItemFail,
  clearItemData,
  itemEditSuccess,
  // customer type
  getItemCustomerTypeRequest,
  getItemCustomerTypeSuccess,
  getItemCustomerTypeFail,
  // item price details
  itemDetailUpdateSuccess,
  itemDetailAddedSuccess,
  itemDetailDeleteSuccess,
  itemDetailEditSuccess,
} = Item.actions;
export default Item.reducer;
