import { Action } from "@reduxjs/toolkit";
import { Observable } from "rxjs";
import { filter, map, mergeMap } from "rxjs/operators";
import {
    dispatchAction,
    stateAction,
} from "../../../../../../AppUtils/Utils/globalTypes";
import {
    createContactRequestByIdFail,
    createContactRequestByIdRequest,
    createContactRequestByIdSuccess,
    editContactRequest,
    editContactRequestFail,
    editContactRequestSuccess,
    getContactRequest,
    getContactRequestByIdRequest,
    getContactRequestFail,
    getContactRequestForListFail,
    getContactRequestForListRequest,
    getContactRequestForListSuccess,
    getContactRequestSuccess,
    updateContactRequestByIdFail,
    updateContactRequestByIdRequest,
    updateContactRequestByIdSuccess,
    getAllContactRequest,
    getAllContactRequestFail,
    getAllContactRequestSuccess,
    clearContactRequest,
    updateContactStatusRequest,
    updateContactStatusSuccess,
    updateContactStatusFail,
    getAllContactRequestPreviousRequest,
    getAllContactRequestNextRequest,
} from "./contactRequestSlice";
import {
    createContactRequestById,
    getContactRequestById,
    getContactRequestForList,
    getContactRequests,
    getCustomerContactRequests,
    getEditedContactRequest,
    updateContactRequestById,
} from "./api";
import { combineEpics } from "redux-observable";
import {
    alertErrorAction,
    alertSuccessAction,
    closeModal,
} from "../../../../../CommonAppRedux/CommonAppSlice";
import messages from "../../../../../../AppUtils/Utils/validationConstants";
import { getNext, getPrevious } from "../../../../../CommonAppRedux/api";

// get all contact request epic
const getAllContactRequestEpic = (
    action$: Observable<Action>,
    _: stateAction,
    { dispatch }: dispatchAction
) =>
    action$.pipe(
        filter(getAllContactRequest.match),
        mergeMap(async (action) => {
            try {
                const response = await getContactRequests(action?.payload);
                return { payload: response?.data };
            } catch (e) {
                return { error: e };
            }
        }),
        map((action) =>
            action?.payload
                ? getAllContactRequestSuccess(action?.payload)
                : getAllContactRequestFail()
        )
    );

// get customer contact request epic
const getContactRequestEpic = (
    action$: Observable<Action>,
    _: stateAction,
    { dispatch }: dispatchAction
) =>
    action$.pipe(
        filter(getContactRequest.match),
        mergeMap(async (action) => {
            try {
                const response = await getCustomerContactRequests(action?.payload);
                return { payload: response?.data };
            } catch (e) {
                return { error: e };
            }
        }),
        map((action) =>
            action?.payload
                ? getContactRequestSuccess(action?.payload)
                : getContactRequestFail()
        )
    );

const getContactRequestByIdEpic = (
    action$: Observable<Action>,
    _: stateAction,
    { dispatch }: dispatchAction
) =>
    action$.pipe(
        filter(getContactRequestByIdRequest.match),
        mergeMap(async (action) => {
            try {
                const response = await getContactRequestById(action?.payload);

                return { payload: response.data };
            } catch (e) {
                return { error: e };
            }
        }),
        map((action) => {
            return action?.payload
                ? editContactRequestSuccess(action?.payload)
                : editContactRequestFail();
        })
    );

const getContactRequestForListEpic = (
    action$: Observable<Action>,
    _: stateAction,
    { dispatch }: dispatchAction
) =>
    action$.pipe(
        filter(getContactRequestForListRequest.match),
        mergeMap(async (action) => {
            try {
                const response = await getContactRequestForList();

                return { payload: response.data };
            } catch (e) {
                return { error: e };
            }
        }),
        map((action) => {
            return action?.payload
                ? getContactRequestForListSuccess(action?.payload)
                : getContactRequestForListFail();
        })
    );

const createContactRequestByIdEpic = (
    action$: Observable<Action>,
    _: stateAction,
    { dispatch }: dispatchAction
) =>
    action$.pipe(
        filter(createContactRequestByIdRequest.match),
        mergeMap(
            async ({
                payload: { values, rowsPerPage, page },
            }) => {
                try {
                    const body = JSON.stringify(values);
                    const response = await createContactRequestById(body);
                    if (response) {
                        dispatch(alertSuccessAction(messages.createMessage));
                        dispatch(
                            getContactRequest({
                                id: values?.customer,
                                rowsPerPage,
                                page,
                            })
                        );
                        dispatch(closeModal());
                        dispatch(clearContactRequest());
                    }
                    return { payload: { response } };
                } catch (e) {
                    dispatch(alertErrorAction(messages.createFailMessage));
                    return { error: e };
                }
            }
        ),
        map((action) => {
            return action?.payload
                ? createContactRequestByIdSuccess()
                : createContactRequestByIdFail();
        })
    );

const updateContactRequestByIdEpic = (
    action$: Observable<Action>,
    _: stateAction,
    { dispatch }: dispatchAction
) =>
    action$.pipe(
        filter(updateContactRequestByIdRequest.match),
        mergeMap(
            async ({
                payload: { values, rowsPerPage, page, id },
            }) => {
                try {
                    const body = JSON.stringify(values);
                    const response = await updateContactRequestById(body, id);

                    if (response) {
                        dispatch(
                            getContactRequest({
                                id: values?.customer,
                                rowsPerPage,
                                page,
                            })
                        );
                        dispatch(alertSuccessAction(messages.updateMessage));
                        dispatch(closeModal());
                        dispatch(clearContactRequest());
                    }
                    return { payload: { response } };
                } catch (e) {
                    dispatch(alertErrorAction(messages.updateFailMessage));
                    return { error: e };
                }
            }
        ),
        map((action) => {
            return action?.payload
                ? updateContactRequestByIdSuccess()
                : updateContactRequestByIdFail();
        })
    );

// Update Contact Status
const updateContactStatusEpic = (
    action$: Observable<Action>,
    _: stateAction,
    { dispatch }: dispatchAction
) =>
    action$.pipe(
        filter(updateContactStatusRequest.match),
        mergeMap(
            async ({
                payload: { values, id, rowsPerPage, page, setShowUpdateStatus },
            }) => {
                try {
                    const body = JSON.stringify(values);
                    const response = await updateContactRequestById(body, id);
                    if (response) {
                        dispatch(getAllContactRequest({ rowsPerPage, page }));
                        dispatch(alertSuccessAction(messages?.updateMessage));
                        dispatch(closeModal());
                        setShowUpdateStatus?.(false);
                    }
                    return { payload: { response, rowsPerPage } };
                } catch (e) {
                    dispatch(alertErrorAction(messages?.updateFailMessage));
                    return { error: e };
                }
            }
        ),
        map((action) =>
            action?.payload ? updateContactStatusSuccess() : updateContactStatusFail()
        )
    );


const getEditedContactRequestEpic = (
    action$: Observable<Action>,
    _: stateAction,
    { dispatch }: dispatchAction
) =>
    action$.pipe(
        filter(editContactRequest.match),
        mergeMap(async (action) => {
            try {
                const response = await getEditedContactRequest(action?.payload);
                return { payload: response.data };
            } catch (e) {
                return { error: e };
            }
        }),
        map((action) => {
            return action?.payload
                ? editContactRequestSuccess(action?.payload)
                : editContactRequestFail();
        })
    );

// GET Next Contact Reqeusts
const getAllContactRequestNext = (
    action$: Observable<Action>,
    _: stateAction,
    { dispatch }: dispatchAction
) =>
    action$.pipe(
        filter(getAllContactRequestNextRequest.match),
        mergeMap(async (action) => {
            try {
                const response = await getNext(action?.payload);
                return { payload: response?.data };
            } catch (e) {
                return { error: e };
            }
        }),
        map((action) =>
            action?.payload
                ? getAllContactRequestSuccess(action?.payload)
                : getAllContactRequestFail()
        )
    );

// GET Previous Contact Reqeusts
const getAllContactRequestPrevious = (
    action$: Observable<Action>,
    _: stateAction,
    { dispatch }: dispatchAction
) =>
    action$.pipe(
        filter(getAllContactRequestPreviousRequest.match),
        mergeMap(async (action) => {
            try {
                const response = await getPrevious(action?.payload);
                return { payload: response?.data };
            } catch (e) {
                return { error: e };
            }
        }),
        map((action) =>
            action?.payload
                ? getAllContactRequestSuccess(action?.payload)
                : getAllContactRequestFail()
        )
    );

export const contactRequestEpic = combineEpics(
    getContactRequestEpic,
    getContactRequestByIdEpic,
    getContactRequestForListEpic,
    createContactRequestByIdEpic,
    updateContactRequestByIdEpic,
    getEditedContactRequestEpic,
    getAllContactRequestEpic,
    updateContactStatusEpic,
    getAllContactRequestNext,
    getAllContactRequestPrevious,
);
