import {
  createDepartmentFail,
  createDepartmentRequest,
  createDepartmentSuccess,
  getDepartmentSuccess,
  getDepartmentFail,
  updateDepartmentFail,
  updateDepartmentSuccess,
  getDepartmentRequest,
  updateDepartmentRequest,
  getDepartmentNextRequest,
  getDepartmentPreviousRequest,
  clearDepartmentData,
  getDepartmentCustomerTypeRequest,
  getDepartmentCustomerTypeSuccess,
  getDepartmentCustomerTypeFail,
  departmentEditSuccess,
  getDepartmentByIdRequest,
  departmentEditFail,
} from "./departmentSlice";
import { mergeMap } from "rxjs";
import {
  createDepartment,
  getDepartment,
  getDepartmentById,
  getDepartmentCustomerType,
  updateDepartment,
} from "./api";
import { map, filter, tap } from "rxjs/operators";
import { Observable } from "rxjs";
import { Action } from "@reduxjs/toolkit";
import { combineEpics } from "redux-observable";
import {
  alertErrorAction,
  alertSuccessAction,
  closeModal,
} from "../../../CommonAppRedux/CommonAppSlice";
import { getNext, getPrevious } from "../../../CommonAppRedux/api";
import {
  dispatchAction,
  stateAction,
} from "../../../../AppUtils/Utils/globalTypes";
import messages from "../../../../AppUtils/Utils/validationConstants";

export const controller = new AbortController();

// get department epic
const getDepartmentEpic = (
  action$: Observable<Action>,
  _: stateAction,
  { dispatch }: dispatchAction
) =>
  action$.pipe(
    filter(getDepartmentRequest.match),
    mergeMap(async (action) => {
      try {
        const response = await getDepartment(action.payload);
        return { payload: response.data };
      } catch (e) {
        return { error: e };
      }
    }),
    map((action) =>
      action?.payload
        ? getDepartmentSuccess(action?.payload)
        : getDepartmentFail()
    )
  );

//get next
const getDepartmentNext = (
  action$: Observable<Action>,
  _: stateAction,
  { dispatch }: dispatchAction
) =>
  action$.pipe(
    filter(getDepartmentNextRequest.match),
    mergeMap(async (action) => {
      try {
        const response = await getNext(action.payload);
        return { payload: response.data };
      } catch (e) {
        return { error: e };
      }
    }),
    map((action) =>
      action?.payload
        ? getDepartmentSuccess(action?.payload)
        : getDepartmentFail()
    )
  );

//get previous
const getDepartmentPrevious = (
  action$: Observable<Action>,
  _: stateAction,
  { dispatch }: dispatchAction
) =>
  action$.pipe(
    filter(getDepartmentPreviousRequest.match),
    mergeMap(async (action) => {
      try {
        const response = await getPrevious(action.payload);
        return { payload: response.data };
      } catch (e) {
        return { error: e };
      }
    }),
    map((action) =>
      action?.payload
        ? getDepartmentSuccess(action?.payload)
        : getDepartmentFail()
    )
  );
//create Department epic
const createDepartmentEpic = (
  action$: Observable<Action>,
  _: stateAction,
  { dispatch }: dispatchAction
) =>
  action$.pipe(
    filter(createDepartmentRequest.match),
    mergeMap(
      async ({
        payload: { values, rowsPerPage, page, setShowModalFromAnotherModule },
      }) => {
        try {
          const body = JSON.stringify(values);
          const response = await createDepartment(body);
          if (response) {
            dispatch(getDepartmentRequest({ rowsPerPage, page }));
            dispatch(alertSuccessAction(messages.createMessage));
            setShowModalFromAnotherModule
              ? setShowModalFromAnotherModule(false)
              : dispatch(closeModal());
          }
          return { payload: { response } };
        } catch (e) {
          dispatch(alertErrorAction(messages.createFailMessage));
          return { error: e };
        }
      }
    ),
    map((action) => {
      return action?.payload
        ? createDepartmentSuccess()
        : createDepartmentFail();
    })
  );

//update Department epic
const updateDepartmentEpic = (
  action$: Observable<Action>,
  _: stateAction,
  { dispatch }: dispatchAction
) =>
  action$.pipe(
    filter(updateDepartmentRequest.match),
    mergeMap(async ({ payload: { values, id, rowsPerPage, page } }) => {
      try {
        const body = JSON.stringify(values);
        const response = await updateDepartment(body, id);
        if (response) {
          dispatch(getDepartmentRequest({ rowsPerPage, page }));
          dispatch(alertSuccessAction(messages.updateMessage));
          dispatch(clearDepartmentData());
          dispatch(closeModal());
        }
        return { payload: { response, rowsPerPage } };
      } catch (e) {
        dispatch(alertErrorAction(messages.updateFailMessage));
        return { error: e };
      }
    }),
    map((action) =>
      action?.payload ? updateDepartmentSuccess() : updateDepartmentFail()
    )
  );

const getDepartmentCustomerTypeEpic = (
  action$: Observable<Action>,
  _: stateAction,
  { dispatch }: dispatchAction
) =>
  action$.pipe(
    filter(getDepartmentCustomerTypeRequest.match),
    mergeMap(async (action) => {
      try {
        const response = await getDepartmentCustomerType();
        return { payload: response.data };
      } catch (e) {
        return { error: e };
      }
    }),
    map((action) =>
      action?.payload
        ? getDepartmentCustomerTypeSuccess(action?.payload)
        : getDepartmentCustomerTypeFail()
    )
  );

const getDepartmentByIdEpic = (
  action$: Observable<Action>,
  _: stateAction,
  { dispatch }: dispatchAction
) =>
  action$.pipe(
    filter(getDepartmentByIdRequest.match),
    mergeMap(async (action) => {
      try {
        const response = await getDepartmentById(action.payload);
        return { payload: response.data };
      } catch (e) {
        return { error: e };
      }
    }),
    map((action) =>
      action?.payload
        ? departmentEditSuccess(action?.payload)
        : departmentEditFail()
    )
  );

export const departmentEpics = combineEpics(
  getDepartmentEpic,
  createDepartmentEpic,
  updateDepartmentEpic,
  getDepartmentNext,
  getDepartmentPrevious,
  getDepartmentCustomerTypeEpic,
  getDepartmentByIdEpic
);
