import React, { memo, useEffect, useRef } from "react";
import TooltipContent from "../../Tooltip/TooltipContent";
import Spinner from "../../Loader/Spinner";
import { useImmer } from "use-immer";
const AppButton = ({
  className,
  tooltipTitle,
  disabled,
  title,
  loading,
  content,
  color,
  onClick,
  reference,
  id,
  children,
  submit,
  isDelete,
  name,
  style,
  checkTitleToDisplayAddIcon,
  autoFocus,
}: any) => {
  const [setEvent, ShowSetEvent] = useImmer(true);
  // useEffect(() => {
  //   if (id === "save" && loading === false && setEvent) {
  //     document.addEventListener(
  //       "keyup",
  //       function (event) {
  //         if (event.ctrlKey && event.code === "KeyS" && loading === false) {
  //           // @ts-ignore
  //           var elem = document.getElementById("save").click();
  //           ShowSetEvent(false);
  //         }
  //       },
  //       false
  //     );
  //   }
  //   if (id === "saveAndPrintBtn") {
  //     document.addEventListener(
  //       "keyup",
  //       function (event) {
  //         if (event.ctrlKey && event.code === "KeyP") {
  //           // @ts-ignore
  //           var elem = document.getElementById("saveAndPrintBtn").click();
  //         }
  //       },
  //       false
  //     );
  //   }
  //   if (id === "addBtn") {
  //     document.addEventListener(
  //       "keyup",
  //       function (event) {
  //         if (event.ctrlKey && event.code === "Enter") {
  //           // @ts-ignore
  //           var elem = document.getElementById("addBtn").click();
  //           // @ts-ignore
  //         }
  //       },
  //       false
  //     );
  //   }
  // }, [id]);
  useEffect(() => {
    if (id === "save" && !loading) {
      const saveBtn = document.getElementById("save");
      const handleSave = (event: KeyboardEvent) => {
        if (event.ctrlKey && event.key === "s") {
          event.preventDefault(); // prevent default behavior of saving the page
          saveBtn?.click(); // click the button
        }
      };
      document.addEventListener("keydown", handleSave);
      return () => {
        document.removeEventListener("keydown", handleSave);
      };
    }
    if (id === "saveAndPrint" && !loading) {
      const savePrintBtn = document.getElementById("saveAndPrint");
      const handleSaveAndPrint = (event: KeyboardEvent) => {
        if (event.ctrlKey && event.key === "p") {
          event.preventDefault(); // prev ent default behavior of saving the page
          savePrintBtn?.click(); // click the button
        }
      };
      document.addEventListener("keydown", handleSaveAndPrint);
      return () => {
        document.removeEventListener("keydown", handleSaveAndPrint);
      };
    }
  }, []);
  return (
    <TooltipContent title={tooltipTitle ? tooltipTitle : title ? title : ""}>
      <button
        style={style}
        name={name ?? tooltipTitle}
        type={submit ? "submit" : "button"}
        id={id}
        onClick={onClick}
        disabled={loading || disabled}
        className={
          className
            ? className
            : submit
            ? "submit_button"
            : isDelete
            ? "delete_button"
            : "default_button"
        }
        ref={reference}
        autoFocus={autoFocus}
      >
        <span>{loading ? <Spinner color={color} /> : title}</span>
        {children}
      </button>
    </TooltipContent>
  );
};
export default memo(AppButton);
