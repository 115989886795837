import { createSlice } from "@reduxjs/toolkit";
import { appointmentRoutineInitialState } from "./types";

const initialState: appointmentRoutineInitialState = {
  appointmentRoutines: [],
  loadingAppointmentRoutine: false,
  loadingCreateAppointmentRoutine: false,
  edit: false,
  appointmentRoutine: null,
  sites: [],
  loadingAppointmentSite: false,
  clinics: [],
  loadingAppointmentClinic: false,
  doctors: [],
  loadingAppointmentDoctor: false,
  routinesValue: [],
  loadingGenerateRoutine: false,
  count: 0,
  previous: "",
  next: "",
};

export const AppointmentRoutine = createSlice({
  name: "AppointmentRoutine Reducer",
  initialState,
  reducers: {
    getAppointmentRoutineRequest: (state, payload) => {
      state.loadingAppointmentRoutine = true;
    },
    getAppointmentRoutineSuccess: (
      state,
      { payload: { results, count, next, previous } }
    ) => {
      state.loadingAppointmentRoutine = false;
      state.appointmentRoutines = results;
      state.count = count;
      state.next = next;
      state.previous = previous;
    },
    getAppointmentRoutineFail: (state) => {
      state.loadingAppointmentRoutine = false;
    },
    loadingAppointmentSiteRequest: (state) => {
      state.loadingAppointmentSite = true;
    },
    getAppointmentSiteRequest: (state, payload) => {
      state.loadingAppointmentSite = true;
    },
    getAppointmentSiteSuccess: (state, { payload: { results } }) => {
      state.loadingAppointmentSite = false;
      state.sites = results;
    },
    getAppointmentSiteFail: (state) => {
      state.loadingAppointmentSite = false;
    },
    //
    loadingAppointmentClinicRequest: (state) => {
      state.loadingAppointmentClinic = true;
    },
    getAppointmentClinicRequest: (state, payload) => {
      state.loadingAppointmentClinic = true;
    },
    getAppointmentClinicSuccess: (state, { payload: { results } }) => {
      state.loadingAppointmentClinic = false;
      state.clinics = results;
    },
    getAppointmentClinicFail: (state) => {
      state.loadingAppointmentClinic = false;
    },
    //
    getClinicFromSiteRequest: (state, payload) => {
      state.loadingAppointmentClinic = true;
    },
    getClinicFromSiteSuccess: (state, { payload: { results } }) => {
      state.loadingAppointmentClinic = false;
      state.clinics = results;
    },
    getClinicFromSiteFail: (state) => {
      state.loadingAppointmentClinic = false;
    },
    //
    loadingAppointmentDoctorRequest: (state) => {
      state.loadingAppointmentDoctor = true;
    },
    getAppointmentDoctorRequest: (state, payload) => {
      state.loadingAppointmentDoctor = true;
    },
    getAppointmentDoctorSuccess: (state, { payload: { results } }) => {
      state.loadingAppointmentDoctor = false;
      state.doctors = results;
    },
    getAppointmentDoctorFail: (state) => {
      state.loadingAppointmentDoctor = false;
    },
    //
    getDoctorFromClinicRequest: (state, payload) => {
      state.loadingAppointmentDoctor = true;
    },
    getDoctorFromClinicSuccess: (state, { payload: { results } }) => {
      state.loadingAppointmentDoctor = false;
      state.doctors = results;
    },
    getDoctorFromClinicFail: (state) => {
      state.loadingAppointmentDoctor = false;
    },
    //
    getAppointmentRoutineNextRequest: (state, payload) => {
      state.loadingAppointmentRoutine = true;
    },
    getAppointmentRoutinePreviousRequest: (state, payload) => {
      state.loadingAppointmentRoutine = true;
    },
    createAppointmentRoutineRequest: (state, payload) => {
      state.loadingCreateAppointmentRoutine = true;
    },
    createAppointmentRoutineSuccess: (state) => {
      state.loadingCreateAppointmentRoutine = false;
    },
    createAppointmentRoutineFail: (state) => {
      state.loadingCreateAppointmentRoutine = false;
    },
    getAppointmentRoutineByIdRequest: (state, payload) => {
      state.edit = true;
    },
    appointmentRoutineEditSuccess: (state, { payload }) => {
      state.appointmentRoutine = payload;
    },
    appointmentRoutineEditFail: (state) => {
      state.edit = false;
    },
    //
    getAppointmentRoutineOnClickRequest: (state, payload) => {
      state.loadingGenerateRoutine = true;
    },
    getAppointmentRoutineOnClickSuccess: (state, { payload: { results } }) => {
      state.routinesValue = results;
    },
    getAppointmentRoutineOnClickFail: (state) => {
      state.loadingGenerateRoutine = false;
    },
    //
    updateAppointmentRoutineRequest: (state, payload) => {
      state.loadingCreateAppointmentRoutine = true;
    },
    updateAppointmentRoutineSuccess: (state) => {
      state.loadingCreateAppointmentRoutine = false;
    },
    updateAppointmentRoutineFail: (state) => {
      state.loadingCreateAppointmentRoutine = false;
    },
    clearAppointmentRoutineData: (state) => {
      state.edit = false;
      state.appointmentRoutine = null;
    },
  },
});
// Action creators are generated for each case reducer function
export const {
  getAppointmentRoutineRequest,
  getAppointmentRoutineSuccess,
  getAppointmentRoutineFail,
  //
  getAppointmentRoutineNextRequest,
  getAppointmentRoutinePreviousRequest,
  //
  createAppointmentRoutineRequest,
  createAppointmentRoutineSuccess,
  createAppointmentRoutineFail,
  //
  appointmentRoutineEditSuccess,
  //
  updateAppointmentRoutineRequest,
  updateAppointmentRoutineSuccess,
  updateAppointmentRoutineFail,
  //
  clearAppointmentRoutineData,
  getAppointmentRoutineByIdRequest,
  appointmentRoutineEditFail,
  //
  loadingAppointmentSiteRequest,
  getAppointmentSiteRequest,
  getAppointmentSiteSuccess,
  getAppointmentSiteFail,
  //
  getClinicFromSiteRequest,
  getClinicFromSiteSuccess,
  getClinicFromSiteFail,
  //
  getDoctorFromClinicRequest,
  getDoctorFromClinicSuccess,
  getDoctorFromClinicFail,
  //
  loadingAppointmentClinicRequest,
  getAppointmentClinicRequest,
  getAppointmentClinicSuccess,
  getAppointmentClinicFail,
  //
  loadingAppointmentDoctorRequest,
  getAppointmentDoctorRequest,
  getAppointmentDoctorSuccess,
  getAppointmentDoctorFail,
  //
  getAppointmentRoutineOnClickRequest,
  getAppointmentRoutineOnClickSuccess,
  getAppointmentRoutineOnClickFail,
} = AppointmentRoutine.actions;
export default AppointmentRoutine.reducer;
