import {
  createTestFindingFail,
  createTestFindingRequest,
  createTestFindingSuccess,
  getTestFindingFail,
  getTestFindingSuccess,
  loadingTestFinding,
  updateTestFindingFail,
  updateTestFindingSuccess,
  getTestFindingRequest,
  getTestFindingNextRequest,
  getTestFindingPreviousRequest,
  updateTestFindingRequest,
  clearTestFindingData,
  getAllTestFindingTestRequest,
  getTestFindingTestSuccess,
  getTestFindingTestFail,
  getTestFindingReferenceRangeSuccess,
  getTestFindingReferenceRangeFail,
  getTestFindingReferenceRangeRequest,
  testFindingEditSuccess,
  testFindingEditFail,
  getTestFindingByIdRequest,
} from "./testFindingSlice";
import { mergeMap } from "rxjs";
import {
  createTestFinding,
  getTestFinding,
  updateTestFinding,
  getTestFindingReferenceRange,
  getAllTest,
  getTestFindingById,
} from "./api";
import { map, filter } from "rxjs/operators";
import { Observable } from "rxjs";
import { Action } from "@reduxjs/toolkit";
import { combineEpics } from "redux-observable";
import {
  alertErrorAction,
  alertSuccessAction,
  closeModal,
} from "../../../CommonAppRedux/CommonAppSlice";
import { getNext, getPrevious } from "../../../CommonAppRedux/api";
import {
  dispatchAction,
  stateAction,
} from "../../../../AppUtils/Utils/globalTypes";
import messages from "../../../../AppUtils/Utils/validationConstants";
export const controller = new AbortController();
// import { searchData } from "../../../CommonAppRedux/commonAppFunctions";
//get Type epic
const getTestFindingEpic = (
  action$: Observable<Action>,
  _: stateAction,
  { dispatch }: dispatchAction
) =>
  action$.pipe(
    filter(getTestFindingRequest.match),
    mergeMap(async (action) => {
      dispatch(loadingTestFinding());
      try {
        const response = await getTestFinding(action.payload);
        return { payload: response.data };
      } catch (e) {
        return { error: e };
      }
    }),
    map((action) =>
      action?.payload
        ? getTestFindingSuccess(action?.payload)
        : getTestFindingFail()
    )
  );

//get next
const getTestFindingNext = (
  action$: Observable<Action>,
  _: stateAction,
  { dispatch }: dispatchAction
) =>
  action$.pipe(
    filter(getTestFindingNextRequest.match),
    mergeMap(async (action) => {
      dispatch(loadingTestFinding());
      try {
        const response = await getNext(action.payload);
        return { payload: response.data };
      } catch (e) {
        return { error: e };
      }
    }),
    map((action) =>
      action?.payload
        ? getTestFindingSuccess(action?.payload)
        : getTestFindingFail()
    )
  );
//get previous
const getTestFindingPrevious = (
  action$: Observable<Action>,
  _: stateAction,
  { dispatch }: dispatchAction
) =>
  action$.pipe(
    filter(getTestFindingPreviousRequest.match),
    mergeMap(async (action) => {
      dispatch(loadingTestFinding());
      try {
        const response = await getPrevious(action.payload);
        return { payload: response.data };
      } catch (e) {
        return { error: e };
      }
    }),
    map((action) =>
      action?.payload
        ? getTestFindingSuccess(action?.payload)
        : getTestFindingFail()
    )
  );
const createTestFindingEpic = (
  action$: Observable<Action>,
  _: stateAction,
  { dispatch }: dispatchAction
) =>
  action$.pipe(
    filter(createTestFindingRequest.match),
    mergeMap(async ({ payload: { values, rowsPerPage, page } }) => {
      try {
        const body = JSON.stringify(values);
        const response = await createTestFinding(body);
        if (response) {
          dispatch(getTestFindingRequest({ rowsPerPage, page }));
          dispatch(alertSuccessAction(messages.createMessage));
          dispatch(closeModal());
        }
        return { payload: { response } };
      } catch (e) {
        dispatch(alertErrorAction(messages.createFailMessage));
        return { error: e };
      }
    }),
    map((action) => {
      return action?.payload
        ? createTestFindingSuccess()
        : createTestFindingFail();
    })
  );
//update Type epic
const updateTestFindingEpic = (
  action$: Observable<Action>,
  _: stateAction,
  { dispatch }: dispatchAction
) =>
  action$.pipe(
    filter(updateTestFindingRequest.match),
    mergeMap(async ({ payload: { values, id, rowsPerPage, page } }) => {
      try {
        const body = JSON.stringify(values);
        const response = await updateTestFinding(body, id);
        if (response) {
          dispatch(getTestFindingRequest({ rowsPerPage, page }));
          dispatch(alertSuccessAction(messages.updateMessage));
          dispatch(closeModal());
          dispatch(clearTestFindingData());
        }
        return { payload: { response, rowsPerPage } };
      } catch (e) {
        dispatch(alertErrorAction(messages.updateFailMessage));
        return { error: e };
      }
    }),
    map((action) =>
      action?.payload ? updateTestFindingSuccess() : updateTestFindingFail()
    )
  );

const getAllTestEpic = (
  action$: Observable<Action>,
  _: stateAction,
  { dispatch }: dispatchAction
) =>
  action$.pipe(
    filter(getAllTestFindingTestRequest.match),
    mergeMap(async (action) => {
      try {
        const response = await getAllTest(action?.payload);
        return { payload: response.data };
      } catch (e) {
        return { error: e };
      }
    }),
    map((action) =>
      action?.payload
        ? getTestFindingTestSuccess(action?.payload)
        : getTestFindingTestFail()
    )
  );

const getTestFindingReferenceRangeEpic = (
  action$: Observable<Action>,
  _: stateAction,
  { dispatch }: dispatchAction
) =>
  action$.pipe(
    filter(getTestFindingReferenceRangeRequest.match),
    mergeMap(async (action) => {
      try {
        const response = await getTestFindingReferenceRange(
          action?.payload?.defaultRowsPerPage,
          action?.payload?.id
        );
        return { payload: response.data };
      } catch (e) {
        return { error: e };
      }
    }),
    map((action) =>
      action?.payload
        ? getTestFindingReferenceRangeSuccess(action?.payload)
        : getTestFindingReferenceRangeFail()
    )
  );

const getTestFindingByIdEpic = (
  action$: Observable<Action>,
  _: stateAction,
  { dispatch }: dispatchAction
) =>
  action$.pipe(
    filter(getTestFindingByIdRequest.match),
    mergeMap(async (action) => {
      try {
        const response = await getTestFindingById(action.payload);
        return { payload: response.data };
      } catch (e) {
        return { error: e };
      }
    }),
    map((action) =>
      action?.payload
        ? testFindingEditSuccess(action?.payload)
        : testFindingEditFail()
    )
  );

export const testFindingEpics = combineEpics(
  getTestFindingEpic,
  createTestFindingEpic,
  updateTestFindingEpic,
  getTestFindingNext,
  getTestFindingPrevious,
  getAllTestEpic,
  getTestFindingReferenceRangeEpic,
  getTestFindingByIdEpic
);
