import {
  createReferenceRangeFail,
  createReferenceRangeRequest,
  createReferenceRangeSuccess,
  getReferenceRangeSuccess,
  getReferenceRangeFail,
  getReferenceRangeDepartmentRequest,
  getReferenceRangeDepartmentSuccess,
  getReferenceRangeDepartmentFail,
  getReferenceRangeTestRequest,
  getReferenceRangeTestSuccess,
  getReferenceRangeTestFail,
  getReferenceFilteredTestRequest,
  getReferenceFilteredTestSuccess,
  getReferenceFilteredTestFail,
  getReferenceRangeTestUnitRequest,
  getReferenceRangeTestUnitSuccess,
  getReferenceRangeTestUnitFail,
  getReferenceRangeTestMethodRequest,
  getReferenceRangeTestMethodSuccess,
  getReferenceRangeTestMethodFail,
  updateReferenceRangeFail,
  updateReferenceRangeSuccess,
  getReferenceRangeRequest,
  updateReferenceRangeRequest,
  getReferenceRangeNextRequest,
  getReferenceRangePreviousRequest,
  clearReferenceRangeData,
  getReferenceRangeByIdRequest,
  referenceRangeEditSuccess,
  referenceRangeEditFail,
} from "./referenceRangeSlice";
import { mergeMap } from "rxjs";
import {
  createReferenceRange,
  getReferenceRange,
  updateReferenceRange,
  getReferenceRangeTestMethod,
  getReferenceRangeTestUnit,
  getReferenceFilteredTest,
  getReferenceRangeTest,
  getReferenceRangeDepartment,
  getReferenceRangeById,
} from "./api";
import { map, filter, tap } from "rxjs/operators";
import { Observable } from "rxjs";
import { Action } from "@reduxjs/toolkit";
import { combineEpics } from "redux-observable";
import {
  alertErrorAction,
  alertSuccessAction,
  closeModal,
} from "../../../CommonAppRedux/CommonAppSlice";
import { getNext, getPrevious } from "../../../CommonAppRedux/api";
import {
  dispatchAction,
  stateAction,
} from "../../../../AppUtils/Utils/globalTypes";
import messages from "../../../../AppUtils/Utils/validationConstants";

export const controller = new AbortController();

// get ReferenceRange epic
const getReferenceRangeEpic = (
  action$: Observable<Action>,
  _: stateAction,
  { dispatch }: dispatchAction
) =>
  action$.pipe(
    filter(getReferenceRangeRequest.match),
    mergeMap(async (action) => {
      try {
        const response = await getReferenceRange(action.payload);
        return { payload: response.data };
      } catch (e) {
        return { error: e };
      }
    }),
    map((action) =>
      action?.payload
        ? getReferenceRangeSuccess(action?.payload)
        : getReferenceRangeFail()
    )
  );

// get ReferenceRange by idepic
const getReferenceRangeByIdEpic = (
  action$: Observable<Action>,
  _: stateAction,
  { dispatch }: dispatchAction
) =>
  action$.pipe(
    filter(getReferenceRangeByIdRequest.match),
    mergeMap(async (action) => {
      try {
        const response = await getReferenceRangeById(action.payload);
        return { payload: response.data };
      } catch (e) {
        return { error: e };
      }
    }),
    map((action) =>
      action?.payload
        ? referenceRangeEditSuccess(action?.payload)
        : referenceRangeEditFail()
    )
  );

//get next
const getReferenceRangeNext = (
  action$: Observable<Action>,
  _: stateAction,
  { dispatch }: dispatchAction
) =>
  action$.pipe(
    filter(getReferenceRangeNextRequest.match),
    mergeMap(async (action) => {
      try {
        const response = await getNext(action.payload);
        return { payload: response.data };
      } catch (e) {
        return { error: e };
      }
    }),
    map((action) =>
      action?.payload
        ? getReferenceRangeSuccess(action?.payload)
        : getReferenceRangeFail()
    )
  );

//get previous
const getReferenceRangePrevious = (
  action$: Observable<Action>,
  _: stateAction,
  { dispatch }: dispatchAction
) =>
  action$.pipe(
    filter(getReferenceRangePreviousRequest.match),
    mergeMap(async (action) => {
      try {
        const response = await getPrevious(action.payload);
        return { payload: response.data };
      } catch (e) {
        return { error: e };
      }
    }),
    map((action) =>
      action?.payload
        ? getReferenceRangeSuccess(action?.payload)
        : getReferenceRangeFail()
    )
  );
// get ReferenceRange lists
const getAllReferenceRangeTests = (
  action$: Observable<Action>,
  _: stateAction,
  { dispatch }: dispatchAction
) =>
  action$.pipe(
    filter(getReferenceRangeTestRequest.match),
    mergeMap(async (action) => {
      try {
        const response = await getReferenceRangeTest(action.payload);
        return { payload: response.data };
      } catch (e) {
        return { error: e };
      }
    }),
    map((action) =>
      action?.payload
        ? getReferenceRangeTestSuccess(action?.payload)
        : getReferenceRangeTestFail()
    )
  );
// get  filtered referenceRange lists
const getReferenceFilteredTests = (
  action$: Observable<Action>,
  _: stateAction,
  { dispatch }: dispatchAction
) =>
  action$.pipe(
    filter(getReferenceFilteredTestRequest.match),
    mergeMap(async (action) => {
      try {
        const response = await getReferenceFilteredTest(action.payload);
        return { payload: response.data };
      } catch (e) {
        return { error: e };
      }
    }),
    map((action) =>
      action?.payload
        ? getReferenceFilteredTestSuccess(action?.payload)
        : getReferenceFilteredTestFail()
    )
  );
// get ReferenceRange department
const getAllReferenceRangeDepartments = (
  action$: Observable<Action>,
  _: stateAction,
  { dispatch }: dispatchAction
) =>
  action$.pipe(
    filter(getReferenceRangeDepartmentRequest.match),
    mergeMap(async (action) => {
      try {
        const response = await getReferenceRangeDepartment(action.payload);
        return { payload: response.data };
      } catch (e) {
        return { error: e };
      }
    }),
    map((action) =>
      action?.payload
        ? getReferenceRangeDepartmentSuccess(action?.payload)
        : getReferenceRangeDepartmentFail()
    )
  );
// get ReferenceRange testunits
const getAllReferenceRangeTestUnits = (
  action$: Observable<Action>,
  _: stateAction,
  { dispatch }: dispatchAction
) =>
  action$.pipe(
    filter(getReferenceRangeTestUnitRequest.match),
    mergeMap(async (action) => {
      try {
        const response = await getReferenceRangeTestUnit(action.payload);
        return { payload: response.data };
      } catch (e) {
        return { error: e };
      }
    }),
    map((action) =>
      action?.payload
        ? getReferenceRangeTestUnitSuccess(action?.payload)
        : getReferenceRangeTestUnitFail()
    )
  );
// get referenceRange methods
const getAllReferenceRangeTestMethods = (
  action$: Observable<Action>,
  _: stateAction,
  { dispatch }: dispatchAction
) =>
  action$.pipe(
    filter(getReferenceRangeTestMethodRequest.match),
    mergeMap(async (action) => {
      try {
        const response = await getReferenceRangeTestMethod(action.payload);
        return { payload: response.data };
      } catch (e) {
        return { error: e };
      }
    }),
    map((action) =>
      action?.payload
        ? getReferenceRangeTestMethodSuccess(action?.payload)
        : getReferenceRangeTestMethodFail()
    )
  );
//create ReferenceRange epic
const createReferenceRangeEpic = (
  action$: Observable<Action>,
  _: stateAction,
  { dispatch }: dispatchAction
) =>
  action$.pipe(
    filter(createReferenceRangeRequest.match),
    mergeMap(async ({ payload: { values, rowsPerPage, page } }) => {
      const {
        name,
        displayOrder,
        active,
        remarks,
        rangeSelector,
        header,
        subjective,
        decimal,
        lowRangeGen,
        lowRangeMale,
        lowRangeFemale,
        lowRangeChild,
        highRangeGen,
        highRangeMale,
        highRangeFemale,
        highRangeChild,
        abbreviation,
        specialRange,
        displayRange,
        test,
        testUnit,
        testMethod,
          descriptiveText,
      } = values;
      try {
        const body = JSON.stringify({
          name,
          displayOrder,
          active,
          remarks,
          rangeSelector: !header ? rangeSelector?.id : "HEADER",
          header,
          subjective: !header ? subjective : false,
          decimal: !header ? decimal : false,
          lowRangeGen: !header ? lowRangeGen : "",
          lowRangeMale: !header ? lowRangeMale : "",
          lowRangeFemale: !header ? lowRangeFemale : "",
          lowRangeChild: !header ? lowRangeChild : "",
          highRangeGen: !header ? highRangeGen : "",
          highRangeMale: !header ? highRangeMale : "",
          highRangeFemale: !header ? highRangeFemale : "",
          highRangeChild: !header ? highRangeChild : "",
          abbreviation: !header ? abbreviation : "",
          specialRange: !header ? specialRange : "",
          displayRange: !header ? displayRange : "",
          test,
          testUnit: !header ? (testUnit ? testUnit?.id : null) : null,
          testMethod: !header ? (testMethod ? testMethod?.id : null) : null,
            descriptiveText: !header ?   descriptiveText : "",
        });
        const response = await createReferenceRange(body);
        if (response) {
          dispatch(getReferenceRangeRequest({ id: test }));
          dispatch(alertSuccessAction(messages.createMessage));
          dispatch(closeModal());
        }
        return { payload: { response } };
      } catch (e) {
        dispatch(alertErrorAction(messages.createFailMessage));
        return { error: e };
      }
    }),
    map((action) => {
      return action?.payload
        ? createReferenceRangeSuccess()
        : createReferenceRangeFail();
    })
  );

//update ReferenceRange epic
const updateReferenceRangeEpic = (
  action$: Observable<Action>,
  _: stateAction,
  { dispatch }: dispatchAction
) =>
  action$.pipe(
    filter(updateReferenceRangeRequest.match),
    mergeMap(async ({ payload: { values, id, rowsPerPage, page } }) => {
      const {
        name,
        displayOrder,
        active,
        remarks,
        rangeSelector,
        header,
        subjective,
        decimal,
        lowRangeGen,
        lowRangeMale,
        lowRangeFemale,
        lowRangeChild,
        highRangeGen,
        highRangeMale,
        highRangeFemale,
        highRangeChild,
        abbreviation,
        specialRange,
        displayRange,
        test,
        testUnit,
        testMethod,
          descriptiveText,
      } = values;
      try {
        const body = JSON.stringify({
          name,
          displayOrder,
          active,
          remarks,
          rangeSelector: !header ? rangeSelector?.id : "HEADER",
          header,
          subjective: !header ? subjective : false,
          decimal: !header ? decimal : false,
          lowRangeGen: !header ? lowRangeGen : "",
          lowRangeMale: !header ? lowRangeMale : "",
          lowRangeFemale: !header ? lowRangeFemale : "",
          lowRangeChild: !header ? lowRangeChild : "",
          highRangeGen: !header ? highRangeGen : "",
          highRangeMale: !header ? highRangeMale : "",
          highRangeFemale: !header ? highRangeFemale : "",
          highRangeChild: !header ? highRangeChild : "",
          abbreviation: !header ? abbreviation : "",
          specialRange: !header ? specialRange : "",
          displayRange: !header ? displayRange : "",
          test,
          testUnit: !header ? (testUnit ? testUnit?.id : null) : null,
          testMethod: !header ? (testMethod ? testMethod?.id : null) : null,
            descriptiveText: !header ?   descriptiveText : "",
        });
        const response = await updateReferenceRange(body, id);
        if (response) {
          dispatch(getReferenceRangeRequest({ id: test }));
          dispatch(alertSuccessAction(messages.updateMessage));
          dispatch(clearReferenceRangeData());
          dispatch(closeModal());
        }
        return { payload: { response, rowsPerPage } };
      } catch (e) {
        dispatch(alertErrorAction(messages.updateFailMessage));
        return { error: e };
      }
    }),
    map((action) =>
      action?.payload
        ? updateReferenceRangeSuccess()
        : updateReferenceRangeFail()
    )
  );

export const referenceRangeEpics = combineEpics(
  getReferenceRangeEpic,
  createReferenceRangeEpic,
  updateReferenceRangeEpic,
  getReferenceRangeNext,
  getReferenceRangePrevious,
  getAllReferenceRangeTestUnits,
  getAllReferenceRangeTests,
  getReferenceFilteredTests,
  getAllReferenceRangeDepartments,
  getAllReferenceRangeTestMethods,
  getReferenceRangeByIdEpic
);
