import {
  createReportCommentFail,
  createReportCommentRequest,
  createReportCommentSuccess,
  getReportCommentFail,
  getReportCommentSuccess,
  loadingReportComment,
  updateReportCommentFail,
  updateReportCommentSuccess,
  getReportCommentRequest,
  getReportCommentNextRequest,
  getReportCommentPreviousRequest,
  updateReportCommentRequest,
  clearReportCommentData,
  getAllReportCommentDefaultRequest,
} from "./reportCommentSlice";
import { mergeMap } from "rxjs";
import {
  createReportComment,
  getReportComment,
  updateReportComment,
  getAllReportCommentForDefault,
} from "./api";
import { map, filter } from "rxjs/operators";
import { Observable } from "rxjs";
import { Action } from "@reduxjs/toolkit";
import { combineEpics } from "redux-observable";
import {
  alertErrorAction,
  alertSuccessAction,
  closeModal,
} from "../../../CommonAppRedux/CommonAppSlice";
import { getNext, getPrevious } from "../../../CommonAppRedux/api";
import {
  dispatchAction,
  stateAction,
} from "../../../../AppUtils/Utils/globalTypes";
import messages from "../../../../AppUtils/Utils/validationConstants";
export const controller = new AbortController();
// import { searchData } from "../../../CommonAppRedux/commonAppFunctions";
//get Type epic
const getReportCommentEpic = (
  action$: Observable<Action>,
  _: stateAction,
  { dispatch }: dispatchAction
) =>
  action$.pipe(
    filter(getReportCommentRequest.match),
    mergeMap(async (action) => {
      dispatch(loadingReportComment());
      try {
        const response = await getReportComment(action.payload);
        return { payload: response.data };
      } catch (e) {
        return { error: e };
      }
    }),
    map((action) =>
      action?.payload
        ? getReportCommentSuccess(action?.payload)
        : getReportCommentFail()
    )
  );

//get next
const getReportCommentNext = (
  action$: Observable<Action>,
  _: stateAction,
  { dispatch }: dispatchAction
) =>
  action$.pipe(
    filter(getReportCommentNextRequest.match),
    mergeMap(async (action) => {
      dispatch(loadingReportComment());
      try {
        const response = await getNext(action.payload);
        return { payload: response.data };
      } catch (e) {
        return { error: e };
      }
    }),
    map((action) =>
      action?.payload
        ? getReportCommentSuccess(action?.payload)
        : getReportCommentFail()
    )
  );
//get previous
const getReportCommentPrevious = (
  action$: Observable<Action>,
  _: stateAction,
  { dispatch }: dispatchAction
) =>
  action$.pipe(
    filter(getReportCommentPreviousRequest.match),
    mergeMap(async (action) => {
      dispatch(loadingReportComment());
      try {
        const response = await getPrevious(action.payload);
        return { payload: response.data };
      } catch (e) {
        return { error: e };
      }
    }),
    map((action) =>
      action?.payload
        ? getReportCommentSuccess(action?.payload)
        : getReportCommentFail()
    )
  );
const createReportCommentEpic = (
  action$: Observable<Action>,
  _: stateAction,
  { dispatch }: dispatchAction
) =>
  action$.pipe(
    filter(createReportCommentRequest.match),
    mergeMap(async ({ payload: { values, rowsPerPage, page } }) => {
      try {
        const body = new FormData();
        for (let [key, value] of Object.entries(values)) {
          // @ts-ignore
          body.append(`${key}`, value);
        }
        const response = await createReportComment(body);
        if (response) {
          dispatch(getReportCommentRequest({ rowsPerPage, page }));
          dispatch(alertSuccessAction(messages.createMessage));
          dispatch(closeModal());
        }
        return { payload: { response } };
      } catch (e) {
        dispatch(alertErrorAction(messages.createFailMessage));
        return { error: e };
      }
    }),
    map((action) => {
      return action?.payload
        ? createReportCommentSuccess()
        : createReportCommentFail();
    })
  );
//update Type epic
const updateReportCommentEpic = (
  action$: Observable<Action>,
  _: stateAction,
  { dispatch }: dispatchAction
) =>
  action$.pipe(
    filter(updateReportCommentRequest.match),
    mergeMap(async ({ payload: { values, id, rowsPerPage, page } }) => {
      try {
        const body = new FormData();
        for (let [key, value] of Object.entries(values)) {
          // @ts-ignore
          body.append(`${key}`, value);
        }

        const response = await updateReportComment(body, id);
        if (response) {
          dispatch(getReportCommentRequest({ rowsPerPage, page }));
          dispatch(alertSuccessAction(messages.updateMessage));
          dispatch(closeModal());
          dispatch(clearReportCommentData());
        }
        return { payload: { response, rowsPerPage } };
      }
       catch (e) {
        dispatch(alertErrorAction(messages.updateFailMessage));
        return { error: e };
      }
    }),
    map((action) =>
      action?.payload ? updateReportCommentSuccess() : updateReportCommentFail()
    )
  );
const getAllReportCommentDefaultEpic = (
  action$: Observable<Action>,
  _: stateAction,
  { dispatch }: dispatchAction
) =>
  action$.pipe(
    filter(getAllReportCommentDefaultRequest.match),
    mergeMap(async (action) => {
      // dispatch(loadingReportComment());
      try {
        const response = await getAllReportCommentForDefault();
        return { payload: response.data };
      } catch (e) {
        return { error: e };
      }
    }),
    map((action) =>
      action?.payload
        ? getReportCommentSuccess(action?.payload)
        : getReportCommentFail()
    )
  );
export const reportCommentEpics = combineEpics(
  getReportCommentEpic,
  createReportCommentEpic,
  updateReportCommentEpic,
  getReportCommentNext,
  getReportCommentPrevious,
  getAllReportCommentDefaultEpic
);
