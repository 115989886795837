import {
  getMoldTypeRequest,
  getMoldTypeFail,
  getMoldTypeSuccess,
  createMoldTypeFail,
  createMoldTypeRequest,
  createMoldTypeSuccess,
  updateMoldTypeFail,
  updateMoldTypeSuccess,
  clearAllData,
  getMoldTypeNextRequest,
  getMoldTypePreviousRequest,
  updateMoldTypeRequest,
  getMoldTypeByIdRequest,
  moldTypeEditSuccess,
  moldTypeEditFail,
} from "./moldTypeSlice";
import { mergeMap } from "rxjs";
import {
  getMoldType,
  createMoldType,
  updateMoldType,
  getMoldTypeById,
} from "./api";
import { map, filter } from "rxjs/operators";
import { Observable } from "rxjs";
import { Action } from "@reduxjs/toolkit";
import { combineEpics } from "redux-observable";
import {
  alertErrorAction,
  alertSuccessAction,
  closeModal,
} from "../../../CommonAppRedux/CommonAppSlice";
import { getNext, getPrevious } from "../../../CommonAppRedux/api";
import {
  dispatchAction,
  stateAction,
} from "../../../../AppUtils/Utils/globalTypes";
import messages from "../../../../AppUtils/Utils/validationConstants";
export const controller = new AbortController();

//get MoldType
const getMoldTypeEpic = (
  action$: Observable<Action>,
  _: stateAction,
  { dispatch }: dispatchAction
) =>
  action$.pipe(
    filter(getMoldTypeRequest.match),
    mergeMap(async (action: any) => {
      try {
        const response = await getMoldType(action.payload);
        return { payload: response.data };
      } catch (e) {
        return { error: e };
      }
    }),
    map((action) =>
      action?.payload ? getMoldTypeSuccess(action?.payload) : getMoldTypeFail()
    )
  );

//get moldType by id epic
const getMoldTypeByIdEpic = (
  action$: Observable<Action>,
  _: stateAction,
  { dispatch }: dispatchAction
) =>
  action$.pipe(
    filter(getMoldTypeByIdRequest.match),
    mergeMap(async (action) => {
      try {
        const response = await getMoldTypeById(action.payload);
        return { payload: response.data };
      } catch (e) {
        return { error: e };
      }
    }),
    map((action) =>
      action?.payload ? moldTypeEditSuccess(action.payload) : moldTypeEditFail()
    )
  );

//create
const createMoldTypeEpic = (
  action$: Observable<Action>,
  _: stateAction,
  { dispatch }: dispatchAction
) =>
  action$.pipe(
    filter(createMoldTypeRequest.match),
    mergeMap(async ({ payload: { values, rowsPerPage, page } }) => {
      try {
        const body = new FormData();
        for (let [key, value] of Object.entries(values)) {
          body.append(`${key}`, String(value));
        }
        const response = await createMoldType(body);
        if (response) {
          dispatch(getMoldTypeRequest({ rowsPerPage, page }));
          dispatch(alertSuccessAction(messages.createMessage));
          dispatch(closeModal());
        }
        return { payload: { response } };
      } catch (e) {
        dispatch(alertErrorAction(messages.createFailMessage));
        return { error: e };
      }
    }),
    map((action) => {
      return action?.payload ? createMoldTypeSuccess() : createMoldTypeFail();
    })
  );

//update
const updateMoldTypeEpic = (
  action$: Observable<Action>,
  _: stateAction,
  { dispatch }: dispatchAction
) =>
  action$.pipe(
    filter(updateMoldTypeRequest.match),
    mergeMap(async ({ payload: { values, id, rowsPerPage, page, search } }) => {
      try {
        const body = new FormData();
        for (let [key, value] of Object.entries(values)) {
          body.append(`${key}`, String(value));
        }
        const response = await updateMoldType(body, id);
        if (response) {
          dispatch(getMoldTypeRequest({ rowsPerPage, page, search }));
          dispatch(alertSuccessAction(messages.updateMessage));
          dispatch(closeModal());
          dispatch(clearAllData());
        }
        return { payload: { response, rowsPerPage } };
      } catch (e) {
        dispatch(alertErrorAction(messages.updateFailMessage));
        return { error: e };
      }
    }),
    map((action) => {
      return action?.payload ? updateMoldTypeSuccess() : updateMoldTypeFail();
    })
  );

//get next
const getMoldTypeNext = (
  action$: Observable<Action>,
  _: stateAction,
  { dispatch }: dispatchAction
) =>
  action$.pipe(
    filter(getMoldTypeNextRequest.match),
    mergeMap(async (action) => {
      try {
        const response = await getNext(action.payload);
        return { payload: response.data };
      } catch (e) {
        return { error: e };
      }
    }),
    map((action) => {
      return action?.payload
        ? getMoldTypeSuccess(action?.payload)
        : getMoldTypeFail();
    })
  );

//get previous
const getMoldTypePrevious = (
  action$: Observable<Action>,
  _: stateAction,
  { dispatch }: dispatchAction
) =>
  action$.pipe(
    filter(getMoldTypePreviousRequest.match),
    mergeMap(async (action) => {
      try {
        const response = await getPrevious(action?.payload);
        return { payload: response.data };
      } catch (e) {
        return { error: e };
      }
    }),
    map((action) =>
      action?.payload ? getMoldTypeSuccess(action?.payload) : getMoldTypeFail()
    )
  );

export const moldTypeEpics = combineEpics(
  getMoldTypeEpic,
  createMoldTypeEpic,
  updateMoldTypeEpic,
  getMoldTypeNext,
  getMoldTypePrevious,
  getMoldTypeByIdEpic
);
