import { axiosInstance } from "../../../../AppUtils/Utils/axios";
import {
  searchProps,
  getApiProps,
} from "../../../../AppUtils/Utils/globalTypes";
import { filterApiData } from "../../../../AppUtils/Utils/appFunctions";

export const depositModURL = `api/v1/account-app/deposit-return`;

// Get DepositReturn
export const getDepositReturn = (data: getApiProps) => {
  const { rowsPerPage, page, search } = data;
  return axiosInstance.get(
    `${depositModURL}?offset=${
      rowsPerPage * page
    }&limit=${rowsPerPage}&search=${search ?? ""}${
      data?.filterData ? filterApiData(data?.filterData) : ""
    }`
  );
};

// Get DepositReturn by id
export const getDepositReturnById = (id: number) => {
  return axiosInstance.get(`${depositModURL}/${id}`);
};

// Search DepositReturn
export const searchDepositReturn = ({ rowsPerPage, search }: searchProps) => {
  return `${depositModURL}?offset=0&limit=${rowsPerPage}&search=${
    search ?? ""
  }`;
};

// Update DepositReturn
export const updateDepositReturn = (body: string | FormData, id: number) =>
  axiosInstance.patch(`${depositModURL}/${id}`, body);

// Create DepositReturn
export const createDepositReturn = (body: string | FormData) =>
  axiosInstance.post(`${depositModURL}`, body);

export const getDepositReturnCustomer = (rowsPerPage: number) =>
  axiosInstance.get(`${depositModURL}/customer?offset=0&limit=${rowsPerPage}`);

export const getDepositReturnDeposit = (
  customer: number,
  rowsPerPage: number
) => {
  return axiosInstance.get(
    `${depositModURL}/deposit?customer=${customer}&offset=0&limit=${rowsPerPage}`
  );
};
