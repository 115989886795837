import { Card, CardContent, Divider, Grid } from "@mui/material";
import React, { memo, useEffect, useRef, useState } from "react";
import { useDispatch } from "react-redux";
import AppButton from "../AppElements/Button/AppButton";
import { useAppSelector } from "../../AppUtils/Utils/appHooks";
import {
  showModal,
  showReferenceRangeModal,
  updateInventorySidebar,
} from "../../Pages/CommonAppRedux/CommonAppSlice";
import { commonAppSelector } from "../../Pages/CommonAppRedux/selector";
import FixedAppBar from "./Header/FixedHeader";
import Header from "./Header/Header";
import SidebarMenu from "./Sidebar/SidebarMenu";
import { useStyles, Main } from "./Sidebar/SidebarStyles";
import AppTabs from "../AppTabs/Tabs";
import { masterSidebarData } from "./Sidebar/Master/MasterSidebarData";
import { labSidebarData } from "./Sidebar/Lab/LabSidebarData";
import { clinicSidebarData } from "./Sidebar/Clinic/ClinicSidebarData";
import { useImmer } from "use-immer";
import { handlePermission } from "../../AppUtils/Utils/permission";
import { loginSelector } from "../../Pages/Auth/Redux/selector";
import { postIRDSyncRequest } from "../../Pages/ClinicPages/IRDReport/IRDSync/Redux/irdSyncSlice";
import { postIRDSyncInventoryRequest } from "../../Pages/InventoryPages/IRDReport/IRDSync/Redux/irdSyncInventorySlice";
import { invSidebarData } from "./Sidebar/Inventory/InventorySidebarData";
import SalesInfo from "../CreditSalesInfo";

interface layoutProps {
  children: React.ReactNode;
}
const Layout = ({ children }: layoutProps) => {
  const dispatch = useDispatch();
  const [openSidebar, setOpenSidebar] = useImmer({
    openClinicSidebar: false,
    openLabSidebar: false,
    openInvSidebar: false,
    openMasterSidebar: false,
  });

  const addRef = useRef<HTMLInputElement>(null);
  const { openMasterSidebar, openInvSidebar, openLabSidebar } = openSidebar;
  const {
    hideAddButton,
    hideTabAndAction,
    passPermission,
    AddClinicIRDButton,
    AddInventoryIRDButton,
    updatedInventorySideBar,
    buyerCaption,
    globalOrganizationRules: rule,
    showCreditSalesInfo,
  } = useAppSelector(commonAppSelector);

  const { permissions, isSuperuser } = useAppSelector(loginSelector);
  const [dataToBeLoad, setDataToBeLoad] = useImmer([]);

  //function to check the active sidebar
  const isActiveSidebar = (name: string) => {
    return localStorage?.getItem("activeSidebar") === name;
  };

  useEffect(() => {
    // set active sidebar
    isActiveSidebar("lab")
      ? setOpenSidebar((draft) => {
          draft.openLabSidebar = true;
        })
      : isActiveSidebar("clinic")
      ? setOpenSidebar((draft) => {
          draft.openClinicSidebar = true;
        })
      : isActiveSidebar("inventory")
      ? setOpenSidebar((draft) => {
          draft.openInvSidebar = true;
        })
      : setOpenSidebar((draft) => {
          draft.openMasterSidebar = true;
        });
  }, []);

  useEffect(() => {
    // To also update the name in the items array:
    const updatedSidebarData = invSidebarData.map((section) => {
      if (section.name === "Customer") {
        const updatedItems = section.items.map((item) => {
          return {
            ...item,
            name: `Registration ${buyerCaption as string} `,
          };
        });
        return {
          ...section,
          name: buyerCaption as string,
          items: updatedItems,
        };
      } else if (section.name === "Inventory Management") {
        const updatedItems = section.items.map((item) => {
          return { ...item };
        });
        return {
          ...section,
          name: "Inventory Management",
          items: updatedItems,
        };
      } else {
        return section;
      }
    });
    dispatch(updateInventorySidebar(updatedSidebarData));
  }, [buyerCaption]);

  // update data to be load
  useEffect(() => {
    setDataToBeLoad(
      openMasterSidebar
        ? masterSidebarData
        : openInvSidebar
        ? updatedInventorySideBar
        : openLabSidebar
        ? labSidebarData
        : clinicSidebarData
    );
  }, [
    openInvSidebar,
    openLabSidebar,
    openMasterSidebar,
    updatedInventorySideBar,
    setDataToBeLoad,
  ]);

  const [open, setOpen] = useState(true);

  const classes = useStyles();
  const handleClickOpen = () => {
    dispatch(showModal(true));
    dispatch(showReferenceRangeModal());
  };

  const handleUploadIRDSync = () => {
    if (AddClinicIRDButton) {
      dispatch(postIRDSyncRequest());
    } else if (AddInventoryIRDButton) {
      dispatch(postIRDSyncInventoryRequest());
    }
  };

  useEffect(() => {
    const handleAddButton = (event: KeyboardEvent) => {
      if (event.ctrlKey && (event.key === "o" || event.key === "O")) {
        event.preventDefault(); // prev ent default behavior of saving the page
        addRef?.current?.click(); // click the button
      }
    };
    document.addEventListener("keydown", handleAddButton);
    return () => {
      document.removeEventListener("keydown", handleAddButton);
    };
  }, []);

  if (open === true) {
    const element = document?.getElementById("myMainMenu");
    element?.addEventListener("click", () => {
      setOpen(!rule?.collapseSideBar);
    });
  }

  return (
    <>
      <FixedAppBar openSidebar={openSidebar} setOpenSidebar={setOpenSidebar} />
      <Header open={open} setOpen={setOpen} />
      <SidebarMenu open={open} dataToBeLoad={dataToBeLoad} setOpen={setOpen} />
      <Main open={open} id="myMainMenu">
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <Card>
              {!hideTabAndAction && (
                <Grid
                  container
                  direction="row"
                  alignItems="center"
                  justifyContent="space-between"
                  className={classes.cardHeader}
                >
                  <Grid item className={classes.tabs}>
                    <AppTabs dataToBeLoad={dataToBeLoad as any} />
                  </Grid>
                  {isSuperuser ||
                  handlePermission(
                    permissions,
                    passPermission?.add_permission
                  ) ? (
                    <>
                      {!hideAddButton && (
                        <>
                          <Grid item xs={1.2} lg={0.9}>
                            <AppButton
                              tooltipTitle="Add"
                              submit={true}
                              onClick={handleClickOpen}
                              autoFocus
                              reference={addRef}
                            >
                              Add
                            </AppButton>
                          </Grid>
                        </>
                      )}
                      {(AddClinicIRDButton || AddInventoryIRDButton) && (
                        <>
                          <Grid item>
                            <AppButton
                              tooltipTitle="Upload IRD Sync"
                              submit={true}
                              onClick={handleUploadIRDSync}
                            >
                              Upload IRD Sync
                            </AppButton>
                          </Grid>
                        </>
                      )}
                    </>
                  ) : null}
                </Grid>
              )}
              <Divider />
              <CardContent className={classes.cardContent}>
                {children}
              </CardContent>
            </Card>
          </Grid>
        </Grid>
      </Main>
      {showCreditSalesInfo && <SalesInfo />}
    </>
  );
};

export default memo(Layout);
