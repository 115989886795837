import { memo, useRef } from "react";
import Box from "@mui/material/Box";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableContainer from "@mui/material/TableContainer";
import Paper from "@mui/material/Paper";
import { TableProps } from "../types";
import CommonTableBody from "./CommonTableBody";
import CommonTableHeader from "./CommonTableHeader";
import Pagination from "../TableUtils/Pagination";
import ListingTableData from "../../../../Component/Loader/ListingTableData";
import NoData from "../../../../Component/NoData/NoData";
import { tableStyles } from "../styles";
import CommonReportDetailBody from "./CommonReportDetailBody";
const CommonTable = ({
  data,
  columns,
  count,
  handleClick,
  loading,
  hidePagination,
  tableContainerForInsideDetail,
  subHeader,
  showDetailReportTable,
  getDetailsByKey,
}: TableProps) => {
  const classes = tableStyles();
  const extraCell = useRef(
    subHeader?.length! > 0 ? subHeader?.length! + 1 - columns?.length : 0
  );
  // header props
  const headerProps = {
    columns,
    extraCell,
  };
  //body props
  const bodyProps = {
    columns,
    data,
  };
  // detail body props
  const commonReportDetailBodyProps = {
    header: columns,
    subHeader,
    data,
    getDetailsByKey,
    extraCell,
  };
  // pagination props
  const paginationProps = {
    count,
    handleClick,
  };
  return (
    <Box>
      <>
        <Paper className={classes.tableDataPaper}>
          <TableContainer
            className={
              tableContainerForInsideDetail
                ? data?.length === 0
                  ? classes.noDataDisplay
                  : classes.tableContainerForDetail
                : classes.tableContainer
            }
          >
            <Table size="medium" stickyHeader aria-label="sticky table">
              <CommonTableHeader {...headerProps} />
              {/* show loader if it is in loading state */}
              {loading ? (
                /* pass header length  */
                <ListingTableData headerLength={columns?.length + 3} />
              ) : (
                <>
                  <TableBody>
                    {showDetailReportTable ? (
                      <CommonReportDetailBody
                        {...commonReportDetailBodyProps}
                      />
                    ) : (
                      <CommonTableBody {...bodyProps} />
                    )}
                  </TableBody>
                </>
              )}
            </Table>
          </TableContainer>
          {!loading && data?.length === 0 && <NoData />}
          {/* pagination */}
          {!hidePagination && <Pagination {...paginationProps} />}
          {/* <Pagination /> */}
        </Paper>
      </>
    </Box>
  );
};
export default memo(CommonTable);
