import {
  createRefundBillingFail,
  createRefundBillingRequest,
  createRefundBillingSuccess,
  getRefundBillingRequest,
  getRefundBillingSuccess,
  getRefundBillingFail,
  getRefundBillingNextRequest,
  getRefundBillingPreviousRequest,
  getAllCustomerRequest,
  getAllCustomerFail,
  getAllCustomerSuccess,
  getBillingRequest,
  getBillingSuccess,
  getBillingFail,
  getRefundBillingDetailsRequest,
  getRefundBillingDetailsSuccess,
  getRefundBillingDetailsFail,
  getViewRefundBillingDetailsRequest,
  getViewRefundBillingDetailsSuccess,
  getViewRefundBillingDetailsFail,
  getViewRefundBillingDetailsNextRequest,
  getViewRefundBillingDetailsPreviousRequest,
  getPrintRefundBillingDetailsRequest,
  getPrintRefundBillingDetailsSuccess,
  getPrintRefundBillingDetailsFail,
  refundPrintCountRequest,
  refundPrintCountSuccess,
  refundPrintCountFail,
  clearRefundBillingData,
  getPrintRefundBillingCustomerDetailsSuccess,
  getPrintRefundBillingCustomerDetailsFail,
  getRefundPrintCountRequest,
  getRefundPrintCountSuccess,
  getRefundPrintCountFail,
} from "./refundBillingSlice";
import { mergeMap } from "rxjs";
import {
  createRefundBilling,
  getRefundBilling,
  getAllCustomers,
  getSpecificBillingReturn,
  getAllPendingBillings,
  getViewRefundDetails,
  getPrintRefundDetails,
  printCount,
  getPrintCustomerDetails,
  getRefundPrintCount,
} from "./api";
import { map, filter, tap } from "rxjs/operators";
import { Observable } from "rxjs";
import { Action } from "@reduxjs/toolkit";
import { combineEpics } from "redux-observable";
import {
  alertErrorAction,
  alertSuccessAction,
  closeModal,
} from "../../../CommonAppRedux/CommonAppSlice";
import { getNext, getPrevious } from "../../../CommonAppRedux/api";
import {
  dispatchAction,
  stateAction,
} from "../../../../AppUtils/Utils/globalTypes";
import messages from "../../../../AppUtils/Utils/validationConstants";

export const controller = new AbortController();

// get manufacture epic
const getRefundBillingEpic = (
  action$: Observable<Action>,
  _: stateAction,
  { dispatch }: dispatchAction
) =>
  action$.pipe(
    filter(getRefundBillingRequest.match),
    mergeMap(async (action) => {
      try {
        const response = await getRefundBilling(action.payload);
        return { payload: response.data };
      } catch (e) {
        return { error: e };
      }
    }),
    map((action) =>
      action?.payload
        ? getRefundBillingSuccess(action?.payload)
        : getRefundBillingFail()
    )
  );
const getAllRefundBillingCustomers = (
  action$: Observable<Action>,
  _: stateAction,
  { dispatch }: dispatchAction
) =>
  action$.pipe(
    filter(getAllCustomerRequest.match),
    mergeMap(async (action) => {
      try {
        const response = await getAllCustomers(action.payload);
        return { payload: response.data };
      } catch (e) {
        return { error: e };
      }
    }),
    map((action) =>
      action?.payload
        ? getAllCustomerSuccess(action?.payload)
        : getAllCustomerFail()
    )
  );
const getAllBillings = (
  action$: Observable<Action>,
  _: stateAction,
  { dispatch }: dispatchAction
) =>
  action$.pipe(
    filter(getBillingRequest.match),
    mergeMap(async (action) => {
      try {
        const response = await getAllPendingBillings(action.payload);
        return { payload: response.data };
      } catch (e) {
        return { error: e };
      }
    }),
    map((action) =>
      action?.payload ? getBillingSuccess(action?.payload) : getBillingFail()
    )
  );
const getSpecificBillingDetails = (
  action$: Observable<Action>,
  _: stateAction,
  { dispatch }: dispatchAction
) =>
  action$.pipe(
    filter(getRefundBillingDetailsRequest.match),
    mergeMap(async (action) => {
      try {
        const response = await getSpecificBillingReturn(action.payload);
        return { payload: response.data };
      } catch (e) {
        return { error: e };
      }
    }),
    map((action) =>
      action?.payload
        ? getRefundBillingDetailsSuccess(action?.payload)
        : getRefundBillingDetailsFail()
    )
  );

//get next
const getRefundBillingNext = (
  action$: Observable<Action>,
  _: stateAction,
  { dispatch }: dispatchAction
) =>
  action$.pipe(
    filter(getRefundBillingNextRequest.match),
    mergeMap(async (action) => {
      try {
        const response = await getNext(action.payload);
        return { payload: response.data };
      } catch (e) {
        return { error: e };
      }
    }),
    map((action) =>
      action?.payload
        ? getRefundBillingSuccess(action?.payload)
        : getRefundBillingFail()
    )
  );

//get previous
const getRefundBillingPrevious = (
  action$: Observable<Action>,
  _: stateAction,
  { dispatch }: dispatchAction
) =>
  action$.pipe(
    filter(getRefundBillingPreviousRequest.match),
    mergeMap(async (action) => {
      try {
        const response = await getPrevious(action.payload);
        return { payload: response.data };
      } catch (e) {
        return { error: e };
      }
    }),
    map((action) =>
      action?.payload
        ? getRefundBillingSuccess(action?.payload)
        : getRefundBillingFail()
    )
  );

//create RefundBilling epic
const createRefundBillingEpic = (
  action$: Observable<Action>,
  _: stateAction,
  { dispatch }: dispatchAction
) =>
  action$.pipe(
    filter(createRefundBillingRequest.match),
    mergeMap(
      async ({ payload: { values, rowsPerPage, page, saveAndPrint } }) => {
        const {
          roundOffAmount,
          paymentDetails,
          clinicSaleDetails,
          subTotal,
          payType,
          discountRate,
          totalDiscountableAmount,
          totalTaxableAmount,
          totalNonTaxableAmount,
          totalDiscount,
          totalTax,
          grandTotal,
          remarks,
          customer,
          saleNo,
        } = values;
        try {
          const body = JSON.stringify({
            roundOffAmount,
            clinicSaleDetails,
            paymentDetails,
            subTotal,
            payType,
            discountRate,
            totalDiscountableAmount,
            totalTaxableAmount,
            totalNonTaxableAmount,
            totalDiscount,
            totalTax,
            grandTotal,
            remarks,
            customer: customer?.id,
            saleNo: saleNo?.saleNo,
            refSaleMain: saleNo?.id,
          });
          const response = await createRefundBilling(body);
          if (response) {
            dispatch(getRefundBillingRequest({ rowsPerPage, page }));
            dispatch(alertSuccessAction(messages.createMessage));
            dispatch(clearRefundBillingData());
            !saveAndPrint && dispatch(closeModal());
          }
          return { payload: { response } };
        } catch (e) {
          dispatch(alertErrorAction(messages.createFailMessage));
          return { error: e };
        }
      }
    ),
    map((action) => {
      return action?.payload
        ? createRefundBillingSuccess(action?.payload)
        : createRefundBillingFail();
    })
  );

// get manufacture epic
const getViewRefundBillingDetails = (
  action$: Observable<Action>,
  _: stateAction,
  { dispatch }: dispatchAction
) =>
  action$.pipe(
    filter(getViewRefundBillingDetailsRequest.match),
    mergeMap(async (action) => {
      try {
        const response = await getViewRefundDetails(action.payload);
        return { payload: response.data };
      } catch (e) {
        return { error: e };
      }
    }),
    map((action) =>
      action?.payload
        ? getViewRefundBillingDetailsSuccess(action?.payload)
        : getViewRefundBillingDetailsFail()
    )
  );

//get next
const getViewRefundBillingDetailsNext = (
  action$: Observable<Action>,
  _: stateAction,
  { dispatch }: dispatchAction
) =>
  action$.pipe(
    filter(getViewRefundBillingDetailsNextRequest.match),
    mergeMap(async (action) => {
      try {
        const response = await getNext(action.payload);
        return { payload: response.data };
      } catch (e) {
        return { error: e };
      }
    }),
    map((action) =>
      action?.payload
        ? getViewRefundBillingDetailsSuccess(action?.payload)
        : getViewRefundBillingDetailsFail()
    )
  );

//get previous
const getViewRefundBillingDetailsPrevious = (
  action$: Observable<Action>,
  _: stateAction,
  { dispatch }: dispatchAction
) =>
  action$.pipe(
    filter(getViewRefundBillingDetailsPreviousRequest.match),
    mergeMap(async (action) => {
      try {
        const response = await getPrevious(action.payload);
        return { payload: response.data };
      } catch (e) {
        return { error: e };
      }
    }),
    map((action) =>
      action?.payload
        ? getViewRefundBillingDetailsSuccess(action?.payload)
        : getViewRefundBillingDetailsFail()
    )
  );

const getPrintRefundBillingDetails = (
  action$: Observable<Action>,
  _: stateAction,
  { dispatch }: dispatchAction
) =>
  action$.pipe(
    filter(getPrintRefundBillingDetailsRequest.match),
    mergeMap(async (action) => {
      try {
        const response = await getPrintRefundDetails(action.payload);
        return { payload: response.data };
      } catch (e) {
        return { error: e };
      }
    }),
    map((action) =>
      action?.payload
        ? getPrintRefundBillingDetailsSuccess(action?.payload)
        : getPrintRefundBillingDetailsFail()
    )
  );
const getPrintRefundBillingCustomerDetails = (
  action$: Observable<Action>,
  _: stateAction,
  { dispatch }: dispatchAction
) =>
  action$.pipe(
    filter(getPrintRefundBillingDetailsRequest.match),
    mergeMap(async (action) => {
      try {
        const response = await getPrintCustomerDetails(action.payload);
        return { payload: response.data };
      } catch (e) {
        return { error: e };
      }
    }),
    map((action) =>
      action?.payload
        ? getPrintRefundBillingCustomerDetailsSuccess(action?.payload)
        : getPrintRefundBillingCustomerDetailsFail()
    )
  );

const getViewRefundBillingCustomerDetails = (
  action$: Observable<Action>,
  _: stateAction,
  { dispatch }: dispatchAction
) =>
  action$.pipe(
    filter(getViewRefundBillingDetailsRequest.match),
    mergeMap(async (action) => {
      try {
        const response = await getPrintCustomerDetails(action.payload);
        return { payload: response.data };
      } catch (e) {
        return { error: e };
      }
    }),
    map((action) =>
      action?.payload
        ? getPrintRefundBillingCustomerDetailsSuccess(action?.payload)
        : getPrintRefundBillingCustomerDetailsFail()
    )
  );

const refundInvoicePrintCountEpic = (
  action$: Observable<Action>,
  _: stateAction,
  { dispatch }: dispatchAction
) =>
  action$.pipe(
    filter(refundPrintCountRequest.match),
    mergeMap(async (action) => {
      try {
        const response = await printCount(action.payload);
        return { payload: response.data };
      } catch (e) {
        return { error: e };
      }
    }),
    map((action) =>
      action?.payload
        ? refundPrintCountSuccess(action?.payload)
        : refundPrintCountFail()
    )
  );

// const getRefundBillingPrintCountEpic = (
//   action$: Observable<Action>,
//   _: stateAction,
//   { dispatch }: dispatchAction
// ) =>
//   action$.pipe(
//     filter(getRefundBillingPrintCountRequest.match),
//     mergeMap(async (action) => {
//       try {
//         const response = await getRefundBillingPrintCount(action.payload);
//         return { payload: response.data };
//       } catch (e) {
//         return { error: e };
//       }
//     }),
//     map((action) =>
//       action?.payload
//         ? getRefundBillingPrintCountSuccess(action?.payload)
//         : getRefundBillingPrintCountFail()
//     )
//   );
const getRefundPrintCountEpic = (
  action$: Observable<Action>,
  _: stateAction,
  { dispatch }: dispatchAction
) =>
  action$.pipe(
    filter(getRefundPrintCountRequest.match),
    mergeMap(async (action) => {
      try {
        const response = await getRefundPrintCount(action.payload);
        return { payload: response.data };
      } catch (e) {
        return { error: e };
      }
    }),
    map((action) =>
      action?.payload
        ? getRefundPrintCountSuccess(action?.payload)
        : getRefundPrintCountFail()
    )
  );
export const refundBillingEpics = combineEpics(
  getRefundBillingEpic,
  createRefundBillingEpic,
  getAllRefundBillingCustomers,
  getAllBillings,
  getSpecificBillingDetails,
  getRefundBillingNext,
  getRefundBillingPrevious,
  getViewRefundBillingDetails,
  getViewRefundBillingDetailsNext,
  getViewRefundBillingDetailsPrevious,
  getPrintRefundBillingDetails,
  refundInvoicePrintCountEpic,
  getRefundPrintCountEpic,
  getPrintRefundBillingCustomerDetails,
  getViewRefundBillingCustomerDetails
);
